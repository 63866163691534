import * as React from 'react'
import styled, { css } from 'styled-components'
import { CheckmarkRoundedIcon } from '../Icons'
import { CustomInputFieldProps } from '../../../types/inputFields'
import TextField from '@material-ui/core/TextField'
import { dimensions } from '../../../themes/variables'
import useThemeMode from '../../../hooks/useThemeMode'

const muiInputVariantClass = 'MuiOutlinedInput-notchedOutline'

export const StyledTextFieldWrapper = styled.div<CustomInputFieldProps>`
  position: relative;
  display: flex;
  .checkmark-icon {
    position: absolute;
    color: ${({ theme }) => theme.palette.type}px;
    width: ${({ theme }) => theme.spacing()}px;
    height: ${({ theme }) => theme.spacing()}px;
    top: calc(50% - 8px);
    right: ${({ theme }) => theme.spacing()}px;
    display: ${({ showValidationStatus, error, ispristine }) =>
      showValidationStatus ? (!error && !ispristine ? 'block' : 'none') : 'none'};
    z-index: 1;
  }
  
  .registration-layout__password-input {
    & > .MuiFormHelperText-contained {
      margin-left: 0;
    }
  }

  label {
    font-size: 0;
    width: 100%;
  }
`

export const TextFieldStyle = css<CustomInputFieldProps>`
  ${({ theme, themeMode }) => `
  width: 100%;

  label {
    font-size: 12px;
    color: ${
      themeMode === 'dark' ? theme.palette.secondary.greyDarkmode : theme.palette.secondary.main
    };
    &.Mui-error {
      color: ${theme.palette.text.error} !important;

      & .MuiInputLabel-asterisk {
        color: inherit;
      }
    }
    
    &.Mui-focused {
      transform: translate(16px, -6px) scale(1);
      color: ${
        themeMode === 'dark' ? theme.palette.secondary.greyDarkmode : theme.palette.secondary.main
      };
    }
    &[data-shrink='true'] {
      transform: translate(16px, -6px) scale(1);
    }

    &:not(.Mui-focused)[data-shrink='true'] {
      color: ${theme.palette.secondary[themeMode === 'dark' ? 'greyDarkmode' : 'greyLightmode']};
    }
  }

  & > div {
    height: ${dimensions.inputFields.height}px;
    border-radius: 0;
    padding: ${theme.spacing()}px;

    fieldset,
    fieldset.${muiInputVariantClass} {
      border-color: ${
        theme.palette.secondary[themeMode === 'dark' ? 'greyDarkmode' : 'greyLightmode']
      };
      border-width: 1px !important;
    }

    &.Mui-focused {
      fieldset,
      fieldset.${muiInputVariantClass} {
        border-color: ${
          themeMode === 'dark' ? theme.palette.secondary.dark : theme.palette.secondary.main
        };
        border-width: 1px;
      }
    }
    &.Mui-error {
      fieldset,
      fieldset.${muiInputVariantClass} {
        border-color: ${theme.palette.text.error};
      }

      & + p {
        margin-left: 0;
        color: ${theme.palette.text.error};
      }
    }
  }

  fieldset {
    border: 1px solid;
  }

  input {
    padding: 5px 0px;
    font-size: 12px;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-clip: content-box !important;
      -webkit-box-shadow: 0 0 0 30px
        ${themeMode === 'light' ? theme.palette.custom.warmGrey : theme.palette.custom.black}
        inset;
      box-shadow: 0 0 0 30px
        ${themeMode === 'light' ? theme.palette.custom.warmGrey : theme.palette.custom.black}
        inset;
      -webkit-text-fill-color:${
        themeMode === 'dark' ? theme.palette.secondary.greyDarkmode : theme.palette.secondary.main
      };
    }
  }
  
  &.arn-checkout-login-drawer {
    label {
      color: ${theme.palette.custom.black};
      &.Mui-error {
        color: ${theme.palette.custom.warningRed} !important;
      }

      &.Mui-focused {
        transform: translate(16px, -6px) scale(1);
        color: ${theme.palette.custom.black};
      }
  
      &:not(.Mui-focused)[data-shrink='true'] {
        color: ${theme.palette.secondary.greyDarkmode};
      }
    }

    & > div {
      fieldset {
        border-color: ${theme.palette.secondary.greyLightmode};
        border-width: 1px !important;
      }

      &:hover {
        fieldset {
          border-color: ${theme.palette.secondary.greyLightmode};
          border-width: 1px !important;
        }
      }
  
      &.Mui-focused {
        fieldset {
          border-color: ${theme.palette.custom.black} !important;
          border-width: 1px !important;
        }
      }

      &.Mui-error {
        fieldset,
        .MuiOutlinedInput-notchedOutline {
          border-color: ${theme.palette.custom.warningRed};
        }
  
        & + p {
          margin-left: 0;
          color: ${theme.palette.custom.warningRed};
        }
      }
    }

    input {
      color: ${theme.palette.custom.black};
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        background-clip: content-box !important;
        -webkit-box-shadow: 0 0 0 30px ${theme.palette.custom.warmGrey}
          inset;
        box-shadow: 0 0 0 30px ${theme.palette.custom.warmGrey}
          inset;
        -webkit-text-fill-color: ${theme.palette.custom.black};
      }
    }
  }
`}
`

const TextFieldBase = styled(TextField)<CustomInputFieldProps>`
  ${TextFieldStyle}
`

export const StyledTextField = ({
  showValidationStatus,
  error,
  ispristine,
  ...props
}: CustomInputFieldProps) => {
  const { themeMode } = useThemeMode()

  return (
    <StyledTextFieldWrapper
      themeMode={themeMode}
      showValidationStatus={showValidationStatus}
      error={error}
      ispristine={ispristine}
      className="arn-textfield-wrapper">
      <CheckmarkRoundedIcon className="checkmark-icon" />
      <label>{props.label}
        <TextFieldBase themeMode={themeMode} error={error} variant="outlined" {...props} /></label>
    </StyledTextFieldWrapper>
  )
}
