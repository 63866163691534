/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from 'styled-components'
import FormGroup from '@material-ui/core/FormGroup'
import React from 'react'

const StyledFormGroup = styled(({ ...props }) => <FormGroup {...props} />)`
  ${({ theme }) => `
  `}
`

export default StyledFormGroup
