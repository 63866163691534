import { Button, Snackbar, SnackbarOrigin } from '@material-ui/core'
import HTMLReactParser from 'html-react-parser'
import React, { FC, SyntheticEvent, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { StyledAlert } from '../../StyledUI'
import useThemeMode from '../../../hooks/useThemeMode'

const StyledSnackbar = styled(({ ...props }) => <Snackbar {...props} />)`
  && {
    background-color: #9b0800;
    transform: translateX(0);
    left: 15px;
    bottom: 16px;
    height: 64px;
    opacity: 0.9;

    &.arn-message-snackbar {
      background-color: ${({ theme }) => theme.palette.text.error};
    }
  }
`

const InfoRoundedIcon: FC<{ className: string }> = ({ className }) => (
  <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" className={className}>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z"></path>
  </svg>
)

interface MessageSnackbarProperty {
  anchorOrigin: SnackbarOrigin
  handleClose: Function
  message: string | null
  severity: 'success' | 'info' | 'warning' | 'error' | undefined
  linkAction?: { url: string; text: string }
  autoHideDuration?: number
  ClickAwayListenerProps?: any
}

const MessageToDisplay: FC<{ linkAction?: { text: string; url: string }; message: string }> = ({
  linkAction,
  message,
}) => (
  <>
    {linkAction === null || linkAction === undefined ? (
      message && HTMLReactParser(message)
    ) : (
      <span>
        {message && HTMLReactParser(message)}
        <Link to={linkAction.url}>{linkAction.text}</Link>
      </span>
    )}
  </>
)

const MessageSnackbar = ({
  anchorOrigin,
  handleClose,
  message,
  severity,
  linkAction,
  autoHideDuration,
  ClickAwayListenerProps,
}: MessageSnackbarProperty) => {
  const themeMode = useThemeMode().themeMode
  const location = useLocation()
  const open = !!message
  let clickAway = {}

  if (ClickAwayListenerProps) {
    clickAway = { ClickAwayListenerProps }
  }

  const onClose = (event?: SyntheticEvent) => {
    if (open) {
      handleClose(event)
    }
  }

  useEffect(() => {
    //close on location change.
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <StyledSnackbar
      open={open}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      {...clickAway}
      themeMode={themeMode}
      className="arn-message-snackbar">
      <StyledAlert
        className="arn-message-snackbar_alert"
        icon={<InfoRoundedIcon className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" />}
        action={<Button color="inherit" size="small" style={{ display: 'none' }} />}
        variant="filled"
        severity={severity}
        onClose={onClose}>
        <MessageToDisplay linkAction={linkAction} message={message || ''} />
      </StyledAlert>
    </StyledSnackbar>
  )
}

export default MessageSnackbar
