import { SwipeableDrawerProps } from '@material-ui/core'
import styled from 'styled-components'
import { StyledSwipeableDrawer } from '../index'
import { PROMO_BAR_HEIGHT } from '../../../constants/ui'

export const StyledSearchDrawer = styled(StyledSwipeableDrawer)<
  SwipeableDrawerProps & { hasPromoBar: boolean }
>`
  ${({ hasPromoBar }) => `
  & > div {
    overflow: hidden;
    top: ${hasPromoBar ? PROMO_BAR_HEIGHT : 0}px;
    height: calc(100% - ${hasPromoBar ? PROMO_BAR_HEIGHT : '0px'});
  }
`}
`

export const StyledGridProductLayout = styled.div<{ themeMode?: string }>`
  ${({ theme, themeMode }) => `
  position: relative;
  max-width: 100%;
  flex: 0 0 50%;

  border-right: 1px solid black;
  border-bottom: 1px solid black;
  box-sizing: border-box;
  background-color: ${theme.palette.custom.warmGrey};

  &:nth-child(odd) {
    border-left: 1px solid ${theme.palette.custom[themeMode === 'dark' ? 'warmGrey' : 'black']};
  }

  ${theme.breakpoints.up('lg')} {
    min-height: 370px;
  }
`}
`

export const StyledProductWrap = styled.div`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    height: 100%;
  }

  @supports not (aspect-ratio: 1 / 1) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  & > div {
    footer {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`

export const ContentPlp = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${theme.palette.text.primary};
`}
`
