import React from 'react'
import { PlpFilterLayout } from '../../layouts/plp-filter-page'
import { ISeo } from '../../../types/seo'

const Category: ISeo['component'] = (props) => {
  const { seoData } = props

  return <PlpFilterLayout cid={seoData.page.externalContext.identifier} seoData={seoData} />
}

export default Category
