import { addBusinessDays, format } from 'date-fns'
import { CommerceEnvironment } from '../constants/common'
import { DATE_FNS_LOCALE_MAP, DATE_FORMAT_PATTERN } from '../constants/date'
import store from '../redux/store'

type DatePattern = keyof typeof DATE_FORMAT_PATTERN

class DateService {
  isValid(date: Date | string | number): boolean {
    return date instanceof Date && !isNaN(date.getTime())
  }

  addBusinessDays(dateArg: Date | string | number, daysToAdd: number): Date | null {
    let date: number | Date

    if (this.isValid(dateArg)) {
      date = dateArg as Date
    } else {
      date = new Date(dateArg as string | number)

      if (!this.isValid(date)) {
        return null
      }
    }

    date = addBusinessDays(date, daysToAdd)

    return date
  }

  format(
    dateArg: Date | string | number,
    pattern: DatePattern = DATE_FORMAT_PATTERN['MMMM d, yyyy'],
    optionsArg?: {
      locale?: Locale
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
      firstWeekContainsDate?: number
      useAdditionalWeekYearTokens?: boolean
      useAdditionalDayOfYearTokens?: boolean
    }
  ): string {
    let date: number | Date

    if (this.isValid(dateArg)) {
      date = dateArg as Date
    } else {
      date = new Date(dateArg as string | number)

      if (!this.isValid(date)) {
        return ''
      }
    }

    /** Store is used to automatically set locale to format method from date-fns,
     *  instead of passing a value from components on every DateService.format call */
    const localeCode = store.getState().site.currentSite?.locale || CommerceEnvironment.defaultLang

    /** Use default language if `DATE_FNS_LOCALE_MAP` has no locale entry with store's locale */
    const localeObject =
      localeCode in DATE_FNS_LOCALE_MAP
        ? DATE_FNS_LOCALE_MAP[localeCode]
        : DATE_FNS_LOCALE_MAP[CommerceEnvironment.defaultLang]

    const options = optionsArg ? { locale: localeObject, ...optionsArg } : { locale: localeObject }

    return format(date, pattern, options)
  }
}

export default new DateService()
