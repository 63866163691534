import {
  ApiReducerState,
  CatalogReducerState,
  ConfirmationReducerState,
  ContextReducerState,
  ContractReducerState,
  ErrorReducerState,
  OrderDetailsReducerState,
  OrderReducerState,
  OrganizationReducerState,
  SEOReducerState,
  SearchReducerState,
  SiteReducerState,
  SuccessMessageReducerState,
  ThemeReducerState,
  UIReducerState,
  UserReducerState,
} from './reducerStateInterface'
import wishList, { WishListState } from '../../features/wishList/slice'
import preview, { PreviewState } from '../../features/preview/slice'
//Redux
import api from './api'
import catalog from './catalog'
//Standard libraries
import { combineReducers } from 'redux'
import context from './context'
import contract from './contract'
import error from './error'
import order from './order'
import orderDetails from './orderDetails'
import organization from './organization'
import search from './search'
import seo from './seo'
import site from './site'
import success from './success'
import theme from './theme'
import ui from './ui'
import user from './user'
import { orderApi } from '../../features/order/query'
import uiSlice,{ IUiState } from '../../features/ui/slice'
import { accountApi } from '../../features/account/query'
import account, { IAccountState } from '../../features/account'
import { cmsApi } from '../../features/cms/query'

export * from './reducerStateInterface'
export interface RootReducerState {
  account: IAccountState
  api: ApiReducerState
  catalog: CatalogReducerState
  user: UserReducerState
  order: OrderReducerState
  error: ErrorReducerState
  seo: SEOReducerState
  contract: ContractReducerState
  search: SearchReducerState
  organization: OrganizationReducerState
  context: ContextReducerState
  success: SuccessMessageReducerState
  confirmation: ConfirmationReducerState
  orderDetails: OrderDetailsReducerState
  orderDetailsLoadings: { [orderId: string]: boolean }
  ui: UIReducerState // remove and use slice with RTK
  theme: ThemeReducerState
  site: SiteReducerState
  wishList: WishListState
  preview: PreviewState
  uiSlice: IUiState
}

const reducers = {
  account,
  api,
  catalog,
  user,
  order,
  error,
  seo,
  contract,
  search,
  context,
  organization,
  success,
  orderDetails,
  ui,
  theme,
  site,
  wishList,
  preview,
  uiSlice,
  [orderApi.reducerPath]: orderApi.reducer,
  [accountApi.reducerPath]:accountApi.reducer,
  [cmsApi.reducerPath]:cmsApi.reducer,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
