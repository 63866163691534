import './styles/index.scss'

import * as ROUTES from './constants/routes'

//Standard libraries
import React, { Dispatch, lazy } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import Log from './services/Log'
import { StoreAppContainer } from './components/layouts/StoreAppContainer'
import config from './configs'
import { countryUtil } from './utils/countryUtil'
import { initAxios } from './foundation/axios/axiosConfig'
import { useDispatch } from 'react-redux'

const PreviewLayout = lazy(() => import('./components/layouts/preview'))
const Maintenance = lazy(() => import('./components/pages/maintenance/Maintenance'))

const App: React.FC = () => {
  const dispatch = useDispatch<Dispatch<any>>()
  const { currentLangCode } = countryUtil('', config.defaultLocale)

  initAxios(dispatch)

  Log.info('Init Axios in App.tsx')
  Log.info('App start in App.tsx')
  Log.info('TEST TEST DEPLOY')

  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.MAINTENANCE} component={Maintenance} />
        <Route exact path={ROUTES.CMSPREVIEW} component={PreviewLayout} />
        <Route path="/:country">
          <StoreAppContainer />
        </Route>
        <Route path="/">
          <Redirect to={`/${currentLangCode}`} />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
