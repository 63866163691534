import { generatePath } from 'react-router'

export const BASE_COUNTRY_KEY = 'country'
export const BASE = `/:${BASE_COUNTRY_KEY}`
export const HOME = `${BASE}`
export const CMSPREVIEW = '/cmpreview'

export const ACCOUNT = `${BASE}/account/:page?`
export const buildAccountPageRoute = (page: string, queryParams?: Record<string, string>) => {
  const query = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ''

  return generatePath(ACCOUNT, {
    [BASE_COUNTRY_KEY]: `:${BASE_COUNTRY_KEY}`,
    page,
  }) + query
}

export const ACCOUNT_CHILDREN = {
  ADDRESS_BOOK: 'address-book',
  CHANGE_PASSWORD: 'change-password',
  ORDER_HISTORY: 'order-history',
  PERSONAL_INFORMATION: 'personal-information',
}

//Order
export const CART = `${BASE}/cart`
export const MAINTENANCE = '/maintenance'
export const CHECKOUT = `${BASE}/checkout`
export const CHECKOUT_SHIPPING = `${CHECKOUT}/shipping`
export const CHECKOUT_PAYMENT = `${CHECKOUT}/payment`
export const ORDER_CONFIRMATION = `${CHECKOUT}/order-confirmation`
export const ORDER_HISTORY = buildAccountPageRoute(ACCOUNT_CHILDREN.ORDER_HISTORY)
export const WISHLIST = `${BASE}/wishlist`
export const RECURRING_ORDERS = `${BASE}/recurring-orders`
export const SAVED_ORDERS = `${BASE}/saved-orders`
export const REQUISITION_LISTS = `${BASE}/requisition-lists`
export const ORDER_DETAILS = `${BASE}/order-details`
export const ORDER_ID_PARAM = '/:orderId'
export const ORDER_DETAILS_ROUTE = `${ORDER_DETAILS}${ORDER_ID_PARAM}`

//User
export const CHANGE_PASSWORD = buildAccountPageRoute(ACCOUNT_CHILDREN.CHANGE_PASSWORD)
export const ADDRESS_BOOK = buildAccountPageRoute(ACCOUNT_CHILDREN.ADDRESS_BOOK)
export const PERSONAL_INFORMATION = buildAccountPageRoute(ACCOUNT_CHILDREN.PERSONAL_INFORMATION)

//Account
export const SIGNIN = `${BASE}/sign-in`
export const SIGNUP = `${BASE}/sign-up`
export const BUYER_REGISTRATION = `${BASE}/buyer-registration`
export const ORG_REGISTRATION = `${BASE}/organization-registration`
export const DASHBOARD = `${BASE}/dashboard`
export const FORGOT_PASSWORD = `${BASE}/forgot-password`

//Search
export const SEARCH = `${BASE}/search`

//Services
export const CONTACT_US = `${BASE}/contact-us`

//register user access only
export const REGISTER_PROTECTED = `${BASE}register`
//only guest and generic user access
export const GENERIC_PROTECTED = `${BASE}generic`

export const BUYER_MANAGEMENT = `${BASE}/buyer-management`
export const APPROVALS_MANAGEMENT = `${BASE}/approvals-management`
export const ORGANIZATION_MANAGEMENT = `${BASE}/organization-management`
export const ORDER_APPROVAL = `${BASE}/order-approval`

export const CMS_PAGE_NAME_LV1 = 'pageNameLv1'
export const CMS_PAGE_NAME_LV2 = 'pageNameLv2'
export const CMS_PAGE_NAME_LV3 = 'pageNameLv3'
export const CMS_PAGE_NAME_LV4 = 'pageNameLv4'
export const CMS_PAGE_NAME_LV5 = 'pageNameLv5'
export const CMS_PAGE_ROUTE = `${BASE}/c${[
  CMS_PAGE_NAME_LV1,
  CMS_PAGE_NAME_LV2,
  CMS_PAGE_NAME_LV3,
  CMS_PAGE_NAME_LV4,
  CMS_PAGE_NAME_LV5,
]
  .map((lv) => `/:${lv}?`)
  .join('')}`
