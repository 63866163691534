import {
  AccountEventData,
  CartData,
  CommonData,
  CountrySelectionData,
  DataToSend,
  LoginData,
  PDPData,
  PaymentData,
  UpdateProductSizeData,
  TYPData,
  WishlistData,
  PLPData,
} from './interfaces'
import {
  TRACKER_MINICART_OVERLAY_OPEN,
  TRACKER_NEWSLETTER_SIGNUP,
  TRACKER_PROMOCODE_TOGGLE,
  TRACKER_REGISTRATION,
  TRACKER_SEARCH,
} from './constants/tracker'
import {
  getOrderPaymentType,
  getOrderState,
  getOrderTotalDiscount,
  getOrderZipCode,
  getProductsForAnalytics,
  getProductsParentForAnalytics,
} from './formatters/productFormatter'
import { IOrderDetails } from '../../../types/order'
import Log from '../../../services/Log'
import { ProductAnalyticsRX } from './../../../types/product'
import { ProductBase } from '../../../types/product'
import debounce from 'lodash/debounce'
import md5 from 'crypto-js/md5.js'
import sha256 from 'crypto-js/sha256.js'
import { getProductType } from '../../../utils/productAttributes'

type AccountEventPayload = {
  common: CommonData
  email?: string
  userId?: string
}

export function sendAnalyticEventRaw<D extends { id: string }>(data: D, sessionId?: D) {
  const tealiumPayload = sessionId
    ? {
      Session_Id: sessionId,
      ...data,
    }
    : data
  window.tealium_data2track && window.tealium_data2track.push(tealiumPayload)
}

let sendAnalyticEvent = sendAnalyticEventRaw

window.addEventListener('tealiumInit', () => {
  sendAnalyticEvent = debounce(sendAnalyticEventRaw, 2000)
})

export const sendLoginEvent = (email) => {
  const dataToSend: LoginData = {
    id: 'Login',
    User_LoginType: 'Standard',
    User_Email_MD5: md5(email?.toLowerCase()).toString(),
    User_Email_SHA256: sha256(email?.toLowerCase()).toString(),
  }

  sendAnalyticEvent(dataToSend)
}

export const sendMyAccountEvent = ({ common, email, userId }: AccountEventPayload) => {
  const dataToSend: AccountEventData = {
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'Profile',
    Page_Section1: 'Account',
    User_LoginType: 'Standard',
    User_Email_MD5: md5(email?.toLowerCase()).toString(),
    User_Email_SHA256: sha256(email?.toLowerCase()).toString(),
    User_EmailOptin: '0',
    User_Id: userId,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendWishlistEvent = (common: CommonData, products: ProductBase[]) => {
  const Products = getProductsForAnalytics(products)
  const dataToSend: WishlistData = {
    ...common,
    id: 'VirtualPage-View',
    Page_Type: 'Wishlist',
    Page_Section1: 'Account',
    User_LoginType: 'Standard',
    Products,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendPdpEvent = (data: {
  common: CommonData
  loginStatus: boolean
  products: ProductBase[]
  pageSection1: string
  pageSection2: string
}): void => {
  const Products = getProductsParentForAnalytics(data.products)

  const dataToSend: PDPData = {
    ...data.common,
    id: 'VirtualPage-View',
    User_LoginType: 'Standard',
    Page_Type: 'Pdp',
    Page_Section1: data.pageSection1,
    Page_Section2: data.pageSection2,
    Products,
  }

  sendAnalyticEvent(dataToSend)
}

export const sendCountrySelectionEvent = (countryCode: string) => {
  const dataToSend: CountrySelectionData = {
    id: 'Click',
    countryCode,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendCartEvent = (data: {
  common?: Partial<CommonData>
  loginStatus: boolean
  products: ProductAnalyticsRX[]
}): void => {
  const Products = getProductsParentForAnalytics(data.products)

  const dataToSend: CartData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'CartPage',
    User_LoginType: 'Standard',
    Page_Section1: 'CartPage',
    Products,
  }

  sendAnalyticEvent(dataToSend)
}

export const sendTYPData = (data: {
  common: CommonData
  loginStatus: boolean
  products: ProductBase[]
  email?: string
  orderDetails: IOrderDetails
}): void => {
  const Products = getProductsParentForAnalytics(data.products)

  const dataToSend: TYPData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Thankyou',
    User_LoginType: 'Standard',
    Page_Section1: 'Checkout',
    Page_Section2: 'Standard',
    Products,
    User_Email_MD5: md5(data.email?.toLowerCase()).toString(),
    User_Email_SHA256: sha256(data.email?.toLowerCase()).toString(),
    User_EmailOptin: '0',
    Order_Currency: data.orderDetails.grandTotalCurrency,
    Order_Id: data.orderDetails?.orderId,
    Order_ProductsAmount: data.orderDetails.totalProductPrice,
    Order_DiscountAmount: getOrderTotalDiscount(data.orderDetails),
    Order_ShippingAmount: data.orderDetails.totalShippingCharge,
    Order_ShippingTaxRate: data.orderDetails.totalShippingTax,
    Order_TaxAmount: data.orderDetails.totalSalesTax,
    Order_PaymentType: getOrderPaymentType(data.orderDetails),
    Order_State: getOrderState(data.orderDetails),
    Order_ZipCode: getOrderZipCode(data.orderDetails),
  }

  sendAnalyticEvent(dataToSend)
}

export const sendTYPExpressData = (data: {
  common: CommonData
  loginStatus: boolean
  products: ProductBase[]
  email?: string
  orderDetails: IOrderDetails
}): void => {
  const Products = getProductsParentForAnalytics(data.products)
  const dataToSend: TYPData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Thankyou',
    User_LoginType: 'Express',
    Page_Section1: 'Checkout',
    Page_Section2: 'Express',
    Products,
    User_Email_MD5: md5(data.email?.toLowerCase()).toString(),
    User_Email_SHA256: sha256(data.email?.toLowerCase()).toString(),
    User_EmailOptin: '0',
    Order_Currency: data.orderDetails.grandTotalCurrency,
    Order_Id: data.orderDetails?.orderId,
    Order_ProductsAmount: data.orderDetails.totalProductPrice,
    Order_DiscountAmount: getOrderTotalDiscount(data.orderDetails),
    Order_ShippingAmount: data.orderDetails.totalShippingCharge,
    Order_ShippingTaxRate: data.orderDetails.totalShippingTax,
    Order_TaxAmount: data.orderDetails.totalSalesTax,
    Order_ShippingMode: 'Express',
    Order_PaymentType: 'PayPalExpressCheckout',
    //Order_PaymentType: getOrderPaymentType(data.orderDetails),
    Order_State: getOrderState(data.orderDetails),
    Order_ZipCode: getOrderZipCode(data.orderDetails),
  }
  
  sendAnalyticEvent(dataToSend)
}

export const sendPaymentEvent = (data: {
  common: CommonData
  loginStatus: boolean
  products: ProductBase[]
  email?: string
  acceptedNewsletterFlag?: boolean
}): void => {
  const Products = getProductsParentForAnalytics(data.products)
  
  const dataToSend: PaymentData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Payment',
    User_LoginType: 'Standard',
    Page_Section1: 'Checkout',
    Page_Section2: 'Standard',
    User_Email_MD5: data.email ? md5(data.email.toLowerCase()).toString() : undefined,
    User_Email_SHA256: data.email ? sha256(data.email.toLowerCase()).toString() : undefined,
    Events_UserEmailSub: Boolean(data?.acceptedNewsletterFlag) === true ? '1' : '0',
    User_EmailOptin: '0',
    Products,
  }

  sendAnalyticEvent(dataToSend)
}

export const sendUpdateProductSizeEvent = (data: {
  products: ProductBase[]
}): void => {
  const Products = getProductsForAnalytics(data.products)
  const dataToSend: UpdateProductSizeData = {
    id: 'Prods-Update',
    Products,
  }

  setTimeout(() => sendAnalyticEvent(dataToSend), 3500)
}

export const sendStaticEvent = (data: {
  common?: Partial<CommonData>
  page_Section1: string
  page_Section2: string
}): void => {
  const dataToSend: DataToSend = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Static',
    Page_Section1: data.page_Section1,
    Page_Section2: data.page_Section2,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendNewsletterSubscriptionEvent = (email: string): void => {
  const formattedEmail = email.trim().toLowerCase()
  Log.info('ANALYTICS - send newsletter subscription event', email)
  let dataToSend = {
    id: TRACKER_NEWSLETTER_SIGNUP,
    User_Email_MD5: md5(formattedEmail).toString(),
    User_Email_SHA256: sha256(formattedEmail).toString(),
    User_EmailOptin: '1',
  }

  sendAnalyticEvent(dataToSend)
}

export const sendRegistrationEvent = (
  email: string,
  registrationPayload: any,
  acceptedNewsletterFlag?: boolean
  ): void => {
  Log.info('ANALYTICS - send registration event', email)
  let dataToSend = {
    id: TRACKER_REGISTRATION,
    User_LoginType: 'Standard',
    User_Email_MD5: md5(email.toLowerCase()).toString(),
    User_Email_SHA256: sha256(email.toLowerCase()).toString(),
    Events_UserEmailSub: Boolean(acceptedNewsletterFlag) === true ? '1' : '0',
    Events_UserAccountNew: '1',
    User_Id: registrationPayload.userId,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendSearchEvent = (searchingString: string, qty: number): void => {
  Log.info('ANALYTICS - searching event', searchingString)
  let dataToSend = {
    id: TRACKER_SEARCH,
    Search_ResultItemsQnt: qty,
    Search_Keyword: searchingString,
    Search_Type: 'text',
    Search_View: 'SERP',
  }

  sendAnalyticEvent(dataToSend)
}

export const sendPromoCodeEvent = (): void => {
  Log.info('ANALYTICS - promoCode event')
  let dataToSend = {
    id: TRACKER_PROMOCODE_TOGGLE,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendMiniCartOverlayOpenEvent = () => {
  sendAnalyticEvent({
    id: TRACKER_MINICART_OVERLAY_OPEN,
  })
}

export const sendPlpEvent = (data: {
  common?: Partial<CommonData>
  qnt: number
  products: ProductBase[]
  pageSection: string
}): void => {
  const Products = getProductsForAnalytics(data.products)
  const dataToSend: PLPData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Plp',
    Page_Section1: data.pageSection,
    Search_ResultItemsQnt: `${data.qnt}`,
    Page_Design: 'Editorial',
    Products,
  }
  sendAnalyticEvent(dataToSend)
}

export const sendDeliveryEvent = (data: {
  common?: Partial<CommonData>
  loginStatus: boolean
  products: ProductBase[]
  shipMode?: string
}): void => {
  const dataToSend: DeliveryData = {
    ...data.common,
    id: 'VirtualPage-View',
    Page_Type: 'Delivery',
    User_LoginStatus: data.loginStatus ? 'Logged' : 'Guest',
    User_LoginType: 'Standard',
    Order_ShippingMode: data.shipMode,
    Page_Section1: 'Checkout',
    Page_Section2: 'Standard',
    Products: getProductsParentForAnalytics(
      data.products.filter(
        (y) =>
          getProductType(y) !== 'Rox_lens' &&
          getProductType(y) !== 'Rox_service'
      )
    ),
  }
  sendAnalyticEvent(dataToSend)
}
