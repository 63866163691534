import { PersonFormField } from '../types/user'

export const PERSONAL_INFO_FORM_FIELDS: PersonFormField[] = [
  { fieldName: 'firstName', mandatory: 'true' },
  { fieldName: 'lastName', mandatory: 'true' },
  { fieldName: 'addressLine1', mandatory: 'true' },
  { fieldName: 'addressLine2', mandatory: 'false' },
  { fieldName: 'zipCode', mandatory: 'true' },
  { fieldName: 'city', mandatory: 'true' },
  { fieldName: 'state', mandatory: 'true' },
  { fieldName: 'country', mandatory: 'true' },
  { fieldName: 'phone1', mandatory: 'true' },
  { fieldName: 'preferredLanguage', mandatory: 'false' },
  { fieldName: 'preferredCurrency', mandatory: 'false' },
  { fieldName: 'gender', mandatory: 'false' },
  { fieldName: 'dateOfBirth', mandatory: 'false' },
  { fieldName: 'x_newsLetter', mandatory: 'false' },
  { fieldName: 'x_profiling', mandatory: 'false' },
]
