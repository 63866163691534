//Standard libraries
import React, { FC, useEffect, useMemo, useState } from 'react'
//Custom libs
import { Controller, UseFormReturn, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { CHECKOUT } from '../../../constants/common'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { useGoogleSuggestions } from '../../../hooks/useGoogleSuggestions'
import { countriesListSelector } from '../../../redux/selectors/account'
import { CheckoutAddressFormField } from '../../../types/checkout'
import { AddressFormData } from '../../../types/form'
import { StyledErrorMessage } from '../../pages/change-password/ChangePassword.style'
//UI
import { StyledGrid, StyledSelect, StyledTextField } from '../../StyledUI'
import { CircleInfoIcon, CloseIcon } from '../../StyledUI/Icons'
import {
  StyledCaliforniaWarningContainer,
  StyledCaliforniaWarningModal,
  StyledCaliforniaWarningModalBody,
  StyledCaliforniaWarningModalCloseButtonWrapper,
  StyledCaliforniaWarningModalContainer,
  StyledCaliforniaWarningModalHeader,
  StyledFormFieldGrid,
} from './AddressForm.style'
import { AddressSuggester } from './AddressSuggester'
import { ZipCodeSuggester } from './ZipCodeSuggester'

interface AddressFormProps {
  addressFormFields: CheckoutAddressFormField[]
  addressType?: AddressFormData['addressType']
  cid: string
  edit?: boolean
  form: UseFormReturn
  formName?: string
  page?: string // page name
  resetForm?: boolean
  onFormValidaTionStatusChanged?: (type: AddressFormData['addressType'], isValid: boolean) => void
  onFormDataChanged?: (type: AddressFormData['addressType'], data: AddressFormData) => void
  isDifferentCountry?: boolean
}

/**
 * Address Form component
 * to be resued to display form inputs on a shipping or billing address form
 * @param props
 */
const StyledAddressForm = styled.form`
  &.personal-info-page {
    margin-bottom: 24px;
  }
`

const AddressForm: FC<AddressFormProps> = ({
  addressFormFields,
  addressType,
  cid,
  form,
  formName,
  page = CHECKOUT, // Default page is checkout
  onFormValidaTionStatusChanged,
  onFormDataChanged,
  isDifferentCountry,
}) => {
  const storeCountry = useStoreIdentity().country.toUpperCase()
  const { t } = useTranslation()
  const { isDesktop, isTablet } = useBreakpoints()

  const [isProp65Open, setIsProp65Open] = useState<boolean>(false)
  const countriesList = useSelector(countriesListSelector)

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = form
  const addressFormData = getValues()

  const { isValid } = useFormState({ control })

  const googleSuggestions = useGoogleSuggestions({
    addressType: addressType || '',
    country: storeCountry || '',
    page,
    state: addressFormData?.state || '',
  })

  const { googleAPISuggestionEnabled, gmapCountry, gmapLoaded, provinces } = googleSuggestions

  const toggleProp65 = () => setIsProp65Open((v) => !v)

  useEffect(() => {
    const subscription = watch((value) => {
      setTimeout(() => {
        onFormDataChanged && onFormDataChanged(addressType, { ...value })
      }, 300)
    })

    return () => subscription.unsubscribe()
  }, [watch])

  useMemo(() => {
    setTimeout(() => {
      onFormValidaTionStatusChanged && onFormValidaTionStatusChanged(addressType, isValid)
    }, 300)
  }, [isValid])

  return (
    <StyledAddressForm
      id={`address_form_5_${cid}`}
      className={page === 'personal-information' ? 'personal-info-page' : ''}
      name={formName || `address_form_5_${cid}`}
      noValidate>
      <StyledGrid container spacing={isDesktop ? 2 : 1} xs={isTablet || page === CHECKOUT ? 12 : 6}>
        {addressFormFields?.map(({ fieldName, ...fieldData }, i) => {
          const key = `${cid}-${fieldName}-${i}`
          let fieldType: string

          const isZipCodeField = fieldName === 'zipCode'

          switch (fieldName) {
            case 'email1':
              fieldType = 'email'
              break
            case 'phone1':
              fieldType = 'tel'
              break
            default:
              fieldType = 'text'
              break
          }

          if (fieldName === 'addressLine1' && googleAPISuggestionEnabled && window.google) {
            return (
              <Controller
                key={key}
                name={fieldName}
                control={control}
                render={({
                  field: { value, ref, onChange, ...rest },
                  fieldState: { error, isTouched },
                }) => (
                  <AddressSuggester
                    addressFormFields={addressFormFields}
                    country={gmapCountry}
                    inputRef={ref}
                    mandatoryField={fieldData.mandatory === 'true'}
                    gMapLoaded={gmapLoaded}
                    value={value}
                    formControl={control}
                    cid={`${fieldName}-${addressType}`}
                    fieldName={fieldName}
                    fieldLabel={t(`AddressForm.Labels.${fieldName}`)}
                    error={!!error}
                    formErrors={errors}
                    onSuggestedFieldValueChanged={(fieldName, value) => {
                      setValue(fieldName, value, {
                        shouldValidate: true,
                      })
                    }}
                    ispristine={!isTouched}
                    {...rest}
                  />
                )}
              />
            )
          } else if (isZipCodeField && googleAPISuggestionEnabled && window.google) {
            return (
              <Controller
                key={key}
                name={fieldName}
                control={control}
                render={({
                  field: { value, ref, onChange, ...rest },
                  fieldState: { error, isTouched },
                }) => (
                  <StyledFormFieldGrid
                    name={fieldName}
                    item
                    iZipCodeSuggestertem
                    xs={isDesktop ? 6 : 8}>
                    <ZipCodeSuggester
                      addressFormFields={addressFormFields}
                      country={gmapCountry}
                      inputRef={ref}
                      mandatoryField={fieldData.mandatory === 'true'}
                      gMapLoaded={gmapLoaded}
                      value={value}
                      formControl={control}
                      cid={`${fieldName}-${addressType}`}
                      fieldName={fieldName}
                      fieldLabel={t(`AddressForm.Labels.${fieldName}`)}
                      error={!!error}
                      formErrors={errors}
                      ispristine={!isTouched}
                      onSuggestedFieldValueChanged={(fieldName, value) => {
                        setValue(fieldName, value, {
                          shouldValidate: true,
                        })
                      }}
                      {...rest}
                    />
                  </StyledFormFieldGrid>
                )}
              />
            )
          } else if (fieldName === 'state' && provinces && provinces.length > 0) {
            return (
              <StyledGrid item xs={12} key={key}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({ field: { value, ref, onChange, ...rest }, fieldState: { error } }) => {
                    return (
                      <>
                        <label style={{ display: 'none' }} htmlFor={`${cid}-${fieldName}`}>{fieldName}</label>
                        <StyledSelect
                          key={`${cid}-${fieldName}`}
                          inputRef={ref}
                          value={value}
                          required={fieldData.mandatory === 'true'}
                          id={`${cid}-${fieldName}`}
                          type={fieldType}
                          label={t(`AddressForm.Labels.${fieldName}`)}
                          error={!!error}
                          fullWidth
                          onChange={(event) => {
                            setValue(fieldName, event.target.value, {
                              shouldValidate: true,
                            })
                          }}
                          ispristine={value ? (value as string).length <= 0 : true}
                          {...rest}>
                          {(!provinces || provinces.length <= 0) && <option>&nbsp;</option>}
                          {provinces?.map((provinceState) => (
                            <option key={provinceState.code} value={provinceState.code || ''}>
                              {provinceState.displayName}
                            </option>
                          ))}
                        </StyledSelect>
                      </>
                    )
                  }}
                />
                {getValues().state === 'CA' && (
                  <>
                    <StyledCaliforniaWarningContainer>
                      California residents:
                      <button type="button" onClick={toggleProp65}>
                        Prop 65 Warning
                      </button>
                    </StyledCaliforniaWarningContainer>
                    <StyledCaliforniaWarningModal open={isProp65Open}>
                      <StyledCaliforniaWarningModalContainer>
                        <StyledCaliforniaWarningModalHeader>
                          <StyledCaliforniaWarningModalCloseButtonWrapper>
                            <CloseIcon onClick={toggleProp65} />
                          </StyledCaliforniaWarningModalCloseButtonWrapper>
                          <CircleInfoIcon />
                          <div>{t('AddressForm.Prop65.Heading')}</div>
                        </StyledCaliforniaWarningModalHeader>
                        <StyledCaliforniaWarningModalBody
                          dangerouslySetInnerHTML={{ __html: t('AddressForm.Prop65.Body') }}
                        />
                      </StyledCaliforniaWarningModalContainer>
                    </StyledCaliforniaWarningModal>
                  </>
                )}
              </StyledGrid>
            )
          } else if (fieldName === 'country') {
            return (
              <StyledGrid item xs={12} key={key}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({ field: { value, ref, onChange, ...rest }, fieldState: { error } }) => {
                    const options =
                      countriesList &&
                      countriesList.length > 0 &&
                      countriesList.map((val) => (
                        <option key={val.code} value={val.code || ''}>
                          {val.displayName}
                        </option>
                      ))
                    return (
                      <>
                        <label style={{ display: 'none' }} htmlFor={`${cid}-${fieldName}`}>{fieldName}</label>
                        <StyledSelect
                          key={`${cid}-${fieldName}`}
                          inputRef={ref}
                          value={value || storeCountry}
                          required={fieldData.mandatory === 'true'}
                          id={`${cid}-${fieldName}`}
                          type={fieldType}
                          label={t(`AddressForm.Labels.${fieldName}`)}
                          error={!!error || isDifferentCountry}
                          fullWidth
                          onChange={(event) => {
                            setValue(fieldName, event.target.value, {
                              shouldValidate: true,
                            })
                          }}
                          ispristine={value ? (value as string).length <= 0 : true}
                          {...rest}>
                          {(!countriesList || countriesList.length <= 0) && <option>&nbsp;</option>}
                          {options}
                        </StyledSelect>
                        {isDifferentCountry && (
                          <StyledErrorMessage>{t('AddressForm.Msgs.InvalidCountry')}</StyledErrorMessage>
                        )}
                      </>
                    )
                  }}
                />
              </StyledGrid>
            )
          } else {
            return (
              <StyledGrid item xs={isZipCodeField ? (isDesktop ? 6 : 8) : 12} key={key}>
                <Controller
                  name={fieldName}
                  control={control}
                  render={({
                    field: { value, ref, ...rest },
                    fieldState: { error, isTouched },
                  }) => (
                    <StyledTextField
                      id={`${cid}-${fieldName}`}
                      inputRef={ref}
                      value={value}
                      required={fieldData.mandatory === 'true'}
                      type={fieldType}
                      inputProps={fieldType === 'tel' ? { maxLength: 16 } : undefined}
                      label={t(`AddressForm.Labels.${fieldName}`)}
                      error={!!error}
                      helperText={errors[fieldName]?.message}
                      fullWidth
                      showValidationStatus
                      ispristine={!isTouched}
                      {...rest}
                    />
                  )}
                />
              </StyledGrid>
            )
          }
        })}
      </StyledGrid>
    </StyledAddressForm>
  )
}

export { AddressForm }
