import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Axios, { Canceler } from 'axios'
import getDisplayName from 'react-display-name'
import { PlpFilterLayout } from '../../layouts/plp-filter-page'
import { CATEGORY_DISPLAY } from '../../../constants/marketing'
import { TRIGGER_MARKETING_ACTION } from '../../../redux/actions/marketingEvent'
import { ISeo } from '../../../types/seo'

const CategoryProducts: ISeo['component'] = (props) => {
  const widgetName = getDisplayName(CategoryProducts)
  const dispatch = useDispatch()

  const { seoData } = props

  let cancels: Canceler[] = []
  const CancelToken = Axios.CancelToken

  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  useEffect(() => {
    const mtkParam = {
      categoryId: seoData.page.tokenValue,
      DM_ReqCmd: CATEGORY_DISPLAY,
      ...payloadBase,
    }
    dispatch(TRIGGER_MARKETING_ACTION(mtkParam))

    return () => {
      cancels.forEach((cancel) => cancel())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <PlpFilterLayout cid={seoData.page.externalContext.identifier} seoData={seoData} />
}

export default CategoryProducts
