import React, { useEffect } from 'react'
import { useSite } from '../../foundation/hooks/useSite'
import useExternalScript from '../../hooks/useExternalScript'
import { klarnaScriptPathSelector, klarnaAPIIdSelector } from './../../redux/selectors/site'
import { themeModeSelector } from '../../redux/selectors/theme'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'
import Log from '../../services/Log'
import { cartSummaryDrawerSelector } from '../../redux/selectors/ui'

interface Props {
  grandTotal?: string | number | null
  variant?:
  | 'credit-promotion-badge'
  | 'credit-promotion-standard'
  | 'credit-promotion-auto-size'
  | 'info-page'
  isStickyBarDisplayed?: boolean
}

const KlarnaMessageContainer = styled.div<{
  themeMode?: string
  isStickyBarDisplayed?: boolean
}>`${({ theme, themeMode, isStickyBarDisplayed }) => `
  position: relative;

  && *::part(osm-container) {
    font-family: ${theme.typography.fontFamily};
    background: none;
    border:none;
    padding: ${isStickyBarDisplayed ? '10px 0 10px 0' : '10px 0 0 0'};
    text-align: left;
    color: ${themeMode === 'dark' ? theme.palette.custom.warmGrey : theme.palette.text.light};
  }

  && *::part(osm-logo) {
    color: ${themeMode === 'dark' ? theme.palette.custom.warmGrey : theme.palette.text.light};
  }

  && *::part(osm-cta) {
    color: ${themeMode === 'dark' ? theme.palette.custom.warmGrey : theme.palette.text.light};
  }

  && *::part(osm-legal) {
    font-size: 12px;
    color: ${themeMode === 'dark' ? theme.palette.custom.warmGrey : theme.palette.text.light};
  }

  && *::part(osm-message) {
    line-height: 21px;
  } 
`};
`

const KlarnaOnSiteMessage: React.FC<Props> = ({
  grandTotal,
  variant = 'credit-promotion-auto-size',
  isStickyBarDisplayed
}) => {
  const { mySite } = useSite()
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const klarnaAPIId = useSelector(klarnaAPIIdSelector)
  const cartSummaryDrawerOpen = useSelector(cartSummaryDrawerSelector)
  const urlKlarna = useSelector(klarnaScriptPathSelector)
  const isTestEnv = (klarnaAPIId === '0959b184-d4db-579b-97ef-fc581add976e' || klarnaAPIId === '195af5d7-3e0d-5ffa-9020-9407d0e005a7')

  Log.info('env', process.env.NODE_ENV)
  const [isLoaded, KlarnaOnsiteService] = useExternalScript(
    urlKlarna || 'https://osm.klarnaservices.com/lib.js',
    'KlarnaOnsiteService',
    {
      id: 'klarna-on-site-message',
      'data-environment': isTestEnv ? 'playground' : 'production',
      'data-clientId': klarnaAPIId || '',
    }
  )
  const dataLocale = mySite.locale.replace('_', '-')
  useEffect(() => {
    if (isLoaded && grandTotal) {
      // window.Klarna.OnsiteMessaging.refresh()
      KlarnaOnsiteService?.refresh()
      /* KlarnaOnsiteService?.push({
        eventName: 'refresh-placements',
      }) */
    }
  }, [isLoaded, grandTotal])

  const grandTotalWithCents = (
    (typeof grandTotal !== 'number'
      ? parseFloat(grandTotal || '0')
      : grandTotal) * 100
  )

  return (
    <KlarnaMessageContainer themeMode={cartSummaryDrawerOpen ? 'light' : themeMode} isStickyBarDisplayed={isStickyBarDisplayed}>
      <klarna-placement
        data-key={variant}
        data-locale={dataLocale}
        data-purchase-amount={`${grandTotalWithCents.toFixed(0)}`}
      />
    </KlarnaMessageContainer>
  )
}

export default KlarnaOnSiteMessage
