import { PriceModel, ProductXPrice } from '../../../../types/product'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import styled, { CSSProperties } from 'styled-components'
import CurrencyService from '../../../../services/CurrencyService'
import ProductService from '../../../../services/ProductService'
import { themeModeSelector } from '../../../../redux/selectors/theme'
import { useTranslation } from 'react-i18next'
import KlarnaOnSiteMessage from './../../../klarna/KlarnaOnSiteMessage'
import { StyledVATElement } from '../styled-components/ProductDetailsStyledComponents'
import { pdpTaxesSelector } from '../../../../redux/selectors/site'
import { usePageType } from '../../../../foundation/hooks/usePageType'

const ProductPriceContainer = styled.div<{
  columnGap: CSSProperties['columnGap'];
  justifyContent: CSSProperties['justifyContent'];
}>(({ columnGap, justifyContent }) => ({
  display: 'flex',
  justifyContent: justifyContent,
  alignItems: 'baseline',
  columnGap: columnGap,
}))

const ProductPriceDiv = styled.div<{
  flexOrder?: FlexOrderVariants
  fontSizeMobile?: ProductPriceSize
  fontSizeDesktop?: ProductPriceSize
  themeMode?: string
  pageType?: string | null | undefined
}>`
  ${({ fontSizeMobile, fontSizeDesktop, theme }) => `
  font-size: ${fontSizeMobile};
  line-height: normal;
  display: flex;
  font-size: 16px;

  ${theme.breakpoints.up('sm')} {
    font-size: ${fontSizeDesktop || fontSizeMobile};
    ${(fontSizeDesktop || fontSizeMobile) === PRODUCT_PRICE_SIZES.lg ? 'line-height: 32px' : ''};
  }
`}
`

const CurrentPrice = styled(ProductPriceDiv)`
  gap: ${({ theme }) => `${theme.spacing(0.25)}px`};
 
  ${({ theme }) => `
  color: ${theme.palette.text.primary};
  
  .product-price__current-value_black {
    color: ${theme.palette.custom.black};
  }
`};
`

const InitialPrice = styled(ProductPriceDiv)`
  ${({ theme, themeMode }) => `
  text-decoration: line-through;
  color: ${themeMode === 'dark' ? theme.palette.text.greyDarkmode : theme.palette.text.greyLightmode};
  font-size: 16px;
`}
`

const DiscountWrapper = styled(ProductPriceDiv)`
  align-items: center;

  ${({ theme }) => `
    display: block;

    ${theme.breakpoints.up('sm')} {
      display: flex;
      flex-direction: column;
    }
  `}
`

const PriceWrapper = styled(ProductPriceDiv)`
  display: flex;
  flex-direction: row;
  gap:${({ theme }) => `${theme.spacing(0.5)}px`};
  font-size: 16px;
`

const DiscountAmount = styled(ProductPriceDiv)`
  ${({ fontSizeMobile, fontSizeDesktop, theme }) => `
  line-height: 18px;
  padding: 0 2px;
  color: ${theme.palette.secondary.contrastText};
  background-color: ${theme.palette.custom.geckoGreen};
  width: fit-content;
  white-space: nowrap;
  
  ${theme.breakpoints.up('sm')} {
    font-size: ${fontSizeDesktop || fontSizeMobile};
  }
`}
`


const ProductPriceWrapper = styled.div``

export const PRODUCT_PRICE_SIZES = {
  sm: '0.75rem',
  md: '1rem',
  lg: '1.75rem',
} as const

type SizeKeys = keyof typeof PRODUCT_PRICE_SIZES
type ProductPriceSize = typeof PRODUCT_PRICE_SIZES[SizeKeys]

// With value = 2 price will be displayed as '120.00'. If value has decimal itself, it will be displayed anyway.
type ProductPriceDecimalDisplay = {
  current?: number
  initial?: number
}

type FlexOrderVariants = 1 | 2 | 3 | 4
type ProductPriceOrders = {
  current?: FlexOrderVariants
  initial?: FlexOrderVariants
  discountAmount?: FlexOrderVariants
  vatElement?: FlexOrderVariants
}

type ProductPriceSizes = {
  current?: ProductPriceSize
  currentDesktop?: ProductPriceSize
  initial?: ProductPriceSize
  initialDesktop?: ProductPriceSize
  discountAmount?: ProductPriceSize
  discountAmountDesktop?: ProductPriceSize
}

export interface ProductPriceProps {
  x_prices?: {
    offer?: ProductXPrice
    list?: ProductXPrice
  }

  decimals?: ProductPriceDecimalDisplay
  orders?: ProductPriceOrders
  columnGap?: CSSProperties['columnGap']
  justifyContent?: CSSProperties['justifyContent']
  showDiscountAmount?: boolean
  showPrefixForCurrent?: boolean // show "from" label before the initial price
  sizes?: ProductPriceSizes
  showVAT?: boolean
  fromTile?: boolean
  isPDP?: boolean
  isStickyBarDisplayed?: boolean
  discountedPriceBadges?: any
}

const DEFAULT_SIZES = {
  current: PRODUCT_PRICE_SIZES.sm,
  currentDesktop: PRODUCT_PRICE_SIZES.md,
  initial: PRODUCT_PRICE_SIZES.sm,
  initialDesktop: PRODUCT_PRICE_SIZES.md,
  discountAmount: PRODUCT_PRICE_SIZES.sm,
  discountAmountDesktop: PRODUCT_PRICE_SIZES.md,
}
interface VatProduct {
  isVatIncluded?: boolean
}

const ProductPrice: FC<ProductPriceProps> = ({
  decimals = {
    current: 0,
    initial: 0,
  },
  orders = {
    current: 1,
    initial: 2,
    discountAmount: 3,
    vatElement: 4,
  },
  columnGap = '7px',
  justifyContent = 'flex-start',
  showDiscountAmount,
  showPrefixForCurrent,
  sizes: sizesProp,
  x_prices,
  showVAT = true,
  fromTile = false,
  isPDP = false,
  isStickyBarDisplayed,
  discountedPriceBadges
}) => {
  const { t } = useTranslation()
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const TaxesSelector = useSelector(pdpTaxesSelector)
  const { pageType } = usePageType()

  const sizes = useMemo(() => {
    if (!sizesProp) {
      return DEFAULT_SIZES
    }

    const {
      current,
      currentDesktop,
      initial,
      initialDesktop,
      discountAmount,
      discountAmountDesktop,
    } = sizesProp
    const result: ProductPriceSizes = {
      current: current || DEFAULT_SIZES.current,
      currentDesktop: currentDesktop || current || DEFAULT_SIZES.currentDesktop,
      initial: initial || DEFAULT_SIZES.initial,
      initialDesktop: initialDesktop || initial || DEFAULT_SIZES.initialDesktop,
      discountAmount: discountAmount || DEFAULT_SIZES.discountAmount,
      discountAmountDesktop:
        discountAmountDesktop || discountAmount || DEFAULT_SIZES.discountAmountDesktop,
    }
    return result
  }, [sizesProp])

  const getDiscount = useCallback(
    (current: PriceModel, initial: PriceModel): number => {
      if (!(current && initial && current.price && initial.price)) {
        return 0
      }

      const numberCurrent = Number(current.price)
      const numberInitial = Number(initial.price)

      if (!isNaN(numberCurrent) && !isNaN(numberInitial)) {
        return ((numberInitial - numberCurrent) / numberInitial) * 100
      } else {
        return 0
      }
    },
    []
  )


  const initialPrice = useMemo(() => {
    if (!x_prices?.list) return null

    const prices = ProductService.getXPriceModel(x_prices.list)

    if (!prices) return null

    return prices
  }, [x_prices])

  const [vat, setVat] = useState<VatProduct>({
    isVatIncluded: false,
  })

  const currentPrice = useMemo(() => {
    const prices =
      x_prices?.offer && ProductService.isValidXPriceDate(x_prices.offer)
        ? ProductService.getXPriceModel(x_prices.offer)
        : initialPrice

    if (!prices) return null
    const currentVAT = {
      isVatIncluded: TaxesSelector,
    }

    setVat(currentVAT)
    return prices
  }, [x_prices, initialPrice])

  const discountAmount = useMemo<number>(() => {
    if (!(currentPrice && initialPrice)) {
      return 0
    }

    return getDiscount(currentPrice, initialPrice)
  }, [currentPrice, initialPrice])

  const formatNumberWithDecimals = useCallback((value: number, decimalValue: number): string => {
    if (decimalValue === 0) {
      return value % 1 === 0 ? value.toString() : value.toFixed(2).toString()
    }

    return value.toFixed(decimalValue)
  }, [])

  const hasInitialPriceDiscount = !!(
    discountAmount &&
    initialPrice &&
    initialPrice?.price &&
    +initialPrice.price > 0
  )


  const discountAmountLabel = useMemo<string>(() => {
    if (!(currentPrice && initialPrice && currentPrice.price && initialPrice.price)) {
      return ''
    } else {
      if (!!hasInitialPriceDiscount) {
        return discountedPriceBadges
      }
      else {
        return ''
      }
    }
  }, [hasInitialPriceDiscount, initialPrice, currentPrice])

  return (
    <ProductPriceWrapper>
      <ProductPriceContainer
        className="product-price"
        columnGap={columnGap}
        justifyContent={justifyContent}
      >
        {pageType !== 'plp' && !fromTile ? (
          <>
            {!!hasInitialPriceDiscount  && initialPrice && (initialPrice !== currentPrice) && (
              <InitialPrice
                themeMode={themeMode}
                className="product-price__initial"
                flexOrder={orders.initial}
                fontSizeMobile={sizes.initial}
                fontSizeDesktop={sizes.initialDesktop}
              >
                {CurrencyService.getSymbolByName(initialPrice?.currency)}
                {formatNumberWithDecimals(+initialPrice?.price, decimals.initial!)}
              </InitialPrice>
            )}
            {currentPrice && (
              <CurrentPrice
                className="product-price__current"
                flexOrder={orders.current}
                fontSizeMobile={sizes.current}
                fontSizeDesktop={sizes.currentDesktop}>
                {showPrefixForCurrent && (
                  <span className="product-price__current-prefix">{t('ProductTile.Labels.from')}</span>
                )}
                <span itemProp="price" className="product-price__current-value">
                  {CurrencyService.getSymbolByName(currentPrice.currency)}
                  {formatNumberWithDecimals(+currentPrice.price, decimals.current!)}
                </span>
              </CurrentPrice>
            )}
            {showDiscountAmount && !!hasInitialPriceDiscount  && (initialPrice !== currentPrice) && (discountAmountLabel !== undefined) && (
              <DiscountAmount
                className="product-price__discount"
                flexOrder={orders.discountAmount}
                fontSizeMobile={sizes.discountAmount}
                fontSizeDesktop={sizes.discountAmountDesktop}
              >
                {discountAmountLabel}
              </DiscountAmount>
            )}
          </>
        ) : (
          <DiscountWrapper>
            <PriceWrapper>
              {!!hasInitialPriceDiscount  && initialPrice && (initialPrice !== currentPrice) && (
                <InitialPrice
                  themeMode={themeMode}
                  className="product-price__initial"
                  flexOrder={orders.initial}
                  fontSizeMobile={sizes.initial}
                  fontSizeDesktop={sizes.initialDesktop}
                >
                  {CurrencyService.getSymbolByName(initialPrice?.currency)}
                  {formatNumberWithDecimals(+initialPrice?.price, decimals.initial!)}
                </InitialPrice>
              )}
              {currentPrice && (
                <CurrentPrice
                  className="product-price__current"
                  flexOrder={orders.current}
                  fontSizeMobile={sizes.current}
                  fontSizeDesktop={sizes.currentDesktop}>
                  {showPrefixForCurrent && (
                    <span className="product-price__current-prefix">{t('ProductTile.Labels.from')}</span>
                  )}
                  <span itemProp="price" className="product-price__current-value">
                    {CurrencyService.getSymbolByName(currentPrice.currency)}
                    {formatNumberWithDecimals(+currentPrice.price, decimals.current!)}
                  </span>
                </CurrentPrice>
              )}
            </PriceWrapper>
            {showDiscountAmount && !!hasInitialPriceDiscount  && (initialPrice !== currentPrice) && (discountAmountLabel !== undefined) && (
              <DiscountAmount
                className="product-price__discount"
                flexOrder={orders.discountAmount}
                fontSizeMobile={sizes.discountAmount}
                fontSizeDesktop={sizes.discountAmountDesktop}
              >
                {discountAmountLabel}
              </DiscountAmount>
            )}
          </DiscountWrapper>
        )}
        {(!vat.isVatIncluded && showVAT) && <StyledVATElement>{t('VAT')}</StyledVATElement>}
      </ProductPriceContainer>
      {(isPDP && !fromTile) &&
        <KlarnaOnSiteMessage
          isStickyBarDisplayed={isStickyBarDisplayed}
          variant={'credit-promotion-auto-size'}
          grandTotal={
            !!currentPrice
              ? formatNumberWithDecimals(+currentPrice.price, decimals.current!)
              : ''
          }
        />
      }
    </ProductPriceWrapper>
  )
}

export default ProductPrice
