import { Typography, TypographyTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import React from 'react'
import styled from 'styled-components'

type StyledTypographyCustomProps = {
  component?: string
  fontColor?: string
  fontSize?: string
  fontWeight?: string
  isUnderlined?: boolean
  isUppercased?: boolean
  lineHeight?: string
  margin?: string
}

const StyledTypography: OverridableComponent<
  TypographyTypeMap<StyledTypographyCustomProps>
> = styled(
  ({
    fontColor,
    fontSize,
    fontWeight,
    isUnderlined,
    isUppercased,
    lineHeight,
    margin,
    ...props
  }) => <Typography {...props} />
)<StyledTypographyCustomProps>`
  ${({ fontColor, fontSize, fontWeight, isUnderlined, isUppercased, lineHeight, margin }) => `
  ${margin ? `margin: ${margin};` : ''}
  ${isUnderlined ? 'text-decoration: underline;' : ''}
  ${isUppercased ? 'text-transform: uppercase;' : ''}
  ${fontColor ? `color: ${fontColor};` : ''}
  ${fontSize ? `font-size: ${fontSize};` : ''}
  ${fontWeight ? `font-weight: ${fontWeight};` : ''}
  ${lineHeight ? `line-height: ${lineHeight};` : ''}
`}
`

export default StyledTypography
