import { ListItem, ListItemIcon, Collapse, List } from '@material-ui/core'
import React, { memo, useState } from 'react'
import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { NavigationItem } from '../../../types/menu'
import { UnicodeArrowDownIcon, UnicodeArrowUpIcon } from '../../StyledUI/Icons/Icons'
import { CountryBaseLink } from '../../StyledUI/StyledLink/StyledLink'

interface HamburgerItemProps extends Pick<NavigationItem, 'teaserText'> {
  className?: string
  'data-element-id'?: string
  icon?: React.ReactNode
  isHighlighted: boolean
  href?: string
  title: string
}

/*
  TODO: when HamburgerItem's children are > 0, should show an accordion instead.
  TODO: each accordion's item should have a proper href.
  TODO: when HamburgerItem's children are > 0, his own href should be useless, and open
  TODO: accordion instead.
*/
const StyledList = styled(List).attrs({ component: 'div' })`
  margin-left: ${({ theme }) => theme.spacing(2)};
`

const StyledListItem = styled(ListItem)`
  padding-right: 10px;
  padding-left: 20px;
  height: 64px;
`

const StyledListItemTitleWrapper = styled.div`
  flex: 1;
`

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: auto;
  margin-right: 0.5rem;
`

const StyledCountryBaseLink = styled(CountryBaseLink)`
  display: flex;
`

const StyledListItemText = styled.span<{ isHighlighted: boolean }>`
  ${({ isHighlighted, theme }) => isHighlighted && `padding: 0 ${theme.spacing(0.25)}px`};

  ${({ isHighlighted, theme }) =>
    isHighlighted && `background-color: ${theme.palette.custom.geckoGreen}`};

  font-size: 16px;
  line-height: 1.5;

  color: ${({ isHighlighted, theme }) =>
    theme.palette.custom[isHighlighted ? 'black' : 'warmGrey']};
`

const MenuItemTeaserLabel = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  padding: 0 ${({ theme }) => theme.spacing(0.25)}px;
  background-color: ${({ theme }) => theme.palette.custom.geckoGreen};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`

const CurrentListItem = ({
  childrenLength,
  className,
  icon,
  isHighlighted,
  open,
  teaserText,
  title,
  handleOnClick,
}) => (
  <StyledListItem button className={className} onClick={handleOnClick}>
    {icon && <StyledListItemIcon>{icon}</StyledListItemIcon>}

    <StyledListItemTitleWrapper>
      <StyledListItemText isHighlighted={isHighlighted}>{title}</StyledListItemText>
      {teaserText && <MenuItemTeaserLabel>{teaserText}</MenuItemTeaserLabel>}
    </StyledListItemTitleWrapper>

    {childrenLength > 0 && (open ? <UnicodeArrowUpIcon /> : <UnicodeArrowDownIcon />)}
  </StyledListItem>
)

const HamburgerItem = memo<PropsWithChildren<HamburgerItemProps>>(
  (props) => {
    const {
      className = '',
      children,
      'data-element-id': dataElementId,
      href,
      icon,
      isHighlighted = false,
      teaserText,
      title,
    } = props

    const childrenLength = React.Children.count(children)
    const [open, setOpen] = useState<boolean>(false)

    const handleOnClick = () => {
      // Handle accordion
      if (childrenLength > 0) {
        setOpen((prevState) => !prevState)
      }
    }

    const currentListItemProps = {
      childrenLength,
      className,
      'data-element-id': dataElementId,
      icon,
      isHighlighted,
      open,
      teaserText,
      title,
      handleOnClick,
    }

    return (
      <>
        {href && childrenLength === 0 ? (
          <StyledCountryBaseLink to={href}>
            <CurrentListItem {...currentListItemProps} />
          </StyledCountryBaseLink>
        ) : (
          <CurrentListItem {...currentListItemProps} />
        )}

        {childrenLength > 0 && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <StyledList disablePadding>{children}</StyledList>
          </Collapse>
        )}
      </>
    )
  },
  (prev, next) => prev.href === next.href && prev.title === next.title
)

export default HamburgerItem
