import { Box } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import HeaderLink, { highlightedHeaderLinkCss } from './HeaderLink'
import { HeaderNavigationItem } from '../../types/menu'
import { usePageType } from '../../foundation/hooks/usePageType'

interface Props {
  items: HeaderNavigationItem[]
}

const StyledSubHeader = styled(Box) <{ isPlpOrPdp: boolean }>`
  padding: ${({ theme }) =>
    theme.spacing(0.5)}px 0 ${({ theme }) => theme.spacing(0.625)}px ${({ theme }) => theme.spacing(15.9)}px;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.palette.background.dark};
  height: 41px;
  width: 100%;
  position: ${({ isPlpOrPdp }) => isPlpOrPdp ? 'relative' : 'absolute'};
  z-index: 1;
`

const StyledSubHeaderLink = styled(HeaderLink) <{ $isHighlighted: boolean }>`
  ${highlightedHeaderLinkCss}
  &[href] {
    padding: 0 ${({ theme }) => theme.spacing(0.25)}px 0 ${({ theme }) => theme.spacing(0.125)}px;
  }
  color: ${({ theme, $isHighlighted }) => $isHighlighted ? theme.palette.text.light : theme.palette.text.dark};
  text-transform: none !important;
  display: flex;

  &:hover: {
    display: none;
  }
`

const SubHeaderItemTeaserWrapper = styled.div`
  display: flex;
`

const SubHeaderItemTeaserLabel = styled.div`
  margin-left: ${({ theme }) => theme.spacing(0.1875)}px;
  padding: 0 ${({ theme }) => theme.spacing(0.25)}px;
  background-color: ${({ theme }) => theme.palette.custom.geckoGreen};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`

const Subheader: React.FC<Props> = ({ items }) => {
  const { pageType } = usePageType()

  return (
    <StyledSubHeader className="arn-header__subheader" isPlpOrPdp={pageType === 'plp' || pageType === 'pdp'}>
      {items.map(
        ({
          id,
          hiddenInDesktopNavigation,
          href,
          isHighlightedInNav,
          selected,
          title,
          teaserText,
        }) =>
          hiddenInDesktopNavigation ? null : (
            <>
              <StyledSubHeaderLink
                key={id + href}
                data-element-id={`X_X_${id}`}
                to={href}
                variant={selected ? 'primaryUnderlined' : 'primaryHoverUnderlined'}
                $isHighlighted={isHighlightedInNav}
                $isUnderlined={selected!}
              >
                {teaserText ? (
                  <SubHeaderItemTeaserWrapper>
                    {`${title}`}
                    <SubHeaderItemTeaserLabel>{`${teaserText}`}</SubHeaderItemTeaserLabel>
                  </SubHeaderItemTeaserWrapper>
                ) : (
                  <div>{title}</div>
                )}
              </StyledSubHeaderLink>
            </>
          )
      )}
    </StyledSubHeader>
  )
}

export default Subheader
