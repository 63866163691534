import groupBy from 'lodash/groupBy'
import { Order, OrderItem, OrderItemWithRoxProps, RoxableAttributes } from './../types/order'
import {
  PRODUCT_PRICE_USAGE_NAMES,
  DetailedRxPrice,
  ProductPrice
} from '../types/product'
import { getIsPolarized, getLensesColor, getProductAttribute, getProductType } from './productAttributes'
import ProductService from '../services/ProductService'

export const getRxAttributes = (lensObject): RoxableAttributes => {
  return {
    correctionType: getProductAttribute(lensObject, 'CORRECTION_TYPE'),
    lensColor: getLensesColor(lensObject),
    lensThickness: getProductAttribute(lensObject, 'LENS_THICKNESS'),
    lensTreatment: getProductAttribute(lensObject, 'LENS_TREATMENT'),
    polarized: getIsPolarized(lensObject) === 'True',
  }
}

export const isRxCart = (orderExtendAttribute: Order['orderExtendAttribute']): boolean => {
  return !!orderExtendAttribute?.find(
    (a) => a.attributeName === 'IS_ROX_ORDER' && a.attributeValue === 'true'
  )
}

export const isRxProduct = (
  // @ts-expect-error
  orderItemExtendAttribute: Order['orderItemExtendAttribute']
): boolean => {
  return !!orderItemExtendAttribute?.find((a) => {
    return (a.attributeName === 'IsRoxLens' && a.attributeValue === 'true') || (a.attributeName === 'IsRox' && a.attributeValue === 'true')
  })
}

export const isRxLens = (
  // @ts-expect-error
  orderItemExtendAttribute: Order['orderItemExtendAttribute']
): boolean => {
  return !!orderItemExtendAttribute?.find((a) => {
    return (a.attributeName === 'IsRoxLens' && a.attributeValue === 'true')
  })
}

export const getGroupedFrames = (orderItems: OrderItem[]) => {
  return groupBy(orderItems || [], (item) => item.xitem_field1 || item.orderItemId)
}

export const parseOrderItems = (items: OrderItem[]): OrderItem[] | OrderItemWithRoxProps[] => {
  const groupedFrames = getGroupedFrames(items)
  const groupedFrameValues = Object.values(groupedFrames)

  return groupedFrameValues.map((framesArray) => {
    if (framesArray.length > 1) {
      const lens = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find((a) => a.attributeName === 'IsRoxLens')
      )

      const frame = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find((a) => a.attributeName === 'RxLensId')
      )

      const roxableServices = framesArray.filter((item) => item?.orderItemId !== frame?.orderItemId)

      const roxableAttributes = getRxAttributes(lens)

      return { ...frame, roxableAttributes, roxableServices } as OrderItemWithRoxProps
    } else return framesArray[0]
  })
}

export const parseRxOrderItems = (
  items: OrderItem[]
): OrderItem[] | OrderItemWithRoxProps[] => {
  const groupedFrames = getGroupedFrames(items)
  const groupedFrameValues = Object.values(groupedFrames)

  return groupedFrameValues.map((framesArray) => {
    if (framesArray.length > 1) {
      const lens = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find((a) => a.attributeName === 'IsRoxLens')
      )

      const frame = framesArray.find(({ orderItemExtendAttribute }) =>
        orderItemExtendAttribute.find((a) => a.attributeName === 'RxLensId')
      )

      const roxableServices = framesArray.filter(
        (item) => item?.orderItemId !== frame?.orderItemId
      )
      const isPrescriptionUploadedForLens = roxableServices
        ? orderHasPrescriptionUploaded(roxableServices[0])
        : false
      const roxableAttributes = getRxAttributes(lens)

      return {
        ...frame,
        prescriptionUploaded: isPrescriptionUploadedForLens,
        roxableAttributes,
        roxableServices,
        prescriptionDetails: lens?.prescriptionDetails || null,
      } as OrderItemWithRoxProps
    } else return framesArray[0]
  })
}

export const orderHasPrescriptionUploaded = (
  oi: OrderItem | OrderItemWithRoxProps
): boolean => {
  try {
    return !!oi.prescriptionDetails
  } catch (e) {
    return false
  }
}

export const getRxPrice = (rxServices: any[], rxFramePrice: string | undefined): number => {
  return (
    rxServices.reduce((accumulator, currentValue) => {
      return (
        accumulator + parseFloat(currentValue?.x_offerpriceRx || currentValue?.orderItemPrice || 0)
      )
    }, 0) + parseFloat(rxFramePrice || '0')
  )
}

export const getDetailedRxPrice = (rxServices: OrderItem[]): DetailedRxPrice[] => {
  return rxServices.map(service => ({
    type: getProductType(service),
    currentPrice: {
      usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
      currency: service?.currency ?? 'USD',
      value: service?.x_offerDiscountpriceRx ?? service?.x_offerpriceRx ?? '0'
    },
    initialPrice: {
      usage: PRODUCT_PRICE_USAGE_NAMES.INITIAL,
      currency: service?.currency ?? 'USD',
      value: service?.x_offerpriceRx ?? '0'
    }
  }))
}

export const getFormattedTotalRxPrice = (rxPrices: DetailedRxPrice[]) => {
  const { initialPrice, currentPrice } = rxPrices[0]

  return rxPrices.reduce((total, price, index) => {
    const [initialPriceModel, currentPriceModel] = total

    return index ? [
      { ...initialPriceModel, value: String(+initialPriceModel.value + +price.initialPrice.value) },
      { ...currentPriceModel, value: String(+currentPriceModel.value + +price.currentPrice.value) }
    ] : total
  }, [initialPrice, currentPrice])
}

export const getDetailedPrices = (productPrices: ProductPrice[], rxPrices?: OrderItem[], offerpriceRx?: string, offerDiscountpriceRx?: string) => {
  const formattedPrice = ProductService.genPriceWithoutValue(productPrices)

  const detailedRxPrice = rxPrices ? getDetailedRxPrice(rxPrices) : null
  const totalRxPrice = detailedRxPrice ? getFormattedTotalRxPrice(detailedRxPrice) : null

  const initialFramePrice = ProductService.getInitialPriceModel(formattedPrice) as ProductPrice
  const currentFramePrice = ProductService.getCurrentPriceModel(formattedPrice) as ProductPrice

  const initialFrameRXPrice = {
    usage: PRODUCT_PRICE_USAGE_NAMES.INITIAL,
    currency: (rxPrices && rxPrices[0].currency) ?? 'USD',
    value: offerpriceRx ?? '0'
  } as ProductPrice

  const currentFrameRXPrice = {
    usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
    currency: (rxPrices && rxPrices[0].currency) ?? 'USD',
    value: offerDiscountpriceRx ?? offerpriceRx ?? '0'
  } as ProductPrice

  const initialRxProductsPrice = totalRxPrice ? ProductService.getInitialPriceModel(totalRxPrice) : null
  const currentRxProductsPrice = totalRxPrice ? ProductService.getCurrentPriceModel(totalRxPrice) : null

  const totalCurrentPrice = ProductService.calculateTotalPriceModel(currentRxProductsPrice
    ? [currentFrameRXPrice, currentRxProductsPrice]
    : [currentFramePrice])
  const totalInitialPrice = ProductService.calculateTotalPriceModel(initialRxProductsPrice
    ? [initialFrameRXPrice, initialRxProductsPrice]
    : [initialFramePrice])

  return {
    initialFramePrice,
    currentFramePrice,
    initialFrameRXPrice,
    currentFrameRXPrice,
    initialRxProductsPrice,
    currentRxProductsPrice,
    totalInitialPrice,
    totalCurrentPrice
  }
}
