import React, { useContext } from 'react'
import {
  StyledMoonIcon,
  StyledSunIcon,
  StyledThemeSwitcherInput,
  StyledThemeSwitcherLabel,
  StyledThemeSwitcherWrapper,
} from './ThemeSwitcher.style'
import { ThemeContext } from '../../../themes/EcommThemeProvider'
import { sendAnalyticEventRaw } from '../../../foundation/analytics/tealium/lib'
import { useBodyClass } from '../../../foundation/hooks/useBodyClass'

export const ThemeSwitcher: React.FC = () => {
  const { currentTheme, setTheme } = useContext<any>(ThemeContext)
  const isDark = Boolean(currentTheme === 'dark')
  const switcherRef = React.useRef(null)

  const analyticsData = {
    id: 'Click',
    Tracking_Type: 'hiddenaction',
    Click_FocusElement: switcherRef.current && switcherRef.current,
  }

  useBodyClass('is-dark-theme', isDark)

  const handleThemeChange = () => {
    sendAnalyticEventRaw(analyticsData)

    if (isDark) {
      setTheme('light')
    } else {
      setTheme('dark')
    }
  }

  return (
    <StyledThemeSwitcherWrapper data-element-id="X_X_MainNav_Brightness" ref={switcherRef}>
      <StyledThemeSwitcherInput
        id="theme_mode_switcher"
        type="checkbox"
        checked={isDark}
        onChange={handleThemeChange}
      />
      <StyledThemeSwitcherLabel htmlFor="theme_mode_switcher" tabIndex={0} onKeyPress={handleThemeChange}>
        <StyledMoonIcon className="night" aria-label="night" />
        <StyledSunIcon className="day" aria-label="day" />
      </StyledThemeSwitcherLabel>
    </StyledThemeSwitcherWrapper>
  )
}
