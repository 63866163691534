/**
 * The returned function is executed only once and the result is
 * * @param {string}
 * * @returns {string}
 */
const executedList: Record<string, unknown> = {}
/**
 *
 */
export function executeOnce<Args extends unknown[], Return>(
  cb: (...args: Args) => Return,
  id: string
) {
  return (...args: Args): Return => {
    const hasBeenExecuted = id in executedList
    if (hasBeenExecuted) return executedList[id] as Return

    const returnedValue = cb(...args)
    executedList[id] = returnedValue
    return returnedValue
  }
}

export const sanitizeHtml = (str: string): string => {
  return typeof str === 'string' ? str.replace(/(<([^>]+)>)/gi, '') : ''
}
