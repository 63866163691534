//Standard libraries
import { Action } from 'redux'
import { configureStore, PayloadAction } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
//Redux
import rootReducer from '../reducers/index'
import rootSaga from '../sagas/index'
import { persistReducers, loadState } from '../../localStorage'
import { orderApi } from '../../features/order/query'
import { accountApi } from '../../features/account/query'
import listenerMiddleware from '../listener'
import { cmsApi } from '../../features/cms/query'

const persistedState = loadState()
const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: false,
      thunk: true,
    })
      .prepend(listenerMiddleware.middleware)
      .concat(
      orderApi.middleware,
      cmsApi.middleware,
      accountApi.middleware,
      sagaMiddleware,
    ),
  devTools: process.env.NODE_ENV !== 'production' ? { trace: true, traceLimit: 25 } : false,
  preloadedState: persistedState,
  enhancers: [],
})

sagaMiddleware.run(rootSaga)

export type AppStore = typeof store
export type AppState = ReturnType<typeof rootReducer>
export type AppReducerState = Parameters<typeof rootReducer>[0]
export type AppDispatch = typeof store.dispatch

type ExtraProps = {}

export type AppPayloadWithWidget<T = ExtraProps> = { widget: string } & T
export type AppResponseAction<R = ExtraProps, T = string> = Action<T> & { response: R }
export type AppPayloadWithWidgetAction<P = ExtraProps, T extends string = string> = PayloadAction<
  AppPayloadWithWidget<P>,
  T
>

persistReducers(store, 'theme')

export default store
