import { IAccountActiveComponent, generateAccountPagePath } from '../account/Account'
import {
  OrderHistoryBackLink,
  OrderHistoryContentWrapper,
  OrderHistoryTitleWrapper,
  StyledAnchorAsButton,
} from './OrderHistory.style'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StyledLoader, StyledTypography } from '../../StyledUI'
import Table, { TableCell } from '../../StyledUI/StyledTable/Table'
import { buildOrderHistoryCells, buildOrderHistoryHeaders } from '../../../constants/order'
import { ACCOUNT_CHILDREN } from '../../../constants/routes'
import Flexbox from '../../StyledUI/Flexbox/Flexbox'
import { Order } from '../../../types/order'
import OrderDetails from './OrderDetails'
import { OrderDetailsFooter } from './OrderDetails.style'
import { OrderHistoryList } from './OrderHistoryList'
import OrderService from '../../../foundation/apis/transaction/order.service'
import Pagination from '../../StyledUI/StyledTable/Pagination'
import { UnicodeArrowLeftIcon } from '../../StyledUI/Icons/Icons'
import theme from '../../../themes'
import { useMediaQuery } from '@material-ui/core'
import useRouteQuery from '../../../foundation/hooks/useRouteQuery'
import { shallowEqual, useSelector } from 'react-redux'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'
import { userIdSelector } from '../../../redux/selectors/user'
import { themeModeSelector } from '../../../redux/selectors/theme'
import usePagination from '../../../hooks/usePagination/usePagination'

/**
 * `@name OrderHistory`
 *
 * Component that is rendered at `/account/order-history` route. Routing is managed by `Account` component.
 */
const OrderHistory: IAccountActiveComponent = ({ onSetAccountNavDisplay }) => {
  const { t } = useTranslation()
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const routeQuery = useRouteQuery()

  const userId = useSelector(userIdSelector)
  const { langCode } = useStoreIdentity()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const [isLoading, setLoading] = useState<boolean>(true)
  const [orders, setOrders] = useState<Order[]>([])
  const {
    currentPage,
    setCurrentPage,
    numberOfPages,
    paginatedItems: paginatedOrders,
  } = usePagination(orders, { pageSize: OrderService.GET_HISTORY_REQUEST_PAGE_SIZE })

  const orderIdToShowDetails = useMemo<string>(() => routeQuery.get('orderId') || '', [routeQuery])
  const tableHeaders = useMemo<string[]>(() => buildOrderHistoryHeaders(t), [t])
  const tableCells = useMemo<TableCell<Order>[]>(
    () => buildOrderHistoryCells({ isDesktop, langCode, t }),
    [isDesktop, langCode, t]
  )

  const fetchOrders = useCallback(async () => {
    try {
      setLoading(true)
      setOrders(await OrderService.getOrderHistory({ userId }))
    } catch (error) {
      setOrders([])
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchOrders()
  }, [])

  useEffect(() => {
    const isOnOrderDetailsPage = !!orderIdToShowDetails

    onSetAccountNavDisplay(isDesktop || !isOnOrderDetailsPage)

    return () => {
      /** Returns display state of Account Nav to default 'true' state on unmount from Order Details page */
      if (isOnOrderDetailsPage) {
        onSetAccountNavDisplay(true)
      }
    }
  }, [isDesktop, orderIdToShowDetails])

  return (
    <div>
      {orderIdToShowDetails && (
        <OrderHistoryBackLink
          themeMode={themeMode}
          to={generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ORDER_HISTORY)}
          variant="primaryUnderlined">
          <UnicodeArrowLeftIcon />
          <StyledTypography variant="caption">{t('Order.OrderHistory')}</StyledTypography>
        </OrderHistoryBackLink>
      )}

      {(orderIdToShowDetails || isDesktop) && (
        <OrderHistoryTitleWrapper>
          <StyledTypography>
            {t(orderIdToShowDetails ? 'Order.OrderDetails' : 'Order.OrderHistory')}
          </StyledTypography>
        </OrderHistoryTitleWrapper>
      )}

      {!orderIdToShowDetails && (
        <OrderHistoryContentWrapper>
          {isDesktop && <Table headers={tableHeaders} cells={tableCells} items={paginatedOrders} />}

          {!isDesktop && <OrderHistoryList orders={paginatedOrders} />}

          {!isLoading && numberOfPages > 1 && (
            <Pagination
              currentPage={currentPage}
              numberOfPages={numberOfPages}
              onPageClick={setCurrentPage}
            />
          )}

          {isLoading && (
            <Flexbox align-items="center" justify-content="center" height="52px">
              <StyledLoader contained />
            </Flexbox>
          )}

          {!isLoading && !paginatedOrders.length && (
            <StyledTypography component="p" variant="caption" style={{ margin: '10px 16px' }}>
              {t('Order.NoRecord')}
            </StyledTypography>
          )}
        </OrderHistoryContentWrapper>
      )}

      {orderIdToShowDetails && <OrderDetails orderId={orderIdToShowDetails} />}

      {orderIdToShowDetails && (
        <OrderDetailsFooter>
          <StyledTypography variant="body1">
            {t('OrderDetails.CustomerService.Title')}
          </StyledTypography>
          <StyledTypography variant="caption">
            {t('OrderDetails.CustomerService.Description')}
          </StyledTypography>
          <StyledTypography variant="body1">
            {t('OrderDetails.CustomerService.Call')}
            {': '}
            <a
              className="order-details__footer-tel"
              href={`tel:${t('OrderDetails.Labels.CustomerServiceTel')}`}>
              {t('OrderDetails.Labels.CustomerServiceTel')}
            </a>
          </StyledTypography>
          <StyledAnchorAsButton
            className="order-details__footer-email"
            href={`mailto:${t('OrderDetails.Labels.CustomerServiceEmail')}`}
            variant="primary"
            themeMode={themeMode}>
            {t('OrderDetails.Actions.SendEmail')}
          </StyledAnchorAsButton>
        </OrderDetailsFooter>
      )}
    </div>
  )
}

export default OrderHistory
