import Button from '@material-ui/core/Button'
import React from 'react'
import { dimensions } from '../../../themes/variables'
import styled from 'styled-components'

const searchBarHeight = dimensions.searchBar.height
const mobileHeaderHeight = dimensions.header.height.mobile

const StyledSearchBar = styled.div`
  ${({ theme }) => `
  position: relative;

  .searchBar-results {
    left: 0;
    width: fit-content;
    text-align: left;
    padding: 0;
    
    ${theme.breakpoints.down('sm')} {
      top: ${(mobileHeaderHeight - searchBarHeight) * 0.5 + mobileHeaderHeight}px;
    }

    .MuiListItem-root {
      border-radius: ${theme.shape.borderRadius}px;
      margin-right: ${theme.spacing(1)}px;
      margin-left: ${theme.spacing(1)}px;
      padding-right: ${theme.spacing(1)}px;
      padding-left: ${theme.spacing(1)}px;
    }
  }
  `}
`

export default StyledSearchBar
