import React, { FC, useState } from 'react'
import styled from 'styled-components'
import useThemeMode from '../../../hooks/useThemeMode'
import { AddIcon } from '../../StyledUI/Icons/Icons'
import Modal from '../../StyledUI/Modal/Modal'
import { CountryBaseLink } from '../../StyledUI/StyledLink/StyledLink'
import CMSIcon from '../cms-content/cms-icon'

interface GenericPromoBannerProps<ItemType> {
  title: string
  icon?: string
  items: ItemType[]
}

export type PromoBannerWithModalProps = GenericPromoBannerProps<{
  title: string
  icon: string
  modalContent: string
}>

type PromoBannerWithLinkProps = GenericPromoBannerProps<{
  title: string
  icon?: string
  path: string
}>

const BannerContainer = styled.div`
  ${({ theme }) => `
  width: 100%;
  padding: ${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(1)}px;
  background-color: ${theme.palette.secondary.main};
  font-size: 1rem;
`}
`

export const promoBannerClassName = BannerContainer.toString()

const BannerHeader = styled.header`
  display: flex;
  gap: ${({ theme }) => theme.spacing(0.25)}px;
`

const BannerTitle = styled.h3`
  ${({ theme }) => `
  display: inline-block;
  margin: 0;
  margin-bottom: ${theme.spacing(1)}px;
  padding: 0 ${theme.spacing(0.25)}px;
  background-color: ${theme.palette.custom.geckoGreen};
  font-size: 1rem;
  font-weight: normal;
  color: ${theme.palette.custom.black};
  cursor: default;
`}
`

const BannerList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const BannerListItem = styled.li`
  ${({ theme }) => `
  a {
    color: ${theme.palette.text.contrastText};
  }

  &:not(:last-child) {
    margin-bottom: ${theme.spacing(0.5)}px;
  }
`}
`

const BannerListItemButton = styled.button`
  ${({ theme }) => `
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: ${theme.spacing(0.5)}px;
  border: none;
  padding: 0;
  background: none;
  line-height: 1.5rem;
  color: ${theme.palette.text.contrastText};
  cursor: pointer;

  .special-offers-banner__item-icon {
    margin-left: auto;
  }
`}
`

const PromoBannerWithLink: FC<PromoBannerWithLinkProps> = ({ title, icon, items }) => {
  const { theme } = useThemeMode()

  return (
    <BannerContainer>
      <BannerHeader>
        {icon && <CMSIcon teaserIcon={icon} color={theme.palette.text.contrastText} />}
        <BannerTitle>{title}</BannerTitle>
      </BannerHeader>

      <BannerList>
        {items.map(({ title, icon, path }) => (
          <BannerListItem>
            <CountryBaseLink to={path} variant="primaryUnderlined">
              {icon && <CMSIcon teaserIcon={icon} color={theme.palette.text.contrastText} />}
              {title}
            </CountryBaseLink>
          </BannerListItem>
        ))}
      </BannerList>
    </BannerContainer>
  )
}

const PromoBannerWithModal: FC<PromoBannerWithModalProps> = ({ title, icon, items }) => {
  const { theme } = useThemeMode()

  const [modalData, setModalData] = useState<{
    title: string
    icon: string // e.g., "arn-icon--arnette"
    content: string
  } | null>(null)

  return (
    <BannerContainer>
      <BannerHeader>
        {icon && <CMSIcon teaserIcon={icon} color={theme.palette.text.contrastText} />}
        <BannerTitle>{title}</BannerTitle>
      </BannerHeader>

      <BannerList>
        {items.map(({ title, icon, modalContent }) => (
          <BannerListItem>
            <BannerListItemButton
              onClick={() => setModalData({ title, icon, content: modalContent })}>
              {icon && <CMSIcon teaserIcon={icon} color={theme.palette.text.contrastText} />}
              {title}
              <AddIcon className="special-offers-banner__item-icon" />
            </BannerListItemButton>
          </BannerListItem>
        ))}
      </BannerList>

      <Modal
        title={modalData?.title || ''}
        subtitle=""
        headerIcon={modalData?.icon ? <CMSIcon teaserIcon={modalData.icon} /> : null}
        content={modalData?.content || ''}
        visible={!!modalData}
        onClose={() => setModalData(null)}
      />
    </BannerContainer>
  )
}

export { PromoBannerWithLink, PromoBannerWithModal }
