import styled, { css, CSSObject } from 'styled-components'
import { CustomTheme, ThemeMode } from '../../../types/theme'
import { CheckboxAsButtonProps } from './CheckboxAsButton'

const generateVariantStyles = (
  theme: CustomTheme,
  themeMode: ThemeMode,
  variant: CheckboxAsButtonProps['variant'],
  checked: boolean
) => {
  const darkOutlinedStyles: CSSObject = {
    backgroundColor: checked ? theme.palette.custom.warmGrey : theme.palette.custom.black,
    borderColor: checked ? theme.palette.custom.black : theme.palette.custom.warmGrey,
    color: checked ? theme.palette.custom.black : theme.palette.custom.warmGrey,
  }

  const lightOutlinedStyles: CSSObject = {
    backgroundColor: checked ? theme.palette.custom.black : theme.palette.custom.warmGrey,
    borderColor: checked ? theme.palette.custom.warmGrey : theme.palette.custom.black,
    color: checked ? theme.palette.custom.warmGrey : theme.palette.custom.black,
  }

  const highlightedStyles: CSSObject = checked
    ? {
        backgroundColor: theme.palette.custom.geckoGreen,
        borderColor: theme.palette.custom.geckoGreen,
        color: theme.palette.custom.black,
      }
    : {
        '& > span:last-child': {
          backgroundColor: theme.palette.custom.geckoGreen,
          color: theme.palette.custom.black,
        },
      }

  const map: Record<ThemeMode, Record<typeof variant, CSSObject>> = {
    dark: {
      outlined: darkOutlinedStyles,
      'outlined-highlighted': { ...darkOutlinedStyles, ...highlightedStyles },
    },
    light: {
      outlined: lightOutlinedStyles,
      'outlined-highlighted': { ...lightOutlinedStyles, ...highlightedStyles },
    },
  }

  return css(map[themeMode][variant]).join('')
}

export const FilterElementWrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  font-size: 0.75rem;
  opacity: ${({ disabled }) => `${disabled ? '0.5' : '1'}`};
  pointer-events: ${({ disabled }) => `${disabled ? 'none' : 'initial'}`};
`

export const CheckboxInputElement = styled.input`
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  bottom: 0;
`

export const FilterElementLabelIcon = styled.span`
  margin-left: 1px;
  height: 24px;
`

export const FilterElementText = styled.span`
  flex: 1 1 100%;
  text-align: center;
  margin: 0 10px 0 10px;
`

export const CheckboxAsButtonLabel = styled.label<{
  checked: boolean
  themeMode: ThemeMode
  variant: CheckboxAsButtonProps['variant']
}>`
  ${({ theme, themeMode, variant, checked }) => `
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 0 5px;
  border: 1px solid transparent;
  border-radius: 50vmax;
  text-transform: uppercase;
  cursor: pointer;
  ${generateVariantStyles(theme, themeMode, variant, checked)}

  &:hover {
    text-decoration: underline;
  }
`}
`
