import { IPlacement, IPlacementItem, IPlacementPriority } from '../../../../types/teaser'
import Desktop from './Desktop'
import PLPBox from '../plp-box'
import React from 'react'
import { currentPageSeoDataSelector } from '../../../../redux/selectors/seo'
import styled from 'styled-components'
import { useAppSelector } from '../../../../hooks/redux'
import useBreakpoints from '../../../../hooks/useBreakpoints'
import cmsService from '../../../../foundation/apis/cms/cms.service'

interface IProps {
  index?: number
  teaserIndex?: number
  teaser: IPlacementItem
  placementName?: IPlacement['name']
  variant?: string
  marginLateral?: boolean
}

export const StyledFullWidthBannerContainer = styled.div<{ isPLP: boolean; marginLateral }>`
  position: relative;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 100%;
  }
  ${({ marginLateral, theme }) =>
    marginLateral
      ? `padding: ${theme.spacing(1)}px ${theme.spacing(1)}px ${
          theme.spacing(1) + 5
        }px ${theme.spacing(1)}px;`
      : ''}
`

const FullWidthBanner: React.FC<IProps & IPlacementPriority> = ({
  index,
  teaserIndex,
  teaser,
  placementName,
  variant,
  marginLateral,
  fetchpriority
}) => {
  const { isTablet } = useBreakpoints()

  const seoData = useAppSelector(currentPageSeoDataSelector)
  if (teaser.type !== 'LXTeaser') return null

  const isPLP =
    (seoData?.page?.type === 'ProductListPage' || seoData?.page?.type === 'CategoryPage') ?? false

  return (
    <StyledFullWidthBannerContainer
      className="arn-home__full-width-banner-container"
      isPLP={isPLP}
      marginLateral={marginLateral}
      {...cmsService.getExtraPlacementAttributes(placementName || '')}>
      {isTablet ? (
        <PLPBox
          fetchpriority={fetchpriority}
          index={index}
          teaserIndex={teaserIndex}
          teaser={teaser}
        />
      ) : (
        <Desktop
          fetchpriority={fetchpriority}
          placementName={placementName}
          index={index}
          teaserIndex={teaserIndex}
          teaser={teaser}
          variant={variant}
        />
      )}
    </StyledFullWidthBannerContainer>
  )
}

export default FullWidthBanner
