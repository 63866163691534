//Standard libraries
import { AxiosRequestConfig, Method, AxiosPromise } from 'axios'
//Foundation libraries
import { executeRequest } from '../../axios/axiosConfig'
import { getSite } from '../../hooks/useSite'
import { localStorageUtil } from '../../utils/storageUtil'
import { PRODUCTION, SHOW_API_FLOW } from '../../constants/common'
//Redux
import { API_CALL_ACTION } from '../../../redux/actions/api'
import RequestService, { RecordSetResponse, RequestProps } from '../../../services/RequestService'
import { IOrderDetails, Order } from '../../../types/order'
import { ORDER_EXTEND_ATTRIBUTE_NAMES } from '../../../constants/order'

export interface IGetOrderHistoryParams {
  pageNumber?: number | string
  userId: string
  pageSize?: number
}

class OrderService {
  readonly GET_HISTORY_REQUEST_PAGE_SIZE: number = 10

  /**
   * Gets the order details for a specific order ID.
   * `@method`
   * `@name Order#findByOrderId`
   * `@property {string} orderId (required)` The order identifier
   */
  findByOrderId({ orderId, ...rest }: { orderId: string } & Partial<RequestProps>) {
    return RequestService.request<IOrderDetails>({
      ...rest,
      method: 'GET',
      path: '/store/{storeId}/order/{orderId}',
      pathParams: { orderId },
    })
  }

  getOrders({
    pageNumber = 1,
    pageSize = 100,
    userId,
    isCancelledOrders,
  }: IGetOrderHistoryParams & { isCancelledOrders: boolean }) {
    return RequestService.request<RecordSetResponse<{ Order?: Order[] }>>({
      method: 'GET',
      path: '/store/{storeId}/order/@history',
      queryParams: {
        pageNumber,
        pageSize: pageSize || this.GET_HISTORY_REQUEST_PAGE_SIZE,
        userId,
        cancelledOrders: isCancelledOrders || undefined,
      },
    })
  }

  /**
   * Gets the order history for the authenticated user
   *
   * `@method`
   * `@name Order#getOrderHistory`
   */
  async getOrderHistory(params: IGetOrderHistoryParams) {
    const activeOrders = this.getOrders({ ...params, isCancelledOrders: false })
    const cancelledOrders = this.getOrders({
      ...params,
      isCancelledOrders: true,
    })

    const [activeOrdersResponse, cancelledOrdersResponse] = await Promise.all([
      activeOrders,
      cancelledOrders,
    ])

    return [...(activeOrdersResponse.Order || []), ...(cancelledOrdersResponse.Order || [])]
      .sort((orderA, orderB) => {
        if (!orderA.placedDate || !orderB.placedDate) {
          return 0
        }

        return (
          Number(new Date(orderB.placedDate)) -
          Number(new Date(orderA.placedDate))
        )
      })
      .slice(0, params.pageSize)
  }

  /**
  * Find order by the parent order ID.
  * `@method`
  * `@name Order#findByParentOrderId`
  *
  * `@param {any} headers (optional)` will add headers to rest request
  *
  * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
  *
  * `@param {any} parameters` have following properties:
     ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.

   ** `@property {string} orderId (required)` The order identifier.
   ** `@property {string} pageNumber ` Page number, starting at 1. Valid values include positive integers of 1 and above. The "pageSize" must be specified for paging to work.
   ** `@property {string} pageSize ` Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The "pageNumber" must be specified for paging to work.
  */
  findByParentOrderId(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/order'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let form: any = {}
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request \'/store/{storeId}/order\' missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    queryParameters.set('q', 'findByParentOrderId')

    if (parameters['orderId'] === undefined) {
      throw new Error('Request \'/store/{storeId}/order\' missing required parameter orderId')
    }
    if (parameters['orderId'] !== undefined) {
      const name = 'orderId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageNumber'] !== undefined) {
      const name = 'pageNumber'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageSize'] !== undefined) {
      const name = 'pageSize'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow =
      process.env.NODE_ENV !== PRODUCTION ? localStorageUtil.get(SHOW_API_FLOW) === 'true' : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('../../../redux/store').default
      if (store) {
        store.dispatch(
          API_CALL_ACTION(
            from + ' -> Transaction: ' + method + ' ' + requestUrl + '?' + queryParameters
          )
        )
      }
    }

    return executeRequest(requestOptions)
  }

  paypalExpressCheckStatus(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/paypal/checkStatus/@self'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request \'/store/{storeId}/paypal/checkStatus\' missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    const showAPIFlow =
      process.env.NODE_ENV !== PRODUCTION ? localStorageUtil.get(SHOW_API_FLOW) === 'true' : false
    if (showAPIFlow) {
      const from = parameters['widget'] ? parameters['widget'] : 'Browser'
      const store = require('../../../redux/store').default
      if (store) {
        store.dispatch(
          API_CALL_ACTION(
            from + ' -> Transaction: ' + method + ' ' + requestUrl + '?' + queryParameters
          )
        )
      }
    }

    return executeRequest(requestOptions)
  }

  getInvoiceUrl(orderExtendAttribute: Order['orderExtendAttribute'] = []): string {
    return (
      orderExtendAttribute.find(
        (a) => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.INVOICE_URLS
      )?.attributeValue || ''
    )
  }

  getTrackingIds(orderExtendAttribute: Order['orderExtendAttribute'] = []): string[] {
    try {
      const trackingIdsAttributeValue = JSON.parse(
        orderExtendAttribute.find(
          (a) => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.TRACK_NUMBER
        )?.attributeValue || '{}'
      )

      return trackingIdsAttributeValue['tracking-codes'].filter(Boolean)
    } catch (e) {
      return []
    }
  }

}

export default new OrderService()
