import * as React from 'react'

import styled, { css } from 'styled-components'

import { CustomTheme } from '../../../types/theme'
import { RoundButtonProps } from '../../../types/roundbutton'
import { StyledLoader } from '../../StyledUI'
import { alpha } from '@material-ui/core'
import useThemeMode from '../../../hooks/useThemeMode'

const variantMap = (
  theme: CustomTheme,
  variant: RoundButtonProps['variant'] = 'tertiary',
  selected?: boolean | undefined,
  themeMode: RoundButtonProps['themeMode'] = 'light'
) =>
  ({
    dark: {
      primary: {
        background: selected ? theme.palette.custom.black : theme.palette.custom.warmGrey,
        hoverBackground: theme.palette.custom.black,
        color: selected ? theme.palette.custom.warmGrey : theme.palette.custom.black,
        colorHover: theme.palette.custom.warmGrey,
        borderColor: theme.palette.custom.black,
        borderColorhover: theme.palette.custom.warmGrey,
      },
      secondary: {
        background: theme.palette.custom.black,
        hoverBackground: theme.palette.custom.warmGrey,
        color: theme.palette.custom.warmGrey,
        colorHover: theme.palette.custom.black,
        borderColor: theme.palette.custom.warmGrey,
        borderColorhover: theme.palette.custom.black,
      },
      tertiary: {
        background: theme.palette.custom.geckoGreen,
        hoverBackground: theme.palette.custom.warmGrey,
        color: theme.palette.custom.black,
        colorHover: theme.palette.custom.black,
        borderColor: theme.palette.custom.geckoGreen,
        borderColorhover: theme.palette.custom.black,
      },
    },
    light: {
      primary: {
        background: theme.palette.custom.black,
        hoverBackground: theme.palette.custom.warmGrey,
        color: theme.palette.custom.warmGrey,
        colorHover: theme.palette.custom.black,
        borderColor: theme.palette.custom.black,
        borderColorhover: theme.palette.custom.black,
      },
      secondary: {
        background: selected ? theme.palette.custom.black : theme.palette.custom.warmGrey,
        hoverBackground: theme.palette.custom.black,
        color: selected ? theme.palette.custom.warmGrey : theme.palette.custom.black,
        colorHover: theme.palette.custom.warmGrey,
        borderColor: theme.palette.custom.black,
        borderColorhover: theme.palette.custom.black,
      },
      tertiary: {
        background: theme.palette.custom.geckoGreen,
        hoverBackground: theme.palette.custom.warmGrey,
        color: theme.palette.custom.black,
        colorHover: theme.palette.custom.black,
        borderColor: theme.palette.custom.geckoGreen,
        borderColorhover: theme.palette.custom.black,
      },
    },
  }[themeMode]![variant])

export const ButtonStyle = css<RoundButtonProps>`
  font-family: inherit;
  padding: 15px 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 200px;
  width: ${({ fullWidth }) => `${fullWidth ? '100%' : 'auto'}`};
  border: 1px solid
    ${({ theme, variant, selected, themeMode }) =>
      variantMap(theme, variant, selected, themeMode).borderColor};
  border-radius: 50vmax;
  font-size: 0.75rem;
  color: ${({ theme, variant, selected, themeMode }) =>
    variantMap(theme, variant, selected, themeMode).color};
  line-height: normal;
  text-align: inherit;
  text-transform: uppercase;
  vertical-align: bottom;
  cursor: pointer;
  background: ${({ theme, variant, selected, themeMode }) =>
    variantMap(theme, variant, selected, themeMode).background};
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  svg {
    fill: ${({ theme, variant, selected, themeMode }) =>
      variantMap(theme, variant, selected, themeMode).color};
    margin-right: 5px;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    &:hover {
      background: ${({ theme, variant, selected, themeMode }) =>
        alpha(variantMap(theme, variant, selected, themeMode).background, 0.9)};
      color: ${({ theme, variant, selected, themeMode }) =>
        variantMap(theme, variant, selected, themeMode).color};
      border: 1px solid
        ${({ theme, variant, selected, themeMode }) =>
          variantMap(theme, variant, selected, themeMode).borderColor};
      text-decoration: underline;
      svg {
        fill: ${({ theme, variant, selected, themeMode }) =>
          variantMap(theme, variant, selected, themeMode).color};
      }
    }
  }
`

const RoundButtonBase = styled.button<RoundButtonProps>`
  ${ButtonStyle}
`

export const RoundButton = ({ children, loading, labelText, ...props }: RoundButtonProps) => {
  const { themeMode } = useThemeMode()

  return (
    <RoundButtonBase themeMode={themeMode} {...props}>
      {loading ? <StyledLoader labelText={labelText} /> : children}
    </RoundButtonBase>
  )
}
