import { merge, responsiveFontSizes, sharedOverrides } from './shared-theme'

import { createTheme } from '@material-ui/core/styles'
import { palette } from './color-palette'

const { main, light, dark } = palette.emerald

export const emeraldOverrides = {
  name: 'Emerald',
  palette: {
    primary: {
      light: light,
      main: main,
      dark: dark,
      contrastText: '#fff',
    },
    secondary: {
      light: light,
      main: main,
      dark: dark,
      contrastText: '#fff',
    },
  },
  button: {
    backgroundColor: main,
    '&:hover': {
      backgroundColor: main,
    },
  },
  child: {
    backgroundColor: main,
  },
  rippleVisible: {
    opacity: 0.5,
  },
}

const combinedOverides = merge(sharedOverrides, emeraldOverrides)
const theme = responsiveFontSizes(createTheme(combinedOverides))

export default theme
