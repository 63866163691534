import * as successActions from '../../../redux/actions/success'

import {
  ADDRESSLINE1,
  ADDRESSLINE2,
  ADDRESS_BOOK,
  ADDRESS_LINE,
  ADDRESS_SHIPPING,
  ADDRESS_SHIPPING_BILLING,
  EMPTY_STRING,
} from '../../../constants/common'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { AddAddressFormButtonContainer } from './AddressBook.style'
import { AddressForm } from '../../widgets/address-form'
import { AddressFormData } from '../../../types/form'
import { Box } from '@material-ui/core'
import { CheckoutAddressFormField } from '../../../types/checkout'
import Log from '../../../services/Log'
import { RoundButton } from '../../StyledUI'
import addressUtil from '../../../utils/addressUtil'
import personContactService from '../../../foundation/apis/transaction/personContact.service'
import { shippingFormFieldsSelector } from '../../../redux/selectors/site'
import { useAppDispatch } from '../../../hooks/redux'
import { useTranslation } from 'react-i18next'
import { Contact } from '../../../types/user'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../../redux/actions/user'
import { useCheckoutForm } from '../../../hooks/useCheckoutForm'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'

interface IProps {
  formData: Contact | null
  payloadBase: any
  onEditEnd: () => void
}

const addressFormDataInit: AddressFormData = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  addressLine1: EMPTY_STRING,
  addressLine2: EMPTY_STRING,
  city: EMPTY_STRING,
  country: EMPTY_STRING,
  state: EMPTY_STRING,
  zipCode: EMPTY_STRING,
  phone1: EMPTY_STRING,
  email1: EMPTY_STRING,
  addressType: ADDRESS_SHIPPING,
}

const AddAddressForm: React.FC<IProps> = ({ formData, payloadBase, onEditEnd }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const storeCountry = useStoreIdentity().country.toUpperCase()
  const [isFormValid, setFormValid] = useState<boolean>(false)
  const [newAddressFormData, setNewAddressFormData] = useState<AddressFormData>({
    ...addressFormDataInit,
    ...formData,
    addressLine1: formData?.addressLine?.[0] || '',
    addressLine2: formData?.addressLine?.[1] || '',
    country: storeCountry,
  })

  const shippingFormFieldsConf = useSelector(shippingFormFieldsSelector)
  const shippingFormFields: CheckoutAddressFormField[] =
    shippingFormFieldsConf?.filter((i) => i.fieldName !== 'email1') || []

  const form = useCheckoutForm({
    defaultValues: newAddressFormData,
    fields: shippingFormFields,
  })

  const updateAddress = () => {
    // remove leading and trailing white space from all form input fields.
    let updatedAddressData = addressUtil.removeLeadingTrailingWhiteSpace(newAddressFormData)
    updatedAddressData['addressType'] = ADDRESS_SHIPPING_BILLING
    updatedAddressData[ADDRESS_LINE] = [updatedAddressData[ADDRESSLINE1]]

    if (
      updatedAddressData[ADDRESSLINE2] &&
      updatedAddressData[ADDRESSLINE2].trim() !== EMPTY_STRING
    ) {
      updatedAddressData[ADDRESS_LINE].push(updatedAddressData[ADDRESSLINE2])
    }

    delete updatedAddressData[ADDRESSLINE1]
    delete updatedAddressData[ADDRESSLINE2]

    personContactService
      .updatePersonContact({
        body: updatedAddressData,
        ...payloadBase,
      })
      .then((res) => res.data)
      .then((addressData) => {
        if (addressData.addressId) {
          dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION({ ...payloadBase }))
          const successMessage = {
            key: 'success-message.EDIT_ADDRESS_SUCCESS',
            messageParameters: {
              '0': updatedAddressData.name,
            },
          }
          dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage))

          onEditEnd()
        }
      })
      .catch((e) => {
        Log.error('Could not create new address', e)
      })
  }

  const createAddress = () => {
    // remove leading and trailing white space from all form input fields.
    let newAddressData = addressUtil.removeLeadingTrailingWhiteSpace(newAddressFormData)
    newAddressData[ADDRESS_LINE] = [newAddressData[ADDRESSLINE1]]
    if (newAddressData[ADDRESSLINE2] && newAddressData[ADDRESSLINE2].trim() !== EMPTY_STRING) {
      newAddressData[ADDRESS_LINE].push(newAddressData[ADDRESSLINE2])
    }
    delete newAddressData[ADDRESSLINE1]
    delete newAddressData[ADDRESSLINE2]

    personContactService
      .addPersonContact({
        body: newAddressData,
        ...payloadBase,
      })
      .then((res) => res.data)
      .then((addressData) => {
        if (addressData.addressId) {
          dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION({ ...payloadBase }))
          const successMessage = {
            key: 'success-message.ADD_ADDRESS_SUCCESS',
            messageParameters: {
              '0': newAddressData.nickName,
            },
          }
          dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage))

          onEditEnd()
        }
      })
      .catch((e) => {
        Log.error('Could not create new address', e)
      })
  }

  const isCreatingAddress =
    !newAddressFormData.addressId || newAddressFormData.addressId.length === 0

  return (
    <Box display="flex" flexDirection="column">
      <AddressForm
        addressFormFields={shippingFormFields}
        addressType={newAddressFormData.addressType}
        cid="newAddress-shipping-and-billing"
        form={form}
        page={ADDRESS_BOOK}
        onFormDataChanged={(_, data) => setNewAddressFormData(data)}
        onFormValidaTionStatusChanged={(_, isValid) => setFormValid(isValid)}
      />
      <AddAddressFormButtonContainer>
        <RoundButton variant="secondary" onClick={onEditEnd}>
          {t('AddressBook.Cancel')}
        </RoundButton>
        <RoundButton
          data-element-id={`X_X_AddressBook_${isCreatingAddress ? 'Add' : 'SaveChanges'}`}
          disabled={!isFormValid}
          variant="primary"
          onClick={isCreatingAddress ? createAddress : updateAddress}>
          {isCreatingAddress ? t('AddressBook.CreateAddress') : t('AddressBook.SaveChanges')}
        </RoundButton>
      </AddAddressFormButtonContainer>
    </Box>
  )
}

export default AddAddressForm
