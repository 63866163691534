//Redux
import * as ACCOUNT from './watchers/account'
import * as CONTEXT from './watchers/context'
import * as CONTRACT from './watchers/contract'
import * as ERROR from './watchers/error'
import * as MARKETING from './watchers/marketingEvent'
import * as ORDER from './watchers/order'
import * as ORDERDETAILS from './watchers/orderDetails'
import * as ORGANIZATION from './watchers/organization'
import * as SEO from './watchers/seo'
import * as USER from './watchers/user'

//Standard libraries
import { all } from 'redux-saga/effects'

/**
 * Root Saga
 */
export default function* rootSaga() {
  yield all([
    ACCOUNT.watchSaga(),
    ORDER.watchSaga(),
    ERROR.watchSaga(),
    SEO.watchSaga(),
    USER.watchSaga(),
    CONTRACT.watchSaga(),
    MARKETING.watchSaga(),
    CONTEXT.watchSaga(),
    ORGANIZATION.watchSaga(),
    ORDERDETAILS.watchSaga(),
  ])
}
