import React, { FC } from 'react'
import theme from '../../themes'
import { useMediaQuery } from '@material-ui/core'
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledTypography,
} from '../StyledUI'
import { ArrowDownSlimIcon } from '../StyledUI/Icons'
import { PaymentMethodImageWrapper, PaymentMethodsIcons } from '../StyledUI/Payment/PaymentIcons'
import * as PaymentMethod from '../../constants/paymentMethods'
import { useTranslation } from 'react-i18next'

interface PaymentMethodsProps {
  availablePaymentMethods: PaymentMethod.FooterName[]
}

const PaymentMethods: FC<PaymentMethodsProps> = ({ availablePaymentMethods }) => {
  const isMobileOrTablet = !useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation()

  return isMobileOrTablet ? (
    <StyledAccordion defaultExpanded={false}>
      <StyledAccordionSummary expandIcon={<ArrowDownSlimIcon />}>
        {t('Footer.PaymentMethods')}
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <PaymentMethodImageWrapper>
          <PaymentMethodsIcons paymentMethods={availablePaymentMethods} />
        </PaymentMethodImageWrapper>
      </StyledAccordionDetails>
    </StyledAccordion>
  ) : (
    <>
      <StyledTypography className="sub-menu-title">{t('Footer.PaymentMethods')}</StyledTypography>
      <PaymentMethodImageWrapper>
        <PaymentMethodsIcons paymentMethods={availablePaymentMethods} />
      </PaymentMethodImageWrapper>
    </>
  )
}

export default PaymentMethods
