//Add item to cart
export const ITEM_ADD_REQUESTED = 'ITEM_ADD_REQUESTED'
export const LENS_ADD_REQUESTED = 'LENS_ADD_REQUESTED'
export const ITEM_ADD_SUCCESS = 'ITEM_ADD_SUCCESS'
export const ITEM_ADD_ERROR = 'ITEM_ADD_ERROR'
export const ADDING_ITEM = 'ADDING_ITEM'

//Remove item to cart
export const DELETE_ORDER_ITEM_AND_FETCH_CART_ACTION = 'DELETE_ORDER_ITEM_AND_FETCH_CART_ACTION'

//Update item in cart
export const ITEM_UPDATE_SUCCESS = 'ITEM_UPDATE_SUCCESS'
export const ITEM_UPDATE_ERROR = 'ITEM_UPDATE_ERROR'

//Get current user's cart
/**
 * The action set fetching state to true.
 */
export const CART_FETCHING_REQUESTED = 'CART_FETCHING_REQUESTED'
export const CART_GET_REQUESTED = 'CART_GET_REQUESTED'
export const CART_GET_SUCCESS = 'CART_GET_SUCCESS'
export const CART_GET_ERROR = 'CART_GET_ERROR'
export const CART_TOGGLE_ORDER_COMPLETE = 'CART_TOGGLE_ORDER_COMPLETE'
export const CART_UPDATED_SUCCESS = 'CART_UPDATED_SUCCESS'

//Get current cart's usable shipping info
export const SHIPINFO_GET_REQUESTED = 'SHIPINFO_GET_REQUESTED'
export const SHIPINFO_GET_SUCCESS = 'SHIPINFO_GET_SUCCESS'
export const SHIPINFO_GET_ERROR = 'SHIPINFO_GET_ERROR'

//update current cart's shipping mode
export const SHIPMODE_UPDATE_REQUESTED = 'SHIPMODE_UPDATE_REQUESTED'
export const SHIPMODE_UPDATE_SUCCESS = 'SHIPMODE_UPDATE_SUCCESS'
export const SHIPMODE_UPDATE_ERROR = 'SHIPMODE_UPDATE_ERROR'

//Get current cart's usable payment methods
export const PAYMETHODS_GET_REQUESTED = 'PAYMETHODS_GET_REQUESTED'
export const PAYMETHODS_GET_SUCCESS = 'PAYMETHODS_GET_SUCCESS'
export const PAYMETHODS_GET_ERROR = 'PAYMETHODS_GET_ERROR'

//Reset cart states
export const CART_RESET_REQUESTED = 'CART_RESET_REQUESTED'
export const CART_RESET_SUCCESS = 'CART_RESET_SUCCESS'
export const CART_RESET_ERROR = 'CART_RESET_ERROR'

export const SHIPPING_AS_BILLING_TOGGLE = 'SHIPPING_AS_BILLING_TOGGLE'
export const SHIPPING_FORM_VALID_STATUS = 'CHECKOUT_FORM_VALID_STATUS'
export const BILLING_FORM_VALID_STATUS = 'BILING_FORM_VALID_STATUS'

//Paypal
export const PAYPALEXPRESS_TOGGLE = 'PAYPALEXPRESS_TOGGLE'
export const PAYPALEXPRESS_GET_REQUESTED = 'PAYPALEXPRESS_GET_REQUESTED'
export const PAYPALEXPRESS_GET_SUCCESS = 'PAYPALEXPRESS_GET_REQUESTED_SUCCESS'
export const PAYPALEXPRESS_GET_ERROR = 'PAYPALEXPRESS_GET_REQUESTED_ERROR'
export const PAYPAL_SIGNATURE_DATA_GET_REQUESTED = 'PAYPAL_SIGNATURE_DATA_GET_REQUESTED'
export const PAYPAL_SIGNATURE_DATA_GET_SUCCESS = 'PAYPAL_SIGNATURE_DATA_GET_SUCCESS'
export const PAYPAL_SIGNATURE_DATA_GET_ERROR = 'PAYPAL_SIGNATURE_DATA_GET_ERROR'
export const PAYPALEXPRESS_CHECK_STATUS_GET_REQUESTED = 'PAYPALEXPRESS_CHECK_STATUS_GET_REQUESTED'
export const PAYPALEXPRESS_CHECK_STATUS_GET_SUCCESS = 'PAYPALEXPRESS_CHECK_STATUS_GET_SUCCESS'
export const PAYPALEXPRESS_CHECK_STATUS_GET_ERROR = 'PAYPALEXPRESS_CHECK_STATUS_GET_ERROR'

export const ORDER_FINALIZATION_GET_SUCCESS = 'ORDER_FINALIZATION_GET_SUCCESS'
export const ORDER_FINALIZATION_GET_ERROR = 'ORDER_FINALIZATION_GET_ERROR'
export const ORDER_PAYMENT_METHOD_UPDATE = 'ORDER_PAYMENT_METHOD_UPDATE'

export const NEWS_LETTER_SUBSCRIBE_CHECKED_STATUS = 'NEWS_LETTER_SUBSCRIBE_CHECKED_STATUS'
export const EDIT_LENSES_STATUS = 'EDIT_LENSES_STATUS'
export const IMAGE_RX_URL_STATUS = 'IMAGE_RX_URL_STATUS'

export const TAXES_CALCULATION_GET_REQUESTED = 'TAXES_CALCULATION_GET_REQUESTED'
export const TAXES_CALCULATION_GET_SUCCESS = 'TAXES_CALCULATION_GET_SUCCESS'
export const TAXES_CALCULATION_GET_ERROR = 'TAXES_CALCULATION_GET_ERROR'