import React, { FC } from 'react'
import styled from 'styled-components'

const StyledPaginationContainer = styled.div`
  ${({ theme }) => `
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(0.625)}px;
`}
`

export const StyledPaginationPage = styled.button<{ isCurrent: boolean }>`
  ${({ isCurrent, theme }) => `
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.primary.dark};
  background-color: ${isCurrent ? theme.palette.primary.dark : 'transparent'};
  color: ${isCurrent ? theme.palette.text.contrastText : theme.palette.text.primary};
  font-size: 0.75rem;
  ${!isCurrent ? 'cursor: pointer;' : ''}

  ${!isCurrent ? '&:hover { text-decoration: underline; }' : ''}
`}
`

interface PaginationProps {
  currentPage: number
  numberOfPages: number
  onPageClick(pageNumber: number): void
}

/**
 * @name Pagination
 * @description Component that renders page numbers of pagination with active state and click action.
 * Current design for pages element is 25x25 black-bordered squares with transparent background
 * for non-current pages and black background for current page.
 * It is currently used with 'TableWithPagination' only. (`src\components\StyledUI\StyledTable\TableWithPagination.tsx`)
 */
const Pagination: FC<PaginationProps> = ({ currentPage, numberOfPages, onPageClick }) => {
  return (
    <StyledPaginationContainer className="arn-pagination">
      {[...Array(numberOfPages).keys()].map((_, index) => (
        <StyledPaginationPage
          key={index}
          isCurrent={1 + index === currentPage}
          onClick={() => onPageClick(1 + index)}>
          {1 + index}
        </StyledPaginationPage>
      ))}
    </StyledPaginationContainer>
  )
}

export default Pagination
