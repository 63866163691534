import { useDispatch, useSelector } from 'react-redux'
import { THEME_MODE_TOGGLE_ACTION } from '../redux/actions/theme'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import arnetteThemes from './arnette/themes'
import { createContext } from 'react'
import emeraldTheme from './emerald-theme'
import sapphireTheme from './sapphire-theme'
import { themeModeSelector } from '../redux/selectors/theme'

const baseThemes = {
  Arnette: arnetteThemes,
  Emerald: { emeraldTheme },
  Sapphire: { sapphireTheme },
}

const getTheme = (theme) => {
  const themes = baseThemes[process.env.REACT_APP_STORENAME]
  // Return the specified theme or the first available
  if (themes[theme]) {
    return themes[theme]
  } else {
    return themes[Object.keys(themes)[0]]
  }
}

export const ThemeContext = createContext({
  currentTheme: 'light',
  setTheme: null,
})

const EcommThemeProvider = (props) => {
  const dispatch = useDispatch()
  const { children } = props
  const themeMode = useSelector(themeModeSelector)
  const theme = getTheme(themeMode)
  const setThemeName = (name) => {
    dispatch(THEME_MODE_TOGGLE_ACTION(name))
  }

  const contextValue = {
    currentTheme: themeMode,
    setTheme: setThemeName,
  }

  return (
    <ThemeContext.Provider value={contextValue}>
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </StyledThemeProvider>
    </ThemeContext.Provider>
  )
}

export default EcommThemeProvider
