import type { ICallToAction } from '../types/teaser'
import { ctaStylesMap } from '../constants/ui'
import { removeStoreSegment } from './url'
import config from '../configs'

export const getCTAVariant = (cta: ICallToAction) => {
  const ctaStyle = cta?.style?.split('--')[1] || 'primary'
  return ctaStylesMap[ctaStyle]
}

export const getCTAToUrl = (basePath: string, cta: ICallToAction) => {
  const formattedUrl = removeStoreSegment(`${cta.target.formattedUrl || ''}`)

  switch (cta.target.type) {
    case 'CMExternalPage':
    case 'CMExternalChannel':
    case 'CMChannel':
    case 'CMExternalProduct':
      return `${basePath}/${formattedUrl}`
    case 'CMDownload':
      return `${config.cmsImageServerUrl}${cta.target.fullyQualifiedUrl}`
    case 'CMExternalLink':
      return `${cta.target.formattedUrl}`
    default:
      return formattedUrl
  }
}
