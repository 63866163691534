import { AxiosError } from 'axios'
import get from 'lodash/get'
import * as Events from './types/events.types'
import * as Props from './types/props.types'
import { CommonData } from './interfaces'
import { executeOnce } from '../../../utils/common'
import { ProductAnalyticsRX } from '../../../types/product'
import { Facet, SelectedFacet, SelectedFacets } from '../../../types/filters'
import tealiumProductFormatService from './tealiumProductFormat.service'
import { LANGUAGE_CODE_MAP } from '../../../constants/common'
import { SingleSortOrderOption } from '../../../redux/reducers'

class TealiumService {
  private isInitiated: boolean = false

  private validCountryCodes = Object.keys(LANGUAGE_CODE_MAP)
    .map((code) => code.split('_')[1])
    .concat(['GB']) // e.g., 'US', 'CA', 'IT', 'GB'

  readonly constantRequiredPageEventProps: Pick<
    Props.PageEventCommon,
    | 'Page_Brand'
    | 'Page_DeviceType'
    | 'Page_Platform'
    | 'Page_Server'
    | 'Store_Brand'
    | 'User_LoginType'
  > = {
    Page_Brand: 'AR',
    Page_DeviceType: 'X',
    Page_Platform: 'WCS',
    Page_Server: '23.218.225.8',
    Store_Brand: 'AR',
    User_LoginType: 'Standard',
  } as const

  readonly pageSectionFallback = 'Other'

  readonly pageTypes = {
    cart: 'CartPage',
    clp: 'Clp',
    error404: 'Error',
    home: 'Home',
    landing: 'Landing',
    pdp: 'Pdp',
    plp: 'Plp',
    searchResults: 'Search',
    shipping: 'Delivery',
  } as const

  loadAnalyticScript(sessionId: string, commonData: CommonData) {
    if (this.isInitiated) {
      return
    }

    this.isInitiated = true

    return executeOnce(() => {
      const headFragment = document.createDocumentFragment()
      const tealiumTag = document.createElement('script')
      const matchParam =
        /^www\.arnette|^m\.arnette|^china\.arnette|^india\.arnette|^japan\.arnette|^arnette\.world\.tmall\.com/
      const utagProfile = 'arnette'
      const utagEnv = window.location.host.match(matchParam) ? 'prod' : 'qa'

      const {
        Page_Brand,
        Page_Country,
        Page_Language,
        Page_DeviceType,
        Page_Platform,
        Page_Server,
        Page_Design,
        Store_Id,
        Order_Currency,
      } = commonData

      //const lowercasePageRegionEnv = pageRegionEnv.env.toLowerCase()
      //tealiumTag.id = this.analyticsConfig.gtagId
      tealiumTag.text = `(function(a,b,c,d){
        a='//tags.tiqcdn.com/utag/luxottica/${utagProfile}/${utagEnv}/utag.js';
        b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
        a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
        })();
      (function(a,b,c,d){
        a='https://code.jquery.com/jquery-1.12.4.min.js';
        b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
        a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
        })();
        window.tealium_data2track = window.tealium_data2track || []
        window.utag_data = window.utag_data || {};

        function tealiumTag(data){return ({...utag_data, ...data })};
  
        window.utag_data = tealiumTag({
          Page_Brand: "${Page_Brand}",
          Page_Country: "${this.usePageCountryWithFallback(Page_Country)}",
          Page_Language: "${Page_Language}",
          Page_DeviceType: "${Page_DeviceType}",
          Page_Platform: "${Page_Platform}",
          Page_Server: '${Page_Server}',
          Page_Design: "${Page_Design}",
          Store_Id: "${Store_Id}",
          Session_Id: "${sessionId}",
          Order_Currency: "${Order_Currency}"
        });`

      headFragment.appendChild(tealiumTag)
      document.head.appendChild(headFragment)
    }, 'dw-gtag-tealium')()
  }

  private sendAnalyticEvent<D extends { id: string }>(data: D) {
    if (window.tealium_data2track) {
      window.tealium_data2track.push(data)
    }
  }

  formatRequestError(error: AxiosError): Omit<Events.Error, 'id'> {
    const statusCode: number = get(error, 'response.status', 500)
    const errorKey: string = get(error, 'response.data.errors[0].errorKey', '')
    const errorMessage: string = get(error, 'response.data.errors[0].errorMessage', '')

    return {
      Error_Source: 'Server',
      Error_Code: statusCode.toString(),
      Error_Details: errorKey || error?.key,
      Error_Message: errorMessage || error?.message,
    }
  }

  usePageCountryWithFallback(pageCountry: string = '') {
    if (this.validCountryCodes.includes(pageCountry)) {
      return pageCountry
    }

    if (pageCountry.toUpperCase() === 'WW') {
      return 'INT'
    }

    return ''
  }

  sendAddToCartEvent(product: ProductAnalyticsRX) {
    const Products = tealiumProductFormatService.get('AddToCart', [product])

    this.sendAnalyticEvent<Events.AddToCart>({ id: 'AddToCart', Products })
  }

  sendErrorEvent(data: Omit<Events.Error, 'id'>) {
    this.sendAnalyticEvent<Events.Error>({ id: 'Error', ...data })
  }

  formatSearchProps(
    currentPLPFacets: Facet[],
    selectedFacets: SelectedFacets,
    selectedSortOption: SingleSortOrderOption, // value is `key` from SortOrderOptions map, instead of number value, e.g., '3' -> 'Price_low'
    productTotal: number
  ): null | Pick<
    Props.Search,
    'Search_FacetValues_String' | 'Search_Keyword' | 'Search_ResultItemsQnt'
  > {

    const getUntranslatedFilterEntryValue = (entry: Facet['entry'][0], facetName: string): string => {
      try {
        return entry?.attributeValueIdentifier?.split(`${facetName}_`)?.filter(segment => segment !== '')?.pop() || entry.value
      } catch (e) {
        return entry.value
      }
    }
    
    const getUnTranslatedFilters = (plpFacets: Facet[], selectedFacets: SelectedFacets): SelectedFacets => {
      try {
        const facetIdentifiers = plpFacets.map(el => el.name)
        const currentlySelectedFacetsEntries = Object.keys(selectedFacets).map((key) => {
          return plpFacets.map((facet) => {
            return facet.entry.filter(entry => {
              return entry.value === key
            })
          })?.filter(entry => !!entry)?.flat()
        })?.flat()

        const getUntranslatedFacetData = (identifiers: string[], entry: Facet['entry'][0]): Array<{
          key: string
          value: string
        }> => {
          return identifiers.filter(identifier => entry?.attributeValueIdentifier?.includes(`${identifier}`)).map(identifier => {
            return {
              key: entry?.value || '',
              value: entry?.attributeValueIdentifier?.split(`${identifier}_`).filter(segment => segment !== '').pop() || ''
            }

          })?.filter(translatedFacet => !!translatedFacet)
        }

        return currentlySelectedFacetsEntries.map(entry => {
          return getUntranslatedFacetData(facetIdentifiers, entry)
        })?.filter(facet => !!facet)?.flat()?.reduce(
          (selectedFiltersObject, facet) => ({
            ...selectedFiltersObject,
            [facet.key]: facet?.value,
          }),
          {}
        )


      } catch (e) {
        return selectedFacets
      }
    }

    // productTotal can be -1
    if (!(currentPLPFacets && productTotal > 0 && selectedFacets)) {
      return null
    }

    const facetsMap: Record<string, string[]> = {}

    const putFacetValueToMap = (facetName: string, label: string) => {
      if (!facetsMap[facetName]) {
        facetsMap[facetName] = []
        facetsMap[facetName].push(label)
      } else if (facetName && facetsMap[facetName]) {
        facetsMap[facetName].push(label)
      }
    }

    if (selectedSortOption && selectedSortOption.value) {
      putFacetValueToMap('sortOrderOption', selectedSortOption.value)
    }

    for (const selectedFacetValue in getUnTranslatedFilters(currentPLPFacets, selectedFacets)) {
      const facet = getUnTranslatedFilters(currentPLPFacets, selectedFacets)[selectedFacetValue]

      if (facet && (facet as SelectedFacet).facetName) {
        putFacetValueToMap((facet as SelectedFacet).facetName, (facet as SelectedFacet).value)

        continue
      }

      for (const plpFacet of currentPLPFacets) {
        if (!selectedFacetValue.startsWith(plpFacet.value)) {
          continue
        }

        plpFacet.entry?.forEach((entry) => {
          if (entry.value === selectedFacetValue) {
            const untranslatedEntryvalue = getUntranslatedFilterEntryValue(entry,plpFacet.name)
            putFacetValueToMap(plpFacet.name, untranslatedEntryvalue)
          }
        })
      }
    }

    const filtersKeyValueArray: string[] = []

    for (const key in facetsMap) {
      for (const value of facetsMap[key]) {
        filtersKeyValueArray.push(`${key}=${value}`)
      }
    }

    return {
      Search_FacetValues_String: filtersKeyValueArray.join('|'),
      Search_Keyword: '',
      Search_ResultItemsQnt: productTotal.toString(),
    }
  }

  sendSearchFilterUpdatedEvent(Search_FacetValues_String: string, Search_ResultItemsQnt: string) {
    this.sendAnalyticEvent<Events.SearchFilterUpdated>({
      id: 'SearchFilterUpdated',
      Search_FacetValues_String,
      Search_ResultItemsQnt,
    })
  }

  sendPromoCodeEvent(isApplied: boolean, data: Events.PromoCode | Props.Error) {
    this.sendAnalyticEvent({
      id: isApplied ? 'OrderDiscountCode-Applied' : 'OrderDiscountCode-Tentative',
      ...data,
    })
  }

  /** Used in `useSendPageEventWithRequiredProps` hook only */
  sendPageEvent<P extends Events.PageLoadedKey>(
    data: Props.PageEventCommon & Events.PageLoadedPreset[P] & Events.PageLoadedSpecific[P]
  ) {
    this.sendAnalyticEvent({ ...data, id: 'VirtualPage-View' })
  }
}

export default new TealiumService()
