import { de, deAT, enCA, enGB, enIN, enUS, es, fr, it } from 'date-fns/locale'

export const DATE_FORMAT_PATTERN = {
  'MMMM d, yyyy': 'MMMM d, yyyy', // July 3, 2021,
  'yyyy-MM-dd': 'yyyy-MM-dd', // 2021-07-03
} as const

export const DATE_FNS_LOCALE_MAP = {
  de_AT: deAT,
  de_DE: de,
  en_CA: enCA,
  en_GB: enGB,
  en_IE: enIN,
  en_US: enUS,
  es_ES: es,
  fr_FR: fr,
  it_IT: it,
  nl_NL: enUS,
  pt_PT: enUS,
  // en_WW: 'en_WW',
} as const
