import { Maybe } from './common'
import { PRODUCT_SOLDOUT_STATUS } from '../constants/product'
import { PaginationResponse } from '../services/RequestService'
import { Facet } from './filters'

export type ProductSeo = {
  href?: Maybe<string>
}

export const PRODUCT_PRICE_USAGE_NAMES = {
  CURRENT: 'Offer', // current price of product, can be initial or discounted
  INITIAL: 'Display', // initial price of product, before applied discount
} as const

type UsageNames = keyof typeof PRODUCT_PRICE_USAGE_NAMES
type ProductPriceUsageName = typeof PRODUCT_PRICE_USAGE_NAMES[UsageNames]

export interface ProductPrice {
  __typename?: 'ProductPrice'
  contractId?: string
  currency: string
  description?: string
  usage: ProductPriceUsageName
  value: string
}

export interface ProductXPrice {
  endDate?: string
  startDate?: string
  price: string
  currency: string
  priority?: string
}

export interface DetailedRxPrice {
  type: string,
  initialPrice: ProductPrice,
  currentPrice: ProductPrice
}

export interface ProductAttribute {
  __typename?: 'ProductAttribute'
  comparable: string
  displayable: string
  facetable: string
  id: string
  identifier: string
  key?: string
  merchandisable: string
  name: string
  searchable: string
  sequence: string
  storeDisplay?: string
  swatchable: string
  usage: string
  values: ProductAttributeValue[]
}

type ProductAttributeValue = {
  __typename?: 'ProductAttributeValue'
  sequence?: Maybe<string> | Maybe<Array<Maybe<string>>>
  identifier?: Maybe<string> | Maybe<Array<Maybe<string>>>
  id?: Maybe<string> | Maybe<Array<Maybe<string>>>
  image1?: Maybe<string>
  image1path?: Maybe<string>
  value?: Maybe<string> | Maybe<Array<Maybe<string>>>
  unitOfMeasure?: Maybe<string>
  unitID?: Maybe<string>
}

export type ProductImage = {
  name: string
  sequence?: string
}

export type Attachment = {
  length: number
  usage: string
  sequence: string
  name: string
  attachmentAssetPathRaw: string
}

export interface ProductBase {
  __typename?: 'Product'
  attachments: Attachment[]
  attributes: ProductAttribute[]
  buyable: string
  cluster?: ProductBase[]
  hasSingleSKU: boolean
  id: string
  items?: ProductBase[]
  longDescription: string
  merchandisingAssociations: ProductBase[]
  name: string
  parentCatalogEntryID: string
  parentCatalogGroupID: string | string[]
  partNumber: string
  price: ProductPrice[]
  x_prices: {
    offer?: ProductXPrice
    list?: ProductXPrice
  }
  seo: ProductSeo
  shortDescription: string
  sKUs: ProductBase[]
  storeID: string
  type?: string
  uniqueID?: string
  x_offerpriceRx?: string
  x_offerDiscountpriceRx?: string
  x_priceBadge?: string
}

export interface ProductAnalyticsRX extends ProductBase {
  rxPrice?: number
}

export type ProductSoldOutStatus =
  typeof PRODUCT_SOLDOUT_STATUS[keyof typeof PRODUCT_SOLDOUT_STATUS]

export type DefaultProductCtaStatus = Extract<
  ProductSoldOutStatus,
  typeof PRODUCT_SOLDOUT_STATUS.NONE
>

export type SoldOutProductCtaStatus = Exclude<
  ProductSoldOutStatus,
  typeof PRODUCT_SOLDOUT_STATUS.NONE
>

export interface Category {
  description: string // "90s FLASHBACK"
  id: string
  identifier: string // "AN_coll_flashback"
  links: { parent: { href: string }; self: { href: string } }
  name: string // "90s FLASHBACK"
  parentCatalogGroupID: string // "/3074457345616680697/3074457345616680698"
  resourceId: string
  seo: {
    href: string // "/collection-90-flashback"
  }
  sequence: string // "1000.0"
  shortDescription: string // "90s FLASHBACK"
  storeID: string
  uniqueID: string
}

export type ProductBreadcrumb = {
  label: string
  type: string
  seo: { href: string }
  value: string
}

export type ProductRequestResponse = PaginationResponse<ProductBase & { type: 'item' }> & {
  breadCrumbTrailEntryView?: ProductBreadcrumb[]
  contents: ProductBase[]
  facets?: Facet[]
  metaData: { price: string }
  total: number
}

export interface PriceModel {
  price: string
  currency: string
}
