import findKey from 'lodash/findKey'
import * as queryString from 'querystring'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PAGINATION_CONFIGS, PRODUCT_LIST_FIELDS } from '../configs/catalog'
import { GROUPING_PROFILE_NAME, PROFILE_NAME_PARAM } from '../constants/common'
import { useSite } from '../foundation/hooks/useSite'
import { setFacetsAction } from '../redux/actions/catalog'
import { SingleSortOrderOption } from '../redux/reducers'
import { currentContractIdSelector } from '../redux/selectors/contract'
import Log from '../services/Log'
import ProductService from '../services/ProductService'
import { Facet, SelectedFacet, SelectedFacets, SelectedFacetsStringMap } from '../types/filters'
import { ProductBase, ProductBreadcrumb } from '../types/product'
import { fromUrlParamsToObject, updateUrl } from '../utils/url'
import { useAppDispatch } from './redux'
import { sortOrderOptionsSelector } from '../redux/selectors/site'
import { PLP_DEFAULT_SORT_OPTION } from '../redux/reducers/initStates'
import tealiumService from '../foundation/analytics/tealium/tealium.service'

const FACET_CATEGORY_VALUE_PREFIX = 'path.tree:'
const defaultCurrentPage = 1

const getFacetFromUrl = (facet: string[] | string): SelectedFacetsStringMap => {
  if (!facet) return {}

  if (Array.isArray(facet)) {
    return facet.reduce((acc, f) => {
      //returns an object, I need the first key
      const decodedFacet = queryString.decode(f)
      const firstKey = Object.keys(decodedFacet)[0]
      const splitDecodedFacet = String(firstKey).split(':')
      Log.info('facet', splitDecodedFacet.toString())
      acc[f] = splitDecodedFacet[1].replace('"', '')
      return acc
    }, {} as SelectedFacetsStringMap)
  } else {
    const decodedFacet = queryString.decode(facet)
    const retValue = {} as SelectedFacetsStringMap

    if (decodedFacet !== undefined) {
      const firstKey = Object.keys(decodedFacet)[0]
      const splitDecodedFacet = String(firstKey).split(':')
      retValue[facet] = splitDecodedFacet[1].replace('"', '')
    }

    return retValue
  }
}

const sendSearchFilterUpdatedEvent = (
  currentPLPFacets: Facet[],
  selectedFacets: SelectedFacets,
  selectedSortOption: SingleSortOrderOption,
  productTotal: number
) => {
  const searchProps = tealiumService.formatSearchProps(
    currentPLPFacets,
    selectedFacets,
    selectedSortOption,
    productTotal
  )

  if (searchProps) {
    tealiumService.sendSearchFilterUpdatedEvent(
      searchProps.Search_FacetValues_String,
      searchProps.Search_ResultItemsQnt
    )
  }
}

export interface UseProductsReturn {
  areFacetsLoading: boolean
  areProductsLoading: boolean
  breadcrumbs: ProductBreadcrumb[]
  currentPage: number
  products: ProductBase[]
  productsTotal: number
  selectedFacets: SelectedFacets
  selectedSortOption: SingleSortOrderOption
  deselectAllFacets(): void
  fetchProducts(
    selectedFacetsArg?: SelectedFacets,
    selectedSortOptionArg?: SingleSortOrderOption,
    offset?: number,
    limit?: number
  ): Promise<void>
  getUpdatedSelectedFacets(facet: Facet, entry: Facet['entry'][number]): SelectedFacets
  handleFacetSelect(facet: Facet, entry: Facet['entry'][number]): void
  cleanup(): void
}

const useProducts = (categoryId: string, searchTerm: string): UseProductsReturn => {
  const { mySite } = useSite()
  const dispatch = useAppDispatch()
  const contractId = useSelector(currentContractIdSelector)
  const sortOrderOptions = useSelector(sortOrderOptionsSelector)!

  const [areProductsLoading, setProductsLoading] = useState<boolean>(true)
  const [areFacetsLoading, setFacetsLoading] = useState<boolean>(true)
  const [currentPage, setCurrentPage] = useState(defaultCurrentPage)
  const [selectedSortOption, setSelectedSortOption] =
    useState<SingleSortOrderOption>(PLP_DEFAULT_SORT_OPTION)
  const [selectedFacets, setSelectedFacets] = useState<SelectedFacets>({})
  const [products, setProducts] = useState<ProductBase[]>([])
  const [productsTotal, setProductsTotal] = useState<number>(-1)
  const [breadcrumbs, setBreadcrumbs] = useState<ProductBreadcrumb[]>([])

  const baseRequestParams = useMemo(
    () => ({
      contractId,
      currency: mySite.defaultCurrencyID,
      _fields: PRODUCT_LIST_FIELDS,
      [PROFILE_NAME_PARAM]: GROUPING_PROFILE_NAME,
      ...(categoryId ? { categoryId } : null),
    }),
    [categoryId, contractId, mySite.defaultCurrencyID, selectedSortOption.value]
  )

  const setFacetsToStore = (facets: Facet[]): void => {
    dispatch(setFacetsAction(facets))
  }

  /** Performs a request for all possible facets, by setting limit=0 & offset=9999, and sets response to store */
  const fetchFacetsAndSetToStore = () => {
    ProductService.get({ ...baseRequestParams, limit: 0, offset: 9999 }).then((response) => {
      if (response.facets) {
        setFacetsToStore(response.facets)
      }
    })
  }

  /** Performs products request with provided params.
   * Sets products, productsTotal, selectedFacets, selectedSortOption.
   * Updates url.
   * @returns {Promise<void>}
   */
  const fetchProducts = async (
    selectedFacetsArg?: SelectedFacets,
    selectedSortOptionArg?: SingleSortOrderOption,
    offset?: number,
    limit?: number
  ): Promise<void> => {
    setProductsLoading(true)

    const freshSelectedFacets = selectedFacetsArg || selectedFacets
    const freshSelectedSortOption = selectedSortOptionArg || selectedSortOption

    const { requestParams, urlQueryParams } = ProductService.getUrlAndRequestParams(
      baseRequestParams,
      searchTerm,
      freshSelectedFacets,
      freshSelectedSortOption,
      offset,
      limit
    )
    const currentPage = +urlQueryParams['page']

    if (selectedFacetsArg) {
      setSelectedFacets(selectedFacetsArg)
    }

    if (selectedSortOptionArg) {
      setSelectedSortOption(selectedSortOptionArg)
    }

    updateUrl(window.location.pathname, urlQueryParams)
    setCurrentPage(currentPage)

    const response = await ProductService.get(requestParams)

    setProductsTotal(response.total ?? 0)
    setProducts(currentPage > 1 ? [...products, ...response.contents] : response.contents || [])
    setProductsLoading(false)

    if (
      response.facets &&
      (!!searchTerm || Object.keys(selectedFacets).length || freshSelectedSortOption.value) &&
      response.total >= 0
    ) {
      sendSearchFilterUpdatedEvent(
        response.facets,
        freshSelectedFacets,
        {
          ...freshSelectedSortOption,
          value:
            findKey(sortOrderOptions, freshSelectedSortOption) || freshSelectedSortOption.value,
        },
        response.total
      )
    }

    if (response.facets && selectedFacetsArg) {
      setFacetsToStore(response.facets)
    }

    return Promise.resolve()
  }

  const onSelectedFacetsChange = async (
    selectedFacets: SelectedFacets,
    selectedSortOption: SingleSortOrderOption
  ) => {
    setFacetsLoading(true)

    const { requestParams } = ProductService.getUrlAndRequestParams(
      baseRequestParams,
      searchTerm,
      selectedFacets,
      selectedSortOption
    )

    setSelectedFacets(selectedFacets)
    setSelectedSortOption(selectedSortOption)

    const response = await ProductService.get({
      ...requestParams,
      limit: 0, // we dont need products here ARN-6304
      offset: 0,
    })

    if (Array.isArray(response.facets)) {
      setFacetsToStore(response.facets)
    }

    setProductsTotal(response.total ?? 0)

    setFacetsLoading(false)
  }

  /** Receives data of changed facet (selected/deselected), merges it with other selected facets and returns merged.
   * Doesn't mutate selectedFacets (state variable).
   */
  const getUpdatedSelectedFacets = (facet: Facet, entry: Facet['entry'][number]) => {
    const facetEntryValue: string =
      (ProductService.isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : '') + entry.value
    const facetEntryLabel: string = entry.label
    const facetName: string = facet.name

    let newSelectedFacets = Object.assign({}, selectedFacets)

    if (newSelectedFacets[facetEntryValue] === undefined) {
      newSelectedFacets = {
        ...newSelectedFacets,
        [facetEntryValue]: { value: facetEntryLabel, facetName } as SelectedFacet,
      } as SelectedFacets
    } else {
      const { [facetEntryValue]: tempLabel, ...newRemovedSelectedFacets } = newSelectedFacets

      newSelectedFacets = newRemovedSelectedFacets
    }

    return newSelectedFacets
  }

  const handleFacetSelect = (facet: Facet, entry: Facet['entry'][number]) => {
    onSelectedFacetsChange(getUpdatedSelectedFacets(facet, entry), selectedSortOption)
  }

  const deselectAllFacets = () => {
    onSelectedFacetsChange({}, PLP_DEFAULT_SORT_OPTION)
  }

  const cleanup = () => {
    setProducts([])
    setProductsTotal(-1)
    setSelectedFacets({})
    setSelectedSortOption(PLP_DEFAULT_SORT_OPTION)
    setBreadcrumbs([])
    setCurrentPage(defaultCurrentPage)
  }

  useEffect(() => {
    if (contractId && (!!categoryId || !!searchTerm)) {
      setProductsLoading(true)
      setFacetsLoading(true)

      const queryParams = fromUrlParamsToObject(window.location.search)
      const selectedFacetsFromQuery = getFacetFromUrl(queryParams.facet as string[])
      const currentPage =
        typeof queryParams.page === 'string' && !isNaN(+queryParams.page)
          ? +queryParams.page
          : defaultCurrentPage

      const sortValue = (queryParams.orderBy as string) || selectedSortOption?.value
      const sortProfile = (queryParams.profileName as string) || selectedSortOption?.profileName
      const sortOption =
        sortValue && sortProfile
          ? {
              value: sortValue,
              profileName: searchTerm.length
                ? Object.values(sortOrderOptions).find(({ value }) => value === sortValue)!
                    .profileName
                : sortProfile,
            }
          : PLP_DEFAULT_SORT_OPTION

      // parameters for the request are the url parameters merged with base params
      const { requestParams } = ProductService.getUrlAndRequestParams(
        baseRequestParams,
        searchTerm,
        selectedFacetsFromQuery,
        sortOption,
        0,
        PAGINATION_CONFIGS.pageLimit * currentPage
      )

      setSelectedFacets(selectedFacetsFromQuery)
      setCurrentPage(currentPage)
      setSelectedSortOption(sortOption)

      fetchFacetsAndSetToStore()

      ProductService.get(requestParams).then((response) => {
        const { breadCrumbTrailEntryView, contents, facets, total } = response

        if (breadCrumbTrailEntryView) {
          setBreadcrumbs(breadCrumbTrailEntryView)
        }

        setProducts(contents || [])
        setProductsTotal(total ?? 0)

        if (Array.isArray(facets) && facets.length) {
          setFacetsToStore(facets)
        }

        setProductsLoading(false)
        setFacetsLoading(false)
      })
    }

    return cleanup
  }, [categoryId, contractId, searchTerm])

  return {
    areFacetsLoading,
    areProductsLoading,
    breadcrumbs,
    currentPage,
    products,
    productsTotal,
    selectedFacets,
    selectedSortOption,
    deselectAllFacets,
    fetchProducts,
    getUpdatedSelectedFacets,
    handleFacetSelect,
    cleanup,
  }
}

export default useProducts
