import styled from 'styled-components'

export const StyledContainer = styled.footer<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  padding: 10px 108px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  font-size: 0.75rem;

  background-color: ${theme.palette.primary.warmGrey};
  border-top: 1px solid ${theme.palette.text[themeMode]};

  a {
    cursor: pointer;
    color: ${theme.palette.text[themeMode]};
    text-decoration: underline;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 10px 1rem 3rem 1rem;

    flex-direction: column;
    justify-content: center;

    & > :first-child {
      margin-bottom: 31px;
      padding: 10px 19px 20px 19px;
      width: 100%;

      border-bottom: 1px solid ${theme.palette.text[themeMode]};

      text-align: center;
    }
  }
`}
`

export const StyledSecurityContainer = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 40px;

    display: flex;
    align-items: center;
    span {
      margin-left: 6px;
    }
  }

  & > :last-child {
    display: flex;
    align-items: center;

    & > svg:not(:last-child) {
      margin-right: 10px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    justify-content: center;

    & > :first-child {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`
