import * as ROUTES from '../../../constants/routes'
//Standard libraries
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
//UI
import { StyledButtonAsLink, StyledCheckbox, StyledGrid, StyledTypography } from '../../StyledUI'
import Table, { TableCell } from '../../StyledUI/StyledTable/Table'
import { Trans, useTranslation } from 'react-i18next'
import { buildOrderHistoryCells, buildOrderHistoryHeaders } from '../../../constants/order'
import { generatePath, useHistory } from 'react-router'
import { userDetailsSelector, userIdSelector } from '../../../redux/selectors/user'

import { ORDER_HISTORY } from '../../../constants/routes'
import { CountryBaseLink } from '../../StyledUI/StyledLink/StyledLink'
import { NewsletterService } from '../../../foundation/apis/newsletter/newsletter.service'
import { Order } from '../../../types/order'
import { OrderHistoryList } from '../../pages/order/OrderHistoryList'
import orderService from '../../../foundation/apis/transaction/order.service'
import styled from 'styled-components'
//Foundation libraries
import theme from '../../../themes'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { useCms } from '../../providers/CmsProvider'
//Custom libraries
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION } from './../../../redux/actions/user'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'

const StyledWrapper = styled.div`
  padding-bottom: 3rem;
`

const StyledContainer = styled(StyledGrid)<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  border: 1px solid ${theme.palette.custom[themeMode === 'dark' ? 'warmGrey' : 'black']};
  border-bottom: none;
  padding: 1rem;
  padding-bottom: 2rem;

  .order-history__list-item {
    padding: 0 0 ${theme.spacing(1)}px;
  }

  .arn-account--personal_information__orders-2 {
    margin-top: ${theme.spacing(0.625)}px;
    margin-left: ${theme.spacing(1)}px;
    display: none;
  }

  ${getDesktopThemeBreakpoint(theme)} {
    padding: 24px 36px 32px;

    .arn-account--personal_information__orders {
      &-1 {
        display: none;
      }

      &-2 {
        display: inline-block;
      }
    }
  }
`}
`

const StyledContainerLast = styled(StyledContainer)<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  border-bottom: 1px solid ${theme.palette.custom[themeMode === 'dark' ? 'warmGrey' : 'black']};
`}
`

const StyledPrivacy = styled(StyledTypography)`
  width: 85%;
  font-size: 12px;

  ${theme.breakpoints.up('md')} {
    width: initial;
  }
`

const StyledEdit = styled(StyledGrid)`
  ${({ theme }) => `
  margin-bottom: ${theme.spacing(1.5)}px;
  align-items: center;
  justify-content: space-between;

  button {
    text-transform: uppercase;
    font-size: 12px;
  }

  ${theme.breakpoints.up('md')} {
    margin-bottom: 10px;
    justify-content: flex-start;
    gap: 30px;
  }
`}
`

const StyledInfo = styled(StyledGrid)`
  ${({ theme }) => `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    min-width: 25%;
  }
  p,
  div {
    font-size: 12px;
  }

  ${getDesktopThemeBreakpoint(theme)} {
  }
`}
`

const StyledSummary = styled(StyledTypography)`
  font-size: 12px;
`

function PersonalInformationLayout() {
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()
  const history = useHistory()
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const { privacyPagePath } = useCms()

  const userId = useSelector(userIdSelector)
  const addressDetails = useSelector(userDetailsSelector)

  const [orderHistory, setOrderHistory] = useState<Order[]>([])
  const [isOrderHistoryLoading, setOrderHistoryLoading] = useState<boolean>(true)

  const orderHistoryTableHeaders = useMemo<string[]>(() => buildOrderHistoryHeaders(t), [t])

  const orderHistoryTableCells = useMemo<TableCell<Order>[]>(
    () => buildOrderHistoryCells({ isDesktop, langCode, t }),
    [isDesktop, langCode, t]
  )

  const AccountInformation = t('PersonalInformation.AccountInformation')
  const Newsletter = t('PersonalInformation.Newsletter')
  const PrivacyPolicy = t('PersonalInformation.PrivacyPolicy')
  const Edit = t('PersonalInformation.Edit')
  const RecentOrder = t('PersonalInformation.OrderInfo')
  const AllOrders = t('PersonalInformation.Orders').toUpperCase()
  const Name = t('PersonalInformation.Name')
  const Email = t('PersonalInformation.Email')
  const themeMode = useSelector(themeModeSelector, shallowEqual)

  const [isNewsletterCheckboxChecked, setNewsletterCheckboxChecked] = useState(
    addressDetails?.x_data.hasNewsletter
  )

  const [isNewsletterCheckboxDisabled, setNewsletterCheckboxDisabled] = useState(false)

  const onNewsletterCheckboxChange = (_e, checked: boolean) => {
    if (!addressDetails?.email1) {
      return
    }

    if (checked) {
      setNewsletterCheckboxDisabled(true)

      NewsletterService.subscribeToNewsletter({
        email: addressDetails.email1,
        firstName: addressDetails.firstName,
        newsletter_id: mySite.newsletterType,
        from: 'myAccount',
      })
        .then(() => {
          setNewsletterCheckboxChecked(true)

          dispatch(NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION({ subscribed: true }))
        })
        .finally(() => setNewsletterCheckboxDisabled(false))
    } else {
      setNewsletterCheckboxDisabled(true)

      setNewsletterCheckboxDisabled(false)

      NewsletterService.unsubscribeFromNewsletter({
        email: addressDetails.email1,
      })
        .then((res) => {
          if (res) {
            setNewsletterCheckboxChecked(false)

            dispatch(NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION({ subscribed: false }))
          }
        })
        .finally(() => setNewsletterCheckboxDisabled(false))
    }
  }

  useEffect(() => {
    orderService
      .getOrderHistory({ userId, pageSize: 3 })
      .then((orders) => setOrderHistory(orders))
      .finally(() => setOrderHistoryLoading(false))
  }, [])

  useEffect(() => {
    setNewsletterCheckboxChecked(addressDetails?.x_data.hasNewsletter)
  }, [addressDetails])

  return (
    <StyledWrapper className="arn_account arn-account--personal_information">
      <StyledContainer themeMode={themeMode}>
        <StyledGrid item>
          <StyledSummary variant="subtitle2">
            {t('PersonalInformation.welcomeMessage')} {addressDetails?.firstName}{' '}
            {addressDetails?.lastName}. <br />
            {t('PersonalInformation.personalInformationMessage')}
          </StyledSummary>
        </StyledGrid>
      </StyledContainer>
      <StyledContainer
        themeMode={themeMode}
        container
        spacing={0}
        justifyContent="space-between"
        alignItems="center">
        <StyledEdit container spacing={0}>
          <StyledTypography variant="body1">{AccountInformation}</StyledTypography>
          <StyledButtonAsLink
            data-element-id={'X_X_PersonalInformation_Edit'}
            onClick={() =>
              history.push(generatePath(ROUTES.PERSONAL_INFORMATION, { country: langCode }))
            }
            customprops={{
              isUnderlined: true,
              color:
                themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.primary.dark,
            }}>
            {Edit}
          </StyledButtonAsLink>
        </StyledEdit>
        <StyledGrid item xs={12} sm={6} md={4}>
          <StyledInfo item container spacing={0}>
            <StyledTypography variant="body1" className="bottom-margin-1">
              {Name}
            </StyledTypography>
            <StyledTypography variant="body1" className="bottom-margin-1">
              {addressDetails?.firstName} {addressDetails?.lastName}
            </StyledTypography>
          </StyledInfo>
          <StyledInfo item container spacing={0}>
            <StyledTypography variant="body1" className="bottom-margin-1">
              {Email}
            </StyledTypography>
            <StyledTypography variant="body1" className="bottom-margin-1" component="div">
              {addressDetails?.email1 && (
                <StyledTypography variant="body1" component="div">
                  {addressDetails?.email1}
                </StyledTypography>
              )}
            </StyledTypography>
          </StyledInfo>
        </StyledGrid>
      </StyledContainer>
      <StyledContainer themeMode={themeMode} container spacing={0} alignItems="center">
        <StyledEdit container spacing={0} justifyContent="space-between" alignItems="center">
          <StyledTypography variant="body1">{Newsletter}</StyledTypography>
        </StyledEdit>
        <StyledGrid item xs={12}>
          <label>
            <StyledInfo item container spacing={0}>
              <StyledCheckbox
                checked={isNewsletterCheckboxChecked}
                disabled={isNewsletterCheckboxDisabled}
                onChange={onNewsletterCheckboxChange}
              />
              <StyledPrivacy variant="body1" className="bottom-margin-1">
                <Trans i18nKey="PersonalInformation.NewsletterCheckbox">
                  {{ policyUrl: PrivacyPolicy }}
                  <CountryBaseLink to={privacyPagePath} variant="primaryUnderlined" />
                </Trans>
              </StyledPrivacy>
            </StyledInfo>
          </label>
        </StyledGrid>
      </StyledContainer>
      <StyledContainerLast themeMode={themeMode}>
        <StyledEdit container spacing={0}>
          <StyledTypography variant="body1">{RecentOrder}</StyledTypography>
          <CountryBaseLink
            className="arn-account--personal_information__orders-1"
            fontSize="0.75rem"
            variant="primaryUnderlined"
            to={ORDER_HISTORY}>
            {AllOrders}
          </CountryBaseLink>
        </StyledEdit>

        {isDesktop && (
          <Table
            cells={orderHistoryTableCells}
            headers={orderHistoryTableHeaders}
            items={orderHistory}
          />
        )}

        {!isDesktop && <OrderHistoryList orders={orderHistory} />}

        <StyledInfo item container spacing={0}>
          {!isOrderHistoryLoading && !orderHistory.length && (
            <StyledTypography variant="body1" className="bottom-margin-1">
              {t('Order.NoRecord')}
            </StyledTypography>
          )}
        </StyledInfo>

        <CountryBaseLink
          className="arn-account--personal_information__orders-2"
          fontSize="0.75rem"
          variant="primaryUnderlined"
          to={ORDER_HISTORY}>
          {AllOrders}
        </CountryBaseLink>
      </StyledContainerLast>
    </StyledWrapper>
  )
}

export { PersonalInformationLayout }
