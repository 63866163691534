import { TMedia } from '../../types/teaser'
import useBreakpoints from '../useBreakpoints'

const useMediaByDeviceType = (medias: TMedia[]) => {
  const { isMobile } = useBreakpoints()
  return getMediaByDeviceType(isMobile, medias)
}

// Useful for non-functional components or for conditional rendering
export const getMediaByDeviceType = (isMobile: boolean, medias: TMedia[]) => {
  return isMobile && medias.length > 1 ? medias[1] : medias[0]
}

export default useMediaByDeviceType
