import {
  AffirmIcon,
  AmExIcon,
  ApplePayIcon,
  DiscoverIcon,
  KlarnaIcon,
  MastercardIcon,
  PayPalIcon,
  VisaIcon,
} from '../components/StyledUI/Icons/Icons'

import { FC } from 'react'
import { SvgIconProps } from '@material-ui/core'

const KEYS = {
  AFFIRM: 'AFFIRM',
  AMEX: 'AMEX',
  APPLE_PAY: 'APPLE_PAY',
  COD: 'COD',
  DISCOVER: 'DISCOVER',
  MASTERCARD: 'MASTERCARD',
  PAYPAL: 'PAYPAL',
  VISA: 'VISA',
  KLARNA: 'KLARNA',
} as const

type Key = keyof typeof KEYS
export type Icon = FC<SvgIconProps>

const { AFFIRM, AMEX, APPLE_PAY, COD, DISCOVER, KLARNA, MASTERCARD, PAYPAL, VISA } = KEYS

/**
 * @constant
 * Map of payment methods names fetched from usable_payment_info request ("paymentMethodName" property from "usablePaymentInfo" array)
 */
const CHECKOUT_NAMES = {
  [AFFIRM]: '? Affirm',
  [AMEX]: 'CyberSourceAmex',
  [APPLE_PAY]: 'ApplePay',
  [COD]: 'cod',
  [DISCOVER]: 'CyberSourceDiscover',
  [KLARNA]: 'CyberSourceKlarna',
  [MASTERCARD]: 'CyberSourceMC',
  [PAYPAL]: 'PayPal',
  [VISA]: 'CyberSourceVisa',
} as const

/**
 * @constant
 * Map of all credit cards methods
 */
const CHECKOUT_CC_NAMES: CheckoutCCNames = [
  CHECKOUT_NAMES[AMEX],
  CHECKOUT_NAMES[DISCOVER],
  CHECKOUT_NAMES[MASTERCARD],
  CHECKOUT_NAMES[VISA],
]

/**
 * Type of payment method name. Equals to "paymentMethodName" property in "usablePaymentInfo" array (from server)
 */
export type CheckoutName = typeof CHECKOUT_NAMES[keyof typeof CHECKOUT_NAMES]

/**
 * Type of array with credit card method names.
 */
export type CheckoutCCNames = CheckoutName[]

/**
 * @constant
 * Map of payment methods names that are fetched from server and stored at {@linkcode xStoreCfg.paymentMethods} string array.
 * Displayed at footer's "Payment methods" section.
 */
const FOOTER_NAMES = {
  [AFFIRM]: '? Affirm',
  [AMEX]: 'Amex',
  [APPLE_PAY]: 'ApplePay',
  [DISCOVER]: 'Discover',
  [MASTERCARD]: 'MasterCard',
  [PAYPAL]: 'PayPal',
  [VISA]: 'CreditCard',
  [KLARNA]: 'Klarna',
} as const

/**
 * Type of payment method name. Equals to one of the strings from {@linkcode xStoreCfg.paymentMethods} array
 */
export type FooterName = typeof FOOTER_NAMES[keyof typeof FOOTER_NAMES]

const ICONS: Record<Key, Icon | null> = {
  [AFFIRM]: AffirmIcon,
  [AMEX]: AmExIcon,
  [APPLE_PAY]: ApplePayIcon,
  [COD]: null,
  [DISCOVER]: DiscoverIcon,
  [KLARNA]: KlarnaIcon,
  [MASTERCARD]: MastercardIcon,
  [PAYPAL]: PayPalIcon,
  [VISA]: VisaIcon,
} as const

const PAY_OPTIONS = { cod: 'COD', cc: 'CC' }

/* const TYP_NAMES = {
  [CHECKOUT_NAMES.KLARNA]: 'Klarna Payment',
} as const */

export const PAYMENT_METHODS = {
  CHECKOUT_NAMES,
  FOOTER_NAMES,
  ICONS,
  PAY_OPTIONS,
  CHECKOUT_CC_NAMES,
}

/**
 * @constant
 * Map of all errors code received from Cybersource
 */
export const SECURE_PAYMENT_CODES = {
  100: '100',
  101: '101',
  102: '102',
  150: '150',
  151: '151',
  152: '152',
  231: '231',
  233: '233',
  234: '234',
  236: '236',
  239: '239',
  241: '241',
  244: '244',
  250: '250',
  254: '254',
  255: '255',
  475: '475',
  476: '476',
} as const

/**
 * Type of Cybersource codes
 */
export type SecurePaymentCode = typeof SECURE_PAYMENT_CODES[keyof typeof SECURE_PAYMENT_CODES]

/**
 * Type of Cybersource success codes
 */
export type SecurePaymentSuccessCode = SecurePaymentCode[]

/**
 * Type of Cybersource error codes
 */
export type SecurePaymentErrorCode = SecurePaymentCode[]

/**
 * @constant
 * Map of all Cybersource success codes
 */
export const SECURE_PAYMENT_SUCCESS_CODES: SecurePaymentSuccessCode = [
  SECURE_PAYMENT_CODES[100],
  SECURE_PAYMENT_CODES[475],
]

/**
 * @constant
 * Map of all Cybersource error codes
 */
export const SECURE_PAYMENT_ERROR_CODES: SecurePaymentErrorCode = [
  SECURE_PAYMENT_CODES[101],
  SECURE_PAYMENT_CODES[102],
  SECURE_PAYMENT_CODES[150],
  SECURE_PAYMENT_CODES[151],
  SECURE_PAYMENT_CODES[152],
  SECURE_PAYMENT_CODES[231],
  SECURE_PAYMENT_CODES[233],
  SECURE_PAYMENT_CODES[244],
  SECURE_PAYMENT_CODES[255],
  SECURE_PAYMENT_CODES[476],
]

export const METHODS_UNAVAILABLE_IF_NOT_EQUAL_SHIPPING_BILLING: CheckoutName[] = [];

/**
 * Type of available payment methods in Checkout page
 */
export type AvailableCheckoutPaymentMethods =
  'CyberSourceDiscover'
  | 'CyberSourceAmex'
  | 'CyberSourceMC'
  | 'CyberSourceVisa'
  | 'ApplePay'
  | 'PayPal'

/**
 * Type of payment method with details for validator
 */
export type CardDetails = {
  cardType: AvailableCheckoutPaymentMethods
  maxLength: number
  patterns: string[]
  cvvSize: number,
  holderNameFormat: RegExp,
}

/**
 * @constant
 * Default details for credit cards
 */
export const STANDARD_CARD_DETAILS: Omit<CardDetails, 'cardType' | 'patterns'> = {
  maxLength: 16,
  cvvSize: 3,
  holderNameFormat: /^(([a-z]){1,24}(\.*)(\s*))+$/i
};

/**
 * @constant
 * Array with validator details for available payment methods in Checkout page
 */
export const CHECKOUT_CARD_DETAILS: CardDetails[] = [
  { cardType: CHECKOUT_NAMES[AMEX], ...STANDARD_CARD_DETAILS, patterns: ['3', '34', '37'], maxLength: 15, cvvSize: 4 },
  { cardType: CHECKOUT_NAMES[VISA], ...STANDARD_CARD_DETAILS, patterns: ['4', '41'] },
  { cardType: CHECKOUT_NAMES[MASTERCARD], ...STANDARD_CARD_DETAILS, patterns: ['2', '5'] },
  { cardType: CHECKOUT_NAMES[DISCOVER], ...STANDARD_CARD_DETAILS, patterns: ['6', '65'] },
];

/**
 * Checks if the entered credit card number is a numeric value
 * @returns Whether the card number format is valid.
 */
export const isValidCardNumber = (cardNumber: string, paymentMethod: AvailableCheckoutPaymentMethods): boolean => {
  const currentCard = CHECKOUT_CARD_DETAILS.find((card) => card.cardType === paymentMethod)

  if (!currentCard || isNaN(+cardNumber)) {
    return false
  }

  const isCardNumberPatternValid = !!currentCard.patterns.find(pattern => cardNumber.startsWith(pattern));

  return isCardNumberPatternValid && cardNumber.length === currentCard.maxLength
}

/**
 * Checks if the provided cardholder name corresponds to a certain format of the payment method
 * @returns Whether the cardholder name is valid.
 */
export const isValidCardHolderName = (cardHolderName: string, paymentMethod: AvailableCheckoutPaymentMethods): boolean => {
  const currentCard = CHECKOUT_CARD_DETAILS.find((card) => card.cardType === paymentMethod)

  if (cardHolderName.trim() === '' || !currentCard) {
    return false
  }

  return currentCard.holderNameFormat.test(cardHolderName.trim())
}

/**
 * Checks if the provided data that includes year and month isn't expired
 * @returns Whether the data is valid.
 */
export const isValidCardExpirationDate = (year: number, month: number): boolean => {
  const currentDate = new Date(new Date().getFullYear(), new Date().getMonth())
  const receivedDate = new Date(year, month - 1)

  if (!receivedDate.getTime()) {
    return false
  }

  const isCardExpired = receivedDate.getTime() < currentDate.getTime()

  return !isCardExpired
}

/**
 * Checks if the cvv number is a valid numeric value and the length of the characters matches.
 * @returns Whether the cvv code format is valid.
 */
export const isValidCode = (cardCVC: string, paymentMethod: AvailableCheckoutPaymentMethods): boolean => {
  const currentCard = CHECKOUT_CARD_DETAILS.find((card) => card.cardType === paymentMethod)

  if (!currentCard || isNaN(+cardCVC)) {
    return false
  }

  return cardCVC.length === currentCard.cvvSize
}
