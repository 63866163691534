//Standard libraries
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
//Redux
import { userDetailsSelector, userNameSelector } from '../../../redux/selectors/user'
//UI
import { StyledTypography } from '../../StyledUI'
import DateService from '../../../services/DateService'

function WelcomeUserSection() {
  const { t } = useTranslation()
  const addressDetails = useSelector(userDetailsSelector)
  const login = addressDetails?.x_data?.lastUserLogin?.toString().split(' ')[0]
  const title = t('WelcomeUserSection.Title')
  const LastLogin = t('WelcomeUserSection.LastLogin')
  const { firstName, lastName } = useSelector(userNameSelector)
  const splitted = login?.split('-').toString()
  const date = splitted ? new Date(splitted) : new Date()
  const formatDate: string = DateService.format(date)

  return (
    <>
      <StyledTypography component="p" variant="caption">
        {title}
        {firstName} {lastName}
      </StyledTypography>
      <StyledTypography component="p" variant="caption">
        {LastLogin}
        {formatDate}
      </StyledTypography>
    </>
  )
}

export { WelcomeUserSection }
