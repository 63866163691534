/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from 'react'
import styled from 'styled-components'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

const StyledListItemSecondaryAction = styled(({ ...props }) => (
  <ListItemSecondaryAction {...props} />
))`
  ${({ theme }) => `
  `}
`

export default StyledListItemSecondaryAction
