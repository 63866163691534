import React, { CSSProperties, FC, ReactNode } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { StyledTable } from './Table.style'

export interface TableProps<T = any> {
  headers?: ReactNode[]
  items: T[]
  cells: TableCell<T>[]
}

export interface TableCell<T> {
  key: 'check' | 'action' | keyof T
  widthPercent?: number
  render?(item: T, index: number): ReactNode
}

const getCellStyleProps = (cell: TableCell<unknown>): { style?: CSSProperties } => ({
  ...(cell?.widthPercent ? { style: { width: `${cell?.widthPercent || 100}%` } } : null),
})

const Table: FC<TableProps> = ({ headers, items = [], cells }) => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  return (
    <StyledTable themeMode={themeMode}>
      {headers && (
        <thead>
          <tr>
            {headers?.map((item, index) => (
              <th key={index} {...getCellStyleProps(cells[index])}>
                {item}
              </th>
            ))}
          </tr>
        </thead>
      )}

      <tbody>
        {items?.map((item, itemIndex) => (
          <tr key={itemIndex}>
            {cells?.map((cell) => (
              <td key={cell.key as string} {...getCellStyleProps(cell)}>
                {cell?.render ? cell.render(item, itemIndex) : item[cell.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

export default Table
