/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import StyledCircularProgress from './StyledCircularProgress'
import StyledProgressPlaceholder from './StyledProgressPlaceholder'

export default StyledCircularProgress
export { StyledProgressPlaceholder }
