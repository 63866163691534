/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
// user context

export const USER_CONTEXT_REQUESTED = 'USER_CONTEXT_REQUESTED'
export const USER_CONTEXT_REQUEST_SUCCESS = 'USER_CONTEXT_REQUEST_SUCCESS'
export const USER_CONTEXT_REQUEST_ERROR = 'USER_CONTEXT_REQUEST_ERROR'
