import { createSlice,AnyAction, isAnyOf } from '@reduxjs/toolkit'
import { CountryStates, StatesProvinces } from '../../types/common'
import * as ACTIONS from '../../redux/action-types/account'
import { FETCH_USER_DETAILS_SUCCESS_ACTION, LOGOUT_SUCCESS_ACTION, REGISTRATION_SUCCESS_ACTION } from '../../redux/actions/user'
import listenerMiddleware from '../../redux/listener'
import { accountApi } from './query'
import { get } from 'lodash-es'
import { sendRegistrationEvent } from '../../foundation/analytics/tealium/lib'
import { RegistrationBody } from '../../types/account'
import Log from '../../services/Log'

export interface IAccountState {
  isFetching?: boolean
  error?: any
  address?: any
  orders?: any
  countries?: CountryStates[]
  statesProvinces?: StatesProvinces[]
}


const initialState: IAccountState = {
  isFetching: false,
}
//TODO: THIS SHOULD REPLACE OLD ACCOUNT AND USER REDUCER

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //TODO: REMOVE THE BELOW OLD ACTIONS WHEN SWITCHING FROM SAGA TO RTK
    builder.addCase(ACTIONS.ORDERS_GET_SUCCESS, (state, action: AnyAction) => {
      state.orders = action.response
    })
  
    builder.addCase(LOGOUT_SUCCESS_ACTION, (state) => {
      state.orders = null
    })
    builder.addCase(ACTIONS.COUNTRY_DETAILS_GET_SUCCESS, (state, action: AnyAction) => {
      const response = action.response
      state.countries = response.countries
    })
    builder.addCase(ACTIONS.PROVINCES_DETAILS_GET_SUCCESS, (state, action: AnyAction) => {
      const response = action.response
      state.statesProvinces = response.countryCodeStates
    })
    builder.addCase(ACTIONS.COUNTRY_DETAILS_GET_ERROR, (state) => {
      state = {
        ...state,
        countries: [],
      }
    })
    builder.addCase(ACTIONS.PROVINCES_DETAILS_GET_ERROR, (state) => {
      state = {
        ...state,
        statesProvinces: [],
      }
    })
  },
})

listenerMiddleware.startListening({
  matcher: isAnyOf(accountApi.endpoints.registerPerson.matchFulfilled),
  effect: async (action, listenerApi) => {
    try {
      const registrationPayload = action.meta.arg.originalArgs as RegistrationBody
      listenerApi.dispatch(REGISTRATION_SUCCESS_ACTION(action.payload))
        const acceptedNewsletterFlag = get(
          registrationPayload,
          'x_optIn.x_newsLetter',
          false
        )
        sendRegistrationEvent(
          registrationPayload.email1,
          registrationPayload,
          acceptedNewsletterFlag
        )
        const userDetails = await listenerApi.dispatch(accountApi.endpoints.findPersonBySelf.initiate({})).unwrap()
        listenerApi.dispatch(FETCH_USER_DETAILS_SUCCESS_ACTION(userDetails))
      
    } catch(e:any) {
      Log.error('registration event error',e)
    }
  },
})

export default accountSlice.reducer
