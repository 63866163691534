//Standard libraries
import React, { SyntheticEvent, useEffect } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'

import Log from '../../../services/Log'

//Redux
import { RESET_ERROR_ACTION } from '../../../redux/actions/error'
//UI
import { SnackbarOrigin } from '@material-ui/core'
import { genericErrorSelector } from '../../../redux/selectors/error'
import { useCSRForUser } from '../../../foundation/hooks/useCSRForUser'
//Custom libraries
import { useTranslation } from 'react-i18next'
import MessageSnackbar from './MessageSnackbar'
import { COOKIE_ERROR_CODE, FORTER_ERROR_CODES } from '../../../constants/errors'



const useErrorMessageSnackbar = () => {
  const anchorOrigin: SnackbarOrigin = {
    horizontal: 'center',
    vertical: 'bottom',
  }

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const error: any = useSelector(genericErrorSelector)
  const { handleLockOrderError, isOrderLockError, isAuthenticationError } = useCSRForUser()

  let errorKeys: string[] = []
  let errorKey = error.errorKey || ''
  let errorCode = error.errorCode
  let errorParameters = error.errorParameters
  let errorParameterKey: string | undefined = undefined

  if (typeof errorParameters === 'string') {
    errorParameters = errorParameters.split(',')
  }
  if (!errorParameters) {
    errorParameters = []
  }

  if (errorCode && errorCode !== errorKey) {
    errorKey = errorKey + '_' + errorCode
  }

  if (errorParameters.length > 0) {
    //user first item in parameters as part of key
    errorParameterKey = errorKey + '_' + errorParameters[0]
  }

  if (errorParameterKey) {
    const _errorParameterKey = `error-message.${errorParameterKey}`
    if (i18n.exists(_errorParameterKey)) {
      errorKeys.push(_errorParameterKey)
    }
  }
  if (i18n.exists(`error-message.${errorKey}`)) {
    errorKeys.push(`error-message.${errorKey}`)
  }

  let errorMessage = error.errorMessage || error.message
  if (errorKeys.length > 0) {
    errorMessage = t(errorKeys, { ...errorParameters })
  }

  const handleClose = (event?: SyntheticEvent): void => {
    if (FORTER_ERROR_CODES.includes(error.errorCode) && !event) {
      return;
    }

    dispatch(RESET_ERROR_ACTION())
  }

  useEffect(() => {
    let logErrorUrl = get(errorParameters, 'url', '')
    let logErrorMessage = errorMessage ? errorMessage : ''
    if (!isEmpty(logErrorUrl) || !isEmpty(logErrorMessage)) {
      Log.error(logErrorUrl, logErrorMessage)
    }
  }, [error])

  return {
    anchorOrigin,
    handleClose,
    errorMessage,
    error,
    isOrderLockError,
    handleLockOrderError,
    isAuthenticationError,
  }
}

const ErrorMessageSnackbar = () => {
  const {
    anchorOrigin,
    handleClose,
    errorMessage,
    error,
    isOrderLockError,
    handleLockOrderError,
    isAuthenticationError,
  } = useErrorMessageSnackbar()
  if (isOrderLockError(error)) {
    handleLockOrderError(error)
  }

  if (error.errorCode === COOKIE_ERROR_CODE) return null

  return (
    <>
      {!isOrderLockError(error) && !isAuthenticationError(error) && (
        <MessageSnackbar
          handleClose={handleClose}
          anchorOrigin={anchorOrigin}
          severity="error"
          message={errorMessage}
          ClickAwayListenerProps={{
            mouseEvent: 'onMouseDown',
          }}
        />
      )}
    </>
  )
}

export default ErrorMessageSnackbar
