import { IGridPositioning, IPlacement, IViewType, isCMCollection } from '../../../../types/teaser'
import { useMediaQuery, useTheme } from '@material-ui/core'

import FullWidthBanner from '../../cms-content/full-width-banner'
import React from 'react'
import styled from 'styled-components'

interface IProps {
  'data-element-id'?: string
  placement: IPlacement
}

const getGridColumn = (isTablet: boolean, type: IViewType, gridPositioning: IGridPositioning) => {
  if (type === 'full-width-banner') {
    return '1 / -1'
  }

  if (isTablet) return '1 / span 2'

  switch (gridPositioning) {
    case 1:
      return '1 / span 2'
    case 2:
      return '2 / span 2'
    case 3:
      return '3 / span 2'
    case 4:
      return '3 / span 2'
  }
  // return plpBoxOrientation === 'left' ? '1 / span 2' : '2 / span 2'
}

const getGridRow = (verticalIndex: number) => `${verticalIndex} / span 2`

const StyledGridBannerItem = styled('div') <{
  viewtype: IViewType
  verticalIndex: number
  gridPositioning: IGridPositioning
  isTablet: boolean
}>`
  grid-column: ${({ viewtype, isTablet, gridPositioning }) =>
    getGridColumn(isTablet, viewtype, gridPositioning)};
  grid-row: ${({ verticalIndex }) => getGridRow(verticalIndex)};
`

const GridBannerItem: React.FC<IProps> = ({ 'data-element-id': dataElementId, placement }) => {
  // NO BANNER? NO PARTY!
  const theme = useTheme()
  const isTablet = !useMediaQuery(theme.breakpoints.up('md'))
  const { items } = placement

  if (!items) return null

  let item
  let viewtype

  if (placement.viewtype === 'default') {
    const collection = placement.items.filter(isCMCollection)
    if (collection.length > 0 && Array.isArray(collection[0].teasableItems)) {
      item = collection[0].teasableItems[0]
      viewtype = items[0].viewtype
    }
  } else if (items.length > 0) {
    item = items[0]
    viewtype = placement.viewtype
  }

  if (item?.type !== 'LXTeaser') {
    return <></>
  }

  const gridPositioning = items[0].gridPositioning ?? 1
  const split = placement.name.split('_')
  const verticalIndex = +split[split.length - 1]

  return (
    <StyledGridBannerItem
      data-element-id={dataElementId}
      viewtype={viewtype}
      verticalIndex={verticalIndex}
      gridPositioning={gridPositioning}
      isTablet={isTablet}>
      <FullWidthBanner teaser={item} variant={viewtype === 'plp-box' ? 'PLP' : ''} />
    </StyledGridBannerItem>
  )
}

export default GridBannerItem
