import {
    createApi,
    fetchBaseQuery,
    FetchBaseQueryError,
  } from '@reduxjs/toolkit/query/react'
  import config from '../../configs'
  import {
    ICommerceHclPage,
    IFooter,
    IHeader,
    IMegaMenu,
    IPage,
    IPageStaticLanding,
    ICmsPreviewArgs,
    IQueryParams,
    ISitemapItem,
  } from '../../types/cms'
  import { RootReducerState } from '../../redux/reducers'
  //import { seoApi } from '../seo/query'
  
  const storeName = config.name.toLowerCase()
  const getLang = (locale: string) => locale.split('_')[0]
  const getCountry = (country: string) =>
    country.toLowerCase() !== 'uk' ? country : 'gb'
  
  export const cmsApi = createApi({
    reducerPath: 'cmsApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.cmsApiUrl }),
    endpoints: (builder) => ({
      getHeader: builder.query<IHeader, IQueryParams | void>({
        queryFn: async (args, api, _extraOptions, baseQuery) => {
          const state = api.getState() as RootReducerState
          const { locale, country, storeID, defaultLanguageID } =
            state.site.currentSite!
  
          const params = {
            storeId: storeID,
            langId: defaultLanguageID,
            filterRulesLocaleOverride: args?.filterRulesLocaleOverride,
            previewDate: args?.previewDate,
          }
  
          const result = await baseQuery({
            url: `cms/live/page/header/${storeName}/${getLang(
              locale
            )}/${getCountry(country)}`,
            params,
          })
  
          return (result.data as IHeader)
            ? { data: result.data as IHeader }
            : { error: result.error as FetchBaseQueryError }
        },
      }),
      getMegaMenu: builder.query<IMegaMenu, IQueryParams | void>({
        queryFn: async (args, api, _extraOptions, baseQuery) => {
          const state = api.getState() as RootReducerState
          const { locale, country, storeID, defaultLanguageID } =
            state.site.currentSite!
  
          const params: IQueryParams = {
            storeId: storeID,
            langId: defaultLanguageID,
            filterRulesLocaleOverride: args?.filterRulesLocaleOverride,
            previewDate: args?.previewDate,
          }
  
          const result = await baseQuery({
            url: `cms/live/page/mega-menu/${storeName}/${getLang(
              locale
            )}/${getCountry(country)}`,
            params,
          })
  
          return (result.data as IMegaMenu)
            ? { data: result.data as IMegaMenu }
            : { error: result.error as FetchBaseQueryError }
        },
      }),
      getPage: builder.query<IPage, IQueryParams | void>({
        queryFn: async (args, api, _extraOptions, baseQuery) => {
          const state = api.getState() as RootReducerState
          const { locale, country, storeID, defaultLanguageID } =
            state.site.currentSite!
  
          const params: IQueryParams = {
            storeId: storeID,
            langId: defaultLanguageID,
            filterRulesLocaleOverride: args?.filterRulesLocaleOverride,
            previewDate: args?.previewDate,
          }
  
          const result = await baseQuery({
            url: `cms/live/home/content/${storeName}/${getLang(
              locale
            )}/${getCountry(country)}`,
            params,
          })
  
          return (result.data as IPage)
            ? { data: result.data as IPage }
            : { error: result.error as FetchBaseQueryError }
        },
      }),
      getFooter: builder.query<IFooter, IQueryParams | void>({
        queryFn: async (args, api, _extraOptions, baseQuery) => {
          const state = api.getState() as RootReducerState
          const { locale, country, storeID, defaultLanguageID } =
            state.site.currentSite!
  
          const params: IQueryParams = {
            storeId: storeID,
            langId: defaultLanguageID,
            filterRulesLocaleOverride: args?.filterRulesLocaleOverride,
            previewDate: args?.previewDate,
          }
  
          const result = await baseQuery({
            url: `cms/live/page/footer/${storeName}/${getLang(
              locale
            )}/${getCountry(country)}`,
            params,
          })
  
          return (result.data as IFooter)
            ? { data: result.data as IFooter }
            : { error: result.error as FetchBaseQueryError }
        },
      }),
      getHclPage: builder.query<ICommerceHclPage, string>({
        queryFn: async (args, api, _extraOptions, baseQuery) => {
          const state = api.getState() as RootReducerState
          const { locale, country, storeID, defaultLanguageID } =
            state.site.currentSite!
  
          const params: IQueryParams = {
            storeId: storeID,
            langId: defaultLanguageID,
            externalId: args,
          }
  
          const result = await baseQuery({
            url: `cms/live/hcl/content/${storeName}/${getLang(
              locale
            )}/${getCountry(country)}`,
            params,
          })
  
          return (result.data as ICommerceHclPage)
            ? { data: result.data as ICommerceHclPage }
            : { error: result.error as FetchBaseQueryError }
        },
      }),
      getPlpCommerce: builder.query<ICommerceHclPage, IQueryParams>({
        queryFn: async (args, api, _extraOptions, baseQuery) => {
          const state = api.getState() as RootReducerState
          const { locale, country, storeID, defaultLanguageID } =
            state.site.currentSite!
  
          const params: IQueryParams = {
            storeId: storeID,
            langId: defaultLanguageID,
            ...args,
          }
  
          const result = await baseQuery({
            url: `cms/live/plp/content/${storeName}/${getLang(
              locale
            )}/${getCountry(country)}`,
            params,
          })
  
          return (result.data as ICommerceHclPage)
            ? { data: result.data as ICommerceHclPage }
            : { error: result.error as FetchBaseQueryError }
        },
      }),
      getPdpCommerce: builder.query<ICommerceHclPage, IQueryParams>({
        queryFn: async (args, api, _extraOptions, baseQuery) => {
          const state = api.getState() as RootReducerState
          const { locale, country, storeID, defaultLanguageID } =
            state.site.currentSite!
  
          const params: IQueryParams = {
            storeId: storeID,
            langId: defaultLanguageID,
            ...args,
          }
  
          const result = await baseQuery({
            url: `cms/live/pdp/content/${storeName}/${getLang(
              locale
            )}/${getCountry(country)}`,
            params,
          })
  
          return (result.data as ICommerceHclPage)
            ? { data: result.data as ICommerceHclPage }
            : { error: result.error as FetchBaseQueryError }
        },
      }),
      getPageStaticLanding: builder.query<IPageStaticLanding, IQueryParams>({
        queryFn: async (args, api, _extraOptions, baseQuery) => {
          const state = api.getState() as RootReducerState
          const { locale, country, storeID, defaultLanguageID } =
            state.site.currentSite!
  
          const params: IQueryParams = {
            storeId: storeID,
            langId: defaultLanguageID,
            ...args,
          }
  
          const result = await baseQuery({
            url: `cms/live/page/content/${storeName}/${getLang(
              locale
            )}/${getCountry(country)}`,
            params,
          })
  
          return (result.data as IPageStaticLanding)
            ? { data: result.data as IPageStaticLanding }
            : { error: result.error as FetchBaseQueryError }
        },
      }),
      getPreviewContent: builder.query<any, ICmsPreviewArgs>({
        query: (args) => ({
          url: `cms/preview/component/${args.id}`,
          params: args.params,
        }),
        transformResponse: (response: any) => response.content.content,
      }),
      getPreviewPage: builder.query<any, ICmsPreviewArgs>({
        query: (args) => ({
          url: `cms/preview/page/${args.id}`,
          params: args.params,
        }),
        transformResponse: (response: any, _meta, args) => {
          const { filterRulesLocaleOverride, previewDate } = args.params
          const content = response.content.content
          return {
            ...content,
            isEnabled: !!response.content.content,
            filterRulesLocaleOverride,
            previewDate,
          }
        },
      }),
      getSitemap: builder.query<ISitemapItem[], void>({
        query: () => ({
          url: `sitemap/${storeName}/en/gb`,
          params: { json: true, baseUrl: 'www.arnette.com' },
        }),
      }),
    }),
  })
  
  export const {
    useGetHeaderQuery,
    useGetMegaMenuQuery,
    useGetPageQuery,
    useGetPageStaticLandingQuery,
    useGetFooterQuery,
    useGetHclPageQuery,
    useGetPlpCommerceQuery,
    useGetPdpCommerceQuery,
    useGetPreviewContentQuery,
    useGetPreviewPageQuery,
    useGetSitemapQuery,
  } = cmsApi
  