import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary } from '../StyledUI'
import { shallowEqual, useSelector } from 'react-redux'
import { ArrowDownSlimIcon } from '../StyledUI/Icons'
import React, { useMemo } from 'react'
import { countrySelector } from '../../redux/selectors/site'
import { countryUtil } from '../../utils/countryUtil'
import { sendCountrySelectionEvent } from '../../foundation/analytics/tealium/lib'
import styled from 'styled-components'
import { themeModeSelector } from '../../redux/selectors/theme'
import { useTranslation } from 'react-i18next'
import { getCountryFlagImage, useStoreCountries } from '../../hooks/useStoreCountries'

const CountryImageDiv = styled.div<{ country: string }>`
  background: url(${({ country }) => getCountryFlagImage(country)});
  width: 17.05px;
  height: 10px;
  background-size: cover;
`

const CountryLabel = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.text.primary};

  &.arn-country__label--selected {
    text-decoration: underline;
  }
`

const CountryWrapper = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const CountryAccordionDetails = styled(StyledAccordionDetails)`
  display: flex;
  flex-direction: column;
`

const CountrySelect = () => {
  const { t } = useTranslation()
  const countries = useStoreCountries()
  const currentCountry = useSelector(countrySelector)
  const themeMode = useSelector(themeModeSelector, shallowEqual)

  const currentCountryFlagIcon = useMemo(
    () =>
      typeof currentCountry === 'string' ? getCountryFlagImage(currentCountry?.toLowerCase()) : '',
    [currentCountry]
  )

  const _currentLangCode = (langCode) => {
    return countryUtil('', langCode).currentLangCode
  }

  const onCountryClick = (evt, langCode) => {
    evt.preventDefault()
    sendCountrySelectionEvent(langCode)
    const currentLangCode = _currentLangCode(langCode)
    window.location.href = `/${currentLangCode}`
  }

  return (
    <StyledAccordion defaultExpanded={false}>
      <StyledAccordionSummary
        expandIcon={<ArrowDownSlimIcon />}
        className="arn-footer__country-select">
        <CountryWrapper>
          {currentCountry?.toLowerCase() && currentCountryFlagIcon && (
            <CountryImageDiv country={currentCountry.toLowerCase()} />
          )}
          <CountryLabel className="arn-country__label" themeMode={themeMode}>
            {t(`Countries.${currentCountry?.toLowerCase()}`)}
          </CountryLabel>
        </CountryWrapper>
      </StyledAccordionSummary>

      <CountryAccordionDetails>
        {countries.map(({ code, href, isSelected, label, langCode }, i) => (
          <CountryWrapper
            data-element-id="X_X_Footer_CountrySelector"
            data-description={code}
            key={i}
            href={href}
            onClick={(e) => onCountryClick(e, langCode)}>
            {code && getCountryFlagImage(code) && <CountryImageDiv country={code} />}
            <CountryLabel
              className={`arn-country__label ${isSelected ? 'arn-country__label--selected' : ''}`}
              themeMode={themeMode}>
              {label}
            </CountryLabel>
          </CountryWrapper>
        ))}
      </CountryAccordionDetails>
    </StyledAccordion>
  )
}

export default CountrySelect
