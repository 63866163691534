import './i18n'
import './index.scss'
import './dynatrace'

import React, { Suspense } from 'react'
import App from './App'
import PrescriptionLensesProvider from './components/widgets/prescription-lenses/PrescriptionLensesContext'
import CssBaseline from '@material-ui/core/CssBaseline'
import EcommThemeProvider from './themes/EcommThemeProvider'
import { Provider } from 'react-redux'
import { StylesProvider } from '@material-ui/styles'
import { render } from 'react-dom'
import store from './redux/store/index'

const rootElement = document.getElementById('root')

render(
  <Provider store={store}>
    <Suspense fallback={null}>
      <StylesProvider injectFirst>
        <EcommThemeProvider>
          <CssBaseline />
          <PrescriptionLensesProvider>
            <App />
          </PrescriptionLensesProvider>
        </EcommThemeProvider>
      </StylesProvider>
    </Suspense>
  </Provider>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister()
