import * as PaymentMethod from '../../../constants/paymentMethods'

import React, { FC } from 'react'

import PaymentMethodsService from '../../../services/PaymentMethodsService'
import styled from 'styled-components'
import theme from '../../../themes'

interface PaymentMethodsProps {
  paymentMethods: PaymentMethod.FooterName[]
}

export const PaymentMethodImageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(1)}px ${theme.spacing(0.625)}px;

  ${theme.breakpoints.up('sm')} {
    margin-top: ${theme.spacing(1.75)}px;
  }
`
export const PaymentMethodsIcons: FC<PaymentMethodsProps> = ({ paymentMethods }) => (
  <>
    {paymentMethods?.map((paymentMethodName, i) => {
      const PaymentMethodIcon = PaymentMethodsService.getIconByFooterName(paymentMethodName)

      return PaymentMethodIcon ? <PaymentMethodIcon key={i} /> : null
    })}
  </>
)
