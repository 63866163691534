import { FormHelperText } from '@material-ui/core'
import styled from 'styled-components'

export const PasswordFieldHelperHint = styled(FormHelperText)<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  &.password-reqs__helper-hint {
    margin: ${theme.spacing(0.25)}px 0px;
    color: ${theme.palette.custom[themeMode === 'dark' ? 'greyDarkmode' : 'greyLightmode']};
    
    &--warning {
      color: ${theme.palette.custom[themeMode === 'dark' ? 'warningRedDarkmode' : 'warningRed']};
    }
    
    &--active {
      color: ${theme.palette.custom[themeMode === 'dark' ? 'warmGrey' : 'black']};
    }
  }
`}
`

export const PasswordFieldRequirementsList = styled.ul<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  display: flex;
  flex-direction: column;
  color: ${themeMode === 'dark' ? theme.palette.custom.warmGrey : theme.palette.custom.black};
  font-size: 0.75rem;
  list-style: none;
  padding: 0;
  margin: 0;
  
  .password-reqs {
    &__condition {
      margin-left: ${theme.spacing(0.5)}px;
      
      &--initial {
        color: ${
          themeMode === 'dark'
            ? theme.palette.custom.greyDarkmode
            : theme.palette.custom.greyLightmode
        };
      }
    }
    
    &__icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      
      &--alert {
        color: ${
          themeMode === 'dark'
            ? theme.palette.custom.warningRedDarkmode
            : theme.palette.custom.warningRed
        };
      }
      
      &--initial {
        color: transparent;
      }
    }
  }
`}
`
