import * as ROUTES from '../../constants/routes'

import Axios, { Canceler } from 'axios'
import {
  INIT_STATE_FROM_STORAGE_ACTION,
  LISTEN_USER_FROM_STORAGE_ACTION,
} from '../../redux/actions/user'
import React, { Dispatch, useEffect } from 'react'
import { Redirect, Route, BrowserRouter as Router, Switch, useParams } from 'react-router-dom'
import { initSite, useSite } from '../../foundation/hooks/useSite'

import BaseAppLayout from './base'
import { CommerceEnvironment } from '../../constants/common'
import Log from '../../services/Log'
import Logo from '../widgets/logo/Logo'
import { StyledHeader } from '../StyledUI'
import config from '../../configs'
import { countryUtil } from '../../utils/countryUtil'
import getDisplayName from 'react-display-name'
import maintenanceService from '../../foundation/apis/maintenance/maintenance.service'
import { site } from '../../foundation/constants/site'
import { storageSessionHandler } from '../../foundation/utils/storageUtil'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { CountryBaseLink } from '../StyledUI/StyledLink/StyledLink'
import { CmsProvider } from '../providers/CmsProvider'

const DummyHeader = () => {
  const { isDesktop } = useBreakpoints()
  return (
    <StyledHeader>
      <div className="arn-header__top-container">
        <div className="arn-header__item arn-header__item--logo">
          <CountryBaseLink
            aria-label="Homepage"
            data-element-id="X_X_MainNav_LOGO"
            to={ROUTES.HOME}>
            <Logo width={!isDesktop ? 100 : 160} />
          </CountryBaseLink>
        </div>
      </div>
    </StyledHeader>
  )
}

export const StoreAppContainer: React.FC = () => {
  const widgetName = getDisplayName(StoreAppContainer)
  const { mySite } = useSite()
  const { country } = useParams<{ country: string }>()
  const { currentCountry } = countryUtil(country)
  const { i18n } = useTranslation()
  const dispatch = useDispatch<Dispatch<any>>()
  const CancelToken = Axios.CancelToken
  const history = useHistory()

  useEffect(() => {
    const interval = setInterval(async () => {
      const maintenanceStatus = await maintenanceService.checkMaintenanceStatus()
      if (maintenanceStatus === config.maintenanceStatus) {
        history.replace(ROUTES.MAINTENANCE)
      }
    }, 20e3)

    return () => {
      clearInterval(interval)
    }
  }, [])

  Log.info('StoreAppContainer')

  const countryValues = CommerceEnvironment.localeLangMap

  const controlUrlCountry = countryValues.find((t) => {
    const { currentLangCode } = countryUtil('', t.langCode)
    return country === currentLangCode
  })

  useEffect(() => {
    let cancels: Canceler[] = []

    const payloadBase = {
      widget: widgetName,
      cancelToken: new CancelToken((c) => {
        cancels.push(c)
      }),
    }

    if (controlUrlCountry) {
      if (mySite) {
        Log.info('INIT STATE FROM STORAGE SITE')
        dispatch(INIT_STATE_FROM_STORAGE_ACTION(payloadBase))

        storageSessionHandler.triggerUserStorageListener(() =>
          dispatch(LISTEN_USER_FROM_STORAGE_ACTION(payloadBase))
        )

        // SET TRANSLATE
        const locale =
          //localStorageUtil.get(LOCALE)?.split('_').join('-') ||
          CommerceEnvironment.languageMap[mySite.defaultLanguageID].split('_').join('-')
        Log.info('MYSITE LOCALE: ', mySite.locale)
        Log.info('LANGUAGEMAP LOCALE: ', locale)
        if (locale !== i18n.languages[0]) {
          i18n.changeLanguage(locale)
        }
        Log.info('SET LOCALE in storeAppContainer ', locale)
      } else {
        Log.info('SET LOCALE in storeAppContainer ', currentCountry)
        initSite(site, currentCountry, dispatch)
      }
    }
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [mySite, dispatch, i18n, widgetName, CancelToken, country])

  Log.info('App routing setup in StoreAppContainer, selected country: ', country)

  if (!controlUrlCountry) return <Redirect to="/" />
  // if needed switch here between different layouts
  return mySite ? <CmsProvider><BaseAppLayout /></CmsProvider> : <DummyHeader />
}
