import React from 'react'
import styled, { css } from 'styled-components'
import { AnchorStyle } from '../StyledAnchor/StyledAnchor.style'
import { AnchorCustomProps } from '../StyledAnchor/anchor.type'
import ButtonBase, { ButtonBaseProps } from '@material-ui/core/ButtonBase'
import { withStyles } from '@material-ui/core/styles'
import useThemeMode from '../../../hooks/useThemeMode'

export interface ButtonAsLinkProps extends ButtonBaseProps {
  customprops?: AnchorCustomProps
  children?: React.ReactNode
  themeMode?: string
}

export const ResetButtonBase = withStyles(() => ({
  root: {
    borderWidth: 0,
    fontFamily: 'inherit',
    backgroundColor: 'transparent',
  },
}))(ButtonBase)

export const ButtonAsLinkStyle = css`
  ${AnchorStyle}
`
export const ButtonAsLink = styled(ResetButtonBase)`
  ${ButtonAsLinkStyle}
  &.filters-menu-clear{
    color: ${({ theme, themeMode }) => themeMode === 'dark' ? theme.palette.secondary.greyDarkmode : theme.palette.secondary.main} !important;
  }
  &.arn-home__shop-all-cta {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`
export const ButtonAsLinkShop = styled(ResetButtonBase)`
  ${ButtonAsLinkStyle}

  color: ${({ theme }) => theme.palette.secondary.contrastText};
  &.arn-home__shop-all-cta {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const StyledButtonAsLink = React.forwardRef((props: ButtonAsLinkProps, ref: any) => {
  const { themeMode } = useThemeMode()
  return (
    <ButtonAsLink {...props} ref={ref} thememode={themeMode}>
      {props.children}
    </ButtonAsLink>
  )
})
