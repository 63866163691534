import React from 'react'
import styled from 'styled-components'
import { StyledTypography } from '../../StyledUI'

interface IProps {
  title: string
}

const StyledContainer = styled('div')``

const StyledTitle = styled(StyledTypography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  font-size: 24px;
`

const StyledContent = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const SortingOptions: React.FC<IProps> = ({ title, children }) => {
  return (
    <StyledContainer>
      <StyledTitle variant='h2' className='filters-menu-title'>
        {title}
      </StyledTitle>
      <StyledContent>{children}</StyledContent>
    </StyledContainer>
  )
}

export default SortingOptions
