import { ArrowDownSlimIcon, CloseIcon, ColorWatchIcon } from '../../StyledUI/Icons'
import {
  RoundButton,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledButtonAsLink,
  StyledIconButton,
  StyledTypography,
} from '../../StyledUI'
import React, { FC, useEffect, useMemo } from 'react'
import { SingleSortOrderOption } from '../../../redux/reducers'
import {
  currentPLPDashFacetsSelector,
  currentPLPFacetsSelector,
} from '../../../redux/selectors/catalog'
import findKey from 'lodash/findKey'
import isEqual from 'lodash/isEqual'
import { plpDashfacetSelector, sortOrderOptionsSelector } from '../../../redux/selectors/site'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import { FACET_COLORS } from '../../../constants/colorFacets'
import { FILTERS_DRAWER_TOGGLE_ACTION } from '../../../redux/actions/ui'
import SortingOptions from '../../widgets/sorting-options'
import { StyledFiltersDrawer } from '../../StyledUI/'
import { StyledProductGridContainer } from '../../widgets/product-grid/styles/ProductGrid.style'
import { filtersDrawerSelector } from '../../../redux/selectors/ui'
import { getProductColorFacet } from '../../../utils/productAttributes'
import ProductService from '../../../services/ProductService'
import styled from 'styled-components'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { useTranslation } from 'react-i18next'
import { ProductGridLayout } from '../../widgets/product-grid'
import useProducts from '../../../hooks/useProducts'
import { useHistory } from 'react-router'
import { PLP_DEFAULT_SORT_OPTION } from '../../../redux/reducers/initStates'
import { ISeo } from '../../../types/seo'
import CheckboxAsButton from '../../StyledUI/CheckboxAsButton/CheckboxAsButton'

interface PlpFilterLayoutProps {
  cid: string
  searchTerm?: string
  seoData?: Omit<ISeo, 'component'>
}

const StyledFilterCheckbox = styled(CheckboxAsButton)`
  margin: 5px 10px 5px 0;
`
const StyledCloseIconButtonContainer = styled.div`
  text-align: right;
`
const StyledFilterFacetsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`
const StyledDashFiltersContainer = styled(StyledFilterFacetsContainer)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`
const StyledFiltersWrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.warmGrey};
`
const StyledPLPFilteredContent = styled.div`
  padding-bottom: 80px;

  ${StyledProductGridContainer} {
    padding: 0 1px 1px;
  }
`

const FACET_COLOR_PREFIXES = ['LENS_COLOR_FACET', 'FRAME_COLOR_FACET', 'FRONT_COLOR_FACET']

const PlpFilterLayout: FC<PlpFilterLayoutProps> = ({ cid = '', searchTerm = '', seoData }) => {
  const categoryId = seoData?.tokenValue || ''

  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    areFacetsLoading,
    areProductsLoading,
    breadcrumbs,
    currentPage,
    products,
    productsTotal,
    selectedFacets,
    selectedSortOption,
    deselectAllFacets,
    fetchProducts,
    getUpdatedSelectedFacets,
    handleFacetSelect,
    cleanup,
  } = useProducts(categoryId, searchTerm)

  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const filtersDrawerOpen = useSelector(filtersDrawerSelector)
  const dashFacets = useSelector(plpDashfacetSelector) || {}
  const sortOrderOptions = useSelector(sortOrderOptionsSelector)!
  const currentPLPDashFacets = useSelector(currentPLPDashFacetsSelector('filter'))
  const currentPLPFacets = useSelector(currentPLPFacetsSelector)

  const onResultPress = () => {
    dispatch(FILTERS_DRAWER_TOGGLE_ACTION(false))

    fetchProducts()
  }

  const onSortingSubmit = (sortOption: SingleSortOrderOption) => {
    if (isEqual(sortOption, selectedSortOption)) {
      return
    }

    fetchProducts(undefined, sortOption)
  }

  const selectedFiltersCount = useMemo<number>(
    () => Object.keys(selectedFacets).length,
    [selectedFacets]
  )

  /** Effect with history location listener */
  useEffect(() => {
    let initialLocationPathname: string | null = history.location.pathname

    /** Subscribes to location changes */
    const unlisten = history.listen((location) => {
      /** If pathname hasn't changed (equal to initial) and search is empty, then reset sort & filters and fetch products.
       * This behaviour is meant to react on header link click.
       */
      if (initialLocationPathname === location.pathname && !location.search) {
        fetchProducts({}, PLP_DEFAULT_SORT_OPTION)
      }
    })

    return () => {
      initialLocationPathname = null
      cleanup()
      unlisten()
    }
  }, [categoryId])

  return (
    <>
      <StyledFiltersDrawer
        themeMode={themeMode}
        anchor="right"
        disableSwipeToOpen={true}
        open={!!filtersDrawerOpen}
        className="filters-menu"
        data-testid="filters-menu-drawer-element"
        onClose={() => dispatch(FILTERS_DRAWER_TOGGLE_ACTION(false))}
        onOpen={() => dispatch(FILTERS_DRAWER_TOGGLE_ACTION(true))}
        PaperProps={{ component: StyledFiltersWrapper }}>
        <div className="filters-menu-content" data-analytics_available_call="0">
          <StyledCloseIconButtonContainer>
            <StyledIconButton
              data-analytics_available_call="0"
              onClick={() => dispatch(FILTERS_DRAWER_TOGGLE_ACTION(false))}>
              <CloseIcon />
            </StyledIconButton>
          </StyledCloseIconButtonContainer>
          <Box marginBottom={2}>
            <SortingOptions title={t('ProductFilter.Labels.sortBy')}>
              {Object.values(sortOrderOptions).map((option) => (
                <StyledFilterCheckbox
                  id={option.value || '0'}
                  key={`${option.profileName}__${option.value}`}
                  checked={isEqual(option?.value, selectedSortOption?.value)}
                  labelText={t(`ProductFilter.Labels.${findKey(sortOrderOptions, option)}`)}
                  onChange={() => onSortingSubmit(option)}
                  disabled={areProductsLoading || areFacetsLoading}
                />
              ))}
            </SortingOptions>
          </Box>
          <StyledTypography variant="h2" className="filters-menu-title">
            {t('ProductFilter.Labels.filterBy')}
            {selectedFiltersCount ? ` (${selectedFiltersCount})` : ''}
            <StyledButtonAsLink
              type="button"
              className="filters-menu-clear"
              data-element-id="X_X_Clear_All"
              customprops={{ isUnderlined: true }}
              onClick={deselectAllFacets}>
              {t('ProductGrid.Actions.clearAll')}
            </StyledButtonAsLink>
          </StyledTypography>

          <StyledDashFiltersContainer className="filters-dash-container">
            {currentPLPDashFacets?.map((facet) => {
              if (facet.entry.length < 2) {
                return null
              }

              return facet.entry.map(
                (entry, _: number) =>
                  entry.label === dashFacets[facet.name] && (
                    <StyledFilterCheckbox
                      key={facet.value}
                      labelText={t(`ProductFilter.Labels.${facet.name}`)}
                      checked={ProductService.isFacetSelected(facet, entry, selectedFacets)}
                      id={facet.value}
                      onChange={() => handleFacetSelect(facet, entry)}
                      disabled={areProductsLoading || areFacetsLoading || entry.count === '0'}
                    />
                  )
              )
            })}
          </StyledDashFiltersContainer>

          {currentPLPFacets.map((facet) => {
            const isColorFacet = FACET_COLOR_PREFIXES.includes(facet.name)
            const selectedFacetsCount = facet.entry.filter((entry) =>
              ProductService.isFacetSelected(facet, entry, selectedFacets)
            ).length

            return (
              <StyledAccordion defaultExpanded={false} key={facet.name} hasMarginBottom>
                <StyledAccordionSummary expandIcon={<ArrowDownSlimIcon />}>
                  {t(`ProductFilter.Labels.${ProductService.getFacetTitle(facet)}`)}
                  {selectedFacetsCount ? ` (${selectedFacetsCount})` : ''}
                </StyledAccordionSummary>
                <StyledAccordionDetails className="arn-filters__accordion-details">
                  <StyledFilterFacetsContainer className="filters-options-wrapper">
                    {facet.entry?.map((entry) => {
                      const colorFacetImage = isColorFacet
                        ? getProductColorFacet(FACET_COLORS, entry.attributeValueIdentifier!)
                        : null
                      const { value, type } = colorFacetImage || {}
                      const watchIcon = () =>
                        type === 'plain' ? <ColorWatchIcon htmlColor={`#${value}`} /> : value

                      const commonProps = {
                        key: `${facet.name}-${entry.extendedData?.uniqueId}`,
                        id: `${facet.name}-${entry.extendedData?.uniqueId}`,
                        checked: ProductService.isFacetSelected(facet, entry, selectedFacets),
                        labelText: `${entry.label}`,
                        disabled: areProductsLoading || areFacetsLoading || entry.count === '0',
                        onChange: () => handleFacetSelect(facet, entry),
                      }

                      return isColorFacet ? (
                        <StyledFilterCheckbox renderLabelIcon={watchIcon} {...commonProps} />
                      ) : (
                        <StyledFilterCheckbox {...commonProps} />
                      )
                    })}
                  </StyledFilterFacetsContainer>
                </StyledAccordionDetails>
              </StyledAccordion>
            )
          })}
          <RoundButton
            className="arn-filters-apply-btn"
            fullWidth
            onClick={onResultPress}
            data-element-id="X_X_SortBy_ShowResults"
            variant="primary"
            loading={areProductsLoading || areFacetsLoading}
            disabled={areProductsLoading || areFacetsLoading}>
            {productsTotal}&nbsp;{t('ProductFilter.Labels.results')}
          </RoundButton>
        </div>
      </StyledFiltersDrawer>

      <StyledPLPFilteredContent className="plp-filtered-content" id={cid}>
        <ProductGridLayout
          areFacetsLoading={areFacetsLoading}
          areProductsLoading={areProductsLoading}
          breadcrumbs={breadcrumbs}
          cid={`${cid}-${categoryId || searchTerm}`}
          currentPage={currentPage}
          products={products}
          productsTotal={productsTotal}
          searchTerm={searchTerm}
          selectedFacets={selectedFacets}
          selectedSortOption={selectedSortOption}
          seoData={seoData}
          fetchProducts={fetchProducts}
          getUpdatedSelectedFacets={getUpdatedSelectedFacets}
        />
      </StyledPLPFilteredContent>
    </>
  )
}

export default PlpFilterLayout
