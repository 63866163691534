import { RoundButton, StyledTypography } from '../StyledUI'

import styled from 'styled-components'
import theme from '../../themes'

const NEWSLETTER_CONTAINER_HEIGHT = {
  sm: '160px',
  md: '176px',
  lg: '132px',
}

const SIGNUP_BTN_WIDTH = {
  sm: '100%',
  md: '342px',
  lg: '221px',
}

export const Container = styled.div`
  min-height: ${NEWSLETTER_CONTAINER_HEIGHT.sm};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing(1)}px;
  padding: ${theme.spacing(1)}px;
  background-color: ${theme.palette.primary.main};

  ${theme.breakpoints.up('md')} {
    min-height: ${NEWSLETTER_CONTAINER_HEIGHT.md};
    padding-top: ${theme.spacing(1.5)}px;
    padding-bottom: ${theme.spacing(1.5)}px;
    gap: ${theme.spacing(1.5)}px;
  }

  ${theme.breakpoints.up('lg')} {
    min-height: ${NEWSLETTER_CONTAINER_HEIGHT.lg};
    flex-direction: row;
    justify-content: flex-start;
    gap: ${theme.spacing(1)}px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  ${theme.breakpoints.up('md')} {
    width: 70%;
  }

  ${theme.breakpoints.up('lg')} {
    width: auto;
    flex: initial;
  }
`

export const Text = styled(StyledTypography)`
  font-weight: 400;
  font-size: 1rem;
  color: ${theme.palette.primary.dark};

  ${theme.breakpoints.up('md')} {
    font-size: 2rem;
  }
`

export const SignUpWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    justify-content: flex-start;
  }
`

export const SignUpButton = styled(RoundButton)`
  width: ${SIGNUP_BTN_WIDTH.sm};
  /* background-color: ${theme.palette.primary.dark};
  color: ${theme.palette.primary.contrastText}; */

  ${theme.breakpoints.up('md')} {
    width: ${SIGNUP_BTN_WIDTH.md};
  }

  ${theme.breakpoints.up('lg')} {
    width: ${SIGNUP_BTN_WIDTH.lg};
  }
`
