import { merge, sharedOverrides } from '../shared-theme'

// import { blue } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'
import fontsArray from './fonts'
import { palette } from '../color-palette'

const appName = process.env.REACT_APP_STORENAME
const customPalette = palette[appName?.toLowerCase()] ? palette[appName?.toLowerCase()] : palette.emerald

const { geckoGreen, black, white, lobsterPink, warmGrey, warningRed, greyDarkmode, greyLightmode } =
  customPalette

export const overrides = {
  name: appName,
  typography: {
    fontFamily: ['NeueHaas', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    htmlFontSize: 16,
    h1: {
      fontWeight: 'normal',
      fontSize: '2rem',
    },
    h2: {
      fontWeight: 'normal',
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1rem',
    },
  },
  palette: {
    type: 'light',
    background: {
      default: warmGrey,
      dark: black,
    },
    primary: {
      light: white,
      main: geckoGreen,
      dark: black,
      contrastText: warmGrey,
    },
    secondary: {
      light: lobsterPink,
      main: black,
      dark: warmGrey,
      contrastText: black,
      greyDarkmode: greyDarkmode,
      greyLightmode: greyLightmode,
    },
    text: {
      primary: black,
      secondary: black,
      dark: white,
      light: black,
      error: warningRed,
      contrastText: warmGrey,
      greyDarkmode: greyDarkmode,
      greyLightmode: greyLightmode,
    },
    action: {
      hover: geckoGreen,
    },
    custom: customPalette,
  },
  spacing: 16,
  button: {
    backgroundColor: geckoGreen,
    '&:hover': {
      backgroundColor: geckoGreen,
    },
  },
  child: {
    backgroundColor: geckoGreen,
  },
  rippleVisible: {
    opacity: 0.5,
  },
  breakpoints: {
    values: {
      xs: 375,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fontsArray,
        html: {
          fontSize: '16px',
        },
        body: {
          fontSize: '16px',
          fontWeight: 'normal',
          lineHeight: 'inherit',
        },
      },
    },
  },
}

const combinedOverrides = merge(sharedOverrides, overrides)
const theme = createTheme(combinedOverrides)

export default theme
