import styled from 'styled-components'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'
import { CustomTheme } from '../../../types/theme'

export const OrderDetailsWrapper = styled.div`
  ${({ theme }) => `
  padding: ${theme.spacing(1.5)}px ${theme.spacing(1)}px ${theme.spacing(1.5)}px;
  border: 1px solid ${theme.palette.text.primary};

  ${getDesktopThemeBreakpoint(theme)} {
    padding: ${theme.spacing(1.5)}px ${theme.spacing(2)}px;
  }
`}
`

export const OrderDetailsHeader = styled.header`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  flew-wrap: wrap;

  ${getDesktopThemeBreakpoint(theme)} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`}
`

export const OrderDetailsItemList = styled.div`
  ${({ theme }) => `
  margin-top: ${theme.spacing(1)}px;

  ${getDesktopThemeBreakpoint(theme)} {
    margin-top: 30px;
  }
`}
`

const orderDetailsItemClasses = (theme: CustomTheme) => `
.order-details__item {
  &__main {
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 18px;
  }

  &__image {
    padding: 10px 0;
    width: 160px;
    height: 113px;

    span {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__status {
    margin-top: 10px;
    font-size: inherit;
  }

  &__button-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__button {
    min-width: 150px;
    width: 100%;
    order: 1; // to be placed last as sibling doesn't have order prop
  }

  &__link {
    margin-bottom: 20px;
    line-height: 18px;
  }

  h4 {
    margin-bottom: 5px;
  }

  .product-price {
    display: inline-block;
  }

  ${getDesktopThemeBreakpoint(theme)} {
    &__main {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
    }

    &__image {
      padding: 0;
      width: 100px;
      height: auto;
    }

    &__button-container {
      margin-top: 0;
      align-items: flex-end;
    }

    &__button {
      min-width: 150px;
      order: unset; // to be placed first
    }

    &__link {
      margin-bottom: 0;
    }
  }
}`

export const OrderDetailsItem = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:last-child) {
    padding: 0 0 ${theme.spacing(1.5)}px;
    border-bottom: 1px solid ${theme.palette.primary.dark};
  }

  ${orderDetailsItemClasses(theme)}

  ${getDesktopThemeBreakpoint(theme)} {
    padding: ${theme.spacing(1)}px 0 0;
    flex-direction: row;

    &:not(:last-child) {
      padding: ${theme.spacing(1)}px 0 ${theme.spacing(1.5)}px;
    }
  }
`}
`
export const OrderDetailsPrice = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
  line-height: normal;
`

export const OrderDetailsFooter = styled.div`
  ${({ theme }) => `
  padding: ${theme.spacing(1.5)}px ${theme.spacing(1)}px ${theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.25)}px;
  border: 1px solid ${theme.palette.text.primary};
  border-top: none;

  .order-details__footer-tel {
    color: ${theme.palette.text.primary};
    text-decoration: underline;
  }

  .order-details__footer-email {
    width: 100%;
  }

  ${getDesktopThemeBreakpoint(theme)} {
    padding: ${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(2)}px;

    .order-details__footer-email {
      width: 250px;
    }
  }
`}
`
