/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Get current user's list of orders
export const ORDERS_GET_REQUESTED = 'ORDERS_GET_REQUESTED'
export const ORDERS_GET_SUCCESS = 'ORDERS_GET_SUCCESS'
export const ORDERS_GET_ERROR = 'ORDERS_GET_ERROR'
export const ADDRESS_DETAILS_GET_SUCCESS = 'ADDRESS_DETAILS_GET_SUCCESS'
export const ADDRESS_DETAILS_GET_ERROR = 'ADDRESS_DETAILS_GET_ERROR'
export const COUNTRY_DETAILS_GET_REQUESTED = 'COUNTRY_DETAILS_GET_REQUESTED'
export const COUNTRY_DETAILS_GET_SUCCESS = 'COUNTRY_DETAILS_GET_SUCCESS'
export const COUNTRY_DETAILS_GET_ERROR = 'COUNTRY_DETAILS_GET_ERROR'
export const PROVINCES_DETAILS_GET_REQUESTED = 'PROVINCES_DETAILS_GET_REQUESTED'
export const PROVINCES_DETAILS_GET_SUCCESS = 'PROVINCES_DETAILS_GET_SUCCESS'
export const PROVINCES_DETAILS_GET_ERROR = 'PROVINCES_DETAILS_GET_ERROR'
