import { TFunction } from 'react-i18next'
import { localStorageUtil } from '../foundation/utils/storageUtil'
import { FrameObject, LensObject } from '../types/rxConfigurator'

export const RX_CONFIGURATOR_DOM_ID = 'rxcApp'
export const RX_STYLE_ID = 'rxcCss'
export const RX_SCRIPT_ID = 'rxcJs'

export const RX_SAVED_LENS_DATA_KEY = 'rxSavedLensData' // localStorage key for saved lens data from RX configurator
export const RX_PRESCRIPTION_OBJECT_KEY = 'prescriptionObject' // localStorage key for prescription object

export const RX_CONFIG = {
  selector: `#${RX_CONFIGURATOR_DOM_ID}`,
  actionsModule: {
    genericSaveLensSelection: (frame: FrameObject, lens: LensObject) => {
      if (lens) {
        localStorageUtil.set(RX_SAVED_LENS_DATA_KEY, { ...lens, _frameUpc: frame.upc })
      }
    },
  },
  prescriptionModule: {
    prescriptionType: 'NESTED_CA',
    prescriptionFlows: ['MANUAL', 'UPLOAD', 'NESTED_CA'],
    fileExtensions: ['png', 'jpg', 'pdf'],
    nestedFlows: ['UPLOAD'],
    enablePrism: false,
    maxFileSize: 10,
    hideMoreOptions: true,
    loadPrescription: function () {
      return new Promise(function (_, reject) {
        reject(null)
      })
    },
    clearPrescription: function () {
      try {
        localStorageUtil.remove(RX_PRESCRIPTION_OBJECT_KEY)
        return true
      } catch (e) {
        return false
      }
    },
    loadExtendedPrescription: function (_requestObject) {
      return new Promise(function (_, reject) {
        reject(null)
      })
    },
    clearExtendedPrescription: function (_requestObject) {
      return new Promise((resolve, reject) => {
        try {
          localStorageUtil.set(RX_PRESCRIPTION_OBJECT_KEY, _requestObject)
          resolve({})
        } catch (e) {
          reject({})
        }
      })
    },
  },
  currencyFormat: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: 2,
  },
  paymentInstallment: {
    type: 'klarna',
    installments: 4,
    multiplePaymentInstallment: false,
    contentIdentifier: 'X_PDP_Installments',
  },
}

export const RX_CONFIG_US = {
  selector: `#${RX_CONFIGURATOR_DOM_ID}`,
  actionsModule: {
    genericSaveLensSelection: (frame: FrameObject, lens: LensObject) => {
      if (lens) {
        localStorageUtil.set(RX_SAVED_LENS_DATA_KEY, { ...lens, _frameUpc: frame.upc })
      }
    },
  },
  prescriptionModule: {
    prescriptionType: 'NESTED_CA',
    prescriptionFlows: ['MANUAL', 'UPLOAD', 'NESTED_CA'],
    fileExtensions: ['png', 'jpg', 'pdf'],
    nestedFlows: ['UPLOAD'],
    enablePrism: false,
    maxFileSize: 10,
    hideMoreOptions: true,
    loadPrescription: function () {
      return new Promise(function (_, reject) {
        reject(null)
      })
    },
    clearPrescription: function () {
      try {
        localStorageUtil.remove(RX_PRESCRIPTION_OBJECT_KEY)
        return true
      } catch (e) {
        return false
      }
    },
    loadExtendedPrescription: function (_requestObject) {
      return new Promise(function (_, reject) {
        reject(null)
      })
    },
    clearExtendedPrescription: function (_requestObject) {
      return new Promise((resolve, reject) => {
        try {
          localStorageUtil.set(RX_PRESCRIPTION_OBJECT_KEY, _requestObject)
          resolve({})
        } catch (e) {
          reject({})
        }
      })
    },
  },
  currencyFormat: {
    thousandSeparator: ',',
    decimalSeparator: '.',
    decimalScale: 2,
  },
  paymentInstallment: {
    type: 'klarna',
    installments: 4,
    multiplePaymentInstallment: false,
    contentIdentifier: 'X_PDP_Installments',
  },
}

export const HOW_TO_READ_YOUR_PRESCRIPTION = (t: TFunction) => {
  const paragraphs: string[] = t('PrescriptionConfigurator.HowToReadYourPrescription.Paragraphs', {
    returnObjects: true,
  })

  const sections: { Name: string; Description: string }[] = t(
    'PrescriptionConfigurator.HowToReadYourPrescription.Sections',
    { returnObjects: true }
  )

  return `<div class="PrescriptionModal__container">
    <div class="PrescriptionModal__container__closeContainer">
      <a class="PrescriptionModal__container__close"></a>
    </div>
    <div class="PrescriptionModal__container__title">
      ${t('PrescriptionConfigurator.HowToReadYourPrescription.Title')}
    </div>
    <div class="PrescriptionModal__container__description">${paragraphs
      .map((paragraph) => `<p style={{  }}>${paragraph}</p>`)
      .join('')}</div>
    <div class="PrescriptionModal__container__infoModalBody">
    ${sections
      .map(
        ({ Name, Description }) =>
          `<div class="PrescriptionModal__container__infoModalBodyElem">
        <div class="PrescriptionModal__container__infoModalBodyElem__title">${Name}</div>
        <div class="PrescriptionModal__container__infoModalBodyElem__desc">${Description}</div>
      </div>`
      )
      .join('')}
    </div>
  </div>`
}

export const PUPILLARY_DISTANCE = (t: TFunction) => `
  <div class="PrescriptionModal__container">
    <div class="PrescriptionModal__container__closeContainer">
      <a class="PrescriptionModal__container__close"></a>
    </div>
    <div class="PrescriptionModal__container__title">
      ${t('PrescriptionConfigurator.PupillaryDistance.Title')}
    </div>
    <div class="PrescriptionModal__showPDModalBody">
      <div class="PrescriptionModal__container__bodyText">
        ${t('PrescriptionConfigurator.PupillaryDistance.PDMeaning')}
      </div>
      <div class="PrescriptionModal__container__bodyText">
        ${t('PrescriptionConfigurator.PupillaryDistance.NoPD')}
      </div>
      <div class="PrescriptionModal__container__bodyText">
        ${t('PrescriptionConfigurator.PupillaryDistance.StepsIntroduction')}
      </div>
      <ul>
        <li>
          ${t('PrescriptionConfigurator.PupillaryDistance.FirstStep')}
        </li>
        <li>
          ${t('PrescriptionConfigurator.PupillaryDistance.SecondStep')}
        </li>
        <li>
          ${t('PrescriptionConfigurator.PupillaryDistance.ThirdStep')}
        </li>
        <li>${t('PrescriptionConfigurator.PupillaryDistance.FourthStep')}</li>
        <li>
          ${t('PrescriptionConfigurator.PupillaryDistance.FifthStep')}
        </li>
      </ul>
    </div>
  </div>
`
