import styled from 'styled-components'
import { MoonIcon, SunIcon } from '../Icons'

export const StyledMoonIcon = styled(MoonIcon)`
  width: 16px;
  height: 16px;
  left: 6px;
`

export const StyledSunIcon = styled(SunIcon)`
  width: 22px;
  height: 22px;
  right: 1px;
`

export const StyledThemeSwitcherWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`
export const StyledThemeSwitcherLabel = styled.label`
  display: flex;
  position: relative;
  height: 24px;
  width: 46px;
  background-color: #ebe9e4;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  font-size: 0;
  color: transparent;
  border-radius: 22px;
  svg {
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0);
    transition: fill 1000ms ease-out;
  }
  &:after {
    z-index: 0;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 2px;
    right: 2px;
    left: 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.text.primary};
    transition: right 500ms ease, left 500ms ease, background-color 500ms ease-out;
  }
`
export const StyledThemeSwitcherInput = styled.input`
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  bottom: 0;

  &:checked {
    & + label {
      background-color: ${({ theme }) => theme.palette.custom.lightGrey};
    }
  }

  &:not(:checked) + label:after {
    left: calc(50% + 1px);
  }

  &:checked + label:after {
    right: calc(50% + 3px);
    background-color: #ebe9e4;
  }
  & + label > .night {
    fill: ${({ theme }) => theme.palette.custom.lightGrey};
  }
  & + label > .day {
    fill: ${({ theme }) => theme.palette.custom.warmGrey};
    stroke: ${({ theme }) => theme.palette.custom.warmGrey};
  }
  &:checked + label > .night {
    fill: ${({ theme }) => theme.palette.custom.black};
  }

  &:checked + label > .day {
    fill: ${({ theme }) => theme.palette.custom.warmGrey};
    stroke: ${({ theme }) => theme.palette.custom.warmGrey};
  }
`
