/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'

const PaperWrapper = React.forwardRef((props: any, ref: any) => {
  const { classes, elevation, ...other } = props
  return <Paper {...other} ref={ref} elevation={0} />
})

const StyledPaper = styled(PaperWrapper)`
    border-radius:  ${({ theme }) => theme.shape.borderRadius}px;
    box-shadow: ${({ theme }) => theme.shadows[1]};
    overflow: hidden;

    padding: 16px;
  
    &.expanded-menu-paper,
    &.expanded-menu-two-tier-submenu,
    &.expanded-menu-three-tier-submenu {
      border-radius: ${({ theme }) =>
        `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`};
      ul {
        display:grid;
      }
    }

    &.mini-cart-container {
        overflow-y: auto;
        max-height: ${({ theme }) => window.innerHeight - theme.spacing(12)}px;
    }

    &.scrollable {
      overflow-y: auto;
    }
  }
`

export default StyledPaper
