export const TOP_CATEGORIES_DEPTH_LIMIT = '11,11'

export const PRODUCT_LIST_FIELDS = 'facetView'

export const KEYWORD_LIMIT = 4

export const PAGINATION_CONFIGS = {
  pageLimit: 28,
  pageDefaultOffset: 0, //The page offset to start with on pageload and filters
} as const
