import styled from 'styled-components'
import { AddressFormData } from '../../../types/form'
//UI
import { StyledGrid } from '../../StyledUI'
import Modal from '@material-ui/core/Modal'

export const StyledFormFieldGrid = styled(StyledGrid)<{
  name: keyof AddressFormData
}>`
  ${({ theme }) => `
  padding: ${theme.spacing(0.75)}px 0;
  
  ${theme.breakpoints.up('md')} {
    .arn-textfield-wrapper {
        width: 200px;
    }
  }
`}
`

export const StyledAddressSuggesterContainer = styled(StyledGrid)`
  position: relative;
`

export const StyledSuggestionsContainer = styled.ul`
  position: absolute;
  z-index: 2;
  padding: 0;
  margin: 0;
  list-style-type: none;
  width: ${({ theme }) => `calc(100% - ${theme.spacing() * 2}px)`};
  background-color: ${({ theme }) => theme.palette.background.default};
  border: ${({ theme }) => `1px solid ${theme.palette.text.primary}`};
  border-top: 0;
`

export const StyledSuggestionsElement = styled.li<{
  selected?: boolean
}>`
  cursor: pointer;
  padding: 0 ${({ theme }) => `${theme.spacing()}px`};
  margin: ${({ theme }) => theme.spacing() / 2}px 0;
  background-color: ${({ theme, selected }) =>
    selected ? `${theme.palette.custom.greyLightmode}` : 'none'};
  color: ${({ theme, selected }) =>
    selected ? `${theme.palette.custom.white}` : theme.palette.text.primary};
`

export const StyledCaliforniaWarningContainer = styled.div`
  ${({ theme }) => `
  margin-top: 9px;
  font-size: 0.75rem;

  button {
    margin-left: 0.25rem;
    padding: 0;
    width: auto;
    border: none;

    background-color: transparent;
    color: ${theme.palette.text.primary};

    text-decoration: underline;
    cursor: pointer;

    ${theme.breakpoints.down('sm')} {
      max-width: fit-content;
    }
  }
`}
`

export const StyledCaliforniaWarningModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledCaliforniaWarningModalContainer = styled.div`
  ${({ theme }) => `
  width: 800px;
  background-color: ${theme.palette.custom.warmGrey};
  color: ${theme.palette.secondary.contrastText};

  :focus {
    outline: none;
  }

  ${theme.breakpoints.down('sm')} {
    width: 90%;
  }
`}
`

export const StyledCaliforniaWarningModalHeader = styled.div`
  padding: 37px 30px 38px 30px;

  display: flex;
  align-items: center;

  position: relative;

  & > :last-child {
    margin-left: 1rem;
  }
`

export const StyledCaliforniaWarningModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  svg {
    cursor: pointer;
  }
`

export const StyledCaliforniaWarningModalBody = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.custom.black};
  padding: 31px 30px 46px 30px;

  & > a {
    color: ${({ theme }) => theme.palette.custom.black};
    text-decoration: underline;
  }
`
