//Redux
import * as ACTIONS from '../action-types/order'

//Standard libraries
import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { HYPHEN, ORDER_ID } from '../../constants/common'
//Custom libraries
import {
  ESTIMATED_TAXES,
  INVENTORY_STATUS,
  ORDER_STATUS,
  TAXES_ZIP_CODE,
  RX_PRODUCTS_IN_CART_LIMIT,
  TAX_CALCULATED,
} from '../../constants/order'

import { ACCOUNT } from '../../foundation/constants/common'
import { AppResponseAction } from '../store'
import { LOGIN_SUCCESS_ACTION, LOGOUT_SUCCESS_ACTION } from '../actions/user'
import { NOT_FOUND } from 'http-status-codes'
import { OrderReducerState } from './reducerStateInterface'
import { SHIPINFO_GET_SUCCESS } from '../action-types/order'
import { OrderItem, ShippingInfo } from '../../types/order'
import initStates from './initStates'
//Foundation libraries
import { localStorageUtil, sessionStorageUtil } from '../../foundation/utils/storageUtil'
import { isRxProduct } from '../../utils/isRxOrder'
import axios from 'axios'
import { INIT_SITE_SUCCESS_ACTION } from '../actions/site'

/**
 * Order reducer
 * handles states used by order related components
 * @param state State object managed by order reducer
 * @param action The dispatched action
 */

const getOrderItemsSalesTax = (orderItems?: OrderItem[]) => {
  try {
    const value = orderItems?.map(oi => oi.salesTax).reduce(
      (accumulator, currentValue) => accumulator + parseFloat(currentValue),
      0,
    ) || 0
    return value
  } catch (e) {
    return null
  }
}

const orderReducer = createReducer(initStates.order, (builder) => {
  builder.addCase(ACTIONS.CART_FETCHING_REQUESTED, (state: OrderReducerState) => {
    state.isFetching = true
  })

  builder.addCase(ACTIONS.CART_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response
    if (response) {
      const { orderItem: orderItems, ...cart } = response
      const newCatentries = action.catentries
      const checkInventory = action.checkInventory

      state.cart = cart

      let count = 0
      if (orderItems) {
        count = orderItems.reduce((c: number, item: any) => +item.quantity + c, 0)
      }
      if(!orderItems || orderItems?.length <=0) {
        sessionStorageUtil.remove(TAX_CALCULATED)
        sessionStorageUtil.remove(TAXES_ZIP_CODE)
      }
      state.numItems = count

      let newOrderItems: any[] = []
      let disableRecurringOrder = false
      let disableCheckout = false
      if (orderItems && orderItems.length > 0) {
        let catentries = state.catentries

        if (newCatentries !== undefined) {
          catentries = newCatentries
          state.catentries = newCatentries
        }

        newOrderItems = []
        orderItems.forEach((item: any, index: number) => {
          if (checkInventory) {
            if (
              item.orderItemInventoryStatus !== INVENTORY_STATUS.available &&
              item.orderItemInventoryStatus !== INVENTORY_STATUS.allocated
            ) {
              disableCheckout = true
            }
          }
          let obj = {
            ...item,
          }
          const catentryId = item.productId
          if (catentries != null) {
            const catentry = catentries[catentryId]
            if (catentry !== undefined) {
              if (catentry.name !== undefined) {
                obj['name'] = catentry.name
              }
              if (catentry.thumbnail !== undefined) {
                obj['thumbnail'] = catentry.thumbnail
              }
              if (catentry.attributes !== undefined) {
                obj['attributes'] = catentry.attributes
              }
              if (catentry.seo !== undefined) {
                obj['seo'] = catentry.seo
              }
              if (catentry.disallowRecurringOrder !== undefined) {
                obj['disallowRecurringOrder'] = catentry.disallowRecurringOrder
                if (catentry.disallowRecurringOrder === '1') {
                  disableRecurringOrder = true
                }
              }
              if (catentry.parentCatalogGroupID !== undefined) {
                obj['parentCatalogGroupID'] = catentry.parentCatalogGroupID
              }

              if (catentry.x_offerpriceRx !== undefined) {
                obj['x_offerpriceRx'] = catentry.x_offerpriceRx
              }

              if (catentry.x_offerDiscountpriceRx !== undefined) {
                obj['x_offerDiscountpriceRx'] = catentry.x_offerDiscountpriceRx
              }

              if (catentry.x_priceBadge !== undefined) {
                obj['x_priceBadge'] = catentry.x_priceBadge
              }
            }
          }
          newOrderItems.push(obj)
        })

        state.isCheckoutDisabled = disableCheckout
        state.isRecurringOrderDisabled = disableRecurringOrder
      }
      state.orderItems = newOrderItems
      state.orderComplete = response.orderStatus === ORDER_STATUS.Created
      state.isRXProductsLimitExceeded =
        newOrderItems.filter((item) => isRxProduct(item?.orderItemExtendAttribute)).length >
        RX_PRODUCTS_IN_CART_LIMIT
    }
    if (state.isRecurringOrderDisabled && state.cart && state.cart.orderId) {
      if (localStorageUtil.get(ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId)) {
        const recurringOrderInfo: any[] = [false, '0', null]
        localStorageUtil.set(
          ACCOUNT + HYPHEN + ORDER_ID + HYPHEN + state.cart.orderId,
          recurringOrderInfo
        )
      }
    }
    state.estimatedTaxValue = !!state.orderItems && getOrderItemsSalesTax(state.orderItems)
    state.isFetching = false
  })

  builder.addCase(ACTIONS.CART_GET_ERROR, (state: OrderReducerState, action: AnyAction) => {
    if (
      action.error &&
      action.error.response &&
      action.error.response.status &&
      action.error.response.status === NOT_FOUND
    ) {
      state.cart = null
      state.numItems = 0
      state.orderItems = []
    }

    state.isFetching = false
    if (!axios.isCancel(action.error)) {
      state.isCheckoutDisabled = true
    }
  })
  builder.addCase(ACTIONS.CART_UPDATED_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    state.cart = action.payload
  })
  builder.addCase(ACTIONS.ADDING_ITEM, (state: OrderReducerState, action: AnyAction) => {
    state.isAddingItem = action.payload.isAddingItem
  })
  builder.addCase(
    ACTIONS.CART_TOGGLE_ORDER_COMPLETE,
    (state: OrderReducerState, action: AnyAction) => {
      state.orderComplete = action.payload
      sessionStorageUtil.remove(ESTIMATED_TAXES)
      sessionStorageUtil.remove(TAXES_ZIP_CODE)
      state.estimatedTaxValue = null
    }
  )
  builder.addCase(ACTIONS.SHIPINFO_GET_REQUESTED, (state: OrderReducerState) => {
    state.isFetchingShippingInfo = true
  })
  builder.addCase<
    typeof SHIPINFO_GET_SUCCESS,
    AppResponseAction<ShippingInfo, typeof SHIPINFO_GET_SUCCESS>
  >(SHIPINFO_GET_SUCCESS, (state, action) => {
    state.shipInfos = action.response
    state.isFetchingShippingInfo = false
  })
  builder.addCase(ACTIONS.SHIPINFO_GET_ERROR, (state: OrderReducerState) => {
    state.shipInfos = null
    state.isFetchingShippingInfo = false
  })
  builder.addCase(
    ACTIONS.SHIPPING_AS_BILLING_TOGGLE,
    (state: OrderReducerState, action: AnyAction) => {
      state.isShippingUsedAsBilling = action.payload
    }
  )
  builder.addCase(ACTIONS.PAYMETHODS_GET_SUCCESS, (state: OrderReducerState, action: AnyAction) => {
    const response = action.response
    if (response && response.usablePaymentInformation) {
      let cardsList: any[] = []
      let cashList: any[] = []
      for (let payment of response.usablePaymentInformation) {
        //this has been removed to display all the payment methods
        // if (
        //   payment.paymentMethodName === PAYMENT.paymentMethodName.amex ||
        //   payment.paymentMethodName === PAYMENT.paymentMethodName.mc ||
        //   payment.paymentMethodName === PAYMENT.paymentMethodName.visa
        // ) {
        cardsList.push(payment)
        // } else if (payment.paymentMethodName === PAYMENT.paymentMethodName.cod) {
        //   cashList.push(payment)
        // }
      }

      if (cardsList.length > 0) {
        state.payMethods = cardsList.concat(cashList)
      } else {
        state.payMethods = cashList
      }
    }
  })
  builder.addCase(LOGIN_SUCCESS_ACTION, resetCart)
  builder.addCase(LOGOUT_SUCCESS_ACTION, resetCart)
  builder.addCase(ACTIONS.CART_RESET_REQUESTED, resetCart)
  builder.addCase(ACTIONS.PAYPALEXPRESS_TOGGLE, (state: OrderReducerState, action: AnyAction) => {
    state.paypalExpress = {
      ...state.paypalExpress,
      isSelected: action.payload,
    }
  })
  builder.addCase(
    ACTIONS.PAYPALEXPRESS_GET_REQUESTED,
    (state: OrderReducerState, action: AnyAction) => {
      state.paypalExpress = {
        ...state.paypalExpress,
        loading: true,
      }
    }
  )
  builder.addCase(
    ACTIONS.PAYPALEXPRESS_GET_SUCCESS,
    (state: OrderReducerState, action: AnyAction) => {
      const response = action.response
      const redirecturl = response.redirecturl
      state.paypalExpress = {
        ...state.paypalExpress,
        isSelected: true,
        loading: false,
        redirecturl,
      }
    }
  )
  builder.addCase(
    ACTIONS.PAYPALEXPRESS_GET_ERROR,
    (state: OrderReducerState, action: AnyAction) => {
      state.paypalExpress = {
        ...state.paypalExpress,
        error: true,
        loading: false,
      }
    }
  )
  builder.addCase(
    ACTIONS.PAYPALEXPRESS_CHECK_STATUS_GET_REQUESTED,
    (state: OrderReducerState, action: AnyAction) => {
      state.paypalExpress = {
        ...state.paypalExpress,
        loading: true,
      }
    }
  )
  builder.addCase(
    ACTIONS.PAYPALEXPRESS_CHECK_STATUS_GET_SUCCESS,
    (state: OrderReducerState, action: AnyAction) => {
      const response = action.response
      state.paypalExpress = {
        ...state.paypalExpress,
        orderId: response.orderId,
        decision: response.decision,
        loading: false,
      }
    }
  )
  builder.addCase(
    ACTIONS.PAYPALEXPRESS_CHECK_STATUS_GET_ERROR,
    (state: OrderReducerState, action: AnyAction) => {
      state.paypalExpress = {
        ...state.paypalExpress,
        loading: false,
      }
    }
  )
  builder.addCase(
    ACTIONS.SHIPPING_FORM_VALID_STATUS,
    (state: OrderReducerState, action: AnyAction) => {
      state.shippingFormStatus = action.payload
    }
  )
  builder.addCase(
    ACTIONS.BILLING_FORM_VALID_STATUS,
    (state: OrderReducerState, action: AnyAction) => {
      state.billingFormStatus = action.payload
    }
  )
  builder.addCase(
    ACTIONS.ORDER_FINALIZATION_GET_SUCCESS,
    (state: OrderReducerState, action: AnyAction) => {
      const response = action.payload?.orderFinalizedInfo
      const ppExpress = action.payload?.orderFinalizationBody?.ppExpress
      state.isFinalizing = false
      state.orderComplete = [ORDER_STATUS.Created, ORDER_STATUS.PendingPrescription].includes(
        response?.orderStatus
      )
      state.orderItems = [...response.orderItem]
      state.paypalExpress.isSelected = ppExpress
      state.cart!.totalSalesTax = response.totalSalesTax
      state.cart!.grandTotal = response.grandTotal
      sessionStorageUtil.remove(TAX_CALCULATED)
    }
  )
  builder.addCase(ACTIONS.ORDER_FINALIZATION_GET_ERROR, (state: OrderReducerState) => {
    state.isFinalizing = false
  })
  builder.addCase(
    ACTIONS.ORDER_PAYMENT_METHOD_UPDATE,
    (state: OrderReducerState, action: AnyAction) => {
      state.selectedPayMethodInfoList = action.payload
    }
  )
  builder.addCase(
    ACTIONS.NEWS_LETTER_SUBSCRIBE_CHECKED_STATUS,
    (state: OrderReducerState, action: AnyAction) => {
      state.isNewsletterSubscribeChecked = action.payload
    }
  )
  builder.addCase(
    ACTIONS.EDIT_LENSES_STATUS,
    (state: OrderReducerState, action: AnyAction) => {
      state.isEditLenses = action.payload
    }
  )
  builder.addCase(
    ACTIONS.IMAGE_RX_URL_STATUS,
    (state: OrderReducerState, action: AnyAction) => {
      state.urlImageRx = action.payload
    }
  )
  builder.addCase(
    ACTIONS.TAXES_CALCULATION_GET_REQUESTED,
    (state: OrderReducerState) => {
      state.estimatingTaxes = true
    }
  )
  builder.addCase(
    ACTIONS.TAXES_CALCULATION_GET_SUCCESS,
    (state: OrderReducerState,action: AnyAction) => {
      const response = action.response
      state.estimatingTaxes = false
      sessionStorageUtil.set(TAX_CALCULATED,true)
      !!response.zipCode && sessionStorageUtil.set(TAXES_ZIP_CODE,{
        country:response.country,
        zipCode: response.zipCode
      })
    }
  )
  builder.addCase(
    ACTIONS.TAXES_CALCULATION_GET_ERROR,
    (state: OrderReducerState) => {
      state.estimatingTaxes = false
      sessionStorageUtil.remove(TAX_CALCULATED)
    }
  )
  .addCase(INIT_SITE_SUCCESS_ACTION, (state: any | any, action: AnyAction) => {
    const {country} = action.payload
    const estimatedTaxesInfo = sessionStorageUtil.get(TAXES_ZIP_CODE)
    if(!!estimatedTaxesInfo  && country !== estimatedTaxesInfo?.country) {
      sessionStorageUtil.remove(TAXES_ZIP_CODE)
      sessionStorageUtil.remove(TAX_CALCULATED)
    }

  })
})

/**
 *
 */
function resetCart(state: OrderReducerState, action: AnyAction) {
  resetCartInfo(state, action)
}

/**
 *
 */
function resetCartInfo(state: OrderReducerState, action: AnyAction) {
  state.orderComplete = false
  state.numItems = 0
  state.orderItems = []
  state.catentries = null
  state.isCheckoutDisabled = false
  state.shipInfos = null
  state.shipModes = []
  state.payMethods = []
  state.isRecurringOrderDisabled = false
  state.isFetching = false
  state.cart = null
  sessionStorageUtil.remove(TAX_CALCULATED)
  sessionStorageUtil.remove(TAXES_ZIP_CODE)
  state.estimatedTaxValue = null
}

export default orderReducer
