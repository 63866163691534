import { BylineChoice, BylinePersonalInfo } from './FooterByline'
import { Container, useTheme } from '@material-ui/core'
import {
  FILTERS_DRAWER_TOGGLE_ACTION,
  NEWSLETTER_DRAWER_TOGGLE_ACTION,
} from '../../redux/actions/ui'
import {
  ICMExternalLink,
  ICMExternalPage,
  ICMHtml,
  ICMTeaser,
  ILXTeaser,
  IPlacement,
} from '../../types/teaser'
import React, { FC, useEffect, useRef, useState } from 'react'
import { StyledA, StyledFooter, StyledGrid, StyledLink } from '../StyledUI'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CountryBaseLink } from '../StyledUI/StyledLink/StyledLink'
import CountrySelect from './CountrySelect'
import FooterSubMenu from './FooterSubMenu'
import Log from '../../services/Log'
import { NewsletterBanner } from './index'
import NewsletterDrawerContent from '../widgets/newsletter/newsletterDrawerContent'
import NewsletterSticky from './NewsletterSticky'
import PaymentMethods from './PaymentMethods'
import StyledNewsletterDrawer from '../StyledUI/StyledNewsletterDrawer/StyledNewsletterDrawer'
import { SustainableContainer } from '../StyledUI/StyledFooter'
import { SustainableIcon } from '../StyledUI/Icons'
import cmsService from '../../foundation/apis/cms/cms.service'
import get from 'lodash/get'
import { getImageFromCMS } from '../../utils/url'
import { localStorageUtil } from '../../foundation/utils/storageUtil'
import { newsletterDrawerSelector } from '../../redux/selectors/ui'
import { paymentMethodsSelector } from '../../redux/selectors/site'
import { sanitizeHtml } from '../../utils/common'
import styled from 'styled-components'
import { themeModeSelector } from '../../redux/selectors/theme'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useCms } from '../providers/CmsProvider'
import { useLocation } from 'react-router'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'
import { useDynamicCmsContent } from '../../foundation/hooks/useDynamicCmsContent'

export type FooterLink = {
  title: string
  url?: string
}

const StyledFooterDisclaimer = styled.div`
  p {
    margin: 0;
  }
`

const StyledSitesGroupContainer = styled.div<{ isDisplayed: boolean; themeMode: string }>`
  padding: 1rem 0.875rem;
  display: ${({ isDisplayed }) => (isDisplayed ? 'grid' : 'none')};
  grid-template-columns: repeat(auto-fit, 103px);
  justify-content: space-between;
  align-items: center;

  width: 100%;

  border-top: ${({ themeMode, theme }) =>
    themeMode === 'dark'
      ? '1px solid ' + theme.palette.primary.contrastText
      : '1px solid ' + theme.palette.primary.dark};

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: repeat(6, 103px);
  }

  a {
    color: ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark}`};
  }
`

const StyledOtherSitesButton = styled.button<{ themeMode: string }>`
  padding: 0;
  border: none;
  margin: 3px;

  cursor: pointer;

  color: ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark}`};
  text-decoration: underline;

  background-color: inherit;
`

const Footer: FC = () => {
  const { basePath } = useStoreIdentity()
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const theme = useTheme()
  const dispatch = useDispatch()
  const location = useLocation()
  const newsletterDrawerOpen = useSelector(newsletterDrawerSelector)
  const footerPaymentMethods = useSelector(paymentMethodsSelector)
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const { footerPlacements, legalNavItem, serviceNavItem } = useCms()
  const desktopSitesGroupRef = useRef<HTMLDivElement | null>(null)

  const [newsletterStickyText, setNewsletterStickyText] = useState('')
  const [newsletterText, setNewsletterText] = useState('')
  const [newsletterImageUrl, setNewsletterImageUrl] = useState('')
  const [newsletterImageDescription, setNewsletterImageDescription] = useState('')
  const [newsletterTitle, setNewsletterTitle] = useState('')
  const [footerLinks, setFooterLinks] = useState<ICMExternalPage[]>([])
  const [socialLinks, setSocialLinks] = useState<FooterLink[]>([])
  const [sustainabilityText, setSustainabilityText] = useState('')
  const [sustainabilityPath, setSustainabilityPath] = useState('')
  const [copyright, setCopyright] = useState('')
  const [footerDisclaimer, setFooterDisclaimer] = useState('')
  const [otherSitesGroup, setOtherSitesGroup] = useState<FooterLink[]>([])
  const [bylineLinks, setBylineLinks] = useState<(ICMTeaser | ICMHtml)[]>([])
  const [isShowingOtherSitesGroup, setIsShowingOtherSitesGroup] = useState<boolean>(false)
  const [placementName, setPlacementName] = useState<{ [key: string]: string }>({})

  const newsletterStickyHidden = localStorageUtil.get('newsletterStickyHidden')

  const dynamicContentBanners = useDynamicCmsContent(footerPlacements)

  useEffect(() => {
    const getCmsFooter = async () => {
      try {
        if (Array.isArray(dynamicContentBanners)) {
          const footerLinksItem = dynamicContentBanners.find(
            (i) => i.name === 'footer_page_links'
          ) as IPlacement<ICMExternalPage>
          const footerLinks = get(footerLinksItem, 'items', [])
          const pageLinksName = footerLinksItem.name
          setFooterLinks(footerLinks)

          const socialItem = dynamicContentBanners.find((i) => i.name === 'footer_social')
          const socialLinks = getFormattedSocialLinks(socialItem)
          const socialName = socialItem?.name!
          setSocialLinks(socialLinks)

          const sustainabilityItem = dynamicContentBanners.find(
            (i) => i.name === 'footer_sustainability'
          )
          const sustainabilityName = sustainabilityItem?.name!

          const sustainabilityTeaser = sustainabilityItem?.items?.[0] as ILXTeaser

          if (sustainabilityTeaser) {
            setSustainabilityText(sanitizeHtml(sustainabilityTeaser.teaserText1 || ''))
            setSustainabilityPath(
              get(sustainabilityTeaser, 'teaserLXCallToActionSettings[0].target.formattedUrl', '')
            )
          }

          const copyrightItem = dynamicContentBanners.find((i) => i.name === 'footer_copyright')
          const copyright = get(copyrightItem, 'items[0].teaserTitle1', [])
          const copyrightName = copyrightItem?.name!
          setCopyright(copyright)

          const footerDisclaimerItem = dynamicContentBanners.find((i) => i.name === 'footer_disclaimer')
          const disclaimerName = footerDisclaimerItem?.name!

          setFooterDisclaimer(sanitizeHtml(get(footerDisclaimerItem, 'items[0].teaserText1', [])))

          const bylineItem = dynamicContentBanners.find((i) => i.name === 'footer_byline')
          const bylineName = bylineItem?.name!
          const byline = get(bylineItem, 'items', []) as (ICMTeaser | ICMHtml)[]
          setBylineLinks(byline)

          const newsletterImageItem = dynamicContentBanners.find(
            (i) => i.name === 'footer_newsletter_overlay'
          )
          const newsLetterOverlayName = newsletterImageItem?.name!
          const newsletterPartialImageUrl = get(
            newsletterImageItem,
            'items[0].media[0].uriTemplate'
          )
          const newsletterImageUrl = newsletterPartialImageUrl && getImageFromCMS(newsletterPartialImageUrl.replace('{cropName}/{width}/', ''))

          setNewsletterImageUrl(newsletterImageUrl)
          setNewsletterImageDescription(get(newsletterImageItem, 'items[0].teaserText1', ''))

          const newsletterStickyItem = dynamicContentBanners.find(
            (i) => i.name === 'footer_newsletter_sticky_element'
          )
          const newsLetterStickyElementName = newsletterStickyItem?.name!
          const newsletterStickyText = get(newsletterStickyItem, 'items[0].teaserTitle1', '')
          setNewsletterStickyText(newsletterStickyText)

          const footerNewsLetterItem = dynamicContentBanners.find((i) => i.name === 'footer_newsletter')

          const footerNewsLetterText = get(footerNewsLetterItem, 'items[0].teaserTitle4', '')

          setNewsletterText(footerNewsLetterText)

          const footerCrossSiteLinks = dynamicContentBanners.find(
            (i) => i.name === 'footer_cross_site_links'
          ) as IPlacement<ICMExternalLink>
          const crossSiteLinksName = footerCrossSiteLinks.name
          setOtherSitesGroup(
            footerCrossSiteLinks?.items
              .filter((v) => v.type === 'CMExternalLink')
              .map((v) => ({ ...v, title: v.name }))
          )

          const newsletterItem = dynamicContentBanners.find((i) => i.name === 'footer_newsletter_overlay')
          setNewsletterTitle(get(newsletterItem, 'items[0].teaserTitle1', ''))

          setPlacementName({
            newsLetterOverlayName,
            newsLetterStickyElementName,
            pageLinksName,
            navigationName: dynamicContentBanners.find((i) => i.name === 'footer_navigation')?.name!,
            socialName,
            sustainabilityName,
            bylineName,
            copyrightName,
            disclaimerName,
            crossSiteLinksName,
            //checkoutName
          })
        }
      } catch (e) {
        Log.error('error on footer ' + e)
      }
    }
    getCmsFooter()
  }, [dynamicContentBanners])

  const { t } = useTranslation()

  const isPersonalDataLinksExist = !!bylineLinks.length;

  const getFormattedSocialLinks = (socialItem): FooterLink[] => {
    const socialLinks: ILXTeaser[] = get(socialItem, 'items', [])
    return socialLinks
      .filter((sl) => sl.teaserLXCallToActionSettings && sl.teaserLXCallToActionSettings.length > 0)
      .map((sl) => {
        const title = sl.teaserTitle1
        const url = get(sl, 'teaserLXCallToActionSettings[0].target.url', '')
        return { title, url }
      })
  }

  const handleShowsOtherSitesGroup = () => {
    const updatedValue = !isShowingOtherSitesGroup
    setIsShowingOtherSitesGroup(updatedValue)

    if (updatedValue) {
      setTimeout(() => {
        desktopSitesGroupRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      })
    }
  }

  const handleEscapeKey = (event) => {
    if (event.key === 'Escape') dispatch(NEWSLETTER_DRAWER_TOGGLE_ACTION(false))
  }

  useEffect(() => {
    if (newsletterDrawerOpen) {
      dispatch(NEWSLETTER_DRAWER_TOGGLE_ACTION(false))
    }
  }, [location.pathname])

  return (
    <>
      {newsletterText && (
        <NewsletterBanner text={newsletterText} />
      )}

      {!newsletterStickyHidden && newsletterStickyText && (
        <NewsletterSticky
          text={newsletterStickyText}
          {...cmsService.getPlacementMetaDataAttribute(placementName.newsLetterStickyElementName)}
        />
      )}

      <StyledNewsletterDrawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={newsletterDrawerOpen}
        className="newsletter-menu"
        onClose={() => dispatch(FILTERS_DRAWER_TOGGLE_ACTION(false))}
        onKeyDown={(event) => handleEscapeKey(event)}
        onOpen={() => dispatch(FILTERS_DRAWER_TOGGLE_ACTION(true))}
        {...cmsService.getPlacementMetaDataAttribute(placementName.newsLetterOverlayName)}>
        <NewsletterDrawerContent imageUrl={newsletterImageUrl} imageDescription={newsletterImageDescription} title={newsletterTitle} />
      </StyledNewsletterDrawer>

      <StyledFooter data-element-id="X_X_Footer" themeMode={themeMode}>
        <Container className="main-container" disableGutters={true} maxWidth={false}>
          <StyledGrid direction="column" container spacing={2} className="main-content">
            <StyledGrid
              className="footer-menu"
              item
              lg={12}
              {...cmsService.getPlacementMetaDataAttribute(placementName.pageLinksName)}>
              {footerLinks?.map((item: any, idx) => {
                return (
                  <StyledLink
                    data-description={item.name}
                    key={idx}
                    className="footer-link"
                    to={`${basePath}${item?.formattedUrl}`}>
                    {item.title}
                  </StyledLink>
                )
              })}
            </StyledGrid>
            <StyledGrid className="sub-menus-container" direction="row" container>
              <StyledGrid
                className="sub-menu-container"
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                {...cmsService.getPlacementMetaDataAttribute(placementName.navigationName)}>
                <FooterSubMenu title={serviceNavItem.title} menuItems={serviceNavItem.menuItems} />
              </StyledGrid>
              <StyledGrid className="sub-menu-container" item lg={3} md={3} sm={12} xs={12}>
                <FooterSubMenu
                  title={legalNavItem.title}
                  menuItems={legalNavItem.menuItems}
                  {...cmsService.getPlacementMetaDataAttribute(placementName.navigationName)}
                />
              </StyledGrid>
              <StyledGrid
                className="sub-menu-container"
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                {...cmsService.getPlacementMetaDataAttribute(placementName.socialName)}>
                <FooterSubMenu
                  title={t('Footer.Social')}
                  target="_blank"
                  menuItems={socialLinks}
                  isSocial={true}
                />
              </StyledGrid>
              <StyledGrid className="sub-menu-container" item lg={3} md={3} sm={12} xs={12}>
                <PaymentMethods availablePaymentMethods={footerPaymentMethods || []} />
              </StyledGrid>
            </StyledGrid>
            <StyledGrid className="select-country-container" container>
              <SustainableContainer
                className="sustainable-container"
                item
                md={isPersonalDataLinksExist ? 6 : 9}
                lg={isPersonalDataLinksExist ? 6 : 9}
                xs={12}
                {...cmsService.getPlacementMetaDataAttribute(placementName.sustainabilityName)}>
                <CountryBaseLink to={sustainabilityPath}>
                  <div className="sustainable-wrapper">
                    <SustainableIcon htmlColor={theme.palette.background.dark} />
                  </div>
                  {sustainabilityText}
                </CountryBaseLink>
              </SustainableContainer>
              {isPersonalDataLinksExist && <StyledGrid
                className="personaldata-container"
                item
                md={3}
                lg={3}
                xs={12}
                {...cmsService.getPlacementMetaDataAttribute(placementName.bylineName)}>
                {isPersonalDataLinksExist && bylineLinks.length > 1 ? (
                  <>
                    <StyledGrid item lg={6} xs={12}>
                      <BylinePersonalInfo info={bylineLinks[0] as ICMHtml} />
                    </StyledGrid>
                    <StyledGrid className="personaldata-container" item md={6} lg={6} xs={12}>
                      <BylineChoice choice={bylineLinks[1] as ICMTeaser} />
                    </StyledGrid>
                  </>
                ) : (
                  <StyledGrid item md={3} lg={3} xs={12}>
                    <BylineChoice choice={bylineLinks[0] as ICMTeaser} />
                  </StyledGrid>
                )}
              </StyledGrid>}
              <StyledGrid className="select-country-wrapper" item md={3} lg={3} xs={12}>
                <CountrySelect />
              </StyledGrid>
            </StyledGrid>
            <StyledGrid className="copyright-container" container>
              <StyledGrid
                item
                lg={3}
                xs={12}
                {...cmsService.getPlacementMetaDataAttribute(placementName.copyrightName)}>
                <div dangerouslySetInnerHTML={{ __html: copyright }} />
              </StyledGrid>
              <StyledGrid
                item
                lg={9}
                xs={12}
                {...cmsService.getPlacementMetaDataAttribute(placementName.disclaimerName)}>
                <StyledFooterDisclaimer>
                  {footerDisclaimer}
                  {(!isTablet || isDesktop) && (
                    <StyledOtherSitesButton
                      onClick={handleShowsOtherSitesGroup}
                      themeMode={themeMode}>
                      {t('Footer.OtherSites')}
                    </StyledOtherSitesButton>
                  )}
                </StyledFooterDisclaimer>
              </StyledGrid>
            </StyledGrid>
            {(!isTablet || isDesktop) && (
              <StyledSitesGroupContainer
                themeMode={themeMode}
                ref={desktopSitesGroupRef}
                isDisplayed={isShowingOtherSitesGroup}
                {...cmsService.getPlacementMetaDataAttribute(placementName.crossSiteLinksName)}>
                {otherSitesGroup &&
                  otherSitesGroup.map((item) => (
                    <StyledA key={item.title} className="other-sites-link" href={item.url}>
                      {item.title}
                    </StyledA>
                  ))}
              </StyledSitesGroupContainer>
            )}
            {isTablet && !isDesktop && (
              <StyledGrid
                className="other-sites-container"
                {...cmsService.getPlacementMetaDataAttribute(placementName.crossSiteLinksName)}>
                <FooterSubMenu
                  title={t('Footer.OtherSites')}
                  menuItems={otherSitesGroup}
                  shouldScrollAfterExpanded
                  areOtherGroupSites
                  target="_blank"
                  isSocial={true}
                />
              </StyledGrid>
            )}
          </StyledGrid>
        </Container>
      </StyledFooter>
    </>
  )
}

export default React.memo(Footer)
