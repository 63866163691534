import { StyledTypography } from '../StyledUI'

import styled from 'styled-components'
import theme from '../../themes'

const newsletterBannerHeight = '56px'

export const Container = styled.div`
  z-index: 4;
  position: fixed;
  bottom: 0;
  width: 50vw;
  height: ${newsletterBannerHeight};
  display: grid;
  flex-direction: row;
  align-items: center;
  grid-template-columns: 40px 1fr 100px;
  padding: 0 ${theme.spacing(0.5)}px;
  background-color: ${theme.palette.primary.main};

  ${theme.breakpoints.down('sm')} {
    width: 100vw;
  }
`

export const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Text = styled(StyledTypography)`
  padding-left: ${theme.spacing(1)}px;
  font-weight: 500;
  font-size: ${theme.spacing(1)}px;
  color: ${theme.palette.primary.dark};

  ${theme.breakpoints.up('md')} {
    font-size: ${theme.spacing(1.5)}px;
  }

  ${theme.breakpoints.up('lg')} {
    font-size: ${theme.spacing(2)}px;
  }
`

export const LinkWrapper = styled.div`
  margin-left: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: ${theme.palette.primary.dark};
    text-decoration: underline;
  }
`
