import styled from 'styled-components'

import React from 'react'
import { RoundButton } from '../../StyledUI'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core/styles'

interface Props {
  onPress?: () => void
}

const StyledLoadMoreButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`

const StyledLoadMoreButton = styled(RoundButton)`
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 390px;
  }
`

const LoadMoreButton: React.FC<Props> = ({ onPress }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <StyledLoadMoreButtonContainer>
      <StyledLoadMoreButton
        theme={theme}
        variant="secondary"
        onClick={onPress}
        data-element-id="X_X_Tiles_LoadMore">
        {t('ProductGrid.Actions.loadMore')}
      </StyledLoadMoreButton>
    </StyledLoadMoreButtonContainer>
  )
}

export default LoadMoreButton
