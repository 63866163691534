import styled from 'styled-components'
import { StyledSwipeableDrawer } from '../../StyledUI'

const StyledFiltersDrawer = styled(StyledSwipeableDrawer)<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
    .filters-menu-content {
      background-color: ${themeMode==='dark' ?
        theme.palette.background.dark : theme.palette.background.default};
      overflow-x: hidden;
      max-width: 100vw;
      width:100%;
      padding:${theme.spacing()}px;
      display: flex;
      flex-direction: column;
      height: 250%;
      .filters-menu-title {
        margin-bottom: ${theme.spacing()}px;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
      .filters-menu-clear {
        font-size:0.8rem;
        text-transform:uppercase;
        color: ${themeMode==='dark' ?
          theme.palette.text.contrastText : theme.palette.background.light};
      }
      ${theme.breakpoints.up('md')} {
        width: 470px;
        padding:${theme.spacing(2)}px;
        justify-content: flex-start;

      }
      .arn-filters-apply-btn {
        margin-top: auto;
      }
    }
  `}
`

export default StyledFiltersDrawer
