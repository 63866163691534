import { AxiosError } from 'axios'
import { getCookieByName } from '../../../utils/cookie'
import tealiumService from '../../analytics/tealium/tealium.service'
import { NOT_FOUND } from 'http-status-codes'
import RequestService from '../../../services/RequestService'
import type { Cart } from '../../../types/order'
import { SiteInfoService } from '../../hooks/useSite/SiteInfoService'
import { CommonRequestPayloadProps, Optional } from '../../../types/common'
import { OrderRequests } from '../../../types/orderRequests'
import { OrderActionsPayload } from '../../../types/orderActionsPayload'
import { ABANDONED_ORDER_ID_KEY } from '../../../constants/order'

class CartService {
  static getExtraBodyProps() {
    const forterToken = getCookieByName('forterToken')!
    const { userAgent } = window.navigator

    return { forterToken, userAgent }
  }

  /**
   * Gets order details for the shopping cart.
   * `@method`
   * `@name Cart#getCart`
   */
  getCart(params: OrderRequests.FetchCartPayload): Promise<Cart> {
    const extraBodyProps = CartService.getExtraBodyProps()
    const { langId, responseFormat, currency, sortOrderItemBy, profileName, ...extraParams } =
      params

    const storeId = params.storeId || SiteInfoService.getSiteInfo().getStoreId()

    const queryParams = { currency, langId, profileName, responseFormat, sortOrderItemBy, storeId }

    return RequestService.request<Cart>({
      body: extraBodyProps,
      method: 'GET',
      path: '/store/{storeId}/cart/@self',
      queryParams,
      extraParams: { ...extraParams, storeId, langId },
    })
      .then((data) => data)
      .catch((e: AxiosError) => {
        const config = e.config as {
          isGuest: boolean
          storeId: string
          userId: string
          url: string
        }

        if (config) {
          tealiumService.sendErrorEvent({
            Error_Code: 'Error fetching cart data',
            Error_Details:
              `url: ${config.url}; status: ${e.response?.status}; ` +
              `userId: ${config.userId}; isGuest: ${config.isGuest}; storeId: ${config.storeId}`,
            Error_Source: e.response?.status === NOT_FOUND ? '404' : 'Server',
          })
        }

        throw e
      })
  }

  /**
   * Deletes the specified order item from the order.
   * `@method`
   * `@name Cart#deleteOrderItem`
   */
  deleteOrderItem(params: OrderRequests.DeleteOrderItemPayload) {
    const extraBodyProps = CartService.getExtraBodyProps()
    const body: OrderRequests.DeleteOrderItemPayload = { ...params, ...extraBodyProps }

    return RequestService.request<{ orderId: string[]; resourceName: string }>({
      body,
      method: 'PUT',
      path: '/store/{storeId}/cart/@self/delete_order_item',
    })
  }

  /**
   * Gets usable shipping information for the shopping cart.
   * `@method`
   * `@name Cart#getUsableShippingInfo`
   */
  getUsableShippingInfo(params: OrderActionsPayload.GetShipInfo) {
    const { widget, ...queryParams } = params
    const extraBodyProps = CartService.getExtraBodyProps()

    return RequestService.request({
      body: extraBodyProps,
      method: 'GET',
      path: '/store/{storeId}/cart/@self/usable_shipping_info',
      queryParams,
      extraParams: { widget },
    })
  }

  /**
   * Gets usable payment information for the shopping cart.
   * `@method`
   * `@name Cart#getUsablePaymentInfo`
   */
  getUsablePaymentInfo(params: OrderActionsPayload.GetUsablePaymentInfo) {
    const { currency, ...extraParams } = params
    const extraBodyProps = CartService.getExtraBodyProps()

    return RequestService.request({
      body: extraBodyProps,
      method: 'GET',
      path: '/store/{storeId}/cart/@self/usable_payment_info',
      queryParams: { currency },
      extraParams,
    })
  }

  createCart(abandonedOrderId: string) {
    return RequestService.request({
      body: { ...CartService.getExtraBodyProps(), [ABANDONED_ORDER_ID_KEY]: abandonedOrderId },
      method: 'POST',
      path: '/store/{storeId}/cart/createCart',
    })
  }

  /**
   * Adds one or more order items to the shopping cart.
   * `@method`
   * `@name Cart#addOrderItem`
   */
  addOrderItem(
    params: { body: OrderRequests.AddItemPayload | OrderRequests.AddLensPayload } & Partial<
      Pick<CommonRequestPayloadProps, 'widget'>
    >
  ) {
    const extraBodyProps = CartService.getExtraBodyProps()
    const { body, ...extraParams } = params

    return RequestService.request<OrderRequests.AddItemResponse | OrderRequests.AddLensResponse>({
      body: { ...body, ...extraBodyProps },
      method: 'POST',
      path: '/store/{storeId}/cart',
      extraParams,
    })
  }

  /**
   * Gets the order estimated taxes value without shipping address set.
   * `@method`
   * `@name estimateTaxes`
   * `@property {string} orderId (required)` The order identifier
   * `@property {string} zipCode (required)` The zipCode value
   */
  estimateTaxes(
    params: {
      orderId: string,
      zipCode: string
    } & Partial<
      Pick<CommonRequestPayloadProps, 'cancelToken'>
    >
  ) {
    const extraBodyProps = CartService.getExtraBodyProps()
    const { orderId, zipCode, ...extraParams } = params

    return RequestService.request<{
      estimatedTaxes: number
    }>({
      body: { orderId, zipCode, ...extraBodyProps },
      method: 'POST',
      path: '/store/{storeId}/estimatedTax/calculate',
      extraParams
    })
  }

  updateOrderItem(
    params: { body: OrderRequests.UpdateItemPayload } & Pick<CommonRequestPayloadProps, 'widget'>
  ) {
    const extraBodyProps = CartService.getExtraBodyProps()
    const { body, ...extraParams } = params

    return RequestService.request({
      body: { ...body, ...extraBodyProps },
      method: 'PUT',
      path: '/store/{storeId}/cart/@self/update_order_item',
      extraParams,
    })
  }

  /**
   * Prepares the shopping cart for checkout. This method must be called before the checkout service.
   * `@method`
   * `@name Cart#preCheckout`
   */
  preCheckout(params: Optional<OrderActionsPayload.Checkout, 'body' | 'orderId'>) {
    const extraBodyProps = CartService.getExtraBodyProps()
    const { body, ...extraParams } = params

    return RequestService.request({
      body: { ...extraBodyProps, ...body },
      method: 'PUT',
      path: '/store/{storeId}/cart/@self/precheckout',
      extraParams,
    })
  }

  /**
   * Checks out the shopping cart.
   * `@method`
   * `@name Cart#checkOut`
   */
  checkOut(params: OrderActionsPayload.Checkout) {
    const extraBodyProps = CartService.getExtraBodyProps()
    const { body, ...extraParams } = params

    return RequestService.request({
      body: { ...body, ...extraBodyProps },
      extraParams,
      method: 'POST',
      path: '/store/{storeId}/cart/@self/checkout',
    })
  }

  /**
   * Gets buyer purchase order information for buyer purchase order ID.
   * `@method`
   * `@name Cart#getBuyerPurchaseOrderDataBean`
   */
  getBuyerPurchaseOrderDataBean(
    params: Pick<CommonRequestPayloadProps, 'cancelToken' | 'widget'> & {
      buyerPurchaseOrderId: string
    }
  ) {
    const extraBodyProps = CartService.getExtraBodyProps()
    const { buyerPurchaseOrderId, ...extraParams } = params

    return RequestService.request({
      body: extraBodyProps,
      extraParams,
      method: 'GET',
      path: '/store/{storeId}/cart/@self/buyer_purchase_order/{buyerPurchaseOrderId}',
      pathParams: { buyerPurchaseOrderId },
    })
  }
}

export default new CartService()
