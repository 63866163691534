import React from 'react'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CustomTheme } from '../../../types/theme'
import { CheckmarkIcon } from '../Icons'
import useThemeMode from '../../../hooks/useThemeMode'
import { useSelector, shallowEqual } from 'react-redux'
import { themeModeSelector } from '../../../redux/selectors/theme'

const useStyles = makeStyles<CustomTheme, { themeMode: string }>((theme) =>
  createStyles({
    root: {
      marginRight: 10,
      padding: 4,
      borderRadius: 0,
      fontSize: 'inherit',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 0,
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: ({ themeMode }) =>
        theme.palette.custom[themeMode === 'dark' ? 'warmGrey' : 'black'],
      borderStyle: 'solid',
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      color: ({ themeMode }) => theme.palette.custom[themeMode === 'dark' ? 'warmGrey' : 'black'],
      width: 16,
      height: 16,
      display: 'inherit',
      borderWidth: 1,
      borderColor: ({ themeMode }) =>
        theme.palette.custom[themeMode === 'dark' ? 'warmGrey' : 'black'],
      borderStyle: 'solid',
      position: 'relative',
      '& > svg': {
        position: 'absolute',
        height: '8px',
        width: '8px',
        right: '3px',
        top: '3px',
      },
    },
    dark: {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.contrastText,
    },
  })
)

export const StyledCheckbox: React.FC<CheckboxProps> = (props) => {
  const { themeMode } = useThemeMode()
  const classes = useStyles({ themeMode })
  return (
    <Checkbox
      className={classes.root}
      icon={
        <span className={themeMode === 'dark' ? `${classes.icon} ${classes.dark}` : classes.icon} />
      }
      disableRipple
      color="default"
      checkedIcon={
        <span
          className={
            themeMode === 'dark' ? `${classes.checkedIcon} ${classes.dark}` : classes.checkedIcon
          }>
          <CheckmarkIcon />
        </span>
      }
      {...props}
    />
  )
}
