import {
  ContentPlp,
  StyledGridProductLayout,
  StyledProductWrap,
} from '../../StyledUI/StyledSearchDrawer/StyledSearchDrawer'
import { ProductTile, ProductTileSkeleton } from '../../widgets/product-tile'
import { shallowEqual, useSelector } from 'react-redux'

import React from 'react'
import { SEARCHTERM } from '../../../constants/common'
import { StyledAnchor } from '../../StyledUI'
import styled from 'styled-components'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { useTranslation } from 'react-i18next'

const ContentViewAllProduct = styled.div<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  padding: ${theme.spacing(1)}px;

  font-size: 18px;
  text-align: right;

  background-color: ${
    themeMode === 'dark' ? theme.palette.background.dark : theme.palette.background.default
  };

  ${theme.breakpoints.up('sm')} {
    border-left: 1px solid ${theme.palette.primary.dark};
    border-right: 1px solid ${theme.palette.primary.dark};
  }
`}
`

const StyledLink = styled(StyledAnchor)<{ themeMode: string }>`
  color: ${({ theme, themeMode }) =>
    themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary};
`

const SearchProductSuggestions: React.FC<{ onClose; productSearch; loadingProduct }> = ({
  onClose,
  productSearch,
  loadingProduct,
}) => {
  const { t } = useTranslation()
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  return (
    <>
      <ContentViewAllProduct themeMode={themeMode}>
        {productSearch ? (
          <StyledLink
            themeMode={themeMode}
            data-element-id={'X_X_SearchPanel_Content_ViewAll'}
            customprops={{ isUnderlined: true, uppercase: false }}
            onClick={onClose}
            to={'/search?' + SEARCHTERM + '=' + productSearch.searchTerm}>
            {t('SearchBar.ViewAllProducts')} ({productSearch.total})
          </StyledLink>
        ) : (
          <span>&nbsp;</span>
        )}
      </ContentViewAllProduct>
      <ContentPlp>
        {!loadingProduct ? (
          <>
            {productSearch.contents.map((item, index) => (
              <StyledGridProductLayout key={item.idx}>
                <StyledProductWrap>
                  <ProductTile product={item} tileIndex={index} onClick={onClose} />
                </StyledProductWrap>
              </StyledGridProductLayout>
            ))}
          </>
        ) : (
          <>
            {new Array(4).fill(1).map((_, index) => (
              <StyledGridProductLayout key={`_loading_skeleton_${index}`}>
                <StyledProductWrap>
                  <ProductTileSkeleton />
                </StyledProductWrap>
              </StyledGridProductLayout>
            ))}
          </>
        )}
      </ContentPlp>
    </>
  )
}

export default SearchProductSuggestions
