import styled from 'styled-components'
import { StyledSwipeableDrawer } from '../../StyledUI'

const StyledMyAccountDrawer = styled(StyledSwipeableDrawer)`
  ${({ theme }) => `
    .filters-menu-content {
      max-width: 100vw;
      width:100%;
      padding:${theme.spacing()}px;
      .filters-menu-title {
        margin-bottom: ${theme.spacing()}px;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
      }
      .filters-menu-clear {
        font-size:0.8rem;
        text-transform:uppercase;
        color: ${theme.palette.custom.black}
      }
      ${theme.breakpoints.up('md')} {
        width: 470px;
        padding:${theme.spacing(2)}px;
      }
    }
  `}
`

export default StyledMyAccountDrawer
