import React, { ComponentProps, FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getCTAToUrl, getCTAVariant } from '../../../../utils/cta'
import { ICallToAction } from '../../../../types/teaser'
import { StyledLinkAsButton } from '../../../StyledUI'
import { themeModeSelector } from '../../../../redux/selectors/theme'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'

type TStyledCTAProps = Omit<ComponentProps<typeof StyledLinkAsButton>, 'to'> & {
  className?: string
}

const CallToAction: FC<ICallToAction & TStyledCTAProps> = (props) => {
  const { basePath } = useStoreIdentity()
  const globalThemeMode = useSelector(themeModeSelector, shallowEqual)
  const themeMode = props.themeMode ? props.themeMode : globalThemeMode
  const isExternal = ['CMDownload', 'CMExternalLink'].includes(props.target.type)

  return (
    <StyledLinkAsButton
      {...props}
      themeMode={themeMode}
      aria-label={props.callToActionText || 'Placement_Banner_CTA'}
      variant={getCTAVariant(props)}
      disabled={!props.callToActionEnabled}
      external={isExternal}
      to={getCTAToUrl(basePath, props)}>
      {props.callToActionText}
    </StyledLinkAsButton>
  )
}

export default CallToAction
