import { AxiosPromise, AxiosRequestConfig, Method } from 'axios'
import RequestService from '../../../services/RequestService'
import { executeRequest } from '../../axios/axiosConfig'
import { getCookieByName } from '../../../utils/cookie'
import { getSite } from '../../hooks/useSite'

const paymentInstructionService = {

  /**
   * Invalidate the addresses added during the apple pay checkout flow
   * `@method`
   * `@name invalidateExpressAdresses`
   */
  invalidateExpressAdresses() {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    return RequestService.request({
      method: 'GET',
      path: '/store/{storeId}/person/@self/contact/invalidateexpressadresses',
      body: { userAgent, forterToken },
    })
  },

  /**
   * Adds payment instruction to the shopping cart.
   * `@method`
   * `@name PaymentInstruction#addPaymentInstruction`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   ** `@property {any} body ` body data required for rest method
   */
  addPaymentInstruction(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/cart/@self/payment_instruction'
    let requestUrl = domain + path
    let method: Method = 'POST'
    let form: any = {}
    let body = {
      userAgent,
      forterToken,
    }
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/cart/@self/payment_instruction missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['body'] !== undefined) {
      body = { ...body, ...parameters['body'] }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = { ...body, ...formData }
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = { ...body, ...formParams }
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  /**
   * Deletes all payment Instructions from the shopping cart.
   * `@method`
   * `@name PaymentInstruction#deleteAllPaymentInstructions`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  deleteAllPaymentInstructions(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/cart/@self/payment_instruction'
    let requestUrl = domain + path
    let method: Method = 'DELETE'
    let form: any = {}
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/cart/@self/payment_instruction missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = { ...body, ...formParams }
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },
  /**
   * get all payment Instructions from the shopping cart.
   * `@method`
   * `@name PaymentInstruction#deleteAllPaymentInstructions`
   *
   * `@param {any} headers (optional)` will add headers to rest request
   *
   * `@param {string} url (optional)` will override the default domain used by the service. Url can be relative or absolute
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} storeId (required)` The child property of `Parameters`.The store identifier.
   ** `@property {string} responseFormat ` The response format. If the request has an input body, that body must also use the format specified in "responseFormat". Valid values include "json" and "xml" without the quotes. If the responseFormat isn't specified, the "accept" HTTP header shall be used to determine the format of the response. If the "accept" HTTP header isn't specified as well, the default response format shall be in json.
   */
  getAllPaymentInstructions(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/cart/@self/payment_instruction'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let form: any = {}
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/cart/@self/payment_instruction missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },
  getPaypalPaymentInfo(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/paypal/sessionMerchantUrl/@self'
    let requestUrl = domain + path
    let method: Method = 'POST'
    let form: any = {}
    let body = {
      userAgent,
      forterToken,
    }
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/paypal/sessionMerchantUrl/@self missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageNumber'] !== undefined) {
      const name = 'pageNumber'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['pageSize'] !== undefined) {
      const name = 'pageSize'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['orderId'] !== undefined) {
      const name = 'orderId'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['successUrl'] !== undefined) {
      const name = 'successUrl'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['cancelUrl'] !== undefined) {
      const name = 'cancelUrl'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = { ...body, ...formData }
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = { ...body, ...formParams }
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },
  calculateTax(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/cart/calculate'
    let requestUrl = domain + path
    let method: Method = 'POST'
    let body = {
      userAgent,
      forterToken,
      x_calculateOrder: 1,
      calculationUsageId: [-1, -2, -3, -4, -5, -6, -7],
      orderId: '.',
    }
    let header: Headers
    let queryParameters = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error('Request /store/{storeId}/cart/calculate missing path parameter storeId')
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )
    return executeRequest(requestOptions)
  },
  setupPayerAuthentication(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/payerAuthentication/paSetup/@self'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/payerAuthentication/paSetup/@self missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )
    return executeRequest(requestOptions)
  },
  checkPayerAuthenticationEnroll(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/payerAuthentication/paCheckEnroll/@self'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/payerAuthentication/paCheckEnroll/@self missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }
    if (parameters['body'] !== undefined) {
      body = { ...body, ...parameters['body'] }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },

  signPaymentData(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/paypal/signPaymentData/@self'
    let requestUrl = domain + path
    let method: Method = 'GET'
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/paypal/signPaymentData/@self missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    queryParameters.sort()
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )
    return executeRequest(requestOptions)
  },
  updatePaymentInstructions(parameters: any, headers?: any, url?: string): AxiosPromise<any> {
    let site = getSite()
    let siteContext: string = ''
    if (site) {
      siteContext = site.transactionContext || ''
    }
    let domain = url || siteContext
    let path = '/store/{storeId}/cart/@self/payment_instruction'
    let requestUrl = domain + path
    let method: Method = 'PUT'
    let form: any = {}
    let body = {}
    let header: Headers
    let queryParameters = new URLSearchParams()
    let formParams = new URLSearchParams()
    if (typeof headers === 'undefined' || headers === null) {
      header = new Headers()
    } else {
      header = new Headers(headers)
    }
    if (parameters === undefined) {
      parameters = {}
    }
    if (parameters['storeId'] === undefined && site !== null) {
      parameters['storeId'] = site.storeID
    }
    let headerValues: any = {}
    headerValues['Accept'] = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (let val of headerValues['Accept']) {
      header.append('Accept', val)
    }
    if (parameters['storeId'] === undefined) {
      throw new Error(
        'Request /store/{storeId}/cart/@self/payment_instruction missing path parameter storeId'
      )
    }
    requestUrl = requestUrl.replace('{storeId}', parameters['storeId'])

    if (parameters['responseFormat'] !== undefined) {
      const name = 'responseFormat'
      const parameter = parameters[name]
      delete parameters[name]
      if (parameter instanceof Array) {
        parameter.forEach((value) => {
          queryParameters.append(name, value)
        })
      } else {
        queryParameters.set(name, parameter)
      }
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        const parameter = parameters.$queryParameters[parameterName]
        if (parameter !== null && parameter !== undefined) {
          queryParameters.set(parameterName, parameter)
        }
      })
    }
    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      let formData = new FormData()
      for (let p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (let p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }
    const headerObject: any = {}
    for (let headerPair of header.entries()) {
      headerObject[headerPair[0]] = headerPair[1]
    }
    queryParameters.sort()
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')

    body = { ...body, userAgent, forterToken }
    let requestOptions: AxiosRequestConfig = Object.assign(
      {
        params: queryParameters,
        method: method,
        headers: headerObject,
        data: body,
        url: requestUrl,
      },
      { ...parameters }
    )

    return executeRequest(requestOptions)
  },
  createKlarnaSession() {
    const { userAgent } = window.navigator
    const forterToken = getCookieByName('forterToken')
    return RequestService.request({
      body: { userAgent, forterToken },
      method: 'GET',
      path: '/store/{storeId}/klarna/create-session/@self',
    })
  },
}

export default paymentInstructionService
