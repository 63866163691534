import axios, { Method, AxiosRequestConfig, AxiosPromise, AxiosResponse, AxiosError } from 'axios'
import get from 'lodash/get'
import { API_CALL_ACTION } from '../redux/actions/api'
import { executeRequest } from '../foundation/axios/axiosConfig'
import { PRODUCTION, SHOW_API_FLOW } from '../foundation/constants/common'
import { getSite } from '../foundation/hooks/useSite'
import { localStorageUtil } from '../foundation/utils/storageUtil'
import { ServerErrorResponse } from '../types/common'

export interface PaginationResponse<T = any> {
  contents: T[]
  next?: string
  previous?: string
  total: number
}

export type RecordSetResponse<T> = T & {
  recordSetCount: string
  recordSetComplete: string
  recordSetStartNumber: string
  recordSetTotal: string
}

export interface RequestProps {
  body?: any
  extraParams?: {
    siteContextKey?: 'dx' | 'search' | 'transaction'
    [key: string]: any
  }
  headers?: any
  method: Method
  path: string
  pathParams?: Record<string, any>
  queryParams?: Record<string, any>
}

const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

class RequestService {
  axiosRequest<T = any>({
    body: bodyParam,
    extraParams = {},
    headers,
    method,
    path,
    pathParams: pathParamsProp = {},
    queryParams: queryParamsProp = {},
  }: RequestProps): AxiosPromise<T> {
    const site = getSite()
    const siteContextKey = extraParams.siteContextKey || 'transaction'
    const siteContext: string = site ? site[siteContextKey + 'Context'] || '' : ''
    const domain = extraParams.url || siteContext
    let requestUrl = domain + path
    const form: any = {}
    let body = bodyParam || {}
    let header: Headers
    const queryParams = new URLSearchParams()
    const formParams = new URLSearchParams()
    const pathParams = Object.assign({}, pathParamsProp)

    if (!pathParams.storeId && site?.storeID) {
      pathParams.storeId = site.storeID

      if (!path.includes('{storeId}')) {
        queryParams.set('storeId', site.storeID)
      }
    } else {
      throw new Error(`Request '${path}' missing path parameter storeId`)
    }

    requestUrl = this.fillUrlWithPathParams(requestUrl, pathParams)

    for (const queryParam in queryParamsProp) {
      const queryParamValue = queryParamsProp[queryParam]
      if (queryParamValue === undefined) {
        continue
      }

      if (Array.isArray(queryParamValue)) {
        queryParamValue.forEach((value) => {
          queryParams.append(queryParam, value)
        })
      } else {
        queryParams.set(queryParam, queryParamValue)
      }
    }
    queryParams.sort()

    const formattedExtraParams = {} as typeof extraParams
    for (const key in extraParams) {
      if (Object.prototype.hasOwnProperty.call(extraParams, key)) {
        const param = extraParams[key]

        if (param !== undefined) {
          formattedExtraParams[key] = param
        }
      }
    }

    header =
      typeof headers === 'undefined' || headers === null ? new Headers() : new Headers(headers)
    const acceptHeaderValues = [
      'application/json',
      'application/xml',
      'application/xhtml+xml',
      'application/atom+xml',
    ]
    for (const value of acceptHeaderValues) {
      header.append('Accept', value)
    }

    if (!header.get('Content-Type')) {
      header.append('Content-Type', 'application/json; charset=utf-8')
    }
    const accept = header.get('Accept')
    if (accept !== null && accept.indexOf('application/json') > -1) {
      header.set('Accept', 'application/json')
    }
    if (header.get('content-type') === 'multipart/form-data' && Object.keys(form).length > 0) {
      const formData = new FormData()
      for (const p in form) {
        if (form[p].name !== undefined) {
          formData.append(p, form[p], form[p].name)
        } else {
          formData.append(p, form[p])
        }
      }
      body = formData
    } else if (Object.keys(form).length > 0) {
      header.set('content-type', 'application/x-www-form-urlencoded')
      for (const p in form) {
        formParams.append(p, form[p])
      }
      formParams.sort()
      body = formParams
    }

    const headersObject = {}
    for (const headerPair of header.entries()) {
      headersObject[headerPair[0]] = headerPair[1]
    }

    const requestOptions: AxiosRequestConfig = Object.assign(
      {
        data: body,
        headers: headersObject,
        method,
        params: queryParams,
        url: requestUrl,
      },
      { ...formattedExtraParams }
    )

    const showAPIFlow =
      process.env.NODE_ENV !== PRODUCTION ? localStorageUtil.get(SHOW_API_FLOW) === 'true' : false
    if (showAPIFlow) {
      const from = formattedExtraParams.widget ? formattedExtraParams.widget : 'Browser'
      const store = require('../redux/store').default
      if (store) {
        store.dispatch(
          API_CALL_ACTION(
            `${from} -> ${capitalize(siteContextKey)}: ${method} ${requestUrl}?${queryParams}`
          )
        )
      }
    }

    return executeRequest<T>(requestOptions)
  }

  fillUrlWithPathParams(url: string, params: Record<string, string>): string {
    let returnUrl = url

    for (const key in params) {
      returnUrl = returnUrl.replace(`{${key}}`, params[key])
    }

    return returnUrl
  }

  fillUrlWithQueryAndPathParams(
    url: string,
    pathParams: RequestProps['pathParams'] = {},
    queryParams: RequestProps['queryParams'] = {}
  ): string {
    const returnUrl = new URL(this.fillUrlWithPathParams(url, pathParams))

    for (const queryParam in queryParams) {
      const queryParamValue = queryParams[queryParam]
      if (queryParamValue === undefined) {
        continue
      }

      if (Array.isArray(queryParamValue)) {
        queryParamValue.forEach((value) => {
          returnUrl.searchParams.append(queryParam, value)
        })
      } else {
        returnUrl.searchParams.set(queryParam, queryParamValue)
      }
    }

    // returnUrl.searchParams.sort()

    return returnUrl.toString()
  }

  getRecordSetData(
    pageSize: number,
    { recordSetCount, recordSetTotal, recordSetStartNumber }: RecordSetResponse<{}>
  ): {
    currentPage: number
    numberOfPages: number
  } {
    return {
      currentPage: Math.ceil((+recordSetCount + +recordSetStartNumber) / pageSize),
      numberOfPages: Math.ceil(+recordSetTotal / pageSize),
    }
  }

  retrieveAxiosErrorFromResponse(axiosError: AxiosError<ServerErrorResponse>) {
    if (!(axiosError && axios.isAxiosError(axiosError))) {
      return null
    }

    const errorCode: string = get(axiosError, 'response.data.errors[0].errorCode', '')
    const errorKey: string = get(axiosError, 'response.data.errors[0].errorKey', '')
    const errorMessage: string = get(axiosError, 'response.data.errors[0].errorMessage', '')
    const errorParameters: string = get(axiosError, 'response.data.errors[0].errorParameters', '')

    return { errorCode, errorKey, errorMessage, errorParameters }
  }

  async request<T = any>(props: RequestProps) {
    const axiosResponse: AxiosResponse<T> = await this.axiosRequest(props)

    return axiosResponse.data
  }

  async requestForRtk<T = any>(props: RequestProps) {
    const axiosResponse: AxiosResponse<T> = await this.axiosRequest(props)
    return axiosResponse
  }
}

export default new RequestService()
