import styled from 'styled-components'
import theme from '../../../themes'
import { NextIcon, PreviousIcon } from '../../StyledUI/Icons/Icons'

export const StyledBar = styled.div<{ bannerTextLength?: boolean, visible?: boolean }>`
  position: relative;
  height: ${({ bannerTextLength, visible }) => visible ? !bannerTextLength ? '46px' : '30px' : '0px'};
  background: ${({ theme }) => theme.palette.custom.geckoGreen};
  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};

  ${theme.breakpoints.up('sm')} {
    height: ${({ bannerTextLength, visible }) => visible ? !bannerTextLength ? '46px' : '32px' : '0px'};
    justify-content: space-around !important;
  }

  ${theme.breakpoints.up('md')} {
    height: ${({ visible }) => visible ? '32px' : '0px'};
    justify-content: space-evenly;
  }
`


export const StyledPromoWrapper = styled.div<{ bannerTextLength?: boolean }>`
  display: flex;
`

export const StyledLeftIcon = styled(PreviousIcon)<{ bannerTextLength?: boolean }>`
  cursor: pointer;
  ${theme.breakpoints.between('sm', '1440')} {
    position: absolute;
    left: ${({ bannerTextLength }) => (bannerTextLength ? '10px' : '20px')};
  }
  ${theme.breakpoints.between('1440', 'lg')} {
    position: absolute;
    left: ${({ bannerTextLength }) => (bannerTextLength ? '10px' : '240px')};
  }
`;

export const StyledRightIcon = styled(NextIcon)<{ bannerTextLength?: boolean }>`
  cursor: pointer;
  ${theme.breakpoints.between('sm', '1440')} {
    position: absolute;
    right: ${({ bannerTextLength }) => (bannerTextLength ? '10px' : '20px')};
  }
  ${theme.breakpoints.between('1440', 'lg')} {
    position: absolute;
    right: ${({ bannerTextLength }) => (bannerTextLength ? '10px' : '240px')};
  }
`;


export const StyledPromoContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  color: ${theme.palette.secondary.contrastText};

  a {
    color: ${theme.palette.secondary.contrastText};
    font-size: 0.7rem;
  }

  ${theme.breakpoints.up('xs')} {
    font-size: 0.75rem;
    
    a {
      font-size: 0.75rem;
    }
  }

  ${theme.breakpoints.up('md')} {
    white-space: nowrap;
    gap: 10px;
    
    a {
      font-size: 1rem;
    }
  }
`

export const Title = styled.div`
  font-size: inherit;
  align-items: center;
  gap: 5px;
  white-space: normal;
  font-size: 0.75rem;

  a {
    text-decoration: underline;
    font-weight: normal;
    white-space: nowrap;
  }

  span {
    font-weight: bold;
    white-space: nowrap;
  }

  p {
    margin: 0;
    line-height: 1.5;
  }

  strong {
    margin: 0;
    line-height: 1.5;
  }

  div:first-child {
    overflow-wrap: break-word;
    word-wrap: break-word;
    width: 335px;
    text-align: center;
    line-height: 1.5;
    font-size: 0.75rem;
  }

  ${theme.breakpoints.up('md')} {
    word-break: unset;
    white-space: unset;
    font-size: 1rem;

    div:first-child {
      overflow-wrap: unset;
      word-wrap: unset;
      width: unset;
      text-align: unset;
      line-height: unset;
      font-size: unset;
    }

    a {
      text-decoration: underline;
      font-weight: normal;
      white-space: unset;
    }
  
    span {
      font-weight: bold;
      white-space: unset;
    }
  }
`

export const CountdownTime = styled.span`
font-weight: bold;
text-transform: uppercase;
//visibility: hidden;
${theme.breakpoints.up('md')} {
font-size: 1rem;
}
`
