import { useState, useEffect, ScriptHTMLAttributes } from 'react'
import { fetchJsFromCDN } from './../../utils/fetchFromCdn'

type UseExternalScript = (
  src: string,
  windowKey: string,
  attributes: ScriptHTMLAttributes<HTMLScriptElement> & {
    id: string
    [key: string]: string
  }
) => [isLoaded, scriptObject]

const useExternalScript: UseExternalScript = (src, key, attributes) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  const handleScriptLoad = async () => {
    const isAlreadyLoaded = !!document.getElementById(attributes.id)

    if (!isAlreadyLoaded) {
      await fetchJsFromCDN(src, key, attributes)
    }
    setIsLoaded(true)
  }

  useEffect(() => {
    void handleScriptLoad()
  }, [])

  return [isLoaded, window[key]]
}

export default useExternalScript
