import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ChangePasswordForm = styled.form`
  ${({ theme }) => `
  width: 100%;

  .password-reqs__list {
    margin-bottom: ${theme.spacing(1)}px;
  }

  button[type="submit"] {
    margin-top: ${theme.spacing(2)}px;
  }

  & > div > p {
    margin-right: 0;
    margin-left: 0;
  }

  & > div:nth-of-type(1) {
    margin-bottom: 25px;
  }

  & > div:nth-of-type(2) {
    margin-bottom: 18px;

    ${theme.breakpoints.down('sm')} {
      margin-bottom: 1.5rem;
    }
  }

  ${theme.breakpoints.up('sm')} {
    width: 400px;
  }
`}
`

export const StyledContentWrapper = styled.div`
  width: 100%;

  border: 1px solid ${({ theme }) => theme.palette.text.primary};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`

export const StyledBodyHeader = styled.div`
  padding: 1.5rem 36px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.text.primary};

  text-transform: uppercase;
`

export const StyledErrorMessage = styled.p`
  margin-top: 0;
  margin-bottom: 19px;

  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.text.error};
`

export const StyledBodyContainer = styled.div`
  padding: 32px 36px;

  div > button,
  form > button {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 100%;
    }
  }
`

export const StyledBodySuccessContainer = styled.div`
  font-size: 0.875rem;

  & > :first-child {
    margin-top: 0;
    margin-bottom: 2rem;
  }
`

export const StyledBackButton = styled(Link)`
  padding: 15px 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 250px;
  height: 40px;

  border: 1px solid #000000;
  border-radius: 50vmax;

  cursor: pointer;

  font-family: inherit;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.palette.text.contrastText};

  line-height: normal;
  text-align: inherit;
  text-transform: uppercase;
  vertical-align: bottom;

  background-color: ${({ theme }) => theme.palette.text.primary}
`
