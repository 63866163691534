import { createAction } from '@reduxjs/toolkit'
import * as ACTIONTYPES from '../action-types/order'
import { SHIPINFO_GET_REQUESTED } from '../action-types/order'
import { PaymentInfoType } from '../../types/checkout'
import { OrderActionsPayload } from '../../types/orderActionsPayload'

const ADDING_ITEM_ACTION = createAction<any, string>(ACTIONTYPES.ADDING_ITEM)
const ADD_ITEM_ACTION = createAction<
  OrderActionsPayload.AddItem,
  typeof ACTIONTYPES.ITEM_ADD_REQUESTED
>(ACTIONTYPES.ITEM_ADD_REQUESTED)
const ADD_LENS_ACTION = createAction<any, string>(ACTIONTYPES.LENS_ADD_REQUESTED)
const deleteOrderItemAndFetchCartAction =
  createAction<OrderActionsPayload.DeleteOrderItemAndFetchCart>(
    ACTIONTYPES.DELETE_ORDER_ITEM_AND_FETCH_CART_ACTION
  )

/**
 * The action set fetching state to true.
 */
const FETCHING_CART_ACTION = createAction<any, string>(ACTIONTYPES.CART_FETCHING_REQUESTED)
const GET_CART_ACTION = createAction<any, string>(ACTIONTYPES.CART_GET_REQUESTED)
const CART_UPDATED_SUCCESS_ACTION = createAction<any, string>(ACTIONTYPES.CART_UPDATED_SUCCESS)

const GET_SHIPINFO_ACTION = createAction<
  OrderActionsPayload.GetShipInfo,
  typeof SHIPINFO_GET_REQUESTED
>(SHIPINFO_GET_REQUESTED)

const GET_PAYMETHODS_ACTION = createAction<OrderActionsPayload.GetUsablePaymentInfo, string>(
  ACTIONTYPES.PAYMETHODS_GET_REQUESTED
)

const RESET_CART_ACTION = createAction(ACTIONTYPES.CART_RESET_REQUESTED)

const SHIPPING_AS_BILLING_TOGGLE_ACTION = createAction<boolean>(
  ACTIONTYPES.SHIPPING_AS_BILLING_TOGGLE
)
const BILLING_FORM_VALID_STATUS_ACTION = createAction<{
  isValid: boolean
}>(ACTIONTYPES.BILLING_FORM_VALID_STATUS)

const SHIPPING_FORM_VALID_STATUS_ACTION = createAction<{
  isValid: boolean
}>(ACTIONTYPES.SHIPPING_FORM_VALID_STATUS)

const GET_PAYPAL_SIGNATURE_DATA_ACTION = createAction<any, string>(
  ACTIONTYPES.PAYPAL_SIGNATURE_DATA_GET_REQUESTED
)

const PAYPALEXPRESS_TOGGLE_ACTION = createAction<any, string>(ACTIONTYPES.PAYPALEXPRESS_TOGGLE)

const CART_TOGGLE_ORDER_COMPLETE_ACTION = createAction<boolean>(
  ACTIONTYPES.CART_TOGGLE_ORDER_COMPLETE
)

const GET_PAYPALEXPRESS_ACTION = createAction<any, string>(ACTIONTYPES.PAYPALEXPRESS_GET_REQUESTED)

const PAYPALEXPRESS_CHECK_STATUS_GET_REQUESTED_ACTION = createAction<any>(
  ACTIONTYPES.PAYPALEXPRESS_CHECK_STATUS_GET_REQUESTED
)

const FINALIZE_ORDER_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.ORDER_FINALIZATION_GET_SUCCESS
)
const ORDER_PAYMENT_METHOD_UPDATE_ACTION = createAction<PaymentInfoType[]>(
  ACTIONTYPES.ORDER_PAYMENT_METHOD_UPDATE
)

const SHIPMODE_UPDATE_REQUESTED_ACTION = createAction<any>(ACTIONTYPES.SHIPMODE_UPDATE_REQUESTED)

const NEWS_LETTER_SUBSCRIBE_CHECKED_ACTION = createAction<boolean>(ACTIONTYPES.NEWS_LETTER_SUBSCRIBE_CHECKED_STATUS)

const EDIT_LENSES_ACTION = createAction<boolean>(ACTIONTYPES.EDIT_LENSES_STATUS)

const IMAGE_RX_URL_ACTION = createAction<string | undefined>(ACTIONTYPES.IMAGE_RX_URL_STATUS)

const TAXES_CALCULATION_REQUESTED_ACTION = createAction<{
  orderId:string
  zipCode:string
  country:string
}>(ACTIONTYPES.TAXES_CALCULATION_GET_REQUESTED)

export {
  ADD_ITEM_ACTION,
  ADD_LENS_ACTION,
  deleteOrderItemAndFetchCartAction,
  ADDING_ITEM_ACTION,
  FETCHING_CART_ACTION,
  GET_CART_ACTION,
  GET_SHIPINFO_ACTION,
  GET_PAYMETHODS_ACTION,
  CART_UPDATED_SUCCESS_ACTION,
  RESET_CART_ACTION,
  SHIPPING_AS_BILLING_TOGGLE_ACTION,
  GET_PAYPAL_SIGNATURE_DATA_ACTION,
  PAYPALEXPRESS_TOGGLE_ACTION,
  GET_PAYPALEXPRESS_ACTION,
  CART_TOGGLE_ORDER_COMPLETE_ACTION,
  BILLING_FORM_VALID_STATUS_ACTION,
  SHIPPING_FORM_VALID_STATUS_ACTION,
  PAYPALEXPRESS_CHECK_STATUS_GET_REQUESTED_ACTION,
  FINALIZE_ORDER_SUCCESS_ACTION,
  ORDER_PAYMENT_METHOD_UPDATE_ACTION,
  SHIPMODE_UPDATE_REQUESTED_ACTION,
  NEWS_LETTER_SUBSCRIBE_CHECKED_ACTION,
  EDIT_LENSES_ACTION,
  IMAGE_RX_URL_ACTION,
  TAXES_CALCULATION_REQUESTED_ACTION
}
