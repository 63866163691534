import {
  AccountReducerState,
  ApiReducerState,
  CatalogReducerState,
  ContextReducerState,
  ErrorReducerState,
  OrderDetailsReducerState,
  OrderReducerState,
  SearchReducerState,
  SingleSortOrderOption,
  ThemeReducerState,
  UIReducerState,
} from './reducerStateInterface'

import { RootReducerState } from '.'
import { WishListState } from '../../features/wishList/slice'
import { GROUPING_PROFILE_NAME } from '../../constants/common'

const accountDefaultState: AccountReducerState = {
  orders: null,
}

const apiDefaultState: ApiReducerState = {
  apiFlowList: [],
}

export const PLP_DEFAULT_SORT_OPTION: SingleSortOrderOption = {
  profileName: GROUPING_PROFILE_NAME,
}

const catalogDefaultState: CatalogReducerState = {
  facets: null,
}

const errorDefaultState: ErrorReducerState = {
  errorKey: null,
  errorCode: null,
  errorTitleKey: null,
  errorMsgKey: null,
  handled: null,
  errorMessage: '',
  errorParameters: '',
}

const orderDefaultState: OrderReducerState = {
  cart: null,
  numItems: 0,
  orderItems: [],
  catentries: null,
  isCheckoutDisabled: false,
  shipInfos: null,
  shipModes: [],
  payMethods: [],
  isRecurringOrderDisabled: false,
  isFetchingShippingInfo: false,
  isShippingUsedAsBilling: true,
  shippingFormStatus: {
    isValid: false,
  },
  billingFormStatus: {
    isValid: false,
  },
  paypalExpress: {
    isSelected: false,
    loading: false,
    redirecturl: '',
    decision: '',
    orderId: '',
    error: true,
  },
  orderComplete: false,
  isFinalizing: false,
  isFinalizationFailed: false,
  selectedPayMethodInfoList: [],
  isRXProductsLimitExceeded: false,
  isNewsletterSubscribeChecked: false,
  isEditLenses: false,
  estimatingTaxes: false,
  estimatedTaxValue: null,
  totalSalesTax: undefined
}

const orderDetailsInitState: OrderDetailsReducerState = { data: {}, loadings: [] }

const searchDefaultState: SearchReducerState = {
  keywords: [],
}

const themeDefaultState: ThemeReducerState = {
  themeMode: 'light',
}

const uiDefaultState: UIReducerState = {
  filtersDrawerOpen: false,
  cartSummaryDrawerOpen: false,
  newsletterDrawerOpen: false,
  searchDrawerOpen: false,
  myAccountDrawerOpen: false,
  currentIdentifier: '',
  pageType: '',
  productNotificationAvailable: false,
  lastSelectedProduct: '',
  actualPasswordLogin: '',
}

const defaultStates: RootReducerState = {
  account: accountDefaultState,
  api: apiDefaultState,
  catalog: catalogDefaultState,
  confirmation: { key: '', title: '' },
  context: {} as ContextReducerState,
  contract: {},
  error: errorDefaultState,
  order: orderDefaultState,
  orderDetails: orderDetailsInitState,
  orderDetailsLoadings: {},
  organization: { entitledOrganizations: null, organizationDetails: null },
  success: { key: '' },
  seo: {},
  search: searchDefaultState,
  site: { currentSite: null },
  theme: themeDefaultState,
  ui: uiDefaultState,
  user: {
    initiatedFromStorage: false,
    WCToken: '',
    WCTrustedToken: '',
    details: null,
    areDetailsLoading: false,
  },
  wishList: {} as WishListState,
}

const clearState = (o: any) => {
  for (let variableKey in o) {
    if (o.hasOwnProperty(variableKey)) {
      delete o[variableKey]
    }
  }
}

const initStates = {
  ...defaultStates,
}

export { clearState, defaultStates, orderDetailsInitState }

export default initStates
