import React, { FC, memo, useEffect, useState } from 'react'
import { ICMExternalPage, ICallToAction, ILXTeaser } from '../../../types/teaser'
import { CountryBaseLink, StyledA, StyledLinkProps } from '../../StyledUI/StyledLink/StyledLink'
import { CountdownTime, StyledPromoContent, Title } from './style'
import cmsService from '../../../foundation/apis/cms/cms.service'
import useNewBreakpoints from '../../../hooks/useNewBreakpoints'

export const PromotionBarElement: FC<{
  teaser: ILXTeaser,
  promoIndex: number,
  currentPromoIndex?: number
  onCurrentCountDownTick?: (countdownTimeLeft: string, index: number) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onFocus?: () => void
  onBlur?: () => void
}> = ({
  teaser,
  promoIndex,
  currentPromoIndex,
  onCurrentCountDownTick,
  onMouseEnter,
  ...rest
}) => {
    const { teaserLXCallToActionSettings, teaserText2, teaserCountdownStart, teaserCountdownUntil } = teaser
    const [countdownTimeLeft, setCoundownTimeLeft] = useState<string>('')
    const target = teaserLXCallToActionSettings?.[0]?.target
    const callToActionTextMobile = teaserLXCallToActionSettings?.map(x => x?.callToActionText)
    const { isDesktop } = useNewBreakpoints()

    const contentMobile = [teaserText2.slice(0, -10),
    ` <a>${callToActionTextMobile && callToActionTextMobile}</a> `,
    `<span>${(teaserCountdownStart || teaserCountdownUntil) && (countdownTimeLeft || '')}</span>`, teaserText2.slice(-10)].join('')

    const titleProps = {
      className: 'arn-header__promo-content',
      dangerouslySetInnerHTML: { __html: !isDesktop ? contentMobile : teaserText2 },
    }

    useEffect(() => {
      if (teaser && teaserCountdownStart && teaserCountdownUntil)
        cmsService.initTeaserCountdownInterval(teaser, setCoundownTimeLeft)
    }, [teaser, teaserCountdownStart, teaserCountdownUntil])

    useEffect(() => {
      !!onCurrentCountDownTick && onCurrentCountDownTick(countdownTimeLeft, promoIndex)
    }, [countdownTimeLeft, currentPromoIndex, promoIndex])

    const PromoBarLink: FC<{
      target: ICallToAction['target']
      linkVariant: StyledLinkProps['$variant']
    }> = memo(({ children, target, linkVariant }) => {
      return target && target.type === 'CMExternalLink' ? (
        <StyledA href={target.url || ''} target="_blank" $variant={linkVariant}>
          {children}
        </StyledA>
      ) : (
        <CountryBaseLink to={target ? (target as ICMExternalPage).formattedUrl : ''} variant={linkVariant}>
          {children}
        </CountryBaseLink>
      )
    })
    return <StyledPromoContent data-element-id="X_X_MainNav_GlobalOffer" {...rest} onBlur={false}>
      <>
        {!isDesktop ? (
          <>
            {teaserLXCallToActionSettings.length === 0 ? (
              <Title {...titleProps} />
            ) : (
              <PromoBarLink linkVariant="primary" target={target}>
                <Title {...titleProps} />
              </PromoBarLink>
            )}
          </>
        ) : (
          <>
            {teaserLXCallToActionSettings.length === 0 ? (
              <Title {...titleProps} />
            ) : (
              <PromoBarLink linkVariant="primary" target={target}>
                <Title {...titleProps} />
              </PromoBarLink>
            )}

            {teaserLXCallToActionSettings.map(
              ({ callToActionEnabled, callToActionText, target }) =>
                callToActionEnabled &&
                callToActionText && (
                  <PromoBarLink linkVariant="primaryUnderlined" target={target}>
                    {callToActionText}
                  </PromoBarLink>
                )
            )}
            {(teaserCountdownStart || teaserCountdownUntil) && <CountdownTime>{countdownTimeLeft}</CountdownTime>}
          </>
        )}
      </>
    </StyledPromoContent>
  }
