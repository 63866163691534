import { ProductTile, ProductTileSkeleton } from '../product-tile'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { StyledGrid, StyledLoader } from '../../StyledUI'
import {
  StyledGridProductLayout,
  StyledLoaderContainer,
  StyledProductGridContainer,
  StyledProductWrap,
  StyledProductsCount,
} from './styles/ProductGrid.style'
import { useDispatch, useSelector } from 'react-redux'
import GridBannerItem from './GridBannerItem'
import { IPlacement } from '../../../types/teaser'
import LoadMoreButton from './LoadMoreButton'
import { PAGINATION_CONFIGS } from '../../../configs/catalog'
import { ProductBase } from '../../../types/product'
import { ProductTileProps } from '../product-tile/ProductTile'
import { SET_LAST_SELECTED_PRODUCT_ACTION } from '../../../redux/actions/ui'
import { lastSelectedProductSelector } from '../../../redux/selectors/ui'
import { useTranslation } from 'react-i18next'

const { pageLimit } = PAGINATION_CONFIGS

interface ProductGridViewProps {
  bannersData?: IPlacement[]
  currentPage: number
  isCatalogLoading: boolean
  isClustered?: boolean
  isPaginationLoading: boolean
  products: ProductBase[]
  productsCount: number
  productTileProps?: Partial<ProductTileProps>
  variant?: string
  onCurrentPageChange(pageOffset: number): void
}

/**
 * `@function ProductGridView`
 *
 * Component for a view-purpose only.
 * Accepts props for displaying products, pagination, 'load more' action.
 * Doesn't perform server calls on its own, uses callbacks to interact with layer responsible
 * for fetching and formatting data.
 */
const ProductGridView: FC<ProductGridViewProps> = ({
  bannersData,
  children,
  currentPage,
  isCatalogLoading,
  isClustered = true,
  isPaginationLoading,
  products,
  productsCount,
  productTileProps,
  variant,
  onCurrentPageChange,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectedProduct = useRef<HTMLDivElement | null>(null)

  const lastSelectedProduct = useSelector(lastSelectedProductSelector)
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(false)

  const pageCountTotal = useMemo<number>(
    () => Math.ceil(productsCount / pageLimit),
    [pageLimit, productsCount]
  )

  useEffect(() => {
    if (products.length < pageLimit) {
      setShowLoadMoreButton(false)
    }

    const pageCount = Math.ceil(products.length / pageLimit)

    setShowLoadMoreButton(pageCount < pageCountTotal)
  }, [isCatalogLoading, isPaginationLoading, products.length])

  useEffect(() => {
    if (!isCatalogLoading && selectedProduct.current) {
      selectedProduct.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })

      dispatch(SET_LAST_SELECTED_PRODUCT_ACTION(''))
      selectedProduct.current = null
    }
  }, [isCatalogLoading, selectedProduct.current])

  return (
    <div className="arn-plp__container" data-element-id="X_X_Tiles">
      {children}

      <StyledProductGridContainer>
        {isCatalogLoading && (currentPage <= 1 || productsCount < 1) && (
          <>
            {new Array(
              typeof productsCount === 'number' &&
              productsCount > 0 &&
              productsCount <= PAGINATION_CONFIGS.pageLimit
                ? productsCount
                : PAGINATION_CONFIGS.pageLimit
            )
              .fill(1)
              .map((_, index) => (
                <StyledGridProductLayout key={index}>
                  <StyledProductWrap>
                    <ProductTileSkeleton />
                  </StyledProductWrap>
                </StyledGridProductLayout>
              ))}
          </>
        )}

        {!(isCatalogLoading && currentPage === 1) && (
          <>
            {products.map((product, index) => (
              <StyledGridProductLayout
                key={product.id}
                ref={
                  !selectedProduct.current && lastSelectedProduct === product.id
                    ? selectedProduct
                    : undefined
                }>
                <StyledProductWrap id={`product_${product.id}`}>
                  <ProductTile
                    isClustered={isClustered}
                    product={product}
                    tileIndex={index}
                    {...productTileProps}
                    variant={variant ? variant : 'plp'}
                  />
                </StyledProductWrap>
              </StyledGridProductLayout>
            ))}

            {bannersData?.map((item, index) => {
              const controlBanner = item.viewtype === 'plp-box' ? 6 : 4
              const numBanners = Math.floor(products.length / controlBanner)
              if (index >= numBanners) return null

              return (
                <GridBannerItem
                  key={index}
                  data-element-id={`X_PLP_${item.name}_${item.items?.length ?? 0}-Banner`}
                  placement={item}
                />
              )
            })}
          </>
        )}

        {!isCatalogLoading && !products.length && (
          <StyledGrid item xs={12}>
            {t('ProductGrid.Labels.noProductsFoundForFilter')}
          </StyledGrid>
        )}
      </StyledProductGridContainer>

      {currentPage > 1 && isPaginationLoading && (
        <StyledLoaderContainer>
          <StyledLoader contained={true} />
        </StyledLoaderContainer>
      )}

      {!isCatalogLoading && productsCount >= 0 && (
        <StyledProductsCount>
          <div
            dangerouslySetInnerHTML={{
              __html: t('ProductGrid.Labels.pagination', {
                pageSize: products.length,
                total: productsCount,
              }),
            }}
          />
        </StyledProductsCount>
      )}

      {showLoadMoreButton && !isCatalogLoading && !isPaginationLoading && (
        <LoadMoreButton onPress={() => onCurrentPageChange(currentPage + 1)} />
      )}
    </div>
  )
}

export default ProductGridView
