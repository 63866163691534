import NeueHaas from './fonts/Neue-Haas-Unica-Pro.ttf'
import NeueHaasBold from './fonts/Neue-Haas-Unica-Pro-Bold.ttf'
const neueHaas = {
  fontFamily: 'NeueHaas',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Neue-Haas-Regular'),
    url(${NeueHaas}) format('truetype')
  `,
}
const neueHaasBold = {
  fontFamily: 'NeueHaas',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Neue-Haas-Bold'),
    url(${NeueHaasBold}) format('truetype')
  `,
}

const fontsArray = [neueHaas, neueHaasBold]

export default fontsArray
