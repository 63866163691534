import { DefaultValues, useForm as useReactHookForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  buildYupValidationSchema,
  FORM_I18N_FORM_FIELDS_INVALID_MSGS,
  FORM_I18N_INVALID_MSG_BASE,
} from '../constants/form'
import { CheckoutAddressFormField } from '../types/checkout'
import { AddressFormData } from '../types/form'
import { useYupValidationResolver } from '../utils/validationResolver'

export const useCheckoutForm = ({
  defaultValues,
  fields,
}: {
  defaultValues: DefaultValues<AddressFormData>
  fields: CheckoutAddressFormField[]
}): UseFormReturn<AddressFormData> => {
  const { t } = useTranslation()

  const form = useReactHookForm<AddressFormData>({
    defaultValues,
    mode: 'onTouched',
    resolver: useYupValidationResolver(
      buildYupValidationSchema<CheckoutAddressFormField>({
        formFields: fields,
        i18nInvalidMsgBase: FORM_I18N_INVALID_MSG_BASE,
        i18nFormFieldsInvalidMsgs: FORM_I18N_FORM_FIELDS_INVALID_MSGS,
        t,
      })
    ),
  })

  return form
}
