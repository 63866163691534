import { ICMHtml, ICMTeaser, ITeaserTargets } from '../../types/teaser'
import { shallowEqual, useSelector } from 'react-redux'

import { PersonalDataAdChoicesWrapper } from '../StyledUI/StyledFooter'
import React from 'react'
import { StyledA } from '../StyledUI'
import { themeModeSelector } from '../../redux/selectors/theme'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

const BylinePersonalInfo: React.FC<{ info: ICMHtml }> = ({ info }) => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  if (!info) return null

  return (
    <PersonalDataAdChoicesWrapper
      themeMode={themeMode}
      className="personal-info"
      dangerouslySetInnerHTML={{
        __html: info.html?.replace('"', ''),
      }}
    />
  )
}

const BylineChoice: React.FC<{ choice: ICMTeaser }> = ({ choice }) => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const { basePath } = useStoreIdentity()
  if (!choice) return null

  const teaserTarget: ITeaserTargets = choice?.teaserTargets?.[0]
  const link = `${basePath}${teaserTarget?.target?.formattedUrl}#${teaserTarget?.callToActionHash}`
  const defaultAriaLabel = `Footer_Byline link. ID №${choice.id}`

  return (
    <PersonalDataAdChoicesWrapper themeMode={themeMode}>
      <StyledA href={link} aria-label={choice.teaserTitle || defaultAriaLabel}>{choice.teaserTitle}</StyledA>
    </PersonalDataAdChoicesWrapper>
  )
}

export { BylinePersonalInfo, BylineChoice }
