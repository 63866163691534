//Redux
import { RootReducerState } from '../reducers'
import { createSelector } from '@reduxjs/toolkit'
import { currentIdentifier } from './ui'

const seoSelector = (state: RootReducerState) => {
  return state.seo || ''
}

const currentPageSeoDataSelector = createSelector(currentIdentifier, seoSelector, (i, seo) =>
  i ? seo[i] : undefined
)

export { seoSelector, currentPageSeoDataSelector }
