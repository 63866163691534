import styled from 'styled-components'

export const AppWrapper = styled.div<{ theme: any }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const AppMain = styled.main`
  max-width: 100vw;
`
