export const PRODUCT = 'product'
export const CATEGORY = 'category'
export const PURCHASE = 'purchase'
export const BILLING = 'billing'
export const CUSTOM_PRODUCT = 'customProduct'
export const SET_PAGE_TYPE = 'setPageType'
export const SEARCH = 'search'
export const ADD_CART_ROWS = 'addCartRows'
export const ADD_PRODUCT_DETAILS = 'addProductDetails'
export const ADD_PRODUCTS = 'addProducts'
export const ADD_PURCHASE_ROWS = 'addPurchaseRows'
export const TRACK_DATA = 'trackData'
