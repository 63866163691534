import { useEffect, useState } from 'react'

interface IPaginationOptions {
  pageSize?: number
}
function usePagination<T>(items: T[], options: IPaginationOptions) {
  const { pageSize = 10 } = options
  const [currentPage, setCurrentPage] = useState(1)
  const [paginatedItems, setPaginatedItems] = useState<T[]>([])

  const numberOfPages = Math.ceil(items.length / pageSize)

  useEffect(() => {
    const start = (currentPage - 1) * pageSize
    const end = start + pageSize
    setPaginatedItems(items.slice(start, end))
  }, [currentPage, items, pageSize])

  return {
    currentPage,
    setCurrentPage,
    numberOfPages,
    paginatedItems,
  }
}

export default usePagination
