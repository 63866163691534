import { Theme } from '@material-ui/core'
import MuiModal, { ModalProps as MuiModalProps } from '@material-ui/core/Modal'
import styled, { css } from 'styled-components'
import { ModalProps } from './Modal'
import StyledTypography from '../StyledTypography/StyledTypography'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'

export const StyledMuiModal = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledModalContainer = styled.div<{ isSticky?: boolean }>`
  ${({ theme, isSticky }) => `
  overflow: ${isSticky ? 'hidden' : 'auto'};
  max-height: ${isSticky ? '' : '100%'};
  background-color: ${theme.palette.custom.warmGrey};
  border: 1px solid ${theme.palette.custom.black};
  color: ${theme.palette.custom.black};

  ${theme.breakpoints.down('sm')} {
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.custom.warmGrey};
  }
  ${theme.breakpoints.up('sm')} {
    height: auto !important;
  }
`}
`

export const StyledCloseIconeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 0 16px;
`

export const StyledModalHeader = styled.header`
  ${({ theme }) => `
  display: flex;
  border-bottom: 1px solid ${theme.palette.custom.black};
  position: relative;
  z-index: 1;
  
  svg {
    cursor: pointer
  }
`}
`

export const StyledSubtitle = styled(StyledTypography)`
  font-size: 1rem;

  p {
    margin: 0px;
    line-height: 1.5;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 0.75rem;
  }
`

export const StyledModalContent = styled.main`
  ${({ theme }) => `
  & > * {
    padding: 26px 16px 16px;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.palette.custom.black};
    }
  }

  p {
    line-height: 1.5;

    &:first-child {
      margin-top: 0;
    }
  }

  a {
    color: ${theme.palette.custom.black};
    text-decoration: underline;
  }

  ul {
    padding-left: 18px;
    list-style: circle;
    list-style-position: outside;

    li {
      padding-left: 8px;

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  img {
    width: 100%;
  }

  ${getDesktopThemeBreakpoint(theme)} {
    & > * {
      padding: 24px;

      &:not(:last-child) {
        border-bottom: 1px solid ${theme.palette.custom.black};
      }
    }
  }
`}
`


export const StyledModalChildren = styled.main<{ isSticky?: boolean }>`
  ${({ isSticky }) => `
  overflow: ${isSticky ? 'auto' : 'hidden'};
  max-height: ${isSticky ? '80vh' : ''};
`}
`

type ModalVariantStylesGetter = (theme: Theme) => string

const MD: ModalVariantStylesGetter = (theme) => `
  ${StyledModalContainer} {
    width: 100%;
    height: 100%;

    ${getDesktopThemeBreakpoint(theme)} {
      width: 70%;
      max-width: 800px;
      height: unset;
    }
  }

  ${StyledModalHeader} {
    margin-left: 16px;
    margin-right: 16px;
    padding: 0 0 26px 0;
    border-bottom: 1px solid ${theme.palette.custom.black};
    font-size: 1rem;

    .modal__header-icon-container .MuiSvgIcon-root {
      width: 48px;
      height: 48px;
    }

    .modal__header-icon-container .arn-icon {
      background-repeat: no-repeat;
      background-size: cover;
    }

    .modal__header-close-icon.MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      margin-left: auto;
      cursor: pointer;
    }

    .modal__title {
      font-size: 1rem;
    }

    ${getDesktopThemeBreakpoint(theme)} {
      margin-left: 0;
      margin-right: 0;
      padding: 16px;

      .modal__title {
        margin-bottom: 4px;
        font-size: 2rem;
        line-height: 1;
      }
    }
  }

  ${StyledSubtitle} {
    font-size: 0.75rem;

    p {
      margin: 0px;
      line-height: 1.5;
    }

    ${getDesktopThemeBreakpoint(theme)} {
      font-size: 1rem;
    }
  }
}`

const SM: ModalVariantStylesGetter = ({ breakpoints, spacing }) => `
  ${StyledModalContainer} {
    ${getDesktopThemeBreakpoint({ breakpoints })} {
      width: 458px;
      max-height: 94vh;
    }
  }

  ${StyledModalHeader} {
    flex-direction: column-reverse;
    padding: ${spacing(1)}px ${spacing(1)}px ${spacing(1.5)}px ${spacing(1.875)}px;
    font-size: 1rem;

    .modal__header-close-icon {
      align-self: flex-end;
    }
  }

  ${StyledModalChildren} {
    display: flex;
    flex-direction: column;
    gap: ${spacing(0.625)}px;
    padding: ${spacing(1)}px ${spacing(1.875)}px ${spacing(2)}px;
  }
}`

export const MODAL_VARIANTS_MAP = {
  md: MD,
  sm: SM,
} as const

const styles = css<MuiModalProps & Pick<ModalProps, 'variant'>>`
  ${({ theme, variant = 'md' }) => MODAL_VARIANTS_MAP[variant](theme)}
`

export const StyledMuiModalWithVariant = styled(StyledMuiModal) <
  MuiModalProps & Pick<ModalProps, 'variant'>
>`
  ${styles}
`
