import { RootReducerState } from '../reducers'

const uiSelector = (state: RootReducerState) => {
  return state.ui
}

const filtersDrawerSelector = (state: RootReducerState) => {
  return state.ui?.filtersDrawerOpen
}

const cartSummaryDrawerSelector = (state: RootReducerState) => {
  return state.ui?.cartSummaryDrawerOpen
}

const newsletterDrawerSelector = (state: RootReducerState) => {
  return state.ui?.newsletterDrawerOpen
}

const searchDrawerSelector = (state: RootReducerState) => {
  return state.ui?.searchDrawerOpen
}

const productNotificationAvailableSelector = (state: RootReducerState) => {
  return state.ui?.productNotificationAvailable
}

const myAccountDrawerSelector = (state: RootReducerState) => {
  return state.ui?.myAccountDrawerOpen
}

const currentIdentifier = (state: RootReducerState) => state.ui?.currentIdentifier

const lastSelectedProductSelector = (state: RootReducerState) => state.ui?.lastSelectedProduct

const actualPasswordLoginSelector = (state: RootReducerState) => state.ui?.actualPasswordLogin

export {
  uiSelector,
  currentIdentifier,
  filtersDrawerSelector,
  searchDrawerSelector,
  myAccountDrawerSelector,
  newsletterDrawerSelector,
  cartSummaryDrawerSelector,
  productNotificationAvailableSelector,
  lastSelectedProductSelector,
  actualPasswordLoginSelector
}
