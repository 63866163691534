import Log from '../services/Log'
import { OrderItem, OrderItemWithRoxProps } from '../types/order'
import { parseRxOrderItems } from './isRxOrder'
import { isSunProduct } from './product'
import { isRox } from './rx'

export const orderHasPrescriptionUploaded = (
  oi: OrderItem | OrderItemWithRoxProps
): boolean => {
  try {
    return !!oi.prescriptionDetails
  } catch (e) {
    return false
  }
}

export const formatOrderRecapItemPrices = (orderItem: OrderItem) => {
  let productUnitPrice: number | null = null
  let productOrderItemPrice: number | null = null
  let x_offerpriceRx: number | null = null
  let x_offerDiscountpriceRx: number | null = null

  try {
    productOrderItemPrice = orderItem?.groupedItem
      ? parseFloat(
        sum([
          Number(orderItem.orderItemPrice),
          Number(orderItem.sibilingOrderItem?.orderItemPrice),
        ]).toFixed(2)
      ) || null
      : parseFloat(orderItem.orderItemPrice) || null
    productUnitPrice = orderItem.unitPrice
      ? parseFloat(orderItem.unitPrice)
      : null
    x_offerpriceRx = orderItem?.x_offerpriceRx
      ? parseFloat(orderItem.x_offerpriceRx)
      : null
    x_offerDiscountpriceRx = orderItem?.x_offerDiscountpriceRx
      ? parseFloat(orderItem?.x_offerDiscountpriceRx)
      : null
  } catch (e) {
    Log.error('Could not parse order item price')
  }

  return {
    productUnitPrice,
    productOrderItemPrice,
    x_offerpriceRx,
    x_offerDiscountpriceRx,
  }
}

export const getPromoCodeDiscount = (orderItem?: OrderItem | null) => {
  if (!orderItem) return 0
  const discounts = getDiscounts(orderItem)

  const promoCode = discounts.find(
    ({ code }) => !code.includes('CL_CUSTOM_PROMO')
  )

  return promoCode ? Number(promoCode?.amount.replace('-', '')) : 0
}

export const isDiscountOnItemLevel = (orderItem?: OrderItem | null) => {
  if (!orderItem) return false
  const discounts = getDiscounts(orderItem)

  const promoCode = discounts.find(
    ({ code }) => !code.includes('CL_CUSTOM_PROMO')
  )

  return promoCode?.displayLevel === 'OrderItem'
}

const getDiscounts = (orderItem?: OrderItem | null) => {
  const discounts =
    orderItem?.adjustment?.filter(
      ({ usage }) => usage !== 'Shipping Adjustment'
    ) || []

  return discounts
}


/**
 * function to parse order items in cart recap according to product type
 * @param { Cart } cart current cart session info
 * @param { OrderItem[] } orderItems current cart order items list
 */
export const getParsedOrderItems = (
  //cart: Cart,
  orderItems: OrderItem[] | null,
): OrderItem[] | null => {
  try {
    const defaultOrderItems: OrderItem[] | null = !!orderItems
      ? orderItems.filter(
        (oi) => isSunProduct(oi) && !isRox(oi['orderItemExtendAttribute'])
      )
      : null

    const rxItems: OrderItem[] | null = !!orderItems
      ? parseRxOrderItems(
        orderItems.filter((oi) => isRox(oi['orderItemExtendAttribute']))
      )
      : null

    const rest = orderItems?.filter(
      (oi) =>
        !isSunProduct(oi) &&
        !isRox(oi['orderItemExtendAttribute'])
    )

    return [
      ...(defaultOrderItems || []),
      ...(rxItems || []),
      ...(rest || []),
    ]
  } catch (e: any) {
    Log.error('Could not parsed order items', e)
    return null
  }
}
