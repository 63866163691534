import { StyledAnchor, StyledBreadcrumbs } from '../../StyledUI'
import { shallowEqual, useSelector } from 'react-redux'
import { ProductBase, ProductBreadcrumb } from '../../../types/product'
import React from 'react'
import { getModelName } from '../../../utils/productAttributes'
import theme from '../../../themes'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { useTranslation } from 'react-i18next'

interface BreadcrumbProps {
  breadcrumbs: ProductBreadcrumb[]
  cid: string
  searchTermText: string
  product?: ProductBase
}

/**
 * Breadcrumb component
 * displays breadcrumb trail
 * @param props
 */
const BreadcrumbLayout: React.FC<BreadcrumbProps> = ({
  breadcrumbs: breadcrumbsProp,
  cid,
  searchTermText,
  product,
}) => {
  const { t } = useTranslation()
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const breadcrumbs = cid.includes('search-results')
    ? [{ label: searchTermText.replace('*', '') }]
    : breadcrumbsProp

  const _getLabel = (breadcrumb: typeof breadcrumbs[0]) => {
    if (product) {
      const modelName = getModelName(product)
      return modelName
    }
    return breadcrumb.label
  }

  return (
    <StyledBreadcrumbs isLoading={!(breadcrumbs && breadcrumbs.length)}>
      <StyledAnchor
        customprops={{
          isUnderlined: true,
          uppercase: false,
          color: themeMode === 'dark' ? theme.palette.text.dark : theme.palette.text.light,
        }}
        color="inherit"
        data-element-id={'X_X_Top_Breadcrumb'}
        to="/"
        id={`breadcrumb_a_1_${cid}`}>
        {t('CommerceEnvironment.breadcrumbs.Home')}
      </StyledAnchor>

      {breadcrumbs.map((breadcrumb, index: number) =>
        index < breadcrumbs.length - 1 && breadcrumb.seo && breadcrumb.seo.href ? (
          <StyledAnchor
            customprops={{
              isUnderlined: true,
              uppercase: false,
              color: themeMode === 'dark' ? theme.palette.text.dark : theme.palette.text.light,
            }}
            color="inherit"
            to={breadcrumb.seo.href}
            key={breadcrumb.value}
            data-element-id={'X_X_Top_Breadcrumb'}
            id={`breadcrumb_a_1_${cid}_${index}`}>
            {breadcrumb.label}
          </StyledAnchor>
        ) : (
          <span key={breadcrumb.value + index}>{_getLabel(breadcrumb)}</span>
        )
      )}
    </StyledBreadcrumbs>
  )
}

export default BreadcrumbLayout
