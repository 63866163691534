//Standard libraries
import { createReducer, AnyAction } from '@reduxjs/toolkit'
import axios, { AxiosError } from 'axios'
//Foundation libraries
import { localStorageUtil, storageSessionHandler } from '../../foundation/utils/storageUtil'
import { PERSONALIZATION_ID, INITIATED_FROM_STORAGE } from '../../foundation/constants/user'
//Custom libraries
import { PERMANENT_STORE_DAYS } from '../../configs/common'
//Redux
import initStates from './initStates'
import { UserReducerState } from './reducerStateInterface'
import {
  LOGIN_SUCCESS_ACTION,
  LOGOUT_SUCCESS_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  loginErrorAction,
  REGISTRATION_SUCCESS_ACTION,
  REGISTRATION_PENDING_ACTION,
  registrationErrorAction,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  FETCH_USER_DETAILS_SUCCESS_ACTION,
  FETCH_USER_DETAILS_REQUESTED_ACTION,
  NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION,
} from '../actions/user'
import tealiumService from '../../foundation/analytics/tealium/tealium.service'
import { INTERNAL_SERVER_ERROR } from 'http-status-codes'

const clearUserState = (userState: any) => {
  for (let variableKey in userState) {
    if (
      variableKey !== PERSONALIZATION_ID &&
      variableKey !== INITIATED_FROM_STORAGE &&
      userState.hasOwnProperty(variableKey)
    ) {
      delete userState[variableKey]
    }
  }
}
/**
 * User reducer
 * handles states used by user related components
 * @param state State object managed by user reducer
 * @param action The dispatched action
 */
const userReducer = createReducer(initStates.user, (builder) => {
  builder.addCase(LOGIN_SUCCESS_ACTION, (state: UserReducerState | any, action: AnyAction) => {
    if (action.payload) {
      Object.assign(state, action.payload, {
        userLoggedIn: true,
        isGuest: false,
        //lastUpdated is not needed here, since we will fetch details right after.
      })
      storageSessionHandler.saveCurrentUser(state)
      //set personalizationID to localStorage
      const { personalizationID } = action.payload
      localStorageUtil.set(PERSONALIZATION_ID, personalizationID, PERMANENT_STORE_DAYS)
    }
    //else is init from storage.
  })

  builder.addCase(
    INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
    (state: UserReducerState | any, action: AnyAction) => {
      clearUserState(state)
      if (action.payload !== null) {
        Object.assign(state, action.payload, {
          [INITIATED_FROM_STORAGE]: true,
        })
      } else {
        Object.assign(state, {
          [INITIATED_FROM_STORAGE]: true,
        })
      }
    }
  )

  builder.addCase(loginErrorAction, (state: UserReducerState) => {
    Object.assign(state, { userLoggedIn: false, isGuest: false })
    return state
  })

  builder.addCase(LOGOUT_SUCCESS_ACTION, (state: UserReducerState | any, action: AnyAction) => {
    clearUserState(state)
    storageSessionHandler.removeCurrentUser()
    state.lastUpdated = Date.now()
  })
  builder.addCase(
    GUEST_LOGIN_SUCCESS_ACTION,
    (state: UserReducerState | any, action: AnyAction) => {
      if (action.payload) {
        Object.assign(state, action.payload)
        state.userLoggedIn = false
        state.isGuest = true
        state.lastUpdated = Date.now()
        const { personalizationID } = action.payload
        localStorageUtil.set(PERSONALIZATION_ID, personalizationID, PERMANENT_STORE_DAYS)
        storageSessionHandler.saveCurrentUser(state)
      }
      //else is init from storage.
    }
  )
  builder.addCase(
    REGISTRATION_SUCCESS_ACTION,
    (state: UserReducerState | any, action: AnyAction) => {
      Object.assign(state, action.payload)
      state.userRegistration = true
      state.pendingRegistration = false
      state.userLoggedIn = true
      state.isGuest = false
      state.lastUpdated = Date.now()
      storageSessionHandler.saveCurrentUser(state)
      const { personalizationID } = state
      localStorageUtil.set(PERSONALIZATION_ID, personalizationID, PERMANENT_STORE_DAYS)
    }
  )
  builder.addCase(REGISTRATION_PENDING_ACTION, (state: UserReducerState, action: AnyAction) => {
    state.pendingRegistration = action.payload.isRegistrationPending
  })
  builder.addCase(
    NEWSLETTER_SUBSCRIBED_UNSUBSCRIBED_ACTION,
    (state: UserReducerState, action: AnyAction) => {
      state.details.x_data.hasNewsletter = action.payload.subscribed
    }
  )
  // @ts-ignore
  builder.addCase(
    registrationErrorAction,
    (state: UserReducerState, action: { type: string; error: AxiosError }) => {
      state.pendingRegistration = false
      state.userRegistration = false
      state.isGuest = true

      if (axios.isAxiosError(action.error)) {
        const requestStatusCode = action.error.response?.status || INTERNAL_SERVER_ERROR
        const formattedRequestError = {
          ...tealiumService.formatRequestError(action.error),
          Error_Code: 'Account Registration',
        }

        if (requestStatusCode === INTERNAL_SERVER_ERROR) {
          tealiumService.sendErrorEvent(formattedRequestError)
        } else {
          tealiumService.sendErrorEvent({ ...formattedRequestError, Error_Source: 'User' })
        }
      }

      return state
    }
  )

  builder.addCase(FETCH_USER_DETAILS_REQUESTED_ACTION, (state) => {
    state.areDetailsLoading = true
  })

  builder.addCase(FETCH_USER_DETAILS_SUCCESS_ACTION, (state, action) => {
    state.details = action.payload
    state.lastUpdated = Date.now()
    state.areDetailsLoading = false

    storageSessionHandler.saveCurrentUser(state)
  })
})

export default userReducer
