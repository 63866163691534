import { Theme } from '@material-ui/core'
import { css } from 'styled-components'
import { CmsImageCrops, CtaStyle } from '../types/common'

export const APP_MAIN_CONTAINER_CLASSNAME = 'arn-app__main-container'

export const ctaStylesMap: CtaStyle = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
}

export const cmsImageCrops: CmsImageCrops = {
  FULL_WIDTH_BANNER: {
    desk: {
      crop: 'landscape_ratio9x4',
      width: 2880,
    },
    tablet: {
      crop: 'portrait_ratio23x29',
      width: 1840,
    },
    mobile: {
      crop: 'portrait_ratio23x29',
      width: 920,
    },
  },
  LANDSCAPE_BANNER: {
    desk: {
      crop: 'landscape_ratio9x4',
      width: 1880,
    },
    tablet: {
      crop: 'portrait_ratio23x29',
      width: 1840,
    },
    mobile: {
      crop: 'portrait_ratio23x29',
      width: 920,
    },
  },
  BANNER_W_SIDE_FIELDS: {
    desk: {
      crop: 'landscape_ratio5x4',
      width: 1900,
    },
    tablet: {
      crop: 'portrait_ratio23x29',
      width: 1480,
    },
    mobile: {
      crop: 'portrait_ratio23x29',
      width: 740,
    },
  },
  BANNER_W_SIDE_FIELDS_RELATED: {
    desk: {
      crop: 'landscape_ratio5x4',
      width: 1900,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
  },
  BOARD_W_FIELDS_ON_BELOW_FIRST_BLOCK: {
    desk: {
      crop: 'landscape_ratio5x4',
      width: 1900,
    },
    tablet: {
      crop: 'landscape_ratio57x32',
      width: 720,
    },
    mobile: {
      crop: 'landscape_ratio57x32',
      width: 720,
    },
  },
  BOARD_W_FIELDS_ON_BELOW_SECOND_BLOCK: {
    desk: {
      crop: 'portrait_ratio10x12',
      width: 1280,
    },
    tablet: {
      crop: 'landscape_ratio19x12',
      width: 720,
    },
    mobile: {
      crop: 'landscape_ratio19x12',
      width: 720,
    },
  },
  BOARD_W_FIELDS_ON_BELOW_CAROUSEL: {
    desk: {
      crop: 'portrait_ratio10x12',
      width: 1280,
    },
    tablet: {
      crop: 'portrait_ratio10x12',
      width: 1152,
    },
    mobile: {
      crop: 'portrait_ratio10x12',
      width: 640,
    },
  },
  BLURRED_SLIDER: {
    desk: {
      crop: 'landscape_ratio9x4',
      width: 2880,
    },
    tablet: {
      crop: 'landscape_ratio2x1',
      width: 1840,
    },
    mobile: {
      crop: 'landscape_ratio2x1',
      width: 920,
    },
  },
  COMBO_MINI_SLIDER_BOX_ALL_FIELDS: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1440,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 1480,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
  },
  NARROW_BANNER: {
    desk: {
      crop: 'landscape_ratio9x4',
      width: 2880,
    },
    tablet: {
      crop: 'portrait_ratio23x29 ',
      width: 1840,
    },
    mobile: {
      crop: 'portrait_ratio23x29',
      width: 920,
    },
  },
  PLP_BOX: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1440,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 1480,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
  },
  BOX_1: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 1440,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 1480,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
  },
  CART_PROMO_BANNER: {
    desk: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
    tablet: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
    mobile: {
      crop: 'portrait_ratio1x1',
      width: 740,
    },
  },
  NEW_LANDSCAPE_BANNER: {
    desk: {
      crop: 'landscape_ratio40x13',
      width: 2880,
    },
    tablet: {
      crop: 'landscape_ratio375x284',
      width: 675,
    },
    mobile: {
      crop: 'landscape_ratio375x284',
      width: 675,
    },
  },
  TOP_PAGE_BANNER: {
    desk: {
      crop: 'landscape_ratio9x2',
      width: 2880,
    },
    tablet: {
      crop: 'landscape_ratio375x284',
      width: 675,
    },
    mobile: {
      crop: 'landscape_ratio375x284',
      width: 675,
    },
  },
  SQUAT_BANNER: {
    desk: {
      crop: 'landscape_ratio6x1',
      width: 2880,
    },
    tablet: {
      crop: 'portrait_ratio125x126',
      width: 675,
    },
    mobile: {
      crop: 'portrait_ratio125x126',
      width: 675,
    }
  }
}

export const PDP_STICKY_BAR_HEIGHT = '72px'
export const PDP_ROXABLE_STICKY_BAR_HEIGHT = '124px'

export const PROMO_BAR_HEIGHT = '32px'
export const PROMO_BAR_HEIGHT_MOBILE = '30px'
export const PROMO_BAR_HEIGHT_TWO_ROWS = '46px'

export const getDesktopThemeBreakpoint = (theme: Pick<Theme, 'breakpoints'>) =>
  theme.breakpoints.up('md')

export const stickyTopBarCss = css`
  z-index: 1;
  overflow-x: auto;
  position: sticky;
  top: -1px;
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  padding-right: ${({ theme }) => theme.spacing(1)}px;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1.25)}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.main};
  white-space: nowrap;
  background: ${({ theme }) => theme.palette.background.default};
  transition: 300ms;
  max-width: 100vw;
`
