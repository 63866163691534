import { createSlice } from '@reduxjs/toolkit'

export interface IUiState {
    vMisOpen: boolean
  }

  export const initialStateUi: IUiState = {
  vMisOpen: false,
}

export const uiSlice = createSlice({
    name: 'uiSlice',
    initialState: initialStateUi,
    reducers: {
      vMisOpen: (state: IUiState, action) => {
        state.vMisOpen = action.payload
      },
    },
  })

  export const {
    vMisOpen,
  } = uiSlice.actions
  
  export default uiSlice.reducer