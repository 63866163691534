//Standard libraries
import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { getSrcSetsImageFromCms } from '../../../utils/url'
//UI
import {
  StyledContainer,
  StyledImage,
  StyledTitle,
  StyledBodyContainer,
  StyledSubTitle,
  StyledFallback,
} from './NotFound.style'
import { useLocation } from 'react-router-dom'
import { useCms } from '../../providers/CmsProvider'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { ILXTeaser, IPlacement } from '../../../types/teaser'
import tealiumService from '../../../foundation/analytics/tealium/tealium.service'
import { useSendPageEventWithRequiredProps } from '../../../foundation/hooks/useAnalyticsData'

const StyledContainer404 = styled(StyledContainer)`
  background-color: ${({ theme }) => theme.palette.custom.black};
`

const NotFound: React.FC = () => {
  const location = useLocation()
  const { basePath } = useStoreIdentity()
  const { fetchCommerceContent } = useCms()
  const sendAnalyticsPageEvent = useSendPageEventWithRequiredProps(tealiumService.pageTypes.error404)

  const [srcSetContent, setSrcSetContent] = useState<{
    desk: string
    tablet: string
    mobile: string
  }>({ desk: '', mobile: '', tablet: '' })
  const [titleContent, setTitleContent] = useState<string>()
  const [textContent, setTextContent] = useState<string>('')
  const [isFetchError, setFetchError] = useState<boolean>(false)

  useEffect(() => {
    sendAnalyticsPageEvent({ Error_Details: location.pathname })

    const getContent = async () => {
      try {
        const commercePlacements = await fetchCommerceContent('hcl', 'ops--page')

        const notFoundData = commercePlacements.find(
          (i) => i.name === 'OPS_Page_banner'
        ) as IPlacement<ILXTeaser>

        setSrcSetContent(
          getSrcSetsImageFromCms('PLP_BOX', notFoundData.items[0].media[0].uriTemplate as string)
        )
        setTitleContent(notFoundData.items[1].teaserTitle1)

        const parsedText = (notFoundData.items[1].teaserText1 as string).replace(
          /{country}/gm,
          basePath
        )
        setTextContent(parsedText)
      } catch (e) {
        setFetchError(true)
      }
    }

    getContent()
  }, [])

  return (
    <StyledContainer404>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      {isFetchError ? (
        <StyledFallback>ERROR 404 - PAGE NOT FOUND</StyledFallback>
      ) : (
        <>
          <StyledImage
            srcSet={`${srcSetContent.mobile} 300w, ${srcSetContent.tablet} 768w, ${srcSetContent.desk} 1024w`}
          />
          <StyledBodyContainer>
            <StyledTitle>{titleContent}</StyledTitle>
            <StyledSubTitle dangerouslySetInnerHTML={{ __html: textContent }} />
          </StyledBodyContainer>
        </>
      )}
    </StyledContainer404>
  )
}

export default NotFound
