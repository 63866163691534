import { shallowEqual, useSelector } from 'react-redux'
import pick from 'lodash/pick'
import * as AnalyticsEvents from '../analytics/tealium/types/events.types'
import * as AnalyticsProps from '../analytics/tealium/types/props.types'
import { CommonData } from '../analytics/tealium/interfaces'
import { RootReducerState } from '../../redux/reducers'
import { useStoreIdentity } from './useStoreIdentity'
import {
  appliedPromotionCodesSelector,
  cartSelector,
  orderDetailsSelector,
  orderDiscountNamesSelector, orderItemsSelector, shippingModeSelector,
  totalOrderDiscountSelector,
} from '../../redux/selectors/order'
import tealiumService from '../analytics/tealium/tealium.service'
import {
  loginStatusSelector,
  userDetailsSelector,
  userEmailSelector,
  userIdSelector,
} from '../../redux/selectors/user'
import { useSite } from './useSite'
import { ProductAnalyticsRX } from '../../types/product'
import tealiumProductFormatService from '../analytics/tealium/tealiumProductFormat.service'
import md5 from 'crypto-js/md5.js'
import sha256 from 'crypto-js/sha256.js'
import { Maybe } from '../../types/common'

export const useAnalyticsData = (pageType?: string): Partial<CommonData> => {
  const { country, langCode } = useStoreIdentity()
  const totalOrderDiscount = useSelector(totalOrderDiscountSelector)
  const orderDiscountNames = useSelector(orderDiscountNamesSelector)
  const discountCodes = useSelector(appliedPromotionCodesSelector)
  const shippingMode = useSelector(shippingModeSelector)
  const cart = useSelector(cartSelector)
  const orderItems = useSelector(orderItemsSelector)
  const orderDetails = useSelector(orderDetailsSelector)

  return useSelector((state: RootReducerState) => {
    const currency = state.site.currentSite?.defaultCurrencyID
    const Page_Language = langCode.split('-')[0].toUpperCase()
    const Page_Country = country.toUpperCase()
    const User_Country = state.user.details?.country || ''
    const User_LoginStatus = !!state.user.userLoggedIn ? 'Logged' : 'Guest'

    let discountedPriceBadges: string | Maybe<string>[] | null | undefined

    orderItems?.forEach((element) => {
      discountedPriceBadges = element?.attributes?.find(
        (attribute) => attribute?.identifier === 'LX_DISCOUNTED_PRICE_BADGES'
      )?.values?.[0]?.value
    })

    let discountedPriceBadgesTyp: string | Maybe<string>[] | null | undefined

    orderDetails?.detailedOrderItems?.forEach((element) => {
      discountedPriceBadgesTyp = element?.attributes?.find(
        (attribute) => attribute?.identifier === 'LX_DISCOUNTED_PRICE_BADGES'
      )?.values?.[0]?.value
    })

    let baseAnalyticsData: CommonData = {
      Page_Brand: 'AR',
      Page_Design: '',
      Page_Country: tealiumService.usePageCountryWithFallback(Page_Country),
      Page_Language,
      Page_DeviceType: 'X',
      Page_Platform: 'WCS',
      Page_Server: '23.218.225.8',
      Page_Section2: '',
      User_Country,
      User_LoginStatus,
    }

    switch (pageType) {
      case 'cart':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_Currency: currency,
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames ? orderDiscountNames : discountedPriceBadges,
          Order_ShippingMode: shippingMode,
          Order_ProductsAmount: cart?.totalProductPrice
        }
        break
      case 'delivery':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_Currency: currency,
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames ? orderDiscountNames : discountedPriceBadges,
          Order_ShippingMode: shippingMode,
        }
        break
      case 'payment':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_Currency: currency,
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames ? orderDiscountNames : discountedPriceBadges,
          Order_DiscountCode: discountCodes,
          Order_ShippingMode: shippingMode,
          Order_ProductsAmount: cart?.totalProductPrice
        }
        break
      case 'typ':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_Currency: currency,
          Order_DiscountAmount: totalOrderDiscount,
          Order_DiscountName: orderDiscountNames ? orderDiscountNames : discountedPriceBadgesTyp,
          Order_DiscountCode: discountCodes,
          Order_ShippingMode: shippingMode,
          Order_ProductsAmount: cart?.totalProductPrice
        }
        break
      case 'plp':
      case 'pdp':
        baseAnalyticsData = {
          ...baseAnalyticsData,
          Order_Currency: currency,
        }
        break
      case 'home':
        baseAnalyticsData = {
          ...baseAnalyticsData,
        }
        break
    }

    return baseAnalyticsData
  }, shallowEqual)
}

const getPresetPageEventProps = <P extends AnalyticsEvents.PageLoadedKey>(
  pageType: P,
  optionalPageEventProps: AnalyticsProps.OptionalForPageEvent
): AnalyticsEvents.PageLoadedPreset[P] => {
  const { pageTypes } = tealiumService
  const {
    Order_Id,
    Order_Currency,
    Order_DiscountAmount,
    Order_DiscountCode,
    Order_DiscountName,
    Order_ProductsAmount,
  } = optionalPageEventProps

  switch (pageType) {
    case pageTypes.cart:
      return {
        Order_Currency,
        Order_DiscountAmount,
        Order_DiscountName,
        Order_Id,
        Order_ProductsAmount,
        Page_Section1: 'CartPage',
      } as AnalyticsEvents.PageLoadedPreset[typeof pageType & typeof pageTypes.cart]
    case pageTypes.clp:
      return {
        Page_Design: 'Collection',
        Order_Currency,
      } as AnalyticsEvents.PageLoadedPreset[typeof pageType & typeof pageTypes.clp]
    case pageTypes.error404:
      return {
        Error_Source: '404',
        Error_Code: '404 - page not found',
        Page_Section1: 'Other',
        Page_Section2: 'ErrorHttp404',
      } as AnalyticsEvents.PageLoadedPreset[typeof pageType & typeof pageTypes.error404]
    case pageTypes.home:
      return {
        Order_Currency,
        Page_Section1: 'Home',
      } as AnalyticsEvents.PageLoadedPreset[typeof pageType & typeof pageTypes.home]
    case pageTypes.plp:
      return {
        Page_Design: 'Editorial',
        Order_Currency,
      } as AnalyticsEvents.PageLoadedPreset[typeof pageType & typeof pageTypes.plp]
    case pageTypes.searchResults:
      return {
        Page_Design: 'Pages',
        Page_Section1: 'Search',
        Order_Currency,
      } as AnalyticsEvents.PageLoadedPreset[typeof pageType & typeof pageTypes.searchResults]
    case pageTypes.shipping:
      return {
        Page_Section1: 'Checkout',
        Page_Section2: 'Standard',
        Order_Currency,
        Order_DiscountAmount,
        Order_DiscountCode,
        Order_DiscountName,
        Order_ProductsAmount,
      } as AnalyticsEvents.PageLoadedPreset[typeof pageType & typeof pageTypes.shipping]
    default:
      return {} as never
  }
}

/** @returns props retrieved from store that are common for various analytics events */
export const useCommonAnalyticsProps = <K extends keyof AnalyticsProps.UseCommonPropsHookReturn>(
  ...args: K[]
): Pick<AnalyticsProps.UseCommonPropsHookReturn, K> => {
  const { mySite: site } = useSite()
  const { country, langCode } = useStoreIdentity()
  const isUserLoggedIn = useSelector(loginStatusSelector)
  const userId = useSelector(userIdSelector)
  const cart = useSelector(cartSelector) || {}
  const userDetails = useSelector(userDetailsSelector)
  const Order_DiscountAmount = useSelector(totalOrderDiscountSelector)
  const Order_DiscountName = useSelector(orderDiscountNamesSelector)
  const Order_DiscountCode = useSelector(appliedPromotionCodesSelector) || ''
  const email = useSelector(userEmailSelector)
  const orderItems = useSelector(orderItemsSelector)

  let discountedPriceBadges: string | Maybe<string>[] | null | undefined

  orderItems?.forEach((element) => {
    discountedPriceBadges = element?.attributes?.find(
      (attribute) => attribute?.identifier === 'LX_DISCOUNTED_PRICE_BADGES'
    )?.values?.[0]?.value
  })

  const returnObject: AnalyticsProps.UseCommonPropsHookReturn = {
    ...tealiumService.constantRequiredPageEventProps,
    Order_Id: cart.orderId || '',
    Order_Currency: cart.grandTotalCurrency || site.defaultCurrencyID || '',
    Order_DiscountAmount,
    Order_DiscountCode,
    Order_DiscountName: Order_DiscountName ? Order_DiscountName : discountedPriceBadges,
    Order_ProductsAmount: cart.totalProductPrice || '',
    Page_Country: tealiumService.usePageCountryWithFallback(country.toUpperCase()),
    Page_Language: langCode.split('-')[0].toUpperCase(),
    Store_Id: site.storeID,
    User_Country: userDetails?.country || site.country.toUpperCase() || '',
    User_Id: userId,
    User_LoginStatus: isUserLoggedIn ? 'Logged' : 'Guest',
    User_Email_MD5: isUserLoggedIn ? md5(email).toString().toLowerCase() : undefined,
    User_Email_SHA256: isUserLoggedIn ? sha256(email).toString().toLowerCase() : undefined,
  }

  return pick<AnalyticsProps.UseCommonPropsHookReturn, K>(returnObject, args)
}

/**
 * @returns
 * Props with data available before call of analytics event method.
 * Data is retrieved from store and is common for all `VirtualPage-View` (PageLoaded) analytics events
 */
export const usePresetPageEventProps = <P extends AnalyticsEvents.PageLoadedKey>(
  pageType: P
): AnalyticsProps.PageEventCommon & AnalyticsEvents.PageLoadedPreset[P] => {
  const commonAnalyticsProps = useCommonAnalyticsProps(
    'Page_Brand',
    'Page_Country',
    'Page_DeviceType',
    'Page_Language',
    'Page_Platform',
    'Page_Server',
    'Store_Brand',
    'Store_Id',
    'User_Country',
    'User_Id',
    'User_LoginStatus',
    'User_LoginType',
    'User_Email_MD5',
    'User_Email_SHA256'
  )

  const optionalPageEventProps: AnalyticsProps.OptionalForPageEvent = useCommonAnalyticsProps(
    'Order_Id',
    'Order_Currency',
    'Order_DiscountAmount',
    'Order_DiscountCode',
    'Order_DiscountName',
    'Order_ProductsAmount',
    'Order_ShippingMode'
  )

  const presetPageEventProps = getPresetPageEventProps(pageType, optionalPageEventProps)

  const commonPageEventProps: AnalyticsProps.PageEventCommon = {
    ...commonAnalyticsProps,
    Page_Type: pageType,
    Page_Section1: presetPageEventProps['Page_Section1'] || tealiumService.pageSectionFallback,
  }

  return { ...commonPageEventProps, ...presetPageEventProps }
}

/**
 * @returns {function} method prefilled with common props required for
 * `VirtualPage-View` (PageLoaded) event that receives specific props
 */
export const useSendPageEventWithRequiredProps = <P extends AnalyticsEvents.PageLoadedKey>(
  pageType: P
) => {

  const presetPageEventProps: AnalyticsProps.PageEventCommon & AnalyticsEvents.PageLoadedPreset[P] =
    usePresetPageEventProps(pageType)
  return (
    specificProps: AnalyticsEvents.PageLoadedSpecific[P] extends null
      ? void
      : AnalyticsEvents.PageLoadedSpecific[P] extends Pick<AnalyticsProps.Product, 'Products'>
      ? Omit<AnalyticsEvents.PageLoadedSpecific[P], 'Products'> & { Products: ProductAnalyticsRX[] }
      : AnalyticsEvents.PageLoadedSpecific[P]
  ) => {
    const isNull = (p): p is void | null => !p

    if (isNull(specificProps)) {
      return tealiumService.sendPageEvent<P>({
        ...presetPageEventProps,
        ...({} as AnalyticsEvents.PageLoadedSpecific[P]),
      })
    }


    return tealiumService.sendPageEvent<P>({
      ...presetPageEventProps,
      ...(specificProps as AnalyticsEvents.PageLoadedSpecific[P]),
      ...(specificProps['Products']
        ? { Products: tealiumProductFormatService.get(pageType, specificProps['Products']) }
        : null),
    })
  }
}
