import { ACCOUNT, CART, HOME, SIGNIN, WISHLIST } from '../../constants/routes'
import {
  CartIcon,
  CartItemIcon,
  HamburgerSearchIcon,
  HeartFilledIcon,
  HeartFilledItemIcon,
  LensIcon,
  ProfileIcon,
} from '../StyledUI/Icons/Icons'
import { Collapse, useMediaQuery } from '@material-ui/core'
import { HeaderNavigationItem } from '../../types/menu'
import { ICMCollection, ILXTeaser, IPlacement, IPlacementItem } from '../../types/teaser'
import {
  MY_ACCOUNT_DRAWER_TOGGLE_ACTION,
  SEARCH_DRAWER_TOGGLE_ACTION,
} from '../../redux/actions/ui'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { RoundButton, StyledHeader } from '../StyledUI'
import { StyledBenefitBar, StyledContentMenu } from '../StyledUI/StyledHeader/StyledHeader'
import { generatePath, useHistory } from 'react-router'
import { numItemsSelector, orderCompleteSelector } from '../../redux/selectors/order'
import { searchDrawerSelector } from '../../redux/selectors/ui'
import { useDispatch, useSelector } from 'react-redux'
import {
  wishlistEnabledSelector,
  wishlistItemsCountSelector,
} from '../../features/wishList/selector'

import BenefitBar from './BenefitBar'
import ErrorMessageSnackbar from '../widgets/message-snackbar/ErrorMessageSnackbar'
import HamburgerItem from './HamburgerMenu/HamburgerItem'
import HamburgerMenu from './HamburgerMenu/index'
import HeaderLink from './HeaderLink'
import { Helmet } from 'react-helmet'
import Logo from '../widgets/logo/Logo'
import SearchDrawerContent from './SearchDrawerContent'
import { StyledSearchDrawer } from '../StyledUI/StyledSearchDrawer/StyledSearchDrawer'
import Subheader from './Subheader'
import { ThemeSwitcher } from '../StyledUI'
import config from '../../configs'
import styled from 'styled-components'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useCms } from '../providers/CmsProvider'
import { usePageType } from '../../foundation/hooks/usePageType'
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useWishList } from '../../hooks/useWishList'
import { CountryBaseLink } from '../StyledUI/StyledLink/StyledLink'
import cmsService from '../../foundation/apis/cms/cms.service'
import { formatPathWithBase } from '../../utils/url'
import { get } from 'lodash-es'
import { vMisOpenSelector } from '../../features/ui/selector'

interface HeaderProps {
  loggedIn: boolean
  section?: string
}

interface IPlacementTrending extends IPlacement {
  teasableItems: IPlacementItem[]
}

const StyledAccountIcon = styled(ProfileIcon) <{ $isLogged?: boolean }>`
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
  fill: ${({ $isLogged, theme }) => theme.palette.custom[$isLogged ? 'geckoGreen' : 'warmGrey']};
`

const SearchToggleButton = styled(RoundButton)`
  ${({ theme }) => `
  width: calc(100% - ${theme.spacing(2)}px);
  justify-content: flex-start;
  margin: 0 ${theme.spacing(1)}px;
  border-color: ${theme.palette.primary.contrastText};
  background-color: ${theme.palette.secondary.contrastText};
  color: ${theme.palette.primary.contrastText};
  font-size: 14px;
`}
`

const HamburgerFirstItemsRow = styled.div`
  display: flex;
  align-items: stretch;

  & > * {
    flex: 1;
  }
`
const HeaderItemRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .arn-header__item--cta{
    cursor: pointer;
  }
`

const WishlistIconSelector = React.memo<{ wishListItemCount: number }>(({ wishListItemCount }) => {
  const theme = useTheme()

  return wishListItemCount > 0 ? (
    <HeartFilledItemIcon viewBox="0 0 24 24" htmlColor={theme.palette.primary.light} />
  ) : (
    <HeartFilledIcon viewBox="0 0 24 24" htmlColor={theme.palette.primary.light} />
  )
})

interface IHeaderPlacementName {
  benefitBarName: string
  searchBannerName: string
  searchTrendingNowName: string
}

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
const Header: FC<HeaderProps> = (props) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { mySite } = useSite()
  const { basePath, langCode } = useStoreIdentity()
  const {
    headerExtraJS,
    headerExtraCSS,
    headerLinks,
    headerPlacements,
    headerPromoBars,
    pageExtraCSS,
    pageExtraJS,
  } = useCms()
  const { isDesktop } = useBreakpoints()
  const { pageType } = usePageType()

  useWishList()

  const isWishlistEnabled = useSelector(wishlistEnabledSelector)
  const orderComplete = useSelector(orderCompleteSelector)
  const wishListItemCount = useSelector(wishlistItemsCountSelector)
  const cartItemsCount = useSelector(numItemsSelector)
  const isSearchDrawerOpen = useSelector(searchDrawerSelector)
  const isVmOpen = useSelector(vMisOpenSelector)

  const [isHamburgerOpen, setHamburgerOpen] = useState<boolean>(false)
  const [selectedHeaderItemHref, setSelectedHeaderItemHref] = useState<string>('')
  const [subHeaderItems, setSubHeaderItems] = useState<HeaderNavigationItem[]>([])
  const [benefitItems, setBenefitItems] = useState<ILXTeaser[]>([])
  const [trendingNowProduct, setTrendingNowProduct] = useState<ICMCollection>()
  const [searchBannerPlacement, setSearchBannerPlacement] = useState<IPlacementTrending>()
  const [placementName, setPlacementName] = useState<IHeaderPlacementName>({
    benefitBarName: '',
    searchBannerName: '',
    searchTrendingNowName: '',
  })
  const [linkOnHover, setLinkOnHover] = useState<string>('')

  const customMobileSize = useMediaQuery(theme.breakpoints.up(1024))
  const loggedIn = Boolean(props.loggedIn)

  const headerPromoBar = headerPlacements.find((i) => i.name === 'header_promo_bar')

  const isCustomerWeek = get(headerPromoBar, 'items[0].dynamicRules[0].rules[0].value') === 'true'

  const isMenuItemSelected = (headerNavItem: HeaderNavigationItem, locationPathname: string) => {
    return formatPathWithBase(headerNavItem.href, basePath) === locationPathname
  }

  const IS_BENEFIT_ARRAY_FULL: boolean = useMemo(() => {
    if (!Array.isArray(benefitItems)) return false
    if (!benefitItems.length) return false

    if (!benefitItems[0].teaserIcon || !benefitItems[0].teaserText2) return false

    return true
  }, [benefitItems])

  const headerCTAIcons: {
    buttonAriaLabel: string
    children: React.ReactNode
    containerProps?: Record<string, string>
    icon?: FC
    isHidden?: boolean
    buttonOnClick(): void
    isThemeSwitcher?: boolean
  }[] = [
      {
        buttonAriaLabel: t('Header.Actions.Brightness'),
        buttonOnClick: () => null,
        children: <ThemeSwitcher />,
        containerProps: {
          'data-element-id': 'X_X_MainNav_Brightness',
        },
        isThemeSwitcher: true,
      },
      {
        buttonAriaLabel: t('Header.Actions.Search'),
        buttonOnClick: () => dispatch(SEARCH_DRAWER_TOGGLE_ACTION(!isSearchDrawerOpen)),
        children: <LensIcon htmlColor={theme.palette.primary.light} />,
        containerProps: {
          'data-element-id': 'X_X_MainNav_Search',
          'data-testid': 'search-desktop-largetablet-element',
        },
        isHidden: !isDesktop,
      },
      {
        buttonAriaLabel: t('Header.Actions.MyWishlist'),
        buttonOnClick: () => history.push(generatePath(WISHLIST, { country: langCode })),
        children: <WishlistIconSelector wishListItemCount={wishListItemCount} />,
        containerProps: {
          'data-element-id': 'X_X_MainNav_Fav',
          'data-testid': 'wishlist-desktop-largetablet-element',
        },
        isHidden: !(isDesktop && isWishlistEnabled && loggedIn),
      },
      {
        buttonAriaLabel: loggedIn ? t('Header.Actions.Account') : t('Header.Actions.SignIn'),
        buttonOnClick: () =>
          loggedIn
            ? dispatch(MY_ACCOUNT_DRAWER_TOGGLE_ACTION(true))
            : history.push(generatePath(SIGNIN, { country: langCode })),
        children: (
          <StyledAccountIcon $isLogged={loggedIn} viewBox="0 0 24 24" height="24" width="24" />
        ),
        containerProps: { 'data-element-id': 'X_X_MainNav_MyAccount' },
        isHidden: !isDesktop,
      },
      {
        buttonAriaLabel: t('Header.Actions.Cart'),
        buttonOnClick: () => history.push(generatePath(CART, { country: langCode })),
        children: (
          <>
            {!orderComplete && cartItemsCount > 0 ? (
              <CartItemIcon viewBox="0 0 24 24" htmlColor={theme.palette.primary.light} />
            ) : (
              <CartIcon viewBox="0 0 24 24" htmlColor={theme.palette.primary.light} />
            )}
          </>
        ),
        containerProps: { 'data-element-id': 'X_X_MainNav_Bag' },
        isHidden: false,
      },
      {
        buttonAriaLabel: 'Nav Menu',
        buttonOnClick: () => setHamburgerOpen(true),
        children: <HamburgerSearchIcon htmlColor={theme.palette.primary.light} />,
        containerProps: { 'data-element-id': 'X_X_MainNav_Menu' },
        isHidden: customMobileSize,
      },
    ]

  useEffect(() => {
    if (mySite) {
      if (headerPlacements.length) {
        const benefitBar = headerPlacements.find((i) => i.name === 'header_benefit_bar')
        const benefitBarItems = benefitBar?.items[0]?.teasableItems
        setBenefitItems(benefitBarItems)

        const trendingNowProduct = headerPlacements.find(
          ({ name }) => name === 'header_search_trending_now'
        )
        setTrendingNowProduct(trendingNowProduct?.items[0])

        const searchBanner = headerPlacements.find(({ name }) => name === 'header_search_banner')
        setSearchBannerPlacement(searchBanner?.items[0])

        setPlacementName({
          benefitBarName: benefitBar?.name,
          searchBannerName: searchBanner?.name,
          searchTrendingNowName: trendingNowProduct?.name,
        })
      }
    }
  }, [mySite, headerPlacements])

  useEffect(() => {
    const locationPathname = history.location.pathname

    for (const headerLink of headerLinks) {
      const isSelected = isMenuItemSelected(headerLink, locationPathname)
      const hasSelectedChild = headerLink.children?.some((child) =>
        isMenuItemSelected(child, locationPathname)
      )

      if (linkOnHover === '' ? (hasSelectedChild || isSelected) : headerLink.href === linkOnHover) {
        linkOnHover === '' && setSelectedHeaderItemHref(headerLink.href)
        setSubHeaderItems(
          (headerLink.children || []).map((child) => ({
            ...child,
            selected: isMenuItemSelected(child, locationPathname),
          }))
        )

        return
      }
    }

    // if non of headerLinks is selected
    setSelectedHeaderItemHref('')
    setSubHeaderItems([])
  }, [JSON.stringify(headerLinks), history.location.pathname, linkOnHover])

  useEffect(() => {
    if (isHamburgerOpen || isVmOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [isHamburgerOpen, isVmOpen])

  useEffect(() => {
    if (isHamburgerOpen) {
      setHamburgerOpen(false)
    }
  }, [history.location.pathname, history.location.search])

  const onSearchDrawerOpen = () => {
    dispatch(SEARCH_DRAWER_TOGGLE_ACTION(true))
  }

  const onSearchDrawerClose = () => {
    dispatch(SEARCH_DRAWER_TOGGLE_ACTION(false))
  }

  const showBenefitBar = (pageType: string) => {
    const pagesToExclude = ['page404', 'cart', 'wishlist', 'pdp', 'checkout']
    return pagesToExclude.indexOf(pageType) === -1
  }

  const cssLink = (link: string) => (
    <link
      key={link}
      rel="stylesheet"
      type="text/css"
      href={[config.cmsImageServerUrl, link].join('')}
    />
  )

  const jsTag = (src: string) => (
    <script key={src} src={[config.cmsImageServerUrl, src].join('')} type="text/javascript" />
  )

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  )

  return (
    <>
      <Helmet>
        {headerExtraCSS && headerExtraCSS.map((el) => cssLink(el))}
        {pageExtraCSS && pageExtraCSS.map((el) => cssLink(el))}
        {headerExtraJS && headerExtraJS.map((el) => jsTag(el))}
        {pageExtraJS && pageExtraJS.map((el) => jsTag(el))}
      </Helmet>

      {!isHamburgerOpen && (
        <StyledHeader theme={theme} className="arn-header"
          onMouseLeave={() => {
            if (pageType !== 'plp') {
              setLinkOnHover('')
              setSubHeaderItems([])
            } else {
              setLinkOnHover('')
            }
          }}>
          <ErrorMessageSnackbar />

          <div className="arn-header__top-container" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="arn-header__item arn-header__item--logo">
              <CountryBaseLink aria-label="Homepage" data-element-id="X_X_MainNav_LOGO" to={HOME}>
                <Logo width={!isDesktop ? 100 : 160} />
              </CountryBaseLink>
            </div>

            <div className="arn-header__item arn-header__item--menu">
              {headerLinks.map((item) => (
                <HeaderLink
                  key={item.id}
                  data-element-id={`X_X_${item.id}`}
                  aria-label={item.title}
                  to={item.href}
                  $isHighlighted={item.isHighlightedInNav && !isCustomerWeek}
                  $isUnderlined={item.href === selectedHeaderItemHref}
                  onMouseEnter={async () => {
                    !subHeaderItems && setLinkOnHover('')
                    await delay(100)
                    subHeaderItems && setLinkOnHover(item.href)
                  }}
                  onMouseLeave={async () => {
                    !subHeaderItems && setLinkOnHover('')
                    await delay(100)
                  }}
                >
                  {item.title}
                </HeaderLink>
              ))}
            </div>

            <div className="arn-header__item arn-header__item--right">
              <HeaderItemRight aria-label='ItemRight' data-element-id='X_X_Header_ItemRight'>
                {headerCTAIcons.map(
                  ({ buttonAriaLabel, children, containerProps, isHidden, buttonOnClick, isThemeSwitcher }, i) => {
                    if (isHidden) {
                      return null
                    }

                    return !isThemeSwitcher ? (
                      <div key={i} className="arn-header__item--cta" {...containerProps} tabIndex={0} onKeyPress={buttonOnClick} aria-label={buttonAriaLabel} onClick={buttonOnClick}>
                        {children}
                      </div>
                    ) : (
                      <div key={i} className="arn-header__item--cta" {...containerProps}>
                        {children}
                      </div>
                    )
                  }
                )}
              </HeaderItemRight>
            </div>
          </div>

          <StyledContentMenu>
            {((pageType === 'plp' || (subHeaderItems && subHeaderItems.length !== 0 && !isCustomerWeek)) &&
              <Subheader items={subHeaderItems} />
            )}
          </StyledContentMenu>

          {IS_BENEFIT_ARRAY_FULL && showBenefitBar(pageType!) && (
            <StyledBenefitBar
              {...cmsService.getPlacementMetaDataAttribute(placementName.benefitBarName)}>
              <BenefitBar data={benefitItems} />
            </StyledBenefitBar>
          )}

          <StyledSearchDrawer
            anchor="right"
            disableSwipeToOpen={true}
            open={isSearchDrawerOpen}
            hasPromoBar={!!headerPromoBars.length}
            onOpen={onSearchDrawerOpen}
            onClose={onSearchDrawerClose}>
            <SearchDrawerContent
              onCloseHamburgerMobile={!isDesktop ? () => setHamburgerOpen(false) : undefined}
              onClose={onSearchDrawerClose}
              placement={searchBannerPlacement!}
              trendingProduct={trendingNowProduct}
              placementName={placementName}
            />
          </StyledSearchDrawer>
        </StyledHeader>
      )}

      <Collapse in={isHamburgerOpen} timeout="auto">
        <HamburgerMenu
          onClose={() => setHamburgerOpen(false)}
          HeadComponent={
            <SearchToggleButton
              variant="secondary"
              onClick={() => {
                dispatch(SEARCH_DRAWER_TOGGLE_ACTION(true))
                setHamburgerOpen(false)
              }}>
              <LensIcon />
              {t('SearchBar.MobileDrawerToggler')}
            </SearchToggleButton>
          }>
          <HamburgerFirstItemsRow>
            <HamburgerItem
              aria-label={loggedIn ? t('Header.Actions.Account') : t('Header.Actions.SignIn')}
              data-element-id="X_X_MainNav_MyAccount"
              href={loggedIn ? ACCOUNT : SIGNIN}
              title={t('Account.Title')}
              icon={
                <StyledAccountIcon
                  $isLogged={loggedIn}
                  viewBox="0 0 24 24"
                  height="24"
                  width="24"
                />
              }
              isHighlighted={false}
            />

            {isWishlistEnabled && (
              <HamburgerItem
                data-element-id="X_X_MainNav_Fav"
                href={WISHLIST}
                title={t('Header.Actions.MyWishlist')}
                icon={<WishlistIconSelector wishListItemCount={wishListItemCount} />}
                isHighlighted={false}
              />
            )}
          </HamburgerFirstItemsRow>

          {headerLinks.map((item) => (
            <HamburgerItem
              key={item.title + item.href}
              data-element-id={item.id}
              href={item.href}
              isHighlighted={item.isHighlightedInNav}
              teaserText={item.teaserText}
              title={item.title.toUpperCase()}>
              {item.children?.map((trottle) => (
                <HamburgerItem
                  key={item.title + trottle.href}
                  href={trottle.href}
                  isHighlighted={trottle.isHighlightedInNav}
                  teaserText={trottle.teaserText}
                  title={trottle.title}
                />
              ))}
            </HamburgerItem>
          ))}
        </HamburgerMenu>
      </Collapse>
    </>
  )
}

export { Header }
