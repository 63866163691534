import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { useTheme } from 'styled-components'
import { shallowEqual, useSelector } from 'react-redux'
import { themeModeSelector } from '../../../redux/selectors/theme'

const IconWrapper = ({ children, htmlColor, ...props }: SvgIconProps) => {
  const theme = useTheme()
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const color = htmlColor ?? getColorByTheme(theme, themeMode)
  return (
    <SvgIcon htmlColor={color} viewBox='0 0 32 32' {...props}>
      {children}
    </SvgIcon>
  )
}

const getColorByTheme = (theme, themeMode) => {
  return themeMode === 'dark' ? 'theme.palette.custom.dark' : theme.palette.warmGrey
}

export default IconWrapper
