import { List, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PROMO_BAR_HEIGHT, PROMO_BAR_HEIGHT_MOBILE, PROMO_BAR_HEIGHT_TWO_ROWS } from '../../../constants/ui'
import { useCms } from '../../providers/CmsProvider'
import { CloseIcon } from '../../StyledUI/Icons'
import { useDynamicCmsContent } from '../../../foundation/hooks/useDynamicCmsContent'
import { isCMCollection, isLXTeaser } from '../../../types/teaser'
import { sanitizeHtml } from '../../../utils/common'
import cmsService from '../../../foundation/apis/cms/cms.service'

const StyledContainer = styled('div') <{ indentTop?: boolean, headerPromoBar?: boolean }>`
  z-index: 100;
  position: fixed;
  top: ${({ indentTop, headerPromoBar }) => headerPromoBar ? 0 : indentTop ? PROMO_BAR_HEIGHT_TWO_ROWS : PROMO_BAR_HEIGHT_MOBILE};
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100vw;
  width: 100%;
  height: ${({ indentTop }) => `calc(100vh - ${indentTop ? PROMO_BAR_HEIGHT_TWO_ROWS : PROMO_BAR_HEIGHT_MOBILE})`};
  background-color: ${({ theme }) => theme.palette.custom.black};
`

const StyledCloseContainer = styled.div`
  padding: 11px 20px 18px 16px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const StyledContentWrapper = styled.div<{ hasPromoBar: boolean }>`
  ${({ hasPromoBar }) => `
  overflow: auto;
  height: calc(100% - ${hasPromoBar ? PROMO_BAR_HEIGHT : '0px'} - 53px);
`}
`

const StyledList = styled(List)`
  padding: ${({ theme }) => `0 0 ${theme.spacing(4)}px`};

  & > * {
    border-bottom: 1px solid white;
  }
`

const StyledCloseButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font-size: 0;
  cursor: pointer;
`

interface Props {
  HeadComponent: React.ReactNode
  onClose: () => void
}

const HamburgerMenu: React.FC<Props> = ({ children, HeadComponent, onClose }) => {
  const theme = useTheme()
  const { headerPlacements } = useCms()
  const [countdownTimeLeft, setCoundownTimeLeft] = useState<string>('')
  const dynamicContentBanners = useDynamicCmsContent(headerPlacements)
  const headerPromoBar = dynamicContentBanners && dynamicContentBanners.find((i) => i.name === 'header_promo_bar')
  const collectionItems = headerPromoBar?.items?.find(isCMCollection)
  const teaserItems = headerPromoBar?.items?.filter(isLXTeaser) || []
  const teasableItems = collectionItems?.teasableItems?.filter(isLXTeaser) || []
  const dataBanners = Boolean(collectionItems) ? teasableItems : teaserItems

  useEffect(() => {
    if (dataBanners)
      cmsService.initTeaserCountdownInterval(dataBanners[0], setCoundownTimeLeft)
  }, [dataBanners])

  const bannerTextLength = dataBanners?.map(x => {
    return (
      Number(sanitizeHtml(x?.teaserText2).length) +
      Number(x?.teaserLXCallToActionSettings?.[0]?.callToActionText?.length) +
      Number(countdownTimeLeft?.length)
    )
  })

  const textOnTwoRows = bannerTextLength?.some(n => n > 54)

  return (
    <StyledContainer
      className="hamburger_menu"
      indentTop={textOnTwoRows}
      headerPromoBar={!headerPromoBar}
    >
      <StyledCloseContainer>
        <StyledCloseButton onClick={onClose}>
          <CloseIcon htmlColor={theme.palette.primary.light} />
        </StyledCloseButton>
      </StyledCloseContainer>

      <StyledContentWrapper aria-label="wrapper" hasPromoBar={!!headerPlacements.length}>
        {HeadComponent}
        <StyledList>{children}</StyledList>
      </StyledContentWrapper>
    </StyledContainer>
  )
}

export default HamburgerMenu
