import { PayloadAction } from '@reduxjs/toolkit'
import { takeLatest, call } from 'redux-saga/effects'
import * as ACTIONS from '../../action-types/order'
import * as WORKERS from '../workers/order'
import { FETCHING_CART_ACTION, GET_CART_ACTION } from '../../actions/order'
import {
  LOGIN_SUCCESS_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
} from '../../actions/user'
import { OrderActionsPayload } from '../../../types/orderActionsPayload'

/**
 * Order watch saga
 * watchers to intercept order actions
 */
export function* watchSaga() {
  yield takeLatest(ACTIONS.ITEM_ADD_REQUESTED, WORKERS.addItem)
  yield takeLatest(ACTIONS.LENS_ADD_REQUESTED, WORKERS.addLens)
  yield takeLatest(ACTIONS.DELETE_ORDER_ITEM_AND_FETCH_CART_ACTION, removeItemAndFetchCart)
  yield takeLatest(
    [FETCHING_CART_ACTION, GET_CART_ACTION, LOGIN_SUCCESS_ACTION, GUEST_LOGIN_SUCCESS_ACTION],
    WORKERS.fetchCart
  )
  yield takeLatest(INIT_USER_FROM_STORAGE_SUCCESS_ACTION, WORKERS.initFromStorageFetchCart)
  yield takeLatest([ACTIONS.SHIPINFO_GET_REQUESTED], WORKERS.fetchShipInfo)
  yield takeLatest(ACTIONS.SHIPMODE_UPDATE_REQUESTED, updateShipModeAndFetchCart)
  yield takeLatest(ACTIONS.PAYMETHODS_GET_REQUESTED, WORKERS.fetchPayMethods)
  yield takeLatest(ACTIONS.PAYPALEXPRESS_GET_REQUESTED, WORKERS.fetchPayPalInfo)
  yield takeLatest(
    ACTIONS.PAYPALEXPRESS_CHECK_STATUS_GET_REQUESTED,
    WORKERS.fetchPayPalExpressStatus
  )
  yield takeLatest(
    ACTIONS.TAXES_CALCULATION_GET_REQUESTED,
    calculateEstimatedTaxesAndFetchCart
  )
}



function* removeItemAndFetchCart(
  action: PayloadAction<OrderActionsPayload.DeleteOrderItemAndFetchCart>
) {
  yield call(
    WORKERS.removeItem,
    action as PayloadAction<
      Omit<OrderActionsPayload.DeleteOrderItem, 'x_calculateOrder' | 'x_calculationUsage'>
    >
  )
  yield call(WORKERS.fetchCart, action)
}

function* calculateEstimatedTaxesAndFetchCart(
  action: PayloadAction<OrderActionsPayload.EstimateTaxesAndFetchCart>
) {
  yield call(
    WORKERS.estimateTaxes,
    action
  )
  yield call(WORKERS.fetchCart, action)
}


function* updateShipModeAndFetchCart(action: any) {
  yield call(WORKERS.updateShipMode, action)
  yield call(WORKERS.fetchCart, action)
  yield call(WORKERS.fetchShipInfo, action)
}
