import * as AnalyticsEvents from '../../analytics/tealium/types/events.types'

import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import {
  ICMChannel,
  ICMExternalProduct,
  ICMProductTeaser,
  ICmsPage,
  ILXTeaser,
  INavItem,
  IPlacement,
  TOverlayTextAlign,
  TeaserOverlayStyle,
  isCMCollection,
  isCMExternalProduct,
  isCMProductList,
  isCMProductTeaser,
} from '../../../types/teaser'
import RequestService, { RequestProps } from '../../../services/RequestService'

import Log from '../../../services/Log'
import { ProductBase } from '../../../types/product'
import { PropTypes } from '@material-ui/core'
import config from '../../../configs'
import { intervalToDuration } from 'date-fns'
import qs from 'qs'
import store from '../../../redux/store'
import tealiumService from '../../analytics/tealium/tealium.service'
import theme from '../../../themes'

class CmsService {
  private apiUrl = config.cmsApiUrl
  private cmsMetaDataAttributeName = 'data-cm-metadata' as const
  private imageServerUrl = config.cmsImageServerUrl
  private mediaDataSrcRegExp = /data-src="media:\/\/(.*?)"/g
  private storeName = config.name.toLowerCase()

  readonly breadcrumbsRootKey = 'ROOT'
  readonly defaultPageType = 'home'
  readonly homePageWidgetNames = {
    headerDesktop: 'header_desktop_widget',
    headerMobile: 'header_mobile_widget',
  }
  readonly pageTypes = {
    cart: 'cart',
    checkout: 'checkout',
    home: 'home',
  } as const

  readonly urlParams = {
    filterRulesLocale: 'filterRulesLocaleOverride',
    previewDate: 'previewDate',
  } as const

  private langCodeExceptionMap: Record<string, string> = {
    en_NL: 'nl',
    en_PT: 'pt',
  }

  private getCountry(country: string) {
    return country.toLowerCase() !== 'uk' ? country : 'gb'
  }

  private getLanguageName(locale: string) {
    return this.langCodeExceptionMap[locale] || locale.split('_')[0]
  }

  private getRequestUrl(
    urlPattern: string,
    pathParams: RequestProps['pathParams'] = {},
    queryParams: RequestProps['queryParams'] = {}
  ) {
    const site = store.getState().site.currentSite!

    const { country, defaultLanguageID, locale, storeID } = site

    const basePathParams = {
      apiUrl: this.apiUrl,
      countryName: this.getCountry(country),
      langName: this.getLanguageName(locale),
      storeName: this.storeName,
    }
    const baseQueryParams = {
      langId: defaultLanguageID,
      storeId: storeID,
    }

    const requestUrl = RequestService.fillUrlWithQueryAndPathParams(
      urlPattern,
      { ...basePathParams, ...pathParams },
      { ...baseQueryParams, ...queryParams }
    )

    return requestUrl
  }

  channelItemsToFooterLinkObject(footerNavigation: IPlacement<ICMChannel>): INavItem[] {
    try {
      return footerNavigation?.items.map((ni) => {
        const title = ni.title
        const menuItems = ni?.children.map((c) => {
          const title = c.title
          const url = c.formattedUrl

          return { title, url }
        })

        return { title, menuItems }
      })
    } catch (error) {
      throw error
    }
  }

  fetchFooter(searchParams?: Record<string, string>) {
    const requestUrl = this.getRequestUrl(
      '{apiUrl}/cms/live/{pageType}/footer/{storeName}/{langName}/{countryName}',
      { pageType: this.defaultPageType },
      searchParams
    )

    return Axios.get<{ footerPlacements: IPlacement[] }>(requestUrl)
      .then(({ data }) => data)
      .catch((e) => {
        Log.error(window.location.href, 'Unable to get CMS footer data', e.message)

        const errorProps = this.getRequestErrorForAnalytics(
          e as AxiosError,
          'CMS: error fetching footer placements'
        )

        if (errorProps) {
          tealiumService.sendErrorEvent(errorProps)
        }

        throw e
      })
  }

  fetchHeader(searchParams?: Record<string, string>) {
    const requestUrl = this.getRequestUrl(
      '{apiUrl}/cms/live/{pageType}/header/{storeName}/{langName}/{countryName}',
      { pageType: this.defaultPageType },
      searchParams
    )

    return Axios.get<{ extraCSS: string[]; extraJS: string[]; headerPlacements: IPlacement[] }>(
      requestUrl
    )
      .then(({ data }) => data)
      .catch((e) => {
        Log.error(window.location.href, 'Unable to get CMS header data', e.message)

        const errorProps = this.getRequestErrorForAnalytics(
          e as AxiosError,
          'CMS: error fetching footer placements'
        )

        if (errorProps) {
          tealiumService.sendErrorEvent(errorProps)
        }

        throw e
      })
  }

  fetchNavigation(searchParams?: Record<string, string>) {
    const requestUrl = this.getRequestUrl(
      '{apiUrl}/cms/live/{pageType}/navigation/{storeName}/{langName}/{countryName}',
      { pageType: this.defaultPageType },
      searchParams
    )

    return Axios.get(requestUrl)
      .then(({ data }) => data)
      .catch((e) => {
        Log.error(window.location.href, 'Unable to get CMS navigation data', e.message)

        const errorProps = this.getRequestErrorForAnalytics(
          e as AxiosError,
          'CMS: error fetching navigation content',
          { pageType: this.defaultPageType }
        )

        if (errorProps) {
          tealiumService.sendErrorEvent(errorProps)
        }

        throw e
      })
  }

  /** Request route: `{apiUrl}/cms/live/{pageType}/content/{storeName}/{langName}/{countryName}` */
  fetchContent<R = any>(pageType: string, axiosConfig?: AxiosRequestConfig) {
    const requestUrl = this.getRequestUrl(
      '{apiUrl}/cms/live/{pageType}/content/{storeName}/{langName}/{countryName}',
      { pageType }
    )

    return Axios.get<R>(requestUrl, axiosConfig)
      .then(({ data }) => data)
      .catch((e) => {
        Log.error(window.location.href, 'Unable to fetch CMS content data', e.message)

        const additionalErrorDetails = { pageType }

        if (axiosConfig?.params) {
          for (const key in axiosConfig?.params) {
            if (Object.prototype.hasOwnProperty.call(axiosConfig?.params, key)) {
              const element = axiosConfig?.params[key]

              if (element) {
                additionalErrorDetails[key] = element
              }
            }
          }
        }

        const errorProps = this.getRequestErrorForAnalytics(
          e as AxiosError,
          'CMS: error fetching commerce content',
          additionalErrorDetails
        )

        if (errorProps) {
          tealiumService.sendErrorEvent(errorProps)
        }

        throw e
      })
  }

  /** Request route: `{apiUrl}/cms/live/{pageType}/content/{storeName}/{langName}/{countryName}` */
  fetchCommerceContent(
    pageType: string,
    externalId: string,
    breadcrumb?: string[],
    filterRulesLocaleOverride?: string | null,
    previewDate?: string | null
  ) {
    return this.fetchContent<{
      commercePlacements: IPlacement[]
      extraCSS: string[]
      extraJS: string[]
    }>(pageType, {
      params: { externalId, breadcrumb, filterRulesLocaleOverride, previewDate },
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
    })
  }

  fetchPageContent<P extends string = string>(
    pageType: P,
    queryParams?: {
      filterRulesLocaleOverride?: string | null
      pageId?: string
      previewDate?: string | null
    }
  ) {
    return this.fetchContent<
      P extends 'page'
      ? ICmsPage
      : {
        contentPlacements: IPlacement[]
        extraCSS: string[]
        extraJS: string[]
      }
    >(pageType, { params: queryParams })
  }

  fetchPreviewContent(
    id: string,
    contentType: string,
    view: string,
    filterRulesLocaleOverride: string,
    previewDate: string
  ) {
    return Axios.get(
      `${config.cmsApiUrl}/cms/preview/component/${id}?contentType=${contentType}&view=${view}&filterRulesLocaleOverride=${filterRulesLocaleOverride}&previewDate=${previewDate}`
    )
      .then(({ data }) => data)
      .catch((e) => {
        Log.error(window.location.href, 'Unable to get CMS preview content data', e.message)

        const errorProps = this.getRequestErrorForAnalytics(
          e as AxiosError,
          'CMS: error fetching preview content',
          { id }
        )

        if (errorProps) {
          tealiumService.sendErrorEvent(errorProps)
        }

        throw e
      })
  }

  fetchPreviewPage(
    id: string,
    contentType: string,
    filterRulesLocaleOverride: string,
    previewDate: string
  ) {
    return Axios.get(
      `${config.cmsApiUrl}/cms/preview/page/${id}?contentType=${contentType}&filterRulesLocaleOverride=${filterRulesLocaleOverride}&previewDate=${previewDate}`
    )
      .then(({ data }) => data)
      .catch((e) => {
        Log.error(window.location.href, 'Unable to get CMS preview page data', e.message)

        const errorProps = this.getRequestErrorForAnalytics(
          e as AxiosError,
          'CMS: error fetching preview page data',
          { id }
        )

        if (errorProps) {
          tealiumService.sendErrorEvent(errorProps)
        }

        throw e
      })
  }

  convertCMCollectionPlacementToPlacement(placement: IPlacement): IPlacement | null {
    if (!placement.items.length) {
      return null
    }

    const collectionItems = placement.items?.filter(isCMCollection)

    if (collectionItems && collectionItems.length) {
      const collection = collectionItems[0]

      return {
        ...collection,
        items: collection.teasableItems,
        placementReflect: placement.placementReflect,
        placementCenter: placement.placementCenter,
        placementAnimation: placement.placementAnimation,
        name: placement.name,
        marginLateral: false,
        marginVertical: 'X',
        backgroundColor: undefined,
        clusterTile: false,
        cta: '',
      }
    }

    return null
  }

  convertCMProductListPlacementToPlacement(placement: IPlacement): IPlacement | null {
    if (!placement.items.length) {
      return null
    }

    const productListItems = placement.items?.filter(isCMProductList)

    if (productListItems && productListItems.length) {
      const productList = productListItems[0]

      return {
        ...productList,
        items: productList.teasableItems,
        placementReflect: placement.placementReflect,
        placementCenter: placement.placementCenter,
        placementAnimation: placement.placementAnimation,
        name: placement.name,
        marginLateral: false,
        marginVertical: 'X',
        backgroundColor: undefined,
        clusterTile: false,
        cta: '',
      }
    }

    return null
  }

  getAlignItemsByTextAlign(value: string): React.CSSProperties['alignItems'] {
    switch (value) {
      case 'left':
        return 'flex-start'
      case 'right':
        return 'flex-end'
      default:
        return value
    }
  }

  getRequestErrorForAnalytics(
    e: AxiosError<{ code: number; details: string; message: string }>,
    errorDescription: string,
    additionalErrorDetails?: Record<string, string>
  ): Omit<AnalyticsEvents.Error, 'id'> | null {
    const response = e.response

    if (response) {
      const { config, data } = response
      const details = {
        page: window.location.href,
        url: config.url,
        code: data.code,
        dataMessage: data.message,
        ...additionalErrorDetails,
      }

      return {
        Error_Code: errorDescription,
        Error_Details: Object.keys(details)
          .map((key) => `${key}: ${details[key]}`)
          .join('; '),
        Error_Source: 'Server',
      }
    }

    return null
  }

  getTextColorByTeaserOverlayStyle(teaserOverlayStyle: TeaserOverlayStyle): string {
    switch (teaserOverlayStyle) {
      case 'text-dark':
        return theme.palette.custom.black
      case 'text-light':
        return theme.palette.custom.warmGrey
      default:
        return ''
    }
  }

  getFooterNavigation(footerPlacements: IPlacement[]): IPlacement<ICMChannel> {
    return footerPlacements.find((i) => i.name === 'footer_navigation') as IPlacement<ICMChannel>
  }

  getPlpPromoBannerPlacement(placements: IPlacement[]): IPlacement<ILXTeaser> | null {
    return (placements.find((p) => p.name === 'PLP_promo_banner') as IPlacement<ILXTeaser>) || null
  }

  getProductsFromPlacement(placement: IPlacement): ProductBase[] | null {
    let productsSource: Array<ICMExternalProduct | ICMProductTeaser> | null = null

    const cmExternalProducts = placement.items ? placement.items.filter(isCMExternalProduct) : null

    if (cmExternalProducts && cmExternalProducts.length) {
      productsSource = cmExternalProducts
    } else {
      const cmProductTeasers = placement.items ? placement.items.filter(isCMProductTeaser) : null

      if (cmProductTeasers && cmProductTeasers.length) {
        productsSource = cmProductTeasers
      }
    }

    return productsSource
      ? productsSource.reduce<ProductBase[]>((acc, cur) => {
        if (!!cur.productData) {
          acc.push(cur.productData)
        }

        return acc
      }, [])
      : null
  }

  getTeaserOverlayTextAlign(textAlign: TOverlayTextAlign): PropTypes.Alignment {
    if (!textAlign) {
      return 'center'
    }

    return textAlign === 'justified' ? 'justify' : textAlign
  }

  getMetaDataAttribute(content: string) {
    return process.env.REACT_APP_PREVIEW_ENABLED === 'true'
      ? {
        [this.cmsMetaDataAttributeName]: content,
      }
      : null
  }

  getPlacementMetaDataAttribute(placementName: string) {
    return this.getMetaDataAttribute(
      `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`
    )
  }

  getExtraPlacementAttributes(placementName: string) {
    return { id: placementName, ...this.getPlacementMetaDataAttribute(placementName) }
  }

  getResponsiveDevicesMetaDataAttribute() {
    return this.getMetaDataAttribute(
      '[{"cm_responsiveDevices":{"mobile_portrait":{"width":375,"height":667,"order":1},"tablet_landscape":{"width":1024,"height":768,"order":4},"mobile_landscape":{"width":667,"height":375,"order":2},"tablet_portrait":{"width":768,"height":1024,"order":3}},"cm_preferredWidth":1500}]'
    )
  }

  initTeaserCountdownInterval(
    teaser: Pick<
      ILXTeaser,
      'teaserCountdownStart' | 'teaserCountdownUntil' | 'teaserHideExpiredCountdown'
    >,
    setCoundownTimeLeft: (str: string) => void
  ): () => void {
    const countdownStart = teaser?.teaserCountdownStart
    const countdownUntil = teaser?.teaserCountdownUntil

    // countdownStart is optional, as it will be set to 'now', if value not present
    if (!countdownUntil) {
      return () => { }
    }

    const intervalId = setInterval(() => {
      const countdown = this.getTeaserCountdown({
        start: countdownStart ? +countdownStart * 1000 : Date.now() - 60, // if start date is not present, set 'now' as default
        end: +countdownUntil * 1000,
        shouldHideExpired: teaser.teaserHideExpiredCountdown,
      })

      if (typeof countdown === 'string') {
        setCoundownTimeLeft(countdown)
      }
    }, 1000)


    return () => clearInterval(intervalId)
  }

  getTeaserCountdown({
    start,
    end,
    shouldHideExpired,
  }: {
    start: number // milliseconds
    end: number // milliseconds
    shouldHideExpired: boolean
  }): string | null {
    if (!(start && end)) {
      return null
    }
    
    const now = Date.now()
    
    if (now < start) {
      return null
    }
    
    if (now > end) {
      return shouldHideExpired ? null : '00:00:00'
    }
    
    const { years, months, days, hours, minutes, seconds } = intervalToDuration({
      start: now,
      end,
    })
     
    const calculatedDays =
      (years ? years * 8760 : 0) +
      (months ? months * 731 : 0) +
      (days ? days  : 0) + 'D'

    const calculatedHours = (hours ?? 0) + 'H'
    
    const temp = calculatedDays < '10' ? [calculatedHours, minutes + 'M', seconds + 'S']
      : [calculatedDays, calculatedHours, minutes + 'M', seconds + 'S']

    return temp.map((p) => (p! < '10' ? '0' + p : p)).join(' : ')
  }

  formatHtmlStringWithMedia(string: string) {
    return string.replaceAll(this.mediaDataSrcRegExp, (_, path) => {
      return `src="${this.imageServerUrl}${path}"`
    })
  }
}

export default new CmsService()
