import styled, { css } from 'styled-components'
import { CountryBaseLink } from '../StyledUI/StyledLink/StyledLink'

export const highlightedHeaderLinkCss = css<{ $isHighlighted: boolean }>`
  ${({ theme, $isHighlighted }) =>
    $isHighlighted &&
    `background-color: ${theme.palette.custom.geckoGreen};
  color: ${theme.palette.secondary.contrastText};

  &:hover {
    text-decoration: underline;
  }`}
`

// &[href] is used to increase priority of css selector
const HeaderLink = styled(CountryBaseLink)<{ $isHighlighted: boolean; $isUnderlined: boolean }>`
  &[href] {
    margin-right: 45px;
    padding: 0 ${({ theme }) => theme.spacing(0.25)}px;
    color: ${({ theme }) => theme.palette.custom.warmGrey};
    text-transform: uppercase;
    ${({ $isUnderlined }) => $isUnderlined && 'text-decoration: underline;'}

    ${({ theme, $isHighlighted, $isUnderlined }) =>
      !$isHighlighted &&
      `&:hover {
        color: ${theme.palette.custom.geckoGreen};
        text-decoration: ${$isUnderlined ? 'underlined' : 'none'};
      }`}

    ${highlightedHeaderLinkCss}

    @media (max-width: 1367px) {
      margin: auto 2.5rem auto 0;
    }
  }
`

export default HeaderLink
