import React from 'react'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CustomTheme } from '../../../types/theme'
import { shallowEqual, useSelector } from 'react-redux'
import { themeModeSelector } from '../../../redux/selectors/theme'

const useStyles = makeStyles(
  /**
   *
   */
  (theme: CustomTheme) =>
    createStyles({
      root: {
        padding: 0,
        color: theme.palette.custom.black,
      },
      dark: {
        color: theme.palette.primary.contrastText,
      }
    })
)

export default function StyledIconButton(props: IconButtonProps) {
  const classes = useStyles()
  const themeMode = useSelector(themeModeSelector,shallowEqual)
  return <IconButton className={themeMode === 'dark' ? `${classes.root} ${classes.dark}` : classes.root} disableRipple {...props} />
}
