//Standard libraries
import { call, put } from 'redux-saga/effects'
//Foundation libraries
import countryservice from '../../../foundation/apis/transaction/country.service'
//Redux
import * as ACTIONS from '../../action-types/account'
import { CountryStatesResponse } from '../../../types/common'

/**
 * Saga worker to invoke get countries and province list details
 */
export function* fetchCountryListDetails(action: any) {
  try {
    const payload = action.payload
    const response = yield call(countryservice.findCountryStateList, payload)
    const countriesList = response.data as CountryStatesResponse
    yield put({
      type: ACTIONS.COUNTRY_DETAILS_GET_SUCCESS,
      response: countriesList,
    })
  } catch (error) {
    yield put({ type: ACTIONS.COUNTRY_DETAILS_GET_ERROR, error })
  }
}

export function* fetchProvincesListDetails(action: any) {
  try {
    const payload = action.payload
    const response = yield call(countryservice.findCountryStateList, payload)
    const countriesList = response.data as CountryStatesResponse
    yield put({
      type: ACTIONS.PROVINCES_DETAILS_GET_SUCCESS,
      response: countriesList,
    })
  } catch (error) {
    yield put({ type: ACTIONS.PROVINCES_DETAILS_GET_ERROR, error })
  }
}
