import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IWishlist } from '../../types/wishlist'

export type WishListState = Partial<Pick<IWishlist, 'GiftList' | 'x_data'>> & { isLoading: boolean }

const initialState = {} as WishListState

export const {
  actions: { removeWishList, setWishList },
  reducer,
} = createSlice({
  name: 'wishList',
  initialState,
  reducers: {
    setWishList: (state, action: PayloadAction<WishListState>) => {
      return { ...state, ...action.payload }
    },
    removeWishList: () => {
      return initialState
    },
  },
})

export default reducer
