import React, { useEffect, useRef, useState } from 'react'
import {
  StyledA,
  StyledAccordion,
  StyledAccordionSummary,
  StyledLink,
  StyledTypography,
} from '../StyledUI'

import { ArrowDownSlimIcon } from '../StyledUI/Icons'
import { ChangeEvent } from 'react'
import { FooterLink } from './Footer'
import { SubMenuWrapper } from '../StyledUI/StyledFooter'
import styled from 'styled-components'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useLocation } from 'react-router'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'

export type FooterSubMenuProps = {
  title: string
  menuItems: FooterLink[]
  shouldScrollAfterExpanded?: boolean
  target?: string
  isSocial?: boolean
  areOtherGroupSites?: true
}

const MenuItemsAccordionDetails = styled.div`
  display: flex;
  flex-direction: column;
`
const FooterSubMenu = ({
  title,
  menuItems,
  shouldScrollAfterExpanded,
  target,
  isSocial,
  areOtherGroupSites,
}: FooterSubMenuProps) => {
  const { isMobile } = useBreakpoints()
  const { basePath } = useStoreIdentity()
  const { pathname } = useLocation()

  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const menuItemsAccordionDetailsRef = useRef<HTMLDivElement | null>(null)

  const handleChangeEvent = (_: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded((v) => !v)
    if (shouldScrollAfterExpanded && expanded) {
      setTimeout(() => {
        menuItemsAccordionDetailsRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }, 300) // transition-duration of mui-accordion
    }
  }

  const renderMenuItems = () =>
    menuItems ? (
      menuItems.map((mi, idx) => {
        const commonProps = { key: idx, className: 'sub-menu-link', 'data-element-id': `X_X_Footer_${title}`, 'data-description': mi.title }

        return target ? (
          <StyledA
            {...commonProps}
            href={isSocial ? `${mi.url}` : `${basePath}${mi.url}`}
            rel={isSocial ? 'noreferrer' : undefined}
            target={target}
          >
            {mi.title}
          </StyledA>
        ) : (
          <StyledLink {...commonProps} to={isSocial ? `${mi.url}` : `${basePath}${mi.url}`}>
            {mi.title}
          </StyledLink>
        )
      })
    ) : (
      <></>
    )

  useEffect(() => {
    setIsExpanded(false)
  }, [pathname])

  return isMobile || areOtherGroupSites ? (
    <StyledAccordion onChange={handleChangeEvent} expanded={isExpanded}>
      <StyledAccordionSummary expandIcon={<ArrowDownSlimIcon />}>{title}</StyledAccordionSummary>
      <MenuItemsAccordionDetails ref={menuItemsAccordionDetailsRef}>
        {renderMenuItems()}
      </MenuItemsAccordionDetails>
    </StyledAccordion>
  ) : (
    <>
      <StyledTypography className="sub-menu-title">{title}</StyledTypography>
      <SubMenuWrapper>{renderMenuItems()}</SubMenuWrapper>
    </>
  )
}

export default FooterSubMenu
