import { omit } from 'lodash'
import { PAGINATION_CONFIGS } from '../configs/catalog'
import {
  PROFILE_NAME_PARAM,
  SEARCHTERM,
  SORTING_NOT_CLUSTERED,
  SEARCH_TERM_PROFILE_NAME,
  SEARCH_TERM_GROUPING_PROFILE_NAME,
} from '../constants/common'
import { STANDARD_FRAME_DISPLAY_SIZE } from '../constants/product'
import inventoryavailabilityService from '../foundation/apis/transaction/inventoryavailability.service'
import { SingleSortOrderOption } from '../redux/reducers'
import { Facet, SelectedFacets } from '../types/filters'
import { OrderItem } from '../types/order'
import {
  ProductBase,
  ProductBreadcrumb,
  ProductPrice,
  ProductXPrice,
  PriceModel,
  PRODUCT_PRICE_USAGE_NAMES,
} from '../types/product'
import { getRxPrice } from '../utils/isRxOrder'
import { getSize } from '../utils/productAttributes'
import RequestService, { PaginationResponse } from './RequestService'
import DateService from './DateService'

type ProductRequestResponse = PaginationResponse<ProductBase & { type: 'item' }> & {
  breadCrumbTrailEntryView?: ProductBreadcrumb[]
  facets?: Facet[]
}

const FACET_BRAND_TITLE = 'mfName_ntk_cs'
const FACET_CATEGORY_TITLE = 'parentCatgroup_id_search'
const FACET_CATEGORY_VALUE_PREFIX = 'path.tree:'
const defaultPageLimit = PAGINATION_CONFIGS.pageLimit

class ProductService {
  async get(params: Record<string, any>) {
    const { cancelToken, widget, ...queryParams } = params
    const extraParams = {} as Record<string, any>

    if (widget) {
      extraParams.widget = widget
    }

    if (cancelToken) {
      extraParams.cancelToken = cancelToken
    }

    const response = await RequestService.request<ProductRequestResponse>({
      extraParams: { siteContextKey: 'search', ...extraParams },
      method: 'GET',
      path: '/api/v2/products',
      queryParams,
    })

    try {
      if (response.contents?.length) {
        for (const product of response.contents) {
          ProductService.sortProductSKUsBySize(product)
        }
      }
    } catch (error) {}

    return response
  }

  getCurrentPrice(prices: ProductPrice[]): number {
    const priceModel = this.getCurrentPriceModel(prices)

    return priceModel?.value ? +priceModel.value : NaN
  }

  getCurrentPriceModel(prices: ProductPrice[]): ProductPrice | null {
    return prices.find((p) => !!p && p.usage === PRODUCT_PRICE_USAGE_NAMES.CURRENT) || null
  }

  getInitialPrice(prices: ProductPrice[]): number {
    const priceModel = this.getInitialPriceModel(prices)

    return priceModel?.value ? +priceModel.value : NaN
  }

  getInitialPriceModel(prices: ProductPrice[]): ProductPrice | null {
    return prices.find((p) => !!p && p.usage === PRODUCT_PRICE_USAGE_NAMES.INITIAL) || null
  }

  genPriceWithoutValue(prices: ProductPrice[]) {
    return prices.map((price) => {
      if (price.usage === PRODUCT_PRICE_USAGE_NAMES.INITIAL && !price.value) {
        const currentPriceWithValue = prices.find(price => price.usage === 'Offer')?.value || '0'

        return {
          ...price,
          value: currentPriceWithValue
        }
      }

      return price
    })
  }

  calculateTotalPriceModel(prices: ProductPrice[]): ProductPrice {
    return prices.reduce((total, price) => {
      return { ...total, ...price, value: String(+total.value + +price.value) }
    })
  }

  addRxPriceToPricesArray(
    priceArray: ProductPrice[],
    rxServices: OrderItem[],
    rxFramePrice?: string
  ): ProductPrice[] {
    const currentPriceIndex = priceArray.findIndex(
      (p) => !!p && p.usage === PRODUCT_PRICE_USAGE_NAMES.CURRENT
    )

    const newPricesArray = Object.assign([], priceArray)

    newPricesArray.splice(currentPriceIndex, 1, {
      ...priceArray[currentPriceIndex],
      value: getRxPrice(rxServices || [], rxFramePrice).toString(),
    })

    return newPricesArray
  }

  genPricesArrayFromSingleModel(value: number | string, currency: string): ProductPrice[] {
    return [{ currency, usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT, value: value.toString() }]
  }

  getUrlAndRequestParams(
    paramsBase,
    searchTerm: string,
    facets: SelectedFacets,
    sortOption?: SingleSortOrderOption,
    offset: number = PAGINATION_CONFIGS.pageDefaultOffset,
    limit: number = PAGINATION_CONFIGS.pageLimit
  ): { requestParams: Record<string, string>; urlQueryParams: Record<string, string> } {
    const urlQueryParams = {}
    const requestParams = Object.assign({}, paramsBase)

    const selectedFacetsArray = Object.keys(facets)
    if (selectedFacetsArray.length > 0) {
      urlQueryParams['facet'] = selectedFacetsArray
    }

    if (sortOption) {
      requestParams['orderBy'] = sortOption.value
      requestParams[PROFILE_NAME_PARAM] = sortOption.profileName

      if (sortOption.value && sortOption.value !== '0') {
        urlQueryParams['orderBy'] = requestParams['orderBy']
        urlQueryParams[PROFILE_NAME_PARAM] = requestParams[PROFILE_NAME_PARAM]
      }
    } else {
      delete urlQueryParams['orderBy']
      delete urlQueryParams[PROFILE_NAME_PARAM]
    }

    if (searchTerm.length) {
      urlQueryParams[SEARCHTERM] = searchTerm
      urlQueryParams[PROFILE_NAME_PARAM] = SORTING_NOT_CLUSTERED.includes(sortOption?.value || '')
        ? SEARCH_TERM_PROFILE_NAME
        : SEARCH_TERM_GROUPING_PROFILE_NAME
    }

    requestParams['offset'] = offset ?? 0

    if (!isNaN(limit)) {
      requestParams['limit'] = limit
      urlQueryParams['page'] = Math.floor((offset + limit) / defaultPageLimit) || 1
    }

    return { requestParams: { ...requestParams, ...omit(urlQueryParams, 'page') }, urlQueryParams }
  }

  async getInventoryAvailabilityData(
    partNumber: string,
    storeId: string
  ): Promise<{
    isInventoryPresent: boolean
    isProductAvailableAndPresentInInventory?: boolean
  }> {
    try {
      const { data } = await inventoryavailabilityService.getInventoryAvailabilityByProductId({
        productIds: partNumber,
        storeId,
      })
      const onlineInventory = (data.InventoryAvailability || []).find((inventory) => inventory)

      if (!onlineInventory) {
        return { isInventoryPresent: false }
      }

      const { availableQuantity, inventoryStatus } = onlineInventory
      const isProductAvailableAndPresentInInventory =
        inventoryStatus === 'Available' && parseFloat(availableQuantity || '') > 0

      return {
        isInventoryPresent: true,
        isProductAvailableAndPresentInInventory,
      }
    } catch (e) {
      return { isInventoryPresent: false }
    }
  }

  /** Returns the appropriate facet title */
  getFacetTitle(facet: Facet): string {
    return facet.value === FACET_BRAND_TITLE || this.isCategoryFacet(facet)
      ? facet.extendedData!.fname
      : facet.name
  }

  isCategoryFacet(facet: Facet): boolean {
    return facet.value === FACET_CATEGORY_TITLE
  }

  isFacetSelected(facet: Facet, entry: Facet['entry'][number], selectedFacets: SelectedFacets) {
    const value: string =
      (this.isCategoryFacet(facet) ? FACET_CATEGORY_VALUE_PREFIX : '') + entry.value

    return selectedFacets[value] !== undefined
  }

  /** @description Sorts SKUs of products to have 'Standard' size as first */
  static sortProductSKUsBySize(product: ProductBase) {
    if (!product.cluster?.length) {
      return
    }

    product.cluster.forEach((moco) => {
      const skus = moco.sKUs || moco.items || []

      if (skus.length > 1) {
        skus.sort((a, b) => {
          const aSize = getSize(a)
          const bSize = getSize(b)

          if (aSize === STANDARD_FRAME_DISPLAY_SIZE) {
            return -1
          } else if (bSize === STANDARD_FRAME_DISPLAY_SIZE) {
            return 1
          } else {
            return 0
          }
        })
      }
    })
  }
  isValidXPriceDate(price: ProductXPrice) {
    // Final fix for Chrome, Firefox and Safari:
    const fixDate = (dateString) => {
      const replaced = dateString.replace(/-/g, ' ').replace('.', ':')

      const parts = replaced.split(' ')

      const monthNames = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
      ]
      const monthNumber = monthNames.indexOf(parts[1])

      const timeParts = parts[3].split(':')
      let hour = parseInt(timeParts[0], 10)
      if (parts[4] === 'PM' && hour < 12) hour += 12
      if (parts[4] === 'AM' && hour === 12) hour = 0

      const seconds = timeParts[2]
        ? parseInt(timeParts[2].split('.')[0], 10)
        : 0

      return new Date(
        Date.UTC(
          2000 + parseInt(parts[2], 10),
          monthNumber,
          parseInt(parts[0], 10),
          hour,
          parseInt(timeParts[1], 10),
          seconds
        )
      )
    }

    const startDate = price?.startDate && fixDate(price?.startDate)
    const endDate = price?.endDate && fixDate(price?.endDate)

    const isValidStartDate = startDate && new Date(startDate) < new Date()
    const isValidEndDate =
      endDate === undefined || (endDate && new Date(endDate) > new Date())

    return isValidStartDate && isValidEndDate
  }

  getXPriceModel(xprice: ProductXPrice): PriceModel | null {
    const checkDates = this.isValidXPriceDate(xprice)
    if (!checkDates) return null

    return { currency: xprice.currency, price: xprice.price }
  }
}

export default new ProductService()
