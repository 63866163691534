/**
 * Loader component is used to give a feedback regarding the loading of the page or the interaction with an external API.
 * Loader component wrap the MaterialUI CircularProgress component and add custom props to it
 * Check basic documentation here https://material-ui.com/components/progress/
 * */

import React from 'react'
import {
  StyledLoaderPlaceholder,
  StyledLoaderContainer,
  StyledLoaderSpinner,
} from './StyledLoader.style'
import { LoadingIcon } from '../Icons'
import { ProgressProps } from './loader.type'

export const StyledLoader: React.FC<ProgressProps> = ({ overlayVisible, contained, labelText, htmlColor, ...props }) => {
  return contained ? (
    <StyledLoaderSpinner {...props}>
      <LoadingIcon htmlColor={htmlColor} />
      {labelText}
    </StyledLoaderSpinner>
  ) : (
    <StyledLoaderContainer overlayVisible={overlayVisible}>
      <StyledLoaderPlaceholder>
        <StyledLoaderSpinner {...props}>
          <LoadingIcon htmlColor={htmlColor} />
        </StyledLoaderSpinner>
        {labelText}
      </StyledLoaderPlaceholder>
    </StyledLoaderContainer>
  )
}
