import axios from 'axios'
import { SiteInfo } from '../../../redux/reducers'
import RequestService from '../../../services/RequestService'

export type NewsletterItem = {
  id?: string
  newsletter_id: string
  firstName?: string
  email: string
  from?: string
}

/**
 * {
    "newsletter_id": "arn.newsletter",
    "email": "pietro.ferraresi@abstract.it"
   }
 */

export class NewsletterService {
  static async subscribeToNewsletter(newsletterItem: NewsletterItem) {
    const { newsletter_id, firstName, email, from } = newsletterItem

    return RequestService.request({
      body: {
        newsletter_id,
        firstName,
        email,
        from,
      },
      method: 'POST',
      path: '/store/{storeId}/newsletter',
    })
  }

  static async unsubscribeFromNewsletter(newsletterItem: Pick<NewsletterItem, 'email'>) {
    const { email } = newsletterItem

    return RequestService.request({
      body: {
        email,
      },
      method: 'POST',
      path: '/store/{storeId}/newsletter/optOut',
    })
  }
}
