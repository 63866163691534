import * as searchActions from '../../../redux/actions/search'

import Axios, { Canceler } from 'axios'
import { ClickAwayListener, MenuItem } from '@material-ui/core'
//Custom libraries
import {
  CommerceEnvironment,
  KEY_CODES,
  SEARCHTERM,
  SEARCH_TERM_GROUPING_PROFILE_NAME,
  SUGGESTIONS_KEYWORDS_PROFILE_NAME,
} from '../../../constants/common'
import { KEYWORD_LIMIT, PAGINATION_CONFIGS } from '../../../configs/catalog'
import { Link, useHistory, useLocation } from 'react-router-dom'
//Standard libraries
import React, { ChangeEvent, useEffect } from 'react'
//UI
import { StyledMenuTypography, StyledSearchBar, StyledTextField } from '../../StyledUI'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Log from '../../../services/Log'
import { OK } from 'http-status-codes'
import { ProductBase } from '../../../types/product'
import ProductService from '../../../services/ProductService'
//Redux
import { currentContractIdSelector } from '../../../redux/selectors/contract'
import debounce from 'lodash/debounce'
import getDisplayName from 'react-display-name'
import searchDisplayService from '../../../foundation/apis/transaction/searchDisplay.service'
import { sendSearchEvent } from '../../../foundation/analytics/tealium/lib'
import siteContentService from '../../../foundation/apis/search/siteContent.service'
import styled from 'styled-components'
import theme from '../../../themes'
import { themeModeSelector } from '../../../redux/selectors/theme'
//Foundation libraries
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'

const SearchTextField = styled(StyledTextField)`
  label {
    &.Mui-focused {
      transform: translate(16px, -6px) scale(1);
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }

  & > div {
    height: 40px;
    padding: ${({ theme }) => theme.spacing(0.25) + 'px ' + theme.spacing(1)}px;

    &.Mui-focused {
      fieldset {
        border-color: ${({ theme }) => theme.palette.secondary.greyLightmode} !important;
        border-width: 1px !important;
      }
    }

    &.Mui-error {
      fieldset {
        border-color: ${({ theme }) => theme.palette.custom.warningRed} !important;
      }

      & + p {
        margin-left: 0;
        color: ${({ theme }) => theme.palette.custom.warningRed} !important;
      }
    }
  }

  fieldset,
  &:hover fieldset {
    border: 1px solid ${({ theme }) => theme.palette.secondary.greyLightmode};
    border-color: ${({ theme }) => theme.palette.secondary.greyLightmode};
  }

  input,
  input::placeholder {
    font-size: ${({ theme }) => theme.spacing(1)}px;
  }

  input::placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.palette.secondary.greyDarkmode};
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 84%;
    margin-left: 1px;

    input,
    input::placeholder {
      font-size: 16px;
    }

    & > div {
      height: 49px;
      padding: ${({ theme }) => theme.spacing(0.5) + 'px ' + theme.spacing(1)}px;
    }
  }
`

const SearchErrorTypography = styled(StyledMenuTypography)`
    ${({ theme }) => theme.breakpoints.up('sm')} {
      font-size: 16px;
    }
    font-size: theme.spacing(1)}px;
`

const SearchResultsTypography = styled(StyledMenuTypography)`
    ${({ theme }) => theme.breakpoints.up('sm')} {
      font-size: 16px;
    }
    font-size: theme.spacing(1)}px;
`
const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.dark};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`

interface IProductSearchResponse {
  contents: ProductBase[]
  facets: any[]
  next: string
  previous: string
  total: number
  searchTerm: string
}

interface SearchBarProps {
  onClose: () => void
  setProductSearch: (e: IProductSearchResponse | undefined) => void
  setLoadingSearch: (e: boolean) => void
}

const SearchBar: React.FC<SearchBarProps> = ({ onClose, setProductSearch, setLoadingSearch }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { basePath } = useStoreIdentity()
  const { mySite } = useSite()
  const dispatch = useDispatch()

  const widgetName = getDisplayName(SearchBar)
  const contractId = useSelector(currentContractIdSelector)
  const [keywordSuggestions, setKeywordSuggestions] = React.useState<Array<Object>>([])
  const [categorySuggestions, setCategorySuggestions] = React.useState<Array<Object>>([])

  const [input, setInput] = React.useState('')
  const [nameList, setNameList] = React.useState<Array<string>>([])
  const [index, setIndex] = React.useState(0)
  let nameListIndex = 1

  const [showKeywords, setShowKeywords] = React.useState(false)
  const [showCategories, setShowCategories] = React.useState(false)

  const [categories, setCategories] = React.useState<Array<string>>([])
  const [categoriesUrl, setCategoriesUrl] = React.useState<Map<any, any>>(() => new Map())

  const [inputDisabled, setinputDisabled] = React.useState(true)
  const [enabledText, setEnabledText] = React.useState<boolean>(false)
  const [searchReload, setSearchReload] = React.useState<boolean>(false)

  const searchField = t('SearchBar.SearchField')
  const keywordTitle = t('SearchBar.KeywordTitle')
  const categoryTitle = t('SearchBar.CategoryTitle')

  const debouncedCallSearch = React.useCallback(
    debounce(() => {
      setSearchReload(true)
    }, 1000),
    []
  )

  useEffect(() => {
    if (searchReload) {
      searchSuggestionsProduct(input.trim() + '*')
      retrieveSuggestions(input.trim())
      setSearchReload(false)
    }
  }, [searchReload])

  const clearSuggestions = () => {
    setIndex(0)
    setKeywordSuggestions([])
    setCategorySuggestions([])
    setShowKeywords(false)
    setShowCategories(false)
  }

  const clearSuggestionsAndUpdateInputField = (str: string) => {
    clearSuggestions()
    str = callRegex(str)
    setInput(str)
    onClose()
  }

  const clearKeywords = () => {
    dispatch(searchActions.KEYWORDS_RESET_ACTION(''))
  }

  const setKeywordsToLocalStorage = (list: string[]) => {
    dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list))
  }
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  useEffect(() => {
    if (mySite) {
      const catalogId = mySite?.catalogID
      const parameters: any = {
        responseFormat: 'application/json',
        suggestType: ['Category', 'Brand'],

        contractId: contractId,
        catalogId: catalogId,
        ...payloadBase,
      }

      siteContentService
        .findSuggestionsUsingGET(parameters)
        .then((res) => {
          if (res.status === OK) {
            const categoriesResponse = res?.data.suggestionView[0].entry
            generateCategoriesList(categoriesResponse)
            generateCategoriesURL(categoriesResponse)
            setinputDisabled(false)
          }
        })
        .catch((e) => {
          Log.error(e.message)
        })
    }

    return () => {
      cancels.forEach((cancel) => cancel())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite])

  useEffect(() => {
    const queryString = location.search
    const params = new URLSearchParams(queryString)
    const searchTermValue = params.get(SEARCHTERM)
    if (searchTermValue === null) {
      setInput('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const generateCategoriesList = (categoriesResponse: any[]) => {
    const lists: string[] = []
    for (let i = 0; i < categoriesResponse.length; i++) {
      lists.push(categoriesResponse[i].fullPath)
    }
    setCategories(lists)
  }

  const generateCategoriesURL = (categoriesResponse: any[]) => {
    const categoriesUrl = new Map()
    for (let i = 0; i < categoriesResponse.length; i++) {
      let url = categoriesResponse[i].seo ? categoriesResponse[i].seo.href : ''
      categoriesUrl.set(categoriesResponse[i].fullPath, url)
    }
    setCategoriesUrl(categoriesUrl)
  }

  const handleLookAheadSearch = (event: ChangeEvent, type: string) => {
    event.persist()

    const element = event.currentTarget as HTMLInputElement

    setInput(element.value)
    debouncedCallSearch()
  }

  const retrieveSuggestions = (searchTerm: string) => {
    if (searchTerm.length > 1) {
      setTimeout(function () {
        const storeID = mySite.storeID
        const contractId = mySite.contractId
        const catalogId = mySite.catalogID

        const parameters: any = {
          responseFormat: 'application/json',
          storeId: storeID,
          term: searchTerm,
          limit: KEYWORD_LIMIT,
          contractId: contractId,
          catalogId: catalogId,
          profileName: SUGGESTIONS_KEYWORDS_PROFILE_NAME,
          ...payloadBase,
        }

        siteContentService.findKeywordSuggestionsByTermUsingGET(parameters).then((res) => {
          if (res.status === OK) {
            const keywordSuggestions = res?.data.suggestionView[0].entry
            if (keywordSuggestions) {
              let list: string[] = []
              generateSuggestionList(keywordSuggestions, searchTerm, list)
              generateCatgoriesAndBrandsSuggestions(searchTerm, list)
              setNameList(list)
              setEnabledText(true)
            }
          }
        })
      }, 300)
    }
    clearKeywords()
    clearSuggestions()
  }
  const generateCatgoriesAndBrandsSuggestions = (userInput: string, listTemp: string[]) => {
    const regex = new RegExp(userInput, 'ig')
    const matchedCategories = categories?.filter((e) => e.match(regex))
    let lists: object[] = []
    if (matchedCategories) {
      for (let suggestion of matchedCategories) {
        if (lists.length === 4) {
          break
        }
        let suggestionSkeleton = JSON.parse(JSON.stringify(CommerceEnvironment.suggestionSkeleton))

        suggestionSkeleton.arrIndex = nameListIndex
        suggestionSkeleton.id = ''
        suggestionSkeleton.name = suggestion
        suggestionSkeleton.url = categoriesUrl.get(suggestion)
        nameListIndex++
        lists.push(suggestionSkeleton)
        listTemp.push(suggestion)
      }
    }
    setCategorySuggestions(lists)
    setShowCategories(lists.length !== 0)
  }

  const generateSuggestionList = (
    keywordSuggestions: any[],
    userInput: string,
    listTemp: string[]
  ) => {
    const lists: object[] = []

    listTemp.push(userInput)
    const listTemp2: string[] = []

    for (let suggestion of keywordSuggestions) {
      if (keywordSuggestions) {
        let suggestionSkeleton = JSON.parse(JSON.stringify(CommerceEnvironment.suggestionSkeleton))

        suggestionSkeleton.arrIndex = nameListIndex
        suggestionSkeleton.id = ''
        suggestionSkeleton.name = suggestion.term
        suggestionSkeleton.url = basePath + '/search?' + SEARCHTERM + '=' + suggestion.term
        listTemp.push(suggestion.term)
        lists.push(suggestionSkeleton)
        listTemp2.push(suggestion.term)
        nameListIndex++
      }
    }
    setKeywordSuggestions(lists)
    setKeywordsToLocalStorage(listTemp2)
    setShowKeywords(lists.length !== 0)
  }

  const callRegex = (str: string) => {
    const regex2 = new RegExp('>', 'ig')
    let arr: string[]
    if (str.match(regex2)) {
      arr = str.split('>')
      str = arr[arr.length - 1].trim()
    }
    return str
  }

  const onKeyDown = (e) => {
    let len = nameList ? nameList.length : 0
    let str = ''
    if (e.keyCode === KEY_CODES.UP) {
      e.preventDefault()

      if (index === 0) {
        return
      }
      setIndex(index - 1)
      if (nameList) {
        str = callRegex(nameList[index - 1])
        setInput(str)
      }
    } else if (e.keyCode === KEY_CODES.DOWN) {
      e.preventDefault()

      if (index === len - 1) {
        setIndex(0)
        if (nameList) {
          str = callRegex(nameList[0])
          setInput(str)
        }
        return
      }
      setIndex(index + 1)
      if (nameList) {
        str = callRegex(nameList[index + 1])
        setInput(str)
      }
    }
  }

  const submitSearch = (props: any) => {
    props.preventDefault()

    if (keywordSuggestions.length > 0 || categorySuggestions.length > 0) {
      if (input && input.trim() !== '') {
        let url = ''
        const storeID = mySite.storeID
        const searchTerm = input.trim() + '*'
        const parameters: any = {
          storeId: storeID,
          searchTerm: searchTerm,
          ...payloadBase,
        }
        searchDisplayService
          .getSearchDisplayView(parameters)
          .then((res) => {
            if (res.status === OK) {
              url = res?.data.redirecturl
              if (url === undefined) {
                url = basePath + '/search?' + SEARCHTERM + '=' + searchTerm
              }
              redirectTo(url)
            }
          })
          .catch((e) => {
            url = basePath + '/search?' + SEARCHTERM + '=' + searchTerm
            redirectTo(url)
          })
      }
    }
  }

  const redirectTo = (url: string) => {
    clearSuggestions()
    onClose()
    //redirect

    if (url.startsWith('http')) {
      window.location.href = url
    } else {
      history.push(url)
    }
  }

  const clickAway = (prev) => {
    if (!prev) onClose()
  }

  const searchSuggestionsProduct = (searchTerm: string) => {
    const storeID = mySite.storeID
    const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''

    if (searchTerm.length > 1) {
      setLoadingSearch(true)
      const paramsBase = {
        searchTerm: searchTerm,
        storeId: storeID,
        currency: defaultCurrencyID,
        contractId: contractId ? contractId : '',
        offset: PAGINATION_CONFIGS.pageDefaultOffset,
        limit: KEYWORD_LIMIT,
        profileName: SEARCH_TERM_GROUPING_PROFILE_NAME,
        ...payloadBase,
      }

      ProductService.get(paramsBase).then((res) => {
        sendSearchEvent(input, res?.total ?? 0)
        setProductSearch({ ...res, searchTerm })
        setLoadingSearch(false)
      })
    } else {
      setProductSearch(undefined)
      setLoadingSearch(false)
    }
  }

  const themeMode = useSelector(themeModeSelector, shallowEqual)

  return (
    <ClickAwayListener onClickAway={clickAway}>
      <StyledSearchBar>
        <form onSubmit={submitSearch} noValidate>
          <SearchTextField
            size="small"
            autoFocus
            autoComplete="off"
            type="text"
            disabled={inputDisabled}
            placeholder={searchField}
            value={input}
            name="searchTerm"
            onChange={(e) => handleLookAheadSearch(e, 'searchTerm')}
            onKeyDown={onKeyDown}
            inputProps={{ maxLength: 50, 'data-element-id': 'X_X_MainNav_Search' }}
            showValidationStatus={false}
          />
        </form>

        {showKeywords || showCategories ? (
          <ClickAwayListener onClickAway={() => {}}>
            <ul className="searchBar-results">
              {showKeywords && (
                <SearchResultCategory
                  isCategory={false}
                  basePath={basePath}
                  title={keywordTitle}
                  list={keywordSuggestions}
                  index={index}
                  clearSuggestionsAndUpdateInputField={clearSuggestionsAndUpdateInputField}
                />
              )}

              {showCategories && (
                <SearchResultCategory
                  isCategory={true}
                  basePath={basePath}
                  title={categoryTitle}
                  list={categorySuggestions}
                  index={index}
                  clearSuggestionsAndUpdateInputField={clearSuggestionsAndUpdateInputField}
                />
              )}
            </ul>
          </ClickAwayListener>
        ) : (
          <>
            {enabledText && (
              <SearchErrorTypography
                variant="body2"
                className="searchBar-resultsCategory"
                fontColor={
                  themeMode === 'dark'
                    ? theme.palette.secondary.greyDarkmode
                    : theme.palette.text.primary
                }>
                {t('SearchBar.NoMatchesFound')}
              </SearchErrorTypography>
            )}
          </>
        )}
      </StyledSearchBar>
    </ClickAwayListener>
  )
}

const SearchResultCategory = ({
  isCategory,
  basePath,
  title,
  list,
  index,
  clearSuggestionsAndUpdateInputField,
}) => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  return (
    <>
      <StyledMenuTypography
        variant="body2"
        className="searchBar-resultsCategory"
        fontColor={
          themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary
        }>
        {title}
      </StyledMenuTypography>
      {list?.map((e: any, i: number) => (
        <StyledLink
          data-element-id={'X_X_SearchPanel_Content'}
          key={`${title}-${i}`}
          to={isCategory ? `${basePath}${e.url}` : `${e.url}`}
          onClick={() => clearSuggestionsAndUpdateInputField(e.name)}>
          <MenuItem>
            <SearchResultsTypography
              fontColor={
                themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary
              }
              variant="body1"
              className={e.arrIndex === index ? 'active' : ''}
              key={e.arrIndex}
              id={`megamenu_department_${e.id}`}
              title={e.name}>
              {e.name}
            </SearchResultsTypography>
          </MenuItem>
        </StyledLink>
      ))}
    </>
  )
}

export { SearchBar }
