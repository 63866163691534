import { cmsImageCrops } from '../constants/ui'
import config from '../configs'
import qs from 'qs'
import queryString from 'query-string'
import { BASE } from '../constants/routes'

/**
 * Update URL query string values
 * @returns {string}
 */

export const formatPathWithBase = (path: string, basePath: string): string => {
  return path.includes(BASE) ? path.replace(BASE, basePath) : basePath + path
}

export const updateUrlParameter = (
  url: string,
  key: string,
  value: number | boolean | string | undefined | null
): string => {
  const [baseurl, search] = url.split('?')
  const querystring = qs.parse(search)
  querystring[key] = value == null ? '' : value.toString()

  return `${baseurl}?${qs.stringify(querystring)}`
}

export const updateUrl = (url, parameters?) => {
  const updatedUrl = queryString.stringifyUrl(
    {
      url,
      query: parameters,
    },
    {
      encode: false,
    }
  )
  window.history.pushState({}, '', updatedUrl)
}

export const fromUrlParamsToObject = (url: string) => {
  return queryString.parse(url, { decode: false })
}

export const getImageFromCMS = (url: string, crop?: string, width?: number | string) => {
  if (!url) {
    return ''
  }
  const rep = url.replace('/{cropName}/{width}', `/${crop || 'NOTFOUND'}/${width || 'NOTFOUND'}`)
  return `${config.cmsImageServerUrl}${rep}`
}

export const getSrcSetsImageFromCms = (type: string, url: string) => {
  const bannerCropOption = cmsImageCrops[type]
  const { desk, mobile, tablet } = bannerCropOption || {}
  const srcSets = {
    desk: getImageFromCMS(url, desk?.crop || '', desk?.width),
    tablet: getImageFromCMS(url, tablet?.crop || '', tablet?.width),
    mobile: getImageFromCMS(url, mobile?.crop || '', mobile?.width),
  }

  return srcSets
}

export const getVideoFromCMS = (url: string) => `${config.cmsImageServerUrl}/${url}`

/**
 * removeStoreSegment remove the store segment of the url
 * retrieved from CMS
 * @param  url : the url to parse
 *
 */
export const removeStoreSegment = (url) => {
  return url
    .split('/')
    .filter((item) => item.length > 0)
    .join('/')
    .replace('//', '/')
}
