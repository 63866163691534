import { Modal, useMediaQuery } from '@material-ui/core'
import { TFunction } from 'i18next'
import React, { FC, ReactNode, Ref, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { themeModeSelector } from '../../../redux/selectors/theme'
import theme from '../../../themes'
import { StyledAccordionSummary } from '../../StyledUI'
import { ArrowDownSlimIcon } from '../../StyledUI/Icons'
import SignOutButton from '../../widgets/sign-out-button/SignOutButton'
import { generateAccountPagePath } from './Account'
import {
  PageNav,
  PageNavList,
  PageNavListItem,
  StyledAccordionItemsContainer,
  StyledAccountNavAccordion,
} from './AccountPageLayout.style'
import AccountPageWishlistLink from './AccountPageWishlistLink'

const mobileLayoutIndentY = 16
const ACCOUNT_PAGE_NAV_MAIN_ITEM_NAME = 'AccountLinksGridView.Title' as const

const ACCOUNT_PAGE_NAV_ITEMS: (
  | {
      nameTranslationKey: string
      path: string
      Component?: any
    }
  | {
      Component: FC
      nameTranslationKey: string
      path?: any
    }
)[] = [
  {
    nameTranslationKey: ACCOUNT_PAGE_NAV_MAIN_ITEM_NAME,
    path: '',
  },
  {
    nameTranslationKey: 'AccountLinksGridView.PersonalInformation',
    path: ROUTES.ACCOUNT_CHILDREN.PERSONAL_INFORMATION,
  },
  {
    nameTranslationKey: 'ChangePassword.Title',
    path: ROUTES.ACCOUNT_CHILDREN.CHANGE_PASSWORD,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.AddressBookText',
    path: ROUTES.ACCOUNT_CHILDREN.ADDRESS_BOOK,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.WishListText',
    Component: AccountPageWishlistLink,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.OrderHistoryText',
    path: ROUTES.ACCOUNT_CHILDREN.ORDER_HISTORY,
  },
  {
    nameTranslationKey: 'AccountLinksGridView.SignOut',
    Component: SignOutButton,
  },
]

const matchPathWithLocation = (langCode: string, locationPathname: string, path?: string) =>
  matchPath(locationPathname, {
    exact: true,
    path: generateAccountPagePath(langCode, path || undefined),
  })

const StyledAccordion: FC<{
  activeNavItemNameTranslationKey: string
  isExpanded: boolean
  navAccordionRef?: Ref<HTMLDivElement | null>
  themeMode: string
  renderNavItems(): ReactNode
  setExpanded(v: boolean): void
  t: TFunction
}> = ({
  activeNavItemNameTranslationKey,
  isExpanded,
  navAccordionRef,
  themeMode,
  renderNavItems,
  setExpanded,
  t,
}) => (
  <StyledAccountNavAccordion
    className="arn-my-account_accordion"
    expanded={isExpanded}
    ref={navAccordionRef}
    themeMode={themeMode}
    onChange={(_, expanded) => setExpanded(expanded)}>
    <StyledAccordionSummary
      className="arn-my-account_accordion-summary"
      expandIcon={<ArrowDownSlimIcon />}>
      {t(activeNavItemNameTranslationKey)}
    </StyledAccordionSummary>
    <StyledAccordionItemsContainer
      className="arn-my-account_accordion-items-container"
      themeMode={themeMode}>
      {renderNavItems()}
    </StyledAccordionItemsContainer>
  </StyledAccountNavAccordion>
)

const AccountPageLayoutNav: FC<{ isShown: boolean }> = ({ isShown }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { langCode } = useStoreIdentity()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const themeMode = useSelector(themeModeSelector, shallowEqual)

  const navAccordionRef = useRef<HTMLDivElement | null>(null)

  const [isNavAccordionExpanded, setNavAccordionExpanded] = useState(false)

  const navAccordionBounding = navAccordionRef.current?.getBoundingClientRect()

  const activeNavItemNameTranslationKey = useMemo<string>(() => {
    const item = ACCOUNT_PAGE_NAV_ITEMS.find(({ path }) => {
      if (typeof path !== 'string') {
        return false
      }

      return matchPathWithLocation(langCode, location.pathname, path)
    })

    return item?.nameTranslationKey || 'AccountLinksGridView.Title'
  }, [location.pathname])

  useEffect(() => {
    if (isNavAccordionExpanded) {
      setNavAccordionExpanded(false)
    }
  }, [location.pathname])

  const renderNavItems = () =>
    ACCOUNT_PAGE_NAV_ITEMS.map(({ Component, nameTranslationKey, path }) => (
      <PageNavListItem
        key={nameTranslationKey}
        data-element-id="X_X_MyAccount_Summary"
        data-description={t(nameTranslationKey)}
        themeMode={themeMode}>
        {Component && <Component />}

        {typeof path === 'string' && (
          <NavLink
            className={
              !!matchPathWithLocation(langCode, location.pathname, path)
                ? 'account-page__nav-list__link--active'
                : ''
            }
            to={generateAccountPagePath(langCode, path || undefined)}>
            {t(nameTranslationKey)}
          </NavLink>
        )}
      </PageNavListItem>
    ))

  return (
    <PageNav isShown={isShown}>
      <PageNavList themeMode={themeMode}>
        <Modal
          style={{
            top: (navAccordionBounding?.top || 32) - mobileLayoutIndentY,
            left: navAccordionBounding?.left,
            right: mobileLayoutIndentY,
          }}
          open={!isDesktop && isNavAccordionExpanded}
          onClose={() => setNavAccordionExpanded(!isNavAccordionExpanded)}>
          <StyledAccordion
            activeNavItemNameTranslationKey={activeNavItemNameTranslationKey}
            isExpanded={isNavAccordionExpanded}
            themeMode={themeMode}
            renderNavItems={renderNavItems}
            setExpanded={setNavAccordionExpanded}
            t={t}
          />
        </Modal>

        {isDesktop ? (
          renderNavItems()
        ) : (
          <StyledAccordion
            activeNavItemNameTranslationKey={activeNavItemNameTranslationKey}
            isExpanded={false}
            navAccordionRef={navAccordionRef}
            themeMode={themeMode}
            renderNavItems={renderNavItems}
            setExpanded={setNavAccordionExpanded}
            t={t}
          />
        )}
      </PageNavList>
    </PageNav>
  )
}

export default AccountPageLayoutNav
