import React, { FC } from 'react'
import styled from 'styled-components'
import { useCms } from '../../providers/CmsProvider'
import PromotionBar from '../../widgets/promotion-bar'

const StyledAppHeader = styled.header`
  z-index: 2;
  position: sticky;
  top: 0;
`

const BaseLayoutTopBars: FC = () => {
  const { headerPlacements } = useCms()
  
  return !!headerPlacements.length ? (
    <StyledAppHeader>
      <PromotionBar data={headerPlacements} />
    </StyledAppHeader>
  ) : null
}

export default BaseLayoutTopBars
