import styled from 'styled-components'

export const StyledTable = styled.table<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: ${theme.palette.custom[themeMode === 'dark' ? 'greyDarkmode' : 'black']};
  }

  th, td {
    padding: ${theme.spacing(0.25)}px 0;
    text-align: left;
    vertical-align: top;

    &:first-child {
      padding-left: ${theme.spacing(1)}px;
    }

    &:last-child { 
      padding-right: ${theme.spacing(1)}px;
    }
  }
  
  th {
    font-size: 14px;
    font-weight: normal;
    color: ${theme.palette.custom[themeMode === 'dark' ? 'black' : 'warmGrey']};
    text-transform: uppercase;
  }

  tr {
    font-size: 12px;

    &:first-child td {
      padding-top: 10px;
    }
  }
`}
`
