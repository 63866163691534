import Axios, { Canceler } from 'axios'
import React, { useState, useCallback, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { forUserIdSelector } from '../../../redux/selectors/user'
import { StyledButtonAsLink } from '../../StyledUI'
import * as userAction from '../../../redux/actions/user'
import { themeModeSelector } from '../../../redux/selectors/theme'
import theme from '../../../themes'

const SignOutButton: FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const forUserId = useSelector(forUserIdSelector)
  const themeMode = useSelector(themeModeSelector,shallowEqual)

  const [cancels, setCancels] = useState<Canceler[]>([])

  const logOutUser = useCallback(() => {
    dispatch(
      userAction.LOGOUT_REQUESTED_ACTION({
        cancelToken: new Axios.CancelToken((c) => setCancels(cancels.concat([c]))),
      })
    )
  }, [dispatch])

  return forUserId ? null : (
    <StyledButtonAsLink
      customprops={{ isUnderlined: false, color: themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.primary.dark }}
      onClick={logOutUser}
      style={{ justifyContent: 'flex-start' }}>
      {t('AccountLinksGridView.SignOut')}
    </StyledButtonAsLink>
  )
}

export default SignOutButton
