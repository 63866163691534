import styled from 'styled-components'

export const StyledEmailPrescriptionCta = styled.a`
  margin: 0 0 0 1rem;

  display: inline-flex;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 1rem 0 0 0;
  }

  svg {
    width: 1rem;
    height: 1rem;

    margin-right: 0.25rem;

    fill: ${({ theme }) => theme.palette.custom.warningRed};
    border-radius: 0.5rem;
  }

  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.palette.custom.warningRed};
  }
`
