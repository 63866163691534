import Axios, { Canceler } from 'axios'
//Standard libraries
import { Box, Typography } from '@material-ui/core'
import React, { FC, useEffect, useMemo, useState } from 'react'
//UI
import { StyledAnchor, StyledButtonAsLink, StyledGrid, StyledTypography } from '../../StyledUI'
import { Trans, useTranslation } from 'react-i18next'
import { loginStatusSelector, userDetailsSelector } from '../../../redux/selectors/user'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import AddAddressForm from './AddAddressForm'
import { FETCH_USER_DETAILS_REQUESTED_ACTION } from '../../../redux/actions/user'
import { Redirect } from 'react-router-dom'
//Foundation libraries
import { SIGNIN } from '../../../constants/routes'
import getDisplayName from 'react-display-name'
import theme from '../../../themes'
import styled from 'styled-components'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { useCms } from '../../providers/CmsProvider'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useBreakpoints from '../../../hooks/useBreakpoints';

const StyledSecondaryText = styled.span`
  font-size: 0.75rem;
  line-height: 18px;
`
const StyledBox = styled(Box)`
  position: relative;
`
const StyledEditLink = styled(StyledButtonAsLink)`
  ${theme.breakpoints.down('sm')} {
    position: absolute;
    right: 5%;
    top: 10%;
  }
`

const AddressBook: FC = () => {
  const widgetName = getDisplayName(AddressBook)
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { privacyPagePath } = useCms()
  const { country: storeCountry } = useStoreIdentity()
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const loginStatus = useSelector(loginStatusSelector)
  const userDetails = useSelector(userDetailsSelector)
  const contactList = userDetails?.contact || []
  const themedFontColor =
    theme.palette.secondary[themeMode === 'dark' ? 'greyDarkmode' : 'greyLightmode']
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'))
  const displayedUserAddress = useMemo(() => {
    if (!contactList.length) {
      return null
    }

    const currentCountryContacts = contactList.filter(
      (contact) => contact.country.toLowerCase() === storeCountry.toLowerCase()
    )

    return currentCountryContacts[currentCountryContacts.length - 1] || null
  }, [contactList, contactList.length])

  const contactInfoEmail = useMemo<string>(() => userDetails?.email1 || '', [userDetails?.email1])
  const contactInfoPhone = useMemo<string>(
    () => displayedUserAddress?.phone1 || userDetails?.phone1 || '',
    [displayedUserAddress?.phone1, userDetails?.phone1]
  )

  const displayedUserAddressArray: string[] = useMemo(() => {
    if (!displayedUserAddress) {
      return []
    }

    const {
      firstName,
      lastName,
      addressLine = [],
      zipCode,
      city,
      state,
      country,
    } = displayedUserAddress

    return [
      `${firstName} ${lastName}`,
      addressLine[0] || '',
      addressLine[1] || '',
      zipCode || '',
      city || '',
      state || '',
      country,
    ]
  }, [displayedUserAddress])

  const [isAddingAddress, setIsAddingAddress] = useState(false)
  const { isTablet } = useBreakpoints()

  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  useEffect(() => {
    if (!userDetails) {
      dispatch(FETCH_USER_DETAILS_REQUESTED_ACTION(payloadBase))
    }

    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [])

  if (!loginStatus) {
    return <Redirect to={SIGNIN} />
  } else {
    return (
      <StyledGrid item xs={12}>
        {!isTablet && <Box
          px={1}
          py={1}
          border={`1px solid ${
            themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary
          }`}
          borderBottom="0">
          <StyledTypography component="h1" variant="body1">
            {t('AddressBook.AddressBook')}
          </StyledTypography>
        </Box>}
        <StyledBox
          px={1}
          py={1}
          border={`1px solid ${
            themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary
          }`}>
          <Box mb={isAddingAddress ? '19px' : 0.625}>
            <Typography variant="body1">
              {t(
                isAddingAddress
                  ? 'AddressForm.Labels.ShippingAndBillingAddress'
                  : 'AddressBook.AddressType'
              )}
            </Typography>
          </Box>

          {!isAddingAddress && (
            <Box
              display="flex"
              gridGap={isMobile ? '0px' : '30px'}
              flexDirection={isMobile ? 'column' : 'row'}>
              <Box display="flex" flexDirection="column" gridGap="10px" mb={isMobile ? 0 : 0.75}>
                <StyledSecondaryText>
                  {t('AddressForm.Labels.ShippingAndBillingAddress')}
                  {displayedUserAddress && ':'}
                </StyledSecondaryText>
                {!displayedUserAddress && (
                  <Box display="flex" flexDirection="row">
                    <Box mr={0.625} lineHeight={1}>
                      <StyledSecondaryText>{t('AddressBook.Name')}:</StyledSecondaryText>
                    </Box>
                    <StyledSecondaryText>
                      {userDetails?.firstName} {userDetails?.lastName}
                    </StyledSecondaryText>
                  </Box>
                )}
              </Box>

              <Box display="flex" flexDirection="row" alignItems="flex-start" gridGap="30px">
                {displayedUserAddress && (
                  <Box display="flex" flexDirection="column">
                    {displayedUserAddressArray.map((text) => (
                      <StyledSecondaryText>{text}</StyledSecondaryText>
                    ))}
                  </Box>
                )}

                <StyledEditLink
                  data-element-id={
                    displayedUserAddress ? 'X_X_AddressBook_Edit' : 'X_X_AddressBook_Add'
                  }
                  style={{
                    fontSize: 12,
                    textDecoration: 'underline',
                    textTransform: 'uppercase',
                  }}
                  customprops={{
                    color:
                      themeMode === 'dark'
                        ? theme.palette.text.contrastText
                        : theme.palette.primary.dark,
                  }}
                  onClick={() => setIsAddingAddress(true)}>
                  {t(displayedUserAddress ? 'AddressBook.EditButton' : 'AddressBook.AddButton')}
                </StyledEditLink>
              </Box>
            </Box>
          )}

          {isAddingAddress && (
            <AddAddressForm
              formData={displayedUserAddress}
              payloadBase={payloadBase}
              onEditEnd={() => setIsAddingAddress(false)}
            />
          )}
        </StyledBox>

        {!isAddingAddress && (
          <Box
            px={1}
            py={1}
            border={`1px solid ${
              themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary
            }`}
            borderTop="0">
            <Box mb={0.75}>
              <Typography variant="body1">{t('AddressForm.Labels.ContactInformation')}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" gridGap="10px">
              <Box display="flex" flexDirection="row" gridGap="30px">
                <Box display="flex" flexDirection="column" gridGap="10px">
                  <StyledSecondaryText>{t('AddressForm.Labels.email1')}:</StyledSecondaryText>
                  <StyledSecondaryText>{t('AddressForm.Labels.phone2')}:</StyledSecondaryText>
                </Box>
                <Box display="flex" flexDirection="column" gridGap="10px">
                  <StyledSecondaryText>{contactInfoEmail}&nbsp;</StyledSecondaryText>
                  <StyledSecondaryText>{contactInfoPhone}&nbsp;</StyledSecondaryText>
                </Box>
              </Box>
              {privacyPagePath && (
                <Box display="flex" flexDirection="row" mt={0.75}>
                  <Box color={themedFontColor}>
                    <StyledSecondaryText>
                      <Trans i18nKey="AddressBook.PrivacyPolicyEU">
                        <StyledAnchor
                          customprops={{ color: themedFontColor, isUnderlined: true }}
                          to={privacyPagePath}></StyledAnchor>
                      </Trans>
                    </StyledSecondaryText>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        )}
      </StyledGrid>
    )
  }
}

export default AddressBook
