import React from 'react'
import { Container } from '@material-ui/core'
import styled from 'styled-components'

const CustomContainer = (props: any) => (
  <Container disableGutters={true} maxWidth={false} {...props} />
)

const StyledContainer = styled(CustomContainer)`
  ${({ theme }) => `
    padding: ${theme.spacing()}px;
    ${theme.breakpoints.up('sm')} {
      padding: ${theme.spacing(2)}px;
    }
  `}
`

export default StyledContainer
