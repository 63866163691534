import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { StyledButtonAsLink } from '../../StyledUI'
import styled from 'styled-components'
import theme from '../../../themes'
import { CountryBaseLink } from '../../StyledUI/StyledLink/StyledLink'

export const StyledColorVariantsButtons = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
    justify-content: space-between;
    padding-left: 13px;
    padding-right: 13px;
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: 15px;
  }
`

export const StyledProductTile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-top: 30px;
  padding-bottom: 20px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 40px;
    padding-bottom: 0;
  }
`

export const StyledProductTileWrapper = styled.div`
  font-size: 0.75rem;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 220px;
  height: 100%;
  font-size: 16px;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 1rem;

    &:hover {
      & ${StyledColorVariantsButtons} {
        visibility: visible;
      }
    }

    &:not(:hover) {
      & ${StyledColorVariantsButtons} {
        visibility: hidden;
      }
    }
  }

  &.arn-product-tile-ymal {
    position: relative;
    aspect-ratio: 1/1;
    @supports not (aspect-ratio: 1 / 1) {
      min-height: 356px;
    }
  }
`
export const StyledProductTileHeader = styled.header`
  z-index: 1;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.up('md')} {
    top: 1rem;
    left: 1rem;
    right: 1rem;
  }
`

export const StyledProductTileAttributes = styled.div`
  color: ${({ theme }) => theme.palette.text.light};
  font-size: 12px;
  line-height: 18px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const StyledProductTileBadgeIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  & > svg:not(:last-child) {
    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-right: 0.625rem;
    }
    margin-right: 0;
  }

  svg {
    color: ${({ theme }) => theme.palette.custom.black};
    height: 16px;
    width: 16px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      height: 24px;
      width: 24px;
  }
  }
`
export const StyledProductTileImageContainerLink = styled(CountryBaseLink) <{ disabledClick?: boolean }>`
  overflow: hidden;
  min-height: 58%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 100%;
  }

  span {
    width: 100%;
    margin: 0 auto;
  }

  &.arn-product-tile__unique-image {
    align-items: center;
  }
  &.arn-product-tile__hp {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      box-shadow: ${({ theme }) => `inset -1px 0px 0px 0px ${theme.palette.text.primary}`};
      z-index: 2;
      .lazy-load-image-background {
        padding: 0 3px;
      }
    }
  }

  img {
    vertical-align: bottom;
  }

  ${({ disabledClick }) => `${disabledClick ? 'pointer-events: none;' : ''}`}
`

export const StyledColorVariantsBtn = styled(StyledButtonAsLink)`
  font-size: 0;

  &:disabled {
    opacity: 0.5;
  }
`

export const StyledColorVariantName = styled.div<{ isSearchDrawerOpen?: boolean }>`
${({ isSearchDrawerOpen }) => `${isSearchDrawerOpen ? '' : 'white-space: nowrap;'}`}
  overflow: hidden;
  width: 100%;
  flex: 1;

  text-overflow: ellipsis;
  text-align: center;
  font-size: 1rem;
  color: ${theme.palette.text.primary};
`

export const StyledColorVariantsSlide = styled(Slide)`
  span.lazy-load-image-background {
    height: 100%;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
`

export const StyledColorVariantsProvider = styled(CarouselProvider)``

export const StyledColorVariantsSlider = styled(Slider)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const StyledProductTileFooterWrapper = styled.div<{ themeMode: string }>`
  padding-left: ${({ theme }) => `${theme.spacing(0.625)}px`};
  padding-right: ${({ theme }) => `${theme.spacing(0.625)}px`};
  padding-bottom: ${({ theme }) => `${theme.spacing() / 2}px`};
  padding-top: 0;
  ${({ theme, themeMode }) => `
  box-shadow: 0 0 0 1px ${themeMode === 'dark' ? theme.palette.primary.contrastText : 'transparent'
    };
  background-color: ${theme.palette.background.default};
  `}

  &.hp-variant {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      border-right: 1px solid ${({ theme }) => `${theme.palette.text.primary}`};
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-bottom: 13px;
    padding-top: 0;
    padding-left: ${({ theme }) => `${theme.spacing()}px`};
    padding-right: ${({ theme }) => `${theme.spacing()}px`};
  }
`

export const StyledProductTileFooter = styled.footer`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.custom.black};
  font-size: 16px;
  height: 93px;
  padding: 2px 0 0 0;
  
  .product-price__current {
    color: ${({ theme }) => theme.palette.text.primary};
    
    &-value {
      font-weight: bold;
    }
  }

  .product-price__discount {
    margin-right: 0;
    margin-top: 4px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      margin-left: auto;
      margin-top: unset;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: space-between;
    height: auto;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: 8px 0 0 0;
  }
`

export const StyledProductTileBottomInner = styled.div``

export const StyledTileFooterCol = styled.div`
  ${({ theme }) => theme.breakpoints.up('ms')} {
    overflow: hidden;
    flex: 1;
  }
`

export const StyledProductNameLink = styled(CountryBaseLink) <{ disabledClick?: boolean }>`
${({ disabledClick }) => `${disabledClick ? 'pointer-events: none;' : ''}`}
  ${({ theme }) => `
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  color: ${theme.palette.text.primary};
  font-size: 16px;

  ${theme.breakpoints.up('sm')} {
    font-size: inherit;
  }
`}
`

export const StyledProductVariants = styled.div`
  grid-area: 2 / 1 / span 1 / span 4;
  color: ${({ theme }) => theme.palette.text.primary};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-area: unset;
  }

  & > * {
    margin-right: ${({ theme }) => `${theme.spacing() / 4}px`};
  }
`
