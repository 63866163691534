import {
  ContentPlp,
  StyledGridProductLayout,
} from '../../StyledUI/StyledSearchDrawer/StyledSearchDrawer'
import {
  ICMCollection,
  ICMExternalProduct,
  IPlacement,
  IPlacementItem,
} from '../../../types/teaser'
import { ProductTile } from '../../widgets/product-tile'
import React from 'react'
import SearchBanner from '../../widgets/cms-content/search-banner'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import useThemeMode from '../../../hooks/useThemeMode'
import cmsService from '../../../foundation/apis/cms/cms.service'

const StyledTitle = styled.h4<{ themeMode: string }>`
  ${({ theme }) => `
  margin: 0;
  padding: ${theme.spacing(1)}px;
  border-left: 1px solid ${theme.palette.text.primary};
  background-color: ${theme.palette.background.default};
  color: ${theme.palette.text.primary};
  font-size: 28px;
  font-weight: normal;

  ${theme.breakpoints.down('xs')} {
    font-size: 28px;
    border-left: none;
  }
`}
`

interface IplacementTrending extends IPlacement {
  teasableItems: IPlacementItem[]
}

interface IProps {
  placement: IplacementTrending
  placementName: IHeaderPlacementName
  trendingProduct: ICMCollection | undefined
  onProductClick(): void
}

interface IHeaderPlacementName {
  benefitBarName: string
  searchBannerName: string
  searchTrendingNowName: string
}

const SearchTrendingBanner: React.FC<IProps> = ({
  placement,
  placementName,
  trendingProduct,
  onProductClick,
}) => {
  const { themeMode } = useThemeMode()
  const { t } = useTranslation()

  const trendingNowItems = (trendingProduct?.teasableItems as ICMExternalProduct[]) || []

  return (
    <>
      {placement && (
        <div {...cmsService.getPlacementMetaDataAttribute(placementName.searchBannerName)}>
          <StyledTitle themeMode={themeMode}>{t('SearchBar.ShopTheCollection')}</StyledTitle>
          <SearchBanner teaser={placement.teasableItems[0]} />
        </div>
      )}

      <StyledTitle themeMode={themeMode}>{trendingProduct?.collectionTitle}</StyledTitle>
      <ContentPlp
        {...cmsService.getPlacementMetaDataAttribute(placementName.searchTrendingNowName)}>
        {trendingNowItems?.map((item, index) =>
          item ? (
            <StyledGridProductLayout themeMode={themeMode} key={item.id}>
              <ProductTile product={item.productData} tileIndex={index} onClick={onProductClick} />
            </StyledGridProductLayout>
          ) : null
        )}
      </ContentPlp>
    </>
  )
}

export default SearchTrendingBanner
