import { RoundButton } from '../../../StyledUI'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { PROMO_BAR_HEIGHT, PROMO_BAR_HEIGHT_MOBILE, PROMO_BAR_HEIGHT_TWO_ROWS, stickyTopBarCss } from '../../../../constants/ui'
import { promoBannerClassName } from '../../promo-banner'

export const StyledPLPHeaderWrapper = styled.div`
  ${({ theme }) => `
  max-width: 100vw;
  background: ${theme.palette.background.default};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${theme.spacing(1)}px;
  margin-bottom: ${theme.spacing(0.25)}px;
  padding: ${theme.spacing(1)}px ${theme.spacing(1)}px 0;

  h1 {
    text-transform: uppercase;
  }

  ${theme.breakpoints.up('sm')} {
    flex-direction: row;
    margin-bottom: ${theme.spacing(0.625)}px;

    ${promoBannerClassName} {
      width: 311px;
    }
  }
`}
`

export const StyledPLPHeaderDescription = styled(Typography)`
  ${({ theme }) => `
  padding-top: ${theme.spacing(0.75)}px;
  
  ${theme.breakpoints.up('sm')} {
    width: 50%;
    max-width: 577px;
  }
`}
`

export const ToggleFilterButton = styled(RoundButton)`
  ${({ theme }) => `
    min-width: auto;

  ${theme.breakpoints.up('sm')} {
    margin-left: auto;
    order: 1;
  }

  .arn-plp-header__filters-number {
    width: ${theme.spacing(1)}px;
    height: ${theme.spacing(1)}px;
    line-height: ${theme.spacing(0.9)}px;
    border-radius: 50%;
    text-align: center;
    background-color: ${theme.palette.background.dark};
    color: ${theme.palette.custom.geckoGreen};
    margin-left: ${theme.spacing(0.25)}px;
  }
`}
`

export const StyledPLPFiltersBarTitleWrapper = styled.div`
  ${({ theme }) => `
  height: 32px;
  max-height: 32px;
  display: none;
  opacity: 1;

  h1 {
    line-height: 1;
  }

  ${theme.breakpoints.up('sm')} {
    display: initial;
  }
  `}
`

export const StyledPLPFiltersBarButtonsWrapper = styled.div`
  ${({ theme }) => `
  display: flex;
  align-items: center;
  gap: 10px;

  & > * {
    height: 36px;
  }

  ${theme.breakpoints.up('sm')} {
    & > * {
      height: 40px;
    }
  }
  `}
`

export const StyledPLPFiltersBar = styled.div<{ isStuck: boolean; themeMode: string; hasPromoBar: boolean }>`
  ${stickyTopBarCss}
  top: ${({ hasPromoBar }) => (hasPromoBar ? `${PROMO_BAR_HEIGHT_MOBILE}` : '-1px')};
  z-index: 2;

  .first-btn-skeleton {
    margin-right: 1rem;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    top: ${({ hasPromoBar }) => (hasPromoBar  ? `${PROMO_BAR_HEIGHT_TWO_ROWS}` : '-1px')};
    justify-content: space-between;
    padding-top: 14px;

    ${({ isStuck }) => (isStuck ? `${ToggleFilterButton} { margin-left: initial; }` : '')}
    ${({ isStuck }) =>
      !isStuck
        ? `${StyledPLPFiltersBarTitleWrapper} {
          overflow: hidden;
          height: 0px;
          max-height: 0;
          opacity: 0;
        }
    
        ${StyledPLPFiltersBarButtonsWrapper} { flex: 1 0 100%; }`
        : ''}
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    top: ${({ hasPromoBar }) => (hasPromoBar  ? `${PROMO_BAR_HEIGHT}` : '-1px')};
  }
`
