import { Backdrop, Fade } from '@material-ui/core'
import React, { FC, memo, PropsWithChildren, ReactNode } from 'react'
import {
  StyledModalContainer,
  StyledModalHeader,
  StyledSubtitle,
  StyledModalContent,
  StyledMuiModalWithVariant,
  MODAL_VARIANTS_MAP,
  StyledModalChildren,
  StyledCloseIconeContainer,
} from './Modal.styles'
import { StyledTypography } from '../../StyledUI'
import Flexbox from '../../StyledUI/Flexbox/Flexbox'
import { CloseBigIcon } from '../../StyledUI/Icons/Icons'
import useBreakpoints from '../../../hooks/useBreakpoints'

export interface ModalProps {
  title: string
  headerIcon?: ReactNode
  subtitle: string
  content?: string
  variant?: keyof typeof MODAL_VARIANTS_MAP // 'md' is default
  visible: boolean
  onClose: () => void
  isSticky?: boolean
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  title,
  headerIcon,
  subtitle,
  content,
  children,
  variant = 'md',
  visible,
  onClose,
  isSticky,
}) => {
  const { isTablet } = useBreakpoints()
  return (
    <StyledMuiModalWithVariant
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={visible}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      variant={variant}
      onClose={onClose}>
      <Fade in={visible}>
        <StyledModalContainer isSticky={isSticky}>
          {isTablet && (
            <StyledCloseIconeContainer>
              <CloseBigIcon className="modal__header-close-icon" onClick={onClose} />
            </StyledCloseIconeContainer>
          )}

          <StyledModalHeader>
            {!!headerIcon && (
              <Flexbox
                className="modal__header-icon-container"
                align-items="center"
                padding="0 16px">
                {headerIcon}
              </Flexbox>
            )}

            <Flexbox flex-direction="column" flex={1}>
              <StyledTypography isUppercased className="modal__title">
                {title}
              </StyledTypography>
              <StyledSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
            </Flexbox>

            {!isTablet && <CloseBigIcon className="modal__header-close-icon" onClick={onClose} />}
          </StyledModalHeader>

          {content && <StyledModalContent dangerouslySetInnerHTML={{ __html: content }} />}

          {children && <StyledModalChildren isSticky={isSticky}>{children}</StyledModalChildren>}
        </StyledModalContainer>
      </Fade>
    </StyledMuiModalWithVariant>
  )
}

export default memo(Modal, (prev, next) => {
  const { title, subtitle, visible } = next

  return prev.visible === visible && prev.title === title && prev.subtitle === subtitle
})
