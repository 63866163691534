/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//fetch contract
export const FETCH_ELIGIBLE_CONTRACT_REQUESTED = 'FETCH_ELIGIBLE_CONTRACT_REQUESTED'
export const FETCH_ELIGIBLE_CONTRACT_SUCCESS = 'FETCH_ELIGIBLE_CONTRACT_SUCCESS'
export const FETCH_ELIGIBLE_CONTRACT_ERROR = 'FETCH_ELIGIBLE_CONTRACT_ERROR'

//swicth contract
export const CONTRACT_SWITCH_REQEUSTED = 'CONTRACT_SWITCH_REQEUSTED'
export const CONTRACT_SWITCH_ERROR = 'CONTRACT_SWITCH_ERROR'
