import React from 'react'
import {
  CopperWatchIcon,
  GoldWatchIcon,
  SilverWatchIcon,
  TortoiseWatchIcon,
  MultiColorWatchIcon,
  TransparentWatchIcon,
} from '../components/StyledUI/Icons'

export const FACET_COLORS = [
  {
    key: 'Beige',
    value: 'DBC5A4',
    type: 'plain',
  },
  {
    key: 'Black',
    value: '000000',
    type: 'plain',
  },
  {
    key: 'Blue',
    value: '3151BC',
    type: 'plain',
  },
  {
    key: 'Brown',
    value: '7A5400',
    type: 'plain',
  },

  {
    key: 'Grey',
    value: '9B9B9B',
    type: 'plain',
  },
  {
    key: 'Green',
    value: '308E02',
    type: 'plain',
  },
  {
    key: 'Gunmetal',
    value: '6A808C',
    type: 'plain',
  },
  {
    key: 'Orange',
    value: 'FF7000',
    type: 'plain',
  },
  {
    key: 'Pink',
    value: 'FF9D9D',
    type: 'plain',
  },
  {
    key: 'Red',
    value: 'CC0202',
    type: 'plain',
  },
  {
    key: 'Violet',
    value: '9E76FE',
    type: 'plain',
  },
  {
    key: 'Yellow',
    value: 'FFE300',
    type: 'plain',
  },
  {
    key: 'White',
    value: 'FFFFFF',
    type: 'plain',
  },
  {
    key: 'Copper',
    value: <CopperWatchIcon />,
    type: 'special',
  },
  {
    key: 'Silver',
    value: <SilverWatchIcon />,
    type: 'special',
  },
  {
    key: 'Tortoise',
    value: <TortoiseWatchIcon />,
    type: 'special',
  },
  {
    key: 'Transparent',
    value: <TransparentWatchIcon />,
    type: 'special',
  },
  {
    key: 'Gold',
    value: <GoldWatchIcon />,
    type: 'special',
  },
  {
    key: 'Multi',
    value: <MultiColorWatchIcon />,
    type: 'special',
  },
]
