import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import Log from '../../services/Log'
import { RootReducerState } from '../../redux/reducers'
export interface ShippingChargesWithoutPromotions {
  freeDeliveryPromotion: boolean
  shipModeCharges: number
  shipModeId: string
  shipModeName: 'Standard' | 'Green'
}
export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (builder) => ({
    getShippingCharges: builder.query<ShippingChargesWithoutPromotions[], string>({
      queryFn: async(args, api, _extraOptions, baseQuery) => {
        const orderId = args
        const state = api.getState() as RootReducerState
        const { storeID, transactionContext } = state.site.currentSite!

        try {
          const result = await baseQuery({
            headers: {
              'WCTrustedToken': state.user.WCTrustedToken,
              'WCToken': state.user.WCToken,
              'WCPersonalization': state.user.personalizationID
            },
            url: `${transactionContext}/store/${storeID}/cart/orderId/${orderId}/shipCharges`,
          })

          return (result.data as any)
            ? { data: transformGetShipping(result?.data) as ShippingChargesWithoutPromotions[] }
            : { error: result.error as FetchBaseQueryError }

        } catch (e) {
          Log.error('Could not retrieve shipping price')
          return {
            error: e,
          }
        }
      },
    }),
  }),
})

const transformGetShipping = (data) => {
  return data?.ships?.shipList || [] as ShippingChargesWithoutPromotions[]
}
// @ts-ignore
export const { useGetShippingChargesQuery } = orderApi
