import React, { FC } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import styled from 'styled-components'

interface WithLoadingProps {
  className?: string
  height?: string
  isLoading: boolean
  skeletonCount?: number
}

const StyledSkeletonContainer = styled.div<{ heightProp?: string }>`
  ${({ heightProp }) => `
  display: flex;
  width: 100%;
  height: ${heightProp ?? '100%'};

  & > div {
    display: flex;
    flex: 1;

    & > span {
      flex: 1;
    }
  }
  @supports not (aspect-ratio: 1 / 1) {
    display: block;
    padding-top: 25%;
    padding-bottom: 25%;
  }
`}
`

/** HOC to display Skeleton depending on `isLoading` prop instead of children component */
const WithLoading: FC<WithLoadingProps> = ({ children, height, isLoading, skeletonCount = 1 }) => (
  <>
    {isLoading ? (
      <>
        {[...Array(skeletonCount).keys()].map((_, index) => (
          <StyledSkeletonContainer
            key={index}
            aria-label="StyledSkeletonContainer"
            heightProp={height}>
            <SkeletonTheme color="transparent" highlightColor="rgba(0, 0, 0, 0.1)">
              <Skeleton style={{ borderRadius: 0, height: '100%', verticalAlign: 'top' }} />
            </SkeletonTheme>
          </StyledSkeletonContainer>
        ))}
      </>
    ) : (
      children
    )}
  </>
)

export default WithLoading
