import {
  ArrowLeftBigIcon,
  ArrowRightBigIcon,
  HeartFilledIcon,
  PolarizedIcon,
  RoxableIcon,
  SustainableIcon,
} from '../../StyledUI/Icons'
import { Box, capitalize, useTheme } from '@material-ui/core'
import {
  PRODUCT_ATTRIBUTE_TRUE_VALUE,
  getBadge,
  getDiscountedPriceBadges,
  getFrontColor,
  getIsCustomizable,
  getIsLimitedEdition,
  getIsOnlineExclusive,
  getIsPolarized,
  getIsSustainable,
  getLensesColorFacet,
  getModelCode,
  getModelName,
  getNew,
  getProductType,
  getSoldOut,
} from '../../../utils/productAttributes'
import { ProductBase, ProductSoldOutStatus } from '../../../types/product'
import ProductPrice, { ProductPriceProps } from '../../pages/product/components/ProductPrice'
import React, { useEffect, useMemo, useState } from 'react'
import {
  StyledColorVariantName,
  StyledColorVariantsBtn,
  StyledColorVariantsButtons,
  StyledColorVariantsSlide,
  StyledProductNameLink,
  StyledProductTileAttributes,
  StyledProductTileBadgeIcons,
  StyledProductTileFooter,
  StyledProductTileFooterWrapper,
  StyledProductTileHeader,
  StyledProductTileImageContainerLink,
  StyledProductTile,
  StyledProductTileWrapper,
  StyledProductVariants,
  StyledTileFooterCol,
} from './ProductTile.style'
import { StyledIconButton, StyledLink } from '../../StyledUI'
import { TFunction, useTranslation } from 'react-i18next'
import cloneDeep from 'lodash/cloneDeep'
import first from 'lodash/first'
import get from 'lodash/get'
import { shallowEqual, useSelector } from 'react-redux'
import ColorVariantSlider from './ColorVariantsSlider'
import Log from '../../../services/Log'
import { PRODUCT_SOLDOUT_STATUS } from '../../../constants/product'
import ProductImage from '../../product-image/ProductImage'
import { SET_LAST_SELECTED_PRODUCT_ACTION } from '../../../redux/actions/ui'
import { plpBadgesSelector } from '../../../redux/selectors/site'
import rxService from '../../../foundation/apis/rx-config/rx.service'
import styled from 'styled-components'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { useAppDispatch } from '../../../hooks/redux'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { usePageType } from '../../../foundation/hooks/usePageType'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { LinkProps, useLocation } from 'react-router-dom'
import { searchDrawerSelector } from '../../../redux/selectors/ui'

const siteName = process.env.REACT_APP_STORENAME

const StyledSoldOut = styled.span<{ backgroundEnabled: boolean }>`
  ${({ backgroundEnabled, theme }) => `
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  color: ${theme.palette.text.primary};
  ${backgroundEnabled ? `background-color: ${theme.palette.custom.geckoGreen}` : ''};
`}
`

export interface ICurrentBadges {
  isNew: boolean
  isBadge: boolean
  isLimitedEdition: boolean
  isOnlineExclusive: boolean
  isSustainable: boolean
  isCustomizable: boolean
  isPolarized: boolean
  isRoxable: boolean
}

const getItems = (product: ProductBase) => {
  return product?.sKUs ? product?.sKUs : product?.items
}

const getPriorityBadgeText = (badges, t: TFunction): string => {
  switch (true) {
    case badges.isBadge:
      return t('ProductTile.Labels.preRelease')
    case badges.isLimitedEdition:
      return t('ProductTile.Labels.limitedEdition')
    case badges.isOnlineExclusive:
      return t('ProductTile.Labels.onlineExclusive')
    case badges.isNew:
      return t('ProductTile.Labels.new')
    default:
      return ''
  }
}

export interface ProductTileProps<T extends ProductBase = any> {
  footerElementsDisplay?: {
    colors: boolean
    inWishlistAdded: boolean
  }
  product: T
  tileIndex: number
  variant?: string
  onClick?(): void
  onWishlistButtonClick?: (productId: string) => void
  isClustered?: boolean
}

const ProductTile = <T extends ProductBase>({
  footerElementsDisplay = { colors: true, inWishlistAdded: false },
  product,
  tileIndex,
  variant,
  onClick,
  onWishlistButtonClick,
  isClustered,
}: ProductTileProps<T>) => {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const { t } = useTranslation()
  const location = useLocation()
  const { pageType } = usePageType()
  const { isRXEnabled } = useStoreIdentity()
  const { isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440 } = useBreakpoints()

  const badgeSelector = useSelector(plpBadgesSelector)

  const [clusters, setClusters] = useState<ProductBase[]>() // items when products are not clustered
  const [selectedClusterIndex, setSelectedClusterIndex] = useState<number>(0)
  const [currentLensColor, setCurrentLensColor] = useState<string>()
  const [currentFrontColor, setCurrentFrontColor] = useState<string>()
  const [isEyeglass, setIsEyeglass] = useState<boolean>(false)
  const [badges, setBadges] = useState<ICurrentBadges>({
    isNew: false,
    isBadge: false,
    isLimitedEdition: false,
    isOnlineExclusive: false,
    isSustainable: false,
    isCustomizable: false,
    isPolarized: false,
    isRoxable: false,
  })
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [priceBadge, setPriceBadge] = useState<string[]>([])
  const [soldOut, setSoldOut] = useState<ProductSoldOutStatus | null>(null)
  const [productPriceProps, setProductPriceProps] = useState<
    Pick<ProductPriceProps, 'x_prices' | 'showPrefixForCurrent'>
  >({ showPrefixForCurrent: false, x_prices: {} })

  const selectedViewMoco = useMemo<ProductBase>(
    () => (clusters ? clusters[selectedClusterIndex] : product),
    [clusters, selectedClusterIndex, product]
  )

  const isGroupingPLP: boolean = !!product.cluster && !product.items
  const clusterLength = clusters?.length ?? 0
  const clusterViewLength = clusters?.length ?? 1
  const showColorVariantButtons = clusterViewLength > 1 && isGroupingPLP
  const productItems = getItems(product)
  const name = getModelName(product)
  const modelCode = getModelCode(product)
  const { seo } = getItems(selectedViewMoco)?.[0] || (productItems && productItems[0]) || product
  const isSearchDrawerOpen = useSelector(searchDrawerSelector)

  const tileDataElementId = useMemo(() => {
    switch (pageType) {
      case 'search':
        return `X_X_SearchPanel_Content_Tile${tileIndex}`
      case 'pdp':
        return `X_X_AlsoLike_Tile${tileIndex}`
      default:
        return `X_X_Tiles_Tile${tileIndex}_Img`
    }
  }, [pageType])

  const productImageWidth = useMemo<number>(() => {
    const isCMS = variant === 'cms-products-module'
    switch (true) {
      case isCMS:
        return 300
      case isViewportWidthUnder426:
        return 213
      case isMobile:
        return 296
      case isTablet:
        return 300
      case isViewportWidthAbove1440:
        return 300
      case isDesktop:
        return 260
      default:
        return 260
    }
  }, [isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440, variant])

  const priorityBadgeText = getPriorityBadgeText(badges, t)

  const getMocoLinkTo = (moco?: ProductBase): LinkProps['to'] => {
    const items = moco && getItems(moco)
    const pathname = seo?.href || (items && get(items[0], 'seo.href'))

    return { pathname, state: { prevPath: location.pathname + location.search } }
  }

  const getAttachments = (cluster: ProductBase) => {
    let retvalue = cluster.attachments
    return retvalue
  }

  const controlSoldOut = (cluster: ProductBase) => {
    if (!cluster) {
      return null
    }

    const items = getItems(cluster)
    const totalsKUs = items?.length
    let countSoldOut = 0
    let soldOutValue: ProductSoldOutStatus | null = null

    items?.forEach((p) => {
      Log.info('SOLDOUT: ' + getSoldOut(p))
      if (getSoldOut(p) && getSoldOut(p) !== PRODUCT_SOLDOUT_STATUS.NONE) {
        soldOutValue = getSoldOut(p)
        countSoldOut++
      }
    })

    return countSoldOut === totalsKUs ? soldOutValue : null
  }

  const onImageClick = () => {
    dispatch(SET_LAST_SELECTED_PRODUCT_ACTION(product.id))
    onClick?.()
  }

  const commonProductImageProps = {
    alt: t('ProductTile.Labels.AltPrefix', { name }),
    draggable: false,
    sequence: isHovered ? '1.0' : '2.0',
    usage: 'PLP' as 'PLP',
    width: productImageWidth,
    onClick: onImageClick,
  }

  /**
   * Order logic description: https://luxotticaretail.atlassian.net/browse/ARNETTE-3016
   */
  useEffect(() => {
    const newClusters = cloneDeep<ProductBase[]>(
      (product.cluster ?? (isClustered ? product.items : product?.items?.slice(0, 1))) || []
    )
    const newClustersLength = newClusters?.length ?? 0

    setClusters(newClusters)

    const newClustersPrice = newClusters[selectedClusterIndex]

    const discountedPriceBadges = getDiscountedPriceBadges(newClustersPrice)

    setPriceBadge(discountedPriceBadges)

    if (!newClustersLength) {
      setProductPriceProps({
        x_prices: product?.x_prices || {},
        showPrefixForCurrent: false,
      })
    } else {
      setProductPriceProps({
        x_prices: newClustersPrice?.x_prices,
        showPrefixForCurrent: false,
      })
    }
  }, [product.cluster, product.items, isClustered, selectedClusterIndex])

  useEffect(() => {
    let currentSku: ProductBase | undefined

    if (clusterLength) {
      const items = getItems(selectedViewMoco)

      if (items) {
        currentSku = first(items)
        setSoldOut(controlSoldOut(selectedViewMoco))
      } else {
        currentSku = product
      }
    } else {
      currentSku = product
    }

    if (!currentSku) {
      return
    }

    const currentLensColor = getLensesColorFacet(currentSku)
    const currentFrontColor = getFrontColor(currentSku)
    const currentPolarized = getIsPolarized(currentSku)
    const currentRoxable = rxService.isProductRoxable(isRXEnabled, currentSku)
    const currentSustainable = getIsSustainable(currentSku)
    const currentCustomizable = getIsCustomizable(currentSku)
    const currentOnlineExclusive = getIsOnlineExclusive(currentSku)
    const currentLimitedEdition = getIsLimitedEdition(currentSku)
    const currentNew = getNew(currentSku)
    const currentBadge = getBadge(currentSku)
    const currentEyeglass = getProductType(currentSku)

    setCurrentLensColor(currentLensColor)
    setCurrentFrontColor(currentFrontColor)

    setIsEyeglass(currentEyeglass?.toLowerCase() === 'optical')

    const currentBadges = {
      isBadge: badgeSelector?.BADGE === currentBadge,
      isNew: badgeSelector?.IS_NEW === currentNew,
      isLimitedEdition: badgeSelector?.LIMITED_EDITION === currentLimitedEdition,
      isOnlineExclusive: badgeSelector?.SPECIAL_PROJECT_FLAG === currentOnlineExclusive,
      isPolarized: badgeSelector?.POLARIZED === currentPolarized,
      isRoxable: currentRoxable && badgeSelector?.ROXABLE === PRODUCT_ATTRIBUTE_TRUE_VALUE,
      isSustainable: badgeSelector?.SUSTAINABILITY_CLAIM === currentSustainable,
      isCustomizable: badgeSelector?.CUSTOMIZABLE === currentCustomizable,
    }

    setBadges(currentBadges)
  }, [product, selectedViewMoco])

  return (
    product && (
      <StyledProductTileWrapper
        className={`${variant === 'suggested' ? 'arn-product-tile-ymal' : 'arn-product-tile'}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <StyledProductTile
          className="arn-product-tile__inner"
          data-element-id={tileDataElementId}
          data-description={`${siteName}_${name}_${modelCode}`}
        >
          <StyledProductTileHeader className="arn-product-tile__header">
            <StyledProductTileAttributes>
              {!!priorityBadgeText && <span>{priorityBadgeText}</span>}
            </StyledProductTileAttributes>
            <StyledProductTileBadgeIcons>
              {badges.isPolarized && <PolarizedIcon />}
              {badges.isRoxable && <RoxableIcon />}
              {badges.isSustainable && <SustainableIcon />}
            </StyledProductTileBadgeIcons>
          </StyledProductTileHeader>
          <StyledProductTileImageContainerLink
            className={`arn-product-tile__image-container ${clusterViewLength === 1
              ? 'arn-product-tile__unique-image'
              : 'arn-product-tile__multiple-images'
              } ${variant === 'cms-products-module' ? 'arn-product-tile__hp' : ''}`}
            alt= {t('ProductTile.Labels.AltPrefix', { name , modelCode })}
            aria-label={`${name}_${modelCode}`}
            to={getMocoLinkTo(selectedViewMoco)}
          >
            {clusters && clusterViewLength > 1 ? (
              <ColorVariantSlider
                totalSlides={clusterViewLength}
                naturalSlideHeight={isMobile ? 100 : 85}
                naturalSlideWidth={200}
                dragEnabled={false}
                touchEnabled={false}
                currentSlide={selectedClusterIndex}
                className="arn-product-tile__variants"
              >
                {clusters?.map((colorVariant, index) => {
                  return (
                    colorVariant.partNumber && (
                      <StyledColorVariantsSlide
                        index={index}
                        key={colorVariant.partNumber}
                        tag="div"
                      >
                        <ProductImage
                          attachments={getAttachments(colorVariant)}
                          {...commonProductImageProps}
                        />
                      </StyledColorVariantsSlide>
                    )
                  )
                })}
              </ColorVariantSlider>
            ) : (
              ((variant === 'wishlist') || (variant === 'plp' && clusters) ||
                ((!variant || variant !== 'plp') && selectedViewMoco)) &&
              product && (
                <ProductImage
                  attachments={
                    isGroupingPLP && selectedViewMoco
                      ? getAttachments(selectedViewMoco)
                      : !isGroupingPLP && product
                        ? getAttachments(product)
                        : undefined
                  }
                  {...commonProductImageProps}
                />
              )
            )}
          </StyledProductTileImageContainerLink>
        </StyledProductTile>

        <StyledColorVariantsButtons
          className="arn-product-tile__navButtons"
          data-element-id={`X_X_Tiles_Tile${tileIndex}_Variant`}
          data-analytics_available_call="0"
        >
          {!isTablet && showColorVariantButtons && (
            <StyledColorVariantsBtn
              disabled={selectedClusterIndex <= 0}
              onClick={() => setSelectedClusterIndex(selectedClusterIndex - 1)}>
              <ArrowLeftBigIcon fill={theme.palette.background.dark} />
            </StyledColorVariantsBtn>
          )}

          <StyledColorVariantName isSearchDrawerOpen={isSearchDrawerOpen}>
            {currentFrontColor}
            {!isEyeglass ? ' / ' + currentLensColor : ''}
          </StyledColorVariantName>
          {!isTablet && showColorVariantButtons && (
            <StyledColorVariantsBtn
              disabled={selectedClusterIndex === clusterViewLength - 1}
              onClick={() => setSelectedClusterIndex(selectedClusterIndex + 1)}>
              <ArrowRightBigIcon fill={theme.palette.background.dark} />
            </StyledColorVariantsBtn>
          )}
        </StyledColorVariantsButtons>

        <StyledProductTileFooterWrapper
          className={`${variant === 'cms-products-module' ? 'hp-variant' : ''}`}
          themeMode={themeMode}
        >
          <StyledProductTileFooter className="arn-product-tile__bottom">
            <StyledTileFooterCol>
              <StyledProductNameLink className="arn-product-tile__name" data-element-id={`X_X_Tiles_Tile${tileIndex}_link`} title={name} to={getMocoLinkTo(selectedViewMoco)}>
                {name ? name : '\u00A0'} {/* prevents misalignment when name not present */}
              </StyledProductNameLink>

              {footerElementsDisplay.inWishlistAdded && !!onWishlistButtonClick && (
                <StyledIconButton onClick={() => onWishlistButtonClick(product.id)}>
                  <StyledLink to="#" aria-label="Wishlist" data-element-id={'X_X_WishList_Remove'}>
                    <HeartFilledIcon
                      width="19px"
                      height="16px"
                      aria-label="Heart filled icon"
                      htmlColor={
                        themeMode === 'dark'
                          ? theme.palette.primary.light
                          : theme.palette.primary.dark
                      }
                    />
                  </StyledLink>
                </StyledIconButton>
              )}

              {!soldOut && footerElementsDisplay.colors && (
                <StyledProductVariants className="arn-product-tile__colors">
                  <span>{clusterViewLength}</span>
                  <span>
                    {clusterViewLength > 1
                      ? capitalize(t('ProductTile.Labels.colors'))
                      : capitalize(t('ProductTile.Labels.color'))}
                  </span>
                  {badges.isCustomizable && (
                    <span>{/*<CustomizeIcon/> //available on phase 2*/}</span>
                  )}
                </StyledProductVariants>
              )}

              {soldOut && (
                <Box overflow="hidden" width="fit-content" maxWidth="100%">
                  <StyledSoldOut backgroundEnabled={soldOut !== PRODUCT_SOLDOUT_STATUS.SOLDOUT}>
                    {t(
                      `ProductTile.Labels.${soldOut.toLowerCase().split(' ').join('')}`
                    ).toUpperCase()}
                  </StyledSoldOut>
                </Box>
              )}
            </StyledTileFooterCol>

            {productPriceProps && (
              <ProductPrice
                discountedPriceBadges={priceBadge}
                x_prices={productPriceProps.x_prices}
                showPrefixForCurrent={productPriceProps?.showPrefixForCurrent}
                showDiscountAmount={true}
                showVAT={false}
                fromTile={true}
              />
            )}
          </StyledProductTileFooter>
        </StyledProductTileFooterWrapper>
      </StyledProductTileWrapper>
    )
  )
}

ProductTile.defaultProps = {
  isClustered: true,
}

export { ProductTile }
