import React, { CSSProperties, FC, useMemo } from 'react'
import { Link as RouterLink, LinkProps, NavLinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { formatPathWithBase } from '../../../utils/url'

type StyledLinkVariant = (theme, themeMode?: string) => string

const PRIMARY: StyledLinkVariant = ({ palette }, themeMode) => `
  color: ${palette.text[themeMode === 'dark' ? 'contrastText' : 'primary']};
}`

const PRIMARY_UNDERLINED: StyledLinkVariant = (theme, themeMode) =>
  `${PRIMARY(theme, themeMode)} text-decoration: underline;`

const PRIMARY_HOVER_UNDERLINED: StyledLinkVariant = (theme, themeMode) =>
  `${PRIMARY(theme, themeMode)} &:hover { text-decoration: underline };`

const TERTIARY: StyledLinkVariant = ({ palette }) => `
  color: ${palette.primary.main};

  &:hover {
    color: ${palette.primary.dark};
  }

  &.disabled {
    color: ${palette.text.disabled};
  }`

const LINK_VARIANTS = {
  primary: PRIMARY,
  primaryUnderlined: PRIMARY_UNDERLINED,
  primaryHoverUnderlined: PRIMARY_HOVER_UNDERLINED,
  tertiary: TERTIARY,
} as const

export interface StyledLinkProps {
  fontSize?: CSSProperties['fontSize']
  themeMode?: string
  $variant?: keyof typeof LINK_VARIANTS
}

const styles = css<StyledLinkProps>`
  ${({ fontSize, theme, themeMode, $variant = 'primary' }) => `
  ${LINK_VARIANTS[$variant](theme, themeMode)}

  font-size: ${typeof fontSize === 'string' || typeof fontSize === 'number' ? fontSize : 'inherit'};
`}
`

/** Link component for navigating internal react-router routes */
const StyledLink = styled(RouterLink)<StyledLinkProps>`
  ${styles}
`

type CountryBaseLinkProps = NavLinkProps &
  Omit<StyledLinkProps, '$variant'> & {
    variant?: StyledLinkProps['$variant']
  }

export const CountryBaseLink: FC<CountryBaseLinkProps> = (props) => {
  const { to, variant, ...restProps } = props
  const { basePath } = useStoreIdentity()

  const formattedTo = useMemo<LinkProps['to']>(() => {
    if (typeof to === 'string') {
      return formatPathWithBase(to, basePath)
    }

    if (typeof to === 'object' && to.pathname) {
      return {
        ...to,
        pathname: formatPathWithBase(to.pathname, basePath),
      }
    }

    return to
  }, [basePath, to])

  return <StyledLink {...restProps} to={formattedTo} $variant={variant} />
}

/** Link component for going to external URLs */
export const StyledA = styled.a<StyledLinkProps>`
  ${styles}
`

export default StyledLink
