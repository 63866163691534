import { useCallback } from 'react'
import { ExtractRouteParams, generatePath, matchPath, RouteProps, useLocation } from 'react-router'
import { useStoreIdentity } from './useStoreIdentity'

export const useRouteMatcher = () => {
  const location = useLocation()
  const { langCode } = useStoreIdentity()

  return useCallback(
    (pathname: string, pathProps?: ExtractRouteParams<string>, routeProps?: RouteProps) =>
      matchPath(location.pathname, {
        path: generatePath(pathname, { country: langCode, ...pathProps }),
        ...routeProps,
      }),
    [langCode, location.pathname, matchPath]
  )
}
