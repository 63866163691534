import { ProductBase } from './product'

export const DYNAMIC_CONTENT_KEYS = {
  ANTI_REFLECTIVE: 'ANTI_REFLECTIVE',
  ANTI_SCRATCH: 'ANTI_SCRATCH',
  FACE_SHAPE: 'FACE_SHAPE',
  FRAME_SHAPE: 'FRAME_SHAPE',
  GENDER: 'GENDER',
  GEOFIT: 'GEOFIT',
  IS_NEW: 'IS_NEW',
  LIMITED_EDITION: 'LIMITED_EDITION',
  PHOTOCHROMIC: 'PHOTOCHROMIC',
  POLARIZED: 'POLARIZED',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  ROXABLE: 'ROXABLE',
  SPECIAL_PROJECT_FLAG: 'SPECIAL_PROJECT_FLAG',
  SPECIAL_PROJECT_DESCRIPTION: 'SPECIAL_PROJECT_DESCRIPTION',
  SPECIAL_PROJECT_SPONSOR: 'SPECIAL_PROJECT_SPONSOR',
  SPECIAL_PROJECT_TYPE: 'SPECIAL_PROJECT_TYPE',
  SUSTAINABILITY_CLAIM: 'SUSTAINABILITY_CLAIM',
  UV_PROTECTION: 'UV_PROTECTION',
  FRAME_SHAPE_FACET: 'FRAME_SHAPE_FACET',
  FRONT_COLOR_FACET: 'FRONT_COLOR_FACET',
  LENS_COLOR_FACET: 'LENS_COLOR_FACET',
  LENS_TREATMENT_FACET: 'LENS_TREATMENT_FACET',
  EARLY_ACCESS_CONTENT_ENABLED: 'earlyAccessContentEnabled'
} as const

export const {
  ANTI_REFLECTIVE,
  ANTI_SCRATCH,
  FACE_SHAPE,
  FRAME_SHAPE,
  GENDER,
  GEOFIT,
  IS_NEW,
  LIMITED_EDITION,
  PHOTOCHROMIC,
  POLARIZED,
  PRODUCT_TYPE,
  ROXABLE,
  SPECIAL_PROJECT_FLAG,
  SPECIAL_PROJECT_DESCRIPTION,
  SPECIAL_PROJECT_SPONSOR,
  SPECIAL_PROJECT_TYPE,
  SUSTAINABILITY_CLAIM,
  UV_PROTECTION,
  FRAME_SHAPE_FACET,
  FRONT_COLOR_FACET,
  LENS_COLOR_FACET,
  LENS_TREATMENT_FACET,
  EARLY_ACCESS_CONTENT_ENABLED,
} = DYNAMIC_CONTENT_KEYS

export type DynamicContentKey = typeof DYNAMIC_CONTENT_KEYS[keyof typeof DYNAMIC_CONTENT_KEYS]

export const dynamicContentKeys: DynamicContentKey[] = [
  ANTI_REFLECTIVE,
  ANTI_SCRATCH,
  FACE_SHAPE,
  FRAME_SHAPE,
  GENDER,
  GEOFIT,
  IS_NEW,
  LIMITED_EDITION,
  PHOTOCHROMIC,
  POLARIZED,
  PRODUCT_TYPE,
  ROXABLE,
  SPECIAL_PROJECT_FLAG,
  SPECIAL_PROJECT_DESCRIPTION,
  SPECIAL_PROJECT_SPONSOR,
  SPECIAL_PROJECT_TYPE,
  SUSTAINABILITY_CLAIM,
  UV_PROTECTION,
  FRAME_SHAPE_FACET,
  FRONT_COLOR_FACET,
  LENS_COLOR_FACET,
  LENS_TREATMENT_FACET,
  EARLY_ACCESS_CONTENT_ENABLED,
]

export type DynamicContentRule = {
  key: DynamicContentKey
  value: string
}

export type DynamicRule = {
  rules: DynamicContentRule[]
  target: ILXTeaser | ICMCollection
}

export type IViewType =
  | 'anchor-buttons'
  | 'full-width-banner'
  | 'plp-box'
  | 'banner-with-side-fields-plus-related'
  | 'default'
  | 'text-module'
  | 'banner-with-side-fields'
  | 'blurred-slider'
  | 'landscape-banner'
  | 'narrow-banner-w-all-fields-below'
  | 'box-plus-one'
  | 'boards-with-fields-on-below-mobile-no-carousel'
  | 'boards-with-fields-on-below-mobile-carousel'
  | 'combo-mini-slider-plus-box-all-fields'
  | 'products'
  | 'mini-slider'
  | 'open-article_without_title'
  | 'product-carousel'
  | 'landscape-banner-new'
  | 'top-page-banner-arn'
  | 'squat-banner-arn'

export type IPLPBoxOrientation = 'left' | 'right'
export type IGridPositioning = 1 | 2 | 3 | 4

export interface IPictureMedia {
  type: 'CMPicture'
  uriTemplate: string
}

export interface IVideoMedia {
  type: 'CMVideo'
  autoplay: boolean
  data: {
    uri: string
  }
  dataUrl: string
  hideControl: boolean
  loop: boolean
  mute: boolean
  picture: {
    uriTemplate: string
  }
  playOnHover: boolean
  width: number
}

export type TMedia = IPictureMedia | IVideoMedia

export interface ICallToAction {
  callToActionEnabled?: boolean
  callToActionHash: string
  callToActionText?: string
  style: 'arn-cta--primary' | 'arn-cta--secondary' | 'arn-cta--tertiary'
  target:
  | ICMArticle
  | ICMChannel
  | ICMExternalLink
  | ICMExternalPage
  | ICMProductTeaser
  | ICMExternalChannel
  | ICMExternalProduct
  | ICMDownload
}

type TTeaserLabelPositions =
  | 'left-top'
  | 'center-top'
  | 'right-top'
  | 'left-center'
  | 'center-center'
  | 'right-center'
  | 'left-bottom'
  | 'center-bottom'
  | 'right-bottom'
  | 'top-left'
  | ''

export type TOverlayTextAlign = 'left' | 'right' | 'center' | 'justified'

export interface ICMNavigationPath {
  segment: string
}

// Base Placement Item
// This must contain shared memebers
interface IBasePlacementItem {
  id: string
  name: string
  viewtype: string
}

export interface ICMProductTeaser extends IBasePlacementItem {
  type: 'CMProductTeaser'
  externalId: string
  formattedUrl: string
  media: TMedia[]
  teaserTitle: string
  teaserText: string | null
  productData: ProductBase
}

export interface ICMExternalProduct extends IBasePlacementItem {
  externalId: string
  formattedUrl: string
  media: TMedia[]
  productData: ProductBase
  teaserTitle: string
  teaserText: string | null
  type: 'CMExternalProduct'
}

export interface ICMExternalLink extends IBasePlacementItem {
  formattedUrl: string
  title: string
  type: 'CMExternalLink'
  url: string
}

export interface ICMExternalChannel extends IBasePlacementItem {
  externalId: string
  formattedUrl: string
  title: string
  type: 'CMExternalChannel'
}

export interface ICMChild {
  children: ICMChild[]
  formattedUrl: string
  hidden: boolean
  hiddenInDesktopNavigation: boolean
  hiddenInFooterNavigation: boolean
  hiddenInMobileNavigation: boolean
  hiddenInSearchAndSitemap: boolean
  hiddenInSitemap: boolean
  name: string
  navigationPath: ICMNavigationPath[]
  showChildrenInMenu: boolean
  title: string
}

export interface ICMHtml extends IBasePlacementItem {
  children: ICMChild[]
  formattedUrl: string
  resourceLink: string
  html: string
  title: string
  type: 'CMHTML'
}

export interface ICMExternalPage extends IBasePlacementItem {
  children: ICMChild[]
  externalId: string
  formattedUrl: string
  title: string
  type: 'CMExternalPage'
}

type TOverlaySettings =
  | 'block-left-top'
  | 'block-left-middle'
  | 'block-left-bottom'
  | 'block-center-top'
  | 'block-center-middle'
  | 'block-center-bottom'
  | 'block-right-top'
  | 'block-right-middle'
  | 'block-right-bottom'

export type TeaserOverlayStyle =
  | 'text-dark' // for text on (over) images
  | 'text-light' // for text on (over) images

export interface ILXTeaser extends IBasePlacementItem {
  type: 'LXTeaser'
  promoteToH1: boolean
  gridPositioning: IGridPositioning
  media: TMedia[]
  teaserIcon: string
  teaserPreTitle: string
  teaserTitle1: string
  teaserTitle2: string
  teaserTitle3: string
  teaserTitle4: string
  teaserText1: string
  teaserText2: string
  teaserOverlay1TextAlign: TOverlayTextAlign // Text alignment
  teaserOverlay1Settings: TOverlaySettings // Content positioning inside a block
  teaserOverlay1Style: TeaserOverlayStyle // Text Color
  teaserOverlay2TextAlign: TOverlayTextAlign // Text alignment
  teaserOverlay2Settings: TOverlaySettings // Content positioning inside a block
  teaserOverlay2Style: TeaserOverlayStyle // Text Color
  teaserLabelVisible: boolean
  teaserLabelText: string
  teaserLabelPosition: TTeaserLabelPositions
  teaserLabelStyle: 'col-primary' | 'col-secondary' | 'col-tertiary' | 'col-white' // label color
  teaserBackground: 'bg-primary' | 'bg-secondary' | 'bg-tertiary' | 'bg-white'
  teaserCountdownStart: string
  teaserCountdownUntil: string
  teaserHideExpiredCountdown: boolean
  teaserLXCallToActionSettings: ICallToAction[]
  teaserOverlayVideo: []
  formattedUrl?: string
}

export interface ICMCollection<T = IPlacementItem> extends IBasePlacementItem {
  type: 'CMCollection'
  viewtype: IViewType
  teasableItems: T[]
  gridPositioning: IGridPositioning
  collectionTitle: string
  collectionSubTitle: string
  collectionMaxElementNumber: number
  teaserLXCallToActionSettings?: ICallToAction[]
  placementReflect?: boolean
  collectionText?: string
}

export interface ICMProductList<T = IPlacementItem> extends IBasePlacementItem {
  type: 'CMProductList'
  viewtype: IViewType
  teasableItems: T[]
  gridPositioning: IGridPositioning
  collectionTitle: string
  collectionSubTitle: string
  collectionMaxElementNumber: number
  teaserLXCallToActionSettings?: ICallToAction[]
  placementReflect?: boolean
  collectionText?: string
  productData: ProductBase[]
}

export interface ICMArticle extends IBasePlacementItem {
  type: 'CMArticle'
  title: string
  detailText: string
  formattedUrl?: string
}

export interface ICMChannel extends IBasePlacementItem {
  children: ICMChild[]
  formattedUrl: string
  navigationPath: ICMNavigationPath[]
  title: string
  type: 'CMChannel'
}

export interface ICMTeaser extends IBasePlacementItem {
  teaserTargets: ITeaserTargets[]
  type: 'CMTeaser'
  teaserTitle?: string
}

export interface ICMDownload extends IBasePlacementItem {
  formattedUrl: string
  fullyQualifiedUrl: string
  title: string
  type: 'CMDownload'
}

export interface LXDynamicContent extends IBasePlacementItem {
  dynamicRules: DynamicRule[]
  dynamicDefault: ILXTeaser | ICMCollection
  type: 'LXDynamicContent'
}

export interface ITeaserTargets {
  callToActionHash?: string
  target: ICMChannel | ICMExternalPage | ICMProductTeaser | ICMExternalChannel | ICMExternalProduct
}

export interface IPlacement<ItemType = IPlacementItem> {
  viewtype: IViewType
  name: string
  marginLateral: boolean
  placementReflect: boolean
  placementCenter?: boolean
  marginVertical: 'X' | 'S' | 'M' | 'L'
  backgroundColor: React.CSSProperties['backgroundColor']
  clusterTile: boolean
  placementAnimation: string
  cta: string
  items: ItemType[]
  teaserCountdownStart: string
  teaserCountdownUntil: string
  teaserHideExpiredCountdown: boolean
  type: string
}

export interface IPlacementPriority {
  fetchpriority?: 'high'
}

export interface DashButtonProps {
  formattedUrl: string
  name: string
  teaserIcon: string
}

// static or lading page
export interface ICmsPage {
  alternateLinks: string[]
  analytics: { pageSection1: string; pageSection2: string }
  anchorData: AnchorData[]
  contentChildren: ICMChild[]
  contentPlacements: IPlacement[]
  contentParent: {
    title: string
    children: ICMChild[]
  }
  cssClassName: 'static-page-grid--default' | 'cm-grid--default' | 'page-not-found'
  extraCSS: string[]
  extraJS: string[]
  name: string
  showAnchorMenu: boolean
  teaserText: string
  title: string
}

export interface AnchorData {
  title: string
  placement: string
}

export type IPlacementItem =
  | ICMArticle
  | ICMChannel
  | ICMCollection
  | ICMExternalLink
  | ICMExternalPage
  | ICMExternalProduct
  | ICMProductTeaser
  | ILXTeaser
  | LXDynamicContent
  | ICMHtml

export interface INavItem {
  title: string
  menuItems: { title: string; url: string }[]
}

export const isLXTeaser = (item: IPlacementItem): item is ILXTeaser =>
  (item as ILXTeaser)?.type === 'LXTeaser'

export const isCMCollection = (item: any): item is ICMCollection => item?.type === 'CMCollection'

export const isCMProductList = (item: any): item is ICMProductList => item?.type === 'CMProductList'

export const isCMExternalProduct = (item: IPlacementItem): item is ICMExternalProduct =>
  item?.type === 'CMExternalProduct'

export const isCMProductTeaser = (item: IPlacementItem): item is ICMProductTeaser =>
  item?.type === 'CMProductTeaser'

export const isICMHtml = (item: IPlacementItem): item is ICMHtml =>
  item?.type === 'CMHTML'

export const isCMArticle = (item: IPlacementItem): item is ICMArticle => item?.type === 'CMArticle'

export const isCMChannel = (item: IPlacementItem): item is ICMChannel => item?.type === 'CMChannel'

export const isPictureMedia = (item: TMedia | undefined): item is IPictureMedia =>
  item?.type === 'CMPicture' ?? false

export const isVideoMedia = (item: TMedia): item is IVideoMedia => item?.type === 'CMVideo' ?? false

export const isDynamicContent = (item: IPlacementItem): item is LXDynamicContent =>
  (item as LXDynamicContent)?.type === 'LXDynamicContent'
