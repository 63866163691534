//Standard libraries
import { call, put } from 'redux-saga/effects'
import chunk from 'lodash/chunk'
//Foundation libraries
import orderService from '../../../foundation/apis/transaction/order.service'
import ProductService from '../../../services/ProductService'
//Redux
import {
  FETCH_ORDER_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION,
  FETCH_ORDER_DETAILS_FAIL_ACTION,
  SET_ORDER_DETAILS_LOADINGS_ACTION,
} from '../../actions/orderDetails'
import { IOrderDetails, OrderItem } from '../../../types/order'
import { ProductBase } from '../../../types/product'
import { ITEM_DETAILS_PROFILE_NAME } from '../../../constants/common'

export function* getOrderDetails(action) {
  const { orderId, currency, contractId, skipErrorSnackbar, widget } = action.payload
  try {
    yield put(SET_ORDER_DETAILS_LOADINGS_ACTION(orderId))

    const orderDetails: IOrderDetails = yield call(orderService.findByOrderId, {
      orderId,
      queryParams: { currency, contractId, skipErrorSnackbar, widget },
    })
    yield put(FETCH_ORDER_DETAILS_SUCCESS_ACTION(orderDetails))
    try {
      // check if order already has product details
      let orderItemDetails: ProductBase[] = []

      if (orderDetails.x_data?.productDetails?.length === +orderDetails.recordSetCount) {
        orderItemDetails = orderDetails.x_data.productDetails
      } else {
        const orderItems: OrderItem[] = orderDetails.orderItem
        const id = [...new Set(orderItems.map((i) => i.productId))]
        const itemDetailsParams = {
          id,
          currency,
          contractId,
        }

        orderItemDetails = yield call(fetchOrderItemDetailsByIds, itemDetailsParams)
      }

      yield put(FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION({ orderId, items: orderItemDetails }))
    } catch (error) {
      yield put(FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION({ orderId, error }))
    }
  } catch (error) {
    yield put(FETCH_ORDER_DETAILS_FAIL_ACTION({ orderId, error }))
  }
}

export const fetchOrderItemDetailsByIds = (param: {
  currency: string
  id: string[]
  widget?: string
}) => {
  const promiseArray: ReturnType<typeof ProductService.get>[] = []
  const { currency, id, widget } = param
  const paramBase = { currency, widget }
  const ids = chunk(id, 50)

  ids.forEach((id) => {
    const param = Object.assign({}, paramBase, { id })
    promiseArray.push(ProductService.get({ ...param, profileName: ITEM_DETAILS_PROFILE_NAME }))
  })

  return Promise.all(promiseArray).then((rs) => {
    let contents = []

    rs.forEach((r) => {
      if (r?.contents) {
        contents = contents.concat(r.contents)
      }
    })

    return contents
  })
}
