import { ILXTeaser } from '../types/teaser'

type IPosition =
  | 'left-top'
  | 'left-middle'
  | 'left-bottom'
  | 'right-top'
  | 'right-middle'
  | 'right-bottom'
  | 'center-top'
  | 'center-middle'
  | 'center-bottom'

const normalizeOverlaySettings = (value: IPosition) => {
  switch (value) {
    case 'left-top':
      return 'left: 16px; top: 16px;'
    case 'left-middle':
      return 'left: 16px; top: 50%; transform: translateY(-50%);'
    case 'left-bottom':
      return 'left: 16px; bottom: 16px;'
    case 'center-top':
      return 'left: 50%; transform: translateX(-50%); top: 16px'
    case 'center-middle':
      return 'left: 50%; transform: translateX(-50%) translateY(-50%); top: 50%;'
    case 'center-bottom':
      return 'left: 50%; transform: translateX(-50%); bottom: 16px;'
    case 'right-top':
      return 'right: 16px; top: 16px;'
    case 'right-middle':
      return 'right: 16px; top: 50%; transform: translateY(-50%);'
    case 'right-bottom':
      return 'right: 16px; bottom: 16px;'
  }
}

export const teaserOverlaySettingsToCSS = (value: ILXTeaser['teaserOverlay1Settings'] | '') => {
  if (value.length === 0 || value === '') {
    return 'left: 50%; transform: translateX(-50%) translateY(-50%); top: 50%;'
  }
  const split = value.replace('block-', '') as IPosition

  return `${normalizeOverlaySettings(split)};`
}

export const teaserOverlaySettingsToFlexCSS = (value: ILXTeaser['teaserOverlay1Settings'] | '') => {
  if (value?.length === 0 || value === '') {
    return 'center'
  }
  const split = value.replace('block-', '') as IPosition

  return `${teaserAlignToFlexContent(split)};`
}

export const textAlignToJustifyContentFlex = (value: ILXTeaser['teaserOverlay1TextAlign'] | '') => {
  switch (value) {
    case 'left':
      return 'justify-content:flex-start'
    case 'right':
    return 'justify-content:flex-end'
    case 'center':
      return 'justify-content:center'
        default:
      return 'justify-content:center'
  }
}

export const teaserAlignToFlex = (value: string) => {
  switch (value) {
    case 'left':
      return 'flex-start'
    case 'right':
    return 'flex-end'
    case 'center':
      return 'center'
        default:
      return 'justify-content:center'
  }
}

export const teaserAlignToFlexContent = (value: string) => {
  switch (value) {
    case 'left-top':
    case 'left-middle':
    case 'left-bottom':
      return 'justify-content:flex-start'
    case 'center-top':
    case 'center-middle':
    case 'center-bottom':
      return 'justify-content:center'
    case 'right-top':
    case 'right-middle':
    case 'right-bottom':
      return 'justify-content:flex-end'
    default:
      return 'justify-content:center'
  }
}