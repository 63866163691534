/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//used by CSR
export const SESSION_ERROR_ACTION = 'SESSION_ERROR_ACTION'
export const LOCK_ORDER_ACTION = 'LOCK_ORDER_ACTION'
export const LOCK_ORDRE_ERROR = ['_ERR_ORDER_IS_NOT_LOCKED', '_ERR_ORDER_IS_LOCKED']
export const FOR_USER_SESSION = 'FOR_USER_SESSION'
export const PARENT_IFRAME = 'parentIFrame'
export const IFRAME_RESIZER = 'iFrameResizer'
export const AUTHENTICATION_ERROR = ['_ERR_AUTHENTICATION_ERROR']
