import head from 'lodash/head'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import { isRxCart, parseOrderItems } from '../../utils/isRxOrder'

//Redux
import { RootReducerState } from '../reducers'

export const cartSelector = (state: RootReducerState) => state.order.cart!
export const numItemsSelector = (state: RootReducerState) => state.order.numItems
export const orderItemsSelector = (state: RootReducerState) => state.order.orderItems
export const catentriesSelector = (state: RootReducerState) => state.order.catentries
export const isCheckoutDisabledSelector = (state: RootReducerState) =>
  state.order.isCheckoutDisabled
export const isRXOrderSelector = ({ order }: RootReducerState) => order.cart?.IS_RX === 'true'
export const shipInfosSelector = (state: RootReducerState) => state.order.shipInfos
export const shipModesSelector = (state: RootReducerState) => state.order.shipModes
export const payMethodsSelector = (state: RootReducerState) => state.order.payMethods
export const isRecurringOrderDisabledSelector = (state: RootReducerState) =>
  state.order.isRecurringOrderDisabled
export const isFetchingSelector = (state: RootReducerState) => state.order.isFetching
export const isAddingItemSelector = (state: RootReducerState) => state.order.isAddingItem
export const addedToCartProductSelector = (state: RootReducerState) => state.order.orderItems || []

export const lastProductAddedToCartSelector = (state: RootReducerState) => {
  const orderItems = isRxCart(state.order.cart?.orderExtendAttribute || [])
    ? parseOrderItems(state.order.orderItems)
    : state.order.orderItems
  const ordered = orderBy(orderItems, ['lastUpdateDate'], ['asc'])
  return last(ordered)
}

export const paypalExpressSelector = (state: RootReducerState) => state.order.paypalExpress
export const isFetchingShippingInfoSelector = (state: RootReducerState) =>
  state.order.isFetchingShippingInfo
export const isShippingAsBillingSelector = (state: RootReducerState) =>
  state.order.isShippingUsedAsBilling
export const orderCompleteSelector = (state: RootReducerState) => state.order.orderComplete
export const orderFinalizingSelector = (state: RootReducerState) => state.order.isFinalizing
export const orderFinalizingFailSelector = (state: RootReducerState) =>
  state.order.isFinalizationFailed
export const selectedPayMethodsSelector = (state: RootReducerState) =>
  state.order.selectedPayMethodInfoList
export const shippingFormStatusSelector = (state: RootReducerState) =>
  state.order.shippingFormStatus
export const billingFormStatusSelector = (state: RootReducerState) => state.order.billingFormStatus
export const totalOrderDiscountSelector = (state: RootReducerState) => {
  const total = state.order.cart?.adjustment?.reduce((total, adj) => {
    total += Number(adj.amount)
    return total
  }, 0)
  return total ? String(total) : '0'
}
export const shippingModeSelector = (state: RootReducerState) => state.order.orderItems[0]?.shipModeCode

export const orderDiscountNamesSelector = (state: RootReducerState) => {
  return (
    state.order.cart?.adjustment?.reduce((discounts, adj, i) => {
      const code = adj.code?.replace(' ', '')
      if (i !== 0) {
        discounts += `,${code}`
      } else {
        discounts += code
      }
      return discounts
    }, '') || ''
  )
}

export const appliedPromotionCodesSelector = (state: RootReducerState) => {
  return state.order.cart?.promotionCode?.reduce((codes, promotionCode, i) => {
    if (i !== 0) {
      codes += `,${promotionCode.code}`
    } else {
      codes += promotionCode.code
    }
    return codes
  }, '')
}

export const promoHasAdjustmentSelector = (state: RootReducerState) =>
  state.order.cart?.x_promoHasAdjustment

export const orderDetailsSelector = (state: RootReducerState) => {
  const orderData = state.orderDetails?.data || {}
  const firstKey = Object.keys(orderData).length > 0 ? head(Object.keys(orderData)) : undefined
  if (firstKey) {
    // retrieving value of the first key
    return orderData[firstKey]
  }
}

export const shippingAddressIdSelector = (state: RootReducerState) => {
  return state.order.orderItems.find((order) => !!order)?.addressId
}

export const paymentInstructionSelector = (state: RootReducerState) => {
  return state.order.cart?.paymentInstruction
}

export const isRXProductsLimitExceededSelector = (state: RootReducerState) => {
  return state.order.isRXProductsLimitExceeded
}

export const paymentInstructionIdSelector = (state: RootReducerState) => {
  return state.order.cart?.paymentInstruction?.find(
    (instruction) => !!instruction
  )?.piId
}

export const isNewsletterSubscribeCheckedSelector = (state: RootReducerState) =>
  state.order.isNewsletterSubscribeChecked

export const isEditLensesSelector = (state: RootReducerState) =>
  state.order.isEditLenses

export const urlImageSelector = (state: RootReducerState) =>
  state.order.urlImageRx


export const estimatingTaxesSelector = (state: RootReducerState) =>
  state.order.estimatingTaxes


export const estimatingTaxesValueSelector = (state: RootReducerState) =>
  state.order.estimatedTaxValue