import { PolarizedIcon, RoxableIcon, SustainableIcon } from '../../StyledUI/Icons'
import React, { FC, useEffect, useRef, useState } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import {
  StyledPLPFiltersBar,
  StyledPLPFiltersBarButtonsWrapper,
  StyledPLPFiltersBarTitleWrapper,
  StyledPLPHeaderWrapper,
  ToggleFilterButton,
} from './styles/PLPHeader.style'
import { currentPLPDashFacetsSelector } from '../../../redux/selectors/catalog'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
//Redux
import { FILTERS_DRAWER_TOGGLE_ACTION } from '../../../redux/actions/ui'
import CheckboxAsButton from '../../StyledUI/CheckboxAsButton/CheckboxAsButton'
//Custom libraries
import { Typography } from '@material-ui/core'
import { plpDashfacetSelector } from '../../../redux/selectors/site'
import ProductService from '../../../services/ProductService'
import { themeModeSelector } from '../../../redux/selectors/theme'
//Standard libraries
import { useTranslation } from 'react-i18next'
import { UseProductsReturn } from '../../../hooks/useProducts'
import { PromoBannerWithLink } from '../promo-banner'
import { PlpPromoBanner } from './ProductGridLayout'
import Flexbox from '../../StyledUI/Flexbox/Flexbox'
import { useCms } from '../../providers/CmsProvider'

type PLPHeaderProps = Pick<
  UseProductsReturn,
  | 'areFacetsLoading'
  | 'areProductsLoading'
  | 'selectedFacets'
  | 'selectedSortOption'
  | 'getUpdatedSelectedFacets'
  | 'fetchProducts'
> & {
  title?: string
  description?: string
  plpPromoBanner: PlpPromoBanner | null
}

const PLPHeaderTitle: FC<{ text?: string }> = ({ text = '' }) => (
  <Typography variant="h1" component="h1">
    {text || (
      <SkeletonTheme color="transparent" highlightColor="rgba(0, 0, 0, 0.05)">
        <Skeleton />
      </SkeletonTheme>
    )}
  </Typography>
)

const PLPHeader: FC<PLPHeaderProps> = ({
  title,
  areFacetsLoading,
  areProductsLoading,
  plpPromoBanner,
  selectedFacets,
  getUpdatedSelectedFacets,
  fetchProducts,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { headerPromoBars } = useCms()
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const dashFacets = useSelector(plpDashfacetSelector) || {}
  const currentPLPDashFacets = useSelector(currentPLPDashFacetsSelector('product'))

  const filtersBarRef = useRef<HTMLDivElement>(null)

  const [isFiltersBarStuck, setFiltersBarStuck] = useState<boolean>(false)

  const dasButtonsClassesIconsMap: Record<string, React.ReactNode> = {
    POLARIZED: <PolarizedIcon />,
    SUSTAINABILITY_CLAIM: <SustainableIcon />,
    ROXABLE: <RoxableIcon />,
  }
  const isPromoBar = !!headerPromoBars.length

  useEffect(() => {
    if (!filtersBarRef?.current) {
      return
    }

    const observer = new IntersectionObserver(
      ([e]) => setFiltersBarStuck(e.intersectionRatio < 1),
      { threshold: 1 }
    )

    observer.observe(filtersBarRef.current)

    return () => {
      if (filtersBarRef?.current) {
        observer.unobserve(filtersBarRef.current)
      }
    }
  }, [filtersBarRef])

  const titleRef = useRef<HTMLDivElement | null>(null)
  const [isTitleVisible, setIsTitleVisible] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      const isAtTopOfPage = window.scrollY < 200
      setIsTitleVisible(isAtTopOfPage)
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting && window.scrollY >= 100) {
          setIsTitleVisible(false)
        }
      });
    }, { threshold: 0.1 })

    if (titleRef.current) {
      observer.observe(titleRef.current)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <StyledPLPHeaderWrapper>
        <Flexbox flex="1" flex-direction="column">
          {isTitleVisible ?
            <div ref={titleRef}>
              <PLPHeaderTitle text={title} />
            </div>
            : ''
          }
        </Flexbox>

        {plpPromoBanner && (
          <PromoBannerWithLink
            title={plpPromoBanner.title}
            icon={plpPromoBanner.icon}
            items={[{ title: plpPromoBanner.ctaLabel, path: plpPromoBanner.ctaPath }]}
          />
        )}
      </StyledPLPHeaderWrapper>
      {isTitleVisible ?
      <StyledPLPFiltersBar
          isStuck={isFiltersBarStuck}
          ref={filtersBarRef}
          themeMode={themeMode}
          hasPromoBar={!!headerPromoBars.length}
        >
          <StyledPLPFiltersBarTitleWrapper>
            <PLPHeaderTitle text={title} />
          </StyledPLPFiltersBarTitleWrapper>

          <StyledPLPFiltersBarButtonsWrapper>
            <ToggleFilterButton
              role="button"
              data-element-id="X_X_MainNav_Filter"
              disabled={areProductsLoading}
              onClick={() => dispatch(FILTERS_DRAWER_TOGGLE_ACTION(true))}>
              {t('ProductGrid.Actions.toggleFilter')}
              {Object.keys(selectedFacets).length > 0 && (
                <div className="arn-plp-header__filters-number">
                  {Object.keys(selectedFacets).length}
                </div>
              )}
            </ToggleFilterButton>
            {!areProductsLoading || !areFacetsLoading ? (
              currentPLPDashFacets?.map((facet) => {
                if (facet.entry.length < 2) {
                  return null
                }

                const dasButtonIcon: React.ReactNode = facet.name
                  ? dasButtonsClassesIconsMap[facet.name]
                  : null

                return facet.entry.map(
                  (entry) =>
                    entry.label === dashFacets[facet.name] && (
                      <CheckboxAsButton
                        data-element-id={facet.name}
                        renderLabelIcon={() => dasButtonIcon}
                        key={facet.value}
                        labelText={t(`ProductFilter.Labels.${facet.name}`)}
                        checked={ProductService.isFacetSelected(facet, entry, selectedFacets)}
                        id={facet.value}
                        onChange={() => fetchProducts(getUpdatedSelectedFacets(facet, entry))}
                        disabled={areFacetsLoading || areProductsLoading || entry.count === '0'}
                      />
                    )
                )
              })
            ) : (
              <SkeletonTheme color="transparent" highlightColor={'rgba(0, 0, 0, 0.05)'}>
                <Skeleton
                  className="first-btn-skeleton"
                  style={{ borderRadius: 100 }}
                  width="140px"
                  height="40px"
                />
                <Skeleton style={{ borderRadius: 100 }} width="140px" height="40px" />
              </SkeletonTheme>
            )}
          </StyledPLPFiltersBarButtonsWrapper>
      </StyledPLPFiltersBar>
      :
      !isTitleVisible  && isPromoBar ?
      <StyledPLPFiltersBar
        isStuck={!isFiltersBarStuck}
        ref={filtersBarRef}
        themeMode={themeMode}
        hasPromoBar={!!headerPromoBars.length}
      >
        <StyledPLPFiltersBarTitleWrapper>
          <PLPHeaderTitle text={title} />
        </StyledPLPFiltersBarTitleWrapper>

        <StyledPLPFiltersBarButtonsWrapper>
          <ToggleFilterButton
            role="button"
            data-element-id="X_X_MainNav_Filter"
            disabled={areProductsLoading}
            onClick={() => dispatch(FILTERS_DRAWER_TOGGLE_ACTION(true))}>
            {t('ProductGrid.Actions.toggleFilter')}
            {Object.keys(selectedFacets).length > 0 && (
              <div className="arn-plp-header__filters-number">
                {Object.keys(selectedFacets).length}
              </div>
            )}
          </ToggleFilterButton>
          {!areProductsLoading || !areFacetsLoading ? (
            currentPLPDashFacets?.map((facet) => {
              // this prevents that the filter with 0 items get shown
              // ex presription filter on prescription plp
              if (facet.entry.length < 2) {
                return null
              }

              const dasButtonIcon: React.ReactNode = facet.name
                ? dasButtonsClassesIconsMap[facet.name]
                : null

              return facet.entry.map(
                (entry) =>
                  entry.label === dashFacets[facet.name] && (
                    <CheckboxAsButton
                      data-element-id={facet.name}
                      renderLabelIcon={() => dasButtonIcon}
                      key={facet.value}
                      labelText={t(`ProductFilter.Labels.${facet.name}`)}
                      checked={ProductService.isFacetSelected(facet, entry, selectedFacets)}
                      id={facet.value}
                      onChange={() => fetchProducts(getUpdatedSelectedFacets(facet, entry))}
                      disabled={areFacetsLoading || areProductsLoading || entry.count === '0'}
                    />
                  )
              )
            })
          ) : (
            <SkeletonTheme color="transparent" highlightColor={'rgba(0, 0, 0, 0.05)'}>
              <Skeleton
                className="first-btn-skeleton"
                style={{ borderRadius: 100 }}
                width="140px"
                height="40px"
              />
              <Skeleton style={{ borderRadius: 100 }} width="140px" height="40px" />
            </SkeletonTheme>
          )}
        </StyledPLPFiltersBarButtonsWrapper>
      </StyledPLPFiltersBar>
      :
      <StyledPLPFiltersBar
        isStuck={isFiltersBarStuck}
        ref={filtersBarRef}
        themeMode={themeMode}
        hasPromoBar={!!headerPromoBars.length}
      >
        <StyledPLPFiltersBarTitleWrapper>
          <PLPHeaderTitle text={title} />
        </StyledPLPFiltersBarTitleWrapper>

        <StyledPLPFiltersBarButtonsWrapper>
          <ToggleFilterButton
            role="button"
            data-element-id="X_X_MainNav_Filter"
            disabled={areProductsLoading}
            onClick={() => dispatch(FILTERS_DRAWER_TOGGLE_ACTION(true))}>
            {t('ProductGrid.Actions.toggleFilter')}
            {Object.keys(selectedFacets).length > 0 && (
              <div className="arn-plp-header__filters-number">
                {Object.keys(selectedFacets).length}
              </div>
            )}
          </ToggleFilterButton>
          {!areProductsLoading || !areFacetsLoading ? (
            currentPLPDashFacets?.map((facet) => {
              if (facet.entry.length < 2) {
                return null
              }

              const dasButtonIcon: React.ReactNode = facet.name
                ? dasButtonsClassesIconsMap[facet.name]
                : null

              return facet.entry.map(
                (entry) =>
                  entry.label === dashFacets[facet.name] && (
                    <CheckboxAsButton
                      data-element-id={facet.name}
                      renderLabelIcon={() => dasButtonIcon}
                      key={facet.value}
                      labelText={t(`ProductFilter.Labels.${facet.name}`)}
                      checked={ProductService.isFacetSelected(facet, entry, selectedFacets)}
                      id={facet.value}
                      onChange={() => fetchProducts(getUpdatedSelectedFacets(facet, entry))}
                      disabled={areFacetsLoading || areProductsLoading || entry.count === '0'}
                    />
                  )
              )
            })
          ) : (
            <SkeletonTheme color="transparent" highlightColor={'rgba(0, 0, 0, 0.05)'}>
              <Skeleton
                className="first-btn-skeleton"
                style={{ borderRadius: 100 }}
                width="140px"
                height="40px"
              />
              <Skeleton style={{ borderRadius: 100 }} width="140px" height="40px" />
            </SkeletonTheme>
          )}
        </StyledPLPFiltersBarButtonsWrapper>
      </StyledPLPFiltersBar>}
    </>
  )
}

export default PLPHeader
