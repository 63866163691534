import {
  PRODUCT,
  CATEGORY,
  PURCHASE,
  BILLING,
  CUSTOM_PRODUCT,
  SET_PAGE_TYPE,
  SEARCH,
  ADD_CART_ROWS,
  ADD_PRODUCT_DETAILS,
  ADD_PRODUCTS,
  ADD_PURCHASE_ROWS,
  TRACK_DATA,
} from './pageTypes'

import Log from '../../services/Log'
import { ProductBase } from '../../types/product'
import { OrderItem } from '../../types/order'
import unionBy from 'lodash/unionBy'

export interface ProductForMonetate {
  productId?: string
  sku?: string
  quantity?: string
  unitPrice?: string
  currency?: string
}

function sanitizeSku(sku) {
  if (sku === undefined) {
    return ''
  }
  return sku.replace(/ /g, '_')
}

function mapPurchaseRows(orderId, productsInCart: ProductForMonetate[]) {
  const purchaseRows = productsInCart.map((product) => {
    return {
      ...product,
      purchaseId: orderId,
    }
  })
  return purchaseRows
}

export function mapProductsInCart(productsInCart: OrderItem[], promoAdjust?) {
  let mappedProducts = productsInCart?.map((item) => {
    return {
      productId: item.partNumber,
      quantity: item.quantity,
      unitPrice: item.orderItemPrice,
      currency: item.currency,
    }
  })
  /*if (promoAdjust && promoAdjust !== 0) {
    const objectDiscount = {
      productId: 'DISCOUNT',
      quantity: '1',
      unitPrice: String(parseInt(promoAdjust)),
      currency: item.currency,
    }
    mappedProducts.push(objectDiscount)
  }*/
  return mappedProducts
}

function mergeProductsInCart(productsInCart: ProductForMonetate[]): ProductForMonetate[] {
  const mergedProducts = unionBy(productsInCart, 'productId').map((item) => {
    const found = productsInCart.filter((temp) => {
      return temp.productId === item.productId
    })?.length
    item.quantity = String(found)
    return item
  })
  return mergedProducts
}

function mapPageType(pageType) {
  switch (pageType) {
    case 'HomePage':
      return 'main'
    case 'pdp':
      return PRODUCT
    case 'plp':
      return CATEGORY
    case 'cart':
      return 'cart'
    case 'shipping':
      return 'shipping'
    case 'payment':
      return BILLING
    case 'order-confirmation':
      return PURCHASE
    case 'RemixProductDisplay':
      return CUSTOM_PRODUCT
    case 'SearchDisplay':
    case 'SearchResults':
      return SEARCH
    default:
      return 'other_page'
  }
}

export const getMonetate = () => {
  return window.monetateQ || []
}

export const addToBag = (
  productsInCart: ProductForMonetate[],
  currentProduct: ProductBase,
  pageType?: string
) => {
  try {
    trackSingleProduct(
      currentProduct.uniqueID || currentProduct.id,
      currentProduct.partNumber,
      productsInCart,
      pageType
    )
  } catch (e) {
    Log.error('MONETATE ADD TO BAG TRACK ERROR ', e)
  }
}

export const trackSingleProduct = (
  productId: string,
  partNumber: string,
  productsInCart: ProductForMonetate[],
  pageType?: string
) => {
  try {
    const M = getMonetate()
    const sanitizedPartNumber = sanitizeSku(partNumber)
    const currentProduct: ProductForMonetate = {
      productId: productId,
      sku: sanitizedPartNumber,
    }
    M.push([ADD_PRODUCT_DETAILS, [currentProduct]])
    Log.info('MONETATE SINGLE PRODUCT', JSON.stringify(currentProduct))
    trackCart(productsInCart, pageType, M)
  } catch (e) {
    Log.error('MONETATE SINGLE PRODUCT TACK ERROR ', e)
  }
}

export const trackProducts = (
  productsIds: string[],
  productsInCart: ProductForMonetate[],
  pageType?: string
) => {
  try {
    const M = getMonetate()
    M.push([ADD_PRODUCTS, productsIds])
    Log.info('MONETATE PRODUCTS', JSON.stringify(productsIds))
    trackCart(productsInCart, pageType, M)
  } catch (e) {
    Log.error('MONETATE PRODUCTS TRACK ERROR ', e)
  }
}

export const trackPurchase = (purchaseId, productsInCart: ProductForMonetate[]) => {
  try {
    const M = getMonetate()
    productsInCart = mergeProductsInCart(productsInCart)
    const purchaseRows = mapPurchaseRows(purchaseId, productsInCart)
    M.push([ADD_PURCHASE_ROWS, purchaseRows])
    M.push([SET_PAGE_TYPE, PURCHASE])
    Log.info('MONETATE PURCHASE', JSON.stringify(purchaseRows))
    sendMonetateEvent()
  } catch (e) {
    Log.error('MONETATE PURCHASE TRACK ERROR ', e)
  }
}

export const trackCart = (productsInCart: ProductForMonetate[], pageType?: string, M?) => {
  try {
    if (M == null) {
      M = getMonetate()
    }
    productsInCart = mergeProductsInCart(productsInCart)
    const mappedPageType = mapPageType(pageType)
    M.push([SET_PAGE_TYPE, mappedPageType])
    M.push([ADD_CART_ROWS, productsInCart])
    Log.info('MONETATE CART', JSON.stringify({ products: productsInCart, pageType: pageType }))
    sendMonetateEvent()
  } catch (e) {
    Log.error('MONETATE TRACK CART ERROR ', e)
  }
}

export function sendMonetateEvent() {
  window.monetateQ && window.monetateQ.push([TRACK_DATA])
}
