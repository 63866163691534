//Standard libraries
import { createReducer, AnyAction, PayloadAction } from '@reduxjs/toolkit'
//Redux
import initStates from './initStates'
import { UIReducerState } from './reducerStateInterface'
import {
  FILTERS_DRAWER_TOGGLE_ACTION,
  CART_SUMMARY_DRAWER_TOGGLE_ACTION,
  NEWSLETTER_DRAWER_TOGGLE_ACTION,
  MY_ACCOUNT_DRAWER_TOGGLE_ACTION,
  SET_PAGE_TYPE_ACTION,
  PRODUCT_AVAILABLE_DRAWER_TOGGLE_ACTION,
  SET_LAST_SELECTED_PRODUCT_ACTION,
  SEARCH_DRAWER_TOGGLE_ACTION,
  SET_ACTUAL_PASSWORD_LOGIN_ACTION,
} from '../actions/ui'
import { GET_SEO_CONFIG_SUCCESS_ACTION } from '../actions/seo'

/**
 * Ui reducer
 * handles states used by ui related components
 * @param state State object managed by ui reducer
 * @param action The dispatched action
 */
const uiReducer = createReducer(initStates.ui, (builder) => {
  builder.addCase(FILTERS_DRAWER_TOGGLE_ACTION, (state: UIReducerState, action: AnyAction) => {
    state.filtersDrawerOpen = action.payload
  })
  builder.addCase(CART_SUMMARY_DRAWER_TOGGLE_ACTION, (state: UIReducerState, action: AnyAction) => {
    state.cartSummaryDrawerOpen = action.payload
  })
  builder.addCase(NEWSLETTER_DRAWER_TOGGLE_ACTION, (state: UIReducerState, action: AnyAction) => {
    state.newsletterDrawerOpen = action.payload
  })
  builder.addCase(SEARCH_DRAWER_TOGGLE_ACTION, (state: UIReducerState, action: AnyAction) => {
    state.searchDrawerOpen = action.payload
  })
  builder.addCase(
    PRODUCT_AVAILABLE_DRAWER_TOGGLE_ACTION,
    (state: UIReducerState, action: AnyAction) => {
      state.productNotificationAvailable = action.payload
    }
  )
  builder.addCase(MY_ACCOUNT_DRAWER_TOGGLE_ACTION, (state: UIReducerState, action: AnyAction) => {
    state.myAccountDrawerOpen = action.payload
  })

  builder.addCase(
    GET_SEO_CONFIG_SUCCESS_ACTION,
    (state: UIReducerState | any, action: AnyAction) => {
      const { identifier } = action.payload
      state.currentIdentifier = identifier
      /*const seoconfig = getSEOConfigFromPayload(action.payload)
      Object.assign(state, { ...seoconfig })*/
    }
  )
  builder.addCase(SET_PAGE_TYPE_ACTION, (state: UIReducerState, action: AnyAction) => {
    state.pageType = action.payload
  })

  builder.addCase(
    SET_LAST_SELECTED_PRODUCT_ACTION,
    (state: UIReducerState, action: PayloadAction<string>) => {
      state.lastSelectedProduct = action.payload
    }
  )

  builder.addCase(
    SET_ACTUAL_PASSWORD_LOGIN_ACTION,
    (state: UIReducerState, action: PayloadAction<string>) => {
      state.actualPasswordLogin = action.payload
    }
  )
})

export default uiReducer
