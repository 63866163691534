//Standard libraries
import React from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'

import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

//Custom libraries
import * as ROUTES from '../../constants/routes';
import ErrorMessageSnackbar from '../widgets/message-snackbar/ErrorMessageSnackbar'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
//UI
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { Hidden } from '@material-ui/core'
import { StyledReducedHeader, StyledButtonAsLink } from '../StyledUI'
import Logo from '../widgets/logo/Logo'
import { ArrowLeftBigIcon, SecurePaymentIcon } from '../StyledUI/Icons/Icons'

const StyledArrowLeftBigIcon = styled(ArrowLeftBigIcon)`
  display: flex;
  fill: ${({ theme }) => theme.palette.custom.warmGrey};
`

const StyledASecurePaymentIcon = styled(SecurePaymentIcon)`
  display: flex;
  fill: ${({ theme }) => theme.palette.custom.warmGrey};
`

const StyledArrowContainer = styled.div`
  width: 68px;
  padding: 10px 22px !important;
`

/**
 * Reduced header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
const ReducedHeader: React.FC = () => {
  const history = useHistory<{ prevUrl?: string }>()
  const { basePath, langCode } = useStoreIdentity()

  const { t } = useTranslation()
  const theme = useTheme()

  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'))
  
  const onGoBackArrowClick = () => {
    if (history.location.state?.prevUrl) {
      history.push(history.location.state.prevUrl)

      return
    }

    history.push(generatePath(ROUTES.CART, { country: langCode }))
  }

  return (
    <>
      <StyledReducedHeader theme={theme} className="arn-header arn-header--reduced">
        <ErrorMessageSnackbar />
        <StyledArrowContainer className="arn-header__item arn-header__item--back">
          <StyledButtonAsLink onClick={onGoBackArrowClick} aria-label="Back">
            <StyledArrowLeftBigIcon />
          </StyledButtonAsLink>
        </StyledArrowContainer>
        <div className="arn-header__item arn-header__item--logo">
          <a href={basePath} aria-label="Logo">
            <Logo width={isMobile ? 100 : 160} />
          </a>
        </div>
        <div className="arn-header__item arn-header__item--right">
          <div className="secure-payments">
            <StyledASecurePaymentIcon />
            <Hidden smDown>
              <span className="secure-payments__text">{t('Header.Actions.SecurePayment')}</span>
            </Hidden>
          </div>
        </div>
      </StyledReducedHeader>
    </>
  )
}

export { ReducedHeader }
