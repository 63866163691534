import { IOrderDetails, OrderItem } from '../../../../types/order'
import { ProductForAnalytics, ProductForAnalyticsFields } from '../interfaces'
import {
  getBrand,
  getFrameMaterial,
  getFrameShape,
  getFrontColor,
  getLensesColor,
  getLensesTreatment,
  getModelCode,
  getModelName,
  getProductType,
  getSoldOut,
} from '../../../../utils/productAttributes'

import { ProductAnalyticsRX, ProductBase, ProductSoldOutStatus } from '../../../../types/product'
import get from 'lodash/get'
import { getRxPrice } from '../../../../utils/isRxOrder'
import ProductService from '../../../../services/ProductService'

export const productTypeToCategory = {
  Optical: 'OPTICS',
  Sun: 'SUN',
} as const

export const parseCatentriesForRX = (
  orderItems: OrderItem[],
  products: ProductBase[]
): ProductAnalyticsRX[] => {
  // If there's an RX product, removes from the catEntries the lens and services objects (that are provided as a normal item)
  // Then, add to the single RX item (the frame), the total RX price.
  const RX_FRAME = orderItems.find((i) => !!i?.roxableServices?.length)!
  if (RX_FRAME) {
    const FRAME_ID = RX_FRAME.productId
    const SERVICES_IDS = RX_FRAME!.roxableServices!.map((s) => s.productId) as string[]
    const FRAME_CATENTRIES = products.filter(
      (f) => !SERVICES_IDS.includes(f.id)
    ) as ProductAnalyticsRX[]
    const FRAME_CATENTRY = FRAME_CATENTRIES.find((f) => f.id === FRAME_ID)
    const FRAME_CATENTRY_INDEX = FRAME_CATENTRIES.findIndex((f) => f.id === FRAME_ID)
    const RX_PRICE = getRxPrice(RX_FRAME.roxableServices!, FRAME_CATENTRY?.x_offerpriceRx)
    FRAME_CATENTRIES[FRAME_CATENTRY_INDEX] = {
      ...FRAME_CATENTRIES[FRAME_CATENTRY_INDEX],
      rxPrice: RX_PRICE,
    }

    return FRAME_CATENTRIES
  }

  return products
}

const PRODUCT_SOLDOUT_STATUS: Record<
  ProductSoldOutStatus,
  Pick<ProductForAnalyticsFields, 'Status' | 'OosOptions'>
> = {
  NONE: { Status: 'Available', OosOptions: '' },
  'SOLD OUT': { Status: 'Not-for-sale', OosOptions: '' },
  'COMING SOON': { Status: 'Out-of-stock', OosOptions: 'EmailMeWhenAvailable' },
  'COMING BACK SOON': { Status: 'Out-of-stock', OosOptions: 'EmailMeWhenAvailable' },
}

// TODO product got from addToCartEvent don't have prices
// TODO Fix Status when add to cart
const formatProduct = (item: ProductAnalyticsRX, quantity?: number): ProductForAnalyticsFields => {
  const Category = productTypeToCategory[getProductType(item)] || ''
  const initialPrice = ProductService.getInitialPrice(item.price).toFixed(2) || ''
  const currentPrice = ProductService.getCurrentPrice(item.price).toFixed(2) || ''

  const productContext: ProductForAnalyticsFields = {
    ...getSoldOutWithDefault(item),
    Badges: '', // TODO
    Brand: getBrand(item),
    CancelledUnits: '',
    CancelledAmount: '',
    Category,
    FrameColor: getFrontColor(item),
    FrameTechnology: getFrameMaterial(item),
    FrameType: 'STD',
    InsuranceAmount: '',
    InsuranceCode: '',
    LensColor: getLensesColor(item),
    LensTechnology: getLensesTreatment(item),
    LensType: item.rxPrice ? 'RX' : 'PLANO',
    LensUPC: '',
    MoCo: `${item.name}`,
    ModelName: getModelName(item),
    ModelCode: getModelCode(item),
    PerkCode: '',
    Price: (item.rxPrice ? item.rxPrice.toFixed(2) : currentPrice).toString(),
    PriceFull: (item.rxPrice ? item.rxPrice.toFixed(2) : initialPrice).toString(),
    Shape: getFrameShape(item),
    Sku: `${item.parentCatalogEntryID}`,
    TaxRate: '',
    Type: item.rxPrice ? 'RX' : 'STD',
    Units: quantity?.toString() || '1',
    Visibility: 'Public',
    Warranty: '0',
  }

  return productContext
}

export const getSoldOutWithDefault = (
  product: ProductBase
): Pick<ProductForAnalyticsFields, 'Status' | 'OosOptions'> => {
  const soldOutLabel: ProductSoldOutStatus | string = getSoldOut(product)

  return PRODUCT_SOLDOUT_STATUS[soldOutLabel] || { Status: '', OosOptions: '' }
}

const formatProductForTYP = (orderItem: OrderItem): Partial<ProductForAnalyticsFields> => ({
  TaxRate: orderItem.salesTax,
})

export const getProductsForAnalytics = (products: ProductAnalyticsRX[]): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, p: ProductAnalyticsRX) => {
    if (p.id) {
      acc[p.id] = {
        ...formatProduct(p),
      }
    }

    return acc
  }, {})
}

export const getProductsParentForAnalytics = (products: ProductAnalyticsRX[]): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, p: ProductAnalyticsRX) => {
    if (p.parentCatalogEntryID) {
      acc[p.parentCatalogEntryID] = {
        ...formatProduct(p),
      }
    }

    return acc
  }, {})
}

/** Products formatter for cart-related analytics events.
 * @returns map with unique `string` id as key and `ProductForAnalyticsFields` as value
 */
export const getProductsInCartForAnalytics = (
  products: ProductBase[],
  productPartNumbersCountInOrderMap: Record<string, number>
): ProductForAnalytics => {
  return products.reduce((acc: ProductForAnalytics, product) => {
    if (product && product.partNumber) {
      const { id } = product

      acc[id] = {
        ...formatProduct(product, productPartNumbersCountInOrderMap[id]),
      }
    }

    return acc
  }, {})
}

export const getProductsForTYPAnalytics = (
  products: ProductBase[],
  orderDetails: IOrderDetails
): ProductForAnalytics => {
  const orderItems = orderDetails.orderItem || []
  const productPartNumbersCountInOrderMap: Record<string, number> = products.reduce(
    (productPartNumbersCountInOrder, product) => {
      const { partNumber: productPartNumber } = product
      const count = orderItems.filter(({ partNumber }) => partNumber === productPartNumber).length

      return { ...productPartNumbersCountInOrder, [productPartNumber]: count }
    },
    {}
  )

  let formattedProducts = getProductsInCartForAnalytics(products, productPartNumbersCountInOrderMap)

  for (const orderItemId in formattedProducts) {
    const currentFormattedProduct = formattedProducts[orderItemId]
    const orderItem = orderItems.find((i) => i.orderItemId === orderItemId)

    if (orderItem) {
      formattedProducts[orderItemId] = {
        ...currentFormattedProduct,
        ...formatProductForTYP(orderItem),
      }
    }
  }

  return formattedProducts
}

export const getOrderTotalDiscount = (orderDetails: IOrderDetails): string => {
  const totalDiscount = orderDetails.adjustment?.reduce((acc, adj) => {
    if (adj.usage === 'Discount') {
      return acc + Number(adj.amount)
    }
    return acc
  }, 0)
  return String(totalDiscount)
}

export const getOrderState = (orderDetails: IOrderDetails): string =>
  get(orderDetails, 'paymentInstruction[0].country')

export const getOrderZipCode = (orderDetails: IOrderDetails): string =>
  get(orderDetails, 'paymentInstruction[0].zipCode')

export const getOrderPaymentType = (orderDetails: IOrderDetails): string =>
  get(orderDetails, 'paymentInstruction[0].piDescription', '').replace(/\s/g, '')
