import { ProductBase, ProductSoldOutStatus } from '../types/product'
import { TFunction } from 'react-i18next'
import get from 'lodash/get'

type Product = Pick<ProductBase, 'attributes' | 'cluster'>

export const PRODUCT_ATTRIBUTE_TRUE_VALUE = 'True' as const

export const PRODUCT_SOLDOUT_ATTRIBUTE_ID = 'LX_SOLDOUT' as const

export const getProductAttribute = (p?: Product, key?: string, subKey?: string) => {
  const attributes = p?.attributes || []
  let attribute = attributes.find((attr) => attr.identifier === key)

  if (!attribute) {
    const clusters = p?.cluster
    if (clusters && clusters.length) {
      const cluster = clusters[0]
      const clusterAttributes = cluster?.attributes || []
      attribute = clusterAttributes.find((attr) => attr.identifier === key)
    }
  }
  if (subKey) {
    const item = attribute?.values?.find((v) => v.identifier === subKey)
    return item ? item.value : ''
  } else {
    return get(attribute, 'values[0].value', '')
  }
}

export const getFrameShape = (p: Product) => getProductAttribute(p, 'FRAME_SHAPE')
export const getFrameMaterial = (p: Product) => getProductAttribute(p, 'FRAME_MATERIAL_CLASS')
export const getFrameMaterialFacet = (p: Product) => getProductAttribute(p, 'FRAME_MATERIAL_FACET')

export const getIsPolarized = (p: Product) => getProductAttribute(p, 'POLARIZED')
export const getIsRoxable = (p?: Product) =>
  getProductAttribute(p, 'ROXABLE') === PRODUCT_ATTRIBUTE_TRUE_VALUE
export const getIsSustainable = (p: Product) => getProductAttribute(p, 'SUSTAINABILITY_CLAIM')
export const getIsCustomizable = (p: Product) => getProductAttribute(p, 'CUSTOMIZABLE')
export const getIsOnlineExclusive = (p: Product) => getProductAttribute(p, 'SPECIAL_PROJECT_FLAG')
export const getIsLimitedEdition = (p: Product) => getProductAttribute(p, 'LIMITED_EDITION')
export const getNew = (p: Product) => getProductAttribute(p, 'IS_NEW')
export const getBadge = (p: Product) => getProductAttribute(p, 'BADGE')
export const getSoldOut = (p: Product): ProductSoldOutStatus => {
  return getProductAttribute(p, PRODUCT_SOLDOUT_ATTRIBUTE_ID)
}

export const getFrontColor = (p?: Product) => getProductAttribute(p, 'FRONT_COLOR')
export const getLensesColor = (p?: Product) => getProductAttribute(p, 'LENS_COLOR')
export const getLensesColorFacet = (p?: Product) => getProductAttribute(p, 'LENS_COLOR_FACET')
export const getTempleColor = (p?: Product) => getProductAttribute(p, 'TEMPLE_COLOR')
export const getLensesTreatment = (p: Product) => getProductAttribute(p, 'LENS_TREATMENT_FACET')
export const getLensMacroMaterial = (p: Product) =>
  getProductAttribute(p, 'LENS_MATERIAL_MACRO_CLASS')
export const getIncludedItemsImageUrl = (p?: Product): string =>
  getProductAttribute(p, 'INCLUDED_ITEMS_IMAGE_URL') || ''

export const getCaseImageUrl = (p: Product) =>
  getProductAttribute(p, 'INCLUDED_ITEMS', 'INCLUDED_ITEMS_IMAGE_URL')

export const PRODUCT_SIZES_NLS_BASE = 'ProductTile.Sizes.'
/** Returns formatted product size if TFunction from react-i18n was passed as argument.
 * Otherwise returns value of FRAME_SIZE_DISPLAY attribute */
export const getSize = (p: Product, t?: TFunction<'translation'>): string => {
  const frameSizeDisplay = getProductAttribute(p, 'FRAME_SIZE_DISPLAY')
  const productSizeNls = PRODUCT_SIZES_NLS_BASE + frameSizeDisplay

  return t && productSizeNls ? t(productSizeNls) : frameSizeDisplay
}

export const getBrand = (p?: Product) => getProductAttribute(p, 'BRAND')
export const getModelName = (p?: Product) => getProductAttribute(p, 'MODEL_NAME')
export const getProductType = (p?: Product) => getProductAttribute(p, 'PRODUCT_TYPE') as string
export const getModelCode = (p: Product) => getProductAttribute(p, 'DISPLAYSKU')
export const getBridgeWidth = (p: Product) => getProductAttribute(p, 'BRIDGE_WIDTH')
export const getLensWidth = (p: Product) => getProductAttribute(p, 'LENS_WIDTH')
export const getTempleLength = (p: Product) => getProductAttribute(p, 'TEMPLE_LENGTH')
export const getDiscountedPriceBadges = (p?: Product) => getProductAttribute(p, 'LX_DISCOUNTED_PRICE_BADGES')

export const getCanonicalCategory = (p: Product) => {
  return get(
    p?.attributes.find((attr) => attr.identifier === 'LX_CANONICAL_CATEGORY'),
    'values[0].identifier',
    ''
  )
}



export const getProductColorFacet = (facetsMap, key: string) => {
  let facetInfo = facetsMap.filter((facet) => {
    return key.includes(facet.key)
  })
  return facetInfo[0] || null
}

/** Solution to manually add soldout attribute to product. Used if the product is not available in inventory. */
export const addSoldoutAttributeToProduct = (
  product: ProductBase,
  soldoutStatus: ProductSoldOutStatus
): ProductBase => {
  const soldoutAttributeIndexFromProduct = product.attributes.findIndex(
    ({ identifier }) => identifier === PRODUCT_SOLDOUT_ATTRIBUTE_ID
  )

  if (soldoutAttributeIndexFromProduct === -1) {
    return product
  }

  const productCopy = Object.assign({}, product)

  productCopy.attributes[soldoutAttributeIndexFromProduct].values[0].value = soldoutStatus

  return productCopy
}

export const getNormalizedProductType = (p?: Product) => {
  const pt: string = getProductType(p)
  const res = pt && PRODUCT_TYPES_MAP[pt.toLowerCase()]
  return res
}
