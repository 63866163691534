export const HANDLE_SESSION_ERROR = 'HANDLE_SESSION_ERROR'
export const RESET_ERROR = 'RESET_ERROR'
export const RESET_SESSION_ERROR_SUCCESS = 'RESET_SESSION_ERROR_SUCCESS'
export const RESET_SESSION_ERROR = 'RESET_SESSION_ERROR'
export const WATCH_AXIOS_ERROR = 'WATCH_AXIOS_ERROR'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'
export const CANCEL_SESSION_ERROR = 'CANCEL_SESSION_ERROR'
export const RESET_SESSION_POPUP_LOGON_ERROR = 'RESET_SESSION_POPUP_LOGON_ERROR'
export const CMC_SESSION_ERROR = 'CMC_SESSION_ERROR_ACTION'
export const CONNECTION_TIMEDOUT_ERROR_ACTION = 'CONNECTION_TIMEDOUT_ERROR_ACTION'
export const CMS_API_ERROR = 'CMS_API_ERROR'
