import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import React from 'react'

const LoadingSkeleton = () => {
  return (
    <SkeletonTheme color="transparent">
      <Skeleton width={'100%'} height={800} />
    </SkeletonTheme>
  )
}

export default LoadingSkeleton
