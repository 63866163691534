import { createAction } from '@reduxjs/toolkit'
//Redux
import * as ACTIONTYPES from '../action-types/ui'

const PRODUCT_AVAILABLE_DRAWER_TOGGLE_ACTION = createAction<boolean>(
  ACTIONTYPES.PRODUCT_AVAILABLE_DRAWER_TOGGLE
)
const FILTERS_DRAWER_TOGGLE_ACTION = createAction<boolean>(ACTIONTYPES.FILTERS_DRAWER_TOGGLE)
const CART_SUMMARY_DRAWER_TOGGLE_ACTION = createAction<boolean>(
  ACTIONTYPES.CART_SUMMARY_DRAWER_TOGGLE
)
const NEWSLETTER_DRAWER_TOGGLE_ACTION = createAction<boolean>(ACTIONTYPES.NEWSLETTER_DRAWER_TOGGLE)
const SEARCH_DRAWER_TOGGLE_ACTION = createAction<boolean>(ACTIONTYPES.SEARCH_DRAWER_TOGGLE)

const SET_PAGE_TYPE_ACTION = createAction<string>(ACTIONTYPES.SET_PAGE_TYPE)
const MY_ACCOUNT_DRAWER_TOGGLE_ACTION = createAction<boolean>(ACTIONTYPES.MY_ACCOUNT_DRAWER_TOGGLE)

const SET_LAST_SELECTED_PRODUCT_ACTION = createAction<string>(ACTIONTYPES.SET_LAST_SELECTED_PRODUCT)

const SET_ACTUAL_PASSWORD_LOGIN_ACTION = createAction<string>(ACTIONTYPES.SET_ACTUAL_PASSWORD_LOGIN)


export {
  PRODUCT_AVAILABLE_DRAWER_TOGGLE_ACTION,
  CART_SUMMARY_DRAWER_TOGGLE_ACTION,
  NEWSLETTER_DRAWER_TOGGLE_ACTION,
  MY_ACCOUNT_DRAWER_TOGGLE_ACTION,
  FILTERS_DRAWER_TOGGLE_ACTION,
  SET_PAGE_TYPE_ACTION,
  SET_LAST_SELECTED_PRODUCT_ACTION,
  SEARCH_DRAWER_TOGGLE_ACTION,
  SET_ACTUAL_PASSWORD_LOGIN_ACTION
}
