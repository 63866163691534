/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React, { FC } from 'react'
import styled from 'styled-components'
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs'
import { ArrowRightSlimIcon } from '../Icons/Icons'
import { useSelector, shallowEqual } from 'react-redux'
import { themeModeSelector } from '../../../redux/selectors/theme'
import theme from '../../../themes'
import WithLoading from '../../widgets/with-loading/WithLoading'

const StyledBreadcrumbsWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: block;
    padding: ${({ theme }) => theme.spacing(1)}px;
  }

  li {
    margin: 0;
    font-size: 12px;
  }
`

const StyledSeparator = styled(ArrowRightSlimIcon)`
  padding-bottom: 2px;
`

const Breadcrumbs: FC<{ isLoading: boolean }> = ({ children, isLoading }) => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)

  return (
    <StyledBreadcrumbsWrapper>
      <WithLoading height="24px" isLoading={isLoading}>
        <MuiBreadcrumbs
          children={children}
          separator={
            <StyledSeparator
              htmlColor={theme.palette.primary[themeMode === 'dark' ? 'contrastText' : 'dark']}
            />
          }
        />
      </WithLoading>
    </StyledBreadcrumbsWrapper>
  )
}

export default Breadcrumbs
