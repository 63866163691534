import { dimensions } from '../../../themes/variables'
import styled from 'styled-components'

const headerHeight = dimensions.header.height.desktop
const mobileHeaderHeight = dimensions.header.height.mobile
const customMobileSize = 1023
const MAX_WIDTH_FIVE_LINKS_HEADER = 1367

export const StyledHeader = styled.nav`
  ${({ theme }) => `
  position: relative;
  border-bottom: 1px solid ${theme.palette.custom.black};
  color: ${theme.palette.text.dark};

  .arn-header {
    &__top-container {
      overflow: auto;
      display: flex;
      width: auto;
      padding: 14px 16px 14px 16px;
      background-color: ${theme.palette.custom.black};
    }

    &__item {
      max-height: ${headerHeight}px;
      display: flex;
      align-items: center;

      @media (max-width: ${customMobileSize}px) {
        max-height: ${mobileHeaderHeight}px;
      }

      &--logo {
        flex: 0 0 auto;
        margin-right: ${theme.spacing(4.8125)}px;

        a {
          vertical-align: middle;
          svg {
            vertical-align: bottom;
          }
        }
      }

      &--right {
        flex: 1 0 auto;
        justify-content: flex-end;
      }

      &--menu {
        display: none;
        flex: 1 0 auto;
        justify-content: flex-start;
        margin: auto 0;

        .expanded-menu {
          width: 100%;
          &__item {
            padding: 0 ${theme.spacing()}px;

            @media (max-width: ${customMobileSize}px) {
              padding: 0 ${theme.spacing(0.5)}px;
            }
          }
        }

        @media (min-width: ${customMobileSize + 1}px) {
          display: block;
        }
      }

      &--cta {
        display: flex;
        justify-content: center;
        padding: 0 ${theme.spacing()}px;

        @media (max-width: ${MAX_WIDTH_FIVE_LINKS_HEADER}px) {
          padding: 0 ${theme.spacing(0.5)}px;
        }
        a {
          display: flex;
          color: inherit;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
`}
`

export const StyledReducedHeader = styled.nav`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.palette.custom.black};
  .arn-header {
    &__item {
      padding: 10px ${({ theme }) => theme.spacing()}px;

      &--back {
        border-right: 1px solid ${({ theme }) => theme.palette.custom.warmGrey};
      }

      &--logo {
        flex: 1;
        @media (max-width: ${customMobileSize}px) {
          justify-content: center;
          a {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
          }
        }
        a {
          vertical-align: middle;
          svg {
            vertical-align: bottom;
          }
        }
      }
      &--right {
        display: flex;
        color: ${({ theme }) => theme.palette.custom.warmGrey};
        border-left: 1px solid ${({ theme }) => theme.palette.custom.warmGrey};
        .secure-payments {
          display: flex;
          align-items: center;
          .secure-payments__text {
            text-transform: uppercase;
            font-size: 14px;
            padding-left: 10px;
          }
        }
      }
    }
  }
`

export const StyledContentMenu = styled.div`
  display: none;

  @media (min-width: ${customMobileSize + 1}px) {
    display: block;
  }
`

export const StyledBenefitBar = styled.div`
  padding: 2px 0;
  
  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: block;
    padding: 12px 0;
    background-color: ${({ theme }) => theme.palette.custom.warmGrey};

    .arn-icon--green-shipping{
      margin-bottom: 5px;
    }
  }
`
