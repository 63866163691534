import React, { useEffect, useState } from 'react'
import { StyledAnchor, StyledTypography } from '../../../StyledUI'
import { teaserAlignToFlex, teaserOverlaySettingsToCSS } from '../../../../utils/placements'

import CMSCommonMedia from '../cms-common-media'
import CMSIcon from '../cms-icon'
import CallToAction from '../call-to-action'
import { ILXTeaser, IPlacementPriority } from '../../../../types/teaser'
import cmsService from '../../../../foundation/apis/cms/cms.service'
import styled from 'styled-components'
import usePlayerBanner from '../../../../hooks/useBannerPlayer'
import CmsVideoMedia from '../video-media'
import { HTMLContainer } from '../HTMLContainer'

interface IProps {
  index?: number
  teaserIndex?: number
  teaser: ILXTeaser
  variant?: string
  placementName?: string
}

const StyledContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 9 / 4;
`

const StyledContent = styled.div<{
  isVideo: boolean
  overlaySettings: ILXTeaser['teaserOverlay1Settings']
  overlayTextAlign: string
  variant?: string
  teaserTextColor?:string
}>`
  ${({ isVideo, overlaySettings, overlayTextAlign, theme, variant , teaserTextColor}) => `
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: ${(teaserTextColor)? teaserTextColor: theme.palette.primary.contrastText};
  text-align: ${overlayTextAlign};
  align-items: ${teaserAlignToFlex(overlayTextAlign)};
  ${teaserOverlaySettingsToCSS(overlaySettings)};
  ${variant ? '' : `width: calc(50% - ${theme.spacing(2)}px)`};
  ${overlaySettings === 'block-left-bottom' && isVideo ? `bottom:${theme.spacing(4)}px}` : ''};
`}
`

const StyledHeadline = styled(StyledTypography)`
  line-height: 1;
  letter-spacing: normal;

  font-size: 56px;
  font-weight: bold;
  white-space: pre-wrap;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 32px;
    font-weight: normal;
  }
`

const StyledCTAContainer = styled.div<{ overlayTextAlign: string }>`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    & > * {
      &:not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
      }
    }
  }
`

const StyledOverlap = styled.div<{
  overlaySettings: ILXTeaser['teaserOverlay1Settings']
  overlayTextAlign: string
}>`
  ${({ overlayTextAlign }) => `
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: ${overlayTextAlign};

  & > a {
    max-height: 100%;
    flex: 1;
  }
`}
`

const Desktop: React.FC<IProps & IPlacementPriority> = ({ index = 1, teaserIndex, teaser, variant, placementName, fetchpriority }) => {
  const media = teaser.media?.[0] ?? undefined
  const video = media?.type === 'CMVideo' ? media : undefined
  const playerBanner = usePlayerBanner(video)
  const [teaserText, setTeaserText] = useState<string>(teaser?.teaserText1)
  const [countdownTimeLeft, setCoundownTimeLeft] = useState<string>('')

  useEffect(() => cmsService.initTeaserCountdownInterval(teaser, setCoundownTimeLeft), [])

  useEffect(() => {
    if (teaserText) {
      const stringToSearch = 'data-show="new"'
      const stringTargetBlank = 'target="_blank"'
      const re = new RegExp(stringToSearch, 'g')

      if (teaserText.toLowerCase().indexOf(stringToSearch) >= 0) {
        const newTeaserText = teaserText.replace(re, stringTargetBlank)
        setTeaserText(newTeaserText)
      }
    }
  }, [teaser])

  if (teaser.type !== 'LXTeaser') return null
  const dataElementIdImg = teaserIndex
    ? `X_HP_${teaserIndex}Hero_Banner1_IMG`
    : 'X_HP_1Hero_Banner1_IMG'
  const dataElementIdCta = `X_HP_${teaserIndex}Hero_Banner${index}_CTA`

  const toLink = teaser.teaserLXCallToActionSettings?.[0]?.target.formattedUrl
  const bannerImageCommonProps = {
    'aria-label': `Placement_Banner1_IMG link. Teaser №${teaserIndex}`,
    'data-element-id': `X_X_${teaserIndex}Placement_Banner1_IMG`,
  }

  const teaserTextColor = cmsService.getTextColorByTeaserOverlayStyle(teaser.teaserOverlay1Style)

  return (
    <StyledContainer
      className="arn-cms-content__full-width-wrapper"
      data-element-id={dataElementIdImg}>
      <StyledOverlap
        className="arn-cms-content__overlap"
        overlaySettings={teaser.teaserOverlay1Settings}
        overlayTextAlign={cmsService.getTeaserOverlayTextAlign(teaser.teaserOverlay1TextAlign)}
        {...playerBanner.events}>
        {toLink ? (
          <StyledAnchor
            {...bannerImageCommonProps}
            fontSize={0}
            to={toLink}
          >
            <CMSCommonMedia
              fetchpriority={fetchpriority}
              placementName={placementName}
              type={variant === 'PLP' ? 'PLP_BOX' : 'FULL_WIDTH_BANNER'}
              media={media}
              playerBannerHook={playerBanner}
            />
          </StyledAnchor>
        ) : (
          <CMSCommonMedia
            fetchpriority={fetchpriority}
            placementName={placementName}
            type={variant === 'PLP' ? 'PLP_BOX' : 'FULL_WIDTH_BANNER'}
            media={media}
            playerBannerHook={playerBanner}
          />
        )}
        <StyledContent
          className="arn-cms-content__text-wrapper"
          isVideo={!!video}
          overlaySettings={teaser.teaserOverlay1Settings}
          overlayTextAlign={teaser.teaserOverlay1TextAlign}
          variant={variant}
          teaserTextColor={teaserTextColor}>
          <div
            className="arn-cms-content__fwb--logo"
            {...cmsService.getMetaDataAttribute('[{"_":"properties.teaserIcon"}]')}>
            <CMSIcon teaserIcon={teaser.teaserIcon} color={'white'} />
          </div>

          {countdownTimeLeft && (
            <StyledHeadline variant={teaser.promoteToH1 ? 'h1' : 'h2'}>
              {countdownTimeLeft}
            </StyledHeadline>
          )}

          {teaser.teaserTitle1 && (
            <StyledHeadline
              className="arn-cms-content__fwb--title"
              fontColor={teaserTextColor}
              variant={teaser.promoteToH1 ? 'h1' : 'h2'}
              {...cmsService.getMetaDataAttribute('[{"_":"properties.teaserTitle1"}]')}>
              {teaser.teaserTitle1}
            </StyledHeadline>
          )}
          <HTMLContainer
            dangerouslySetInnerHTML={{ __html: teaser.teaserText1 }}
            fontColor={teaserTextColor}
            textAlign={cmsService.getTeaserOverlayTextAlign(teaser.teaserOverlay1TextAlign)}
            {...cmsService.getMetaDataAttribute('[{"_":"properties.teaserText1"}]')}
          />
          <StyledCTAContainer
            overlayTextAlign={cmsService.getTeaserOverlayTextAlign(teaser.teaserOverlay1TextAlign)}>
            {teaser.teaserLXCallToActionSettings.map(
              (cta, index) =>
                cta.callToActionEnabled && (
                  <CallToAction
                    {...cta}
                    themeMode={'dark'}
                    dataElementId={`${dataElementIdCta}${teaser.teaserLXCallToActionSettings?.length === 1 ? '' : index + 1
                      }`}
                    key={`cms-content__fwb--cta-${index}`}
                    style={cta.style}
                  />
                )
            )}
          </StyledCTAContainer>
        </StyledContent>
      </StyledOverlap>

      {video && (
        <CmsVideoMedia
          playerBanner={{
            isPlaying: playerBanner.isPlaying,
            muted: playerBanner.muted,
          }}
          toggleMute={() => playerBanner.setMuted((prev) => !prev)}
          togglePlay={() => playerBanner.setIsPlaying((prev) => !prev)}
        />
      )}
    </StyledContainer>
  )
}

export default Desktop
