
import { axiosBaseQuery } from '../../services/AxiosBaseQuery'
import { createApi, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { OauthIdentityResponse, SocialItem, LoginIdentityResponse, RegistrationBody, RegistrationResponse } from '../../types/account'
import { RootReducerState } from '../../redux/reducers'
import { UserDetails } from '../../types/user'


export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: axiosBaseQuery({
    baseUrl: '/',
  }),
  endpoints: (build) => ({
    getOauthInfo: build.mutation<
      OauthIdentityResponse,
      {
        storeId: string
        socialItem: SocialItem
        redirectUrl: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, socialItem, redirectUrl } = args
        const { authorizationProvider } = socialItem
        const result = await fetchWithBQ({
          url: `store/${storeId}/loginidentity/oauth`,
          method: 'POST',
          body:
            {
              authorizationProvider,
              redirectUrl,
            } || {},
        })

        if (result.error) {
          return { error: result.error as FetchBaseQueryError }
        }
        return { data: result.data as OauthIdentityResponse }
      },
    }),
    oauthValidate: build.mutation<
      LoginIdentityResponse,
      {
        storeId: string
        socialItem: SocialItem
        redirectUrl: string
        id?: string
        accessToken?: string
        clientId?: string
        email?: string
        birthday?: string
        first_name?: string
        last_name?: string
      }
    >({
      async queryFn(args, _queryApi, _extraOptions, fetchWithBQ) {
        const { storeId, socialItem, redirectUrl } = args
        const { authorizationProvider, code, accessToken, clientId } =
          socialItem
        const result = await fetchWithBQ({
          url: `store/${storeId}/loginidentity/oauth_validate`,
          method: 'POST',
          body:
            {
              ...args,
              authorizationProvider,
              code,
              accessToken,
              clientId,
              redirectUrl,
            } || {},
        })
        if (result.error) {
          return { error: result.error }
        }
        return { data: result.data as LoginIdentityResponse }
      },
    }),
    registerPerson: build.mutation<
    RegistrationResponse,
    RegistrationBody
  >({
    async queryFn(args, queryApi, _extraOptions, fetchWithBQ) {
      const state = queryApi.getState() as RootReducerState
      const storeID = state.site.currentSite?.storeID
      const result = await fetchWithBQ({
        url: `store/${storeID}/person`,
        method: 'POST',
        body:
          {
            ...args,
          } || {},
      })
      if (result.error) {
        return { error: result.error }
      }
      return { data: result.data as LoginIdentityResponse }
    },
  }),
  findPersonBySelf: build.query<
  UserDetails,
 {}
>({
  async queryFn(_args, queryApi, _extraOptions, fetchWithBQ) {
    const state = queryApi.getState() as RootReducerState
    const storeID = state.site.currentSite?.storeID
    const result = await fetchWithBQ({
      url: `store/${storeID}/person/@self`,
      method: 'GET',
    })

    if (result.error) {
      return { error: result.error as FetchBaseQueryError }
    }
    return { data: result.data as UserDetails }
  },
}),
  }),
})

export const { useFindPersonBySelfQuery,useRegisterPersonMutation } = accountApi