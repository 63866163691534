import { BASE, CART, CHECKOUT, CONTACT_US, WISHLIST, ORDER_HISTORY, ACCOUNT, CMS_PAGE_ROUTE } from '../../constants/routes'

import { currentPageSeoDataSelector } from '../../redux/selectors/seo'
import { searchDrawerSelector } from '../../redux/selectors/ui'
import { useRouteMatcher } from './usePathMatcher'
import { useSelector } from 'react-redux'

export const usePageType = () => {
  const seoData = useSelector(currentPageSeoDataSelector)
  const isSearchDrawerVisible = useSelector(searchDrawerSelector)
  const matchRoute = useRouteMatcher()

  const seoPageType: string = seoData?.page?.type || ''

  const pageTypes = {
    cmsPage: !!matchRoute(CMS_PAGE_ROUTE),
    search: isSearchDrawerVisible,
    home: !!matchRoute(BASE)?.isExact,
    cart: !!matchRoute(CART),
    checkout: !!matchRoute(CHECKOUT),
    plp: seoPageType === 'ProductListPage' || seoPageType === 'CategoryPage',
    pdp: seoPageType === 'ProductPage' || seoPageType === 'ItemPage',
    contactUs: !!matchRoute(CONTACT_US),
    wishlist: !!matchRoute(WISHLIST),
    page404: seoPageType === 'NotFound',
    orderHistory: !!matchRoute(ORDER_HISTORY),
    account: !!matchRoute(ACCOUNT),
  } as const

  return {
    pageType: Object.keys(pageTypes).find((key) => pageTypes[key]) || (seoData ? null : undefined),
  }
}
