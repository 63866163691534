import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { NavLink } from 'react-router-dom'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import * as ROUTES from '../../../constants/routes'

const AccountPageWishlistLink: FC = () => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()

  return (
    <NavLink to={generatePath(ROUTES.WISHLIST, { country: langCode })}>
      {t('AccountLinksGridView.WishListText')}
    </NavLink>
  )
}

export default AccountPageWishlistLink
