const mockedData = {
  attachments: [
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_PI20_Quarter_e4c28dd9-f977-4222-ad04-acb7013c4d1d/0AN4265__2767_2__STD__shad__qt',
      sequence: '14.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP',
      name: 'Quarter',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/e4c28dd9-f977-4222-ad04-acb7013c4d1d/0AN4265__2767_2__STD__shad__qt.png',
      attachmentAssetID: '3074457345616677305',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/e4c28dd9-f977-4222-ad04-acb7013c4d1d/0AN4265__2767_2__STD__shad__qt.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_PI20_Closed Front_2379553e-86c2-4f68-99fa-acb7013c4fda/0AN4265__2767_2__STD__shad__cfr',
      sequence: '19.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP',
      name: 'Closed Front',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/2379553e-86c2-4f68-99fa-acb7013c4fda/0AN4265__2767_2__STD__shad__cfr.png',
      attachmentAssetID: '3074457345616677306',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/2379553e-86c2-4f68-99fa-acb7013c4fda/0AN4265__2767_2__STD__shad__cfr.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_PI20_Alternative_3104e97c-5b50-4107-8664-acb7013c528f/0AN4265__2767_2__STD__shad__al2',
      sequence: '16.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP',
      name: 'Alternative',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/3104e97c-5b50-4107-8664-acb7013c528f/0AN4265__2767_2__STD__shad__al2.png',
      attachmentAssetID: '3074457345616677307',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/3104e97c-5b50-4107-8664-acb7013c528f/0AN4265__2767_2__STD__shad__al2.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_PI20_Back_7e97306c-1d03-4eb4-a24a-acb7013c5850/0AN4265__2767_2__STD__shad__bk',
      sequence: '18.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP',
      name: 'Back',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/7e97306c-1d03-4eb4-a24a-acb7013c5850/0AN4265__2767_2__STD__shad__bk.png',
      attachmentAssetID: '3074457345616677308',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/7e97306c-1d03-4eb4-a24a-acb7013c5850/0AN4265__2767_2__STD__shad__bk.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_PI20_Front_5d3314e7-fe06-4279-91eb-acb7013c5a33/0AN4265__2767_2__STD__shad__fr',
      sequence: '15.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP',
      name: 'Front',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/5d3314e7-fe06-4279-91eb-acb7013c5a33/0AN4265__2767_2__STD__shad__fr.png',
      attachmentAssetID: '3074457345616677309',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/5d3314e7-fe06-4279-91eb-acb7013c5a33/0AN4265__2767_2__STD__shad__fr.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_PI20_Lateral_510f53b1-74b9-46dc-938b-acb7013c5c28/0AN4265__2767_2__STD__shad__lt',
      sequence: '17.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP',
      name: 'Lateral',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/510f53b1-74b9-46dc-938b-acb7013c5c28/0AN4265__2767_2__STD__shad__lt.png',
      attachmentAssetID: '3074457345616677310',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/510f53b1-74b9-46dc-938b-acb7013c5c28/0AN4265__2767_2__STD__shad__lt.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_Degree_000_5ab4a478-d45d-482c-a0fa-acc20145be9c/0AN4265__2767_2_000A',
      sequence: '33.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP_360',
      name: 'Degree_000',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/5ab4a478-d45d-482c-a0fa-acc20145be9c/0AN4265__2767_2_000A.png',
      attachmentAssetID: '3074457345616677311',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/5ab4a478-d45d-482c-a0fa-acc20145be9c/0AN4265__2767_2_000A.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_Degree_030_14eeb80b-b9c4-4ac5-89ba-acc20145c079/0AN4265__2767_2_030A',
      sequence: '32.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP_360',
      name: 'Degree_030',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/14eeb80b-b9c4-4ac5-89ba-acc20145c079/0AN4265__2767_2_030A.png',
      attachmentAssetID: '3074457345616677312',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/14eeb80b-b9c4-4ac5-89ba-acc20145c079/0AN4265__2767_2_030A.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_Degree_090_c8bba510-5bf1-42d2-80cb-acc20145c4c0/0AN4265__2767_2_090A',
      sequence: '34.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP_360',
      name: 'Degree_090',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/c8bba510-5bf1-42d2-80cb-acc20145c4c0/0AN4265__2767_2_090A.png',
      attachmentAssetID: '3074457345616677313',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/c8bba510-5bf1-42d2-80cb-acc20145c4c0/0AN4265__2767_2_090A.png',
    },
    {
      image: 'images/attachments/usages/images_icon.jpg',
      identifier:
        '888392564122_Degree_180_ac8f04d1-6256-4961-b72a-acc20145ca0c/0AN4265__2767_2_180A',
      sequence: '35.0',
      catentry_id: '3074457345616698935',
      usage: 'PDP_360',
      name: 'Degree_180',
      shortdesc: '',
      attachmentAssetPathRaw:
        '/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/ac8f04d1-6256-4961-b72a-acc20145ca0c/0AN4265__2767_2_180A.png',
      attachmentAssetID: '3074457345616677314',
      mimeType: '',
      attachmentAssetPath:
        '/dx/api/dam/custom/cdn-record-files-pi/fd916dde-12e2-4c0e-a12a-acb7013c4b36/ac8f04d1-6256-4961-b72a-acc20145ca0c/0AN4265__2767_2_180A.png',
    },
  ],
}

export default mockedData
