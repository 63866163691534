import React from 'react'
import styled from 'styled-components'

import AccordionDetails from '@material-ui/core/AccordionDetails'

const StyledAccordionDetails = styled(({ ...props }) => <AccordionDetails {...props} />)`
  ${({ theme }) => `
  padding: 10px 0 0;
  .header-menu &.MuiAccordionDetails-root{
    display: block;
    padding-top: 0;
  }
`}
`

export default StyledAccordionDetails
