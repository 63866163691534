import * as ACTIONS from '../../action-types/order'

//Standard libraries
import { call, put } from 'redux-saga/effects'

import { ADDING_ITEM_ACTION } from '../../actions/order'
import { CART } from '../../../constants/routes'
import { Cart } from '../../../types/order'
import { HANDLE_SUCCESS_MESSAGE_ACTION } from '../../actions/success'
import Log from '../../../services/Log'
//Custom libraries
import { ORDER_CONFIGS } from '../../../configs/order'
import { ORDER_EXTEND_ATTRIBUTE_NAMES } from '../../../constants/order'
import { OrderActionsPayload } from '../../../types/orderActionsPayload'
import { OrderReducerState } from '../../reducers'
import { OrderRequests } from '../../../types/orderRequests'
//Redux
import { PayloadAction } from '@reduxjs/toolkit'
import { RX_PRESCRIPTION_OBJECT_KEY } from '../../../constants/rxConfigurator'
import { SUCCESS_MSG_PREFIX } from '../../../constants/common'
//Foundation libraries
import cartService from '../../../foundation/apis/transaction/cart.service'
import { fetchOrderItemDetailsByIds } from './orderDetails'
import { getSite } from '../../../foundation/hooks/useSite'
import last from 'lodash/last'
import { localStorageUtil } from '../../../foundation/utils/storageUtil'
import orderService from '../../../foundation/apis/transaction/order.service'
import paymentInstructionService from '../../../foundation/apis/transaction/paymentInstruction.service'
import rxService from '../../../foundation/apis/rx-config/rx.service'
import shippingInfoService from '../../../foundation/apis/transaction/shippingInfo.service'

/**
 * Saga worker to invoke add lens API
 */
export function* addLens(action: PayloadAction<OrderActionsPayload.AddLens>) {
  try {
    yield put(ADDING_ITEM_ACTION({ isAddingItem: true }))
    const payloadParams = action.payload.params
    const addToCartSuccessCallback = action.payload.callback
    const mySite = action.payload.siteInfo
    const cartPayload = {
      contractId: payloadParams.contractId,
    }
    const {orderId,zipCode} = payloadParams

    const savedPrescription = localStorageUtil.get(RX_PRESCRIPTION_OBJECT_KEY)

    const _orderLensExtendAttribute: any[] = []
    const _orderFrameExtendAttribute: any[] = []
    const _orderLensExtendAttributes: any[] = []
    const _orderFrameExtendAttributes: any[] = []
    const _orderLensItems: any[] = []
    const _orderFrameItems: any[] = []
    let catentryIds: string[] = []
    let partnumbers: string[] = []
    let quantities: any[] = []

    const imagesArray = [
      {
        attributeName: 'rxProductImage',
        attributeType: 'String',
        attributeValue: payloadParams?.images?.productImage || '',
      },
      {
        attributeName: 'rxFallbackImage',
        attributeType: 'String',
        attributeValue: payloadParams?.images?.fallbackImage || '',
      },
    ]

    if (payloadParams.partnumber) {
      partnumbers =
        payloadParams.partnumber instanceof Array
          ? payloadParams.partnumber
          : [payloadParams.partnumber]
      quantities =
        payloadParams.quantity instanceof Array ? payloadParams.quantity : [payloadParams.quantity]
    }
    if (payloadParams.catentryId) {
      catentryIds =
        payloadParams.catentryId instanceof Array
          ? payloadParams.catentryId
          : [payloadParams.catentryId]
      quantities =
        payloadParams.quantity instanceof Array ? payloadParams.quantity : [payloadParams.quantity]
    }

    for (const i in catentryIds) {
      _orderLensExtendAttribute.push(
        ...[
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'IsRoxLens',
            attributeType: 'String',
            attributeValue: 'true',
          },
          ...imagesArray,
        ]
      )
      _orderLensItems[i] = {
        quantity: quantities[i].toString(),
        productId: catentryIds[i],
        contractId: payloadParams.contractId,
        orderItemExtendAttribute: _orderLensExtendAttribute,
      }
      _orderLensExtendAttributes[i] = {
        attributeName: 'LanguageId',
        attributeType: 'string',
        attributeValue: payloadParams.langId,
      }
    }

    const lensPayload = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderLensItems,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderExtendAttribute: _orderLensExtendAttributes,
      } as OrderRequests.AddLensPayload,
      widget: '',
    }

    if (payloadParams.widget) {
      lensPayload['widget'] = payloadParams.widget
      cartPayload['widget'] = payloadParams.widget
    }

    const responseLens: OrderRequests.AddLensResponse = yield call(
      cartService.addOrderItem,
      lensPayload
    )

    const orderItemId = last(responseLens.orderItem)!.orderItemId

    for (const i in partnumbers) {
      if (orderItemId) {
        _orderFrameExtendAttribute.push(
          ...[
            {
              attributeName: 'IsRox',
              attributeType: 'String',
              attributeValue: 'true',
            },
            {
              attributeName: 'RxLensId',
              attributeType: 'String',
              attributeValue: orderItemId,
            },
          ]
        )
      }
      _orderFrameItems[i] = {
        quantity: quantities[i].toString(),
        partNumber: partnumbers[i],
        contractId: payloadParams.contractId,
        orderItemExtendAttribute: orderItemId ? _orderFrameExtendAttribute : undefined,
      }
      _orderFrameExtendAttributes[i] = {
        attributeName: 'LanguageId',
        attributeType: 'string',
        attributeValue: payloadParams.langId,
      }
    }

    const framePayload: { body: OrderRequests.AddLensPayload; widget: string } = {
      body: {
        orderExtendAttribute: _orderFrameExtendAttributes,
        orderId: '.',
        orderItem: _orderFrameItems,
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
      },
      widget: '',
    }

    if (payloadParams.widget) {
      framePayload['widget'] = payloadParams.widget
      cartPayload['widget'] = payloadParams.widget
    }

    yield call(cartService.addOrderItem, framePayload)

    if (!!zipCode && !!orderId) {
      yield call(
        cartService.estimateTaxes,
        {
          orderId: orderId,
          zipCode: zipCode || ''
        }
      )
    }

    const cartAction = { ...action, payload: cartPayload }
    yield call(fetchCart, cartAction)

    const successMessage = {
      key: SUCCESS_MSG_PREFIX + ACTIONS.ITEM_ADD_SUCCESS,
      link: {
        url: CART,
        textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
      },
    }
    yield put(HANDLE_SUCCESS_MESSAGE_ACTION(successMessage))

    if (savedPrescription) {
      if (savedPrescription?.prescriptionFlow === 'MANUAL') {
        const prescriptionObject = {
          orderId: responseLens.orderId,
          orderItemId,
          prescription: {
            firstName: '',
            lastName: '',
            nickName: '',
            telephone: '',
            dateOfBirth: '',
            prescriptionState: '',
            productType: '',
            pupillaryDistance: savedPrescription?.PD?.OD ? String(savedPrescription.PD.OD): '63',
            rightSphere: savedPrescription?.SPH?.OD ? String(savedPrescription.SPH.OD) : '0.00',
            rightAdd: savedPrescription?.ADD?.OD ? String(savedPrescription.ADD.OD) : '',
            rightAxis: savedPrescription?.AX?.OD ? String(savedPrescription.AX.OD) : '0',
            rightCyl: savedPrescription?.CYL?.OD ? String(savedPrescription.CYL.OD) : '0.00',
            leftSphere: savedPrescription?.SPH?.OS ? String(savedPrescription.SPH.OS) : '0.00',
            leftAdd: savedPrescription?.ADD?.OS ? String(savedPrescription.ADD.OS) : '',
            leftAxis: savedPrescription?.AX?.OS ? String(savedPrescription.AX.OS) : '0',
            leftCyl: savedPrescription?.CYL?.OS ? String(savedPrescription.CYL.OS) : '0.00',
            lPupDistance: savedPrescription?.PD?.OS ? String(savedPrescription.PD.OS) : '31',
            rPupDistance: savedPrescription?.PD?.OD ? String(savedPrescription.PD.OD) : '31',
          },
        }

        const responsePrescription = yield call(
          rxService.addPrescriptionDetails,
          mySite,
          prescriptionObject
        )
        if (responsePrescription.data?.successCode !== '200') {
          throw new Error(responsePrescription.data?.successMessage)
        }
      }
      if (
        savedPrescription?.prescriptionFlow === 'UPLOAD' ||
        savedPrescription?.prescriptionFlow === 'NESTED_CA'
      ) {
        const prescriptionObject = {
          orderId: responseLens.orderId,
          orderItemId,
          rxFileStorageId: savedPrescription?.savedFileName,
        }

        const responsePrescription = yield call(
          rxService.linkRXFileWithOrder,
          mySite,
          prescriptionObject
        )
        if (responsePrescription.data?.successCode !== '200') {
          throw new Error(responsePrescription.data?.successMessage)
        }
      }
      localStorageUtil.remove(RX_PRESCRIPTION_OBJECT_KEY)
    }

    if (addToCartSuccessCallback && typeof addToCartSuccessCallback === 'function') {
      yield call(addToCartSuccessCallback)
    }
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_ADD_ERROR, error })
  } finally {
    yield put(ADDING_ITEM_ACTION({ isAddingItem: false }))
  }
}

/**
 * Saga worker to invoke add item API
 */
export function* addItem(action: PayloadAction<OrderActionsPayload.AddItem>) {
  try {
    yield put(ADDING_ITEM_ACTION({ isAddingItem: true }))

    const payload = action.payload.params
    const addToCartSuccessCallback = action.payload.callback
    const {orderId,zipCode} = payload
    const cartPayload = {
      contractId: payload.contractId,
    }

    const _orderExtendAttributes: any[] = []
    const _orderItemExtendAttribute: any[] = []
    const _orderItems: any[] = []
    let catentryIds: string[] = []
    let partnumbers: string[] = []
    let quantities: any[] = []

    if (payload.partnumber) {
      partnumbers = payload.partnumber instanceof Array ? payload.partnumber : [payload.partnumber]
      quantities = payload.quantity instanceof Array ? payload.quantity : [payload.quantity]
    } else if (payload.catentryId) {
      catentryIds = payload.catentryId instanceof Array ? payload.catentryId : [payload.catentryId]
      quantities = payload.quantity instanceof Array ? payload.quantity : [payload.quantity]
    }

    for (const i in partnumbers) {
      if (payload.lensId) {
        _orderItemExtendAttribute.push(
          ...[
            {
              attributeName: 'IsRox',
              attributeType: 'String',
              attributeValue: 'true',
            },
            {
              attributeName: 'RxLensId',
              attributeType: 'String',
              attributeValue: payload.lensId,
            },
          ]
        )
      }
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        partNumber: partnumbers[i],
        contractId: payload.contractId,
        orderItemExtendAttribute: payload.lensId ? _orderItemExtendAttribute : undefined,
      }
      _orderExtendAttributes[i] = {
        attributeName: ORDER_EXTEND_ATTRIBUTE_NAMES.LANG_ID,
        attributeType: 'string',
        attributeValue: payload.langId,
      }
    }
    for (const i in catentryIds) {
      _orderItemExtendAttribute.push(
        ...[
          {
            attributeName: 'IsRox',
            attributeType: 'String',
            attributeValue: 'true',
          },
          {
            attributeName: 'IsRoxLens',
            attributeType: 'String',
            attributeValue: 'true',
          },
        ]
      )
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        productId: catentryIds[i],
        contractId: payload.contractId,
        orderItemExtendAttribute: _orderItemExtendAttribute,
      }
      _orderExtendAttributes[i] = {
        attributeName: ORDER_EXTEND_ATTRIBUTE_NAMES.LANG_ID,
        attributeType: 'string',
        attributeValue: payload.langId,
      }
    }
    let body = {
      body: {
        orderId: '.',
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        orderItem: _orderItems,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderExtendAttribute: _orderExtendAttributes,
      },
    }
    if (payload.widget) {
      body['widget'] = payload.widget
      cartPayload['widget'] = payload.widget
    }

    yield call(cartService.addOrderItem, body)
    if (!!zipCode && !!orderId) {
      yield call(
        cartService.estimateTaxes,
        {
          orderId: orderId,
          zipCode: zipCode || ''
        }
      )
    }
    const cartAction = {
      ...action,
      payload: cartPayload,
    }
    yield call(fetchCart, cartAction)

    const successMessage = {
      key: SUCCESS_MSG_PREFIX + ACTIONS.ITEM_ADD_SUCCESS,
      link: {
        url: CART,
        textKey: SUCCESS_MSG_PREFIX + 'ViewCart',
      },
    }

    yield put(HANDLE_SUCCESS_MESSAGE_ACTION(successMessage))

    if (addToCartSuccessCallback && typeof addToCartSuccessCallback === 'function') {
      yield call(addToCartSuccessCallback)
    }
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_ADD_ERROR, error })
  } finally {
    yield put(ADDING_ITEM_ACTION({ isAddingItem: false }))
  }
}

/**
 * Saga worker to invoke remove item API
 */
export function* removeItem(
  action: PayloadAction<
    Omit<OrderActionsPayload.DeleteOrderItem, 'x_calculateOrder' | 'x_calculationUsage'>
  >
) {
  try {
    const { orderItemIds, orderId, zipCode } = action.payload

    const requestPayload = {
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      x_calculationUsage: ORDER_CONFIGS.calculationUsage,
    }

    if (orderItemIds.length === 1) {
      requestPayload.orderItemId = orderItemIds[0]
    } else if (orderItemIds.length > 1) {
      for (let i = 0; i < orderItemIds.length; i++) {
        requestPayload[`orderItemId_${i + 1}`] = orderItemIds[i]
      }
    }

    yield call(cartService.deleteOrderItem, requestPayload)
    if (!!zipCode && !!orderId) {
      yield call(
        cartService.estimateTaxes,
        {
          orderId: orderId,
          zipCode: zipCode || ''
        }
      )
    }
  } catch (error) { }
}

/**
 * Saga worker to invoke update item API
 */
export function* updateItem(action: any) {
  try {
    const payload = action.payload
    const orderItemId = payload.orderItemId
    const quantity = payload.quantity
    const body: { body: OrderRequests.UpdateItemPayload; widget: string } = {
      body: {
        x_calculateOrder: ORDER_CONFIGS.calculateOrder,
        x_calculationUsage: ORDER_CONFIGS.calculationUsage,
        x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        orderItem: [
          {
            quantity: quantity,
            orderItemId: orderItemId,
          },
        ],
      },
      widget: '',
    }
    if (payload.widget) {
      body['widget'] = payload.widget
    }

    const response = yield call(cartService.updateOrderItem, body)
    yield put({ type: ACTIONS.ITEM_UPDATE_SUCCESS, response, payload })
  } catch (error) {
    yield put({ type: ACTIONS.ITEM_UPDATE_ERROR, error })
  }
}

export function* initFromStorageFetchCart(action: any) {
  const { WCToken } = action.payload || {}
  const storeId = getSite()?.storeID

  if (!!WCToken) {
    yield* fetchCart({
      ...action,
      payload: { ...action.payload, storeId: storeId || action.payload.storeId },
    })
  }
}

/**
 * Saga worker to invoke get cart API
 */
export function* fetchCart(action: PayloadAction<OrderActionsPayload.FetchCart>) {
  try {
    const payload = action.payload
    const parameters: OrderActionsPayload.FetchCart = {
      ...payload,
      sortOrderItemBy: ORDER_CONFIGS.sortOrderItemBy,
    }
    const checkInventory: boolean = payload.checkInventory ? payload.checkInventory : false

    if (payload.widget) {
      parameters['widget'] = payload.widget
    }

    const responseCart: Cart = yield call([cartService, cartService.getCart], parameters)

    let catentries: OrderReducerState['catentries'] = null
    if (responseCart) {
      const orderItems = responseCart.orderItem

      if (orderItems && orderItems.length > 0) {
        let catentryIdList: string[] = []

        //get product info for all items
        orderItems.forEach((item: any) => {
          catentryIdList.push(item.productId)
        })

        if (catentryIdList.length > 0) {
          catentryIdList = [...new Set(catentryIdList)]

          const currency = parameters ? parameters.currency : ''
          const contractId = parameters ? parameters.contractId : ''
          const paramsProduct = {
            currency: currency,
            contractId: contractId,
            id: catentryIdList,
          }
          if (parameters?.cancelToken) {
            paramsProduct['cancelToken'] = parameters.cancelToken
          }
          if (parameters?.widget) {
            paramsProduct['widget'] = parameters.widget
          }

          try {
            const contents = yield call(fetchOrderItemDetailsByIds, paramsProduct)
            if (contents) {
              catentries = contents.reduce((acc, p) => {
                acc[p.id] = p
                return acc
              }, {})
            }
          } catch (error) {
            Log.error('Could not retrieve products')
            //Cannot retrieve catentry details; return order items as-is
            catentries = null
          }

      }
    }
      // if(estimateTaxes) {
      //   yield call(
      //     cartService.estimateTaxes,
      //     {
      //       orderId: responseCart.orderId,
      //       zipCode: zipCode || ''
      //     }
      //   )
      // }
    }
    //yield call([cartService, cartService.getCart], parameters)
    if (catentries) {
      yield put({
        type: ACTIONS.CART_GET_SUCCESS,
        response: responseCart,
        catentries,
        checkInventory,
      })
    } else {
      yield put({
        type: ACTIONS.CART_GET_SUCCESS,
        response: responseCart,
        checkInventory: checkInventory,
      })
    }

    if (payload.callback) {
      payload.callback()
    }
  } catch (error) {
    yield put({
      type: ACTIONS.CART_GET_ERROR,
      error,
    })
  }
}

/**
 * Saga worker to invoke get usable ship info API
 */
export function* fetchShipInfo(action: PayloadAction<OrderActionsPayload.GetShipInfo>) {
  try {
    const payload = action.payload
    const response = yield call(cartService.getUsableShippingInfo, payload)

    yield put({ type: ACTIONS.SHIPINFO_GET_SUCCESS, response })
  } catch (error) {
    yield put({ type: ACTIONS.SHIPINFO_GET_ERROR, error })
  }
}

/**
 * Saga worker to invoke update ship mode API
 */
export function* updateShipMode(action: any) {
  const payload = action.payload
  const body = {
    body: {
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      x_allocate: ORDER_CONFIGS.allocate,
      x_backorder: ORDER_CONFIGS.backOrder,
      x_remerge: ORDER_CONFIGS.remerge,
      x_check: ORDER_CONFIGS.check,
      orderId: payload.orderId,
      shipModeId: payload.shipModeId,
      //addressId: payload.shipAddressId,
      orderItem: [], //bypass defect HC-2784
    },
  }
  if (payload.widget) {
    body['widget'] = payload.widget
  }
  const response = yield call(shippingInfoService.updateOrderShippingInfo, body)
  yield put({
    type: ACTIONS.SHIPMODE_UPDATE_SUCCESS,
    response: response.data,
  })
}

/**
 * Saga worker to invoke get usable payment methods API
 */
export function* fetchPayMethods(action) {
  try {
    const payload = action.payload
    const response = yield call(cartService.getUsablePaymentInfo, payload)

    yield put({ type: ACTIONS.PAYMETHODS_GET_SUCCESS, response })
  } catch (error) {
    yield put({ type: ACTIONS.PAYMETHODS_GET_ERROR, error })
  }
}

/**
 * Saga worker to invoke get Paypal session info
 */
export function* fetchPayPalInfo(action: any) {
  try {
    const payload = action.payload
    const response = yield call(paymentInstructionService.getPaypalPaymentInfo, payload)
    yield put({
      type: ACTIONS.PAYPALEXPRESS_GET_SUCCESS,
      response: response.data,
    })
  } catch (error) {
    yield put({ type: ACTIONS.PAYPALEXPRESS_GET_ERROR, error })
  }
}

export function* fetchPayPalExpressStatus(action: any) {
  try {
    const payload = action.payload
    const response = yield call(orderService.paypalExpressCheckStatus, payload)
    yield put({
      type: ACTIONS.PAYPALEXPRESS_CHECK_STATUS_GET_SUCCESS,
      response: response.data,
    })
  } catch (error) {
    yield put({ type: ACTIONS.PAYPALEXPRESS_CHECK_STATUS_GET_ERROR, error })
  }
}

export function* estimateTaxes(action: PayloadAction<{
  orderId: string,
  zipCode: string
}>) {
  try {
    const payload = action.payload
    const { orderId, zipCode } = payload
    yield call(cartService.estimateTaxes, { orderId, zipCode })
    yield put({
      type: ACTIONS.TAXES_CALCULATION_GET_SUCCESS,
      response: payload,
    })
  } catch (error) {
    yield put({ type: ACTIONS.TAXES_CALCULATION_GET_ERROR, error })
  }
}