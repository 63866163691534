import CMSIcon from '../widgets/cms-content/cms-icon'
import { ILXTeaser } from '../../types/teaser'
import React from 'react'
import { StyledAnchor } from '../StyledUI'
import styled from 'styled-components'
import theme from '../../themes'
interface IProps {
  data: ILXTeaser[]
}

const CustomStyledAnchor = styled(StyledAnchor)`
  column-gap: 8px;
  color: ${({ theme }) => theme.palette.custom.black};
`

const StyledBenefitBar = styled('div')`
  max-width: 100vw;
  margin: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  background-color: ${({ theme }) => theme.palette.custom.warmGrey};

  scrollbar-width: none;
  -ms-overflow-style: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    overflow-x: scroll;
    overflow-y: hidden;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    position: relative;
    height: 24px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledBenefitList = styled('ul')`
  margin: 0;
  padding: 0 213px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  list-style-type: none;
  white-space: nowrap;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    position: absolute;
    padding: 0;
    height: 24px;
 
  }
`

const StyledBenefitListElement = styled('li')`
  flex: 0 0 auto;
  color: ${({ theme }) => theme.palette.custom.black};
  line-height: 1.5rem;
  text-align: center;
  font-size: 12px;
  padding: 0 12px;
  overflow: auto;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }

  p {
    margin: 0;
    line-height: 1.5;
  }

  div {
    display: flex;
    column-gap: 4px;
  }

  ${theme.breakpoints.up('sm')} {
    font-size: 16px;

    a {
      font-size: 16px;
    }
  }

  ${theme.breakpoints.up('md')} {
    padding: 0 40px;
    div {
      display: flex;
      column-gap: 4px;
    }
  }
`

const BenefitBar: React.FC<IProps> = ({ data }) => {
  return data && (
    <StyledBenefitBar className="arn-header__benefit-bar">
      <StyledBenefitList data-analytics_available_call="0">
        {data &&
          data.map((item) => (
            <StyledBenefitListElement key={`benefit_bar_item_${item.id}`}>
              <div>
                <CMSIcon teaserIcon={item.teaserIcon} />
                <CustomStyledAnchor
                  to={
                    item.teaserLXCallToActionSettings &&
                      Array.isArray(item.teaserLXCallToActionSettings) &&
                      item.teaserLXCallToActionSettings.length > 0 &&
                      item.teaserLXCallToActionSettings[0] &&
                      item.teaserLXCallToActionSettings[0].target
                      ? item.teaserLXCallToActionSettings[0].target.formattedUrl
                      : ''
                  }
                  data-element-id={'X_X_Strip_Benefits'}
                >
                  <div dangerouslySetInnerHTML={{ __html: item.teaserText2 }} />
                </CustomStyledAnchor>
              </div>
            </StyledBenefitListElement>
          ))}
      </StyledBenefitList>
    </StyledBenefitBar>
  )
}

export default BenefitBar
