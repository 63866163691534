import axios from 'axios'
import { ProductBase } from '../../../types/product'
import { getIsRoxable } from '../../../utils/productAttributes'
import { SiteInfo } from '../../../redux/reducers/reducerStateInterface'
import { RX_CONFIGURATOR_DOM_ID } from '../../../constants/rxConfigurator'
import {
  LensDataResponse,
  PrescriptionDetailsBody,
  RXFileLink,
} from '../../../types/rxConfigurator'

class rxService {
  /**
   *
   * @param siteInfo
   * @param id product id
   * @returns lenseData to be merged with the config object of rx Configurator widget.
   */
  getLensesData(siteInfo: SiteInfo, id: string | null) {
    const { storeID, transactionContext } = siteInfo

    return axios.get<LensDataResponse>(
      `${transactionContext}/store/${storeID}/rox/lensesData/${id}`
    )
  }

  async uploadPrescriptionFile(siteInfo: SiteInfo, formData: FormData) {
    const { storeID, transactionContext } = siteInfo

    return await axios.post(
      `${transactionContext}/store/${storeID}/prescription/uploadFile`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }

  async addPrescriptionDetails(siteInfo: SiteInfo, body: PrescriptionDetailsBody) {
    const { storeID, transactionContext } = siteInfo

    return await axios.post(
      `${transactionContext}/store/${storeID}/prescription/prescriptionDetails`,
      body
    )
  }

  async uploadFileFromConfigurator(siteInfo: SiteInfo, formData: FormData) {
    const { storeID, transactionContext } = siteInfo

    return await axios.post(
      `${transactionContext}/store/${storeID}/prescription/storeRXFile`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }

  async downloadPrescriptionFile(siteInfo: SiteInfo, rxFileStorageId: string) {
    const { storeID, transactionContext } = siteInfo

    return await axios.get(
      `${transactionContext}/store/${storeID}/prescription/rxFileStorageId/${rxFileStorageId}`,
      { responseType: 'arraybuffer' }
    )
  }

  async linkRXFileWithOrder(siteInfo: SiteInfo, body: RXFileLink) {
    const { storeID, transactionContext } = siteInfo

    return await axios.post(
      `${transactionContext}/store/${storeID}/prescription/uploadRXFile`,
      body
    )
  }

  isProductRoxable(isRXEnabled: boolean, product: ProductBase): boolean {
    return isRXEnabled && getIsRoxable(product)
  }

  closeConfigurator() {
    if (window.RXC) {
      window.RXC.rxcWidget.close(`#${RX_CONFIGURATOR_DOM_ID}`)
    }

    document.querySelectorAll('html, body').forEach((e) => {
      (e as HTMLElement).style.removeProperty('overflow')
      e.classList.remove('RXC__is-locked')
    })
  }
}

export default new rxService()
