import { Order, OrderItemsAttachments } from '../types/order'
import ProductPrice, {
  PRODUCT_PRICE_SIZES,
} from '../components/pages/product/components/ProductPrice'
import { StyledA, StyledTypography } from '../components/StyledUI'

import {ACCOUNT_CHILDREN, buildAccountPageRoute} from './routes'
import DateService from '../services/DateService'
import Flexbox from '../components/StyledUI/Flexbox/Flexbox'
import { OrderReducerState } from '../redux/reducers'
import React from 'react'
import { TFunction } from 'i18next'
import { TableCell } from '../components/StyledUI/StyledTable/Table'
import theme from '../themes'
import orderService from '../foundation/apis/transaction/order.service'
import { CountryBaseLink } from '../components/StyledUI/StyledLink/StyledLink'

export const INVENTORY_STATUS = {
  available: 'Available',
  allocated: 'Allocated',
  backordered: 'Backordered',
  unallocated: 'Unallocated',
}

export const RECURRING_ORDER_OPTIONS = [
  {
    key: 'EVERYDAY',
    value: '0',
    fulfillmentInterval: '1',
    fulfillmentIntervalUOM: 'DAY',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.Everyday',
  },
  {
    key: 'EVERYWEEK',
    value: '1',
    fulfillmentInterval: '1',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryWeek',
  },
  {
    key: 'EVERYTWOWEEKS',
    value: '2',
    fulfillmentInterval: '2',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryTwoWeeks',
  },
  {
    key: 'EVERYTHREEWEEKS',
    value: '3',
    fulfillmentInterval: '3',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryThreeWeeks',
  },
  {
    key: 'EVERYFOURWEEKS',
    value: '4',
    fulfillmentInterval: '4',
    fulfillmentIntervalUOM: 'WEE',
    translationKey: 'CommerceEnvironment.recurringOrderFrequency.EveryFourWeeks',
  },
]

export const SHIPMODE = {
  shipModeCode: {
    PickUp: 'PickupInStore',
  },
}

export const EXPIRY = {
  MONTHS: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  YEARS: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
}

export const PO_NUMBER = 'poNumber'
export const TAXES_ZIP_CODE = 'TAXES_ZIP_CODE'
export const ESTIMATED_TAXES = 'ESTIMATED_TAXES'
export const TAX_CALCULATED = 'TAX_CALCULATED'

export const ORDER_STATUS = {
  Created: 'M',
  Late: 'L',
  Error: 'H',
  Settled: 'D',
  Confirmed: 'R',
  Cancelled: 'X',
  Delivered: '1',
  PendingPrescription: 'E',
}

const ORDER_RETURN_STATUS = {
  ReturnCreated: 'APP',
  ReturnReceived: 'CRE',
  ReturnComplete: 'CLO',
  ReturnCancelled: 'CAN',
  ReturnPending: 'PND',
  ReturnDelivered: 'SNT',
}

export const ORDER_ITEM_STATUS = {
  Delivered: '1',
  Billed_Shipped: 'D',
  SAPSent_Created: 'G',
  Created_F: 'F',
  Error_Created: 'H',
  Late_InProcess: 'L',
  Created: 'M',
  Pending: 'P',
  Confirmed_InProcess: 'R',
  InTransit_Shipped: 'S',
  ReturnDelivered: 'SNT',
  Cancelled: 'X',
  FailedSettlement_Shipped: 'Z',
}

const RMA_ORDER_STATUS_COLOR = theme.palette.secondary.greyLightmode

export const ORDER_EXTEND_ATTRIBUTE_NAMES = {
  INVOICE_PROCESSED: 'INVOICE_PROCESSED',
  INVOICE_URLS: 'xinvoiceUrls',
  IS_MIX_ORDER: 'IS_MIX_ORDER', // 'true' | 'false'
  IS_ROX_ORDER: 'IS_ROX_ORDER', // 'true' | 'false'
  LANG_ID: 'LanguageId',
  RMA_ITEMS_COMPLETED: 'numberOfRmaItemsCompleted',
  RMA_ITEMS_IN_PROGRESS: 'numberOfRmaItemsInProgress',
  RMA_STATUS: 'RMAStatus',
  TAX_SETTLEMENT: 'TaxSettlement',
  TAX_SYSTEM: 'TaxSystem',
  TRACK_NUMBER: 'track_number',
} as const

export const ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES = {
  RMA_STATUS: 'RMA',
} as const

const orderItemExtendAttributeNameGetter = (orderId: string, name: string) => `${orderId}:${name}`

export const ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS = {
  TRACK_NUMBER: (orderId: string) => orderItemExtendAttributeNameGetter(orderId, 'TrackNumber'),
  FULFILLMENT_STATUS: (orderId: string) =>
    orderItemExtendAttributeNameGetter(orderId, 'FulfillmentStatus'),
}

export const NO_CCARD_INFO_NEEDED_PAYMENTS: string[] = ['paypal', 'applepay', 'cod', 'cybersourceklarna']

export const getOrderItemsAttachments = (
  orderItems: OrderReducerState['orderItems'],
  catentries: OrderReducerState['catentries']
) => {
  if (!Array.isArray(orderItems) || !catentries) {
    return {}
  }

  const orderItemsImages = {} as OrderItemsAttachments

  for (const orderItem of orderItems) {
    const { productId } = orderItem
    const attachments = catentries[productId]?.attachments

    orderItemsImages[productId] = attachments || []
  }

  return orderItemsImages
}

export const buildOrderHistoryHeaders = (t: TFunction): string[] => [
  t('Order.OrderNumber'),
  t('Order.OrderDate'),
  t('Order.Status'),
  t('Order.TrackingNumber'),
  t('Order.TotalPrice'),
  t('Order.Actions'),
]

export const buildOrderHistoryCells = ({
  isDesktop,
  t,
}: {
  isDesktop: boolean
  langCode: string
  t: TFunction
}): TableCell<Order>[] => [
  {
    key: 'orderId',
    widthPercent: 19,
    render: ({ orderId }) => (
      <Flexbox
        align-items="flex-start"
        justify-content={isDesktop ? 'initial' : 'space-between'}
        gap="10px"
        flex="1">
        <span>{orderId}</span>
        <CountryBaseLink
          data-element-id="X_X_OrderHistory_ViewDetails"
          fontSize="0.75rem"
          variant="primaryUnderlined"
          to={buildAccountPageRoute(ACCOUNT_CHILDREN.ORDER_HISTORY, { orderId })}>
          {t('Order.HistoryViewDetailTooltip')}
        </CountryBaseLink>
      </Flexbox>
    ),
  },
  {
    key: 'placedDate',
    widthPercent: 18,
    render: ({ placedDate }) => <span>{DateService.format(placedDate || '')}</span>,
  },
  {
    key: 'orderStatus',
    widthPercent: 13,
    render: ({ orderExtendAttribute = [], orderStatus }) => {
      if (!(orderStatus && typeof orderStatus === 'string')) {
        return ''
      }

      const orderReturnStatus = orderExtendAttribute.find((a) =>
        a.attributeName.startsWith(ORDER_EXTEND_ATTRIBUTE_NAMES.RMA_STATUS)
      )?.attributeValue
      const isOrderReturnStatusUsable = Object.values(ORDER_RETURN_STATUS).includes(
        orderReturnStatus || ''
      )
      let itemsRequestedForReturn: number = 0
      let itemsReturned: number = 0

      if (isOrderReturnStatusUsable) {
        itemsRequestedForReturn =
          Number(
            orderExtendAttribute.find(
              (a) => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.RMA_ITEMS_IN_PROGRESS
            )?.attributeValue
          ) || 0
        itemsReturned =
          Number(
            orderExtendAttribute.find(
              (a) => a.attributeName === ORDER_EXTEND_ATTRIBUTE_NAMES.RMA_ITEMS_COMPLETED
            )?.attributeValue
          ) || 0
      }

      // order status text should be hidden on mobile if there are items returned/for return
      const isOrderStatusHidden =
        !isDesktop && isOrderReturnStatusUsable && (itemsRequestedForReturn || itemsReturned)

      return (
        <Flexbox flex-direction={isDesktop ? 'column' : 'row'}>
          {!isOrderStatusHidden && (
            <StyledTypography
              variant="caption"
              fontColor={orderStatus === 'E' ? '#9b0800' : undefined}>
              {t(`Order.Status_.${orderStatus}`)}
            </StyledTypography>
          )}
          {!!itemsReturned && (
            <StyledTypography fontColor={RMA_ORDER_STATUS_COLOR} variant="caption">
              {t(
                orderReturnStatus === ORDER_RETURN_STATUS.ReturnCancelled
                  ? 'Order.ItemsReturnCancelled'
                  : 'Order.ItemsReturned',
                { count: itemsReturned }
              )}
            </StyledTypography>
          )}
          {!!itemsRequestedForReturn && (
            <StyledTypography fontColor={RMA_ORDER_STATUS_COLOR} variant="caption">
              {!isDesktop && !!itemsReturned && ', '}
              {t('Order.ItemsRequestedForReturn', { count: itemsRequestedForReturn })}
            </StyledTypography>
          )}
        </Flexbox>
      )
    },
  },
  {
    key: 'x_trackingIds',
    widthPercent: 23,
    render: ({ orderExtendAttribute = [] }) => {
      const trackingNumbers = orderService.getTrackingIds(orderExtendAttribute)

      return trackingNumbers?.length ? (
        <Flexbox flex-direction="column">
          {trackingNumbers.map((trackingNumber) => (
            <StyledTypography component="p" lineHeight="18px" margin="0" variant="caption">
              {trackingNumber}
            </StyledTypography>
          ))}
        </Flexbox>
      ) : null
    },
  },
  {
    key: 'grandTotal',
    widthPercent: 18,
    render: ({ grandTotal, grandTotalCurrency }) => (
      <ProductPrice
        x_prices={{
          offer: {
            currency: grandTotalCurrency,
            price: grandTotal,
            startDate: new Date().toString(),
          },
        }}
        sizes={{
          current: PRODUCT_PRICE_SIZES.sm,
        }}
      />
    )
  },
  {
    key: 'action',
    widthPercent: 9,
    render: ({ orderExtendAttribute = [] }) => {
      const invoiceUrl = orderService.getInvoiceUrl(orderExtendAttribute)

      return !!invoiceUrl ? (
        <StyledA href={invoiceUrl} fontSize="0.75rem" target="_blank" variant="primaryUnderlined">
          {t('OrderDetails.Actions.Invoice')}
        </StyledA>
      ) : null
    },
  },
]

export const PRODUCT_COUNT_IN_CART_LIMIT = 10

export const RX_PRODUCTS_IN_CART_LIMIT = 5

export const ABANDONED_ORDER_ID_KEY = 'abandonedOrderId' as const
