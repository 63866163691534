import styled from 'styled-components'

export const StyledProductsCount = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`

export const StyledLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledProductGridContainer = styled.div`
  ${({ theme }) => `
  margin-bottom: ${theme.spacing(1.5)}px;
  padding: 1px 0;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1px;

  ${theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`}
`

export const StyledGridProductLayout = styled('div')`
  box-shadow: ${({ theme }) => `0 0 0 1px ${theme.palette.custom.black}`};
  aspect-ratio: initial;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: ${({ theme }) => theme.palette.custom.warmGrey};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    min-height: 358px;
  }
`

export const StyledProductWrap = styled.div`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;

    /* for older browser's versions that don't supprt aspetc-ratio */
    @supports not (aspect-ratio: 1 / 1) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`
