class CurrencyService {
  static SYMBOLS = {
    EUR: '€',
    GBP: '£',
    USD: '$',
    CAD: '$'
  } as const

  getSymbolByName(currencyName: string): string {
    return CurrencyService.SYMBOLS[currencyName] || ''
  }
}

export default new CurrencyService()