/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from 'react'
import styled from 'styled-components'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

const StyledListItemAvatar = styled(({ ...props }) => <ListItemAvatar {...props} />)`
  && {
  }
`

export default StyledListItemAvatar
