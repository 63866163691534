import * as React from 'react'
import styled, { css } from 'styled-components'
import { CustomSelectProps } from '../../../types/inputFields'
import Select from '@material-ui/core/Select'
import { ArrowDownSlimIcon } from '../Icons'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { shallowEqual, useSelector } from 'react-redux'
import { dimensions } from '../../../themes/variables'

export const StyledSelectWrapper = styled.div<CustomSelectProps>`
  ${({ theme, themeMode }) => `
  position: relative;

  &.arn-select--disabled {
    label {
      color: ${theme.palette.secondary.greyLightmode};
    }
  }

  & > * {
    width: 100%;
  }

  label {
    font-size: 12px;
    color: ${theme.palette.secondary[themeMode === 'dark' ? 'greyDarkmode' : 'main']};

    &.Mui-focused {
      color: ${theme.palette.secondary[themeMode === 'dark' ? 'greyDarkmode' : 'main']};
      transform: translate(16px, -6px) scale(1);
    }

    &[data-shrink='true'] {
      transform: translate(16px, -6px) scale(1);
    }
   
    &.Mui-error {
      color: ${theme.palette.text.error};
    }
  }

  .arn-select-error {
    color: ${theme.palette.text.error};
    margin-left:0;
  }
`}
`
export const SelectStyle = css<CustomSelectProps>`
  ${({ theme, themeMode }) => `
  border-radius: 0;
  width: 100%;
  height: ${dimensions.inputFields.height}px;
  line-height: ${dimensions.inputFields.height}px;
  color: ${themeMode === 'dark' ? theme.palette.custom.warmGrey : theme.palette.custom.black};

  & > select,
  & .MuiSelect-select:not([multiple]) {
    font-size: 12px;
    height: 50px;
    padding-bottom: 0;
    padding-top: 0;
    &:focus {
      background:none;
    }

    option {
      background-color: ${theme.palette.background.default};
      color: ${theme.palette.text.primary};
    }
  }

  & > fieldset {
    border: 1px solid ${theme.palette.secondary[themeMode === 'dark' ? 'greyDarkmode' : 'main']};
    border-width: 1px;
  }

  &.Mui-focused {
    fieldset {
      border-width: 1px !important;
      border-color: ${
        theme.palette.secondary[themeMode === 'dark' ? 'greyDarkmode' : 'main']
      } !important;
    }
  }

  &.Mui-error {
    fieldset {
      border-color: ${theme.palette.text.error} !important ;
    }
  }

  svg {
    top: 50%;
    transform: translateY(-50%);
    color: ${theme.palette.custom.black};
  }

  &.Mui-disabled {
    color: ${
      themeMode === 'dark' ? theme.palette.custom.greyDarkmode : theme.palette.custom.greyLightmode
    } !important;

    fieldset {
      border-color: ${
        themeMode === 'dark'
          ? theme.palette.custom.greyDarkmode
          : theme.palette.custom.greyLightmode
      } !important;
      border-width: 1px !important;
    }

    svg {
      opacity: 0.4;
    }
  }
  select {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      background-clip: content-box !important;
      -webkit-box-shadow: 0 0 0 30px
        ${themeMode === 'light' ? theme.palette.custom.warmGrey : theme.palette.custom.black}
        inset;
      box-shadow: 0 0 0 30px
        ${themeMode === 'light' ? theme.palette.custom.warmGrey : theme.palette.custom.black}
        inset;
        -webkit-text-fill-color:${
          themeMode === 'dark' ? theme.palette.secondary.greyDarkmode : theme.palette.secondary.main
        };
    }
  }
`}
`
const SelectdBase = styled(Select)<CustomSelectProps>`
  ${SelectStyle}
`
export const InputStyle = css<CustomSelectProps & { themeMode: string }>`
  ${({ theme, themeMode }) => `
    color: ${theme.palette.custom[themeMode === 'dark' ? 'greyDarkmode' : 'greyLightmode']};
`}
`

const InputBase = styled(InputLabel)<CustomSelectProps & { themeMode: string }>`
  ${InputStyle}
`

export const StyledSelect = ({
  required,
  error,
  ispristine,
  inputProps,
  errorMessage,
  label,
  ...props
}: CustomSelectProps) => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)

  return (
    <StyledSelectWrapper
      className={'arn-select' + (props.disabled ? ' arn-select--disabled' : '')}
      error={error}
      ispristine={ispristine}
      themeMode={themeMode}>
      <FormControl variant="outlined" required={required} error={error}>
        <InputBase
          id={`${props.id}-label`}
          htmlFor={`${inputProps?.name}-${label}`}
          themeMode={themeMode}>
          {label}
        </InputBase>
        <SelectdBase
          id={`${inputProps?.name}-${label}`}
          native
          themeMode={themeMode}
          error={error}
          IconComponent={ArrowDownSlimIcon}
          label={label}
          {...props}></SelectdBase>
        {error && (
          <FormHelperText className="arn-select-error">
            {errorMessage && errorMessage}
          </FormHelperText>
        )}
      </FormControl>
    </StyledSelectWrapper>
  )
}

export default StyledSelect
