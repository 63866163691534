import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { AnchorWithParamsProps } from './anchor.type'

export const AnchorStyle = css<AnchorWithParamsProps>`
  opacity: ${({ customprops }) => (customprops && customprops.disabled ? '0.5' : '1')};
  display: ${({ customprops }) => (customprops && customprops.isHidden ? 'none' : 'initial')};
  cursor: ${({ customprops }) => (customprops && customprops.disabled ? 'none' : 'pointer')};
  pointer-events: ${({ customprops }) =>
    customprops && customprops.disabled ? 'none' : 'initial'};
  color: ${({ customprops, theme,themeMode  }) =>
    customprops && customprops.color ? customprops.color : themeMode === 'dark' ? theme.palette.secondary.greyDarkmode : theme.palette.secondary.main};
  line-height: ${({ customprops, theme}) =>
    customprops && customprops.lineHeight ? customprops.lineHeight : 'normal'};
  text-align: inherit;
  text-decoration: ${({ customprops }) =>
    customprops && customprops.isUnderlined ? 'underline !important' : 'none'};
  text-transform: ${({ customprops }) =>
    customprops && customprops.uppercase ? 'uppercase' : 'initial'};
  &:hover {
    color: ${({ customprops }) => customprops && customprops.colorHover && customprops.colorHover};
  }
`
export const AnchorWithStyle = styled(Link)`
  ${AnchorStyle}
`
