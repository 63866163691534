import { merge, sharedOverrides } from '../shared-theme'

import { createTheme } from '@material-ui/core/styles'
import { overrides } from './light-theme'
import { palette } from '../color-palette'

const appName = process.env.REACT_APP_STORENAME
const customPalette = palette[appName?.toLowerCase()]
  ? palette[appName?.toLowerCase()]
  : palette.emerald

const {
  geckoGreen,
  black,
  white,
  lobsterPink,
  warmGrey,
  warningRedDarkmode,
  greyDarkmode,
  greyLightmode,
} = customPalette

export const darkOverrides = {
  name: 'Arnette dark',
  palette: {
    type: 'dark',
    light: white,
    main: black,
    dark: warmGrey,
    contrastText: warmGrey,
    background: {
      default: black,
      dark: black,
    },
    primary: {
      light: white,
      main: black,
      dark: warmGrey,
      contrastText: warmGrey,
    },
    secondary: {
      light: lobsterPink,
      main: warmGrey,
      dark: warmGrey,
      contrastText: black,
      greyDarkmode: greyDarkmode,
      greyLightmode: greyLightmode,
    },
    button: {
      backgroundColor: geckoGreen,
      '&:hover': {
        backgroundColor: geckoGreen,
      },
    },
    text: {
      primary: warmGrey,
      secondary: warmGrey,
      dark: white,
      light: black,
      error: warningRedDarkmode,
      contrastText: black,
    },
    action: {
      hover: geckoGreen,
    },
    custom: customPalette,
  },
}

const combinedOverrides = merge(sharedOverrides, overrides, darkOverrides)
const theme = createTheme(combinedOverrides)

export default theme
