import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CTALink from '../../../StyledUI/CTALink'

export const CmsVideoMediaControllerWrapper = styled.div`
  ${({ theme }) => `
  position: absolute;
  bottom: 0;
  margin-left: ${theme.spacing(1)}px;
  margin-bottom: ${theme.spacing(2)}px;

  ${theme.breakpoints.down('sm')} {
    top: 20rem;
  }
`}
`

const Controller = styled(CTALink)`
  text-transform: uppercase;
  padding: 6px 4px;
  line-height: 1;
  margin-right: 1rem;
`

const CmsVideoMedia: FC<{
  playerBanner: { isPlaying: boolean; muted: boolean }
  toggleMute(e: React.MouseEvent): void
  togglePlay(e: React.MouseEvent): void
}> = ({ playerBanner, toggleMute, togglePlay }) => {
  const { t } = useTranslation()

  return (
    <CmsVideoMediaControllerWrapper aria-label="ControllerWrapper">
      <Controller aria-label={playerBanner.isPlaying ? t('Playing.Pause') : t('Playing.Play')} onClick={(e) => togglePlay(e)}>
        {playerBanner.isPlaying ? t('Playing.Pause') : t('Playing.Play')}
      </Controller>
      {playerBanner.muted ? (
        null
      ) : (
        <Controller aria-label="Controller" onClick={(e) => toggleMute(e)}>
          {t('Playing.UnMuted')}
        </Controller>
      )}
    </CmsVideoMediaControllerWrapper>
  )
}

export default CmsVideoMedia
