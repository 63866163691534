import React from 'react'
import ButtonUnstyled, { ButtonProps } from '@mui/base/Button'
import { styled } from '@mui/material/styles'

export interface PillProps extends ButtonProps {
  labelText?: string
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  variant?: 'primary' | 'secondary' | 'tertiary'
}

export const variantMap = (
  theme,
  variant: PillProps['variant'] = 'primary'
) => {
  const primary =  {
    background: '#EBE9E4',
    hoverBackground: '#EBE9E4',
    color: theme.palette.text.primary,
    colorHover: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
    borderColorhover: theme.palette.text.primary,
  }

  const secondary = {
    background: '#000000',
    hoverBackground: '#000000',
    color: '#EBE9E4',
    colorHover: '#EBE9E4',
    borderColor: theme.palette.text.primary,
    borderColorhover: theme.palette.text.primary,
  }
  

  return {
    primary,
    secondary,
  }[variant]
}

export const Pill = styled(
  ({ labelText, startIcon, endIcon, ...props }: PillProps) => (
    <ButtonUnstyled {...props}>
      {startIcon}
      {labelText}
      {endIcon}
    </ButtonUnstyled>
  )
)(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center',
  borderRadius: 40,
  appearance: 'none',
  fontFamily: 'inherit',
  fontWeight: 400,
  fontSize: 12,
  height:28,
  textTransform: 'uppercase',
  lineHeight: 1.6,
  cursor: 'pointer',
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,

  color: variantMap(theme, variant)?.color ,
  backgroundColor: variantMap(theme, variant)?.background ,
  border: `1px solid ${variantMap(theme, variant)?.borderColor } !important`,

  '&:hover': {
    backgroundColor: variantMap(theme, variant)?.hoverBackground ,
    border: `1px solid ${
      variantMap(theme, variant)?.borderColorhover
    } !important`,
  },

  '&:disabled': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
}))
