//Standard libraries
import { call, put } from 'redux-saga/effects'

//Redux
import { GET_SEO_CONFIG_SUCCESS_ACTION } from '../../actions/seo'
//Foundation libraries
import urlsService from '../../../foundation/apis/search/urls.service'

/**
 *
 */
export function* getSEO(action: any) {
  const { identifier } = action.payload
  try {
    const response = yield call(urlsService.getV2URLResourcesUsingGET, action.payload)
    yield put(GET_SEO_CONFIG_SUCCESS_ACTION({ identifier, response }))
  } catch (error) {
    if (error.isAxiosError && error.response && error.response.status === 404) {
      const response = error.response
      yield put(GET_SEO_CONFIG_SUCCESS_ACTION({ identifier, response }))
    }
  }
}
