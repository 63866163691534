import styled from 'styled-components'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'
import { RoundButtonProps } from '../../../types/roundbutton'
import { StyledLink } from '../../StyledUI'
import { ButtonStyle } from '../../StyledUI/RoundCta'

export const OrderHistoryTitleWrapper = styled.div`
  ${({ theme }) => `
  padding: ${theme.spacing(1.5)}px ${theme.spacing(1)}px;
  border: 1px solid ${theme.palette.text.primary};
  border-bottom: none;

  ${getDesktopThemeBreakpoint(theme)} {
    padding: ${theme.spacing(1.5)}px ${theme.spacing(2.25)}px;
  }
`}
`

export const OrderHistoryContentWrapper = styled.div`
  ${({ theme }) => `
  border: 1px solid ${theme.palette.text.primary};

  .arn-pagination {
    margin-bottom: ${theme.spacing(2)}px;
  }

  ${getDesktopThemeBreakpoint(theme)} {
    padding: 21px ${theme.spacing(2.25)}px ${theme.spacing(2)}px;

    .arn-pagination {
      margin-top: ${theme.spacing(2)}px;
    }
  }
`}
`

// For mobile screens only
export const OrderHistoryList = styled.div`
  display: flex;
  flex-direction: column;
`

// For mobile screens only
export const OrderHistoryListItem = styled.div`
  ${({ theme }) => `
  padding: ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1.5)}px;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;

  &:not(:first-child) {
    margin-top: 10px
  }


  &:not(:last-child) {
    border-bottom: 1px solid ${theme.palette.text.contrastText};
  }
`}
`

export const OrderHistoryBackLink = styled(StyledLink)`
  ${({ theme }) => `
  margin-bottom: ${theme.spacing(0.625)}px;
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
`}
`

export const StyledAnchorAsButton = styled.a<RoundButtonProps>`
  ${ButtonStyle}
`
