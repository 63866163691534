import { find } from 'lodash'
import { ICMChannel } from '../../types/teaser'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import Log from '../../services/Log'
import { useCms } from '../../components/providers/CmsProvider'
/**
Global external library functions hook.
it's purpose is to append functions to the window objest in order to
make all functions coming from external library to be executed (eg: Utag)
*/
export const useGlobalExternalActions = () => {
  const { basePath } = useStoreIdentity()
  const { footerPlacements } = useCms()
  try {
    const navigation = find(footerPlacements, { name: 'footer_navigation' })
    const navigationItems = navigation?.items || []
    const footerLegal = find(navigationItems , { name: 'WW_FOOTER_LEGAL'}) as ICMChannel
    const footerLegalChildern = footerLegal.children || []
    const privacyPolicy = find(footerLegalChildern , {name : 'US__footer_privacy'})
    const privacyPolicyUrl = privacyPolicy?.formattedUrl || ''
    const termsAndUse = find(footerLegalChildern , {name : 'WW__footer_Terms_of_use'})
    const termsAndUseUrl = termsAndUse?.formattedUrl || ''
    window.TealiumConsentPrivacyLink = () => {
        try {
          if (!!privacyPolicyUrl) {
            window.location.href = `${basePath}${privacyPolicyUrl}`
          }
        } catch (e: any) {
          Log.error('error while directing to cookies policy page', e)
        }
      }
      window.TealiumTermsOfUseLink = () => {
        try {
          if (!!termsAndUseUrl) {
            window.location.href = `${basePath}${termsAndUseUrl}`
          }
        } catch (e: any) {
          Log.error('error while directing to terms and condition page', e)
        }
      }
  } catch (e: any) {
    Log.error('error while appending new global function to window object', e)
  }
}
