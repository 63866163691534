import { plpDashBtnSelector, plpDashfacetSelector, plpFacetsSelector } from './site'
import { FacedTypePage, Facet } from '../../types/filters'
import { RootReducerState } from '../reducers'
import { createSelector } from 'reselect'
import pickBy from 'lodash/pickBy'

export const facetsSelector = (state: RootReducerState) => state.catalog.facets

export const facetsAlwaysEmptySelector = createSelector(facetsSelector, (baseFacets) => {
  let retvalue: string[] = []

  if (baseFacets) {
    baseFacets.forEach((filter) => {
      filter.entry.forEach((entry) => {
        if (entry.count === '0') {
          retvalue.push(entry.value)
        }
      })
    })
  }

  return retvalue
})

export const currentPLPDashFacetsSelector = (page: FacedTypePage) =>
  createSelector(
    facetsSelector,
    plpDashfacetSelector,
    facetsAlwaysEmptySelector,
    plpDashBtnSelector,
    (catalogFacets, dashFacets, facetsAlwaysEmpty, dashBtnFacet) => {
      const checkPage: Record<string, string> = page === 'product' ? dashBtnFacet! : dashFacets

      // filter only facets coming from storeconf whose value is "true"
      const facets: Record<string, string> = pickBy(
        checkPage,
        (value) => value.toLowerCase() === 'true'
      )

      return (Object.keys(facets || {}) ?? []).reduce((prev, curr) => {
        let found: Facet | undefined = catalogFacets?.find((v) => v.name === curr)

        if (found) {
          // dashbutton not valid if all results are "True", e.g. polarized button in a polarized category
          const entryTrue = found.entry.find((v) => v.label === facets?.[curr])

          if (!entryTrue || facetsAlwaysEmpty.includes(entryTrue.value)) {
            found = undefined
          }
        }

        return found ? [...prev, found] : prev
      }, [] as Facet[])
    }
  )

export const currentPLPFacetsSelector = createSelector(
  facetsSelector,
  plpFacetsSelector,
  (catalogFacets, plpFacets) =>
    (plpFacets ?? []).reduce((prev, curr) => {
      const found = catalogFacets?.find((v) => v.name === curr)

      return found ? [...prev, found] : prev
    }, [] as Facet[])
)
