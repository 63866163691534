/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from 'styled-components'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import React from 'react'

const StyledListItemIcon = styled(({ ...props }) => <ListItemIcon {...props} />)`
  ${({ theme }) => `
  `}
`

export default StyledListItemIcon
