import { call, put } from 'redux-saga/effects'
import userContextService from '../../../foundation/apis/transaction/userContext.service'
import {
  USER_CONTEXT_REQUEST_ERROR_ACTION,
  USER_CONTEXT_REQUEST_SUCCESS_ACTION,
} from '../../actions/context'
import { AppPayloadWithWidgetAction } from '../../store'

export function* getUserContext({ payload }: AppPayloadWithWidgetAction) {
  try {
    const response = yield call(userContextService.getContextData, payload)

    let userPayload = response.data
    if (payload?.widget) {
      userPayload['widget'] = payload.widget
    }

    yield put(USER_CONTEXT_REQUEST_SUCCESS_ACTION(userPayload))
  } catch (e) {
    yield put(USER_CONTEXT_REQUEST_ERROR_ACTION(e))
  }
}
