import { Drawer } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const StyledNewsletterDrawer = styled(({ ...props }) => <Drawer {...props} />)`
  .filters-menu-content {
    max-width: 100vw;
    max-height: 100vh;

    height: 100%;
    width: 100%;
    padding: ${({ theme }) => theme.spacing()}px;
    .filters-menu-title {
      margin-bottom: ${({ theme }) => theme.spacing()}px;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    .filters-menu-clear {
      font-size: 0.8rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.palette.custom.black};
    }
    ${({ theme }) => theme.breakpoints.up('md')} {
      width: 470px;
      padding: ${({ theme }) => theme.spacing(2)}px;
    }
  }
`

export default StyledNewsletterDrawer
