import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { passwordValidationFields } from '../../../constants/form'
import useThemeMode from '../../../hooks/useThemeMode'
import { AlertIcon, CheckmarkRoundedIcon, CircleIcon } from '../../StyledUI/Icons'
import {
  PasswordFieldHelperHint,
  PasswordFieldRequirementsList,
} from './PasswordRequirements.style'

interface PasswordRequirementsProps {
  errorsFieldNames: string[]
  isInputInitialized: boolean
}

const PasswordRequirements: FC<PasswordRequirementsProps> = ({
  errorsFieldNames,
  isInputInitialized,
}) => {
  const { t } = useTranslation()
  const { themeMode } = useThemeMode()

  const allErrorsKeys = Object.keys(passwordValidationFields)
  const isInvalid = errorsFieldNames.length && isInputInitialized

  return (
    <>
      <PasswordFieldHelperHint className="password-reqs__helper-hint" themeMode={themeMode}>
        {t('RegistrationLayout.Msgs.PasswordPolicy')}
      </PasswordFieldHelperHint>
      <PasswordFieldHelperHint
        className={`password-reqs__helper-hint ${
          isInvalid ? 'password-reqs__helper-hint--warning' : 'password-reqs__helper-hint--active'
        }`}
        themeMode={themeMode}>
        {isInvalid
          ? t('RegistrationLayout.Msgs.InvalidPassword1')
          : t('RegistrationLayout.Msgs.HintPassword')}
      </PasswordFieldHelperHint>
      <PasswordFieldRequirementsList className="password-reqs__list" themeMode={themeMode}>
        {allErrorsKeys.map((key) => {
          const isRequirementExecuted = !errorsFieldNames.includes(key)

          const RequirementIcon = !isInputInitialized
            ? CircleIcon
            : isRequirementExecuted
            ? CheckmarkRoundedIcon
            : AlertIcon

          return (
            <li>
              <RequirementIcon
                className={[
                  'password-reqs__icon',
                  ...(!isRequirementExecuted ? ['password-reqs__icon--alert'] : []),
                  ...(!isInputInitialized ? ['password-reqs__icon--initial'] : []),
                ].join(' ')}
              />
              <span
                className={`password-reqs__condition ${
                  !isInputInitialized ? 'password-reqs__condition--initial' : ''
                }`}>
                {t(passwordValidationFields[key].message || '')}
              </span>
            </li>
          )
        })}
      </PasswordFieldRequirementsList>
    </>
  )
}

export default PasswordRequirements
