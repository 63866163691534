import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { themeModeSelector } from '../../../redux/selectors/theme'
import theme from '../../../themes'
type Props = {
  type?: 'basic' | 'linear' | 'stacked'
  color?: any
  style?: any
  width?: number | string
  height?: number
  viewBox?: string
}

const heights = {
  basic: 20,
  linear: 43,
  stacked: 91,
}

const widths = {
  basic: 160,
  linear: 488,
  stacked: 354,
}

const viewBoxes = {
  basic: '0 0 160 20',
  linear: '0 0 488 43',
  stacked: '0 0 354 91',
}

const Logo = ({ type = 'basic', color, style, width, height, viewBox }: Props) => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  const effectiveHeight = height ?? heights[type]
  const effectiveWidth = width ?? widths[type]
  const effectiveViewBox = viewBox ?? viewBoxes[type]
  const effectiveColor = color ?? getColorByTheme(themeMode)
  let path
  switch (type) {
    case 'basic':
      path = (
        <path
          d='M139.56 0V20H160V15.5427H145.06V12.1282H156.821V7.87137H145.06V4.45725H160V0H139.56ZM99.9526 0V4.42863H107.861V20H113.443V4.42863H123.896V20H129.478V4.42863H137.441V0H99.9526ZM77.3936 0V20H97.8337V15.5427H82.8937V12.1282H94.6545V7.87137H82.8937V4.45725H97.8337V0H77.3936ZM68.8239 0V12.2286L57.468 0H51.5581V20H56.9482V6.85725L69.3168 20H74.2144V0H68.8239ZM27.3 0V20H32.8551L32.8539 13.7145H38.5152L43.5538 20H50.039L44.7214 13.5855C47.4503 12.7204 49.4084 10.0745 49.4084 6.94275C49.4084 3.10824 46.46 0 42.7879 0H27.3ZM32.8278 4.42863H41.5837C42.9136 4.42863 43.9816 5.55412 43.9816 6.94275C43.9816 8.33137 42.9136 9.45726 41.5837 9.45726H32.8278V4.42863ZM9.90517 0L0 20H5.84415L7.54126 16.3855H14.2916L16.2905 12.1294L19.9869 20H25.8306L15.9255 0H9.90517ZM12.9157 4.94275L16.2901 12.1282H9.54092L12.9157 4.94275Z'
          fill={effectiveColor}
        />
      )
      break
    case 'linear':
      path = (
        <>
          <path
            d='M367.917 25.4544H364.766V42.4019H359V0.598145H367.917C371.671 0.598145 375.157 2.5255 375.157 8.10819V17.9444C375.09 23.4606 371.671 25.4544 367.917 25.4544ZM369.392 8.10819C369.392 6.44668 368.788 5.71561 367.514 5.71561H364.699V20.7357H367.514C368.788 20.7357 369.392 19.9382 369.392 18.3431V8.10819Z'
            fill={effectiveColor}
          />
          <path
            d='M386.353 43C381.392 43 377.839 39.4776 377.839 34.493V8.50696C377.839 3.52241 381.392 0 386.353 0C391.314 0 394.867 3.58887 394.867 8.50696V34.493C394.867 39.5441 391.314 43 386.353 43ZM389.035 8.04173C389.035 6.44668 388.029 5.31685 386.353 5.31685C384.61 5.31685 383.738 6.44668 383.738 8.04173V35.0247C383.738 36.6862 384.677 37.7496 386.353 37.7496C388.096 37.7496 389.035 36.6862 389.035 35.0247V8.04173Z'
            fill={effectiveColor}
          />
          <path
            d='M406.063 43C400.834 43 397.683 39.7434 397.683 34.493V29.9737H402.979V34.6924C402.979 36.8856 403.985 38.2148 405.862 38.2148C407.94 38.2148 408.879 36.9521 408.879 34.8918C408.879 30.9706 407.27 27.847 404.253 23.5935C401.437 19.7388 397.884 14.0896 397.884 9.30448C397.884 4.0541 400.566 0 406.331 0C411.091 0 414.175 3.25657 414.175 8.50696V13.2921H409.013V8.30757C409.013 6.2473 408.074 4.98454 406.398 4.98454C404.722 4.98454 403.583 6.04791 403.583 7.97527C403.583 12.6275 405.259 14.8207 408.544 19.6059C411.896 24.3246 414.577 29.0433 414.577 33.8284C414.51 40.4745 410.287 43 406.063 43Z'
            fill={effectiveColor}
          />
          <path d='M423.494 0.598145H417.662V42.4019H423.494V0.598145Z' fill={color} />
          <path
            d='M437.171 5.98145V42.4019H431.405V5.98145H425.908V0.598145H442.467V5.98145H437.171Z'
            fill={effectiveColor}
          />
          <path d='M450.78 0.598145H444.948V42.4019H450.78V0.598145Z' fill={color} />
          <path
            d='M465.06 42.4019H459.295L453.06 0.598145H459.027L462.312 30.2396L465.932 0.598145H471.429L465.06 42.4019Z'
            fill={effectiveColor}
          />
          <path
            d='M473.708 42.4019V0.598145H487.787V5.78207H479.474V18.4096H485.307V23.3277H479.474V37.2179H487.787V42.4019H473.708Z'
            fill={effectiveColor}
          />
          <path
            d='M36.0015 26.0525H21.1182L28.5599 11.0324L36.0015 26.0525ZM44.1806 42.4683H57.1197L35.197 0.664551H21.9227L0 42.4683H12.9391L16.6934 34.8918H31.6438L36.0685 25.986L44.1806 42.4683ZM97.2108 15.2194C97.2108 18.1437 94.8643 20.4698 91.9145 20.4698H72.5393V9.90257H91.9145C94.8643 9.90257 97.2108 12.2952 97.2108 15.2194ZM110.619 42.4683L98.8868 29.0432C104.921 27.2488 109.278 21.7326 109.278 15.153C109.278 7.11123 102.775 0.664551 94.6632 0.664551H60.3377V42.4683H72.6064V29.3091H85.1432L96.2722 42.4683H110.619ZM153.191 42.4683H163.984V0.664551H152.051V26.1854L127.044 0.664551H113.971V42.4683H125.905V15.02L153.191 42.4683ZM171.091 42.4683H216.277V33.1638H183.226V26.0525H209.238V17.1468H183.226V9.96903H216.277V0.664551H171.091V42.4683ZM286.202 9.90257H303.834V0.664551H220.903V9.90257H238.401V42.4683H250.737V9.90257H273.866V42.4683H286.202V9.90257ZM320.594 33.1638V26.0525H346.607V17.1468H320.594V9.96903H353.646V0.664551H308.46V42.4683H353.646V33.1638H320.594Z'
            fill={effectiveColor}
          />
        </>
      )
      break
    case 'stacked':
      path = (
        <>
          <path
            d='M367.917 25.4544H364.766V42.4019H359V0.598145H367.917C371.671 0.598145 375.157 2.5255 375.157 8.10819V17.9444C375.09 23.4606 371.671 25.4544 367.917 25.4544ZM369.392 8.10819C369.392 6.44668 368.788 5.71561 367.514 5.71561H364.699V20.7357H367.514C368.788 20.7357 369.392 19.9382 369.392 18.3431V8.10819Z'
            fill={effectiveColor}
          />
          <path
            d='M386.353 43C381.392 43 377.839 39.4776 377.839 34.493V8.50696C377.839 3.52241 381.392 0 386.353 0C391.314 0 394.867 3.58887 394.867 8.50696V34.493C394.867 39.5441 391.314 43 386.353 43ZM389.035 8.04173C389.035 6.44668 388.029 5.31685 386.353 5.31685C384.61 5.31685 383.738 6.44668 383.738 8.04173V35.0247C383.738 36.6862 384.677 37.7496 386.353 37.7496C388.096 37.7496 389.035 36.6862 389.035 35.0247V8.04173Z'
            fill={effectiveColor}
          />
          <path
            d='M406.063 43C400.834 43 397.683 39.7434 397.683 34.493V29.9737H402.979V34.6924C402.979 36.8856 403.985 38.2148 405.862 38.2148C407.94 38.2148 408.879 36.9521 408.879 34.8918C408.879 30.9706 407.27 27.847 404.253 23.5935C401.437 19.7388 397.884 14.0896 397.884 9.30448C397.884 4.0541 400.566 0 406.331 0C411.091 0 414.175 3.25657 414.175 8.50696V13.2921H409.013V8.30757C409.013 6.2473 408.074 4.98454 406.398 4.98454C404.722 4.98454 403.583 6.04791 403.583 7.97527C403.583 12.6275 405.259 14.8207 408.544 19.6059C411.896 24.3246 414.577 29.0433 414.577 33.8284C414.51 40.4745 410.287 43 406.063 43Z'
            fill={effectiveColor}
          />
          <path d='M423.494 0.598145H417.662V42.4019H423.494V0.598145Z' fill={color} />
          <path
            d='M437.171 5.98145V42.4019H431.405V5.98145H425.908V0.598145H442.467V5.98145H437.171Z'
            fill={effectiveColor}
          />
          <path d='M450.78 0.598145H444.948V42.4019H450.78V0.598145Z' fill={color} />
          <path
            d='M465.06 42.4019H459.295L453.06 0.598145H459.027L462.312 30.2396L465.932 0.598145H471.429L465.06 42.4019Z'
            fill={effectiveColor}
          />
          <path
            d='M473.708 42.4019V0.598145H487.787V5.78207H479.474V18.4096H485.307V23.3277H479.474V37.2179H487.787V42.4019H473.708Z'
            fill={effectiveColor}
          />
          <path
            d='M36.0015 26.0525H21.1182L28.5599 11.0324L36.0015 26.0525ZM44.1806 42.4683H57.1197L35.197 0.664551H21.9227L0 42.4683H12.9391L16.6934 34.8918H31.6438L36.0685 25.986L44.1806 42.4683ZM97.2108 15.2194C97.2108 18.1437 94.8643 20.4698 91.9145 20.4698H72.5393V9.90257H91.9145C94.8643 9.90257 97.2108 12.2952 97.2108 15.2194ZM110.619 42.4683L98.8868 29.0432C104.921 27.2488 109.278 21.7326 109.278 15.153C109.278 7.11123 102.775 0.664551 94.6632 0.664551H60.3377V42.4683H72.6064V29.3091H85.1432L96.2722 42.4683H110.619ZM153.191 42.4683H163.984V0.664551H152.051V26.1854L127.044 0.664551H113.971V42.4683H125.905V15.02L153.191 42.4683ZM171.091 42.4683H216.277V33.1638H183.226V26.0525H209.238V17.1468H183.226V9.96903H216.277V0.664551H171.091V42.4683ZM286.202 9.90257H303.834V0.664551H220.903V9.90257H238.401V42.4683H250.737V9.90257H273.866V42.4683H286.202V9.90257ZM320.594 33.1638V26.0525H346.607V17.1468H320.594V9.96903H353.646V0.664551H308.46V42.4683H353.646V33.1638H320.594Z'
            fill={effectiveColor}
          />
        </>
      )
  }

  return (
    <svg
      style={style}
      width={effectiveWidth}
      height={effectiveHeight}
      viewBox={effectiveViewBox}
      fill='white'
      xmlns='http://www.w3.org/2000/svg'>
      {path}
    </svg>
  )
}

const getColorByTheme = (themeMode) =>
  theme.palette.secondary.main[themeMode === 'light' ? 'light' : 'main']

export default Logo
