import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import { AddressFormData } from '../../../types/form'
import { SuggesterInputFormData } from '../../../types/inputFields'


const useAddressSuggestion = (
  country: string,
  addressFields?: string[]
) => {
  let geocoder: google.maps.Geocoder =
    window.google && window.google.maps && new window.google.maps.Geocoder()
  const {
    init,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    cache: false,
    requestOptions: {
      componentRestrictions: {
        country: country,
      },
      types: ['address'],
    },
    debounce: 300,
  })

  const getAutoCompleteAddressData = (address: string) => {
    return (
      geocoder &&
      geocoder
        .geocode({ address }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            return results
          } else {
            return new Error(status)
          }
        })
        .then((res) => {
          if (res.results && res.results.length > 0) {
            const addressComponents = ([] as google.maps.GeocoderAddressComponent[]).concat(
              ...res.results.map<google.maps.GeocoderAddressComponent[]>(
                (item) => item.address_components
              )
            )
            return formatAddressData(addressComponents)
          }
        })
    )
  }
  /**
   *
   * @param data
   * the address form data
   * @returns address form data fields compared to those in store conf
   */
  const filterAddressDataFields = (data: SuggesterInputFormData): SuggesterInputFormData=> {
    const filteredData: SuggesterInputFormData = {}
    Object.keys(data).map(fieldName => {
      if (addressFields?.includes(fieldName)) {
        filteredData[fieldName] = data[fieldName]
      }
    })
    return filteredData
  }

  const formatAddressData = (details: google.maps.GeocoderAddressComponent[]) => {
    const addressData: SuggesterInputFormData = {
      addressLine1: setAddressByGeocode(details) || '',
      zipCode: setZipByGeocode(details) || '',
      city: setCityByGeocode(details) || '',
      country: setCountryByGeocode(details) || '',
      state: setStateByGeocode(details) || '',
    }


    return filterAddressDataFields(addressData)
  }

  const getCityAndStateByZipcode = async (zip: string, zipCountry: string) => {
    if (zip && zip.length > 3) {
      return (
        geocoder &&
        (await geocoder
          .geocode(
            {
              componentRestrictions: {
                country: zipCountry,
                postalCode: zip,
              },
            },
            (results, status) => {
              if (status === window.google.maps.GeocoderStatus.OK) {
                return results
              } else {
                return new Error(status)
              }
            }
          )
          .then((res) => {
            if (res.results && res.results.length > 0) {
              const addressComponents = res.results[0].address_components
              return filterAddressDataFields({
                state: setStateByGeocode(addressComponents),
                city: setCityByGeocode(addressComponents),
              })
            }
          }))
      )
    }
  }

  const setAddressByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const streetNumber = addressComponents.find(
      (comp) => comp.types.findIndex((type) => type === 'street_number') !== -1
    )
    const route = addressComponents.find(
      (comp) => comp.types.findIndex((type) => type === 'route') !== -1
    )
    const premise = addressComponents.find(
      (comp) => comp.types.findIndex((type) => type === 'premise') !== -1
    )
    let address
    if (streetNumber && route) {
      address = `${streetNumber.long_name} ${route.long_name}`
    } else if (route) {
      address = route.long_name
    } else if (premise) {
      address = premise.long_name
    }

    if (address) {
      return address
    }
  }

  const setZipByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const zip = addressComponents.find(
      (comp) => comp.types.findIndex((type) => type === 'postal_code') !== -1
    )
    if (zip) {
      return zip.short_name
    }
  }

  const setCityByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const cityFallback1 = addressComponents.find(
      (comp) => comp.types.findIndex((type) => type === 'postal_town') !== -1 // UK or GB
    )
    const cityFallback2 = addressComponents.find(
      (comp) =>
        comp.types.findIndex((type) => type === 'locality') !== -1 &&
        comp.types.findIndex((type) => type === 'political') !== -1
    )// DE

    const cityFallback3 = addressComponents.find(
      (comp) =>
        comp.types.findIndex((type) => type === 'sublocality') !== -1 &&
        comp.types.findIndex((type) => type === 'political') !== -1 &&
        comp.types.findIndex((type) => type === 'sublocality_level_1') !== -1
    )

    const cityFallback4 = addressComponents.find(
      (comp) =>
        comp.types.findIndex((type) => type === 'sublocality') !== -1 &&
        comp.types.findIndex((type) => type === 'political') !== -1
    )


    const cityFallback5 = addressComponents.find(
      (comp) =>
        comp.types.findIndex((type) => type === 'administrative_area_level_3') !== -1 &&
        comp.types.findIndex((type) => type === 'political') !== -1
    )

    const city = cityFallback1 || cityFallback2 || cityFallback3 || cityFallback4 || cityFallback5

    if (city) {
      return city.short_name
    }
  }


  const setStateByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    let state: google.maps.GeocoderAddressComponent | undefined
    switch (country) {
      case 'gb':
        state = addressComponents.find(
          (comp) =>
            comp.types.findIndex((type) => type === 'administrative_area_level_2') !== -1 &&
            comp.types.findIndex((type) => type === 'political') !== -1
        )
        break

      case 'it':
        state = addressComponents.find(
          (comp) =>
            comp.types.findIndex((type) => type === 'administrative_area_level_2') !== -1 &&
            comp.types.findIndex((type) => type === 'political') !== -1
        )
        break

      default:
        state = addressComponents.find(
          (comp) =>
            comp.types.findIndex((type) => type === 'administrative_area_level_1') !== -1 &&
            comp.types.findIndex((type) => type === 'political') !== -1
        )
        break
    }

    if (state) {
      return state.short_name
    }
  }

  const setCountryByGeocode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const country = addressComponents.find(
      (comp) =>
        comp.types.findIndex((type) => type === 'country') !== -1 &&
        comp.types.findIndex((type) => type === 'political') !== -1
    )

    if (country) {
      return country.short_name
    }
  }

  /*const checkIfCaliforniaByGeoCode = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const state = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === 'administrative_area_level_1') !== -1 &&
        comp.types.findIndex(type => type === 'political') !== -1
    )

    const country = addressComponents.find(
      comp =>
        comp.types.findIndex(type => type === 'political') !== -1 &&
        comp.types.findIndex(type => type === 'country') !== -1
    )

    if (state && country) {
      const { code, countryId } = this.props

      const storeInfo: StoreCountry = {
        code,
        countryId,
      }

      const stateObj = this.props.states.find(st => st.code === state.short_name)
      stateObj &&
        this.props.checkStateOnSelect &&
        this.props.checkStateOnSelect(stateObj.id, storeInfo, this.props.states)
    }
  }*/

  return {
    setSuggestionValue: setValue,
    getAutoCompleteAddressData,
    suggestion: { status, data },
    clearSuggestions,
    getDetails,
    formatAddressData,
    getCityAndStateByZipcode,
    init,
  }
}
export default useAddressSuggestion
