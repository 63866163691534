import {
  Container,
  SignUpButton,
  SignUpWrapper,
  Text,
  TextWrapper,
} from './NewsletterBannerStyledComponents'

import { NEWSLETTER_DRAWER_TOGGLE_ACTION } from '../../redux/actions/ui'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

type Props = {
  text: string
}

const NewsletterBanner = ({ text }: Props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const toggleNewsletterDrawer = () => {
    dispatch(NEWSLETTER_DRAWER_TOGGLE_ACTION(true))
  }

  return (
    <Container>
      <TextWrapper>
        <Text>{text}</Text>
      </TextWrapper>

      <SignUpWrapper>
        <SignUpButton
          variant={'primary'}
          themeMode={'light'}
          data-element-id={'X_X_Footer_NewsletterSignup'}
          onClick={toggleNewsletterDrawer}>
          {t('Footer.SignUp')}
        </SignUpButton>
      </SignUpWrapper>
    </Container>
  )
}

export default NewsletterBanner
