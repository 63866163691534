import ProductService from '../../../services/ProductService'
import { ProductAnalyticsRX } from '../../../types/product'
import {
  getBrand,
  getFrameMaterial,
  getFrameShape,
  getLensesTreatment,
  getModelCode,
  getModelName,
  getProductType,
} from '../../../utils/productAttributes'
import { getSoldOutWithDefault, productTypeToCategory } from './formatters/productFormatter'
import tealiumService from './tealium.service'
import { Product, ProductFields, ProductFieldsForEventKey } from './types/props.types'

class TealiumProductFormatService {
  eventTypes = {
    addToCart: 'AddToCart',
  } as const

  private getters: Record<
    keyof ProductFields,
    (p: ProductAnalyticsRX, productCountInOrderMap?: number) => string
  > = {
    Badges: () => '',
    Brand: (p): string => getBrand(p),
    Category: (p) => productTypeToCategory[getProductType(p)] || '',
    Conf_IsUpcSupported: () => '0',
    Engraving: () => '',
    FrameTechnology: (p) => getFrameMaterial(p),
    FrameType: () => 'STD',
    InsuranceAmount: () => '',
    InsuranceCode: () => '',
    LensTechnology: (p) => getLensesTreatment(p),
    LensType: (p) => (p.rxPrice ? 'RX' : 'PLANO'),
    LensUPC: () => '',
    ModelCode: (p) => getModelCode(p),
    ModelName: (p) => getModelName(p),
    OosOptions: (p) => getSoldOutWithDefault(p).OosOptions,
    Price: (p) => {
      const price = p.rxPrice || (p.price ? ProductService.getCurrentPrice(p.price) : NaN)

      return price ? price.toFixed(2) : ''
    },
    PriceFull: (p) => {
      const price = p.rxPrice || (p.price ? ProductService.getInitialPrice(p.price) : NaN)

      return price ? price.toFixed(2) : this.getters.Price(p)
    },
    Shape: (p) => getFrameShape(p),
    Sku: (p) => p.id,
    Status: (p) => getSoldOutWithDefault(p).Status,
    Type: (p) => (p.rxPrice ? 'RX' : 'STD'),
    Units: (_, productCountInOrderMap) => productCountInOrderMap?.toString() || '1',
    Visibility: () => 'Public',
    Warranty: () => '0',
  }

  get(eventType: ProductFieldsForEventKey, products: ProductAnalyticsRX[]): Product['Products'] {
    switch (eventType) {
      case this.eventTypes.addToCart:
        return this.getProductsWithOmittedKeys(products, [
          'Conf_IsUpcSupported',
          'InsuranceAmount',
          'InsuranceCode',
          'OosOptions',
          'Warranty',
        ])
      case tealiumService.pageTypes.plp:
        return this.getProductsWithOmittedKeys(products, [
          'Brand',
          'FrameTechnology',
          'InsuranceAmount',
          'InsuranceCode',
          'LensTechnology',
          'LensType',
          'LensUPC',
          'ModelCode',
          'ModelName',
          'Shape',
          'Units',
          'Warranty',
        ])
      case tealiumService.pageTypes.pdp:
        return this.getProductsWithOmittedKeys(products, [
          'InsuranceAmount',
          'InsuranceCode',
          'ModelCode',
          'Units',
          'Warranty',
        ])
      case tealiumService.pageTypes.shipping:
        return this.getProductsWithOmittedKeys(products, ['OosOptions'])

      default:
        return this.getProductsWithOmittedKeys(products, ['OosOptions'])
    }
  }

  private getProductFields(
    product: ProductAnalyticsRX,
    keysToOmit: (keyof ProductFields)[]
  ): Partial<ProductFields> {
    const requiredFields = {}

    for (const key of Object.keys(this.getters)) {
      if (keysToOmit.includes(key as keyof ProductFields)) {
        continue
      }

      requiredFields[key] = this.getters[key](product)
    }

    return requiredFields
  }

  private getProductsWithOmittedKeys(
    products: ProductAnalyticsRX[],
    keysToOmit: (keyof ProductFields)[]
  ): Product['Products'] {
    return products.reduce((acc: Product['Products'], p: ProductAnalyticsRX) => {
      if (p.partNumber) {
        acc[p.partNumber] = this.getProductFields(p, keysToOmit)
      }

      return acc
    }, {})
  }
}

export default new TealiumProductFormatService()
