//Standard libraries
import { AnyAction, createReducer } from '@reduxjs/toolkit'
import {
  CMS_API_ERROR_ACTION,
  HANDLE_CONNECTION_TIMEDOUT_ERROR_ACTION,
  HANDLE_SESSION_ERROR_ACTION,
  RESET_ERROR_SUCCESS_ACTION,
  RESET_SESSION_POPUP_LOGON_ERROR_ACTION,
  VALIDATION_ERROR_ACTION,
} from '../actions/error'
import {
  LOGIN_SUCCESS_ACTION,
  LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION,
  SESSION_ERROR_LOGIN_ERROR_ACTION,
} from '../actions/user'

import { EXPIRED_PASSWORD_PAGE_ERROR } from '../../foundation/constants/common'
import { ErrorReducerState } from './reducerStateInterface'
//Redux
import initStates from './initStates'

/**
 * Session error reducer
 */
const errorReducer = createReducer(initStates.error, (builder) => {
  builder.addCase(
    HANDLE_SESSION_ERROR_ACTION,
    (state: ErrorReducerState | any, action: AnyAction) => {
      Object.assign(state, { ...action.payload })
      delete state[EXPIRED_PASSWORD_PAGE_ERROR]
    }
  )
  builder.addCase(CMS_API_ERROR_ACTION, (state: ErrorReducerState | any, action: AnyAction) => {
    Object.assign(state, { ...action.payload })
    delete state[EXPIRED_PASSWORD_PAGE_ERROR]
  })
  builder.addCase(
    SESSION_ERROR_LOGIN_ERROR_ACTION,
    (state: ErrorReducerState | any, action: AnyAction) => {
      Object.assign(state, { sessionErrorLoginError: { ...action.payload } })
      delete state[EXPIRED_PASSWORD_PAGE_ERROR]
    }
  )
  builder.addCase(LOGIN_SUCCESS_ACTION, (state: ErrorReducerState | any, action: AnyAction) => {
    if (state.handled === false) state.handled = true
    delete state[EXPIRED_PASSWORD_PAGE_ERROR]
  })
  builder.addCase(
    RESET_ERROR_SUCCESS_ACTION,
    (state: ErrorReducerState | any, action: AnyAction) => {
      for (let variableKey in state) {
        if (state.hasOwnProperty(variableKey)) {
          delete state[variableKey]
        }
      }
      Object.assign(state, { ...action.payload })
    }
  )
  builder.addCase(
    RESET_SESSION_POPUP_LOGON_ERROR_ACTION,
    (state: ErrorReducerState | any, action: AnyAction) => {
      if (state.hasOwnProperty('sessionErrorLoginError')) {
        delete state.sessionErrorLoginError
      }
      delete state[EXPIRED_PASSWORD_PAGE_ERROR]
    }
  )
  builder.addCase(VALIDATION_ERROR_ACTION, (state: ErrorReducerState | any, action: AnyAction) => {
    Object.assign(state, { ...action.payload })
    delete state[EXPIRED_PASSWORD_PAGE_ERROR]
  })
  builder.addCase(
    LOGON_AND_CHANGE_PASSWORD_FAIL_ACTION,
    (state: ErrorReducerState | any, action: AnyAction) => {
      Object.assign(state, { ...action.payload })
    }
  )
  builder.addCase(
    HANDLE_CONNECTION_TIMEDOUT_ERROR_ACTION,
    (state: ErrorReducerState | any, action: AnyAction) => {
      Object.assign(state, { ...action.payload })
    }
  )
})
export default errorReducer
