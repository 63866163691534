import React, { FC, createContext, useEffect, useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { HeaderNavigationItem, Navigation } from '../../types/menu'
import {
  ICMCollection,
  ICallToAction,
  ILXTeaser,
  INavItem,
  IPlacement,
  isCMCollection,
  isLXTeaser,
  TMedia,
  ICMChannel,
} from '../../types/teaser'
import { cmsPreviewSelector } from '../../features/preview/selector'
import cmsService from '../../foundation/apis/cms/cms.service'
import { parseMenuItems } from '../../utils/menuItem'
import { useDynamicCmsContent } from '../../foundation/hooks/useDynamicCmsContent'
import { usePageType } from '../../foundation/hooks/usePageType'
import { sanitizeHtml } from '../../utils/common'

export interface PromoBar
  extends Pick<
    ILXTeaser,
    'teaserCountdownStart' | 'teaserCountdownUntil' | 'teaserHideExpiredCountdown'
  > {
  ctaSettings: ICallToAction[]
  textContent: string
}

type CmsPreviewParams = Partial<Record<keyof typeof cmsService.urlParams, string>>

export type HomeWidgetProps = {
  id: string
  placementName: string
  title: string
  icon: string // CMS icon name, e.g., arn-icon--profile_icon
  backgroundImageMedia: TMedia
  itemsColor: string
  items: { label: string; path: string; type: string }[]
}

interface CmsContextProps {
  bannerPromoCartPlacements: ILXTeaser[]
  cartPlacements: ILXTeaser[] | null
  cartTooltipDisc: IPlacement<ILXTeaser>[] | null
  emptyCartPlacements: ILXTeaser | null
  footerPlacements: IPlacement[]
  headerExtraCSS: string[]
  headerExtraJS: string[]
  headerLinks: HeaderNavigationItem[]
  headerPlacements: any[]
  headerPromoBars: PromoBar[]
  homePlacements: IPlacement[] | null
  homeWidgets: HomeWidgetProps[] | null
  legalNavItem: INavItem
  newsletterBannerText: string
  pageExtraCSS: string[]
  pageExtraJS: string[]
  privacyPagePath: string
  previewParams: CmsPreviewParams
  serviceNavItem: INavItem
  termsOfUsePagePath: string
  closeHomeWidget(id: string): void
  fetchCommerceContent(
    pageType: string,
    externalId: string,
    breadcrumb?: string[],
    filterRulesLocaleOverride?: string | null,
    previewDate?: string | null
  ): Promise<IPlacement[]>
  fetchPageContent: typeof cmsService.fetchPageContent
}

export const CmsContext = createContext<CmsContextProps>({
  bannerPromoCartPlacements: [],
  cartPlacements: null,
  cartTooltipDisc: null,
  emptyCartPlacements: null,
  footerPlacements: [],
  headerExtraCSS: [],
  headerExtraJS: [],
  headerLinks: [],
  headerPlacements: [],
  headerPromoBars: [],
  homePlacements: null,
  homeWidgets: null,
  legalNavItem: { title: '', menuItems: [] },
  newsletterBannerText: '',
  pageExtraCSS: [],
  pageExtraJS: [],
  privacyPagePath: '',
  previewParams: {},
  serviceNavItem: { title: '', menuItems: [] },
  termsOfUsePagePath: '',
  closeHomeWidget: () => {
    return
  },
  async fetchCommerceContent(...args) {
    return (await cmsService.fetchCommerceContent.apply(cmsService, args)).commercePlacements
  },
  fetchPageContent(...args) {
    return cmsService.fetchPageContent(...args)
  },
})

const useCms = () => {
  return React.useContext(CmsContext)
}

const CmsProvider: FC = (props) => {
  const { pageType } = usePageType()
  const locationSearch = useLocation().search
  const { isEnabled, previewDate, filterRulesLocaleOverride } = useSelector(cmsPreviewSelector)

  // used for content requests of 'CMExternalPage' preview
  const previewParams = useMemo<CmsPreviewParams>(() => {
    const urlSearchParams = new URLSearchParams(locationSearch)
    const resultParams = {} as CmsPreviewParams

    for (const key of Object.values(cmsService.urlParams)) {
      const urlParamValue = urlSearchParams.get(key)

      if (urlParamValue) {
        resultParams[key] = urlParamValue
      }
    }

    return resultParams
  }, [locationSearch, previewDate])

  const [cartPlacements, setCartPlacements] = useState<CmsContextProps['cartPlacements']>(null)
  const [cartTooltipDisc, setCartTooltipDisc] = useState<CmsContextProps['cartTooltipDisc']>(null)
  const [emptyCartPlacements, setEmptyCartPlacements] =
    useState<CmsContextProps['emptyCartPlacements']>(null)
  const [headerPlacements, setHeaderPlacements] = useState<CmsContextProps['headerPlacements']>([])
  const [footerPlacements, setFooterPlacements] = useState<CmsContextProps['footerPlacements']>([])
  const [legalNavItem, setLegalNavItem] = useState<CmsContextProps['legalNavItem']>({
    title: '',
    menuItems: [],
  })
  const [serviceNavItem, setServiceNavItem] = useState<CmsContextProps['serviceNavItem']>({
    title: '',
    menuItems: [],
  })
  const [privacyPagePath, setPrivacyPagePath] = useState<string>('')
  const [termsOfUsePagePath, setTermsOfUsePagePath] = useState<string>('')
  const [newsletterBannerText, setNewsletterBannerText] = useState<string>('')
  const [bannerPromoCartPlacements, setBannerPromoCartPlacements] = useState<ILXTeaser[]>([])
  const [headerPromoBars, setHeaderPromoBars] = useState<PromoBar[]>([])
  const [homePlacements, setHomePlacements] = useState<CmsContextProps['homePlacements']>(null)
  const [homeWidgets, setHomeWidgets] = useState<CmsContextProps['homeWidgets']>(null)
  const [bannerPromoCmsData, setBannerPromoCmsData] = useState<IPlacement[]>([])
  const [headerLinks, setHeaderLinks] = useState<HeaderNavigationItem[]>([])

  const [headerExtraCSS, setHeaderExtraCSS] = useState<string[]>([])
  const [headerExtraJS, setHeaderExtraJS] = useState<string[]>([])

  const [pageExtraCSS, setPageExtraCSS] = useState<string[]>([])
  const [pageExtraJS, setPageExtraJS] = useState<string[]>([])

  const dynamicContentBanners = useDynamicCmsContent(bannerPromoCmsData)

  const fetchNavigation = async () => {
    const navigationCmsRes = await cmsService.fetchNavigation(previewParams)
    const navigation: Navigation = navigationCmsRes.navigation

    setHeaderLinks(parseMenuItems(navigation || {}))
  }

  const fetchHeaderPlacements = async () => {
    const headerCmsRes = await cmsService.fetchHeader(previewParams)

    setHeaderExtraCSS(headerCmsRes.extraCSS)
    setHeaderExtraJS(headerCmsRes.extraJS)
    setHeaderPlacements(headerCmsRes.headerPlacements)
  }

  const fetchFooterPlacements = async () => {
    const pageFooterCmsRes = await cmsService.fetchFooter(previewParams)
    const { footerPlacements } = pageFooterCmsRes || []
    const footerNavigation = cmsService.getFooterNavigation(footerPlacements)

    const navItems = cmsService.channelItemsToFooterLinkObject(footerNavigation)
    const legalNavItem = navItems && navItems[1]

    const privacyPath = legalNavItem.menuItems[0]?.url || ''
    const termsOfUsePath = legalNavItem.menuItems[1]?.url || ''

    const newsletterTextItem = footerPlacements.find(({ name }) => name === 'footer_newsletter')
      ?.items[0]?.teaserTitle4

    setFooterPlacements(footerPlacements)
    setLegalNavItem(legalNavItem)
    setServiceNavItem(navItems[0])
    setPrivacyPagePath(privacyPath)
    setTermsOfUsePagePath(termsOfUsePath)
    setNewsletterBannerText(newsletterTextItem)
  }

  const closeHomeWidget = useCallback(
    (id: string) => {
      if (!homeWidgets) return

      setHomeWidgets(homeWidgets.filter((w) => id !== w.id))
    },
    [homeWidgets?.length]
  )

  const fetchPageContent = useCallback<typeof cmsService.fetchPageContent>(
    async (pageType, params) => {
      const pageContentResponse = await cmsService.fetchPageContent(pageType, {
        ...params,
        filterRulesLocaleOverride: isEnabled
          ? filterRulesLocaleOverride
          : previewParams.filterRulesLocale,
        previewDate: isEnabled ? previewDate : previewParams.previewDate,
      })

      const { contentPlacements = [], extraCSS, extraJS } = pageContentResponse

      const headerPromoBarPlacementItems = contentPlacements.find(
        (p) => p.name === 'header_promo_bar'
      )?.items

      if (headerPromoBarPlacementItems) {
        const promoBarItems: ILXTeaser[] = isLXTeaser(headerPromoBarPlacementItems[0])
          ? (headerPromoBarPlacementItems as ILXTeaser[])
          : isCMCollection(headerPromoBarPlacementItems[0])
          ? (headerPromoBarPlacementItems[0].teasableItems as ILXTeaser[])
          : []

        if (Array.isArray(promoBarItems)) {
          setHeaderPromoBars(
            promoBarItems.map(
              ({
                teaserCountdownStart,
                teaserCountdownUntil,
                teaserHideExpiredCountdown,
                teaserLXCallToActionSettings,
                teaserText1,
                teaserText2,
              }) => ({
                ctaSettings: teaserLXCallToActionSettings || [],
                textContent: teaserText1 || teaserText2 || '',
                teaserCountdownStart,
                teaserCountdownUntil,
                teaserHideExpiredCountdown,
              })
            )
          )
        }
      }

      setPageExtraCSS(extraCSS)
      setPageExtraJS(extraJS)

      return pageContentResponse
    },
    [
      isEnabled,
      filterRulesLocaleOverride,
      previewDate,
      previewParams.filterRulesLocale,
      previewParams.previewDate,
    ]
  )

  const fetchCartPlacements = async () => {
    try {
      const cmsCartPLacementsData = await cmsService.fetchCommerceContent('hcl', 'cart', [
        cmsService.breadcrumbsRootKey,
      ])
      const cmsCheckoutRawBannerData = cmsCartPLacementsData?.commercePlacements
      const cmsCheckoutData = (
        cmsCheckoutRawBannerData?.filter(
          (item) => item.name === 'Cart_Checkout_benefit_area'
        ) as IPlacement<ICMCollection<ILXTeaser>>[]
      )
        .find((teaser) => !!teaser)
        ?.items.find((item) => !!item)?.teasableItems

      const cmsCheckoutDataDisclaimer = cmsCheckoutRawBannerData?.filter(
        (item) => item.name === 'Cart_Checkout_disclaimer_tooltip'
      ) as IPlacement<ILXTeaser>[]

      const cmsEmptyCartData: ILXTeaser | null =
        (
          cmsCheckoutRawBannerData?.filter(
            (item) => item.name === 'Cart_Checkout_empty_cart_banner'
          ) as IPlacement<ILXTeaser>[]
        )
          .find((teaser) => !!teaser)
          ?.items.find((item) => !!item) || null

      setBannerPromoCmsData(cmsCheckoutRawBannerData)
      setCartPlacements(cmsCheckoutData || [])
      setCartTooltipDisc(cmsCheckoutDataDisclaimer || [])
      setEmptyCartPlacements(cmsEmptyCartData)
    } catch (error) {
      setCartTooltipDisc(null)
      setCartPlacements(null)
      setEmptyCartPlacements(null)
    }
  }

  const onInit = async () => {
    fetchNavigation()
    fetchHeaderPlacements()
    fetchFooterPlacements()
  }

  const fetchCommerceContent = useCallback(
    async (
      pageType: string,
      externalId: string,
      breadcrumb?: string[],
      filterRulesLocaleOverride?: string,
      previewDate?: string
    ) => {
      const { commercePlacements, extraCSS, extraJS } = await cmsService.fetchCommerceContent(
        pageType,
        externalId,
        breadcrumb,
        filterRulesLocaleOverride,
        previewDate
      )

      setPageExtraCSS(pageExtraCSS.concat(extraCSS))
      setPageExtraJS(pageExtraJS.concat(extraJS))

      return commercePlacements
    },
    [pageExtraCSS, pageExtraJS]
  )

  useEffect(() => {
    onInit()
  }, [])

  useEffect(() => {
    const cmsBannerPromoCartData = dynamicContentBanners
      ?.filter((item) => item.name === 'Cart_Checkout_promo_banner')
      .find((teaser) => !!teaser)?.items

    if (cmsBannerPromoCartData) {
      setBannerPromoCartPlacements(cmsBannerPromoCartData as ILXTeaser[])
    }
  }, [dynamicContentBanners])

  useEffect(() => {
    const { cart, checkout, home, plp, pdp } = cmsService.pageTypes

    if ((pageType === cart || pageType === checkout) && !Array.isArray(cartPlacements)) {
      fetchCartPlacements()
    }

    if ((pageType === home || pageType === plp || pageType === pdp) && !Array.isArray(homePlacements)) {
      fetchPageContent(home).then(({ contentPlacements }) => {
        const newHomePlacements: IPlacement[] = []
        const newHomeWidgets: HomeWidgetProps[] = []
        const homePageWidgetNamesArray = Object.values(cmsService.homePageWidgetNames)

        for (const placement of contentPlacements) {
          if (homePageWidgetNamesArray.includes(placement.name)) {
            const teaser = (placement as IPlacement<ILXTeaser>).items[0]
            if (!teaser) continue

            const { id, teaserText1, teaserIcon, media, teaserLXCallToActionSettings } = teaser

            newHomeWidgets.push({
              id,
              placementName: placement.name,
              title: sanitizeHtml(teaserText1),
              icon: teaserIcon,
              backgroundImageMedia: media[0],
              itemsColor: cmsService.getTextColorByTeaserOverlayStyle(teaser.teaserOverlay1Style),
              items: teaserLXCallToActionSettings.map((i) => ({
                label: i.callToActionText || '',
                path: (i.target as ICMChannel).formattedUrl,
                type: (i.target as ICMChannel).type
              })),
            })
          } else {
            newHomePlacements.push(placement)
          }
        }

        setHomePlacements(newHomePlacements)
        setHomeWidgets(newHomeWidgets.length ? newHomeWidgets : null)
      })
    }
  }, [pageType])

  return (
    <CmsContext.Provider
      value={{
        bannerPromoCartPlacements,
        cartPlacements,
        cartTooltipDisc,
        emptyCartPlacements,
        footerPlacements,
        headerExtraCSS,
        headerExtraJS,
        headerLinks,
        headerPlacements,
        headerPromoBars,
        homePlacements,
        homeWidgets,
        legalNavItem,
        newsletterBannerText,
        pageExtraJS,
        pageExtraCSS,
        previewParams,
        privacyPagePath,
        serviceNavItem,
        termsOfUsePagePath,
        closeHomeWidget,
        fetchCommerceContent,
        fetchPageContent,
      }}>
      {props.children}
    </CmsContext.Provider>
  )
}

export { CmsProvider, useCms }
