import Axios, { Canceler } from 'axios'
import React, { lazy, useEffect, useMemo, useState } from 'react'
import getDisplayName from 'react-display-name'
import { CLUSTERING_PROFILE, PROFILE_NAME_PARAM } from '../../../constants/common'
//Standard libraries
import { useDispatch, useSelector } from 'react-redux'
import { IPlacement } from '../../../types/teaser'
import Log from '../../../services/Log'
import { PRODUCT_DISPLAY } from '../../../constants/marketing'
import { ProductBase, ProductBreadcrumb } from '../../../types/product'
import { useHistory} from 'react-router'
//Redux
import { TRIGGER_MARKETING_ACTION } from '../../../redux/actions/marketingEvent'
import { currentContractIdSelector } from '../../../redux/selectors/contract'
import ProductService from '../../../services/ProductService'
import { updateUrl } from '../../../utils/url'
import { useDynamicCmsContent } from '../../../foundation/hooks/useDynamicCmsContent'
//Foundation libraries
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useCms } from '../../providers/CmsProvider'
import categoriesService from '../../../foundation/apis/search/categories.service'
import { ISeo } from '../../../types/seo'
import cmsService from '../../../foundation/apis/cms/cms.service'
import { currentPageSeoDataSelector } from '../../../redux/selectors/seo'
import { useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { sendPdpEvent } from '../../../foundation/analytics/tealium/lib'

const ProductDetails = lazy(() => import('../../pages/product/ProductDetails'))

export const getCategoryIdentifier = (productDetails: ProductBase[]) => {
  // it tasks the parentCatalogGroupID and split flat destroy it
  // in order to take the last category id found

  let categoryIdentifier: string = ''
  const parentCatalogGroupID = productDetails[0].parentCatalogGroupID
  let ids: string[]
  if (Array.isArray(parentCatalogGroupID)) {
    ids = parentCatalogGroupID.map((el) => el.split('/')).flat()
  } else {
    ids = parentCatalogGroupID?.split('/')
  }
  if (ids && ids.length > 0) {
    categoryIdentifier = ids[ids.length - 1]
  }
  return categoryIdentifier
}

const fetchCategoriesData = async (payload) => {
  const productDetailsRes = await ProductService.get(payload)
  const categoryEntries = productDetailsRes.breadCrumbTrailEntryView!.map((entry) => entry.value)
  const categoriesRes = await categoriesService.getV2CategoryResourcesUsingGET({
    id: categoryEntries,
    storeId: payload.storeID,
  })

  return {
    productData: productDetailsRes,
    categoriesData: categoriesRes?.data!,
  }
}

/**
 * Product page component
 * @param props
 */
const Product: ISeo['component'] = ({seoData}) => {
  const { page } = seoData
  const widgetName = getDisplayName(Product)
  let cancels: Canceler[] = []
  const CancelToken = Axios.CancelToken
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const { basePath } = useStoreIdentity()
  const storeIdentifier: string = mySite ? mySite.storeID : ''
  const catalogIdentifier: string = mySite ? mySite.catalogID : ''
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : ''
  const { previewParams: cmsPreviewParams, fetchCommerceContent } = useCms()
  const [isVmSupported, setIsVmSupported] = useState('')
  const { ...analyticsDataForPdp } = useAnalyticsData('pdp')
  const contract = useSelector(currentContractIdSelector)
  const [pdpData, setPdpData] = useState<ProductBase>()
  const [partNumber, setPartNumber] = useState<string>(page.externalContext.identifier)
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingCommercePlacements, setLoadingCommercePlacements] = useState<boolean>(false)
  const [cmsPlacements, setCmsPlacements] = useState<IPlacement[]>([])
  const [categoryIdentifier, setCategoryIdentifier] = useState<string>('')
  const [breadcrumbs, setBreadcrumbs] = useState<ProductBreadcrumb[]>([])
  const dynamicContentBanners = useDynamicCmsContent(cmsPlacements, pdpData)
  const categoryId = seoData?.tokenValue || ''
  const history = useHistory()
  const  urlProductNumber = window.location.pathname.split('-').pop()

  const defaultEngLangPageSection = useMemo(() => {
    const url = seoData?.alternateHrefs?.find((href) => href.key === 'en-us')
    return url?.value.split('/')?.[0]
  },[seoData])

  const payloadBase = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }
  /**
   *Get product information from part number
   */
  const getProductDetails = (partNumber: string) => {
    setLoading(true)

    const parameters = {
      storeId: storeIdentifier,
      partNumber,
      contractId: contract,
      catalogId: catalogIdentifier,
      [PROFILE_NAME_PARAM]: CLUSTERING_PROFILE,
      ...payloadBase,
    }

    ProductService.get(parameters)
      .then((productData) => {
        const productDetails = productData.contents

        if (productDetails && productDetails.length > 0) {
          setCategoryIdentifier(getCategoryIdentifier(productDetails))


          let productDetailsData = Array.isArray(productDetails)
            ? productDetails[0]
            : productDetails

          setPdpData(productDetailsData)

          const newPathname = `${basePath}${productDetails[0].seo.href}`

          if (window.location.pathname !== newPathname) {
            updateUrl(newPathname, undefined)
          }
        }

        setLoading(false)
      })
      .catch((e) => {
        Log.error('Could not retrieve product details page informarion', e)
      })
  }

  const onClusterProductClick = (p: ProductBase) => {
    //TODO figure out if I should send 'p' or 'p.items[0]'

    const partNumberToSet = p.items?.[0]?.partNumber

    if (partNumberToSet) {
      setPartNumber(partNumberToSet)
    }
  }
useEffect(()=>{// resolve routing issue https://luxotticaretail.atlassian.net/browse/ARNETTE-2667
  if (partNumber !== urlProductNumber && urlProductNumber){
    setPartNumber(urlProductNumber)
  }
},[urlProductNumber])
  useEffect(() => {
    if (mySite !== null && mySite !== undefined) {
      getProductDetails(partNumber)

      const mtkParam = {
        productId: page.tokenValue,
        DM_ReqCmd: PRODUCT_DISPLAY,
        ...payloadBase,
      }

      dispatch(TRIGGER_MARKETING_ACTION(mtkParam))
    }

    return () => {
      cancels.forEach((cancel) => cancel())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, contract, partNumber])

  useEffect(() => {
    let initialLocationPathname: string | null = history.location.pathname
    const unlisten = history.listen((location) => {
      if (initialLocationPathname === location.pathname && !location.search) {
        setPartNumber(page.externalContext.identifier)
        getProductDetails(page.externalContext.identifier)
      }
    })

    return () => {
      initialLocationPathname = null
      unlisten()
    }
  }, [categoryId, pdpData, page])

  useEffect(() => {
    if (
      !(
        mySite &&
        pdpData &&
        breadcrumbs?.length &&
        dynamicContentBanners &&
        dynamicContentBanners.length === 0
      )
    ) {
      return
    }

    const productId = pdpData.cluster?.[0]?.name

    if (!productId) {
      return
    }

    const categoryiesIdentifiers = breadcrumbs
      .map((breadcrumb) => breadcrumb.categoryIdentifier)
      .join(',')

    const breadCrumbsLabels = [cmsService.breadcrumbsRootKey, categoryiesIdentifiers]

    setLoadingCommercePlacements(true)

    fetchCommerceContent(
      'pdp',
      productId,
      breadCrumbsLabels,
      cmsPreviewParams.filterRulesLocale,
      cmsPreviewParams.previewDate
    )
      .then((commercePlacements) => {
        if (commercePlacements) {
          setCmsPlacements(commercePlacements)
        }
      })
      .finally(() => {
        setLoadingCommercePlacements(false)
      })
  }, [breadcrumbs, mySite, pdpData])

  useEffect(() => {
    if (pdpData?.name && categoryIdentifier !== '') {
      const parameters = {
        categoryId: categoryIdentifier,
        contractId: contract,
        currency: defaultCurrencyID,
        storeId: storeIdentifier,
        productName: pdpData.name,
        ...payloadBase,
      }

      fetchCategoriesData(parameters).then(({ categoriesData, productData }) => {
        const breadcrumbs =
          productData?.breadCrumbTrailEntryView?.map((breadcrumbEntry) => {
            let categoryIdentifier = categoriesData?.contents?.find(
              (content) => content.description === breadcrumbEntry.label
            )?.identifier
            breadcrumbEntry.categoryIdentifier = categoryIdentifier
            return breadcrumbEntry
          }) || []

        if (parameters.productName !== undefined && breadcrumbs.length > 0) {
          breadcrumbs.push({ label: parameters.productName } as ProductBreadcrumb)
        }

        setBreadcrumbs(breadcrumbs)
      })
    }
  }, [categoryIdentifier, pdpData?.name])

  const supportedVm = (vm: string) => {
    setIsVmSupported(vm)
  }

  useEffect(() => {
    if (
      pdpData !== undefined &&
      pdpData !== null &&
      seoData
    ) {
      sendPdpEvent({
        common: {
          ...analyticsDataForPdp
        },
        products: [pdpData],
        pageSection1: defaultEngLangPageSection || '',
        pageSection2: seoData?.tokenExternalValue || '',
        vmIsSupported: isVmSupported,
      })
    }
  }, [pdpData])

  // TODO if breadcrumb will be need, just copy content from topMarketingSection
  return (
    <div id={page.externalContext.identifier}>
      <ProductDetails
        breadcrumbs={breadcrumbs}
        onClusterProductClick={onClusterProductClick}
        partNumber={partNumber}
        pdpData={pdpData}
        storeId={storeIdentifier}
        loading={loading}
        loadingCommercePlacements={loadingCommercePlacements}
        teasers={dynamicContentBanners}
        supportedVm={supportedVm}
      />
    </div>
  )
}

export default Product
