import { Link, LinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ButtonStyle } from './index'
import React from 'react'
import { RoundButtonProps } from '../../../types/roundbutton'

export interface LinkAsButtonProps extends RoundButtonProps {
  children?: React.ReactNode
  dataElementId?: string
  to: LinkProps['to']
  external?: boolean
}

export const LinkAsButtonStyle = css`
  ${ButtonStyle}
`
export const LinkAsButton = styled(Link)`
  ${LinkAsButtonStyle}
`

export const ExternalLinkAsButton = styled('a')`
  ${LinkAsButtonStyle}
`

export const StyledLinkAsButton = React.forwardRef((props: LinkAsButtonProps, ref: any) => {
  const {
    to,
    variant,
    'aria-label': ariaLabel,
    className,
    children,
    disabled,
    dataElementId,
    themeMode,
    external,
  } = props

  return external ? (
    <ExternalLinkAsButton
      ref={ref}
      href={to as string}
      target="_blank"
      variant={variant}
      aria-label={ariaLabel}
      className={className}
      disabled={disabled}
      themeMode={themeMode}
      data-element-id={dataElementId}>
      {children}
    </ExternalLinkAsButton>
  ) : (
    <LinkAsButton
      ref={ref}
      to={to}
      variant={variant}
      aria-label={ariaLabel}
      className={className}
      disabled={disabled}
      themeMode={themeMode}
      data-element-id={dataElementId}>
      {children}
    </LinkAsButton>
  )
})
