import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

const ComponentWrapper = (props: any) => <Grid {...props} />

const StyledGrid = styled(ComponentWrapper)`
  line-height: 1.5;

  .footer-menu {
    padding-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .sustainable-container {
      order: 1;
    }
    .personaldata-container {
      order: 0;
    }
    .select-country-wrapper {
      order: 2;
    }
  }

  &.sidebar {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      padding: 0;
    }
  }
  &.mbl-item {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-top: 1rem;
    }
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    &.checkout-actions {
      flex-direction: column;

      .MuiGrid-item {
        .MuiButtonBase-root {
          width: 100%;
        }
      }
    }
  }
`

export default StyledGrid
