import React, { lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box } from '@material-ui/core'

import { sendMyAccountEvent } from '../../../foundation/analytics/tealium/lib'
import { StyledGrid, StyledTypography } from '../../StyledUI'
import { WelcomeUserSection } from './WelcomeUserSection'
import { useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { userEmailSelector, userIdSelector } from '../../../redux/selectors/user'

const PersonalInformationSection = lazy(() => import('./PersonalInformationSection'))

const StyledBoxTitle = styled(Box)`
  ${({ theme }) => `
  padding: ${theme.spacing(1.5)}px ${theme.spacing(2.25)}px;
  border: 1px solid ${theme.palette.text.primary};
  border-radius: 0;
  background: none;
  box-shadow: none;
`}
`

const StyledBoxContent = styled(StyledBoxTitle)`
  ${({ theme }) => `
  border-top: none;
  margin-bottom: 2rem;
  padding: ${theme.spacing(2)}px;
`}
`

const StyledTitle = styled(StyledTypography)`
  margin-bottom: 0.5rem;
`

function AccountSummary() {
  const { t } = useTranslation()
  const analyticsDataForMyAccount = useAnalyticsData('profile')
  const email = useSelector(userEmailSelector)
  const userId = useSelector(userIdSelector)

  const title = t('AccountSummary.Title')

  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsDataForMyAccount,
      email,
      userId,
    })
  }, [])

  return (
    <div>
      <StyledBoxTitle>
        <StyledTitle component="h1" variant="body1">
          {title}
        </StyledTitle>
        <WelcomeUserSection />
      </StyledBoxTitle>
      <StyledGrid>
        <StyledBoxContent className="vertical-padding-2 horizontal-padding-2">
          <StyledGrid container spacing={2}>
            <StyledGrid item xs={12} md={6} lg={7}>
              <PersonalInformationSection />
            </StyledGrid>
          </StyledGrid>
        </StyledBoxContent>
      </StyledGrid>
    </div>
  )
}

export default AccountSummary
