import {
  Container,
  Content,
  EmailField,
  Footer,
  Header,
  Image,
  Result,
  SubscribeButton,
  Title,
  Description,
} from './styled-components'
import { NEWSLETTER_DRAWER_TOGGLE_ACTION } from '../../../redux/actions/ui'
import React, { FC, useState } from 'react'
import { StyledIconButton } from '../../StyledUI'
import { CloseIcon } from '../../StyledUI/Icons'
import Log from '../../../services/Log'
import { NewsletterService } from '../../../foundation/apis/newsletter/newsletter.service'
import isEmpty from 'lodash/isEmpty'
import { sendNewsletterSubscriptionEvent } from '../../../foundation/analytics/tealium/lib'
import { useDispatch, useSelector } from 'react-redux'
import { useSite } from '../../../foundation/hooks/useSite'
import { Trans, useTranslation } from 'react-i18next'
import theme from '../../../themes'
import { useCms } from '../../providers/CmsProvider'
import { CountryBaseLink } from '../../StyledUI/StyledLink/StyledLink'
import { getCookieByName } from '../../../utils/cookie'
import { loginStatusSelector } from '../../../redux/selectors/user'
import {  earlyAccessContentEnabledSelector } from '../../../redux/selectors/site'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
interface NewsletterDrawerContentProps {
  imageUrl: string
  imageDescription: string
  title: string
}

type ResultObject = {
  error?: boolean
  text?: string
}

const NewsletterImage: FC<{ src: string }> = (props) => {
  const [src, setSrc] = useState<string>(props.src)
  const handleError = () => setSrc(process.env.PUBLIC_URL + '/images/common/404.png')

  return <Image {...props} src={src} onError={handleError} />
}

const NewsletterDrawerContent: FC<NewsletterDrawerContentProps> = ({ imageUrl, imageDescription, title }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const { privacyPagePath } = useCms()
  const [error, setError] = useState(false)
  const [result, setResult] = useState<ResultObject>({})
  const [email, setEmail] = useState('')

  const onChange = (e) => {
    setError(false)
    setResult({})
    setEmail(e.target.value)
  }
 
  const { basePath } = useStoreIdentity()
  
  const earlyAccessContentEnabled = getCookieByName('eaccess') || 'false' //da usare al posto della xStoreConf
  const userLoggedIn = useSelector(loginStatusSelector)
  //const earlyAccessContentEnabled = useSelector(earlyAccessContentEnabledSelector) // per test

  const userLogged = userLoggedIn.toString()

  const storeConfMap = { earlyAccessContentEnabled, userLogged }
  
  const subscribe = async (e) => {
    const emailRegExp = new RegExp(
      // eslint-disable-next-line
      '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])'
    )
    if (emailRegExp.test(email)) {
      try {
        const data = await NewsletterService.subscribeToNewsletter({
          email,
          newsletter_id: mySite.newsletterType,
          from: 'footer',
        })
        if (data.code === '200') {
         if (storeConfMap.earlyAccessContentEnabled==='false'){
           setResult({
             error: false,
             text: t('Newsletter.Success'),
            })
            sendNewsletterSubscriptionEvent(email)
          } else {
            setResult({
              error: false,
              text: t('Newsletter.Success'),
             })
             sendNewsletterSubscriptionEvent(email)
             window.location.href = `${basePath}/?promo=eaccess`
          }
        } else if (data.code === '409') {
          if (storeConfMap.earlyAccessContentEnabled==='false'){
          setResult({
            error: true,
            text: t('Newsletter.DuplicateError'),
          })
          sendNewsletterSubscriptionEvent(email)
          } else {
          setResult({
            error: false,
            text: t('Newsletter.Success'),
           })
           sendNewsletterSubscriptionEvent(email)
           window.location.href = `${basePath}/?promo=eaccess`
        }
        } else
          setResult({
            error: true,
            text: t('Newsletter.GenericError'),
          })
      } catch (e) {
        setResult({
          error: true,
          text: t('Newsletter.GenericError'),
        })
        Log.error(e)
      }
    } else {
      setError(true)
    }
  }
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <StyledIconButton
          data-analytics_available_call="0"
          onClick={() => dispatch(NEWSLETTER_DRAWER_TOGGLE_ACTION(false))}>
          <CloseIcon htmlColor={theme.palette.text.light} />
        </StyledIconButton>
      </Header>
      <Content>
        <Description dangerouslySetInnerHTML={{ __html: imageDescription }} />
        {imageUrl && (
          <NewsletterImage src={imageUrl} />
        )}
        <EmailField
          error={error}
          helperText={error ? t('Newsletter.InputError') : undefined}
          variant="outlined"
          margin="normal"
          fullWidth
          name="email"
          label={t('productDetail.notificationAvailable.InputPlaceholder')}
          type="email"
          id="email"
          value={email}
          onChange={onChange}
        />
        <SubscribeButton
          data-element-id={'X_X_Footer_NewsletterSignup'}
          disabled={!email}
          onClick={subscribe}>
          {t('Newsletter.Subscribe')}
        </SubscribeButton>
        {!isEmpty(result) && <Result error={result.error}>{result.text}</Result>}
      </Content>
      <Footer>
        <Trans i18nKey="Newsletter.FooterText">
          <CountryBaseLink variant="primaryUnderlined" to={privacyPagePath} />
        </Trans>
      </Footer>
    </Container>
  )
}

export default NewsletterDrawerContent
