import React, { useEffect, useState } from 'react'
import { ILXTeaser, IPlacementItem } from '../../../../types/teaser'
import styled from 'styled-components'
import { teaserOverlaySettingsToCSS } from '../../../../utils/placements'
import CallToAction from '../call-to-action'
import { StyledTypography } from '../../../StyledUI'
import CMSCommonMedia from '../cms-common-media'
import useMediaByDeviceType from '../../../../hooks/useMediaByDeviceType'
import { HTMLContainer } from '../HTMLContainer'

const StyledContainer = styled.div`
  ${({ theme }) => `
  position: relative;
  width: 100%;
  min-height: 240px;
  line-height: 0;

  ${theme.breakpoints.up('sm')} {
    height: 300px;
    min-height: 300px;
  }
`}
`

const StyledOverlap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100%;

  padding: ${({ theme }) => theme.spacing(1)}px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: ${({ theme }) => theme.spacing(0.5)}px;
  }

  display: flex;
  flex-direction: column;
`

const StyledContent = styled.div<{ overlaySettings: ILXTeaser['teaserOverlay1Settings'] }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ overlaySettings }) => teaserOverlaySettingsToCSS(overlaySettings)}
`

const StyledHeadline = styled(StyledTypography)`
  line-height: 1;
  letter-spacing: normal;

  color: white;

  font-size: 32px;
  font-weight: bold;
  white-space: pre-wrap;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 32px;
    font-weight: normal;
  }
`

const StyledTeaserTextDiv = styled.div`
  color: white;
`

const StyledCTAContainer = styled.div<{ overlayTextAlign: string }>`
  display: flex;
  margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
  justify-content: ${({ overlayTextAlign }) => overlayTextAlign};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    & > * {
      &:not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing(1)}px;
      }
    }
  }
`

interface IProps {
  teaser: IPlacementItem
}
const SearchBanner: React.FC<IProps> = ({ teaser }) => {
  const media = useMediaByDeviceType(teaser.media)

  const [teaserText, setTeaserText] = useState<string>(teaser?.teaserText1)

  useEffect(() => {
    if (teaserText) {
      const stringToSearch = 'data-show="new"'
      const stringTargetBlank = 'target="_blank"'
      const re = new RegExp(stringToSearch, 'g')

      if (teaserText.toLowerCase().indexOf(stringToSearch) >= 0) {
        const newTeaserText = teaserText.replace(re, stringTargetBlank)
        setTeaserText(newTeaserText)
      }
    }
  }, [teaser])

  if (teaser.type !== 'LXTeaser') {
    return null
  }

  return (
    <StyledContainer>
      <CMSCommonMedia type="FULL_WIDTH_BANNER" media={media} />
      <StyledOverlap>
        <StyledContent overlaySettings={teaser.teaserOverlay1Settings}>
          <StyledHeadline
            className="rn-cms-content__fwb--title"
            variant={teaser.promoteToH1 ? 'h1' : 'h2'}>
            {teaser.teaserTitle1}
          </StyledHeadline>
          <HTMLContainer textAlign={teaser.teaserOverlay1TextAlign.replace('text-', '')}>
            <StyledTeaserTextDiv dangerouslySetInnerHTML={{ __html: teaserText }} />
          </HTMLContainer>
          <StyledCTAContainer overlayTextAlign={teaser.teaserOverlay1TextAlign}>
            {teaser.teaserLXCallToActionSettings.map((cta, index) => (
              <CallToAction
                dataElementId={'X_X_1Placement_Banner1_CTA'}
                key={`cms-content__fwb--cta-${index}`}
                {...cta}
              />
            ))}
          </StyledCTAContainer>
        </StyledContent>
      </StyledOverlap>
    </StyledContainer>
  )
}

export default SearchBanner
