//Custom libraries
import * as ROUTES from '../constants/routes'

//Standard libraries
import { lazy, memo } from 'react'

import { RouteConfig } from 'react-router-config'

//Common pages
const SEO = memo(lazy(() => import('../foundation/seo/SEO')))
const Cart = lazy(() => import('../components/pages/cart/Cart'))
const Home = lazy(() => import('../components/pages/home'))

//component
const SignIn = memo(lazy(() => import('../components/pages/sign-in/SignIn')))
const SignUp = memo(lazy(() => import('../components/pages/sign-up/SignUp')))
const ContactUs = lazy(() => import('../components/pages/contact-us/ContactUs'))
const ForgotPassword =
  lazy(() => import('../components/widgets/forgot-password/ForgotPassword'))
const AddressBook =
  lazy(() => import('../components/pages/address-book/AddressBook'))
const ChangePassword =
  lazy(() => import('../components/pages/change-password/ChangePassword'))
const SearchResults =
  lazy(() => import('../components/pages/search-results/SearchResults'))

//checkout
const Checkout = lazy(() => import('../components/pages/checkout/Checkout'))
const Shipping = lazy(() => import('../components/pages/checkout/shipping/Shipping'))
const Billing = lazy(() => import('../components/pages/checkout/payment/Payment'))
const OrderConfirmation =
  lazy(() => import('../components/pages/order-confirmation/OrderConfirmation'))

//Emerald pages
const Account = lazy(() => import('../components/pages/account/Account'))
const Wishlist = lazy(() => import('../components/pages/wishlist/Wishlist'))

const CmsPage = lazy(() => import('../components/pages/cms-pages/CmsPages'))

export const CheckoutRouteConfig = [
  {
    key: ROUTES.CHECKOUT_SHIPPING,
    path: ROUTES.CHECKOUT_SHIPPING,
    component: Shipping,
  },
  {
    key: ROUTES.CHECKOUT_PAYMENT,
    path: ROUTES.CHECKOUT_PAYMENT,
    component: Billing,
  },
  {
    key: ROUTES.ORDER_CONFIRMATION,
    path: ROUTES.ORDER_CONFIRMATION,
    component: OrderConfirmation,
  },
]

const routeConfig: RouteConfig[] = [
  {
    key: ROUTES.HOME,
    path: ROUTES.HOME,
    exact: true,
    component: Home,
  },
  {
    key: ROUTES.CART,
    path: ROUTES.CART,
    exact: true,
    component: Cart,
  },
  {
    key: ROUTES.SIGNIN,
    path: ROUTES.SIGNIN,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: SignIn,
  },
  {
    key: ROUTES.SIGNUP,
    path: ROUTES.SIGNUP,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: SignUp,
  },
  {
    key: ROUTES.FORGOT_PASSWORD,
    path: ROUTES.FORGOT_PASSWORD,
    exact: true,
    isProtected: ROUTES.GENERIC_PROTECTED,
    component: ForgotPassword,
  },
  {
    key: ROUTES.CHECKOUT,
    path: ROUTES.CHECKOUT,
    component: Checkout,
    routes: CheckoutRouteConfig,
  },
  {
    key: ROUTES.ACCOUNT,
    path: ROUTES.ACCOUNT,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: Account,
  },
  {
    key: ROUTES.WISHLIST,
    path: ROUTES.WISHLIST,
    exact: true,
    component: Wishlist,
  },
  {
    key: ROUTES.SEARCH,
    path: ROUTES.SEARCH,
    exact: true,
    component: SearchResults,
  },
  {
    key: ROUTES.ADDRESS_BOOK,
    path: ROUTES.ADDRESS_BOOK,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: AddressBook,
  },
  {
    key: ROUTES.CHANGE_PASSWORD,
    path: ROUTES.CHANGE_PASSWORD,
    exact: true,
    isProtected: ROUTES.REGISTER_PROTECTED,
    component: ChangePassword,
  },
  {
    key: ROUTES.CONTACT_US,
    path: ROUTES.CONTACT_US,
    exact: true,
    component: ContactUs,
  },
  {
    key: ROUTES.CMS_PAGE_ROUTE,
    path: ROUTES.CMS_PAGE_ROUTE,
    exact: true,
    component: CmsPage,
  },
  {
    key: 'other',
    path: '/:country/*',
    exact: true,
    component: SEO,
  },
]

export const ROUTE_CONFIG = {
  CFG: routeConfig,
}
