import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ILXTeaser, IPlacementPriority, TMedia } from '../../../../types/teaser'
import CMSCommonMedia from '../cms-common-media'
import CMSIcon from '../cms-icon'
import CallToAction from '../call-to-action'
import { StyledAnchor, StyledTypography } from '../../../StyledUI'
import cmsService from '../../../../foundation/apis/cms/cms.service'
import useMediaByDeviceType from '../../../../hooks/useMediaByDeviceType'
import usePlayerBanner from '../../../../hooks/useBannerPlayer'
import CmsVideoMedia, { CmsVideoMediaControllerWrapper } from '../video-media'
import { HTMLContainer } from '../HTMLContainer'

interface IProps {
  index?: number
  teaserIndex?: number
  teaser: ILXTeaser
  backgroundColor?: React.CSSProperties['backgroundColor']
}

const StyledContainer = styled('div')`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => `$${theme.spacing(1)}px`};
`

const StyledLogoPlaceholder = styled('div')`
  max-width: 100%;
`

const StyledTop = styled('div')`
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
`

const MediaWrapper = styled('div')`
  width: 100vw;
  aspect-ratio: 30 / 29;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`

const StyledBottom = styled('div') <{ backgroundColor?: React.CSSProperties['backgroundColor'] }>`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`};
  background-color: ${({ backgroundColor }) => backgroundColor};
`

const StyledHeadline = styled(StyledTypography) <{ media?: TMedia }>`
  width: 100%;
  line-height: 1;
  letter-spacing: normal;
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 4px;
  white-space: pre-wrap;

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ media, theme }) => media?.type === 'CMVideo' && `padding-bottom: ${theme.spacing(1)}px;`}
  }
`

const StyledCTAContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(1)}px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    & > * {
      &:not(:last-child) {
        margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
      }
    }
  }
`

const StyledTopSettings = styled.div`
  ${({ theme }) => `
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: ${theme.spacing(1)}px;
  padding-bottom: ${theme.spacing(1)}px;
  color: ${theme.palette.primary.contrastText};

  ${CmsVideoMediaControllerWrapper} {
    all: initial;
  }
`}
`

const PLPBox: React.FC<IProps & IPlacementPriority> = ({ index = 1, teaserIndex, teaser, backgroundColor, fetchpriority }) => {
  const media = useMediaByDeviceType(teaser.media)

  const video = media?.type === 'CMVideo' ? media : undefined
  const playerBanner = usePlayerBanner(video)

  const toLink = teaser.teaserLXCallToActionSettings?.[0]?.target.formattedUrl
  const bannerImageCommonProps = {
    'aria-label': `Placement_Banner1_IMG link. Teaser №${teaserIndex}`,
    'data-element-id': `X_X_${teaserIndex}Placement_Banner1_IMG`,
  }
  const teaserTextColor = cmsService.getTextColorByTeaserOverlayStyle(teaser.teaserOverlay1Style)

  const [countdownTimeLeft, setCoundownTimeLeft] = useState<string>('')

  useEffect(() => cmsService.initTeaserCountdownInterval(teaser, setCoundownTimeLeft), [])

  return (
    <StyledContainer>
      <StyledTop>
        {toLink ? (
          <StyledAnchor
            {...bannerImageCommonProps}
            to={toLink}
          >
            <MediaWrapper>
              <CMSCommonMedia
                fetchpriority={fetchpriority}
                type="PLP_BOX"
                media={media}
                playerBannerHook={playerBanner}
              />
            </MediaWrapper>
          </StyledAnchor>
        ) : (
          <MediaWrapper>
            <CMSCommonMedia
              fetchpriority={fetchpriority}
              type="PLP_BOX"
              media={media}
              playerBannerHook={playerBanner}
            />
          </MediaWrapper>
        )}
        <StyledTopSettings>
          <StyledLogoPlaceholder
            className="arn-cms-content__fwb--logo"
            {...cmsService.getMetaDataAttribute('[{"_":"properties.teaserIcon"}]')}>
            <CMSIcon teaserIcon={teaser.teaserIcon} color={'white'} />
          </StyledLogoPlaceholder>

          {countdownTimeLeft && (
            <StyledHeadline fontColor={teaserTextColor} variant={teaser.promoteToH1 ? 'h1' : 'h2'}>
              {countdownTimeLeft}
            </StyledHeadline>
          )}

          <StyledHeadline
            className="rn-cms-content__fwb--title"
            fontColor={teaserTextColor}
            media={media}
            variant={teaser.promoteToH1 ? 'h1' : 'h2'}
            {...cmsService.getMetaDataAttribute('[{"_":"properties.teaserTitle1"}]')}>
            {teaser?.teaserTitle1}
          </StyledHeadline>

          {video && (
            <CmsVideoMedia
              playerBanner={{
                isPlaying: playerBanner.isPlaying,
                muted: playerBanner.muted,
              }}
              toggleMute={() => playerBanner.setMuted((prev) => !prev)}
              togglePlay={() => playerBanner.setIsPlaying((prev) => !prev)}
            />
          )}
        </StyledTopSettings>
      </StyledTop>
      <StyledBottom backgroundColor={backgroundColor}>
        <HTMLContainer
          dangerouslySetInnerHTML={{ __html: teaser.teaserText1 }}
          textAlign={cmsService.getTeaserOverlayTextAlign(teaser.teaserOverlay1TextAlign)}
          {...cmsService.getMetaDataAttribute('[{"_":"properties.teaserText1"}]')}
        />
        <StyledCTAContainer className="cta-container">
          {teaser.teaserLXCallToActionSettings.map(
            (cta, i) =>
              cta.callToActionEnabled && (
                <CallToAction
                  dataElementId={`X_X_${i}Placement_Banner${index}_CTA`}
                  key={`arn-cms-content__cta-${cta.target?.name}`}
                  themeMode={'dark'}
                  {...cta}
                />
              )
          )}
        </StyledCTAContainer>
      </StyledBottom>
    </StyledContainer>
  )
}

export default PLPBox
