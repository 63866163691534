import { Box, FormControl, Select } from '@material-ui/core'
import {
  RoundButton,
  StyledAccordion,
  StyledAccordionDetails,
  Pill,
  StyledButtonAsLink,
  StyledTypography,
} from '../../../StyledUI'

import ProductImage from '../../../product-image/ProductImage'
import styled from 'styled-components'
import theme from '../../../../themes'
import { styled as stl } from '@mui/material/styles'

const tabletBreakpoint = theme.breakpoints.up('md')
const borderColor = theme.palette.primary.dark
const productSectionMobileIndent = `${theme.spacing(1)}px`

export const StyledSingleSizeContainer = styled.div<{ themeMode: string }>`
  padding-bottom: 0.25rem;
  width: 100%;

  cursor: default;
  font-size: 1rem;
  border-bottom: 1px solid
    ${({ themeMode }) =>
    themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark};

  &:hover {
    &.arn-pdp_selected-size-clickable {
      cursor: pointer;

      span span {
        text-decoration: underline;
      }
    }
  }
`

export const StyledEstimatedDeliveryTime = styled(StyledTypography).attrs({ variant: 'caption' })`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(1.25)}px;

  ${tabletBreakpoint} {
    margin-bottom: ${({ theme }) => theme.spacing(3.75)}px;
  }
`

export const ProductDetailsContainer = styled.div<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  .product-price__initial {
    color: ${themeMode === 'dark' ? theme.palette.text.greyDarkmode : theme.palette.text.greyLightmode
    };
  }
`}
`

export const MainRow = styled.div`
  display: flex;
  flex-direction: column;

  ${tabletBreakpoint} {
    flex-direction: row;
  }
`

export const StyledProductImageContainer = styled.div`
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const StyledProductImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 900px;
  margin: auto;
  transform: translateY(-60px);
  cursor: pointer;
`

export const StyledProductImage = styled(ProductImage)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const StyledMiniProductImage = styled(ProductImage)`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 1/1;
  cursor: pointer;

  @supports not (aspect-ratio: 1 / 1) {
    display: block;
    padding-top: 25%;
    padding-bottom: 25%;
  }
`

export const ProductMainColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.primary.contrastText};

  ${tabletBreakpoint} {
    padding: 0;
    flex: 1 1 66%;
  }
`

export const GoBack = styled(StyledButtonAsLink)`
  color: ${theme.palette.primary.dark};
  text-decoration-line: underline;
`
export const ProductSubColumn = styled.div<{ themeMode: string }>`
  padding: ${theme.spacing(1)}px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  background-color: ${({ theme, themeMode }) =>
    themeMode === 'dark' ? theme.palette.background.dark : 'transparent'};

  ${tabletBreakpoint} {
    padding: 50px 50px 41px 43px;
    flex: 0 1 488px;
  }
`

export const AddToCartButton = styled(RoundButton)`
  position: relative;
  width: 100%;
  min-width: 300px;
`

export const AttributesList = styled.ul`
  margin: 7px 0 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
`

export const AttributesListElement = styled.li`
  display: flex;
`
export const AttributesListName = styled.span`
  flex: 1 0 100%;
  max-width: 145px;
  &::after {
    content: ' : ';
    display: inline-block;
  }
`
export const AttributesListValue = styled.span`
  margin-left: 30px;
  margin-right: 10px;
  text-align: left;
`
export const PdpCtasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
`

export const SoldOutContainer = styled.div`
  display: grid;
  width: 100%;
  gap: 0.625rem;
  justify-items: flex-start;

  button {
    width: 100%;
  }

  ${theme.breakpoints.down('sm')} {
    justify-items: center;
  }
`

export const SoldOutStickyContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    width: 300px;
  }
`

export const AvailabilityWarning = styled.div`
  line-height: 1.5rem;
  text-transform: uppercase;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 0.75rem;
  }
`

export const AvailabilityWarningWithBackground = styled(AvailabilityWarning)`
  text-align: center;
  background-color: ${({ theme }) => theme.palette.custom.geckoGreen};
  color: ${({ theme }) => theme.palette.custom.black};
`

export const StyledDetailsAccordion = styled(StyledAccordion) <{ themeMode: string }>`
  padding: ${theme.spacing(1.5)}px ${productSectionMobileIndent} ${theme.spacing(1.5)}px;
  width: 100%;
  min-height: 72px;
  border-top: 1px solid ${({ theme }) => theme.palette.secondary.main};
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  &.Mui-expanded {
    margin: 0;
  }

  ${StyledAccordionDetails} {
    padding: 23px 0 0;
  }

  ${tabletBreakpoint} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const BadgesContainer = styled.div`
  position: absolute;
  top: ${theme.spacing(2)}px;
  right: ${theme.spacing(1)}px;
  width: 150px;
  height: 100px;

  ${tabletBreakpoint} {
    top: ${theme.spacing(2.1875)}px;
  }
`

export const LabelBadge = styled.div`
  margin-top: ${theme.spacing(1)}px;
  text-align: right;
  color: ${theme.palette.text.light};
`

export const IconBadge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: ${theme.palette.text.light};

  .arn-pdp__icon {
    margin-left: 4px;
  }
`

export const StyledProductTDetailsWrapper = styled.div`
  position: relative;
  min-height: 440px;

  .lazy-load-image-background {
    font-size: 0;
  }

  ${tabletBreakpoint} {
    border: 1px solid ${borderColor};
    border-top: none;
  }
`

export const StyledProductImagesWrapper = styled.div<{ isEvenLength: boolean }>`
  ${({ isEvenLength }) => `
  position: relative;
  display: flex;
  flex-flow: row wrap;
  border-top: 1px solid ${borderColor};

  & > * {
    flex: 0 0 50%;
    border-bottom: 1px solid ${borderColor};

    &:nth-child(odd) {
      border-right: 1px solid ${borderColor};
    }

    &:last-child {
      border-bottom: none;
    }

    ${isEvenLength ? '&:nth-last-child(2) { border-bottom: none; }' : ''}
  }

  .react-loading-skeleton {
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 1/1;
    border-radius: 0;
    @supports not (aspect-ratio: 1 / 1) {
      display: block;
      padding-top: 25%;
      padding-bottom: 25%;
    }
  }
`}
`

export const StyledComingBackSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.75rem;
    & > :first-child {
      margin-right: 0.25rem;
    }
  }
`

export const StyledTitleContent = styled.div`
  position: relative;
  margin-bottom: ${theme.spacing(3.625)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 32px;

  ${tabletBreakpoint} {
    margin-bottom: ${theme.spacing(0.625)}px;
  }
`

export const StyledProductName = styled(StyledTypography).attrs({ variant: 'h1' }) <{
  $isTablet: boolean
}>`
  ${({ theme, $isTablet }) => `
  width: 100%;
  font-size: 28px;
  color: var(--black);
  line-height: 32px;
  ${$isTablet ? `color: ${theme.palette.text.light}` : ''}
`}
`

export const StyledProductPriceWrapper = styled.div<{ themeMode: string }>`
  min-height: 32px;
  display: flex;
  align-items: flex-end;
  gap: 7px;
  padding: 0 ${productSectionMobileIndent};
  font-size: 0.75rem;

  ${tabletBreakpoint} {
    padding: 0;
  }

  .product-price__current {
    line-height: 32px;
    order: -1;
  }

  .product-price__initial {
    margin-top: 3px;
    color: ${({ theme, themeMode }) =>
    themeMode === 'dark' ? theme.palette.text.greyDarkmode : theme.palette.text.greyLightmode};
  }
`

export const StyledProductColorWrapper = styled.div`
  margin-bottom: ${productSectionMobileIndent};
  ${theme.breakpoints.up('md')} {
    margin-bottom: ${theme.spacing(1.5)}px
  }
  align-self: stretch;
`

export const StyledProductSizeWrapper = styled.div`
  margin-bottom: ${theme.spacing(1.5)}px;
  padding: 0 ${productSectionMobileIndent};
  display: flex;
  justify-content: space-between;
  align-self: stretch;

  ${tabletBreakpoint} {
    padding: 0;
  }
`

export const StyledSustainabilityContainer = styled('div')`
  margin-bottom: 0;

  ${tabletBreakpoint} {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }
`

export const SizeFormControl = styled(FormControl)`
  flex: 1;
`

export const StyledBox = styled(Box)`
  padding-top: 3.125rem;
  padding-left: 3rem;
  display: flex;
  align-items: center;
`

export const StyledWrapperContainer = styled.div`
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.background.default};
`

export const CloseButton = styled.button`
  z-index: 1;
  position: absolute;
  top: ${theme.spacing(1)}px;
  right: ${theme.spacing(1)}px;
  border: none;
  border-radius: 2rem;
  background-color: transparent;
  cursor: pointer;

  ${tabletBreakpoint} {
    top: ${theme.spacing(3)}px;
    right: ${theme.spacing(3)}px;
    width: 145px;
    height: 36px;
    border: 1px solid ${theme.palette.text.primary};
  }
`

export const StyledCloseText = styled(StyledTypography).attrs({ variant: 'caption' })`
  font-size: 16px;
  color: ${theme.palette.text.primary};
  text-transform: uppercase;
`

export const StyledProductGridContainer = styled('div')`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 1px;
  padding-bottom: 5rem;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 0;
  }
`
export const StyledGridProductLayout = styled('div')`
  /* for older browser's versions that don't supprt aspetc-ratio */
  @supports not (aspect-ratio: 1 / 1) {
    &::before {
      content: '';
      padding-top: 150%;
      display: block;

      ${tabletBreakpoint} {
        padding-top: 100%;
      }
    }
  }
  aspect-ratio: initial;
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => `0 0 0 1px ${theme.palette.text.light}`};
  background-color: ${({ theme }) => theme.palette.custom.warmGrey};

  ${tabletBreakpoint} {
    aspect-ratio: 1 / 1;
  }
`
export const StyledProductWrap = styled.div`
  /* for older browser's versions that don't supprt aspetc-ratio */
  @supports not (aspect-ratio: 1 / 1) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${tabletBreakpoint} {
    height: 100%;
  }
`
export const Title = styled.div`
  margin: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(0)}px;
  margin-top: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  gap: ${({ theme }) => theme.spacing(1)}px;
  .sub-menu-title {
    font-size: ${({ theme }) => theme.spacing(1)}px;
    color: ${({ theme }) => theme.palette.text.primary};
    text-decoration: underline;
    text-transform: uppercase;
  }
  .sub-menu-shop-all {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 90%;
    }
  }

  ${tabletBreakpoint} {
    margin: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(1)}px;
    padding: 0;
  }
`
export const StyledRelativeBtn = styled(RoundButton) <{ elementOrder?: '1' | '2' }>`
  position: relative;
  width: 100%;
  order: ${({ elementOrder }) => elementOrder || 'initial'};
`

export const StyledSizeSelect = styled(Select)`
  * > svg {
    display: none;
  }

  span {
    overflow: auto;
  }
`

export const StyledSizeFormControl = styled(SizeFormControl)`
  * > svg {
    display: none;
  }

  span span:hover {
    text-decoration: underline;
  }

  &.MuiInput-underline:hover:before {
    border-bottom: 1px solid ${borderColor};
  }
`
export const StyledMobileShopWrap = styled(Box)`
  width: 100%;
  padding: 20px 15px 52px;
`

export const StyledBlurredSlider = styled.div<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  ${themeMode === 'light' ? 'margin-top: -1px;' : ''}
  border: 1px solid ${theme.palette.secondary.main};
`}
`

export const StyledVATElement = styled.div`
  order: 2;
  font-size: ${({ theme }) => theme.spacing(0.75)}px;
  line-height: ${({ theme }) => theme.spacing(1.25)}px;
`

export const StyledSwitcherContainer = stl('div', {
  name: 'PdpContainer',
  slot: 'SwitcherContainer',
})<{ isSticky?: boolean; hasPromoBar: boolean}>(({ isSticky, hasPromoBar, theme }) => ({
  position: 'absolute',
  top: '62px',
  left: '16px',
  [theme.breakpoints.up('sm')]: {
    //position: isSticky ? 'fixed' : hasPromoBar ? 'relative' : 'relative',
    //top: isSticky ? '116px' : hasPromoBar ? '84px' : 'inherit',
    //top: isSticky && hasPromoBar ? '84px' : isSticky ? '116px' : hasPromoBar ? '84px' :'inherit',
    position: isSticky ? 'fixed' : 'relative',
    top: isSticky && !hasPromoBar ? '116px' : !isSticky && hasPromoBar ? 'inherit' : isSticky && hasPromoBar ? '84px' : 'inherit',
    left: isSticky ? '16px' : 'inherit',
    margin: '0 auto',
  }
}))

export const VirtualMirrorPill = stl(Pill, {
  name: 'VirtualMirror',
  slot: 'Pill',
})<{ showAnimation?: boolean }>(({ showAnimation }) => ({
  '&.MuiButton-root': {
    animation: showAnimation && 'pulse 3s infinite',
    transform: showAnimation && 'scale(1)',
  },

  '&.MuiButton-root:before': {
    animation: showAnimation && 'animate 3s infinite',
    transform: showAnimation && 'scale(1)',
    animationDelay: showAnimation && '0.5s',
  },

  '&:before, &:after': {
    content: '""',
    position: 'absolute',
    left: '0',
    top: '0',
    right: '0',
    bottom: '0',
    borderRadius: 40,
  },

  '&:hover:before': {
    animation: 'pulseHover 3s infinite',
    transform: 'scale(1)',
  },

  '&:hover:after': {
    animation: 'animateHover 3s infinite',
    transform: 'scale(1)',
    animationDelay: '0.5s',
  },

  '@keyframes pulse': {
    '0%': {
      boxShadow: showAnimation && '0 0 0 0 rgba(148, 250, 102, 0.5)',
    },
    '70%': {
      boxShadow: showAnimation && '0 0 0 20px rgba(148, 250, 102, 0)'
    },
    '100%': {
      boxShadow: showAnimation && '0 0 0 0 rgba(148, 250, 102, 0)'
    }
  },

  '@keyframes pulseHover': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(148, 250, 102, 0.5)',
    },
    '70%': {
      boxShadow: '0 0 0 20px rgba(148, 250, 102, 0)'
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(148, 250, 102, 0)'
    }
  },

  '@keyframes animate': {
    '0%': {
      boxShadow: showAnimation && '0 0 0 0 rgba(148, 250, 102, 1)',
    },
    '70%': {
      boxShadow: showAnimation && '0 0 0 20px rgba(148, 250, 102, 0)'
    },
    '100%': {
      boxShadow: showAnimation && '0 0 0 0 rgba(148, 250, 102, 0)'
    }
  },

  '@keyframes animateHover': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(148, 250, 102, 1)',
    },
    '70%': {
      boxShadow: '0 0 0 20px rgba(148, 250, 102, 0)'
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(148, 250, 102, 0)'
    }
  },
}))

export const VMWrapper = stl('div', {
  name: 'VirtualMirror',
  slot: 'Wrapper',
})(() => ({
  backgroundColor: '#000',
  inset: 0,
  opacity: 0.3,
  position: 'fixed',
  zIndex: 9998,
}))

export const StyledLoaderWrapper = stl('div')(() => ({
  display: 'flex',
  height: '100%',
}))

export const VMContainer = stl('div', {
  name: 'VirtualMirror',
  slot: 'Container',
  shouldForwardProp: (prop) => prop === 'isOpen',
})<{ isOpen: boolean }>(({ theme, isOpen }) => (
  {
    backgroundColor: '#EBE9E4 !important',
    color: '#222!important',
    fontFamily: 'NeueHaas, \'Helvetica Neue\', Arial, sans-serif !important',
    maxWidth: '644px',
    height: '100%',
    width: '100%',
    zIndex: 9999,
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '768px',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: '800px',
      maxWidth: '644px',
    },
    '.vm-ready': {
      backgroundColor: '#EBE9E4 !important',
    },
    '.mirror-loader': {
      width: 'auto!important'
    },
    '.is-vm-open': {
      body: {
        overflowY: isOpen ? 'hidden' : 'auto',
      },
    },
    '.vmmv-drawer-screenshot': {
      maxWidth: '48em',
      width: '100%',
      left: 0,
    },
    '.vmmv-screenshot-wrapper': {
      padding: '3em 10%',
      overflowY: 'auto',
      'img:nth-child(3)': {
        width: 'auto',
      },
      'p:nth-child(5)': {
      },
    },
    '.swiper-slide': {
      marginRight: '10px!important',
      [theme.breakpoints.up('sm')]: {
        marginRight: '10px!important'
      },
      [theme.breakpoints.up('md')]: {
        marginRight: '20px!important'
      },
    },
    '[class^="styles__PrivacyHeader-sc-"]': {
      padding: '2em !important',
      '[class^="styles__CloseIconWrapper-sc-"]': {
        top: '2em !important',
      },
      '[class^="styles__CloseIconWrapper-sc-"] svg': {
        width: '24px !important',
        height: '24px !important',

      }
    },
    '[class^="styles__ActionBox-sc-"]': {
      maxWidth: '100%',
      fontWeight: '400!important',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
      },
    },
    '[class^="styles__ActionStyled-sc-"]': {
      paddingLeft: '0.5em',

    },
    '#bipa-component-container': {

      backgroundColor: '#EBE9E4 !important',
      color: '#222!important',
      '#BIOMETRIC_AGREEMENT_TITLE': {
        marginTop: '0em !important',
        marginBottom: '20px!important'
      },
      [theme.breakpoints.up('sm')]: {
        padding: '0 134px 4em !important',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '0 102px 4em !important',
        fontSize: '14px',
        b: {
          fontSize: '14px',
        }
      },
    },
    '#bipa-privacy-policy-notice': {
      margin: '0 auto',
      width: '337px',
      [theme.breakpoints.up('sm')]: {
        width: '100%!important',
      },
      [theme.breakpoints.up('lg')]: {
        width: '337px!important',
      },
    },
    '[class^="styles__ActionWrapper-sc-"]': {
    },
    '[class^="styles__ParagraphDescription-sc-"], [class^="styles__TipDescription-sc-"],[class^="styles__ActionBox-sc-"],[class^="styles__ProductInfo-sc-"], #bipa-component-container,#bipa-footer-bar, #PRIVACY_IMPLICIT_TERMS_AND_CONDITIONS, #PRIVACY_IMPLICIT_AGREEMENT b, #PRIVACY_FIRST_AGREEMENT, #privacy-agreement p span, #privacy-agreement p':
    {

      fontSize: '14px !important',

    },
    '[class^="styles__ProductInfo-sc-"]:nth-child(1)': {
      dispay: 'none'
    },
    '[class^="styles__FooterLabel-sc-"]':
    {
      fontSize: '14px !important',
      padding: '0px 16px 0px 12px'
    },
    '[class^="styles__ListText-sc-"]':
    {
      fontSize: '14px !important',
      fontWeight: '700',
      padding: '5px 16px 4px 12px',
      backdropFilter: 'none',
    },
    '[class^="styles__ErrorLabel-sc-"]':
    {
      fontSize: '14px !important',
      fontWeight: '400',
      padding: '5px 16px 4px 12px'
    },
    '[class^="styles__ProductInfo-sc-"]':
    {
      marginTop: '7px'

    },
    '[class^="styles__StyledError-sc-"]':
    {
      maxWidth: '300px'

    },
    '#bipa-overlay-root-style.bipa-overlay *:focus': {
      outline: '#767676 solid 1px'
    },
    '.note-warning, .error-text': {
      [theme.breakpoints.up('sm')]: {
        padding: '0 48px!important'
      },

    },
    '#bipa-overlay-root-style.bipa-overlay .note-text': {
      color: '#767676 !important',
      fontFamily: 'NeueHaas, \'Helvetica Neue\', Arial, sans-serif !important',
      fontSize: '12px',
      [theme.breakpoints.up('sm')]: {
        padding: '0 48px!important'
      },
    },
    '#bipa-overlay-root-style.bipa-overlay b': {
      fontSize: '14px !important',
    },
    '#bipa-overlay-root-style.bipa-overlay input::placeholder': {
      color: '#767676'
    },
    '#bipa-overlay-root-style.bipa-overlay p': {
      fontSize: '14px !important',
    },

    '[class^=\'styles__Title-sc-\']': {
      fontFamily: 'NeueHaas, \'Helvetica Neue\', Arial, sans-serif !important',
      color: '#222',
      fontSize: '20px',
      fontWeight: '700',
      marginBottom: '20px',
      [theme.breakpoints.up('sm')]: {
        whiteSpace: 'nowrap !important',
        marginBottom: '1em',
        fontSize: '32px',
      },
      [theme.breakpoints.up('md')]: {
        whiteSpace: 'inherit !important',
      },
    },
    '[class^=\'styles__Text-sc-\']': {
      fontSize: '14px',

      [theme.breakpoints.up('sm')]: {

        fontSize: '16px',
      },
    },
    '[class^=\'styles__VideoScanInstructionPageWraper-sc-\']': {
      backgroundColor: '#EBE9E4 !important',
      h1: {
        maxWidth: '243px',
      }
    },
    '[class^=\'styles__BrandImage-sc-\']': {

      marginBottom: '12px',

    },
    '[class^=\'styles__ProductName-sc-\']': {
      fontSize: '14px!important',
      marginBottom: '2px',
      marginTop: '2px',
      fontWeight: '700',
    },
    '[class^=\'styles__SeeInstructions\']': {
      fontWeight: '700 !important',
    },
    '[class^=\'styles__StyledLabel-sc-\']': {
      fontWeight: '700 !important',
      top: '-3em!important',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        fontSize: '16px!important',
        gap: '16px',
        top: '-2.5em !important',
      },
      [theme.breakpoints.up('md')]: {
        top: '-2.5em !important',
      },
    },
    '[class^=\'styles__ButtonsContainer\']': {
      position: 'static !important',
      marginTop: 'auto',
      width: 'auto',
    },
    '[class^=\'styles__Tip-sc\']': {
      '@media (min-width: 768px)': {
        marginTop: '1em',
      },
    },
    '[class^=\'styles__PictureModeEntryPageWraper-sc-\'],[class^="styles__VideoScanInstructionPageWraper-sc-"]': {
      '[class^=\'styles__ButtonWrapper-sc-\']': {
        boxShadow: 'none',
      },
      '[class^=\'Button__StyledButton-sc\']': {
      },
    },
    '[class^="styles__VideoScanInstructionPageWraper-sc-"]': {
      padding: '3em 4em 5.8125em !important',
      '[class^=\'styles__Title-sc-\']': {
        marginBottom: '14px!important',
        [theme.breakpoints.up('sm')]: {
          marginBottom: '75px!important',
          whiteSpace: 'initial !important',
        },
        [theme.breakpoints.up('md')]: {
          marginBottom: '42px!important',
          whiteSpace: 'initial !important',
        },
        '@media only screen and (max-height: 571px)': {
          marginBottom: '36px !important',
        },
      },
      '[class^=\'styles__Tip-sc-\']': {
        marginTop: '0px!important',
        marginBottom: '16px!important',
        [theme.breakpoints.up('sm')]: {
          marginTop: '0px!important',
          marginBottom: '32px!important',
        },
      },
      '[class^="styles__TitleWrapper-sc-"]': {
        '@media only screen and (max-height: 571px)': {
          marginTop: '0px !important',
          marginBottom: '0px !important',
        },
      },
    },
    '#PRIVACY_AGREEMENT_TITLE': {
      margin: '0 !important',
    },
    '#bipa-component-container .field': {
      marginBottom: '12px!important'
    },
    '#bipa-component-container button': {
      borderRadius: '24px!important',
      textTransform: 'uppercase'
    },
    '#bipa-cancel-button': {
      height: '48px!important',
      background: 'none !important',
      padding: '12px 24px!important'
    },
    '#bipa-submit-button': {
      height: '48px!important',
      padding: '12px 24px!important'
    },
    '[class^="styles__TryAgainButton-sc-"]': {
      fontFamily: 'NeueHaas, \'Helvetica Neue\', Arial, sans-serif !important',
    },
    '#upload-image-drop-zone::after, .upload-image-display-accept::after': {
      color: '#EBE9E4 !important',
      fontWeight: '400 !important',
      fontSize: '16px!important',
    },
    '.upload-image-display-controls > .upload-image-display-decline::after': {
      //position: 'static !important',
      fontWeight: '700'
    },
    '#bipa-submit-button, [class^="Button__StyledButton-sc-"], #upload-image-drop-zone, .upload-image-display-accept, [class^="styles__TryAgainButton-sc-"]':
    {
      textTransform: 'uppercase',
      textAlign:'center !important',
      fontWeight: '400 !important',
      fontSize: '16px!important',
      backgroundColor: '#000 !important',
      padding: '12px 24px',

      width: 'auto!important',
      height: '48px!important',
    },
    '.yes-no-checkbox': {
      textTransform: 'uppercase',

    },
    '.yes-no-checkbox span': {
      opacity: '1!important',

    },
    '.yes-no-checkbox .switch .slider': {
      backgroundColor: '#767676 !important',
      textTransform: 'uppercase',
    },
    '.yes-no-checkbox .switch .slider.round::before': {
      backgroundColor: '#EBE9E4 !important',
    },
    '[class^="styles__TitleWrapper-sc-"]': {
      marginTop: '14px',
      [theme.breakpoints.up('sm')]: {
        marginTop: '10px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '18px',
        whiteSpace: 'nowrap!important',
      },


      marginBottom: '0'
    },
    '[class^="styles__PrivacyNotice-sc"]': {
      color: '#222',
      opacity: '100%',
      padding: '0 16px 17px !important',
      [theme.breakpoints.up('sm')]: {
        padding: '0 81px 45px !important',
      },
      [theme.breakpoints.up('md')]: {
        padding: '0 24px 30px !important',
      },
    },
    '[class^="styles__StickyWrapper-sc-"], .upload-image-container.button-wrapper': {
      backgroundColor: '#EBE9E4 !important',
      boxShadow: 'none',
      bottom: '0px !important',
      [theme.breakpoints.up('sm')]: {
        bottom: '45px !important',
      },
      [theme.breakpoints.up('md')]: {
        bottom: '0px !important',
      },
    },
    '.yes-no-checkbox .switch input:checked + .slider': {
      backgroundColor: '#000 !important'
    },
    '.yes-no-checkbox .switch input:checked + .slider::before': {
      backgroundColor: '#EBE9E4 !important',
    },

    '[class^="styles__ModalWindowContainer-sc-"]': {
      backgroundColor: '#EBE9E4 !important',
      borderRadius: 'inherit'
    },
    '[class^="/styles__PictureModeEntryPageWraper-sc-/"]': {
      height: '100%',
      backgroundColor: '#EBE9E4 !important',
      padding: '3em 1em 2em',
      [theme.breakpoints.up('sm')]: {
        padding: '82px 127px 93px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '48px 127px 32px',
      },
      '[class^="styles__TitleWrapper-sc-"]': {
        '[class^="styles__Title-sc-"]': {
          marginBottom: '12px !important',
          [theme.breakpoints.up('sm')]: {
            marginBottom: '15px !important',
          },

          '[class^="styles__ParagraphDescription-sc-"]': {
            padding: '0 16px 0',
          },
        },
      },
      '[class^="styles__ParagraphDescription-sc-"]': {
        marginBottom: '22px !important',
        padding: '0 38px',
        [theme.breakpoints.up('sm')]: {
          marginBottom: '23px !important',
          padding: '0 22%',
        },
        [theme.breakpoints.up('md')]: {
          padding: '0 52px',
          marginBottom: '33px !important',
        },
      },
      '[class^="styles__TipsWrapper-sc-"]': {
        marginTop: '0 !important',
        height: '100%',
        maxHeight: '60vh',

        [theme.breakpoints.up('sm')]: {
          marginTop: '28px !important',
        },
        [theme.breakpoints.up('md')]: {
          marginTop: '19px !important',
        },

        '[class^="styles__TipUpload-sc-"]': {
          '[class^="styles__ParagraphDescription-sc-"]': {
          },
        },
      },

      '.vmmv-upload-instructions::-webkit-scrollbar, .vmmv-take-picture-instructions::-webkit-scrollbar': {
        backgroundColor: '#EBE9E4 !important',
        width: '4px'
      },

      '.vmmv-upload-instructions::-webkit-scrollbar-thumb, .vmmv-take-picture-instructions::-webkit-scrollbar-thumb': {
        backgroundColor: '#C0C1C6 !important',
      },
    },

    'button, [class^="styles__MenuLogo-sc-"], [class^="styles__CatalogItem-sc-"], [class^="styles__ActionBtn-sc-"], [class^="styles__ActionBox-sc-"]>svg':
    {
      cursor: 'pointer',
      fill: theme.palette.text.primary,
      stroke: `${theme.palette.background.default} !important`,

    },
    '[class^="styles__CarouselWrapper-sc-"]': {


    },
    '[class^="styles__CatalogContainer-sc-"]': {
      '[class^="swiper-container swiper-container-initialized"]': {
        [theme.breakpoints.up('sm')]: {
          marginBottom: '16px',
        },
      },
    },
    '.vm__frame-catalog-item-inner, .vm__colors-catalog-item-inner': {
        width: '70px',
        height: '70px',
      [theme.breakpoints.up('sm')]: {
        width: '80px',
        height: '80px',
      },
      [theme.breakpoints.up('md')]: {
        width: '88px',
        height: '88px',
      },
    },

    '[class^="styles__TipTake-sc-"],[class^="styles__TipUpload-sc-"], [class^="styles__Tip-sc-"]':
    {
      marginTop: '0',
      marginBottom: '13px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '32px'
      },
      [theme.breakpoints.up('md')]: {
        marginBottom: '24px'
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '20px',
      },

      '[class^="styles__IconWrapper-sc-"]':
      {
        cursor: 'pointer',
        fill: theme.palette.text.primary,
        backgroundColor: 'inherit',
        stroke: '#222 !important',
        strokeWidth: '0.5',
      },
      '[class^="styles__TipDescription-sc"]':
      {
        [theme.breakpoints.up('sm')]: {
          maxWidth: '19.75em !important'
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: '20.50em !important'
        },
      },
    },
    '[class^="styles__IconWrapper-sc-"]':
    {
      cursor: 'pointer',
      fill: theme.palette.text.primary,
      stroke: `${theme.palette.background.default} !important`,
      strokeWidth: '0.5',
      [theme.breakpoints.up('md')]: {
        backgroundColor: '#000',
      },
    },
    '[class^="styles__FooterInner-sc-"]': {
      height: '48px',
      [theme.breakpoints.up('sm')]: {
        height: '56px',
      },
      [theme.breakpoints.up('md')]: {
        height: '5em',
      },
    },
    
    '#bipa-overlay-root-style #privacy-agreement .scrollable-container .scroll-gradient': {
      display: 'none !important',
      background: 'none'
    },
    '#bipa-overlay-root-style #privacy-agreement .scrollable-container::-webkit-scrollbar': {
      backgroundColor: '#EBE9E4 !important',
      width: '4px'

    },
    '#bipa-overlay-root-style #privacy-agreement .scrollable-container::-webkit-scrollbar-thumb': {
      backgroundColor: '#C0C1C6 !important',
    },
    '#bipa-overlay-root-style #privacy-agreement .footer-bar': {
      backgroundColor: '#EBE9E4 !important',
      position: 'fixed',
    },
    '#bipa-overlay-root-style #privacy-agreement .footer-bar .footer-content .line': {
      margin: '0 0 12px 0'

    },
    '#bipa-overlay-root-style #privacy-agreement .footer-bar .footer-content .line.buttons span': {
      fontSize: '16px'

    },
    '#bipa-overlay-root-style #privacy-agreement .footer-bar .footer-content': {
      maxWidth: '70vh'
    },
    '#bipa-biometric-agreement': {
      [theme.breakpoints.up('sm')]: {
        width: '440px'
      },

    },
    '#BIPA_FORM_SUBTITLE': {
      marginBottom: '71px!important',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '75px!important'
      },
    },
    '#bipa-biometric-agreement .field input': {
      background: 'none',
      maxWidth: '343px',
      margin: '0 auto !important',
      height: '48px !important',
      padding: '12px 16px !important'
    },
    '.button-wrapper': {
      boxShadow: 'none!important'
    },
    '#notificationText': {
      fontWeight: '700 !important',
      fontSize: '12px'
    },
    '.vmmv-add-prescription-modal-prescription-button': {
      backgroundColor: 'inherit!important',
    },
    '.error-icon': {
      right: '41px',
      position: 'absolute',
      [theme.breakpoints.up('sm')]: {
        right: '161px',
      },
      '#bipa-field-warning': {
        marginBottom: '12px!important',
        '.error-text': {
          marginLeft: '4px'
        }
      }
    },
    '[class^="styles__OptionStyled-sc-"]': {
      marginBottom: theme.spacing(3),

      svg: {
        width: 24,
        height: 24,
      },

      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(3.5),

        svg: {
          width: 28,
          height: 28,
        },
      }
    },
    '[class^="styles__StyledLensDescriptionWrapper-sc-"]': {
      padding: `${theme.spacing(2)} 0 ${theme.spacing(2.375)} 0`,
      [theme.breakpoints.down('sm')]: {
        height: 'auto !important',
      },
      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(3)} 0 ${theme.spacing(3.75)} 0`,
      }
    },
    '[class^="styles__ToggleBtn-sc-"]': {
      stroke: 'inherit !important',
    },
    '[class^="styles__PictureModeEntryPageWraper-sc-"]': {
      '.frame-advisor-root-style': {
        width: '50%',
        height: '46%',
        '@media only screen and (min-width: 376px)': {
          width: '80%!important',
          height: '55%!important'
        }
      },
      backgroundColor: '#EBE9E4 !important',
      '@media only screen and (min-width: 1224px)': {
        overflow: 'auto',
        padding: '5.625em 3em 5.8125em',
      },
      '@media only screen and (min-width: 768px)': {
        overflow: 'auto !important',
        padding: '5em 4em 5.8125em',
      },
      '@media only screen and (min-width: 571px)': {
        overflow: 'auto !important',
        padding: '3em 4em 5.8125em !important',
        flexDirection: 'column !important',
        maxHeight: 'none !important',
      },
      '[class^="styles__TitleWrapper-sc-"]': {
        '@media only screen and (max-height: 571px)': {
            marginTop: '0px !important',
          },
        '[class^="styles__Title-sc-"]': {
          marginBottom: '12px !important',
          overflowY: 'visible !important',
          [theme.breakpoints.up('sm')]: {
            marginBottom: '15px !important',
          },

          '[class^="styles__ParagraphDescription-sc-"]': {
            padding: '0 16px 0',
          },
        },
      },
      '[class^="styles__ParagraphDescription-sc-"]': {
        marginBottom: '22px !important',
        padding: '0 38px',
        [theme.breakpoints.up('sm')]: {
          marginBottom: '23px !important',
          padding: '0 22%',
        },
        [theme.breakpoints.up('md')]: {
          padding: '0 52px',
          marginBottom: '33px !important',
        },
      },
      '[class^="styles__PictureModeAction-sc-"]': {
        '@media only screen and (max-height: 650px)': {
          marginTop: '0em !important',
        },
      },
      '[class^="styles__TipsWrapper-sc-"]': {
        marginTop: '0 !important',
        overflowY: 'visible',
        height: '100%',
        maxHeight: 'none !important',

        [theme.breakpoints.up('sm')]: {
          marginTop: '28px !important',
        },
        [theme.breakpoints.up('md')]: {
          marginTop: '19px !important',
        },
        '@media only screen and (min-width: 391px)': {
          marginBottom: '80px !important',
        },
        '@media only screen and (min-width: 571px)': {
          marginBottom: '40px !important',
        },
        '[class^="styles__TipUpload-sc-"]': {
          '[class^="styles__ParagraphDescription-sc-"]': {
          },
        },
      },
      '.vmmv-upload-instructions::-webkit-scrollbar, .vmmv-take-picture-instructions::-webkit-scrollbar': {
        backgroundColor: '#EBE9E4 !important',
        width: '4px'
      },

      '.vmmv-upload-instructions::-webkit-scrollbar-thumb, .vmmv-take-picture-instructions::-webkit-scrollbar-thumb': {
        backgroundColor: '#C0C1C6 !important',
      },
    '[class^="styles__StyledDiv-sc-"]': {
      width: '45%',
      height: '40%',

      '.upload-image-container.button-wrapper': {
        position: 'absolute',
        bottom: '0px !important',
        left: '0px',
        right: '0px',
        maxHeight: '5.8125em',
      },
    '@media only screen and (min-width: 768px)': {
      height: '60%',
    },
    '@media only screen and (min-width: 376px)': {
      width:  '80%',
      height: '40%!important',
    },
    '.upload-image-container': {
      width: '100%',
      height: '100%',
      position: 'relative',
      '.upload-image': {
        '.upload-image-display-controls': {
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          webkitBoxPack: 'center',
          justifyContent: 'center',
          webkitBoxAlign: 'center',
          alignItems: 'center',
          bottom: '-120% !important',
          left: '50%',
          transform: 'translate(-50%, 0px)',
          '.upload-image-display-controls button': {
            marginBottom: '1em',
            marginTop: '0px',
          },
          '.upload-image-display-controls>.upload-image-display-decline': {
        },
          '.upload-image-display-accept': {
            width: '11.375em',
            height: '3.5em',
            borderRadius: '3.125em',
            border: 'none',
            position: 'relative',
            cursor: 'pointer',
          },
          '.upload-image-display-accept::after': {
            display: 'block',
            width: 'auto',
            whiteSpace: 'nowrap',
          },
          '.upload-image-display-controls .upload-image-display-decline ': {
            marginBottom: '2.5em,',
              '.upload-image-display-controls button': {
                marginBottom: '1em',
                marginTop: '0px',
                margin: '10px',
              },
            },
          '.upload-image-display-decline': {
            visibility: 'hidden',
            position: 'relative',
            width: '11.25em',
            cursor: 'pointer',
          },
          '.upload-image-display-decline::after': {
            display: 'block',
            position: 'absolute',
            visibility: 'visible',
            top: '0px',
            left: '1.375em',
            whiteSpace: 'nowrap',
          },
        },
        '.upload-image-loading': {
          backdropFilter: 'none',

          pointerEvents: 'none',
          position: 'absolute',
          top: '0',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '.loading-bg': {
            position: 'absolute',
  
            pointerEvents: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            '.loading-spinner-container': {
              position: 'absolute',
              alignSelf: 'center',
            },
          },
        },
      },
    },
    '.vm__lens-catalog-item-inner':{
      height:'48px'
    },
    },
  },
  }
)
)
