/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import AccordionSummary from '@material-ui/core/AccordionSummary'

const CustomAccordionSummary = withStyles((theme) => ({
  content: {
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {
    margin: '0',
  },
  expandIcon: {
    zIndex: 1,
    position: 'relative',
    padding: '0',
    margin: '0',
  },
}))(AccordionSummary)

const StyledAccordionSummary = styled(({ ...props }) => <CustomAccordionSummary {...props} />)`
  text-transform: uppercase;
  min-height: initial;
  padding: 0;

  &.Mui-expanded {
    min-height: initial;
  }

  &.arn-footer__country-select {
    text-transform: none;
  }

  &.arn-my-account_accordion-summary{
    color: ${({ theme }) => theme.palette.text.primary};
  }
`

export default StyledAccordionSummary
