import { StyledProductTileWrapper } from './ProductTile.style'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { themeModeSelector } from '../../../redux/selectors/theme'

export const ProductTileSkeleton: React.FC<{}> = () => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  return (
  <StyledProductTileWrapper style={{ position: 'relative', padding: 0 }}>
    <SkeletonTheme color={themeMode==='dark' ? 'black' : 'transparent'}
      highlightColor={themeMode==='dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'}>
      <Skeleton
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 0,
        }}
      />
    </SkeletonTheme>
  </StyledProductTileWrapper>
)}
