import { AppDispatch } from '../../redux/store'
import wishListService from '../../foundation/apis/transaction/wishList.service'
import { setWishList, removeWishList } from './slice'
import tealiumService from '../../foundation/analytics/tealium/tealium.service'

export const fetchWishlistAction = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setWishList({ isLoading: true }))

    const wishlist = await wishListService.getDefault()

    dispatch(
      setWishList({ GiftList: wishlist.GiftList, x_data: wishlist.x_data, isLoading: false })
    )
  } catch (error) {
    dispatch(setWishList({ isLoading: false }))

    tealiumService.sendErrorEvent({
      Error_Code: 'Error fetching wishlist data',
      Error_Details: JSON.stringify(error?.response?.data.errors[0]) || 'wishlist/@default',
      Error_Source: 'Server',
    })
  }
}

export const clearWishlistAction = () => (dispatch: AppDispatch) => dispatch(removeWishList())
