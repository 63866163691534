import { AnchorWithParamsProps } from './anchor.type'
// styled
import { AnchorWithStyle } from './StyledAnchor.style'
import React from 'react'
import { updateUrlParameter } from '../../../utils/url'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'

export const StyledAnchor = ({ to, params = {}, children, ...props }: AnchorWithParamsProps) => {
  const { basePath } = useStoreIdentity()
  const url = Object.keys(params).reduce((url: string, key) => {
    return updateUrlParameter(url, key, params[key])
  }, to)

  return (
    <AnchorWithStyle to={`${basePath}${url}`} {...props}>
      {children}
    </AnchorWithStyle>
  )
}
