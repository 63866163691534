/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from 'styled-components'
import Alert from '@material-ui/lab/Alert'

const StyledAlert = styled(Alert)`
  border-radius: 0;
  background-color: #9b0800;

  &.arn-message-snackbar_alert {
    background-color: transparent;
  }

  &.MuiAlert-filledSuccess {
    color: rgb(48, 48, 48);
    font-weight: 400;
    background-color: #ecfaeb;
    border: 1px solid #1a930f;
    .MuiAlert-icon {
      color: rgb(26, 147, 15);
    }
  }
`

export default StyledAlert
