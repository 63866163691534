import { useEffect } from 'react'
import Log from '../../services/Log'
import config from '../../configs'
import { executeOnce } from '../../utils/common'
import { monetateKeySelector } from '../../redux/selectors/site'
import { useSelector } from 'react-redux'

export const useMonetate = () => {
  const isMonetateEnabledConf = useSelector(monetateKeySelector)
  const isMonetateEnabled = config.enableMonetate && isMonetateEnabledConf

  const loadScript = () => {
    return executeOnce(() => {
      const headFragment = document.createDocumentFragment()
      const monetateTag = document.createElement('script')
      // let monetateT = new Date().getTime()
      let src = '//se.monetate.net/js/2/a-ecfffeda/p/arnette.com/entry.js'
      monetateTag.src = src

      headFragment.appendChild(monetateTag)
      document.head.appendChild(headFragment)
    }, 'dw-monetate')()
  }

  useEffect(() => {
    if (isMonetateEnabled) {
      try {
        loadScript()
      } catch (e) {
        Log.error(window.location.href, '[MONETATE] LOAD SCRIPT ERROR ', e.message)
      }
    }
  }, [isMonetateEnabled])
}
