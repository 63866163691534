import styled from 'styled-components'
import { getDesktopThemeBreakpoint } from '../../../constants/ui'
import { StyledAccordion, StyledAccordionSummary } from '../../StyledUI'

export const PageContainer = styled.div`
  ${({ theme }) => `
  padding: ${theme.spacing(1)}px;
`}
`

export const PageContent = styled.div`
  ${({ theme }) => `
  margin: 0 auto;
  width: 100%;
  max-width: 1171px;
  display: flex;
  flex-direction: column;
  gap: 22px;

  ${getDesktopThemeBreakpoint(theme)} {
    flex-direction: row;
    gap: 16px;
  }

  ${theme.breakpoints.up('lg')} {
    gap: 50px;
  }
`}
`

export const PageNav = styled.nav<{ isShown: boolean }>`
  ${({ isShown, theme }) => `
  display: ${isShown ? 'flex' : 'none'};
  flex-direction: column;

  ${getDesktopThemeBreakpoint(theme)} {
    flex: 1 1 218px;
  }
`}
`

export const PageNavList = styled.ul<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    color: ${theme.palette.custom[themeMode === 'dark' ? 'warmGrey' : 'black']};
  }

  a:hover,
  button:hover {
    text-decoration: underline;
  }
`}
`

export const PageNavListItem = styled.li<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  flex: 1;

  .account-page__nav-list__link--active {
    text-decoration: underline;

    &:before {
      content: '>  ';
    }
  }

  ${getDesktopThemeBreakpoint(theme)} {
    &:nth-child(1) {
      padding-bottom: ${theme.spacing(1.25)}px;
      font-size: 18px;
      line-height: 2rem;
      color: ${themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary};
    }
  }
`}
`

export const PageMain = styled.main`
  ${({ theme }) => `
  ${getDesktopThemeBreakpoint(theme)} {
    flex: 1 1 903px;
  }
`}
`

export const StyledAccountNavAccordion = styled(StyledAccordion)<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  background-color: ${theme.palette.background.default};
  list-style: none;

  a {
    color: ${themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary};
  }

  &.arn-my-account_accordion {
    a {
      color: ${theme.palette.text.primary}
    }    
  }

  a:hover,
  button:hover {
    text-decoration: underline;
  }

  ${StyledAccordionSummary} {
    padding: ${theme.spacing(1)}px;
    border: 1px solid ${
      themeMode === 'dark' ? theme.palette.text.contrastText : theme.palette.text.primary
    };

    &.arn-my-account_accordion-summary{
      border: 1px solid ${theme.palette.text.primary};
    }

    text-transform: initial;
  }
`}
`

export const StyledAccordionItemsContainer = styled.div<{ themeMode: string }>`
  ${({ theme, themeMode }) => `
  padding: ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1.5)}px;
  padding-bottom: ${theme.spacing(1)}px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border: 1px solid ${
    themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
  };
  border-top: none;
  width: calc(100vw - 32px);

  &.arn-my-account_accordion-items-container {
    border: 1px solid ${theme.palette.text.primary};
    border-top: none;
    * {
      color: ${theme.palette.text.primary};
    }
  }  
`}
`
