import * as yup from 'yup'

import { IFormField } from '../types/form'
import { TFunction } from 'i18next'
import { CheckoutAddressFormField } from '../types/checkout'

export const FORM_I18N_INVALID_MSG_BASE = 'AddressForm.Msgs.'
export const FORM_I18N_FORM_FIELDS_INVALID_MSGS: Partial<
  Record<CheckoutAddressFormField['fieldName'], string>
> = {
  email1: 'InvalidEmail',
  phone1: 'InvalidPhone',
  addressLine1: 'InvalidAddress',
  zipCode: 'InvalidZipCode',
  city: 'InvalidCity',
  firstName: 'InvalidFirstName',
  lastName: 'InvalidLastName',
}

export const buildYupValidationSchema = <FormFieldType extends IFormField>({
  formFields,
  i18nInvalidMsgBase,
  i18nFormFieldsInvalidMsgs,
  t,
}: {
  formFields: FormFieldType[]
  i18nInvalidMsgBase: string
  i18nFormFieldsInvalidMsgs: Record<string, string>
  t: TFunction
}) => {
  const yupObjectFields = formFields.reduce((prev, curr) => {
    let currentYupSchema = yup.string()
    const { fieldName } = curr
    const invalidMessage = t(
      i18nInvalidMsgBase + (i18nFormFieldsInvalidMsgs[fieldName] || 'InvalidFormat')
    )

    if (curr.mandatory === 'true') {
      currentYupSchema = currentYupSchema.required(invalidMessage)
    }

    if (curr.validation) {
      currentYupSchema = currentYupSchema.matches(new RegExp(curr.validation), {
        excludeEmptyString: true,
        message: invalidMessage,
      })
    }

    return { ...prev, [fieldName]: currentYupSchema }
  }, {} as Record<keyof FormFieldType['fieldName'], yup.StringSchema>)

  return yup.object().shape(yupObjectFields)
}

const passwordSchemaFieldNames = {
  MINIMUM_LENGTH: 'minimumLength',
  UPPERCASE: 'uppercase',
  LOWERCASE: 'lowercase',
  NUMBER: 'number',
  SPECIAL_CHARACTER: 'specialCharacter',
} as const

export const passwordValidationFields = {
  [passwordSchemaFieldNames.MINIMUM_LENGTH]: {
    regexp: /.{8,}/,
    message: 'RegistrationLayout.Msgs.Requirements.MinimumLength',
  },
  [passwordSchemaFieldNames.UPPERCASE]: {
    regexp: /[A-Z]/,
    message: 'RegistrationLayout.Msgs.Requirements.Uppercase',
  },
  [passwordSchemaFieldNames.LOWERCASE]: {
    regexp: /[a-z]/,
    message: 'RegistrationLayout.Msgs.Requirements.Lowercase',
  },
  [passwordSchemaFieldNames.NUMBER]: {
    regexp: /\d/,
    message: 'RegistrationLayout.Msgs.Requirements.Number',
  },
  [passwordSchemaFieldNames.SPECIAL_CHARACTER]: {
    regexp: /[!@#$%^&*)(+=._\-£§€:;<>?\[\]`{|}~"'/]/,
    message: 'RegistrationLayout.Msgs.Requirements.SpecialCharacter',
  },
}

export const passwordValidationSchema = yup
  .string()
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.MINIMUM_LENGTH].regexp,
    passwordSchemaFieldNames.MINIMUM_LENGTH
  )
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.NUMBER].regexp,
    passwordSchemaFieldNames.NUMBER
  )
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.LOWERCASE].regexp,
    passwordSchemaFieldNames.LOWERCASE
  )
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.UPPERCASE].regexp,
    passwordSchemaFieldNames.UPPERCASE
  )
  .matches(
    passwordValidationFields[passwordSchemaFieldNames.SPECIAL_CHARACTER].regexp,
    passwordSchemaFieldNames.SPECIAL_CHARACTER
  )
