import React, { FC, useEffect, useState } from 'react'
import { passwordValidationSchema } from '../../../constants/form'
import { CustomInputFieldProps } from '../../../types/inputFields'
import { StyledTextField } from '../../StyledUI'
import PasswordRequirements from './PasswordRequirements'

type InputWithPasswordRequirementsProps = CustomInputFieldProps & {
  errorMessage?: string
  value?: string
  onValidationChange?(isValid: boolean, errorNames: string[]): void
}

const InputWithPasswordRequirements: FC<InputWithPasswordRequirementsProps> = ({
  errorMessage,
  value,
  onValidationChange,
  ...props
}) => {
  const [arePasswordRequirementsVisible, setPasswordRequirementsVisible] = useState<boolean>(false)
  const [passwordErrorsNames, setPasswordErrorsName] = useState<string[]>([])

  useEffect(() => {
    passwordValidationSchema
      .validate(value, { abortEarly: false })
      .then(() => setPasswordErrorsName([]))
      .catch((e) => setPasswordErrorsName(e.errors))
  }, [value])

  useEffect(() => {
    if (typeof onValidationChange === 'function') {
      onValidationChange(!passwordErrorsNames.length, passwordErrorsNames)
    }
  }, [passwordErrorsNames.length])

  return (
    <>
      <StyledTextField
        {...props}
        type="password"
        error={!props.ispristine && (!!errorMessage || !!passwordErrorsNames.length)}
        value={value}
        onFocus={() => setPasswordRequirementsVisible(true)}
        onBlur={() => setPasswordRequirementsVisible(!!value)}
      />

      {arePasswordRequirementsVisible && (
        <PasswordRequirements
          errorsFieldNames={passwordErrorsNames}
          isInputInitialized={!!value?.length}
        />
      )}
    </>
  )
}

export default InputWithPasswordRequirements
