import { useState } from 'react'
import { IVideoMedia } from '../../types/teaser'

const usePlayerBanner = (video?: IVideoMedia) => {

  const [isPlaying, setIsPlaying] = useState(video?.autoplay ?? false)
  const [muted, setMuted] = useState(video?.mute ?? false)

  const onPlayerActionClick = () => {
    if (isPlaying) {
      setIsPlaying(false)
    } else {
      setIsPlaying(true)
    }
  }

  const onMouseEnter = video?.playOnHover
    ? () => {
        if (!isPlaying) {
          setIsPlaying(true)
        }
      }
    : undefined

  const onMouseLeave = video?.playOnHover
    ? () => {
        if (isPlaying) {
          setIsPlaying(false)
        }
      }
    : undefined

  return {
    isPlaying,
    setIsPlaying,
    setMuted,
    muted,
    events: {
      onMouseEnter,
      onMouseLeave,
      onPlay: () => setIsPlaying(true),
      onPause: () => setIsPlaying(false),
      toggleMuted: () => setMuted(!muted)
    },
  }
}

export type TPlayerBannerHook = ReturnType<typeof usePlayerBanner>

export default usePlayerBanner
