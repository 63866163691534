import 'react-lazy-load-image-component/src/effects/blur.css'

import React, { CSSProperties, useMemo } from 'react'

import { Attachment } from '../../types/product'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import config from '../../configs/config.base'
import mockedData from './mocked-attachments'
import queryString from 'query-string'
import { useSite } from '../../foundation/hooks/useSite'

export type PictureType =
  | 'quarter'
  | 'front'
  | 'lateral'
  | 'closed front'
  | 'back'
  | 'alternative'
  | 'folding'
  | 'folding group'
  | 'group'
  | 'OnModel'
  | 'adv'

type ProductImageUsage = 'PDP' | 'PLP' | 'Thumbnail'

export type ProductImageProps = {
  alt?: string
  attachments?: Attachment[]
  attr?: object
  draggable?: boolean
  mpolicy?: string
  noImageStyle?: CSSProperties
  pictureType?: PictureType
  sequence?: string
  style?: any
  isTransparent?: boolean
  usage?: ProductImageUsage
  width?: number
  onClick?: () => void
}

const ProductImage = ({
  alt,
  attachments,
  width = 300,
  pictureType,
  sequence,
  usage = 'PDP',
  attr,
  noImageStyle,
  draggable,
  isTransparent,
  ...rest
}: ProductImageProps) => {
  const { mySite: site } = useSite()
  const damDomain: string = site?.xStoreCfg?.['damDomain'] || config.defaultDamDomain



  if (attachments === undefined && config.useMockedAttachments !== 'false') {
    attachments = mockedData.attachments
  }

  const attachment = attachments?.find((attachment) => {
    return (
      attachment.usage?.toLowerCase() === usage.toLowerCase() &&
      (pictureType ? attachment.name.toLowerCase() === pictureType.toLowerCase() : true) &&
      (sequence ? attachment.sequence.toLowerCase() === sequence.toLowerCase() : true)
    )
  })

  const url404 = process.env.PUBLIC_URL + '/images/common/404.png'
  const imagePath = attachment ? damDomain + attachment.attachmentAssetPathRaw : url404


  const getImageUrl = (width: number) => {
    return queryString.stringifyUrl({
      url: imagePath.toString(),
      query: {
        impolicy: 'ARN_resize',
        wid: width * 2 ,
        ...attr,
      },
    })
  }

  const altToSet = useMemo<string>(() => alt ?? imagePath, [alt, imagePath])

  return imagePath !== url404 ? (
    <LazyLoadImage
      alt={altToSet}
      src={getImageUrl(width)}
      effect="opacity"
      draggable={draggable}
      width="100%"
      {...rest}
    />
  ) : (
    <img alt={altToSet}  src={getImageUrl(width)} {...rest} style={noImageStyle} draggable={draggable} />
  )
}

export default ProductImage
