import { sanitizeHtml } from './common'
import { HeaderNavigationItem, Navigation, NavigationItem } from '../types/menu'

const parseMenuItem = (naviItem: NavigationItem): HeaderNavigationItem => {
  return {
    title: naviItem.title || '',
    href: naviItem.formattedUrl || '',
    id: naviItem.id,
    isHighlightedInNav: !!naviItem.isHighlightedInNav,
    categoryId: naviItem.categoryId || '',
    parentCategoryId: naviItem.parentCategoryId || '',
    hidden: naviItem.hidden,
    children: naviItem.children?.map((item) => parseMenuItem(item)),
    hiddenInDesktopNavigation: naviItem.hiddenInDesktopNavigation || false,
    teaserText: naviItem.teaserText ? sanitizeHtml(naviItem.teaserText) : '',
  }
}

export const parseMenuItems = (navigation: Navigation): HeaderNavigationItem[] => {
  return navigation.children?.map((item) => parseMenuItem(item)) || []
}
