import { useEffect } from 'react'
import Log from '../../services/Log'
import { executeOnce } from '../../utils/common'
import { wordLiftKeySelector, wordLiftUrlSelector } from '../../redux/selectors/site'
import { useSelector } from 'react-redux'

export const useWordLift = () => {
    const isWordLiftEnabledConf = useSelector(wordLiftKeySelector)
    const worldLiftUrl = useSelector(wordLiftUrlSelector)
    const isWordLiftEnabled = isWordLiftEnabledConf

  
    const loadScript = () => {
      return executeOnce(() => {
        const headFragment = document.createDocumentFragment()
        const wordLiftTag = document.createElement('script')
        // let monetateT = new Date().getTime()
        wordLiftTag.src = worldLiftUrl||''
  
        headFragment.appendChild(wordLiftTag)
        document.head.appendChild(headFragment)
      }, 'arn-wordlift')()
    }
  
    useEffect(() => {
      if (isWordLiftEnabled) {
        try {
          loadScript()
        } catch (e) {
          Log.error(window.location.href, '[WORDLIFT] LOAD SCRIPT ERROR ', e.message)
        }
      }
    }, [isWordLiftEnabled])
  }