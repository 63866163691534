import * as ERRORS from '../../../constants/errors'
import {
  CANCEL_SESSION_ERROR_ACTION,
  CMS_API_ERROR_ACTION,
  HANDLE_CONNECTION_TIMEDOUT_ERROR_ACTION,
  HANDLE_SESSION_ERROR_ACTION,
  RESET_ERROR_SUCCESS_ACTION,
  RESET_SESSION_POPUP_LOGON_ERROR_ACTION,
  VALIDATION_ERROR_ACTION,
} from '../../actions/error'
import { put, select } from 'redux-saga/effects'
import { defaultStates } from '../../reducers/initStates'
import { sessionErrorSelector } from '../../selectors/error'

let handlingError = false

const userLoggedOutConsoleLog = (errorMessage: string): void =>
  // eslint-disable-next-line no-console
  console.log(
    '%c[SESSION ERROR] USER LOGGED OUT - ',
    'color: red; font-weight: bold;',
    errorMessage
  )

/**
 * Saga worker to invoke get orders API
 */
export function* handleAxiosErrors(action: any) {
  const { payload: error } = action
  const isLogoff =
    error.config &&
    error.config.url.endsWith('loginidentity/@self') &&
    error.config.method.toLowerCase() === 'delete'

  //ignore logoff error
  if (error.isAxiosError && !handlingError && !isLogoff) {
    handlingError = true
    const errorResponse = error.response
    const isCmsError = errorResponse?.data?.name === 'CMS'

    if (errorResponse && errorResponse.data && errorResponse.data.errors) {
      const e = errorResponse.data.errors[0]
      const { errorCode, errorKey, errorMessage } = e

      // handle expired session
      if (
        errorCode === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
        errorKey === ERRORS.EXPIRED_ACTIVITY_TOKEN_ERROR ||
        errorCode === ERRORS.PARTIAL_AUTHENTICATION_ERROR_CODE ||
        errorKey === ERRORS.PARTIAL_AUTHENTICATION_ERROR_KEY ||
        errorCode === ERRORS.ACTIVITY_TOKEN_ERROR_CODE ||
        errorKey === ERRORS.ACTIVITY_TOKEN_ERROR_KEY
      ) {
        userLoggedOutConsoleLog(errorMessage)

        yield put(
          CANCEL_SESSION_ERROR_ACTION({
            ...e,
            handled: false,
            errorTitleKey: 'SessionError.TimeoutTitle',
            errorMsgKey: 'SessionError.TimeoutMsg',
          })
        )
      }
      // handle invalid session where another user logged in from different location
      else if (
        errorCode === ERRORS.INVALID_COOKIE_ERROR_CODE &&
        errorKey === ERRORS.INVALID_COOKIE_ERROR_KEY
      ) {
        userLoggedOutConsoleLog(errorMessage)

        yield put(
          CANCEL_SESSION_ERROR_ACTION({
            ...e,
            handled: false,
            errorTitleKey: 'SessionError.InvalidTitle',
            errorMsgKey: 'SessionError.InvalidMsgB2C',
          })
        )
      }
      // handle password expired issue.
      else if (
        errorCode === ERRORS.PASSWORD_EXPIRED_ERR_CODE ||
        errorKey === ERRORS.PASSWORD_EXPIRED
      ) {
        //reset password dialog
        userLoggedOutConsoleLog(errorMessage)

        yield put(
          CANCEL_SESSION_ERROR_ACTION({
            ...e,
            handled: false,
            errorTitleKey: 'reset.title',
            errorMsgKey: 'reset.errorMsg',
          })
        )
        yield put(
          HANDLE_SESSION_ERROR_ACTION({
            ...e,
            handled: false,
            errorTitleKey: 'SessionError.GenericTitle',
          })
        )
      }
      else if (errorCode === ERRORS.INVALID_VALIDATION_CODE_ERROR_CODE) {
        yield put(
          HANDLE_SESSION_ERROR_ACTION({
            ...e,
            handled: false,
            errorTitleKey: 'SessionError.GenericTitle',
          })
        )
      } else {
        //other errors
        yield put(
          HANDLE_SESSION_ERROR_ACTION({
            ...e,
            handled: false,
            errorTitleKey: 'SessionError.GenericTitle',
          })
        )
      }
    } else {
      if (error.code && error.code === ERRORS.CONNECTION_TIMEDOUT_ERROR) {
        const payload = {
          errorCode: error.code,
          errorMessage: error.toLocaleString(),
          errorParameters: {
            url: error.config?.url,
          },
          handled: false,
        }
        // eslint-disable-next-line no-console
        console.log(
          '%c[CONNECTION ERROR] - ',
          'color: red; font-weight: bold;',
          payload.errorMessage
        )

        yield put(HANDLE_CONNECTION_TIMEDOUT_ERROR_ACTION(payload))
      } else if (isCmsError) {
        yield put(
          CMS_API_ERROR_ACTION({
            errorMessage: `CMS ERROR - ${errorResponse.data?.message.toLocaleString()}`,
            handled: false,
          })
        )
      } else {
        const payload = {
          errorMessage: error.toLocaleString(),
          errorParameters: {
            url: error.config?.url,
          },
          handled: false,
        }

        userLoggedOutConsoleLog(payload.errorMessage)

        yield put(HANDLE_SESSION_ERROR_ACTION(payload))
      }
    }

    setTimeout(() => {
      if (handlingError) {
        handlingError = false
      }
    }, 1000)
  }
}

export function* handleCMCSessionError() {
  const payload = {
    errorKey: ERRORS.CMC_SESSION_ERROR_KEY,
    handled: false,
    errorTitleKey: 'SessionError.InvalidTitle',
    errorMsgKey: 'SessionError.InvalidMsg',
  }
  yield put(HANDLE_SESSION_ERROR_ACTION(payload))
}

export function* resetError() {
  const sessionErrorObject = yield select(sessionErrorSelector)
  if (!sessionErrorObject.errorKey && !sessionErrorObject.errorCode) {
    //do not reset session error
    const sessionError = { ...defaultStates.error }
    yield put(RESET_ERROR_SUCCESS_ACTION(sessionError))
  } else {
    yield put(RESET_SESSION_POPUP_LOGON_ERROR_ACTION())
  }
}

export function* resetSessionError() {
  const sessionErrorObject = yield select(sessionErrorSelector)
  if (sessionErrorObject.errorKey || sessionErrorObject.errorCode) {
    //reset session error
    const sessionError = { ...defaultStates.error }
    yield put(RESET_ERROR_SUCCESS_ACTION(sessionError))
  }
}

export function* handleValidationError(action: any) {
  const payload = action.payload
  yield put(VALIDATION_ERROR_ACTION(payload))
}
