import { RoundButton, StyledLink, StyledTextField, StyledTypography } from '../../../StyledUI'
import styled from 'styled-components'
import theme from '../../../../themes'

export const Container = styled.div`
  width: 33vw;
  display: grid;
  grid-template-rows: auto 1fr 100px;
  flex: 1;
  border: 1px solid ${theme.palette.primary.dark};
  background-color: ${theme.palette.primary.contrastText};

  ${theme.breakpoints.down('sm')} {
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
  }
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding: ${theme.spacing(2) + 1}px ${theme.spacing(2)}px ${theme.spacing(2) + 1}px
    ${theme.spacing(3)}px;
  border-bottom: 1px solid ${theme.palette.primary.dark};
  min-width: 491px;

  ${theme.breakpoints.down('sm')} {
    padding: 0 ${theme.spacing(1)}px;
  }
  ${theme.breakpoints.down('md')} {
    min-width: 0;
  }
`

export const Title = styled(StyledTypography)`
  font-size: 1.75rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.palette.text.light};
`

export const Content = styled.div`
  padding: ${theme.spacing(2)}px ${theme.spacing(3)}px;
  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(1)}px;
  }
`

export const Image = styled.img`
  margin-top: 10px;
  width: 100%;
  height: auto;
`

export const EmailField = styled(StyledTextField)`
  height: 50px;

  label {
    color: ${({ theme }) => theme.palette.custom.black};
    &.Mui-focused {
      color: ${({ theme }) => theme.palette.custom.black};
    }

    &:not(.Mui-focused)[data-shrink='true'] {
      color: ${({ theme }) => theme.palette.custom.black};
    }
  }

  input {
    color: ${({ theme }) => theme.palette.custom.black};
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      caret-color:${({ theme }) => theme.palette.custom.black};
      -webkit-text-fill-color: ${({ theme }) => theme.palette.custom.black};
      -webkit-box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.primary.contrastText} inset`};
      box-shadow: ${({ theme }) => `0 0 0 30px ${theme.palette.primary.contrastText} inset`};
    }
  }
  & > div {
    fieldset {
    border: 1px solid ${theme.palette.custom.black};
  }
    &.Mui-focused {
      fieldset {
        border: 1px solid ${theme.palette.custom.black} !important;
      }
    }
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.custom.black};
      }
    }
  }
`

export const Footer = styled.div`
  padding-left: ${theme.spacing(3)}px;
  padding-right: ${theme.spacing(3)}px;
  padding-bottom: ${theme.spacing(2)}px;
  ${theme.breakpoints.down('sm')} {
    padding: ${theme.spacing(1)}px;
  }
  font-size: 0.75rem;
  line-height: normal;
  color: ${({ theme }) => theme.palette.text.light};
`

export const Link = styled(StyledLink)`
  text-decoration: underline;
  font-size: 0.75rem;
  color: ${theme.palette.primary.dark};
`

export const SubscribeButton = styled(RoundButton)`
  width: 100%;
  border: none;
  background-color: ${theme.palette.primary.dark};
  color: ${theme.palette.primary.light};
  width: 100%;
  margin-top: 1rem;
`

type ResultProps = {
  error?: boolean
}
export const Result = styled(StyledTypography) <ResultProps>`
  margin: ${({ theme }) => theme.spacing(1)}px 0;
  color: ${(props) => (props.error ? 'red' : theme.palette.primary.dark)};
`

export const Description = styled(StyledTypography)`
  font-size: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.text.light};
`
