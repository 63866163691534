import React, { createContext, useContext, useState } from 'react'

type ContextType = {
  isOpenPrescriptionLensesForm: boolean
  isLoadingConfigurator: boolean
  setPrescriptionLensesFormOpen(value: boolean): void
  toggleLoadingConfigurator: () => void
}

const _throw = (m: string) => {
  throw new Error(m)
}

const PrescriptionLensesContext = createContext<ContextType | undefined>(undefined)

const PrescriptionLensesProvider: React.FC = ({ children }) => {
  const [isOpenPrescriptionLensesForm, setIsOpenPrescriptionLensesForm] = useState<boolean>(false)
  const [isLoadingConfigurator, setIsLoadingConfigurator] = useState<boolean>(false)

  const toggleLoadingConfigurator = () => setIsLoadingConfigurator((v) => !v)

  return (
    <PrescriptionLensesContext.Provider
      value={{
        isLoadingConfigurator,
        isOpenPrescriptionLensesForm,
        setPrescriptionLensesFormOpen: setIsOpenPrescriptionLensesForm,
        toggleLoadingConfigurator,
      }}>
      {children}
    </PrescriptionLensesContext.Provider>
  )
}

const usePrescriptionLenses = () => {
  const context = useContext(PrescriptionLensesContext)

  return context || _throw('Init PrescriptionLensesContext first.')
}

export default PrescriptionLensesProvider

export { usePrescriptionLenses }
