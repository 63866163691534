/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const PRODUCT_DISPLAY = 'ProductDisplay'
export const CATEGORY_DISPLAY = 'CategoryDisplay'
export const SEARCH_DISPLAY = 'SEARCH_DISPLAY'
export const HCL_Dx_PREFIX: string = 'dx://'
export const DX_HOST: string = '/dx/'
export const CONTENT_TYPE_VIDEO = 'Commerce Video Content'
export const CONTENT_FORMAT_ID = {
  STORE_TEXT: '2',
  EXTERNAL: '6',
}
export const CONTENT_TEMPLATE = {
  IMAGE: 'Image',
  IMAGE_USAGE: 'Image Usage',
  IMAGE_ALTERNATE_URL: 'Image Alternate URL',
  TEXT: 'Text',
  TEXT_ELEMENT_WIDTH: 'Text Element Width',
  TEXT_ALIGNMENT: 'Text Alignment',
  TEXT_ELEMENT_PADDING_DESKTOP: 'Text Element Padding (Desktop)',
  TEXT_ELEMENT_PADDING_TABLET: 'Text Element Padding (Tablet)',
  TEXT_ELEMENT_PADDING_MOBILE: 'Text Element Padding (Mobile)',
  BUTTON_LINK: 'Button Link',
  BUTTON_TEXT_DISPLAY: 'Button Text to Display',
  BUTTON_APPEARANCE: 'Button Appearance',
  HEIGHT_DESKTOP: 'Height (Desktop)',
  HEIGHT_TABLET: 'Height (Tablet)',
  HEIGHT_MOBILE: 'Height (Mobile)',
  ELEMENT_ALIGNMENT: 'Element Alignment',
  BORDER_RADIUS: 'Border Radius',
  DROP_SHADOW: 'Drop Shadow',
  LAYOUT_DIRECTION: 'Layout Direction',
  VIDEO: 'Video',
  VIDEO_ALTERNATE_URL: 'Video Alternate URL',
  VIDEO_POSTER: 'Video Poster',
  VIDEO_POSTER_ALTERNATE_URL: 'Video Poster Alternate URL',
  VIDEO_USAGE: 'Yes',
  VIDEO_AUTOPLAY: 'Video Auto-play',
  VIDEO_AUTOPLAY_YES: 'Yes',
  VIDEO_CONTROLS: 'Video Controls',
  VIDEO_VOLUME: 'Video Volume',
  ML_TRANSLATION: 'ML Translations',
  USE_AS_BACKGROUND_IMAGE: 'Use as background image',
  USE_AS_BACKGROUND_VIDEO: 'Use as Background Video',
  SHOW_VIDEO_CONTROLS: 'Show video controls',
  NOT_MUTED: 'Not muted',
  VIDEO_TEMPLATE_ID: 'd78c2265-d156-4798-b30b-fe4b9145b554',
  PRIMARY_BUTTON: 'Primary Button',
  SECONDARY_BUTTON: 'Secondary Button',
  TEXT_LINK: 'Text Link',
  USE_ENTIRE_CONTENT_AREA_AS_LINK: 'Do not show button - Use entire content area as a link',
  USE_DROP_SHADOW: 'Use drop shadow',
  SHOW_DROP_SHADOW: 'Show drop shadow',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TEXT_APPEARANCE: 'text',
  AREA: 'area',
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
}
