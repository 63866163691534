import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;

  border: 1px solid ${({ theme }) => theme.palette.custom.warmGrey};
  background-color: ${({ theme }) => theme.palette.primary.dark};

  display: flex;

  ${({ theme }) => `${theme.breakpoints.down('sm')} {
    & > img {
      display: none;
    }

    & > div {
      width: 100%;
    }
  }`}
`

export const StyledFallback = styled.div`
  width: 100%;
  height: 100vh;

  display: grid;
  place-content: center;

  color: ${({ theme }) => theme.palette.custom.geckoGreen};

  font-size: 2rem;
  text-align: center;
`

export const StyledImage = styled.img`
  max-width: 50%;
`

export const StyledBodyContainer = styled.div`
  width: 50%;
  padding: 15rem;

  a {
    color: ${({ theme }) => theme.palette.custom.warmGrey};
  }

  ${({ theme }) => `
    ${theme.breakpoints.down('lg')} {
      padding: 8rem;

    }

    ${theme.breakpoints.down('md')} {
      padding: 7rem;
    }

    ${theme.breakpoints.down('sm')} {
      padding: 3rem 5rem 3rem 5rem;
    }

    ${theme.breakpoints.down('xs')} {
      padding: 1.25rem 1rem 3rem 1rem;
    }
  `}

  & > :nth-child(1) {
    margin-bottom: 1rem;
    ${({ theme }) => theme.breakpoints.down('lg')} {
      max-width: 458px;
    }
    ${({ theme }) => theme.breakpoints.up('lg')} {
      min-width: 458px;
    }
  }
  & > :nth-child(2) {
    margin-bottom: 1.5rem;
    ${({ theme }) => theme.breakpoints.down('lg')} {
      max-width: 458px;
    }
    ${({ theme }) => theme.breakpoints.up('lg')} {
      min-width: 458px;
    }
  }
`

export const StyledTitle = styled.h2`
  font-size: 2rem;
  font-weight: normal;
  margin-top: 0;
  line-height: 1;

  color: ${({ theme }) => theme.palette.custom.geckoGreen};
  text-transform: uppercase;
`

export const StyledSubTitle = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.custom.warmGrey};

  p:first-child {
    margin-bottom: 40px;
  }

  a {
    font-size: 18px;
    line-height: 1.78;
    text-decoration: underline;
    cursor: pointer;
  }
`
