//Standard libraries
import Axios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios'
import { FOR_USER_ID, LANGID, SKIP_WC_TOKEN_HEADER, WC_PREVIEW_TOKEN } from '../constants/common'
import { INTERNAL_SERVER_ERROR, NOT_FOUND, UNAUTHORIZED } from 'http-status-codes'
import {
  localStorageUtil,
  storageSessionHandler,
  storageStoreIdHandler,
} from '../utils/storageUtil'

//Custom libraries
import { CommerceEnvironment } from '../../constants/common'
import { GUEST_LOGIN_SUCCESS_ACTION } from '../../redux/actions/user'
import { PERSONALIZATION_ID } from '../constants/user'
//Redux
import { WATCH_AXIOS_ERROR_ACTION } from '../../redux/actions/error'
//Foundation libraries
import { axiosHeaderIgnoredServices } from '../configs/axiosHeaderIgnoredService'
import config from '../../configs'
import { getSite } from '../hooks/useSite'
import guestIdentityService from '../apis/transaction/guestIdentity.service'
import i18n from 'i18next'
import { parse as losslessParse } from 'lossless-json'
import { numberParserRequiredServices } from '../configs/numberParserRequiredService'
import { site } from '../constants/site'
import tealiumService from '../analytics/tealium/tealium.service'
import { userRequiredServices } from '../configs/userRequiredService'

const GUEST_IDENTITY: string = 'guestidentity'
const SHIPPING_INFO: string = 'usable_shipping_info'

const isServiceInList = (request: AxiosRequestConfig, serviceList: string[]) => {
  const url = request.url === undefined ? '' : request.url
  if (url.length > 0) {
    const storePath = `${site.transactionContext}/store/`
    const path = url.split(storePath).pop()
    if (path && path.length > 0) {
      const serviceName = path.split('/')[1]
      return serviceList.indexOf(serviceName) > -1
    }
  }
  return false
}

const isUserRequiredService = (request: AxiosRequestConfig) => {
  return isServiceInList(request, userRequiredServices)
}

const isNumberParserRequiredService = (request: AxiosRequestConfig) => {
  return isServiceInList(request, numberParserRequiredServices)
}

const dispatchObject = {
  _dispatch: null,
  set dispatch(dispatch: any) {
    this._dispatch = dispatch
  },
  get dispatch(): any {
    return this._dispatch
  },
}

const processForUserParameter = (params: URLSearchParams) => {
  // Log.debug('PROCESS FOR USER PARAMETER')
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  if (currentUser && currentUser.forUserId) {
    params.set(FOR_USER_ID, currentUser.forUserId)
  }
}

const processTransactionHeader = (header: any) => {
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  if (currentUser) {
    if (!header['WCTrustedToken']) {
      header['WCTrustedToken'] = currentUser.WCTrustedToken
    }
    if (!header['WCToken']) {
      header['WCToken'] = currentUser.WCToken
    }
    if (!header['WCPersonalization']) {
      header['WCPersonalization'] = currentUser.personalizationID
    }
  }
  if (!header['WCPersonalization']) {
    const personalizationID = localStorageUtil.get(PERSONALIZATION_ID)
    if (personalizationID !== null) {
      header['WCPersonalization'] = personalizationID
    }
  }
  const previewToken = storageSessionHandler.getPreviewToken()
  if (previewToken && previewToken[WC_PREVIEW_TOKEN]) {
    header['WCPreviewToken'] = previewToken[WC_PREVIEW_TOKEN]
  }
}

const processSearchHeader = (header: any) => {
  const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
  if (currentUser) {
    if (!header['WCTrustedToken']) {
      header['WCTrustedToken'] = currentUser.WCTrustedToken
    }
    if (!header['WCToken']) {
      header['WCToken'] = currentUser.WCToken
    }
  }
  const previewToken = storageSessionHandler.getPreviewToken()
  if (previewToken && previewToken[WC_PREVIEW_TOKEN]) {
    header['WCPreviewToken'] = previewToken[WC_PREVIEW_TOKEN]
  }
}

const transformNumberResponse = function (data) {
  if (typeof data === 'string') {
    data = losslessParse(data, (_, value) => {
      if (value && value.isLosslessNumber) {
        return value.toString()
      } else {
        return value
      }
    })
  }
  return data
}

const useSnackbarHandleError = (error: AxiosError) => {
  if (error.config) {
    const { skipErrorSnackbar } = error.config as any
    if (
      skipErrorSnackbar === true &&
      error.response &&
      error.response.status < INTERNAL_SERVER_ERROR
      // status 500 and above will be handled by snackbar
    ) {
      return false
    }
  }
  return !(error.isAxiosError && error.response && error.response.status === NOT_FOUND)
}

const initAxios = (dispatch: any) => {
  dispatchObject.dispatch = dispatch

  // In order to correctly use the Akamai Bot Protection feature,
  // all fetch/XHR calls to BE must send cookies along
  // https://luxotticaretail.atlassian.net/browse/ARNETTE-2810

  // TODO REMOVED BECAUSE WE HAV AN INFRA ISSUE
  Axios.defaults.withCredentials = process.env.NODE_ENV === 'development' ? false : true

  Axios.interceptors.request.use(
    (request: AxiosRequestConfig) => {
      if (
        request.url?.startsWith(site.transactionContext) &&
        !isServiceInList(request, axiosHeaderIgnoredServices)
      ) {
        const header = request.headers
        if (!request[SKIP_WC_TOKEN_HEADER]) {
          processTransactionHeader(header)
        }
      }
      if (request.url?.startsWith(site.searchContext)) {
        const header = request.headers
        processSearchHeader(header)
      }
      return request
    },
    function (error: any) {
      return Promise.reject(error)
    }
  )
  Axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (!Axios.isCancel(error) && error.response?.status === UNAUTHORIZED) {
        const errorObject = error.response.data.errors[0]

        tealiumService.sendErrorEvent({
          Error_Code: 'Auth token has expired',
          Error_Source: 'Client',
          Error_Details: `url: ${error.config.url}; dataMessage: ${errorObject.errorMessage}; code: ${errorObject.errorCode}`,
        })
      }

      if (useSnackbarHandleError(error)) {
        dispatch(WATCH_AXIOS_ERROR_ACTION(error))
      }

      return Promise.reject(error)
    }
  )
}

const executeRequest = async <T = any>(
  request: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  request.timeout = config.apiCalltimeout
  const params: URLSearchParams = request.params
  processForUserParameter(params)
  //verify active storeId in localStorage.
  storageStoreIdHandler.verifyActiveStoreId()
  if (!params.has(LANGID)) {
    // add language Id
    const langId =
      getSite()?.defaultLanguageID ||
      CommerceEnvironment.reverseLanguageMap[
        i18n.languages[0].split('-').join('_')
      ]
    params.set(LANGID, langId)
  }

  if (isNumberParserRequiredService(request)) {
    request.transformResponse = [transformNumberResponse]
  }

  if (
    !request.url ||
    (request.url.indexOf(GUEST_IDENTITY) === -1 && request.url.indexOf(SHIPPING_INFO) === -1&&
      request.url.startsWith(site.transactionContext))
  ) {
    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()

    if (!currentUser && isUserRequiredService(request)) {
      const payload = { widget: 'axiosConfig' }

      const guestResponse: AxiosResponse = await guestIdentityService.login(
        payload
      )

      dispatchObject.dispatch(
        GUEST_LOGIN_SUCCESS_ACTION({ ...guestResponse.data, ...payload })
      )

      return Axios(request)
    } else {
      return Axios(request)
    }
  } else {
    return Axios(request)
  }
}

export { initAxios, executeRequest }
