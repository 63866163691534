import React, { useEffect } from 'react'
import styled from 'styled-components'
import { StyledIconButton, StyledLink, StyledTypography } from '../../StyledUI'
import { CloseIcon, ProfileIcon } from '../../StyledUI/Icons'
import { MY_ACCOUNT_DRAWER_TOGGLE_ACTION } from '../../../redux/actions/ui'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import theme from '../../../themes'
import Axios, { Canceler } from 'axios'
import * as userAction from '../../../redux/actions/user'
import getDisplayName from 'react-display-name'
import { useTranslation } from 'react-i18next'
import { useSite } from '../../../foundation/hooks/useSite'
import { wishlistEnabledSelector } from '../../../features/wishList/selector'
import { ACCOUNT_CHILDREN, BASE_COUNTRY_KEY, WISHLIST } from '../../../constants/routes'
import { generateAccountPagePath } from './Account'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { generatePath } from 'react-router'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { userDetailsSelector } from '../../../redux/selectors/user'

const Wrapper = styled.div<{ themeMode: string }>`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.custom.warmGrey};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 33vw;
  }
`
const Header = styled.div<{ themeMode: string }>`
  color: ${({ theme }) => theme.palette.custom.black};
  padding: 0 ${({ theme }) => theme.spacing(2)}px;
  display: grid;
  align-items: center;
  height: 100px;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom.black};
`
const Title = styled(StyledTypography)`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.spacing(1.5)}px;
  gap: ${({ theme }) => theme.spacing(1)}px;

  svg {
    font-size: ${({ theme }) => theme.spacing(3)}px;
  }
`

const Body = styled.div`
  padding: 30px ${({ theme }) => theme.spacing(3)}px;
`

const List = styled.div<{ themeMode: string }>`
  border: solid 1px ${({ theme }) => theme.palette.custom.black};
`
const ListItem = styled.div<{ themeMode: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.palette.custom.black};
  height: 65px;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }

  a {
    color: ${({ theme }) => theme.palette.custom.black};
  }
`

const MyAccountDrawerContent: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const addressDetails = useSelector(userDetailsSelector)
  const widgetName = getDisplayName(MyAccountDrawerContent)
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)
  const themeMode = useSelector(themeModeSelector, shallowEqual)

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const logOutUser = () => {
    let payload = {
      ...payloadBase,
    }
    dispatch(userAction.LOGOUT_REQUESTED_ACTION(payload))
  }

  const onCloseDrawer = () => dispatch(MY_ACCOUNT_DRAWER_TOGGLE_ACTION(false))

  useEffect(() => {
    if (mySite) {
      let payload = {
        ...payloadBase,
      }
      dispatch(userAction.FETCH_USER_DETAILS_REQUESTED_ACTION(payload))
    }
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [mySite])

  return (
    <Wrapper themeMode={themeMode}>
      <Header themeMode={themeMode}>
        <Title>
          <StyledIconButton>
            <ProfileIcon
              htmlColor={theme.palette.primary.dark}
              viewBox="0 0 24 24"
              height="24"
              width="24"
            />
          </StyledIconButton>
          {addressDetails?.firstName} {addressDetails?.lastName}
        </Title>
        <StyledIconButton data-analytics_available_call="0" onClick={onCloseDrawer}>
          <CloseIcon htmlColor={theme.palette.primary.dark} />
        </StyledIconButton>
      </Header>
      <Body>
        <List themeMode={themeMode} onClick={onCloseDrawer}>
          <ListItem themeMode={themeMode}>
            <StyledLink
              data-element-id="X_X_MyAccount_MyAccount"
              to={generateAccountPagePath(langCode)}>
              {t('Account.Title')}
            </StyledLink>
          </ListItem>
          {isWishlistEnabled && (
            <ListItem themeMode={themeMode}>
              <StyledLink
                data-element-id="X_X_MyAccount_WishList"
                to={generatePath(WISHLIST, { [BASE_COUNTRY_KEY]: langCode })}>
                {t('Account.WishList')}
              </StyledLink>
            </ListItem>
          )}
          <ListItem themeMode={themeMode}>
            <StyledLink
              data-element-id="X_X_MyAccount_Orders"
              to={generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ORDER_HISTORY)}>
              {t('Account.OrderHistory')}
            </StyledLink>
          </ListItem>
          <ListItem onClick={logOutUser}>
            <StyledLink data-element-id="X_X_MyAccount_SignOut" to="#">
              {t('Account.SignOut')}
            </StyledLink>
          </ListItem>
        </List>
      </Body>
    </Wrapper>
  )
}

export default MyAccountDrawerContent
