import styled from 'styled-components'

export const HTMLContainer = styled.div<{ fontColor?: string; textAlign: string }>`
  ${({ fontColor }) => !!fontColor && `color: ${fontColor};`}
  text-align: center;
  line-height: 1.5;

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: ${({ textAlign }) => textAlign};
  }
`
