export const REG_EX = {
  NUMERIC: /^[0-9]+$/,
  EMAIL: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
  PHONE: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, // /^[-+() ]*[0-9][-+() 0-9]*$/,
  PRICE: /^[0-9]+([.][0-9]{0,2})?$|^[.][0-9]{1,2}$/,
  NICKNAME_ALPHA_NUMERIC_SPECIAL_CHAR: /^[a-zA-Z0-9 ]*$/,
}

export const DEFINING = 'Defining'
export const DESCRIPTIVE = 'Descriptive'
export const OFFER = 'Offer'
export const DISPLAY = 'Display'
export const SEARCHTERM = 'searchTerm'

export const EMPTY_STRING = ''
export const STRING_TRUE = 'true'
export const STRING_FALSE = 'false'

//Address Form
export const CHECKOUT = 'checkout'
export const ADDRESS_BOOK = 'address-book'
export const ADDRESS_SHIPPING = 'Shipping'
export const ADDRESS_BILLING = 'Billing'
export const ADDRESS_SHIPPING_BILLING = 'ShippingAndBilling'
export const NEW_SHIPPINNG_ADDRESS = 'newAddress'
export const ADDRESS_LINE = 'addressLine'
export const ADDRESSLINE1 = 'addressLine1'
export const ADDRESSLINE2 = 'addressLine2'
export const ADDRESS_1 = 'address1'
export const ADDRESS_2 = 'address2'
export const PHONE1 = 'phone1'

//Address Form. Genders
export const GENDER_MALE = 'Male';
export const GENDER_FEMALE = 'Female';
export const GENDER_UNSPECIFIED = '-';

//Admin Tools
export const IBM_ASSIGNED_ROLE_DETAILS = 'IBM_Assigned_Roles_Details'
export const BUYER_ADMIN_ROLE = '-21'
export const BUYER_APPROVAL_ROLE = '-22'

export const ADDRESS_TYPE_MAP = new Map()
ADDRESS_TYPE_MAP.set(ADDRESS_SHIPPING, 'AddressBook.ShippingLabel')
ADDRESS_TYPE_MAP.set(ADDRESS_BILLING, 'AddressBook.BillingLabel')
ADDRESS_TYPE_MAP.set(ADDRESS_SHIPPING_BILLING, 'AddressBook.ShippingBillingLabel')

//keycodes
export const KEY_CODES = {
  UP: 38,
  DOWN: 40,
  ENTER: 13,
}

export const CommerceEnvironment = {
  categorySkeleton: {
    id: '',
    type: 'Child PIM categories',
    categoryInternal: {},
    eSpotInternal: {},
    eSpotDescInternal: {},
  },
  productSkeleton: {
    id: '',
    type: 'Product',
    productInternal: '',
    productDesc: {},
    eSpotInternal: {},
    eSpotDescInternal: {},
  },
  suggestionSkeleton: {
    arrIndex: '',
    id: '',
    type: 'Suggestion',
    name: '',
    url: {},
  },
  errorParamsSkeleton: {
    zero: '',
    one: '',
    two: '',
  },
  defaultLang: 'en_US' as const,
  languageMap: {
    '-1': 'en_US',
    '-2': 'fr_FR',
    '-3': 'de_DE',
    '-4': 'it_IT',
    '-5': 'es_ES',
    '-6': 'pt_BR',
    '-7': 'zh_CN',
    '-8': 'zh_TW',
    '-10': 'ja_JP',
    '-20': 'ru_RU',
    '-21': 'ro_RO',
    '-24': 'en_CA',
    '-39': 'de_AT',
    '-42': 'en_PT',
    '-43': 'en_GB',
    '-44': 'en_NL',
    '-45': 'en_IE',
    '-99': 'en_WW',
  },
  reverseLanguageMap: {
    en_US: '-1',
    fr_FR: '-2',
    de_DE: '-3',
    it_IT: '-4',
    es_ES: '-5',
    pt_BR: '-6',
    zh_CN: '-7',
    zh_TW: '-8',
    ja_JP: '-10',
    ru_RU: '-20',
    ro_RO: '-21',
    en_CA: '-24',
    de_AT: '-39',
    pt_PT: '-42',
    en_GB: '-43',
    nl_NL: '-44',
    en_IE: '-45',
    en_WW: '-99',
  },
  dxLanguageMap: {
    '-1': 'en',
    '-2': 'fr',
    '-3': 'de',
    '-4': 'it',
    '-5': 'es',
    '-6': 'pt',
    '-7': 'zh',
    '-8': 'zh-TW',
    '-10': 'ja',
    '-20': 'ru',
    '-21': 'ro',
    '-24': 'ca',
    '-49': 'at',
    '-42': 'pt',
    '-43': 'en',
    '-44': 'nl',
    '-45': 'en',
    '-99': 'en',
  },
  localeLangMap: [
    {
      countryCode: 'AT',
      langCode: 'de_AT',
    },
    {
      countryCode: 'CA',
      langCode: 'en_CA',
    },
    {
      countryCode: 'DE',
      langCode: 'de_DE',
    },
    {
      countryCode: 'ES',
      langCode: 'es_ES',
    },
    {
      countryCode: 'FR',
      langCode: 'fr_FR',
    },
    {
      countryCode: 'IE',
      langCode: 'en_IE',
    },
    {
      countryCode: 'IT',
      langCode: 'it_IT',
    },
    {
      countryCode: 'NL',
      langCode: 'en_NL',
    },
    {
      countryCode: 'PT',
      langCode: 'en_PT',
    },
    {
      countryCode: 'UK',
      langCode: 'en_UK',
    },
    {
      countryCode: 'US',
      langCode: 'en_US',
    },
    {
      countryCode: 'WW',
      langCode: 'en_WW',
    },
  ],
}

export const LANGUAGE_CODE_MAP = {
  en_US: 'en-us',
  fr_FR: 'fr-fr',
  de_DE: 'de-de',
  it_IT: 'it-it',
  es_ES: 'es-es',
  en_CA: 'en-ca',
  de_AT: 'de-at',
  en_UK: 'en-uk',
  en_IE: 'en-ie',
  en_WW: 'en-ww',
  en_PT: 'en-pt',
  en_NL: 'en-nl',
}

export const INVENTORY = {
  NON_ATP: -2,
}

export const SUCCESS_MSG_PREFIX = 'success-message.'
export const DEFAULT_LANG_ID = '-1'

// Shipping
export const IS_PERSONAL_ADDRESS_ALLOWED = 'x_isPersonalAddressesAllowedForShipping'
export const ORG_ADDRESS_DETAILS = 'orgAddressDetails'
export const ORG_ADDRESS = 'orgAddress'
export const ORDER_ID = 'orderid'
export const HYPHEN = '-'
export const UNDERSCORE = '_'

// Discover
export const DISCOVER_FEATURE = 'Discover'
//Image
export const IMAGE = 'IMAGE'
export const VIDEO = 'VIDEO'
export const ATTACHMENTS = 'Attachments'
//DX
export const DX_IMAGE_PATH_STARTS_WITH = '/dx/'
export const DX_IMAGE_THUMBNAIL_TRUE = '?thumbnail=true'
//Teaser
export const DASH_BUTTON_TEASER_VIEWTYPE = 'PLP_dash_button'
export const FULL_WIDTH_BANNER_VIEWTYPE = 'full-width-banner'
export const PLP_BOX_TEASER_VIEWTYPE = 'plp-box'

//Filters
export const GROUPING_PROFILE_NAME = 'LX_findProductsByCategory_Grouping'
export const GROUPING_DISABLED_PROFILE_NAME = 'LX_findProductsByCategory_With_Attachments'
export const SEARCH_TERM_GROUPING_PROFILE_NAME = 'LX_findProductsBySearchTerm_Grouping'
export const SEARCH_TERM_PROFILE_NAME = 'LX_findProductsBySearchTerm'
export const SUGGESTIONS_KEYWORDS_PROFILE_NAME = 'LX_findNavigationSuggestion_Keywords'
export const CLUSTERING_PROFILE = 'LX_findProductByPartNumber_Details_Grouping'
export const ITEM_DETAILS_PROFILE_NAME = 'LX_findItemByIds_Details'
export const PROFILE_NAME_PARAM = 'profileName'
export const SORTING_BY_PRICE_LOW = '3'
export const SORTING_BY_PRICE_HIGH = '4'
export const SORTING_BY_NEW_ARRIVALS = '5'
export const SORTING_NOT_CLUSTERED = [SORTING_BY_PRICE_LOW, SORTING_BY_PRICE_HIGH]
