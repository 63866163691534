import React from 'react'
import { CarouselProviderProps } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

import { StyledColorVariantsProvider, StyledColorVariantsSlider } from './ProductTile.style'

interface ColorVariantSliderProps extends CarouselProviderProps {}

const ColorVariantSlider: React.FC<ColorVariantSliderProps> = ({
  children,
  ...rest
}: ColorVariantSliderProps) => {
  return (
    <StyledColorVariantsProvider {...rest}>
      <StyledColorVariantsSlider aria-label='Color variants slider' trayTag='div'>{children}</StyledColorVariantsSlider>
    </StyledColorVariantsProvider>
  )
}

export default ColorVariantSlider
