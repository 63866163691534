import * as ROUTES from '../../constants/routes'

import { CloseIconWrapper, Container, LinkWrapper, Text } from './NewsletterStickyStyledComponents'
import React, { useState } from 'react'
import { match, useRouteMatch } from 'react-router-dom'

import { CloseIcon } from '../StyledUI/Icons'
import { NEWSLETTER_DRAWER_TOGGLE_ACTION } from '../../redux/actions/ui'
import { StyledButtonAsLink } from '../StyledUI'
import { localStorageUtil } from '../../foundation/utils/storageUtil'
import styled from 'styled-components'
import theme from '../../themes'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

type Props = {
  text: string
}

const StyledNewsletterCta = styled(StyledButtonAsLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`

const NewsletterSticky = ({ text }: Props) => {
  const [visible, setVisibility] = useState(true)
  const { t } = useTranslation()
  const homeMatch = useRouteMatch(ROUTES.HOME) as match
  const isHomePage = homeMatch.isExact
  const dispatch = useDispatch()
  const toggleNewsletterDrawer = () => {
    dispatch(NEWSLETTER_DRAWER_TOGGLE_ACTION(true))
  }

  const hide = () => {
    setVisibility(false)
    localStorageUtil.set('newsletterStickyHidden', true, 365)
  }

  return visible && isHomePage ? (
    <Container className="arn__newsletter-cta-container">
      <CloseIconWrapper>
        <CloseIcon
          htmlColor={theme.palette.primary.dark}
          data-analytics_available_call="0"
          onClick={hide}
        />
      </CloseIconWrapper>
      <Text title={text}>{text}</Text>
      <LinkWrapper>
        <StyledNewsletterCta
          data-element-id={'X_X_Overlay_NewsletterStrip_Signup'}
          onClick={toggleNewsletterDrawer}>
          {t('Newsletter.Subscribe')}
        </StyledNewsletterCta>
      </LinkWrapper>
    </Container>
  ) : (
    <></>
  )
}

export default NewsletterSticky
