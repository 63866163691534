//Standard libraries
import React, { useEffect, ChangeEvent, useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import Axios, { Canceler } from 'axios'
import getDisplayName from 'react-display-name'
//Foundation libraries
import personService from '../../../foundation/apis/transaction/person.service'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useSite } from '../../../foundation/hooks/useSite'
//Custom libraries
import { EMPTY_STRING } from '../../../constants/common'
//UI
import { RoundButton, StyledTextField } from '../../StyledUI'
import { shallowEqual, useSelector } from 'react-redux'
import { loginStatusSelector } from '../../../redux/selectors/user'
import { generatePath, Redirect } from 'react-router'
import { ACCOUNT, SIGNIN } from '../../../constants/routes'
import {
  ChangePasswordForm,
  StyledBackButton,
  StyledBodyContainer,
  StyledBodyHeader,
  StyledBodySuccessContainer,
  StyledContentWrapper,
  StyledErrorMessage,
} from './ChangePassword.style'
import { themeModeSelector } from '../../../redux/selectors/theme'
import InputWithPasswordRequirements from '../../widgets/password-requirements/InputWithPasswordRequirements'
import tealiumService from '../../../foundation/analytics/tealium/tealium.service'

const ChangePassword: FC = () => {
  const { langCode } = useStoreIdentity()
  const { mySite: site } = useSite()
  const widgetName = getDisplayName(ChangePassword)
  const loginStatus = useSelector(loginStatusSelector)
  const themeMode = useSelector(themeModeSelector, shallowEqual)

  const { t } = useTranslation()
  const title = t('ChangePassword.Title')
  const currentPasswordLabel = t('ChangePassword.CurrentPasswordLabel')
  const newPasswordLabel = t('ChangePassword.NewPasswordLabel')
  const verifyPasswordLabel = t('ChangePassword.VerifyPasswordLabel')
  const saveLabel = t('ChangePassword.SaveLabel')
  const successLabel = t('ChangePassword.SuccessLabel')
  const successCta = t('ChangePassword.SuccessCTA')

  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [verifyPassword, setVerifyPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')

  const [isSuccessSubmit, setIsSubmitSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isNewPasswordValid, setNewPasswordValid] = useState<boolean>(false)
  const [newPasswordErrorNames, setNewPasswordErrorNames] = useState<string[]>([])

  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const handleCurrentPasswordChange = (evt: ChangeEvent<HTMLInputElement>) =>
    setCurrentPassword(evt.target.value)

  const handleVerifyPasswordChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setVerifyPassword(evt.target.value)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (isFormValid) {
      try {
        const submitResult = await personService.updatePerson({
          responseFormat: 'application/json',
          storeId: site.storeID,
          body: {
            resetPassword: 'true',
            xcred_logonPasswordOld: currentPassword,
            logonPassword: newPassword,
            xcred_logonPasswordVerify: verifyPassword,
          },
          ...payloadBase,
        })

        setIsSubmitSuccess(submitResult.resourceName === 'person')
      } catch (e: any) {
        setErrorMessage(e?.response?.data.errors?.[0].errorMessage)
        return
      }
    } else {
      try {
        const errors = {
          currentPassword: !currentPassword.trim(),
          newPassword: !isNewPasswordValid,
          verifyPassword: !verifyPassword.trim() || newPassword !== verifyPassword,
        }

        const errorMessagesArray = Object.keys(errors).reduce((acc, cur) => {
          if (cur === 'newPassword' && errors[cur]) {
            return acc.concat(
              `${cur} (errors: ${newPasswordErrorNames.join(', ')}): ${t(
                'RegistrationLayout.Msgs.InvalidPassword1'
              )}`
            )
          }

          return acc.concat(`${cur}: ${t('ChangePassword.ErrorMessage')}`)
        }, [] as string[])

        tealiumService.sendErrorEvent({
          Error_Source: 'User',
          Error_Code: 'Form Filling Error',
          Error_Message: errorMessagesArray.join('; '),
          Error_Details: errorMessagesArray.join('; '),
        })
      } catch (error) {}

      setErrorMessage(t('ChangePassword.ErrorMessage'))
    }
  }

  const isFormValid =
    currentPassword.trim() !== EMPTY_STRING &&
    newPassword.trim() !== EMPTY_STRING &&
    verifyPassword.trim() !== EMPTY_STRING &&
    newPassword === verifyPassword &&
    isNewPasswordValid

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel())
    }
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('')
    }
  }, [isFormValid])

  if (!loginStatus) return <Redirect to={SIGNIN} />

  return (
    <StyledContentWrapper>
      <StyledBodyHeader>{title}</StyledBodyHeader>
      <StyledBodyContainer>
        {isSuccessSubmit ? (
          <StyledBodySuccessContainer>
            <p>{successLabel}</p>
            <StyledBackButton
              to={generatePath(ACCOUNT, { country: langCode })}>
              {successCta}
            </StyledBackButton>
          </StyledBodySuccessContainer>
        ) : (
          <ChangePasswordForm noValidate onSubmit={handleSubmit}>
            {!!errorMessage ? <StyledErrorMessage>{errorMessage}</StyledErrorMessage> : null}
            <StyledTextField
              required
              autoFocus
              type="password"
              themeMode={themeMode}
              error={!!errorMessage}
              value={currentPassword}
              label={currentPasswordLabel}
              onChange={handleCurrentPasswordChange}
            />
            <InputWithPasswordRequirements
              required
              ispristine={!newPassword.length}
              errorMessage={errorMessage}
              value={newPassword}
              label={newPasswordLabel}
              onChange={(e) => setNewPassword(e.target.value)}
              onValidationChange={(isValid, errorNames) => {
                if (isValid !== isNewPasswordValid) {
                  setNewPasswordValid(isValid)
                  setNewPasswordErrorNames(errorNames)
                }
              }}
            />
            <StyledTextField
              required
              type="password"
              themeMode={themeMode}
              error={!!errorMessage}
              value={verifyPassword}
              label={verifyPasswordLabel}
              onChange={handleVerifyPasswordChange}
            />
            <RoundButton
              data-element-id="X_X_ChangePassword_Update"
              variant="primary"
              type="submit"
              style={{ minWidth: '250px' }}>
              {saveLabel}
            </RoundButton>
          </ChangePasswordForm>
        )}
      </StyledBodyContainer>
    </StyledContentWrapper>
  )
}

export default ChangePassword
