export const PRODUCT_SOLDOUT_STATUS = {
  NONE: 'NONE',
  SOLDOUT: 'SOLD OUT',
  COMING_SOON: 'COMING SOON',
  COMING_BACK_SOON: 'COMING BACK SOON',
} as const

export const DEFAULT_DELIVERY_DATES = {
  PLANO_STANDARD: 5,
  RX_MAN: 2,
  RX_STANDARD: 7,
}

// lowercased values related to `FRAME_MATERIAL_CLASS` attribute
export const RECYCLABEL_FRAME_MACRO_MATERIALS = ['bio-acetate', 'bio-plastic']

// lowercased values related to `LENS_MATERIAL_MACRO_CLASS` attribute
export const RECYCLABEL_LENS_MACRO_MATERIALS = ['bio-lenses', 'standard lenses']

export const STANDARD_FRAME_DISPLAY_SIZE = 'M' as const

export const PRODUCT_URL_SEARCH_PARAMS = {
  ADD_LENS: 'addLens',
  EDIT_LENS: 'editLens',
  EDIT_SIZE: 'editSize',
} as const

export const PRODUCT_TYPES_KEYS = {
  CONTACT_LENSES: 'contact-lenses',
  OPTICAL: 'optical',
  FRAMES: 'frames',
  ACCESSORIES: 'accessories',
  CONTACT_LENSES_ACCESSORIES: 'contact-lenses-accessories',
  SUN: 'sun',
  ELECTRONICS: 'electronics',
} as const
