import { GoogleAPIEnabledInterface } from '../../types/googleAPI'
import { RootReducerState } from '../reducers'
import cloneDeep from 'lodash/cloneDeep'

export const siteSelector = (state: RootReducerState) => state.site.currentSite

export const availablePaymentMethodsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.availablePaymentMethods

export const sortOrderOptionsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.sortorderOptions

export const plpDashBtnSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.plpDashButtonsFacet

export const plpDashfacetSelector = (state: RootReducerState): Record<string, string> => {
  const dashFacetsFromStoreConf = state.site.currentSite?.xStoreCfg?.plpDashfacet
  let dashFacets: Record<string, string> = {}

  dashFacetsFromStoreConf?.forEach((value) => {
    Object.assign(dashFacets, value)
    return value
  })

  return dashFacets
}

export const plpFacetsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.plpFacet

export const plpBadgesSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.badges

export const pdpTaxesSelector = (state: RootReducerState) => {
  return state.site.currentSite?.xStoreCfg?.calculateTaxEnabled === 'true'
}

export const paymentMethodsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.paymentMethods

export const shippingFormFieldsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.shippingAddressFieldsList

export const earlyAccessContentEnabledSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.earlyAccessContentEnabled

export const billingFormFieldsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.billingAddressFieldsList

export const countrySelector = (state: RootReducerState) => state.site.currentSite?.country

export const xStoresCfgSelector = (state: RootReducerState) => state.site.currentSite?.xStoresCfg
export const availableStoresSelector = (state: RootReducerState) =>
  state.site.currentSite?.availableStores

export const paypalReturnUrlSelector = (state: RootReducerState) =>
  state.site.currentSite?.paypalReturnUrl

export const paypalCancelUrlSelector = (state: RootReducerState) =>
  state.site.currentSite?.paypalCancelUrl

export const cartMaxItemsSelector = (state: RootReducerState) =>
  state.site.currentSite?.cartMaxItems

export const damDomainSelector = (state: RootReducerState) => state.site.currentSite?.damDomain

export const availableShippingDaysStandardSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.AVAIL_SHIP_DAYS_PLANO_Standard!

export const availableShippingDaysGreenSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.AVAIL_SHIP_DAYS_PLANO_Green!
export const RXManDaysSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.MAN_DAYS_RX!

export const uploadRxFileOnOrderConfirmSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.uploadRXFileOnOrderConfirm!

export const standardRXShippingDaysSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.SHIPPING_DAYS_STANDARD_RX!

export const greenRXShippingDaysSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.SHIPPING_DAYS_GREEN_RX!

export const manDaysRXSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.MAN_DAYS_RX!

export const storeCfgServiceLinksSelector = (state: RootReducerState) => {
  const serviceLinks = state.site.currentSite?.xStoreCfg?.serviceLinks
  if (serviceLinks) {
    return serviceLinks.replace('[', '{').replace(']', '}')
  }
  return null
}
export const googleApiEnabledeSelector = (state: RootReducerState): GoogleAPIEnabledInterface => {
  if (!state.site.currentSite?.xStoreCfg?.GOOGLE_API_ENABLED) {
    return {
      isEnabled: 'false',
      isEnabledOnShipping: 'false',
      isEnabledOnBilling: 'false',
      isEnabledOnAddressBook: 'false',
    }
  }

  return state.site.currentSite?.xStoreCfg.GOOGLE_API_ENABLED
}

export const googleApiKeySelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.googleApiKey

export const klarnaScriptPathSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.klarnaServicesScriptUrl

export const klarnaAPIIdSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.klarnaClientId

export const forterSiteIdSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.FORTER_SITE_ID

export const monetateKeySelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.monetateEnabled

export const wordLiftKeySelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.WordLiftEnabled

export const wordLiftUrlSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.WordLiftScriptSrc

export const isPriceLogicActiveSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.isPriceLogicActive

export const paypalSecureAcceptanceUrlSelector = (state: RootReducerState) =>
  state.site.currentSite?.paypalSecureAcceptanceUrl

export const is3dsEnabledSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg.is3dsEnabled

export const availableShippingMethodsSelector = (state: RootReducerState) =>
  state.site.currentSite?.xStoreCfg?.availableShippingMethods || []
export const defaultShippingMethodNameSelector = (state: RootReducerState) => {
  const sortBySequence = cloneDeep(
    state.site.currentSite?.xStoreCfg?.availableShippingMethods
  )?.sort((a, b) => Number(a.sequence) - Number(b.sequence))
  return sortBySequence?.[0].name//get(sortBySequence, '[0].name')
}

export const shortCodesSelector = (state: RootReducerState) => {
  return state.site.currentSite?.xStoreCfg?.shortCodes
}
export const pdpFrameImageOrderSelector = (state: RootReducerState) => {
  return state.site.currentSite?.xStoreCfg?.pdpFrameImageOrder
}
