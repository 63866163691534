import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components'
import LoadingSkeleton from './LoadingSkeleton'
import { TPlayerBannerHook } from '../../../../hooks/useBannerPlayer'
import { TMedia, isPictureMedia, IPlacementPriority } from '../../../../types/teaser'
import { getSrcSetsImageFromCms, getVideoFromCMS } from '../../../../utils/url'
import { Helmet } from 'react-helmet'
import { cmsImageCrops } from '../../../../constants/ui'

interface IProps {
  type: string
  media?: TMedia
  playerBannerHook?: TPlayerBannerHook
  placementName?: string
  ariaLabel?: string
}

const StyledPlayerContainer = styled('div')`
  height: 100%;
  video {
    object-fit: cover;
  }
`

const StyledReactPlayer = styled(ReactPlayer)`
  object-fit: cover;
`

const CMSCommonMedia: React.FC<IProps & IPlacementPriority> = ({ type, media, playerBannerHook, placementName, ariaLabel, fetchpriority }) => {
  const video = media?.type === 'CMVideo' ? media : undefined
  const picture = isPictureMedia(media) ? media : undefined

  const srcSets = getSrcSetsImageFromCms(
    type,
    picture?.uriTemplate ?? video?.picture?.uriTemplate ?? ''
  )

  const bannerCropOption = cmsImageCrops[type]

  const desk = srcSets?.desk || ''
  const mobile = srcSets?.mobile || ''
  const tablet = srcSets?.tablet || ''

  const deskWidth = bannerCropOption?.desk?.width || ''
  const mobileWidth = bannerCropOption?.mobile?.width || ''
  const tabletWidth = bannerCropOption?.tablet?.width || ''

  return (
    <>
      {!!fetchpriority && (
        <Helmet>
          {desk && (
            <link
              rel="preload"
              as="image"
              media={`min-width: ${deskWidth}px`}
              href={desk}
            />
          )}
          {tablet && (
            <link
              rel="preload"
              as="image"
              media={`min-width: ${tabletWidth}px`}
              href={tablet}
            />
          )}
          {mobile && (
            <link
              rel="preload"
              as="image"
              media={`min-width: ${mobileWidth}px`}
              href={mobile}
            />
          )}
        </Helmet>
      )}
      {playerBannerHook && video ? (
        <StyledPlayerContainer>
          <StyledReactPlayer
            url={getVideoFromCMS(video.data.uri)}
            width="100%"
            fallback={<LoadingSkeleton />}
            height="100%"
            controls={false}
            loop={video.loop}
            muted={playerBannerHook.muted}
            playing={playerBannerHook.isPlaying}
            config={{ file: { attributes: { poster: desk } } }}
            playsinline
            {...playerBannerHook.events}
          />
        </StyledPlayerContainer>
      ) : (
        <LazyLoadImage
          visibleByDefault={placementName === 'blurred-slider'}
          alt={ariaLabel || 'arn-cms-content__fwb-image'}
          wrapperClassName={'lazy-load-wrapper'}
          threshold={500}
          src={mobile}
          effect={'blur'}
          placeholder={<LoadingSkeleton />}
          srcSet={`${mobile} 1024w, ${tablet} 1279w, ${desk} 1280w`}
          fetchpriority={fetchpriority}
        />
      )}
    </>
  )

}

export default CMSCommonMedia
