import * as React from 'react'

import { shallowEqual, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { CheckmarkRoundedIcon } from '../Icons'
import { CustomInputFieldProps } from '../../../types/inputFields'
import TextField from '@material-ui/core/TextField'
import { dimensions } from '../../../themes/variables'
import { themeModeSelector } from '../../../redux/selectors/theme'

export const StyledTextFieldWrapper = styled.div<CustomInputFieldProps>`
  position: relative;
  display: flex;
  .checkmark-icon {
    position: absolute;
    width: ${({ theme }) => theme.spacing()}px;
    height: ${({ theme }) => theme.spacing()}px;
    top: calc(50% - 8px);
    right: ${({ theme }) => theme.spacing()}px;
    display: ${({ showValidationStatus, error, ispristine }) =>
      showValidationStatus ? (!error && !ispristine ? 'block' : 'none') : 'none'};
    z-index: 1;
  }
`
export const TextFieldStyle = css<CustomInputFieldProps & {themeMode:string}>`
  width: 100%;

  label {
    font-size: 12px;
    color: ${({ theme, themeMode }) => themeMode === 'dark' ? theme.palette.secondary.greyDarkmode : theme.palette.secondary.main};

    &.Mui-error {
      color: ${({ theme }) => theme.palette.text.error};
    }
    &.Mui-focused {
      transform: translate(16px, -6px) scale(1);
      color: ${({ theme, themeMode }) => themeMode === 'dark' ? theme.palette.secondary.greyDarkmode : theme.palette.secondary.main};
    }
    &[data-shrink='true'] {
      transform: translate(16px, -6px) scale(1);
    }

    &:not(.Mui-focused)[data-shrink='true'] {
      color: ${({ theme }) => theme.palette.secondary.greyDarkmode};
    }
  }

  & > div {
    border-radius: 0;
    padding: ${({ theme }) => theme.spacing()}px;
    &.Mui-focused {
      fieldset {
        border-color: ${({ theme, themeMode }) => themeMode === 'dark' ? theme.palette.secondary.greyDarkmode : theme.palette.secondary.main } !important;
        border-width: 1px !important;
      }
    }
    &.Mui-error {
      fieldset {
        border-color: ${({ theme }) => theme.palette.text.error} !important;
      }
      & + p {
        margin-left: 0;
        color: ${({ theme }) => theme.palette.text.error} !important;
      }
    }
  }

  fieldset {
    border: 1px solid;
  }

  input, textarea {
    padding: 5px 0px;
    font-size: 12px;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.palette.custom.warmGrey} inset;
      box-shadow: 0 0 0 30px ${({ theme }) => theme.palette.custom.warmGrey} inset;
    }
  }
`
const TextFieldBase = styled(TextField)<CustomInputFieldProps>`
  ${TextFieldStyle}
`

const StyledTextAreaField = ({
  showValidationStatus,
  error,
  ispristine,
  ...props
}: CustomInputFieldProps) => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)
  return (
    <StyledTextFieldWrapper
      showValidationStatus={showValidationStatus}
      error={error}
      ispristine={ispristine}
      className="arn-textfield-wrapper">
      <CheckmarkRoundedIcon className="checkmark-icon" />
      <TextFieldBase
        themeMode={themeMode}
        error={error}
        variant="outlined"
        {...props}></TextFieldBase>
    </StyledTextFieldWrapper>
  )
}

export default StyledTextAreaField
