export const palette = {
  emerald: {
    main: '#009874',
    light: '#1EC79F',
    dark: '#007055',
  },
  sapphire: {
    main: '#0091FF',
    light: '#54B5FF',
    dark: '#016FC2',
  },
  arnette: {
    geckoGreen: '#94FA66',
    lobsterPink: '#DF756F',
    black: '#000000',
    white: '#fff',
    warmGrey: '#EBE9E4',
    lightGrey: '#898989',
    warningRed: '#9b0800',
    warningRedDarkmode: '#ff635a',
    greyDarkmode: '#a1a0a0',
    greyLightmode: '#4b4b4b',
  },
  text: {
    primary: '#43484D',
    secondary: '#5E6977',
    disabled: '#B5BECA',
    highlight: '#f44336',
    alert: '#C10C0D',
    expandedMenu: '#FFFFFF',
  },
  border: {
    main: '#cccccc',
    hover: '#F0F4F7',
    alert: '#C10C0D',
  },
  divider: {
    dark: '#4C5256',
  },
  background: {
    default: '#F4F4F4',
    paper: '#FFFFFF',
    transparency: 'CC',
    warmGrey:'#EBE9E4',
  },
  disabled: {
    background: '#F0F4F7',
  },
}
