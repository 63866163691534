import { Button } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

type TProps = React.ComponentProps<typeof Button>

const StyledCTALink = styled(Button)`
  ${({ theme }) => `
  border-radius: 0;
  background-color: ${theme.palette.custom.warmGrey};
  color: ${theme.palette.custom.black};
  text-decoration: none;

  &:hover {
    opacity: 0.9;
    background-color: ${theme.palette.custom.warmGrey};
    text-decoration: underline;
  }

  &:active {
    color: white;
    text-decoration: none;
    background-color: transparent;
  }
`}
`

const CTALink: React.FC<TProps> = ({ children, ...props }) => {
  return <StyledCTALink {...props}>{children}</StyledCTALink>
}

export default CTALink
