import { shallowEqual, useSelector } from 'react-redux'

import { DEFAULT_LANG_ID, LANGUAGE_CODE_MAP } from '../../constants/common'
import { Maybe } from '../../types/common'
import { RootReducerState } from '../../redux/reducers'
import config from '../../configs'
import { countryUtil } from '../../utils/countryUtil'
import { useLocation } from 'react-router-dom'

export interface StoreIdentity {
  country: string
  basePath: string
  langCode: string
}

const getWithFallback = (value: Maybe<string>, fallback: string) => {
  if (!value || value === 'undefined') return fallback
  return value
}

export const langCodeExceptionMap: Record<string, string> = {
  en_GB: 'en_UK',
}

const countryExceptionMap: Record<string, string> = {
  [LANGUAGE_CODE_MAP.en_UK]: 'en-gb',
}

export const useStoreIdentity = () => {
  const location = useLocation()

  return useSelector((state: RootReducerState) => {
    const locale = state.site.currentSite?.locale || null
    const [, countryFromUrl] = location.pathname.split('/')

    let country = getWithFallback(countryFromUrl, config.defaultCountry)
    let langCode = getWithFallback(locale, config.defaultLocale)
    langCode = langCodeExceptionMap[langCode] || langCode
    country = countryExceptionMap[country] || country

    const { currentCountry, currentLangCode } = countryUtil(country, langCode)

    return {
      isRXEnabled:
        state.site.currentSite?.xStoreCfg?.addPrescriptionLenses === 'true' ? true : false,
      langCode: currentLangCode, // 'en-us'
      country: currentCountry, // 'us'
      basePath: `/${currentLangCode}`, // '/en-us'
      langId: state.site.currentSite?.defaultLanguageID || DEFAULT_LANG_ID, // '-1'
    }
  }, shallowEqual)
}
