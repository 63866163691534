import '../../../styles/index.scss'

import { AppMain, AppWrapper } from './BaseLayout.style'
import { Footer, ReducedFooter } from '../../footer'
import { Header, ReducedHeader } from '../../header'
import React, { FC, Suspense, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { APP_MAIN_CONTAINER_CLASSNAME } from '../../../constants/ui'
import BaseLayoutTopBars from './BaseLayoutTopBars'
// import { CmsProvider } from '../../providers/CmsProvider'
import ErrorMessageSnackbar from '../../widgets/message-snackbar/ErrorMessageSnackbar'
import { Extensions } from '../../extensions'
import { Helmet } from 'react-helmet'
import { IFRAME_RESIZER } from '../../../foundation/constants/csr'
import LoginGuard from '../../../foundation/guard/LoginGuard'
import { MY_ACCOUNT_DRAWER_TOGGLE_ACTION } from '../../../redux/actions/ui'
import MyAccountDrawerContent from '../../pages/account/MyAccountDrawerContent'
//Foundation libraries
import { PRODUCTION } from '../../../foundation/constants/common'
//Custom libraries
import { ROUTE_CONFIG } from '../../../configs/routes'
//UI
import { StyledGrid } from '../../StyledUI'
import StyledMyAccountDrawer from '../../StyledUI/StyledMyAccountDrawer'
import TealiumService from '../../../foundation/analytics/tealium/tealium.service'
import config from '../../../configs'
import { cmsPreviewSelector } from '../../../features/preview/selector'
import { loginStatusSelector } from '../../../redux/selectors/user'
import { myAccountDrawerSelector } from '../../../redux/selectors/ui'
import { renderRoutes } from 'react-router-config'
import styled from 'styled-components'
import { currentPageSeoDataSelector } from '../../../redux/selectors/seo'
// Tealium
import { useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { useForter } from '../../../foundation/forter/useForter'
import { useLocation } from 'react-router-dom'
import { useMonetate } from '../../../foundation/monetate/useMonetate'
import { useWordLift } from '../../../foundation/hooks/useWordLift'
import { usePageType } from '../../../foundation/hooks/usePageType'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import cmsService from '../../../foundation/apis/cms/cms.service'
import useThemeMode from '../../../hooks/useThemeMode'
import { useTranslation } from 'react-i18next'
import { storeCfgServiceLinksSelector } from '../../../redux/selectors/site'
import { useGlobalExternalActions } from '../../../hooks/useGlobalExternalActions'

const StyledGridContent = styled(StyledGrid)`
  width: 100%;
  min-height: calc(100vh - 56px);
`

const StyledGridPage404 = styled(StyledGrid)`
  width: 100%;
`

export const BaseAppLayout: FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const { pageType } = usePageType()
  const { theme } = useThemeMode()
  const analyticsCommonData = useAnalyticsData('Home')


  const loggedIn = useSelector(loginStatusSelector)
  const myAccountDrawerOpen = useSelector(myAccountDrawerSelector)
  const getPreview = useSelector(cmsPreviewSelector)
  const serviceLinksString = useSelector(storeCfgServiceLinksSelector)

  const seoData = useSelector(currentPageSeoDataSelector)

  const langCodeSeo = langCode.slice(0, langCode.lastIndexOf('-'))

  useForter()
  useMonetate()
  useWordLift()
  const serviceLinks = serviceLinksString ? JSON.parse(serviceLinksString) : null
  useGlobalExternalActions()
  const shouldShowReducedFooter = pageType === 'checkout' || pageType === 'cart'

  const isIOSDevice = useMemo(
    () => navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent),
    [navigator.userAgent]
  )

  const scriptLoaded = useMemo(
    () => window && window.tealium_data2track,
    [window && window.tealium_data2track]
  )

  useEffect(() => {
    window.scroll(0, 0)
  }, [location])

  useEffect(() => {
    if (!scriptLoaded && config.enableAnalytics && mySite) {
      TealiumService.loadAnalyticScript(Date.now().toString(), analyticsCommonData)

      window.dispatchEvent(new Event('tealiumInit'))
    }
  }, [mySite, scriptLoaded])

  return (
    <AppWrapper className="app-wrapper" data-testid="app-wrapper" theme={theme}>
      {/* <CmsProvider> */}
        <BaseLayoutTopBars />

        <AppMain className={APP_MAIN_CONTAINER_CLASSNAME}>
          {loggedIn && (
            <StyledMyAccountDrawer
              anchor="right"
              disableSwipeToOpen={true}
              open={!!myAccountDrawerOpen}
              onClose={() => dispatch(MY_ACCOUNT_DRAWER_TOGGLE_ACTION(false))}
              onOpen={() => dispatch(MY_ACCOUNT_DRAWER_TOGGLE_ACTION(true))}>
              <MyAccountDrawerContent />
            </StyledMyAccountDrawer>
          )}

          <ErrorMessageSnackbar />

          {pageType === 'checkout' && location.pathname.indexOf('order-confirmation') === -1 ? (
            <ReducedHeader />
          ) : (
            <Header loggedIn={loggedIn} />
          )}

          <StyledGrid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="stretch"
            className="full-viewport-height">
            <StyledGrid item xs={false}>
              <LoginGuard />
              <Helmet>
                <html lang={langCodeSeo} />
                <meta charSet="utf-8" />
                {isIOSDevice && (
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                  />
                )}
                {
                  langCode === 'en-ww'
                    ? <title>Arnette® Official Store</title>
                    : <title>{t('CommerceEnvironment.Title')}</title>
                }
                <meta name="description" content={t('CommerceEnvironment.Description')} />
                <meta
                  name="google-site-verification"
                  content="7xdTHP1uR7N2bofap4JNS8AebhplKbOj72Rk4FaG1aM"
                />
                <link rel="canonical" href={`${window.location.origin}${location.pathname}`} />
                {window[IFRAME_RESIZER] && (
                  <script src="/iframeResizer.contentWindow.min.js" type="text/javascript" async />
                )}
                {serviceLinks && langCode === 'en-us' ? (
                  <script type="text/javascript">
                    {`function TealiumTermsOfUseLink(){window.location.href = '/${langCode}${serviceLinks.terms}'}`}
                  </script>
                ) : (
                  <script type="text/javascript">
                    {`function TealiumConsentPrivacyLink(){window.location.href = '/${langCode}${serviceLinks.privacy}'}`}
                  </script>
                )}
                <script type="application/ld+json">
                  {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    'itemListElement': [{
                      '@type': 'ListItem',
                      'position': 1,
                      'name': 'Home',
                      'item': `https://www.arnette.com/${langCode}`
                    }, {
                      '@type': 'ListItem',
                      'position': 2,
                      'name': `${seoData?.page?.title}`,
                      'item': `https://www.arnette.com${location.pathname}`
                    }]
                  })}
                </script>
              </Helmet>
              {getPreview?.isEnabled && (
                <Helmet>
                  <html lang={`${getPreview.locale}`} dir="ltr" />
                  <title {...cmsService.getMetaDataAttribute('[{"_":"properties.htmlTitle"}]')}>{`${getPreview.name || 'CMS Preview'
                    }`}</title>
                  <meta name="generator" content="CoreMedia Content Cloud" />
                  <script>
                    {
                      (document.documentElement.className =
                        document.documentElement.className.replace(/no-js/g, 'js'))
                    }
                  </script>
                  <link rel="stylesheet" href="/cms-preview/coremedia.preview.blueprint.css" />
                  <script src="/cms-preview/jquery-3.6.0.min.js" />
                  <script src="/cms-preview/coremedia.preview.js" />
                  <script src="/cms-preview/coremedia.preview.$.js" />
                  <script src="/cms-preview/coremedia.preview.blueprint.js" />
                  <body
                    id="top"
                    className="cm-page"
                    {...cmsService.getResponsiveDevicesMetaDataAttribute()}
                  />
                </Helmet>
              )}
            </StyledGrid>
            {pageType === 'page404' ? (
              <StyledGridPage404 item className="main">
                <Suspense fallback={<div />}>{renderRoutes(ROUTE_CONFIG.CFG)}</Suspense>
              </StyledGridPage404>
            ) : (
              <StyledGridContent item className="main">
                <Suspense fallback={<div />}>{renderRoutes(ROUTE_CONFIG.CFG)}</Suspense>
              </StyledGridContent>
            )}
            <StyledGrid item xs={false}>
              {shouldShowReducedFooter ? <ReducedFooter /> : <Footer />}
            </StyledGrid>
            {process.env.NODE_ENV !== PRODUCTION && <Extensions />}
          </StyledGrid>
        </AppMain>
      {/* </CmsProvider> */}
      <div id="vmPortal"></div>
    </AppWrapper>
  )
}
