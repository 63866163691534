import {
  OrderDetailsHeader,
  OrderDetailsItem,
  OrderDetailsItemList,
  OrderDetailsPrice,
  OrderDetailsWrapper,
} from './OrderDetails.style'
import { PRODUCT_PRICE_USAGE_NAMES, ProductBase } from '../../../types/product'
import React, { FC, useEffect } from 'react'
import { RoundButton, StyledA, StyledLoader, StyledTypography } from '../../StyledUI'
import { generatePath, useHistory } from 'react-router'
import {
  getFrameMaterial,
  getFrontColor,
  getLensesColor,
  getLensMacroMaterial,
  getModelName,
  getSize,
} from '../../../utils/productAttributes'
import { useOrderDetails, useOrderDetailsLoadings } from '../../../hooks/useOrderDetails'
import { ADD_ITEM_ACTION, ADD_LENS_ACTION } from '../../../redux/actions/order'
import { CART } from '../../../constants/routes'
import DateService from '../../../services/DateService'
import { FETCH_ORDER_DETAILS_ACTION } from '../../../redux/actions/orderDetails'
import Flexbox from '../../StyledUI/Flexbox/Flexbox'
import {
  ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES,
  ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS,
} from '../../../constants/order'
import { Order } from '../../../types/order'
import { OrderActionsPayload } from '../../../types/orderActionsPayload'
import ProductImage from '../../product-image/ProductImage'
import { currentContractIdSelector } from '../../../redux/selectors/contract'
import { useAppSelector } from '../../../hooks/redux'
import { useDispatch } from 'react-redux'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'react-i18next'
import {
  getRxAttributes,
  isRxCart,
  isRxProduct,
  parseOrderItems,
} from '../../../utils/isRxOrder'
import { StyledEmailPrescriptionCta } from '../../widgets/personal-information/StyledPersonalInformation'
import { AlertIcon } from '../../StyledUI/Icons'
import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  RECYCLABEL_FRAME_MACRO_MATERIALS,
  RECYCLABEL_LENS_MACRO_MATERIALS,
} from '../../../constants/product'
import CurrencyService from '../../../services/CurrencyService'
import { getRxLensPrice } from '../../../utils/rx'

interface OrderDetailsProps {
  orderId: Order['orderId']
}

const OrderDetails: FC<OrderDetailsProps> = ({ orderId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const { langCode, langId } = useStoreIdentity()
  const contractId = useAppSelector(currentContractIdSelector)
  const orderDetails = useOrderDetails(orderId) || {}
  const areOrderDetailsLoading = useOrderDetailsLoadings(orderId)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const onOrderAgain = (product: ProductBase, catentryId: string | undefined) => {
    return () => {
      const params: OrderActionsPayload.AddLens['params'] = {
        catentryId,
        contractId,
        langId,
        partnumber: [product.partNumber],
        quantity: ['1'],
      }

      if (catentryId) {
        dispatch(
          ADD_LENS_ACTION({
            params,
            callback: () => {
              history.push(generatePath(CART, { country: langCode }))
            },
          })
        )
      } else {
        dispatch(
          ADD_ITEM_ACTION({
            params,
            callback: () => {
              history.push(generatePath(CART, { country: langCode }))
            },
          })
        )
      }
    }
  }

  useEffect(() => {
    if (orderId) {
      dispatch(FETCH_ORDER_DETAILS_ACTION({ orderId, skipErrorSnackbar: true }))
    }
  }, [orderId])

  const PARSED_ORDER_ITEMS = isRxCart(orderDetails?.orderExtendAttribute)
    ? parseOrderItems(orderDetails.orderItem)
    : orderDetails.orderItem

  if (areOrderDetailsLoading) {
    return (
      <OrderDetailsWrapper>
        <Flexbox align-items="center" justify-content="center" height="52px">
          <StyledLoader contained />
        </Flexbox>
      </OrderDetailsWrapper>
    )
  }

  return (
    <OrderDetailsWrapper>
      <OrderDetailsHeader>
        <Flexbox flex-direction="column">
          <StyledTypography isUppercased variant="caption">
            {t('Order.OrderNumber')}: {orderDetails.orderId}
          </StyledTypography>
          <StyledTypography isUppercased variant="caption">
            {t('Order.OrderDate')}: {DateService.format(orderDetails.placedDate || '')}
          </StyledTypography>
        </Flexbox>
      </OrderDetailsHeader>

      <OrderDetailsItemList>
        {PARSED_ORDER_ITEMS?.map((orderItem) => {
          const product = orderDetails.x_data?.productDetails?.find(
            ({ id }) => orderItem.productId === id
          )
          if (!product) {
            return null
          }

          const { orderItemExtendAttribute = [], orderItemId, orderItemStatus } = orderItem

          const orderItemReturnStatus = orderItemExtendAttribute.find((a) =>
            a.attributeName.startsWith(ORDER_ITEM_EXTEND_ATTRIBUTE_NAMES.RMA_STATUS)
          )?.attributeValue
          // RMAItemStatus should be displayed prior to orderItemStatus for order item if exists
          const preferredOrderItemStatus = orderItemReturnStatus || orderItemStatus || ''

          const invoiceUrl = orderDetails.xinvoiceUrls?.[orderItemId!] || ''

          const rxServices = orderItem?.roxableServices

          const LENS_ITEM = rxServices?.find((i) => isRxProduct(i.orderItemExtendAttribute))

          const LENS_OBJECT = orderDetails.x_data?.productDetails?.find(
            ({ id }) => LENS_ITEM?.productId === id
          )

          const RX_ATTRIBUTES = getRxAttributes(LENS_OBJECT)

          const frameMaterial = getFrameMaterial(product)
          const isFrameMaterialRecyclabel = RECYCLABEL_FRAME_MACRO_MATERIALS.includes(
            frameMaterial.toLowerCase()
          )
          const frameAttributesArray: string[] = [getFrontColor(product)]
          if (isFrameMaterialRecyclabel) {
            frameAttributesArray.push(frameMaterial)
          }

          const nonRxLensMacroMaterial = getLensMacroMaterial(product)
          const nonRxLensAttributesArray: string[] = [getLensesColor(product)]
          if (
            !rxServices &&
            isFrameMaterialRecyclabel &&
            RECYCLABEL_LENS_MACRO_MATERIALS.includes(nonRxLensMacroMaterial.toLowerCase())
          ) {
            nonRxLensAttributesArray.unshift(nonRxLensMacroMaterial)
          }

          const trackingNumber =
            orderItemExtendAttribute.find(
              (a) =>
                a.attributeName ===
                ORDER_ITEM_EXTEND_ATTRIBUTE_NAME_GETTERS.TRACK_NUMBER(orderDetails.orderId)
            )?.attributeValue || ''

          const FRAME_DETAILS = orderDetails?.x_data?.productDetails.find(
            ({ id }) => id === orderItem.productId
          )

          const RX_LENS_PRICE = rxServices ? getRxLensPrice(rxServices || []) : 0

          const orderItemCurrency =
            orderItem && CurrencyService.getSymbolByName(orderItem.currency)

          const DISCOUNT_AMOUNT = !isNaN(Number(orderDetails?.totalAdjustment))
            ? Number(orderDetails?.totalAdjustment)
            : 0

          const RX_PRICE = (
            parseFloat(FRAME_DETAILS?.x_offerpriceRx || 0) + (RX_LENS_PRICE || 0) + (DISCOUNT_AMOUNT)
          )

          const ORDER_PRICE = parseFloat(orderItem?.orderItemPrice)

          const isPriceInteger = Number.isInteger(ORDER_PRICE)
          const isRxPriceInteger = Number.isInteger(RX_PRICE)


          const ORDER_FINAL_PRICE = isPriceInteger ? ORDER_PRICE : ORDER_PRICE.toFixed(2).toString()
          const RX_FINAL_PRICE = isRxPriceInteger ? RX_PRICE : RX_PRICE.toFixed(2).toString()

          const PRICE = rxServices?.length
            ? {
              currency: orderItemCurrency,
              usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
              value: RX_FINAL_PRICE,
            }
            : {
              currency: orderItemCurrency,
              usage: PRODUCT_PRICE_USAGE_NAMES.CURRENT,
              value: ORDER_FINAL_PRICE,
            }

          return (
            <OrderDetailsItem key={orderItemId}>
              <div className="order-details__item__main">
                <div className="order-details__item__image">
                  <ProductImage
                    alt="Product image"
                    attachments={product.attachments}
                    usage="Thumbnail"
                    width={100}
                  />
                </div>
                <div className="order-details__item__product-info">
                  <StyledTypography component="h4" variant="body1">
                    {getModelName(product)}
                  </StyledTypography>
                  <div>{getSize(product, t)}</div>
                  <div>{frameAttributesArray.filter((attr) => !!attr).join(' / ')}</div>
                  <div>
                    {rxServices ? (
                      <>
                        {RX_ATTRIBUTES.correctionType}
                        {RX_ATTRIBUTES.lensColor ? ' / ' + RX_ATTRIBUTES.lensColor : ''}
                      </>
                    ) : (
                      nonRxLensAttributesArray.filter((attr) => !!attr).join(' / ')
                    )}
                  </div>
                  <OrderDetailsPrice>
                    {t('Order.TotalPrice')}: {`${PRICE.currency}${PRICE.value}`}
                  </OrderDetailsPrice>
                  {!!trackingNumber && (
                    <div>
                      {t('Order.TrackingNumber')}: {trackingNumber}
                    </div>
                  )}
                  <StyledTypography isUppercased className="order-details__item__status">
                    {t('Order.Status')}:{' '}
                    <span>{t(`OrderItem.Status_.${preferredOrderItemStatus}`)}</span>
                    {['V', 'E'].includes(preferredOrderItemStatus) && !isMobile ? (
                      <StyledEmailPrescriptionCta
                        href={`mailto:${t('OrderDetails.Labels.CustomerServiceEmail')}`}>
                        <AlertIcon />
                        <span>{t('Order.PendingPrescriptionCta')}</span>
                      </StyledEmailPrescriptionCta>
                    ) : null}
                  </StyledTypography>
                  {['V', 'E'].includes(preferredOrderItemStatus) && isMobile ? (
                    <StyledTypography isUppercased>
                      <StyledEmailPrescriptionCta
                        href={`mailto:${t('OrderDetails.Labels.CustomerServiceEmail')}`}>
                        <AlertIcon />
                        <span>{t('Order.PendingPrescriptionCta')}</span>
                      </StyledEmailPrescriptionCta>
                    </StyledTypography>
                  ) : null}
                </div>
              </div>

              <div className="order-details__item__button-container">
                <RoundButton
                  className="order-details__item__button"
                  variant="secondary"
                  onClick={onOrderAgain(product, LENS_ITEM?.productId)}>
                  {t('OrderDetails.Actions.OrderAgain')}
                </RoundButton>
                {!!invoiceUrl && (
                  <div className="order-details__item__link">
                    <StyledA href={invoiceUrl} fontSize="0.75rem" variant="primaryUnderlined">
                      {t('OrderDetails.Actions.DownloadInvoice')}
                    </StyledA>
                  </div>
                )}
              </div>
            </OrderDetailsItem>
          )
        })}
      </OrderDetailsItemList>
    </OrderDetailsWrapper>
  )
}

export default OrderDetails
