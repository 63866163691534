import { useEffect } from 'react'

const addBodyClass = (className) => document.body.classList.add(className)
const removeBodyClass = (className) => document.body.classList.remove(className)

export const useBodyClass = (className, toggle: boolean) => {
  useEffect(() => {
    if (toggle) {
      className instanceof Array ? className.map(addBodyClass) : addBodyClass(className)
    } else {
      className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className)
    }

    // Clean up
    return () => {
      className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className)
    }
  }, [toggle, className])
}
