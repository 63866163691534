import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { IPlacement, isLXTeaser, isCMCollection } from '../../../types/teaser'
import { Fade } from '@material-ui/core'
import { useDynamicCmsContent } from '../../../foundation/hooks/useDynamicCmsContent'
import { sanitizeHtml } from '../../../utils/common'
import { PromotionBarElement } from './promotion'
import { StyledBar, StyledLeftIcon, StyledRightIcon } from './style'
import { isEmpty } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import useBreakpoints from '../../../hooks/useBreakpoints'

interface PromotionBarProps {
  data: IPlacement[]
}

const PromotionBar: FC<PromotionBarProps> = ({ data }) => {
  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)
  const dynamicContentBanners = useDynamicCmsContent(data)
  const headerPromoBar = dynamicContentBanners && dynamicContentBanners.find((i) => i.name === 'header_promo_bar')
  const collectionItems = headerPromoBar?.items?.find(isCMCollection)
  const teaserItems = headerPromoBar?.items?.filter(isLXTeaser) || []
  const teasableItems = collectionItems?.teasableItems?.filter(isLXTeaser) || []
  const dataBanners = Boolean(collectionItems) ? teasableItems : teaserItems
  const [visibleCountDown, setVisibleCountDown] = useState<string | null>(null)
  const [pauseAnimation, setPauseAnimation] = useState<boolean>(false)
  const { isDesktop } = useBreakpoints()

  const next = useCallback(
    () => setCurrentIndex((prevState) => (prevState + 1) % dataBanners.length),
    [dataBanners.length]
  )

  const prev = () =>
    setCurrentIndex((prevState) =>
      prevState === 0 ? dataBanners.length - 1 : (prevState - 1) % dataBanners.length
    )

    useEffect(() => {
      if (dataBanners.length === 0 || pauseAnimation) return;
     
      const interval = setInterval(() => {
        setCurrentIndex(prevState => (prevState + 1) % dataBanners.length)
      }, 5000)
     
      return () => clearInterval(interval);
    }, [dataBanners.length, pauseAnimation])

  const bannerTextLength = dataBanners?.map(x => {
    return (
      Number(sanitizeHtml(x?.teaserText2).length) +
      Number(x?.teaserLXCallToActionSettings?.[0]?.callToActionText?.length) +
      Number(visibleCountDown?.length || 0)
    )
  })

  // need this to wait the countdown to be initialized
  const showPromoBar = useMemo(() => {
    const bannersContainCountDown = dataBanners?.some(banner => !isEmpty(banner.teaserCountdownStart))
    return bannersContainCountDown ? !isEmpty(visibleCountDown) : true
  }, [dataBanners, visibleCountDown])

  const textOnTwoRows = bannerTextLength?.some(n => n > 54)
  return !!dataBanners.length ? (
    <StyledBar bannerTextLength={textOnTwoRows} visible={showPromoBar}>
      {!!dataBanners && dataBanners.length > 1 && (
        <StyledLeftIcon
          aria-label={t('Header.Actions.ArrowPrevious')}
          onClick={prev}
          tabIndex={0}
          onKeyPress={prev}
          onBlur={() => setPauseAnimation(false)}
          onFocus={() => setPauseAnimation(true)}
          bannerTextLength={textOnTwoRows}
        />
      )}
      {dataBanners?.map((item, index) => {
        return (
          <Fade key={index} in={index === currentIndex}>
          {!isDesktop ? (
            <PromotionBarElement
              teaser={item}
              promoIndex={index}
              currentPromoIndex={currentIndex}
              onCurrentCountDownTick={(currentCountDownLeft) => {
                if (!isEmpty(currentCountDownLeft)) {
                  setVisibleCountDown((prev) => {
                    return prev === currentCountDownLeft ? prev : currentCountDownLeft
                  })
                }
              }}
            />
          ) : (
            <PromotionBarElement
              tabIndex={0}
              teaser={item}
              promoIndex={index}
              currentPromoIndex={currentIndex}
 		          onMouseLeave={() => setPauseAnimation(false)}
              onMouseEnter={() => setPauseAnimation(true)}
              onBlur={() => setPauseAnimation(false)}
              onFocus={() => setPauseAnimation(true)}
              onCurrentCountDownTick={(currentCountDownLeft) => {
                if (!isEmpty(currentCountDownLeft)) {
                  setVisibleCountDown((prev) => {
                    return prev === currentCountDownLeft ? prev : currentCountDownLeft
                  })
                }
              }}
            />
        )}
        </Fade>
        )
      })}
      {!!dataBanners && dataBanners.length > 1 && (
        <StyledRightIcon
          aria-label={t('Header.Actions.ArrowNext')}
          onClick={next}
          tabIndex={0}
          onKeyPress={next}
          onBlur={() => setPauseAnimation(false)}
          onFocus={() => setPauseAnimation(true)}
          bannerTextLength={textOnTwoRows}
        />
      )}
    </StyledBar>
  ) : null
}

export default PromotionBar
