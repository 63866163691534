import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PreviewState {
  isEnabled: boolean
  externalId: string
  id: string
  locale: string
  name: string
  navigationPath: { segment: string }[]
  siteName: string
  type: string
  formattedUrl: string
  filterRulesLocaleOverride: string
  previewDate: string
}

const initialState: PreviewState = {
  isEnabled: false,
  externalId: '',
  id: '',
  locale: '',
  name: '',
  navigationPath: [],
  siteName: '',
  type: '',
  formattedUrl: '',
  filterRulesLocaleOverride: '',
  previewDate: '',
}

export const {
  actions: { setPreview },
  reducer,
} = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    setPreview: (state, action: PayloadAction<Partial<PreviewState>>) => {
      return { ...state, ...action.payload }
    },
  },
})

export default reducer
