import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { buildOrderHistoryCells, buildOrderHistoryHeaders } from '../../../constants/order'
import {
  OrderHistoryListItem,
  OrderHistoryList as StyledOrderHistoryList,
} from './OrderHistory.style'
import Flexbox from '../../StyledUI/Flexbox/Flexbox'
import { Order } from '../../../types/order'
import { StyledA } from '../../StyledUI'
import { TableCell } from '../../StyledUI/StyledTable/Table'
import theme from '../../../themes'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import orderService from '../../../foundation/apis/transaction/order.service'

export const OrderHistoryList: FC<{
  orders: Order[]
}> = ({ orders }) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))

  const headers = useMemo<string[]>(() => buildOrderHistoryHeaders(t), [t])

  const cells = useMemo<TableCell<Order>[]>(
    () => buildOrderHistoryCells({ isDesktop, langCode, t }),
    [isDesktop, langCode, t]
  )

  return (
    <StyledOrderHistoryList className="order-history__list">
      {orders &&
        orders.map((order) => (
          <OrderHistoryListItem
            key={order.orderId}
            className="order-history__list-item">
            {cells.map(({ key, render }, cellIndex) => {
              if (key !== 'action') {
                return (
                  <Flexbox key={key} align-items="baseline" gap="3px">
                    {headers[cellIndex]}: {render ? render(order, cellIndex) : null}
                  </Flexbox>
                )
              }

              const invoiceUrl = orderService.getInvoiceUrl(order.orderExtendAttribute)

              return !!invoiceUrl ? (
                <StyledA
                  key={key}
                  data-element-id={'X_X_OrderHistory_ViewInvoice'}
                  href={invoiceUrl}
                  fontSize="0.75rem"
                  variant="primaryUnderlined">
                  {t('OrderDetails.Actions.DownloadInvoice')}
                </StyledA>
              ) : null
            })}
          </OrderHistoryListItem>
        ))}
    </StyledOrderHistoryList>
  )
}
