import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import forEach from 'lodash/forEach'
import union from 'lodash/union'
import Log from '../../services/Log'
import {
  isDynamicContent,
  IPlacement,
  DynamicContentKey,
  DynamicRule,
  dynamicContentKeys,
  LXDynamicContent,
  ILXTeaser,
  ICMCollection,
  isCMCollection,
  IPlacementItem,
} from '../../types/teaser'
import { orderItemsSelector } from '../../redux/selectors/order'
import { facetsSelector } from '../../redux/selectors/catalog'
import { usePageType } from './usePageType'
import { getProductAttribute } from '../../utils/productAttributes'
import { ProductBase, ProductAttribute } from '../../types/product'
import { SelectedFacets } from '../../types/filters'
import { loginStatusSelector } from '../../redux/selectors/user'
import { getCookieByName } from '../../utils/cookie'

type DynamicContentKeyMapping = Record<DynamicContentKey, string[]> | {}
type Product = Pick<ProductBase, 'attributes' | 'cluster'>

/**
 * Create product attributes flat map by comparison with dynamic content keys map
 * @param orderItems order items map
 */
const mapItemAttributes = (pdpData: ProductBase): DynamicContentKeyMapping => {
  const productAttributesMap: DynamicContentKeyMapping = {}
  try {
    dynamicContentKeys.map((key) => {
      if (!productAttributesMap[key]) {
        productAttributesMap[key] = []
      }
      let mapElement = productAttributesMap[key]
      let attrValue = getProductAttribute(pdpData, key)
      if (attrValue && attrValue !== '') {
        if (Array.isArray(attrValue)) {
          attrValue.map((v) => {
            mapElement && mapElement.push(`${key}_${v}`)
          })
        } else {
          mapElement && mapElement.push(`${key}_${attrValue}`)
        }
      }
      mapElement = union(mapElement)
    })
  } catch (e) {
    Log.error('DYNAMIC PDP CONTENT MAPPING ERROR', e)
  }
  Log.info('DYNAMIC PDP CONTENT MAPPING', JSON.stringify(productAttributesMap))
  return productAttributesMap
}

/**
 * Create order items attributes flat map by comparison with dynamic content keys map
 * @param orderItems order items map
 */
const mapCartItemsAttributes = (orderItems: Product[]): DynamicContentKeyMapping => {
  let orderItemAttributes: ProductAttribute[] = []
  const orderItemAttributesMap: DynamicContentKeyMapping = {}

  try {
    for (const item of orderItems) {
      const concatedItemAttributes = orderItemAttributes.concat(item.attributes || [])

      concatedItemAttributes
        .filter(
          (attr) =>
            attr.identifier && dynamicContentKeys.includes(attr.identifier as DynamicContentKey)
        )
        .forEach((attr) => {
          if (attr?.name && !orderItemAttributesMap[attr?.name]) {
            orderItemAttributesMap[attr?.name] = []
          }

          let attrValue = attr?.name ? getProductAttribute(item, attr?.name) : null

          if (attr?.name) {
            if (Array.isArray(attrValue)) {
              attrValue.map((v) => {
                attr?.name &&
                  orderItemAttributesMap[attr?.name] &&
                  orderItemAttributesMap[attr?.name].push(`${attr?.name}_${v}`)
              })
            } else {
              orderItemAttributesMap[attr?.name] &&
                orderItemAttributesMap[attr?.name].push(`${attr?.name}_${attrValue}`)
            }

            orderItemAttributesMap[attr?.name] = union(orderItemAttributesMap[attr?.name])
          }
        })
    }
  } catch (e) {
    Log.error('DYNAMIC CONTENT MAPPING ERROR', e)
  }
  Log.info('DYNAMIC CONTENT MAPPING', JSON.stringify(orderItemAttributesMap))
  return orderItemAttributesMap
}

/**
 * Create order items attributes flat map by comparison with dynamic content keys map
 * @param orderItems order items map
 */
const mapAttributesFromFacets = (selectedFacets, facets): DynamicContentKeyMapping => {
  let facetsMap: DynamicContentKeyMapping = {}
  try {
    forEach(selectedFacets, (_, selectedFacetKey) => {
      forEach(facets, (facet, _) => {
        facet.entry?.map((entry) => {
          if (entry.value === selectedFacetKey) {
            const facetName = facet.name
            if (!facetsMap[facetName]) {
              facetsMap[facetName] = []
              facetsMap[facetName].push(entry.attributeValueIdentifier)
            } else if (facetName && facetsMap[facetName]) {
              facetsMap[facetName].push(entry.attributeValueIdentifier)
            }
          }
        })
      })
    })
  } catch (e) {
    Log.error('DYNAMIC CONTENT FACETS MAPPING', e)
  }
  Log.info('DYNAMIC CONTENT FACETS MAPPING', JSON.stringify(facetsMap))
  return facetsMap
}

/**
 * get dynamic cms content
 * @param dynamicContentKeyMapping dynamic content attributes map
 * @param dynamicRules dynamic rules from cms to be matched with attributes
 * @param dynamicItem dynamic cms entity
 */
const getDynamicContentByRules = (
  dynamicContentKeyMapping: DynamicContentKeyMapping,
  dynamicRules: DynamicRule[],
  dynamicItem: LXDynamicContent
): ILXTeaser | ICMCollection | null => {
  let matchedRule: DynamicRule | null = null
  try {
    matchedRule = dynamicRules?.filter((dynamicRule) => {
      const match = dynamicRule?.rules.find((rule) => {
        const attributesValues: string[] = dynamicContentKeyMapping[rule?.key] || []

        return !!rule.value && attributesValues?.includes(rule.value)
      })
      return !!match
    })[0]

    Log.info('DYNAMIC CONTENT RULE MATCH', JSON.stringify(matchedRule))
  } catch (e) {
    Log.error('DYNAMIC CONTENT MATCH ERROR', e)
  }

  if (matchedRule !== null && !!matchedRule?.target) {
    return matchedRule.target
  } else {
    Log.info('DYNAMIC CONTENT DEFAULT MATCH', JSON.stringify(matchedRule))

    return dynamicItem.dynamicDefault
  }
}

const processPlacementItem = (
  item: IPlacementItem,
  dynamicContentMap: DynamicContentKeyMapping
): IPlacementItem => {
  if (item && isDynamicContent(item)) {
    const dynamicContentByRules = getDynamicContentByRules(
      dynamicContentMap,
      item.dynamicRules || [],
      item
    )

    return dynamicContentByRules!
  } else {
    return item
  }
}

export const _getUpdatedCmsContent = (
  cmsBannerData: IPlacement[] | ILXTeaser[],
  dynamicContentMap: DynamicContentKeyMapping
): IPlacement[] => {
  let updatedBannersData: IPlacement[] = []
  try {
    cmsBannerData?.map((banner, index) => {
      banner.items?.map((bannerItem) => {
        if (bannerItem && isDynamicContent(bannerItem)) {
          let bannerToUpdate = Object.assign({}, banner)
          updatedBannersData[index] = bannerToUpdate
          const dynamicContentByRules = getDynamicContentByRules(
            dynamicContentMap,
            bannerItem.dynamicRules || [],
            bannerItem
          )
          if (!!dynamicContentByRules) {
            updatedBannersData[index].items = []
            updatedBannersData[index].items.push(dynamicContentByRules)
          }
        } else {
          updatedBannersData.push(banner)
        }
      })
    })
  } catch (e) {
    Log.error('DYNAMIC CONTENT FETCH ERROR', e)
  }

  return updatedBannersData
}

export const getUpdatedCmsContent = (
  cmsBannerData: IPlacement[],
  dynamicContentMap: DynamicContentKeyMapping
  ) => {
  try {
  return (cmsBannerData || [])?.map((placement) => {
  const items = placement.items.map((placementItem) => {
  if (isCMCollection(placementItem)) {
  placementItem.teasableItems = placementItem.teasableItems.map((item) =>
  processPlacementItem(item, dynamicContentMap)
  )
  return placementItem
  }
  return processPlacementItem(placementItem, dynamicContentMap)
  }).filter((item) =>!!item)
  
  if(items.length === 0) return
  return ({
  ...placement,
  items
  })
  }).filter((placement) =>!!placement) as IPlacement[]
  } catch (e) {
  Log.error('DYNAMIC CONTENT FETCH ERROR', e)
  
  return []
  }
  }

export const useDynamicCmsContent = (
  cmsBannerData: IPlacement[] | undefined,
  extraProp?: ProductBase | SelectedFacets // pdpData / selectedFacets
) => {
  const pageType = usePageType()
  const orderItems: Product[] = useSelector(orderItemsSelector)
  const facets = useSelector(facetsSelector)
  const [dynamicContentBanners, setDynamiContentBanners] = useState<IPlacement[] | undefined>([])

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)


  const promoUrlValue = urlParams.get('promo') || null
 
  
  const earlyAccessContentEnabled = getCookieByName('eaccess') || 'false'
  const userLoggedIn = useSelector(loginStatusSelector)

  const userLogged = userLoggedIn.toString()

  const storeConfMap = { earlyAccessContentEnabled, userLogged, promoUrlValue }

  useEffect(() => {
    switch (pageType.pageType) {
      case 'pdp':
        const pdpDynamicProductMap =
          extraProp && extraProp !== null ? mapItemAttributes(extraProp as ProductBase) : {}

        const pdpDynamicContentMap = { ...pdpDynamicProductMap, ...storeConfMap }

        const pdpBannersData =
          cmsBannerData &&
          pdpDynamicContentMap &&
          getUpdatedCmsContent(cmsBannerData, pdpDynamicContentMap)

        setDynamiContentBanners(pdpBannersData)
        break
      case 'plp':
        const plpDynamicFacetMap =
          mapAttributesFromFacets(extraProp as SelectedFacets, facets) || {}

        const plpDynamicContentMap = { ...plpDynamicFacetMap, ...storeConfMap }

        const plpBannersData =
          cmsBannerData &&
          plpDynamicContentMap &&
          getUpdatedCmsContent(cmsBannerData, plpDynamicContentMap)

        setDynamiContentBanners(plpBannersData)
        break
      case 'cart':
        const cartDynamicProductMap = mapCartItemsAttributes(orderItems) || {}

        const cartDynamicContentMap = { ...cartDynamicProductMap, ...storeConfMap }

        const cartBannersData =
          cmsBannerData &&
          cartDynamicContentMap &&
          getUpdatedCmsContent(cmsBannerData, cartDynamicContentMap)

        setDynamiContentBanners(cartBannersData)
        break

      case 'home':
        const homeBannersData = cmsBannerData && getUpdatedCmsContent(cmsBannerData, storeConfMap)

        setDynamiContentBanners(homeBannersData)
        break
      default:
        const landingPageBannersData = cmsBannerData && getUpdatedCmsContent(cmsBannerData, storeConfMap)

        setDynamiContentBanners(landingPageBannersData)
    }
  }, [orderItems, facets, cmsBannerData])

  return dynamicContentBanners
}
