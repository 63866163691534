import React, { useState } from 'react'
import styled from 'styled-components'
import { StyledIconButton, StyledTypography } from '../../StyledUI'
import { CloseIcon, LensIcon } from '../../StyledUI/Icons'
import { useTranslation } from 'react-i18next'
import { SearchBar } from '../../widgets/search-bar'
import SearchTrendingBanner from './SearchTrendingBanner'
import SearchProductSuggestions from './SearchProductSuggestions'
import { ICMCollection, IPlacement, IPlacementItem } from '../../../types/teaser'
import { ProductBase } from '../../../types/product'
import useThemeMode from '../../../hooks/useThemeMode'

const SEARCH_HEADER_HEIGHT = '52px'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.custom.warmGrey};
`
const Header = styled.div<{ themeMode: string }>`
  ${({ theme }) => `
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  height: ${SEARCH_HEADER_HEIGHT};

  padding: 0 ${theme.spacing(1)}px;
  border-bottom: 1px solid ${theme.palette.text.primary};
  background-color: ${theme.palette.background.default};
  color: ${theme.palette.text.primary};


  ${theme.breakpoints.down('xs')} {
    border-bottom: none;
  }
`}
`

const Title = styled(StyledTypography)`
  margin: 14px 0;
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: normal;
  text-transform: uppercase;
  gap: ${({ theme }) => theme.spacing(1)}px;
`

const SearchIcon = styled(LensIcon)<{ themeMode: string }>`
  color: ${({ themeMode, theme }) =>
    themeMode === 'dark' ? theme.palette.custom.warmGrey : theme.palette.primary.dark};
`

const CloseIconButton = styled(StyledIconButton)<{ themeMode: string }>`
  color: ${({ themeMode, theme }) =>
    themeMode === 'dark' ? theme.palette.custom.warmGrey : theme.palette.primary.dark};
`

const Body = styled.div`
  ${({ theme }) => `
  height: calc(100% - ${SEARCH_HEADER_HEIGHT});
  color: ${theme.palette.primary.light};

  ${theme.breakpoints.down('xs')} {
    overflow: auto;
  }

  ${theme.breakpoints.up('sm')} {
    display: flex;
  }
`}
`

const ContentTextField = styled.div<{ themeMode: string }>`
  ${({ theme }) => `
  flex: 1 0 50%;
  padding: 0 ${theme.spacing(1)}px;
  background-color: ${theme.palette.background.default};

  ${theme.breakpoints.up('sm')} {
    overflow: auto;
    padding-top: ${theme.spacing(1)}px;
    padding-bottom: ${theme.spacing(1)}px;
  }
`}
`

const ContentProduct = styled.div<{ themeMode: string }>`
  ${({ theme }) => `
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  background-color: ${theme.palette.background.default};
`}
`

interface IplacementTrending extends IPlacement {
  teasableItems: IPlacementItem[]
}

interface IProps {
  placement: IplacementTrending
  trendingProduct: ICMCollection | undefined
  onClose: () => void
  onCloseHamburgerMobile?: () => void
  placementName: IHeaderPlacementName
}

interface IProductSearchResponse {
  contents: ProductBase[]
  facets: any[]
  next: string
  previous: string
  total: number
  searchTerm: string
}

interface IHeaderPlacementName {
  benefitBarName: string
  searchBannerName: string
  searchTrendingNowName: string
}

const SearchDrawerContent: React.FC<IProps> = ({
  placement,
  trendingProduct,
  onClose,
  onCloseHamburgerMobile,
  placementName
}) => {
  const { themeMode } = useThemeMode()
  const { t } = useTranslation()
  const [productsSearch, setProductSearch] = useState<IProductSearchResponse | undefined>()
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false)

  const onCloseAll = () => {
    onClose()
    if (onCloseHamburgerMobile) {
      onCloseHamburgerMobile()
    }
  }

  return (
    <>
      <Wrapper>
        <Header themeMode={themeMode}>
          <Title>
            <StyledIconButton>
              <SearchIcon themeMode={themeMode} />
            </StyledIconButton>
            {t('SearchBar.SearchTitle')}
          </Title>
          <CloseIconButton
            themeMode={themeMode}
            data-analytics_available_call="0"
            onClick={onCloseAll}>
            <CloseIcon />
          </CloseIconButton>
        </Header>
        <Body>
          <ContentTextField themeMode={themeMode}>
            <SearchBar
              onClose={onClose}
              setProductSearch={setProductSearch}
              setLoadingSearch={setLoadingSearch}
            />
          </ContentTextField>
          <ContentProduct
            themeMode={themeMode}
          >
            {productsSearch?.contents || loadingSearch ? (
              <SearchProductSuggestions
                onClose={onCloseAll}
                loadingProduct={loadingSearch}
                productSearch={productsSearch}
              />
            ) : (
              <SearchTrendingBanner
                placementName={placementName}
                placement={placement}
                trendingProduct={trendingProduct}
                onProductClick={onCloseAll}
              />
            )}
          </ContentProduct>
        </Body>
      </Wrapper>
    </>
  )
}

export default SearchDrawerContent
