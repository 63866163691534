import { Theme, useTheme } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'
import { StyledTypography } from '../../StyledUI'

interface Props {
  title?: string
  description?: string
}

const StyledEndPageContainer = styled.div<{ theme: Theme }>`
  width: 100%;
  margin-top: ${({ theme }) => `${theme.spacing(2.5)}px`};
  padding: ${(props) => `${props.theme.spacing(1)}px`};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: ${(props) => `0px ${props.theme.spacing(8)}px 0px ${props.theme.spacing(1)}px`};
  }
`
const StyledEndPageTitle = styled(StyledTypography).attrs({ variant: 'h2' })`
  text-transform: uppercase;
  font-size: 2rem;
`

const StyledEndPageContent = styled('div')`
  margin-top: ${(props) => `${props.theme.spacing(1)}px}`};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-height: 300px;
  }

  & > div p {
    line-height: 1.5;
  }
  .seo-content {
    columns: auto 1;
    ${({ theme }) => theme.breakpoints.up('md')} {
      column-gap: 128px;
      columns: auto 2;
      column-fill: balance;
    }
  }
`

const BottomSEOBlock: FC<Props> = ({ title, description }) => {
  const theme = useTheme()

  return description ? (
    <StyledEndPageContainer theme={theme} className="arn-plp__seo-text-container">
      <StyledEndPageTitle>{title}</StyledEndPageTitle>
      <StyledEndPageContent theme={theme}>
        <div className="seo-content" dangerouslySetInnerHTML={{ __html: description || '' }} />
      </StyledEndPageContent>
    </StyledEndPageContainer>
  ) : null
}

export default BottomSEOBlock
