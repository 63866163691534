import React, { CSSProperties, DetailedHTMLProps, FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
import CSS from 'csstype'

type FlexboxProps = Pick<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'className'
> &
  Pick<CSSProperties, 'width' | 'height' | 'gap' | 'flex' | 'padding'> &
  Pick<CSS.StandardLonghandPropertiesHyphen, 'align-items' | 'flex-direction' | 'justify-content'>

const FlexDiv = styled.div<FlexboxProps>`
  display: flex;
  ${(props) => {
    let resultString = ''

    for (const key in props) {
      const propValue = props[key]
      if (!propValue || typeof propValue !== 'string') {
        continue
      }

      resultString = resultString.concat(`${key}: ${propValue};`)
    }

    return resultString
  }}
`

const Flexbox: FC<FlexboxProps> = ({ children, className = '', ...rest }) => {
  return (
    <FlexDiv className={className} {...rest}>
      {children}
    </FlexDiv>
  )
}

export default Flexbox
