import { CountryAccordionDetails } from '../../footer/CountrySelect'
import { StyledAccordionDetails } from '../index'
import StyledGrid from '../StyledGrid'
import StyledLink from '../StyledLink/StyledLink'
import styled from 'styled-components'

const StyledFooter = styled.footer<{ themeMode: string }>`
  border: 1px solid;
    ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark}`};

  .main-container {
    padding: ${({ theme }) => theme.spacing(1)}px;
  }

  .footer-menu {
    display: grid;
    width:100%;
    flex-wrap: wrap;
    row-gap: ${({ theme }) => theme.spacing(0.5)}px;
    padding: ${({ theme }) => theme.spacing(1)}px;
    text-transform: uppercase;
    grid-template-columns: auto auto;
    border-bottom: 1px solid
      ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};

    ${({ theme }) => theme.breakpoints.up('sm')} {
      display: grid;
      grid-template-columns: auto auto ;
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: ${({ theme }) => theme.spacing(13)}px;
      flex-wrap: nowrap;
    }

    ${({ theme }) => theme.breakpoints.up('lg')} {
      display: flex;
    }
  }

  .footer-link {
    color: ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark}`};
  }

  .select-country-container {
    border-bottom: 1px solid
      ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};
    ${({ theme }) => theme.breakpoints.down('md')} {
      border-bottom: none;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      flex-flow: column wrap;
    }
  }

  .sustainable-container {
    height: 57px;
    padding: ${({ theme }) => theme.spacing(1)}px;
    border-bottom: 1px solid
      ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};

    ${({ theme }) => theme.breakpoints.up('lg')} {
      border-bottom: none;
    }
  }
  .sustainable-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    margin-right: ${({ theme }) => theme.spacing(0.5)}px;
    background-color: ${({ theme }) => theme.palette.custom.geckoGreen};
    border-radius: ${({ theme }) => theme.spacing(2)}px;

    svg {
      width: 15px;
      height: 15px;
    }
  }
  .personaldata-container {
    height: 57px;
    padding: 0 ${({ theme }) => theme.spacing(1)}px;
    display: flex;
    align-items: center;
    border-left: 1px solid
      ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};
    border-bottom: 1px solid
      ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};
    ${({ theme }) => theme.breakpoints.up('lg')} {
      border-bottom: none;
    }
    ${({ theme }) => theme.breakpoints.down('sm')} {
      border-left: none;
      &:last-child {
        border-left: 1px solid
          ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};
      }
    }

    &:last-child {
      border-bottom: 0px;
    }
  }

  .footer-link:hover {
    text-decoration: underline;
  }
  .select-country-wrapper {
    position: relative;
    flex: 1;
    padding: 0 ${({ theme }) => theme.spacing(1)}px;

    & > div {
      position: initial;
      padding: ${({ theme }) => theme.spacing(1)}px 0;
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      border-bottom: 1px solid
        ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};

      border-left: 0;
    }

    ${({ theme }) => theme.breakpoints.up('md')} {
      border-left: 1px solid
        ${({ themeMode, theme }) =>
    themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark};

      ${CountryAccordionDetails} {
        position: absolute;
        left: -1px;
        right: 0;
        bottom: 0;
        width: calc(100% + 1px);
        padding: ${({ theme }) => theme.spacing(1)}px;
        background: ${({ themeMode, theme }) =>
    themeMode === 'dark' ? theme.palette.background.dark : theme.palette.background.default};
        border-top: 1px solid
          ${({ themeMode, theme }) =>
    themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark};
        border-left: 1px solid
          ${({ themeMode, theme }) =>
    themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark};
      }
    }
  }
  .sub-menu-link {
    font-size: 14px;
    margin: 6px 0;
    color: ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark}`};

    &:first-child {
      margin-top: ${({ theme }) => theme.spacing(1)}px;
    }
  }
  .sub-menu-link:hover {
    text-decoration: underline;
  }
  .sub-menus-container {
    border-bottom: 1px solid
      ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};

    .sub-menu-container:not(:last-child) {
      border-bottom: 1px solid
        ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};

      ${({ theme }) => theme.breakpoints.up('md')} {
        border-bottom: none;
      }
    }

    .sub-menu-container:not(:first-child) {
      ${({ theme }) => theme.breakpoints.up('md')} {
        border-left: 1px solid
          ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};
      }
    }
  }
  .sub-menu-container {
    padding: ${({ theme }) => theme.spacing(1)}px;
  }
  .sub-menu-title {
    text-transform: uppercase;
  }

  .copyright-container {
    padding: ${({ theme }) => theme.spacing(1)}px;
    row-gap: ${({ theme }) => theme.spacing(1)}px;
    font-size: 0.75rem;
  }

  .other-sites-container {
    border-top: 1px solid
      ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark
    }`};
    padding: ${({ theme }) => theme.spacing(1)}px;
  }

  .other-sites-link {
    font-size: 0.75rem;
  }

  .other-sites-link:hover {
    text-decoration: underline;
  }
`

export const StyledReducedFooter = styled.footer`
  background: ${({ theme }) => theme.palette.primary.contrastText};
  height: 45px;
  padding: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(6)}px;
  display: grid;
  grid-template-columns: 3fr 1fr;
`

export const FirstCol = styled.div`
  display: flex;
  flex-direction: row;
`

export const ShippingWrapper = styled.div`
  flex: 1;
  justify-content: flex-start;
  font-size: 12px;
`

export const ShippingLink = styled(StyledLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.primary.dark};
`

export const SecureTextWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const SecondCol = styled.div`
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`

export const SubMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SustainableContainer = styled(StyledGrid)`
  font-size: 12px;
  line-height: 1.5;

  & > a {
    display: flex;
    align-items: center;
  }
`

export const PersonalDataAdChoicesWrapper = styled.div<{ themeMode: string }>`
  font-size: 12px;
  line-height: 1.5;
  &.personal-info {
    padding-right: 16px;
  }
  p {
    margin: 0;
  }
  a,
  a:hover {
    color: ${({ themeMode, theme }) =>
    `${themeMode === 'dark' ? theme.palette.primary.contrastText : theme.palette.primary.dark}`};
  }
`

export const StyledAccordionLanguages = styled(StyledAccordionDetails)`
  display: flex;
  flex-direction: column;
`

export default StyledFooter
