import MuiAccordion from '@material-ui/core/Accordion'
import styled from 'styled-components'

const StyledAccordion = styled(MuiAccordion)<{ hasMarginBottom?: boolean }>`
  ${({ theme, hasMarginBottom }) => `
  ${hasMarginBottom ? 'margin-bottom: ' + theme.spacing(1) + 'px;' : ''}
  box-shadow: none;
  background-color: transparent;

  &:not(:last-child) {
    border-bottom: 0;
  }

  &:before {
    display: none;
  }

  &$expanded {
    margin: 0;
  }
`}
`

export default StyledAccordion
