import styled from 'styled-components'

export const AddAddressFormButtonContainer = styled.div`
  ${({ theme }) => `
  margin-top: 38px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${theme.spacing(1)}px;

  & > button {
    flex-grow: 1; 
  }

  ${theme.breakpoints.up('lg')} {
    & > button {
      flex-grow: initial; 
    }
  }
  
  ${theme.breakpoints.up('md')} {
    max-width: 500px;
`}
`
