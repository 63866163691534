import { useCallback } from 'react'


interface YupValidationResolverOptions {
  isErrorsCombination?: boolean
}

export interface YupValidationResolverErrors {
  type: string
  message: string
}

type IYupValidationResolverErrorObject<T> = {
  [K in keyof T]: YupValidationResolverErrors[] | YupValidationResolverErrors
}

type useYupValidationResponse<T> = Promise<{ values: T, errors: {} } | { values: {}, errors: IYupValidationResolverErrorObject<T> }>

const defaultOptions: YupValidationResolverOptions = {
  isErrorsCombination: false
}

export const useYupValidationResolver = <T>(validationSchema, options: YupValidationResolverOptions = defaultOptions) =>
  useCallback(
    async (data): useYupValidationResponse<T> => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        })

        return {
          values,
          errors: {},
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => {
              const { isErrorsCombination } = options;

              const allErrorsArray = allErrors[currentError.path] || [];
              const errorDetails = {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              }

              return {
                ...allErrors,
                [currentError.path]: isErrorsCombination ? [...allErrorsArray, errorDetails] : errorDetails,
              }
            },
            {}
          ),
        }
      }
    },
    [validationSchema]
  )
