import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { StyledContainer, StyledSecurityContainer } from './ReducedFooter.style'
import { useCms } from '../providers/CmsProvider'
import { SecurePaymentIcon } from '../StyledUI/Icons/Icons'
import { PaymentMethodsIcons } from '../StyledUI/Payment/PaymentIcons'
import { CountryBaseLink } from '../StyledUI/StyledLink/StyledLink'
import useThemeMode from '../../hooks/useThemeMode'
import { paymentMethodsSelector } from '../../redux/selectors/site'
import { ICMChannel } from '../../types/teaser'

const ReducedFooter = () => {
  const { t } = useTranslation()
  const themeMode = useThemeMode().themeMode

  const availablePaymentMethods = useSelector(paymentMethodsSelector)
  const { footerPlacements } = useCms()
  const [returnsObject, setReturnObject] = useState<{ title: string; href: string }>()

  useEffect(() => {
    const getFooterContent = async () => {
      if (!footerPlacements) {
        return
      }

      const content = (footerPlacements.find((i) => i.name === 'footer_checkout')
        ?.items[0] as ICMChannel) || {
        title: '',
        formattedUrl: '',
      }

      if (content) {
        setReturnObject({ title: content.title, href: content.formattedUrl })
      }
    }

    getFooterContent()
  }, [footerPlacements])

  return (
    <StyledContainer themeMode={themeMode}>
      <div>
        {returnsObject && (
          <CountryBaseLink to={returnsObject.href}>{returnsObject.title}</CountryBaseLink>
        )}
      </div>
      <StyledSecurityContainer>
        <div>
          <SecurePaymentIcon />
          <span>{t('Footer.Reduced.SecureTransactionLabel')}</span>
        </div>
        <div>
          {availablePaymentMethods && (
            <PaymentMethodsIcons paymentMethods={availablePaymentMethods} />
          )}
        </div>
      </StyledSecurityContainer>
    </StyledContainer>
  )
}

export default ReducedFooter
