import { createReducer } from '@reduxjs/toolkit'
import initStates from './initStates'
import { setFacetsAction } from '../actions/catalog'

/**
 * Catalog reducer
 * handles states used by catalog related components
 * @param state State object managed by catalog reducer
 * @param action The dispatched action
 */
const catalogReducer = createReducer(initStates.catalog, (builder) => {
  builder.addCase(setFacetsAction, (state, action) => {
    const facets = action.payload

    if (facets && facets.length > 0) {
      state.facets = facets
    }
  })
})

export default catalogReducer
