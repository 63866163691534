import * as WORKERS from '../workers/seo'

//Redux
import { GET_SEO_CONFIG_ACTION } from '../../actions/seo'
//Standard libraries
import { takeLatest } from 'redux-saga/effects'

/**
 *
 */
export function* watchSaga() {
  yield takeLatest(GET_SEO_CONFIG_ACTION, WORKERS.getSEO)
}
