import findKey from 'lodash/findKey'
import {
  FULL_WIDTH_BANNER_VIEWTYPE,
  PLP_BOX_TEASER_VIEWTYPE,
  SORTING_NOT_CLUSTERED,
} from '../../../constants/common'
import { ICMExternalChannel, IPlacement, isCMCollection } from '../../../types/teaser'
import { generatePath, Link, matchPath, useLocation } from 'react-router-dom'
//Custom libraries
import { PAGINATION_CONFIGS } from '../../../configs/catalog'
//Standard libraries
import React, { FC, useEffect, useMemo, useState } from 'react'
//Redux
import { facetsSelector } from '../../../redux/selectors/catalog'
import { mapProductsInCart, trackProducts } from '../../../foundation/monetate/lib'
import { useSelector } from 'react-redux'

import BottomSEOBlock from './BottomSEOBlock'
import { BreadcrumbLayout } from '../breadcrumb'
import PLPHeader from './PLPHeader'
import ProductGridView from './ProductGridView'
//Tealium
import { SEARCH } from '../../../constants/routes'
import categoryService from '../../../foundation/apis/search/categories.service'
//UI
import get from 'lodash/get'
import { keywordSelector } from '../../../redux/selectors/search'
import { orderItemsSelector } from '../../../redux/selectors/order'
import { trackWindowScroll } from 'react-lazy-load-image-component'
import { useAnalyticsData, useSendPageEventWithRequiredProps } from '../../../foundation/hooks/useAnalyticsData'
import { useDynamicCmsContent } from '../../../foundation/hooks/useDynamicCmsContent'
import { usePageType } from '../../../foundation/hooks/usePageType'
//Foundation libraries
import { useSite } from '../../../foundation/hooks/useSite'
import { useTranslation } from 'react-i18next'
import tealiumService from '../../../foundation/analytics/tealium/tealium.service'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useCms } from '../../providers/CmsProvider'
import { Category } from '../../../types/product'
import { UseProductsReturn } from '../../../hooks/useProducts'
import { ISeo } from '../../../types/seo'
import { sortOrderOptionsSelector } from '../../../redux/selectors/site'
import cmsService from '../../../foundation/apis/cms/cms.service'
import { sanitizeHtml } from '../../../utils/common'
import { sendPlpEvent } from '../../../foundation/analytics/tealium/lib'

type ProductGridProps = Pick<
  UseProductsReturn,
  | 'areFacetsLoading'
  | 'areProductsLoading'
  | 'breadcrumbs'
  | 'currentPage'
  | 'products'
  | 'productsTotal'
  | 'selectedFacets'
  | 'selectedSortOption'
  | 'fetchProducts'
  | 'getUpdatedSelectedFacets'
> & {
  cid: string
  searchTerm?: string
  seoData?: Omit<ISeo, 'component'>
}

export type PlpPromoBanner = {
  title: string
  icon: string
  ctaLabel: string
  ctaPath: string
}

/**
 * Product Grid component
 * displays catalog entry listing, pagination and selected facets
 * @param props
 */
const ProductGridLayout: FC<ProductGridProps> = (props) => {
  const {
    areFacetsLoading,
    areProductsLoading,
    breadcrumbs,
    cid,
    products,
    productsTotal,
    searchTerm = '',
    selectedFacets,
    selectedSortOption,
    seoData,
    fetchProducts,
    getUpdatedSelectedFacets,
  } = props

  const { t } = useTranslation()
  const { mySite } = useSite()
  const location = useLocation()
  const { pageType } = usePageType()
  const { langCode } = useStoreIdentity()
  const { previewParams, fetchCommerceContent } = useCms()

  const facets = useSelector(facetsSelector)
  const sortOrderOptions = useSelector(sortOrderOptionsSelector)
  const suggestedKeywords = useSelector(keywordSelector)
  const orderItems = useSelector(orderItemsSelector)
  const { ...analyticsDataForPlp } = useAnalyticsData('plp')
  const sendSearchAnalyticsPageEvent = useSendPageEventWithRequiredProps(
    tealiumService.pageTypes.searchResults
  )

  const [isPaginationLoading, setPaginationLoading] = useState<boolean>(false)
  const [bannersCmsData, setBannersCmsData] = useState<IPlacement[]>()
  const [category, setCategory] = useState<Category>()
  const [categoryIdentifiers, setCategoryIdentifiers] = useState<string[]>()
  const [isPlpAnalyticsPageEventSent, setPlpAnalyticsPageEventSent] = useState<boolean>(false)
  const dynamicContentBanners = useDynamicCmsContent(bannersCmsData, selectedFacets)

  const plpPromoBanner = useMemo<PlpPromoBanner | null>(() => {
    if (!dynamicContentBanners) return null

    const plpPromoBannerPlacement = cmsService.getPlpPromoBannerPlacement(dynamicContentBanners)
    if (!plpPromoBannerPlacement) return null

    const placementItem = plpPromoBannerPlacement.items[0]
    if (!placementItem) return null

    const { teaserText1, teaserIcon, teaserLXCallToActionSettings } = placementItem
    const { title = '', formattedUrl = '' } =
      (teaserLXCallToActionSettings[0].target as ICMExternalChannel) || {}

    return {
      title: sanitizeHtml(teaserText1),
      icon: teaserIcon,
      ctaLabel: title,
      ctaPath: formattedUrl,
    }
  }, [dynamicContentBanners])

  const searchTitle =
    productsTotal > 0 ? `${productsTotal} ${t('ProductGrid.Labels.searchResults')}` : ''

  // XXX: check whether the list is clusterd or not - https://luxotticaretail.atlassian.net/browse/ARNETTE-1264
  const isClustered =
    !!selectedSortOption.value && !SORTING_NOT_CLUSTERED.includes(selectedSortOption.value)

  const defaultEngLangPageSection = useMemo(() => {
    return seoData?.alternateHrefs?.filter(href => {
      return href.key === 'en-us'
    })?.pop()?.value
  },[seoData,langCode])

  //TODO move in the previous funcion to optimize performance
  useEffect(() => {
    const categoriesIds = breadcrumbs.reduce((acc, f, i) => {
      acc[i] = f.value
      return acc
    }, [] as string[])

    const getCategoryIdentifiers = async (categoriesIds) => {
      const categoryResponse = await categoryService.getV2CategoryResourcesUsingGET({
        id: categoriesIds,
        storeId: mySite.storeID,
      })

      const category = get(categoryResponse, 'data.contents').find((identifier: { identifier?: string }) => identifier.identifier === seoData?.tokenExternalValue)
      setCategory(category)
      
      const categoryIdentifiers = get(categoryResponse, 'data.contents').reduce((acc, f, i) => {
        acc[i] = f.identifier
        return acc
      }, [])
      setCategoryIdentifiers(categoryIdentifiers)
    }
    if (categoriesIds.length > 0) {
      getCategoryIdentifiers(categoriesIds)
    }
  }, [seoData?.tokenValue, breadcrumbs])

  useEffect(() => {
    const productsInCartForMonetate = mapProductsInCart(orderItems)

    if (!areProductsLoading && products.length > 0 && facets) {
      const searchProps = tealiumService.formatSearchProps(
        facets,
        selectedFacets,
        {
          ...selectedSortOption,
          value: findKey(sortOrderOptions, selectedSortOption) || selectedSortOption.value,
        },
        productsTotal
      )!

      const isSearchPage = matchPath(location.pathname, generatePath(SEARCH, { country: langCode }))

      if (isSearchPage) {
        sendSearchAnalyticsPageEvent({
          Search_Keyword: searchTerm,
          Search_ResultItemsQnt: productsTotal ? productsTotal.toString() : '',
          Search_Type: 'text',
          Search_View: 'SERP',
        })
      } else {
        if (!isPlpAnalyticsPageEventSent && defaultEngLangPageSection) {
          sendPlpEvent({
            common: analyticsDataForPlp,
            qnt: productsTotal,
            products: products,
            pageSection: defaultEngLangPageSection || '',
            ...searchProps,
          })

          setPlpAnalyticsPageEventSent(true)
        }
      }

      if (category) {
        trackProducts(
          products.map(({ partNumber }) => partNumber),
          productsInCartForMonetate,
          pageType
        )
      }
    }
  }, [areProductsLoading, category, products.length, selectedSortOption, sortOrderOptions, defaultEngLangPageSection])

  useEffect(() => {
    if (!(mySite && seoData && seoData.tokenName === 'CategoryToken' && categoryIdentifiers)) {
      return
    }

    const { tokenExternalValue } = seoData
    if (categoryIdentifiers.indexOf(tokenExternalValue) !== -1) {
      setBannersCmsData([])
      const breadcrumb = [cmsService.breadcrumbsRootKey, ...categoryIdentifiers]

      fetchCommerceContent(
        'plp',
        tokenExternalValue,
        breadcrumb,
        previewParams.filterRulesLocale,
        previewParams.previewDate
      ).then((commercePlacements) => {
        const plpPromoBanner = cmsService.getPlpPromoBannerPlacement(commercePlacements)

        // Start: filtering and setting CMS banners to state
        const bannersInfo: IPlacement[] = plpPromoBanner ? [plpPromoBanner] : []

        if (commercePlacements) {
          commercePlacements
            .filter(
              (item) =>
                item.viewtype === FULL_WIDTH_BANNER_VIEWTYPE ||
                item.viewtype === PLP_BOX_TEASER_VIEWTYPE ||
                item.viewtype === 'default'
            )
            .map((placement: IPlacement) => {
              if (
                placement.viewtype === FULL_WIDTH_BANNER_VIEWTYPE ||
                placement.viewtype === PLP_BOX_TEASER_VIEWTYPE
              ) {
                bannersInfo.push(placement)
              }

              if (placement.viewtype === 'default' && placement.items.length > 0) {
                const collection = placement.items.filter(isCMCollection)
                if (collection.length > 0) {
                  const firstItem = collection[0]
                  if (
                    firstItem.viewtype === FULL_WIDTH_BANNER_VIEWTYPE ||
                    firstItem.viewtype === PLP_BOX_TEASER_VIEWTYPE
                  ) {
                    bannersInfo.push(placement)
                  }
                }
              }
            })
        }

        setBannersCmsData(bannersInfo)
        // End: filtering and setting CMS banners to state
      })
    }
  }, [seoData?.tokenName, categoryIdentifiers])

  /**
   * Sets the page offset and dispatches request to get product list
   * @param value The page number
   */
  const onPageChange = (page: number) => {
    setPaginationLoading(true)

    fetchProducts(
      undefined,
      undefined,
      page === 1 ? 0 : (page - 1) * PAGINATION_CONFIGS.pageLimit,
      PAGINATION_CONFIGS.pageLimit
    ).then(() => {
      setPaginationLoading(false)
    })
  }

  return (
    <>
      <BreadcrumbLayout
        breadcrumbs={breadcrumbs}
        cid={cid}
        searchTermText={`${t('ProductGrid.Labels.search')}: ${searchTerm}`}
      />

      <PLPHeader
        title={searchTerm ? searchTitle : category?.description}
        description={searchTerm ? undefined : seoData?.page.metaDescription}
        areProductsLoading={areProductsLoading}
        areFacetsLoading={areFacetsLoading}
        plpPromoBanner={plpPromoBanner}
        selectedFacets={selectedFacets}
        selectedSortOption={selectedSortOption}
        fetchProducts={fetchProducts}
        getUpdatedSelectedFacets={getUpdatedSelectedFacets}
      />

      <ProductGridView
        bannersData={dynamicContentBanners?.filter((b) => b.name !== 'PLP_promo_banner')}
        currentPage={props.currentPage}
        isClustered={isClustered}
        isCatalogLoading={areProductsLoading}
        isPaginationLoading={isPaginationLoading}
        products={products}
        productsCount={productsTotal}
        onCurrentPageChange={onPageChange}>
        {(productsTotal === 0 || !productsTotal) && searchTerm !== '' && (
          <div id={`productGrid_div_18_${cid}`} className="suggested-keywords">
            <h4 id={`productGrid_p_19_${cid}`}>
              {t('ProductGrid.Labels.noMatches', { searchTerm })}
            </h4>
            <p id={`productGrid_p_21_${cid}`}>
              {t('ProductGrid.Labels.searchAgain', { searchTerm })}
            </p>
            {suggestedKeywords.length > 0 && (
              <>
                {t('ProductGrid.Labels.suggestion')}
                <br />
                {suggestedKeywords?.map((keyword: string, index: number) => (
                  <Link
                    key={keyword}
                    to={SEARCH + '?searchTerm=' + keyword}
                    onClick={() => {}}
                    className="suggestion-link"
                    id={`productGrid_a_22_${index}_${cid}`}>
                    {keyword} <br />
                  </Link>
                ))}
              </>
            )}
          </div>
        )}
      </ProductGridView>

      <BottomSEOBlock title={category?.description} description={category?.longDescription} />
    </>
  )
}

export default trackWindowScroll(ProductGridLayout) as FC<ProductGridProps>
