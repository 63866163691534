import React, { FC } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { themeModeSelector } from '../../../redux/selectors/theme'
import { Optional } from '../../../types/common'
import {
  FilterElementWrapper,
  CheckboxInputElement,
  FilterElementLabelIcon,
  FilterElementText,
  CheckboxAsButtonLabel,
} from './CheckboxAsButton.style'

export interface CheckboxAsButtonProps {
  checked?: boolean
  disabled?: boolean
  id?: string
  labelText?: string
  variant: 'outlined' | 'outlined-highlighted' // 'outlined' is default
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  renderLabelIcon?: () => React.ReactNode
}

const CheckboxAsButton: FC<Optional<CheckboxAsButtonProps, 'variant'>> = ({
  checked = false,
  disabled,
  id,
  labelText,
  variant = 'outlined',
  onChange,
  renderLabelIcon,
  ...props
}) => {
  const themeMode = useSelector(themeModeSelector, shallowEqual)

  return (
    <FilterElementWrapper disabled={!!disabled} {...props}>
      <CheckboxInputElement
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />

      <CheckboxAsButtonLabel htmlFor={id} checked={checked} themeMode={themeMode} variant={variant}>
        {renderLabelIcon && <FilterElementLabelIcon>{renderLabelIcon()}</FilterElementLabelIcon>}
        <FilterElementText>{labelText}</FilterElementText>
      </CheckboxAsButtonLabel>
    </FilterElementWrapper>
  )
}

export default CheckboxAsButton
