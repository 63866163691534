//Redux
//Standard libraries
import React, { FC, useEffect, useMemo } from 'react'
//UI
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useParams } from 'react-router'
import { ACCOUNT, ACCOUNT_CHILDREN } from '../../../constants/routes'
import * as ACTIONS from '../../../redux/actions/account'
import { userEmailSelector, userIdSelector } from '../../../redux/selectors/user'
import { sendMyAccountEvent } from '../../../foundation/analytics/tealium/lib'
import { useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { PersonalInformationLayout } from '../../widgets/personal-information'
import AccountSummary from '../account-summary/AccountSummary'
import AddressBook from '../address-book/AddressBook'
import ChangePassword from '../change-password/ChangePassword'
import OrderHistory from '../order/OrderHistory'
import AccountPageLayout from './AccountPageLayout'

export type IAccountActiveComponent = FC<{ onSetAccountNavDisplay(display: boolean): void }>

const ACCOUNT_PAGE_COMPONENT_MAP: Record<string, IAccountActiveComponent> = {
  [ACCOUNT_CHILDREN.PERSONAL_INFORMATION]: AccountSummary,
  [ACCOUNT_CHILDREN.CHANGE_PASSWORD]: ChangePassword,
  [ACCOUNT_CHILDREN.ADDRESS_BOOK]: AddressBook,
  [ACCOUNT_CHILDREN.ORDER_HISTORY]: OrderHistory,
}

/**
 * Generates react-router path of children account page. (`/:country/account/:page`).
 * To get account page itself page param should be omitted.
 *
 * @param langCode string
 * @param page string. Name of children page path (e.g. address-book, wishlist, order-history)
 */
export const generateAccountPagePath = (
  langCode: string,
  page?: string,
  queryParams?: Record<string, string>
) => {
  const query = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ''

  return generatePath(ACCOUNT, { country: langCode, page }) + query
}

const Account: FC = () => {
  const dispatch = useDispatch()
  const { page: pageRouterParam } = useParams<{ page: string }>()
  const analyticsDataForMyAccount = useAnalyticsData('profile')
  const email = useSelector(userEmailSelector)
  const userId = useSelector(userIdSelector)

  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsDataForMyAccount,
      email,
      userId,
    })
  }, [])

  const ActiveChildComponent = useMemo<IAccountActiveComponent | null>(() => {
    if (
      pageRouterParam &&
      Object.prototype.hasOwnProperty.call(ACCOUNT_PAGE_COMPONENT_MAP, pageRouterParam)
    ) {
      return ACCOUNT_PAGE_COMPONENT_MAP[pageRouterParam]
    }

    return null
  }, [pageRouterParam])

  useEffect(() => {
    dispatch(ACTIONS.getOrders())
  }, [dispatch])

  return <AccountPageLayout ActiveComponent={ActiveChildComponent || PersonalInformationLayout} />
}

export default Account
