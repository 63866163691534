import { useEffect } from 'react'
import { fetchWishlistAction, clearWishlistAction } from '../../features/wishList/action'
import { useDispatch, useSelector } from 'react-redux'
import { useSite } from '../../foundation/hooks/useSite'
import { loginStatusSelector } from '../../redux/selectors/user'
import { wishlistEnabledSelector } from '../../features/wishList/selector'

export const useWishList = () => {
  const dispatch = useDispatch()
  const { mySite } = useSite()
  const loggedIn = useSelector(loginStatusSelector)
  const isWishlistEnabled = useSelector(wishlistEnabledSelector)

  useEffect(() => {
    if (mySite && isWishlistEnabled) {
      loggedIn ? dispatch(fetchWishlistAction()) : dispatch(clearWishlistAction())
    }
  }, [isWishlistEnabled, loggedIn, mySite])
}
