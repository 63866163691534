import { GENERIC_PROTECTED, HOME, REGISTER_PROTECTED } from '../../constants/routes'
/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect } from 'react'
//Redux
import { loginStatusSelector, userInitStatusSelector } from '../../redux/selectors/user'
import { useHistory, useLocation } from 'react-router'

//Custom libraries
import { ROUTE_CONFIG } from '../../configs/routes'
import { matchRoutes } from 'react-router-config'
import { useSelector } from 'react-redux'
//Foundation libraries
import { useSite } from '../hooks/useSite'
import { generatePath } from 'react-router-dom'
import { useStoreIdentity } from '../hooks/useStoreIdentity'

const LoginGuard: React.FC = () => {
  const { mySite } = useSite()
  const location = useLocation()
  const history = useHistory()
  const loginStatus = useSelector(loginStatusSelector)
  const userInited = useSelector(userInitStatusSelector)
  const { langCode } = useStoreIdentity()

  useEffect(() => {
    if (mySite && userInited) {
      const routes = ROUTE_CONFIG.CFG
      const branch = matchRoutes(routes, location.pathname)
      let protectedRoute
      if (!loginStatus) {
        protectedRoute = branch.some((b) => {
          return b.route['isProtected'] === REGISTER_PROTECTED
        })
      } else {
        protectedRoute = branch.some((b) => {
          return b.route['isProtected'] === GENERIC_PROTECTED
        })
      }
      if (!!protectedRoute) {
        history.push(generatePath(HOME, { country: langCode }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, loginStatus, mySite, userInited])

  return <></>
}

export default LoginGuard
