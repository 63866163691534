import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'

const ComponentWrapper = React.forwardRef((props: any, ref: any) => {
  return <Box {...props} ref={ref} />
})

const StyledBox = styled(ComponentWrapper)`
  ${({ theme }) => `
    .arn-checkout__create-account-cta {
      margin-right: ${theme.spacing(0.5)}px;
      ${theme.breakpoints.down('sm')} {
        width: 100%;
      }
    }
  `} 
`

export default StyledBox
